import {
  Component,
  OnInit,
  Input,
  ChangeDetectorRef,
  ViewRef,
  OnDestroy,
} from "@angular/core";
import { restore } from "../../assets/json/restoreTooltip.en";
import { Options } from "ng5-slider";
import { CompileShallowModuleMetadata } from "@angular/compiler";
import { isNullOrUndefined, isNumber } from "util";
import { FieldType } from "@ngx-formly/core";
import { ApiService } from "src/app/core-module/services/api.service";
@Component({
  selector: "app-ztvoutput",
  templateUrl: "./ztvoutput.component.html",
  styleUrls: ["./ztvoutput.component.scss"],
})
export class ZtvoutputComponent extends FieldType implements OnInit, OnDestroy {
  @Input() field;
  formatVersion: any;
  column: any;
  properties: any = [];
  defaultColor: boolean = true;
  inputError: any = [];
  CurveSelection: any;
  CurveSelectionVal: any;
  ngModelval: any = [];
  restoreText = restore;
  userSpecified: boolean = false;
  curveSurveyValues = {};
  dimLevelColumn = [];
  outputVoltageColumn = [];
  quickConfigvalue;
  quickConfigCustomCSS;
  setQuickConfig;
  fileResponse: any;
  isEdited: any;
  ztvoutputDefaultColor;
  ztvoutputCreateFile: boolean;
  constructor(private service: ApiService, private ref: ChangeDetectorRef) {
    super();
  }
  resetDefaultztvoutputValues() {
    this.ngModelval["CurveSelection"] = this.CurveSelection.default;
    if (this.ngModelval["CurveSelection"] === "User specified") {
      this.userSpecified = true;
    } else {
      this.userSpecified = false;
    }
    var dimLevelData =
      this.field.allOf[0].then.dependencies.CurveSelection.filter(function (
        item
      ) {
        return item.startsWith("DimLevel");
      });
    var outputVoltageData =
      this.field.allOf[0].then.dependencies.CurveSelection.filter(function (
        item
      ) {
        return item.startsWith("OutputVoltage");
      });
    this.dimLevelColumn = dimLevelData;
    this.outputVoltageColumn = outputVoltageData;
    for (let index = 0; index < dimLevelData.length; index++) {
      const element = dimLevelData[index];
      this.ngModelval[element] = this.properties[element]["default"];
    }
    for (let index = 0; index < outputVoltageData.length; index++) {
      const element = outputVoltageData[index];
      this.ngModelval[element] = this.properties[element]["default"];
    }
    for (var type in this.inputError) {
      this.inputError[type] = false;
    }
    this.ztvoutputCreateFile = false;
    this.service.ztvoutputInputError(this.ztvoutputCreateFile);
    this.sendztvoutputdata(this.ngModelval);
  }
  checkInvalidValues() {
    this.ztvoutputCreateFile = false;
    for (var type in this.inputError) {
      if (this.inputError[type]) {
        this.ztvoutputCreateFile = true;
      }
    }
    this.service.ztvoutputInputError(this.ztvoutputCreateFile);
  }
  ngOnInit() {
    this.isEdited = false;
    this.service.getztvoutputDefaultValue.subscribe((response) => {
      if (response == "valid") {
        this.ztvoutputDefaultColor = true;
        this.resetDefaultztvoutputValues();
      }
    });
    this.service.getztvoutputData.subscribe((data) => {
      if (data) {
        this.ngModelval = data;
        this.checkdfault();
        this.checkInvalidValues();
      }
    });
    this.formatVersion = this.field.featureVersion;
    this.column = this.field.requiredProperties;
    this.properties = this.field.properties;
    this.service.ztvoutputInputError(false);
    //curve slection
    if (this.properties.CurveSelection) {
      let CurveSelectionEnum = [];
      CurveSelectionEnum = this.properties.CurveSelection.enum;
      let enumCurveValues = {};
      let enumKeys = [];
      CurveSelectionEnum.forEach((item, i) => {
        enumCurveValues[item] = CurveSelectionEnum[i];
        enumKeys.push(item);
        this.curveSurveyValues = enumCurveValues;
      });
      this.properties.CurveSelection["enum"] = enumKeys;
      this.CurveSelection = this.properties.CurveSelection;
      this.ngModelval["CurveSelection"] = this.CurveSelection.default;
    }
    var dimLevelData =
      this.field.allOf[0].then.dependencies.CurveSelection.filter(function (
        item
      ) {
        return item.startsWith("DimLevel");
      });
    var outputVoltageData =
      this.field.allOf[0].then.dependencies.CurveSelection.filter(function (
        item
      ) {
        return item.startsWith("OutputVoltage");
      });
    this.dimLevelColumn = dimLevelData;
    this.outputVoltageColumn = outputVoltageData;
    for (let index = 0; index < dimLevelData.length; index++) {
      const element = dimLevelData[index];
      this.ngModelval[element] = this.properties[element]["default"];
    }
    for (let index = 0; index < outputVoltageData.length; index++) {
      const element = outputVoltageData[index];
      this.ngModelval[element] = this.properties[element]["default"];
    }
    let aaa = JSON.parse(localStorage.getItem("saveSetData"));
    let unSelectedFeat = Array(
      JSON.parse(localStorage.getItem("UnSelectedFeatConfig"))
    );
    let isConfigEdited = false;
    let featIndex: number = 0;
    if (unSelectedFeat[0] != null) {
      unSelectedFeat[0].forEach((element) => {
        if (element["featureName"].toString().toUpperCase() === "ZTVOUTPUT") {
          isConfigEdited = true;
          this.fileResponse = {};
          let ztvOutputUnSelectedVal: any = element["featureParameters"][0];
          // let ztvEditedData = {
          //   resp: {
          //     Ztv: ztvUnSelectedVal,
          //   },
          // };
          // this.fileResponse = ztvEditedData;
          this.addztvoutputLoadedFeatureReadValues(ztvOutputUnSelectedVal);
          unSelectedFeat[0].splice(featIndex, 1);
          localStorage.setItem(
            "UnSelectedFeatConfig",
            JSON.stringify(unSelectedFeat[0])
          );
        } else {
          featIndex++;
        }
      });
    }

    setTimeout(() => {
      this.fileResponse = JSON.parse(localStorage.getItem("configurationData"));
      if (this.fileResponse) {
        if (this.fileResponse.resp && this.fileResponse.resp.ZtvOutput) {
          if (this.fileResponse.resp.ZtvOutput)
            this.addztvoutputLoadedFeatureReadValues(
              this.fileResponse.resp.ZtvOutput
            );
        }
      }
    }, 1000);
    this.service.getquickConfig.subscribe((data) => {
      if (data === "quickConfig") {
        this.quickConfigvalue = true;
        this.quickConfigCustomCSS = "quick-custom-inputbox";
        this.setQuickConfig = "quickConfig";
      } else {
        this.quickConfigvalue = false;
        this.quickConfigCustomCSS = "custom-inputbox";
        this.setQuickConfig = "standard";
      }
      setTimeout(() => {
        if (this.ref && !(this.ref as ViewRef).destroyed) {
          this.ref.detectChanges();
        }
      });
    });
    setTimeout(() => {
      this.sendztvoutputdata(this.ngModelval);
      this.isEdited = true;
    }, 1000);
  }

  ztvUserSpecified() {
    var dimLevelData =
      this.field.allOf[0].then.dependencies.CurveSelection.filter(function (
        item
      ) {
        return item.startsWith("DimLevel");
      });
    var outputVoltageData =
      this.field.allOf[0].then.dependencies.CurveSelection.filter(function (
        item
      ) {
        return item.startsWith("OutputVoltage");
      });
    this.dimLevelColumn = dimLevelData;
    this.outputVoltageColumn = outputVoltageData;
    for (let index = 0; index < dimLevelData.length; index++) {
      const element = dimLevelData[index];
      this.ngModelval[element] = this.properties[element]["default"];
    }
    for (let index = 0; index < outputVoltageData.length; index++) {
      const element = outputVoltageData[index];
      this.ngModelval[element] = this.properties[element]["default"];
    }
  }
  addztvoutputLoadedFeatureReadValues(ztvoutput: any) {
    let ztvoutputObj = ztvoutput;
    var keys = Object.keys(ztvoutputObj);
    var values = Object.values(ztvoutputObj);
    keys.forEach((key, i) => {
      this.textValChanged(values[i], key);
    });
    var result = [];
    keys.forEach((key, i) => (result[key] = values[i]));
    this.ngModelval = result;
    this.ngModelval["CurveSelection"] = result["CurveSelection"];
    var dimLevelData =
      this.field.allOf[0].then.dependencies.CurveSelection.filter(function (
        item
      ) {
        return item.startsWith("DimLevel");
      });
    var outputVoltageData =
      this.field.allOf[0].then.dependencies.CurveSelection.filter(function (
        item
      ) {
        return item.startsWith("OutputVoltage");
      });
    this.dimLevelColumn = dimLevelData;
    this.outputVoltageColumn = outputVoltageData;
    if (this.ngModelval["CurveSelection"] === "User specified") {
      this.userSpecified = true;
    } else {
      this.userSpecified = false;
      this.ztvUserSpecified();
    }
    for (let index = 0; index < dimLevelData.length; index++) {
      const element = dimLevelData[index];
      this.ngModelval[element] = result[element];
    }
    for (let index = 0; index < outputVoltageData.length; index++) {
      const element = outputVoltageData[index];
      this.ngModelval[element] = result[element];
    }
    this.checkdfault();
    this.checkInvalidValues();
    this.sendztvoutputdata(this.ngModelval);
  }
  setConfigurationData(value, keyVal) {
    if (!isNullOrUndefined(value)) {
      let ztvoutputObj = {};
      var key = keyVal;
      var data = {};
      var addNew = {
        [keyVal]: {},
      };
      data = JSON.parse(localStorage.getItem("configurationData"));
      if (data && !isNullOrUndefined(data["resp"])) {
        ztvoutputObj = Object.assign({}, value);
        data["resp"][keyVal] = ztvoutputObj;
      } else {
        data["resp"] = addNew;
        ztvoutputObj = Object.assign({}, value);
        data["resp"][keyVal] = ztvoutputObj;
      }
      localStorage.setItem("configurationData", JSON.stringify(data));
    }
  }
  sendztvoutputdata(val) {
    this.service.sendztvoutputdata(val);
    if (this.isEdited) {
      this.setConfigurationData(this.ngModelval, "ZtvOutput");
    }
  }
  textValChanged(value, itemKey) {
    var dimLevelData =
      this.field.allOf[0].then.dependencies.CurveSelection.filter(function (
        item
      ) {
        return item.startsWith("DimLevel");
      });
    var outputVoltageData =
      this.field.allOf[0].then.dependencies.CurveSelection.filter(function (
        item
      ) {
        return item.startsWith("OutputVoltage");
      });
    if (dimLevelData.indexOf(itemKey) !== -1) {
      var index = dimLevelData.indexOf(itemKey);
      const prevElement = dimLevelData[index - 1];
      const element = dimLevelData[index];
      const nextElement = dimLevelData[index + 1];
      if (
        (!isNullOrUndefined(nextElement) &&
          Number(this.ngModelval[element]) >=
            Number(this.ngModelval[nextElement])) ||
        (!isNullOrUndefined(prevElement) &&
          Number(this.ngModelval[element]) <=
            Number(this.ngModelval[prevElement]))
      ) {
        this.inputError[element] = true;
      } else {
        var itemKeyMin = this.properties[element]["minimum"];
        var itemKeyMax = this.properties[element]["maximum"];
        if (
          Number(value) < Number(itemKeyMin) ||
          Number(value) > Number(itemKeyMax)
        ) {
          this.inputError[element] = true;
          this.ngModelval[element] = Number(value);
        } else if (
          this.ngModelval[element] === "" ||
          isNaN(this.ngModelval[element])
        ) {
          this.inputError[element] = true;
        } else {
          this.inputError[element] = false;
          this.ngModelval[element] = Number(this.ngModelval[element]);
        }
      }
    } else if (outputVoltageData.indexOf(itemKey) !== -1) {
      var indexOutputVoltage = outputVoltageData.indexOf(itemKey);
      const elementOutputVoltage = outputVoltageData[indexOutputVoltage];
      if (this.ngModelval[elementOutputVoltage] === "") {
        this.inputError[elementOutputVoltage] = true;
      }
      const prevElement = outputVoltageData[indexOutputVoltage - 1];
      const nextElementOutputVoltage =
        outputVoltageData[indexOutputVoltage + 1];
      if (
        (!isNullOrUndefined(nextElementOutputVoltage) &&
          Number(this.ngModelval[elementOutputVoltage]) >=
            Number(this.ngModelval[nextElementOutputVoltage])) ||
        (!isNullOrUndefined(prevElement) &&
          Number(this.ngModelval[elementOutputVoltage]) <=
            Number(this.ngModelval[prevElement]))
      ) {
        this.inputError[elementOutputVoltage] = true;
      } else {
        var itemKeyMin = this.properties[itemKey]["minimum"];
        var itemKeyMax = this.properties[itemKey]["maximum"];
        if (
          Number(value) < Number(itemKeyMin) ||
          Number(value) > Number(itemKeyMax)
        ) {
          this.inputError[itemKey] = true;
          this.ngModelval[itemKey] = parseFloat(value);
        } else if (
          this.ngModelval[elementOutputVoltage] === "" ||
          isNaN(this.ngModelval[elementOutputVoltage])
        ) {
          this.inputError[elementOutputVoltage] = true;
        } else {
          this.inputError[elementOutputVoltage] = false;
          this.ngModelval[itemKey] = parseFloat(this.ngModelval[itemKey]);
        }
      }
    }
    this.checkdfault();
    for (var type in this.inputError) {
      if (this.inputError[type]) {
        this.ztvoutputCreateFile = true;
      }
    }
    this.service.ztvoutputEditedVal(this.ztvoutputDefaultColor);
    this.service.ztvoutputInputError(this.ztvoutputCreateFile);
    this.sendztvoutputdata(this.ngModelval);
  }
  checkdfault() {
    this.ztvoutputDefaultColor = true;
    this.properties = this.field.properties;
    for (var type in this.ngModelval) {
      if (this.ngModelval[type] != this.properties[type]["default"]) {
        this.ztvoutputDefaultColor = false;
      }
    }
    this.service.ztvoutputEditedVal(this.ztvoutputDefaultColor);
  }
  sliderEvent() {}
  ZtvoutputvalueChange(modelValue) {
    this.ztvoutputDefaultColor = true;
    this.ngModelval["CurveSelection"] = modelValue;
    if (this.ngModelval["CurveSelection"] === "User specified") {
      this.userSpecified = true;
    } else {
      this.userSpecified = false;
    }
    for (var type in this.ngModelval) {
      if (type === "CurveSelection") {
        if (this.ngModelval[type] != this.properties[type]["default"]) {
          this.ztvoutputDefaultColor = false;
        }
      }
    }
    this.service.ztvoutputEditedVal(this.ztvoutputDefaultColor);
    this.sendztvoutputdata(this.ngModelval);
  }
  numberOnlyAlongWithDec(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57))
      return false;

    return true;
  }
  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  ngOnDestroy() {}
}
