import { Component, Input, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/core-module/services/api.service';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'app-multi-config-mode',
  templateUrl: './multi-config-mode.component.html',
  styleUrls: ['./multi-config-mode.component.scss']
})
export class MultiConfigModeComponent extends FieldType
implements OnInit {

  subscriptions: Subscription[] = [];
  @Input() field;
  column: any;
  properties: any;
  selectedConfig = {ConfigurationMode: ""};
  allConfigs = [];
  constructor(
    private apiSrv: ApiService
  ) { 
    super();
  }

  ngOnInit() {
    this.subscriptions.push(this.apiSrv.multiconfigDefault.subscribe(res => {
      if(res){
        this.restoreDefault();
      }
    }));
    this.properties = this.field.properties;
    this.allConfigs = this.properties.ConfigurationMode["enum"];
    this.setInitialValue();
  }

  setInitialValue(){
    const data = JSON.parse(localStorage.getItem("configurationData"));
    let value = "";
    if(data['resp'] && data['resp']['multiConfigMode']){
      const configData = data['resp']['multiConfigMode'];
      this.onChangeList(configData.ConfigurationMode);
    }else{
      this.restoreDefault();
    }
  }

  onChangeList(value) {
    this.selectedConfig['ConfigurationMode'] = value;
    this.checkDefault();
    this.apiSrv.sendMultiConfigData(this.selectedConfig);
    this.setConfigurationData(this.selectedConfig, 'multiConfigMode');
  }

  checkDefault(){
    if(this.properties.ConfigurationMode['default'] == this.selectedConfig.ConfigurationMode){
      this.apiSrv.restoreMulticonfigDefault(true);
    }else{
      this.apiSrv.restoreMulticonfigDefault(false);
    }
  }

  restoreDefault(){
    this.selectedConfig['ConfigurationMode'] = this.properties.ConfigurationMode['default'];
    this.apiSrv.sendMultiConfigData(this.selectedConfig);
    this.setConfigurationData(this.selectedConfig, 'multiConfigMode');
  }

  setConfigurationData(value, keyVal) {
    if(!isNullOrUndefined(value)){
      let configObj = {};
      var key = keyVal;
      var data = {};
      var addNew = {
        [keyVal]: {},
      };
      data = JSON.parse(localStorage.getItem("configurationData"));
      if (data && !isNullOrUndefined(data["resp"])) {
        configObj = Object.assign({}, value);
        data["resp"][keyVal] = configObj;
      } else {
        data["resp"] = addNew;
        configObj = Object.assign({}, value);
        data["resp"][keyVal] = configObj;
      }
      localStorage.setItem("configurationData", JSON.stringify(data));
    }
  }

  ngOnDestroy(){
    this.subscriptions.forEach(sub => {
      if(sub) sub.unsubscribe();
    })
  }
}
