import {
  Component,
  OnInit,
  Input,
  ChangeDetectorRef,
  ViewRef,
  OnDestroy,
} from "@angular/core";
import { restore } from "../../assets/json/restoreTooltip.en";
import { Options } from "ng5-slider";
import { CompileShallowModuleMetadata } from "@angular/compiler";
import { isNullOrUndefined, isNumber } from "util";
import { FieldType } from "@ngx-formly/core";
import { ApiService } from "src/app/core-module/services/api.service";
import { FormBuilder, NgModel, Validators } from "@angular/forms";
import { FormControl, FormGroup } from "@angular/forms";
import { Subject } from "rxjs";
@Component({
  selector: "app-rso",
  templateUrl: "./rso.component.html",
  styleUrls: ["./rso.component.scss"],
})
export class RsoComponent implements OnInit, OnDestroy {
  @Input() field;
  formatVersion: any;
  column: any;
  properties: any = [];
  defaultColor: boolean = true;
  inputError: any = [];
  CurveSelection: any;
  CurveSelectionVal: any;
  ngModelval: any = [];
  restoreText = restore;
  userSpecified: boolean = false;
  curveSurveyValues = {};
  dimLevelColumn = [];
  outputVoltageColumn = [];
  quickConfigvalue;
  quickConfigCustomCSS;
  setQuickConfig;
  fileResponse: any;
  isEdited: any;
  rsoDefaultColor;
  rsoCreateFile: boolean;
  form: FormGroup;
  unsubscribe = new Subject<any>();
  constructor(
    private service: ApiService,
    private ref: ChangeDetectorRef,
    private fb: FormBuilder
  ) {
    //super();
  }

  ngOnInit() {
    this.form = this.fb.group({
      rsoVal: new FormControl("", [Validators.required]),
    });
    this.isEdited = false;
    this.formatVersion = this.field.featureVersion;
    this.column = this.field.requiredProperties;
    this.properties = this.field.properties;
    this.service.getrsoDefaultValue.subscribe((response) => {
      if (response == "valid") {
        this.rsoDefaultColor = true;
        this.resetDefaultrsoValues();
      }
    });
    this.service.getrsoData.subscribe((data) => {
      if (data) {
        this.ngModelval = data;
        this.checkdfault();
        this.checkInvalidValues();
      }
    });
    this.ngModelval["Enabled"] = this.properties.Enabled.default;
    if (this.properties.Enabled.default === true) {
      this.form.controls["rsoVal"].setValue("1");
      this.ngModelval["Enabled"] = "1";
    } else {
      this.form.controls["rsoVal"].setValue("0");
      this.ngModelval["Enabled"] = "0";
    }
    let aaa = JSON.parse(localStorage.getItem("saveSetData"));
    let unSelectedFeat = Array(
      JSON.parse(localStorage.getItem("UnSelectedFeatConfig"))
    );
    var isConfigEdited = false;
    let featIndex: number = 0;
    if (unSelectedFeat[0] != null) {
      unSelectedFeat[0].forEach((element) => {
        if (element["featureName"].toString().toUpperCase() === "RSO") {
          isConfigEdited = true;
          this.fileResponse = {};
          let rsoUnSelectedVal: any = element["featureParameters"][0];
          this.addrsoLoadedFeatureReadValues(rsoUnSelectedVal);
          unSelectedFeat[0].splice(featIndex, 1);
          localStorage.setItem(
            "UnSelectedFeatConfig",
            JSON.stringify(unSelectedFeat[0])
          );
        } else {
          featIndex++;
        }
      });
    }

    if (isConfigEdited) {


    } else {
      // setTimeout(() => {
        this.fileResponse = JSON.parse(localStorage.getItem("configurationData"));
        if (this.fileResponse) {
          if (this.fileResponse.resp && this.fileResponse.resp.RSO) {
            if (this.fileResponse.resp.RSO)
              this.addrsoLoadedFeatureReadValues(this.fileResponse.resp.RSO);
          }
        }
      // }, 1000);
    }
    this.service.getquickConfig.subscribe((data) => {
      if (data === "quickConfig") {
        this.quickConfigvalue = true;
        this.quickConfigCustomCSS = "quick-custom-inputbox";
        this.setQuickConfig = "quickConfig";
      } else {
        this.quickConfigvalue = false;
        this.quickConfigCustomCSS = "custom-inputbox";
        this.setQuickConfig = "standard";
      }
      setTimeout(() => {
        if (this.ref && !(this.ref as ViewRef).destroyed) {
          this.ref.detectChanges();
        }
      });
    });
    setTimeout(() => {
      this.sendrsodata(this.ngModelval);
      this.isEdited = true;
    }, 1000);
  }

  resetDefaultrsoValues() {
    this.ngModelval["Enabled"] = this.properties.Enabled.default;
    if (this.properties.Enabled.default === true) {
      this.form.controls["rsoVal"].setValue("1");
      this.ngModelval["Enabled"] = "1";
    } else {
      this.form.controls["rsoVal"].setValue("0");
      this.ngModelval["Enabled"] = "0";
    }
    for (var type in this.inputError) {
      this.inputError[type] = false;
    }
    this.rsoCreateFile = false;
    this.service.rsoInputError(this.rsoCreateFile);
    this.sendrsodata(this.ngModelval);
  }
  sendrsodata(val) {
    this.service.sendrsodata(val);
    if (this.isEdited) {
      this.setConfigurationData(this.ngModelval, "RSO");
    }
  }
  setConfigurationData(value, keyVal) {
    if (!isNullOrUndefined(value)) {
      let rsoObj = {};
      var key = keyVal;
      var data = {};
      var addNew = {
        [keyVal]: {},
      };
      data = JSON.parse(localStorage.getItem("configurationData"));
      if (data && !isNullOrUndefined(data["resp"])) {
        rsoObj = Object.assign({}, value);
        data["resp"][keyVal] = rsoObj;
      } else {
        data["resp"] = addNew;
        rsoObj = Object.assign({}, value);
        data["resp"][keyVal] = rsoObj;
      }
      localStorage.setItem("configurationData", JSON.stringify(data));
    }
  }
  checkdfault() {
    this.rsoDefaultColor = true;
    this.properties = this.field.properties;
    if (this.ngModelval["Enabled"] === "1") {
      this.ngModelval["Enabled"] = "1";
    } else {
      this.ngModelval["Enabled"] = "0";
    }
    for (var type in this.ngModelval) {
      if (this.ngModelval[type] != this.properties[type]["default"]) {
        this.rsoDefaultColor = false;
      }
    }
    this.service.rsoEditedVal(this.rsoDefaultColor);
  }
  checkInvalidValues() {
    this.rsoCreateFile = false;
    for (var type in this.inputError) {
      if (this.inputError[type]) {
        this.rsoCreateFile = true;
      }
    }
    this.service.rsoInputError(this.rsoCreateFile);
  }

  addrsoLoadedFeatureReadValues(rso: any) {
    let rsoObj = rso;
    var keys = Object.keys(rsoObj);
    var values = Object.values(rsoObj);
    var result = [];
    keys.forEach((key, i) => (result[key] = values[i]));
    this.ngModelval = result;
    this.ngModelval["Enabled"] = result["Enabled"];
    if (this.ngModelval["Enabled"] === "1" || this.ngModelval["Enabled"] === true) {
      this.form.controls["rsoVal"].setValue("1");
      this.ngModelval["Enabled"] = "1";
    } else {
      this.form.controls["rsoVal"].setValue("0");
      this.ngModelval["Enabled"] = "0";
    }
    this.checkdfault();
    this.checkInvalidValues();
    this.sendrsodata(this.ngModelval);
  }
  handleChange(eventVal) {
    this.ngModelval["Enabled"] = eventVal.target.value;
    for (var type in this.inputError) {
      this.inputError[type] = false;
    }
    this.rsoCreateFile = false;
    this.service.rsoInputError(this.rsoCreateFile);
    this.sendrsodata(this.ngModelval);
  }

  ngOnDestroy() {
    this.unsubscribe.next();
  }
}
