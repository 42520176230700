import { Component, OnInit } from "@angular/core";
import * as d3 from "d3";
import { ApiService } from "src/app/core-module/services/api.service";
@Component({
  selector: "app-apm-dim-graph",
  templateUrl: "./apm-dim-graph.component.html",
  styleUrls: ["./apm-dim-graph.component.scss"],
})
export class ApmDimGraphComponent implements OnInit {
  inputXRange = [70, 277];

  inputYRange = [0, 100];

  data: any[] = [
    { dyanXTime: 70, dyanYOutput: 50 },
    { dyanXTime: 110, dyanYOutput: 50 },
    { dyanXTime: 120, dyanYOutput: 90 },
    { dyanXTime: 277, dyanYOutput: 90 },
  ];
  data1: any[] = [];
  dataFromTableValues: any[] = [];

  focus;
  drag;
  public margin = { top: 50, right: 50, bottom: 50, left: 50 };
  public width: number;
  public height: number;
  public x: any;
  public y: any;
  public svg3: any;
  public line: d3.Line<[number, number]>; // this is line defination
  public area: d3.area<[number, number]>; // this is area defination
  public id = "lineChart";
  public color2 = "#00b36b";
  newData: any;
  changedPoint: any;
  feedMaxValue: any = 112;
  NormalLevelMaximum: any;
  BackgroundLevelMaximum: any;
  Maxdata: any = [];
  Tmaxstart: any;
  TmaxEnd: any;
  TmaxShutDown: any;
  scrolPoint: any;
  deRatingdyanYOutput: any;
  LamOffValue: any;
  DtlDimStopValue: any;
  TmaxdeRatingDim: any;
  Forbidenarea: d3.area<[number, number]>; // this is area defination
  deratingLabelYaxis: number;
  TminEnd: any;
  MaxLimit: any;
  TmindeRatingDim: any;
  graphEnabled: boolean;
  philipsLEDChecked: boolean;

  constructor(private apiService: ApiService) {}

  ngOnInit() {
    // this.apiService.getDynaTimeReferencedMode.subscribe(response => {

    //   if(response===true)
    //   {
    //     this.apiService.getFinalDynaDimmerData.subscribe(response=>{
    //       this.addDynaDetails(response);

    //     });
    //   }
    // })
    this.apiService.getAmpDimData.subscribe((response) => {
      this.data = this.addAmpDimData(response);

      this.buildSvg();
      this.addXandYAxis();
      this.drawLineAndPath();
      this.addDrag();
    });

    this.newData = this.data;

    this.buildSvg();
    this.addXandYAxis();
    this.drawLineAndPath();
    this.addDrag();
  }
  addAmpDimData(response: any): any[] {
    //let dataFromTable = JSON.parse(response);
    let isNoError: boolean = true;
    this.apiService.getampdimError.subscribe((response) => {
      if (response === false) {
        isNoError = true;
      }
    });
    if (isNoError) {
      let dimStopLevelCurrent =
        response.StopDimLevelValue > response.StopDimLevelMax
          ? response.StopDimLevelMax
          : response.StopDimLevelValue;
      let dimStartLevelCurrent =
        response.StartDimLevelValue > response.StartDimLevelMax
          ? response.StartDimLevelMax
          : response.StartDimLevelValue;

      let vMainsStopLevelCurrent =
        response.VmainsStopValue > response.VmainsStopMax
          ? response.VmainsStopMax
          : response.VmainsStopValue;
      let vMainsStartLevelCurrent =
        response.VmainsStartValue > response.VmainsStartMax
          ? response.VmainsStartMax
          : response.VmainsStartValue;

      const data2 = [
        { dyanXTime: 70, dyanYOutput: dimStopLevelCurrent },
        { dyanXTime: vMainsStopLevelCurrent, dyanYOutput: dimStopLevelCurrent },
        {
          dyanXTime: vMainsStartLevelCurrent,
          dyanYOutput: dimStartLevelCurrent,
        },
        {
          dyanXTime: response.VmainsStartMax,
          dyanYOutput: dimStartLevelCurrent,
        },
      ];
      return data2;
    }
  }

  public buildSvg() {
    this.svg3 = d3.select("#svg8");
    this.width = 960 - this.margin.left - this.margin.right;
    this.height = 500 - this.margin.top - this.margin.bottom;
  }
  public addXandYAxis() {
    // range of data configuring
    this.x = d3.scaleLinear().range([0, this.width]);
    this.y = d3.scaleLinear().range([this.height, 0]);
    this.x.domain(this.inputXRange);
    this.y.domain(this.inputYRange);
  }

  public drawLineAndPath() {
    this.line = d3
      .line()
      .x((d: any) => this.x(d.dyanXTime))
      .y((d: any) => this.y(d.dyanYOutput));
    d3.selectAll("#svg8 > *").remove();
    this.area = d3
      .area()
      .x((d: any) => this.x(d.dyanXTime))
      .y0(this.height)
      .y1((d: any) => this.y(d.dyanYOutput));

    this.focus = this.svg3
      .append("g")
      .attr(
        "transform",
        "translate(" + this.margin.left + "," + this.margin.top + ")"
      );
    //ploting Area
    this.focus
      .append("path")
      .datum(this.data)
      .attr("fill", "#1ECBD0")
      .attr("fill-opacity", 0.3)
      .attr("stroke", "#1ECBD0")
      .attr("stroke-width", "1px")
      .attr("fill", "url('#gradient2')")
      .attr("d", this.area);

    //ploting circle
    this.focus
      .selectAll("circle")
      .data(this.data)
      .enter()
      .append("circle")
      .attr("r", 6.0)
      .attr("cx", (d: any) => this.x(d.dyanXTime))
      .attr("cy", (d: any) => this.y(d.dyanYOutput))
      .style("cursor", "pointer")
      .style("fill", "#1ECBD0");

    const maxY = d3.max(this.data, function (d) {
      return d.dyanYOutput;
    });
    // Add Gradient

    this.svg3
      .append("linearGradient")
      .attr("id", "gradient2")
      .attr("gradientUnits", "userSpaceOnUse")
      .attr("x1", 0)
      .attr("y1", this.y(0))
      .attr("x2", 0)
      .attr("y2", this.y(maxY))
      .selectAll("stop")
      .data([
        {
          offset: "10%",
          color: "transparent",
        },
        {
          offset: "30%",
          color: "#00E487",
        },
        {
          offset: "100%",
          color: this.color2,
        },
      ])
      .enter()
      .append("stop")
      .attr("offset", function (d) {
        return d.offset;
      })
      .attr("stop-color", function (d) {
        return d.color;
      });

    this.focus
      .append("g")
      .attr("class", "axis axis--x")
      .style("color", "white")
      .attr("transform", "translate(0," + this.height + ")");
    //.call(d3.axisBottom(this.x));
    // text label for the x axis
    this.svg3
      .append("text")
      /*.attr("transform",
        "translate(" + ((this.width / 2) + 20) + " ," +
        (this.height + this.margin.top + 40) + ")")*/
      .attr("x", this.x(180))
      .attr("y", this.y(-20))
      .style("text-anchor", "middle")
      .style("font", "16px Signify-Relative-Bold")
      .html("Mains (V)");

    // Configure the Y Axis
    this.focus
      .append("g")
      .attr("class", "axis axis--y")
      .call(d3.axisLeft(this.y));

    // text label for the y axis
    this.svg3
      .append("text")
      .attr("transform", "rotate(-90)")
      .attr("y", 0 - this.margin.left + 62)
      .attr("x", 0 - this.height / 2)
      .style("text-anchor", "middle")
      .style("font", "16px Signify-Relative-Bold")
      .text("Light level (%)");

    // add the X gridlines
    this.focus
      .append("g")
      // .attr("class", "grid")
      .attr("transform", "translate(0," + this.height + ")")
      .call(
        this.make_x_gridlines().tickSize(-this.height)

        //(d,i)=>[0,1, 2, 3, 4, 5, 6,7 ,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24][i])
      );

    // add the Y gridlines
    this.focus
      .append("g")
      // .attr("class", "grid")

      .call(this.make_y_gridlines().tickSize(-this.width).tickFormat(""));
  }

  // gridlines in x axis function
  make_x_gridlines() {
    return d3
      .axisBottom(this.x)
      .ticks(21)
      .tickFormat(
        (d, i) =>
          [
            70, 80, 90, 100, 110, 120, 130, 140, 150, 160, 170, 180, 190, 200,
            210, 220, 230, 240, 250, 260, 270, 277,
          ][i]
      );

    //tickFormat(x=>`(${x.toFixed(1)})`);
  }
  // gridlines in y axis function
  make_y_gridlines() {
    return d3.axisLeft(this.y).ticks(10);
  }
  public addDrag() {
    const _this = this;
    let selection = this.focus.selectAll("circle").call(
      d3
        .drag()
        .on("start", function (event, d) {
          const control = _this.dragged();
          d["dyanXTime"] = control.x.invert(event.x);
          d["dyanYOutput"] = control.y.invert(event.y);
          const n = selection.nodes();
          const i = n.indexOf(this);
          const m = d3.select(n[i]).node();
          d3.select(m)
            .raise()
            .attr("cx", control.x(d.dyanXTime))
            .attr("cy", control.y(d.dyanYOutput));
          control.focus.select("path").attr("d", control.line);
        })
        .on("drag", function (event, d) {
          const control = _this.dragged();
          const n = selection.nodes();
          const i = n.indexOf(this);
          if (i >= 0 && i <= 4) {
            control.changedPoint = i;
            const m = d3.select(n[i]).node();
            if (control.changedPoint == 0) {
              d.dyanXTime = 70;
              d.dyanYOutput = control.y.invert(event.y);
              if (d.dyanYOutput < 10) d.dyanYOutput = 10;
              let twoPointX = control.data[1].dyanXTime;
              let twoPointY = control.data[1].dyanYOutput;
              let threePointX = control.data[2].dyanXTime;
              let threePointY = control.data[2].dyanYOutput;
              if (d.dyanXTime >= threePointX) d.dyanXTime = threePointX;
              if (d.dyanYOutput >= threePointY) d.dyanYOutput = threePointY;
              const drag3Pt = d3.select(n[1]).node();
              d3.select(drag3Pt)
                .attr("cx", control.x(twoPointX))
                .attr("cy", control.y(d.dyanYOutput));
              control.focus.select("path").attr("d", control.area);
              control.focus.select("path").attr("d", control.line);
              control.data[1].dyanXTime = twoPointX;
              control.data[1].dyanYOutput = d.dyanYOutput;
            }
            if (control.changedPoint == 1) {
              d.dyanXTime = control.x.invert(event.x);
              d.dyanYOutput = control.y.invert(event.y);
              if (d.dyanXTime >= 230) d.dyanXTime = 230;
              if (d.dyanXTime <= 70) d.dyanXTime = 70;
              if (d.dyanYOutput >= 100) d.dyanYOutput = 100;
              if (d.dyanYOutput <= 10) d.dyanYOutput = 10;
              let threePointX = control.data[2].dyanXTime;
              let threePointY = control.data[2].dyanYOutput;
              if (d.dyanXTime >= threePointX) d.dyanXTime = threePointX;
              if (d.dyanYOutput >= threePointY) d.dyanYOutput = threePointY;
              const drag1Pt = d3.select(n[0]).node();
              d3.select(drag1Pt)
                .attr("cx", control.x(70))
                .attr("cy", control.y(d.dyanYOutput));
              control.focus.select("path").attr("d", control.area);
              control.focus.select("path").attr("d", control.line);
              control.data[0].dyanYOutput = d.dyanYOutput;
            }
            if (control.changedPoint == 2) {
              d.dyanXTime = control.x.invert(event.x);
              d.dyanYOutput = control.y.invert(event.y);
              if (d.dyanYOutput <= 30) d.dyanYOutput = 30;
              if (d.dyanYOutput >= 100) d.dyanYOutput = 100;
              if (d.dyanXTime > 277) d.dyanXTime = 277;
              let twoPointX = control.data[1].dyanXTime;
              let twoPointY = control.data[1].dyanYOutput;
              if (d.dyanXTime <= twoPointX) d.dyanXTime = twoPointX;
              if (d.dyanYOutput <= twoPointY) d.dyanYOutput = twoPointY;
              const drag3Pt = d3.select(n[3]).node();
              d3.select(drag3Pt)
                .attr("cx", control.x(277))
                .attr("cy", control.y(d.dyanYOutput));
              control.focus.select("path").attr("d", control.area);
              control.focus.select("path").attr("d", control.line);
              control.data[3].dyanYOutput = d.dyanYOutput;
            }
            if (control.changedPoint == 3) {
              d.dyanXTime = 277;
              d.dyanYOutput = control.y.invert(event.y);
              if (d.dyanYOutput <= 30) d.dyanYOutput = 30;
              if (d.dyanYOutput >= 100) d.dyanYOutput = 100;
              if (d.dyanXTime > 277) d.dyanXTime = 277;
              let twoPointX = control.data[1].dyanXTime;
              let twoPointY = control.data[1].dyanYOutput;
              if (d.dyanXTime <= twoPointX) d.dyanXTime = twoPointX;
              if (d.dyanYOutput <= twoPointY) d.dyanYOutput = twoPointY;
              let threePointX = control.data[2].dyanXTime;
              const drag2Pt = d3.select(n[2]).node();
              d3.select(drag2Pt)
                .attr("cx", control.x(threePointX))
                .attr("cy", control.y(d.dyanYOutput));
              control.focus.select("path").attr("d", control.area);
              control.focus.select("path").attr("d", control.line);
              control.data[2].dyanYOutput = d.dyanYOutput;
            }
            d3.select(m)
              .attr("cx", control.x(d.dyanXTime))
              .attr("cy", control.y(d.dyanYOutput));
            control.focus.select("path").attr("d", control.area);
          }
        })
        .on("end", this.dragended.bind(this))
    );
  }

  dragstarted(d) {}

  public dragged() {
    return this;
  }
  public dragended(d) {
    this.apiService.sendAmpDimGraphdata(this.data);
  }
}
