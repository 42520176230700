import { Component, OnInit } from '@angular/core';
 import { HttpClient } from '@angular/common/http';
 
 @Component({
   selector: 'app-token-refresh',
   templateUrl: './token-refresh.component.html',
   styleUrls: ['./token-refresh.component.scss']
 })
 export class TokenRefreshComponent implements OnInit {
 
   constructor(private http: HttpClient) { }
 
   ngOnInit() {
     
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
     
    var url_string=     window.location.href
    var url = new URL(url_string);
    var token = url.searchParams.get("token");

     this.http.get('https://signify-portal-prod.trimm.net/.rest/multione/v1/refresh/'+token).subscribe((resp)=> {
   
   })
   }
 
	 }