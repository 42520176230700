import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  ChangeDetectorRef,
  ViewRef,
  DoCheck,
} from "@angular/core";
import { Options } from "ng5-slider";
import { FieldType } from "@ngx-formly/core";
import { restore } from "./../../../../assets/json/restoreTooltip.en";
import { ApiService } from "src/app/core-module/services/api.service";
import { isNullOrUndefined } from "util";

@Component({
  selector: "app-startuptime",
  templateUrl: "./startuptime.component.html",
  styleUrls: ["./startuptime.component.scss"],
})
export class StartuptimeComponent extends FieldType implements OnInit {
  isEdited: any;
  @Input() field;
  valOptions: Options = {};
  floorValue;
  ceilValue;
  defaultValue;
  enableFeatureCheckbox;
  isResetDisabled;
  manualRefresh: EventEmitter<void> = new EventEmitter<void>();
  sliderValue;
  textboxValue;
  defaultColor = true;
  deviceName;
  isHidden;
  isAocEnabled;
  isEnableFeature;
  inputError = false;
  quickConfigvalue;
  quickConfigCustomCSS;
  setQuickConfig;
  restoreText = restore;
  booleanValue = true;
  fileResponse: any;
  constructor(private apiService: ApiService, private ref: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {
    this.isEdited = false;
    this.apiService.getStartupDefaultValue.subscribe((response) => {
      this.defaultValue = response;
      this.textboxValue = response;
      this.sliderValue = response;
      this.inputError = false;
      let mode = JSON.parse(localStorage.getItem("modes"));
      // if (mode === 'Standard') {
        this.valueChanged(response);
      //}
    });
    this.floorValue = this.field.properties.AdjustableStartupTime.minimum;
    this.ceilValue = this.field.properties.AdjustableStartupTime.maximum;
    this.defaultValue = this.field.properties.AdjustableStartupTime.default;
    if (this.field.properties.length === 1) {
      this.enableFeatureCheckbox = false;
      this.isResetDisabled = true;
    } else {
      this.isResetDisabled = false;
    }
    this.valOptions = {
      floor: this.floorValue,
      ceil: this.ceilValue,
      disabled: false,
    };
    this.textboxValue = this.defaultValue;
    this.sliderValue = this.defaultValue;
    this.fileResponse = JSON.parse(localStorage.getItem("configurationData"));
    let saveData = JSON.parse(localStorage.getItem("saveSetData"));
    if (saveData && saveData["resp"] && saveData["resp"]["Ast"]) {
      this.fileResponse = saveData;
    }
    let unSelectedFeat = Array(
      JSON.parse(localStorage.getItem("UnSelectedFeatConfig"))
    );

    let isConfigEdited = false;
    let featIndex: number = 0;
    if (unSelectedFeat[0] != null) {
      unSelectedFeat[0].forEach((element) => {
        if (!isNullOrUndefined(element) && !isNullOrUndefined(element["featureName"]) && element["featureName"].toString().toUpperCase() === "AST") {
          isConfigEdited = true;
          this.textboxValue =
            element["featureParameters"][0]["AdjustableStartupTime"];
          this.sliderValue = this.textboxValue;
          this.sliderEvent(this.sliderValue);
          unSelectedFeat[0].splice(featIndex, 1);
          localStorage.setItem(
            "UnSelectedFeatConfig",
            JSON.stringify(unSelectedFeat[0])
          );
        } else {
          featIndex++;
        }
      });
    }
    this.fileResponse = JSON.parse(localStorage.getItem("configurationData"));
    if (isConfigEdited) {
    } else if (this.fileResponse["resp"]) {
      if (this.fileResponse["resp"]["AST"]) {
        this.textboxValue =
          this.fileResponse["resp"]["AST"]["AdjustableStartupTime"];
        this.sliderValue = this.textboxValue;
        // this.formControl.setValue(this.textboxValue);
        this.sliderEvent(this.sliderValue);
      } 
      // else if (this.fileResponse["resp"]["Ast"]) {
      //   this.textboxValue =
      //     this.fileResponse["resp"]["Ast"]["AdjustableStartupTime"];
      //   this.sliderValue = this.textboxValue;
      //   this.sliderEvent(this.sliderValue);
      // }
    }
    this.apiService.getquickConfig.subscribe((data) => {
      if (data === "quickConfig") {
        this.quickConfigvalue = true;
        this.quickConfigCustomCSS = "quick-custom-inputbox";
        this.setQuickConfig = "quickConfig";
      } else {
        this.quickConfigvalue = false;
        this.quickConfigCustomCSS = "custom-inputbox";
        this.setQuickConfig = "standard";
      }
      setTimeout(() => {
        if (this.ref && !(this.ref as ViewRef).destroyed) {
          this.ref.detectChanges();
        }
      });
    });
    this.startupSliderClickEvent(this.textboxValue);
    this.isEdited = true;
    // this.apiService.sendStartupValidData("valid");
  }
  setConfigurationData(value, keyVal) {

    let touchDimObj = {};
    var key = keyVal;
    var data = {};
    var addNew = {
      [keyVal]: {},
    };
    data = JSON.parse(localStorage.getItem("configurationData"));
    if (data && !isNullOrUndefined(data["resp"])) {
      //touchDimObj = Object.assign({}, value);
      touchDimObj = Object.assign({ ["AdjustableStartupTime"]: value });

      data["resp"][keyVal] = touchDimObj;
    } else {
      data["resp"] = addNew;
      touchDimObj = Object.assign({ ["AdjustableStartupTime"]: value });
  
      data["resp"][keyVal] = touchDimObj;
    }

    localStorage.setItem("configurationData", JSON.stringify(data));
  }
  startupSliderClickEvent(val) {
    this.apiService.startupSliderClickEvent(val);
    if (this.isEdited) {
      this.setConfigurationData(val, "AST");
    }
  }
  sliderEvent(val) {
  
    this.textboxValue = val;
    this.startupSliderClickEvent(val);
    if (
      this.defaultValue === Number(this.sliderValue) &&
      this.defaultValue === this.textboxValue
    ) {
      this.defaultColor = true;
    } else {
      this.defaultColor = false;
    }
    if (val < this.floorValue || val > this.ceilValue) {
      this.inputError = true;
      this.apiService.sendStartupValidData("invalid");
    } else {
      this.inputError = false;
      this.apiService.sendStartupValidData("valid");
    }
    this.apiService.setStartupDefaultColor(this.defaultColor);
  }

  valueChanged(value) {
    if (value < this.floorValue || value > this.ceilValue) {
      this.inputError = true;
      this.apiService.sendStartupValidData("invalid");
    } else {
      this.inputError = false;
      this.apiService.sendStartupValidData("valid");
      this.sliderValue = Number(this.textboxValue);
    }
    this.textboxValue = value;
    if (this.field.properties.length === 1) {
      this.defaultColor = true;
    } else {
      this.defaultColor = false;
    }
    if (this.textboxValue == this.defaultValue) {
      this.defaultColor = true;
    }
    this.startupSliderClickEvent(this.textboxValue);
    this.apiService.setStartupDefaultColor(this.defaultColor);
  }

  restoreDefault() {
    this.sliderValue = this.defaultValue;
    this.textboxValue = this.defaultValue;
    this.inputError = false;
    this.apiService.sendStartupValidData("valid");
    this.defaultColor = true;
    if (this.field.properties.length === 1) {
      this.isResetDisabled = true;
    } else {
      this.isResetDisabled = false;
    }
    this.startupSliderClickEvent(this.textboxValue);
    this.apiService.setStartupDefaultColor(this.defaultColor);
  }

  sliderValueChange(value) {
    this.sliderEvent(value);
  }
}
