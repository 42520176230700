import {
  Component,
  OnInit,
  Input,
  ChangeDetectorRef,
  ViewRef,
  OnDestroy,
} from "@angular/core";
import { FieldType } from "@ngx-formly/core";
import { ApiService } from "src/app/core-module/services/api.service";
import { isNullOrUndefined } from "util";

@Component({
  selector: "app-min-dim-level",
  templateUrl: "./min-dim-level.component.html",
  styleUrls: ["./min-dim-level.component.scss"],
})
export class MinDimLevelComponent
  extends FieldType
  implements OnInit, OnDestroy
{
  @Input() field;
  column: any;
  properties: any;
  MinDimLevel: any;
  MinDimLevelMin: any;
  MinDimLevelMax: any;
  MinDimLevelOption: { floor: any; ceil: any };
  ngModelval: any = [];
  MinDimLevelVal: number;
  minDimLevelEnabledval: any;
  minDimLevelDefaultColor: boolean;
  minDimdisbaled: any;
  quickConfigvalue: boolean;
  quickConfigCustomCSS: string;
  setQuickConfig: string;
  inputError: any = [];
  minDimLevelCreatFile: boolean;
  sliderDisabled: boolean;
  fileResponse: any;
  isEdited: any;
  constructor(private service: ApiService, private ref: ChangeDetectorRef) {
    super();
  }
  ngOnInit() {
    this.isEdited = false;
    this.service.getminDimLevelDefaultColor.subscribe((response) => {
      if (response) {
        this.resetDefaultminDimLevel();
      }
    });
    this.service.getMinDimLevelData.subscribe((data) => {
      if (data) {
        this.ngModelval = data;
        this.ngModelval["Enabled"] = data["Enabled"];
        this.ngModelval["MinDimLevelVal"] = data["MinDimLevelVal"];
        this.checkdfault();
      }
    });
    this.column = this.field.requiredProperties;
    this.properties = this.field.properties;
    this.service.minDimLevelInputError(false);
    this.MinDimLevel = this.properties.MinDimLevel;
    this.MinDimLevelMin = this.MinDimLevel.minimum;
    this.MinDimLevelMax = this.MinDimLevel.maximum;
    this.ngModelval["MinDimLevelVal"] = this.MinDimLevelVal =
      this.MinDimLevel.default;
    this.MinDimLevelOption = {
      floor: this.MinDimLevelMin,
      ceil: this.MinDimLevelMax,
    };
    if (this.properties["Enabled"]) {
      this.minDimLevelEnabledval = this.properties["Enabled"].default;
      this.ngModelval["Enabled"] = this.properties["Enabled"].default;
      this.rSetChanged(this.minDimLevelEnabledval);
    }
    this.service.getquickConfig.subscribe((data) => {
      if (data === "quickConfig") {
        this.quickConfigvalue = true;
        this.quickConfigCustomCSS = "quick-custom-inputbox textBoxUnit";
        this.setQuickConfig = "quickConfig";
      } else {
        this.quickConfigvalue = false;
        this.quickConfigCustomCSS = "custom-inputbox";
        this.setQuickConfig = "standard";
      }
      setTimeout(() => {
        if (this.ref && !(this.ref as ViewRef).destroyed) {
          this.ref.detectChanges();
        }
      });
    });
    let unSelectedFeat = Array(
      JSON.parse(localStorage.getItem("UnSelectedFeatConfig"))
    );
    let isConfigEdited = false;
    let featIndex: number = 0;
    if (unSelectedFeat[0] != null) {
      unSelectedFeat[0].forEach((element) => {
        if (element["featureName"].toString().toUpperCase() === "MINDIMLEVEL") {
          isConfigEdited = true;
          let MINDIMLEVEL: any = element["featureParameters"][0];
          this.addMinDimLevelLoadedFeatureReadValues(MINDIMLEVEL);
          unSelectedFeat[0].splice(featIndex, 1);
          localStorage.setItem(
            "UnSelectedFeatConfig",
            JSON.stringify(unSelectedFeat[0])
          );
        } else {
          featIndex++;
        }
      });
    }
    setTimeout(() => {
      this.fileResponse = JSON.parse(localStorage.getItem("configurationData"));
      if (this.fileResponse && !isConfigEdited) {
        if (this.fileResponse.resp && this.fileResponse.resp.MinDimLevel) {
          this.addMinDimLevelLoadedFeatureReadValues(
            this.fileResponse.resp.MinDimLevel
          );
        }
      }
    }, 2500);
    this.sendMinDimLeveldata(this.ngModelval);
    this.isEdited = true;
  }
  sendMinDimLeveldata(val) {
    this.service.sendMinDimLeveldata(val);
    if (this.isEdited) {
      this.setConfigurationData(this.ngModelval, "MinDimLevel");
    }
  }
  setConfigurationData(value, keyVal) {
    let touchDimObj = {};
    var key = keyVal;
    var data = {};
    var addNew = {
      [keyVal]: {},
    };
    data = JSON.parse(localStorage.getItem("configurationData"));
    if (data && !isNullOrUndefined(data["resp"])) {
      touchDimObj = Object.assign({}, value);
      data["resp"][keyVal] = touchDimObj;
    } else {
      data["resp"] = addNew;
      touchDimObj = Object.assign({}, value);
      data["resp"][keyVal] = touchDimObj;
    }
    localStorage.setItem("configurationData", JSON.stringify(data));
  }
  addMinDimLevelLoadedFeatureReadValues(MinDimLevel: any) {
    let MinDimLevelObj = MinDimLevel;
    var keys = Object.keys(MinDimLevelObj);
    var values = Object.values(MinDimLevelObj);
    keys.forEach((key, i) => {
      if (key === 'MinDimLevel') {
        this.textValChanged(values[i], 'MinDimLevelVal')
      }
    });
    var result = [];
    keys.forEach((key, i) => (result[key] = values[i]));
    this.sliderDisabled = result["Enabled"] ? false : true;
    this.ngModelval["Enabled"] = this.minDimLevelEnabledval = result["Enabled"];
    this.ngModelval["MinDimLevelVal"] = this.MinDimLevelVal = result[
      "MinDimLevel"
    ]
      ? result["MinDimLevel"]
      : result["MinDimLevelVal"];
    this.checkdfault();
    this.sendMinDimLeveldata(this.ngModelval);
  }
  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  resetDefaultminDimLevel() {
    if (
      this.properties &&
      this.properties["Enabled"] &&
      this.properties["Enabled"].default
    ) {
      this.ngModelval["Enabled"] = this.minDimLevelEnabledval =
        this.properties["Enabled"].default;
    }
    if (this.properties.MinDimLevel) {
      this.ngModelval["MinDimLevelVal"] = this.MinDimLevelVal =
        this.properties.MinDimLevel.default;
    }
    for (var type in this.inputError) {
      this.inputError[type] = false;
    }
    this.minDimdisbaled = false;
    this.sliderDisabled = false;
    this.minDimLevelCreatFile = false;
    this.minDimLevelDefaultColor = true;
    this.service.minDimLevelInputError(this.minDimLevelCreatFile);
    this.service.MinDimLevelEditedVal(this.minDimLevelDefaultColor);
  }
  sliderEvent() {
    this.checkdfault();
  }
  minDimLevelSlideValuechange(value) {
    this.inputError["MinDimLevelVal"] = false;
    if (
      value >= this.MinDimLevelVal &&
      this.inputError["MinDimLevelVal"] === false
    ) {
      this.ngModelval["MinDimLevelVal"] = value;
      this.sendMinDimLeveldata(this.ngModelval);
      this.checkdfault();
    }
    this.minDimLevelCreatFile = false;
    if (value < this.MinDimLevelMin || value > this.MinDimLevelMax) {
      this.inputError['MinDimLevelVal'] = true;
      this.minDimLevelCreatFile = true;
    }

    for (var type in this.inputError) {
      if (this.inputError[type]) {
        this.minDimLevelCreatFile = true;
      }
    }
    this.service.minDimLevelInputError(this.minDimLevelCreatFile);
  }
  textValChanged(value, colName) {
    value = parseInt(value);
    if (isNaN(value)) {
      value = 0;
    }
    this.minDimLevelDefaultColor = false;
    this.inputError[colName] = false;
    this.minDimLevelCreatFile = false;
    if (value < this.MinDimLevelMin || value > this.MinDimLevelMax) {
      this.inputError[colName] = true;
      this.minDimLevelCreatFile = true;
    } else {
      this.MinDimLevelVal = value;
    }
    this.checkdfault();
    for (var type in this.inputError) {
      if (this.inputError[type]) {
        this.minDimLevelCreatFile = true;
      }
    }
    this.service.MinDimLevelEditedVal(this.minDimLevelDefaultColor);
    this.service.minDimLevelInputError(this.minDimLevelCreatFile);
    this.sendMinDimLeveldata(this.ngModelval);
  }
  checkdfault() {
    var newtype;
    this.minDimLevelDefaultColor = true;
    for (var type in this.ngModelval) {
      newtype = type.replace("Val", "");
      if (!isNullOrUndefined(this.properties && this.properties[newtype] && this.properties[newtype]["default"]) && this.ngModelval[type] != this.properties[newtype]["default"]) {
        this.minDimLevelDefaultColor = false;
      } 
      this.service.MinDimLevelEditedVal(this.minDimLevelDefaultColor);
    }
  }
  rSetChanged(val) {
    this.minDimLevelDefaultColor = val;
    for (var i = 0; i < this.column.length; i++) {
      let type = this.column[i];
      if (this.ngModelval[type] != this.properties[type]["default"]) {
        this.minDimLevelDefaultColor = false;
      }
    }

    this.checkdfault();
    this.minDimdisbaled = !val;
    this.sliderDisabled = !val;
    this.service.MinDimLevelEditedVal(this.minDimLevelDefaultColor);
    this.sendMinDimLeveldata(this.ngModelval);
  }
  ngOnDestroy() {
    this.service.setMinDimLevelDefaultColor("");
  }
}
