import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  ChangeDetectorRef,
  ViewRef,
} from "@angular/core";
import { Options } from "ng5-slider";
import { FieldType } from "@ngx-formly/core";
import { restore } from "./../../../../assets/json/restoreTooltip.en";
import { ApiService } from "src/app/core-module/services/api.service";
import { distinctUntilChanged, takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { isNullOrUndefined } from "util";
@Component({
  selector: "app-touch-dim",
  templateUrl: "./touch-dim.component.html",
  styleUrls: ["./touch-dim.component.scss"],
})
export class TouchDimComponent extends FieldType implements OnInit {
  @Input() field;
  valOptions: Options = {};
  floorValue;
  ceilValue;
  defaultValue;
  enableFeatureTouchDimCheckbox: any = false;
  isResetDisabled;
  isSliderClick;
  manualRefresh: EventEmitter<void> = new EventEmitter<void>();
  sliderValue;
  textboxValue;
  defaultColor = true;
  deviceName;
  isHidden;
  isAocEnabled;
  isEnableTouchDimFeature;
  inputError = false;
  quickConfigvalue: boolean;
  quickConfigCustomCSS;
  setQuickConfig;
  restoreText = restore;
  booleanValue = true;
  isToggleLimitEnabled;
  isLastKnownEnabled: any = true;
  sliderDisabled;
  fileResponse: any;
  isEdited: any;
  ngModelval: any = [];
  calculatedPercentageVal = null;
  touchDimObj = {};
  unsubscribe = new Subject<any>();
  constructor(private apiService: ApiService, private ref: ChangeDetectorRef) {
    super();
  }
  ngOnInit() {
    this.isEdited = false;
    this.apiService.getTouchDimDefaultData.subscribe((response) => {
      if (response) {
        this.defaultColor = true;
        this.resetDefaultTouchDim();
      }
    });
    this.isEnableTouchDimFeature =
      this.field.properties.TouchDimEnabled.default;
    if (this.isEnableTouchDimFeature || !this.isEnableTouchDimFeature) {
      this.enableFeatureTouchDimCheckbox = true;
    }
    this.isToggleLimitEnabled =
      this.field.properties.IgnoreToggleLimits.default;
    this.floorValue = this.field.oneOf[1].properties.PowerOnLevel.minimum;
    this.ceilValue = this.field.oneOf[1].properties.PowerOnLevel.maximum;
    this.defaultValue = this.field.oneOf[0].properties.PowerOnLevel.const;
    if (this.defaultValue === 255) {
      this.defaultValue = this.defaultValue - 1;
    }
    if (this.isLastKnownEnabled) {
      this.sliderDisabled = true;
    } else {
      this.sliderDisabled = false;
    }
    this.valOptions = {
      floor: this.floorValue,
      ceil: this.ceilValue,
      disabled: false,
    };
    this.textboxValue = this.isLastKnownEnabled
      ? this.defaultValue
      : this.sliderValue;
    this.sliderValue = this.textboxValue;
    if (this.textboxValue >= this.floorValue && this.inputError != true) {
      var y = (this.textboxValue - 1) / (253 / 3) - 1;
      this.calculatedPercentageVal = Math.round(Math.pow(10, y));
    } else {
      this.calculatedPercentageVal = null;
    }
    this.ngModelval["IgnoreToggleLimits"] = this.isToggleLimitEnabled;
    this.ngModelval["PowerOnLevel"] = this.textboxValue;
    this.ngModelval["TouchDimEnabled"] = this.isEnableTouchDimFeature;
    this.ngModelval["LastKnownEnabled"] = this.isLastKnownEnabled;
    this.apiService.getquickConfig.subscribe((data) => {
      if (data === "quickConfig") {
        this.quickConfigvalue = true;
        this.quickConfigCustomCSS = "quick-custom-inputbox";
        this.setQuickConfig = "quickConfig";
      } else if (data === "standard") {
        this.quickConfigvalue = false;
        this.quickConfigCustomCSS = "custom-inputbox";
        this.setQuickConfig = "standard";
      }
      setTimeout(() => {
        if (this.ref && !(this.ref as ViewRef).destroyed) {
          this.ref.detectChanges();
        }
      });
    });
    this.lastKnownEnabled(this.isLastKnownEnabled);
    this.enableFeature(this.isEnableTouchDimFeature);
    this.toggleLimitEnabled(this.isToggleLimitEnabled);
    this.valueChanged(this.textboxValue);
    this.sliderValueChange(this.textboxValue);
    if (this.isEnableTouchDimFeature === true) {
      this.isResetDisabled = false;
    }
    this.apiService.sendTouchDimInputError(this.inputError);
    this.checkDefault();
    this.apiService.setTouchDimDefaultColor(this.defaultColor);
    this.sendTouchDimData();
    this.isEdited = true;
    let unSelectedFeat = Array(
      JSON.parse(localStorage.getItem("UnSelectedFeatConfig"))
    );
    let isConfigEdited = false;
    let featIndex: number = 0;
    if (unSelectedFeat[0] != null) {
      unSelectedFeat[0].forEach((element) => {
        if (element["featureName"].toString().toUpperCase() === "TOUCHDIM") {
          isConfigEdited = true;
          let touchDimRead: any = element["featureParameters"][0];
          this.addTouchDimLoadedFeatureReadValues(touchDimRead);
          unSelectedFeat[0].splice(featIndex, 1);
          localStorage.setItem(
            "UnSelectedFeatConfig",
            JSON.stringify(unSelectedFeat[0])
          );
        } else {
          featIndex++;
        }
      });
    }
    if (isConfigEdited === false) {
      setTimeout(() => {
        const touchDimLoaddedFetaure = JSON.parse(
          localStorage.getItem("configurationData")
        );
        if (touchDimLoaddedFetaure) {
          if (
            touchDimLoaddedFetaure.resp &&
            touchDimLoaddedFetaure.resp.TouchDim
          ) {
            if (touchDimLoaddedFetaure.resp.TouchDim) {
              this.addTouchDimLoadedFeatureReadValues(
                touchDimLoaddedFetaure.resp.TouchDim
              );
            }
          }
        }
      }, 100);
    }
  }
  addTouchDimLoadedFeatureReadValues(TouchDim: any) {
    let lastKnownEnabledCheck = false;
    let touchDimObj = TouchDim;
    var keys = Object.keys(touchDimObj);
    var values = Object.values(touchDimObj);
    var result = [];
    keys.forEach((key, i) => (result[key] = values[i]));
    this.ngModelval = result;
    let powerOnLeveVal = result["PowerOnLevel"];
    this.ngModelval["IgnoreToggleLimits"] = result["IgnoreToggleLimits"];
    this.isToggleLimitEnabled = result["IgnoreToggleLimits"];
    if (
      result["PowerOnLevel"] ===
      this.field.oneOf[0].properties.PowerOnLevel.const
    ) {
      lastKnownEnabledCheck = true;
      powerOnLeveVal = this.field.oneOf[1].properties.PowerOnLevel.maximum;
    }
    let loadedFF = JSON.parse(localStorage.getItem("loadffflow"));
    let readDC = JSON.parse(localStorage.getItem("reading"));
    let readingDevice = JSON.parse(localStorage.getItem("readingDevice"));
    let isWriting = JSON.parse(localStorage.getItem("isWriting"));
    if (
      loadedFF === true ||
      readDC === true ||
      readingDevice === true ||
      isWriting === true
    ) {
      this.ngModelval["LastKnownEnabled"] = lastKnownEnabledCheck;
      this.isLastKnownEnabled = lastKnownEnabledCheck;
    } else if (
      result["PowerOnLevel"] ===
      this.field.oneOf[0].properties.PowerOnLevel.const
    ) {
      this.ngModelval["LastKnownEnabled"] = this.isLastKnownEnabled = true;
    } else {
      this.ngModelval["LastKnownEnabled"] = this.isLastKnownEnabled =
        result["LastKnownEnabled"];
    }
    this.lastKnownEnabled(this.isLastKnownEnabled);
    this.ngModelval["PowerOnLevel"] = powerOnLeveVal;
    this.textboxValue = powerOnLeveVal;
    this.ngModelval["TouchDimEnabled"] = result["TouchDimEnabled"];
    this.isEnableTouchDimFeature = result["TouchDimEnabled"];
    this.enableFeature(this.isEnableTouchDimFeature);
    this.toggleLimitEnabled(this.isToggleLimitEnabled);
    this.valueChanged(powerOnLeveVal);
    this.sliderValueChange(powerOnLeveVal);
    this.apiService.sendTouchDimInputError(this.inputError);
    if (result["TouchDimEnabled"] === true) {
      this.isResetDisabled = false;
    }
    this.checkDefault();
    this.apiService.setTouchDimDefaultColor(this.defaultColor);
  }

  sliderEvent(val) {
    this.defaultColor = false;
    this.inputError = false;
    if (
      isNaN(val) ||
      val.length <= 0 ||
      val < this.floorValue ||
      val > this.ceilValue
    ) {
      this.inputError = true;
      this.textboxValue = val;
      this.sliderValue = Number(this.textboxValue);
      this.apiService.sendTouchDimInputError(this.inputError);
    } else {
      this.inputError = false;
      this.apiService.sendTouchDimInputError(this.inputError);
      this.textboxValue = val;
      this.sliderValue = this.textboxValue;
    }
    if (this.textboxValue >= this.floorValue && this.inputError != true) {
      var y = (this.textboxValue - 1) / (253 / 3) - 1;
      this.calculatedPercentageVal = Math.round(Math.pow(10, y));
    } else {
      this.calculatedPercentageVal = null;
    }
    this.ngModelval["PowerOnLevel"] = this.textboxValue;
    this.checkDefault();
    this.sendTouchDimData();
  }

  valueChanged(value) {
    this.defaultColor = false;
    this.inputError = false;
    if (
      isNaN(value) ||
      value.length <= 0 ||
      value < this.floorValue ||
      value > this.ceilValue
    ) {
      this.inputError = true;
      this.textboxValue = value;
      if (value.length <= 0) {
        this.sliderValue = Number(0);
      }
      this.apiService.sendTouchDimInputError(this.inputError);
    } else {
      this.inputError = false;
      this.apiService.sendTouchDimInputError(this.inputError);
      this.textboxValue = value;
      this.sliderValue = this.textboxValue;
    }
    if (this.textboxValue >= this.floorValue && this.inputError != true) {
      var y = (this.textboxValue - 1) / (253 / 3) - 1;
      this.calculatedPercentageVal = Math.round(Math.pow(10, y));
    } else {
      this.calculatedPercentageVal = null;
    }
    this.ngModelval["PowerOnLevel"] = this.textboxValue;
    this.checkDefault();
    this.sendTouchDimData();
  }
  sliderValueChange(value) {
    this.sliderEvent(value);
  }
  resetDefaultTouchDim() {
    this.isEnableTouchDimFeature =
      this.field.properties.TouchDimEnabled.default;
    if (this.isEnableTouchDimFeature) {
      this.enableFeatureTouchDimCheckbox = true;
    }
    this.isLastKnownEnabled = true;
    if (this.isLastKnownEnabled) {
      this.sliderDisabled = true;
    } else {
      this.sliderDisabled = false;
    }
    this.isToggleLimitEnabled =
      this.field.properties.IgnoreToggleLimits.default;
    this.floorValue = this.field.oneOf[1].properties.PowerOnLevel.minimum;
    this.ceilValue = this.field.oneOf[1].properties.PowerOnLevel.maximum;
    this.defaultValue = this.field.oneOf[0].properties.PowerOnLevel.const;
    if (this.defaultValue === 255) {
      this.defaultValue = this.defaultValue - 1;
    }
    this.valOptions = {
      floor: this.floorValue,
      ceil: this.ceilValue,
      disabled: false,
    };
    this.textboxValue = this.sliderValue = this.defaultValue;
    this.inputError = false;
    if (this.textboxValue >= this.floorValue) {
      var y = (this.textboxValue - 1) / (253 / 3) - 1;
      this.calculatedPercentageVal = Math.round(Math.pow(10, y));
    } else {
      this.calculatedPercentageVal = null;
    }
    if (this.isLastKnownEnabled)
      this.ngModelval["PowerOnLevel"] =
        this.field.properties.PowerOnLevel.default - 1;
    this.ngModelval["IgnoreToggleLimits"] = this.isToggleLimitEnabled;
    this.ngModelval["TouchDimEnabled"] = this.isEnableTouchDimFeature;
    this.ngModelval["LastKnownEnabled"] = this.isLastKnownEnabled;
    this.isResetDisabled = false;
    this.isSliderClick = false;
    this.apiService.sendTouchDimInputError(this.inputError);
    this.apiService.setTouchDimEditedData(this.ngModelval);
    this.sendTouchDimData();
  }
  setConfigurationData(value, keyVal) {
    let touchDimObj = {};
    var key = keyVal;
    var data = {};
    var addNew = {
      [keyVal]: {},
    };
    data = JSON.parse(localStorage.getItem("configurationData"));
    if (data && !isNullOrUndefined(data["resp"])) {
      touchDimObj = Object.assign({}, value);
      data["resp"][keyVal] = touchDimObj;
    } else {
      data["resp"] = addNew;
      touchDimObj = Object.assign({}, value);
      data["resp"][keyVal] = touchDimObj;
    }
    localStorage.setItem("configurationData", JSON.stringify(data));
  }
  enableFeature(event) {
    if (event) {
      if (this.isLastKnownEnabled) {
        this.sliderDisabled = true;
      } else {
        this.sliderDisabled = false;
      }
    }
    if (event) {
      this.isResetDisabled = false;
    } else {
      this.isResetDisabled = true;
    }
    this.ngModelval["TouchDimEnabled"] = event;
    this.checkDefault();
    this.apiService.setTouchDimDefaultColor(this.defaultColor);
    this.sendTouchDimData();
  }
  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  lastKnownEnabled(event) {
    this.defaultColor = true;
    let powerLevelOneMin = this.field.oneOf[1].properties.PowerOnLevel.minimum;
    let powerLevelOneMax = this.field.oneOf[1].properties.PowerOnLevel.maximum;
    let props = this.field.properties;
    let powerLevelPropDefault = props.PowerOnLevel.default;
    this.isLastKnownEnabled = event;
    if (this.isLastKnownEnabled) {
      this.sliderDisabled = true;
    } else {
      this.sliderDisabled = false;
    }
    if (this.isLastKnownEnabled) {
      if (!(this.ngModelval["PowerOnLevel"] === powerLevelPropDefault - 1)) {
        this.defaultColor = false;
      }
    }
    if (!this.isLastKnownEnabled) {
      if (
        this.ngModelval["PowerOnLevel"] >= powerLevelOneMin &&
        this.ngModelval["PowerOnLevel"] <= powerLevelOneMax
      ) {
        this.defaultColor = false;
      }
    }
    this.ngModelval["LastKnownEnabled"] = event;
    this.checkDefault();
    this.apiService.setTouchDimDefaultColor(this.defaultColor);
    this.sendTouchDimData();
  }
  checkDefault() {
    let powerLevelZero = this.field.oneOf[0].properties.PowerOnLevel.const;
    let powerLevelOneMin = this.field.oneOf[1].properties.PowerOnLevel.minimum;
    let powerLevelOneMax = this.field.oneOf[1].properties.PowerOnLevel.maximum;
    let props = this.field.properties;

    let powerLevelPropDefault = props.PowerOnLevel.default;
    let ignoreLimitPropDefault = props.IgnoreToggleLimits.default;
    let touchEnabledPropDefault = props.TouchDimEnabled.default;

    this.defaultColor = true;
    if (!(this.isToggleLimitEnabled === ignoreLimitPropDefault)) {
      this.defaultColor = false;
    }
    if (!(this.isEnableTouchDimFeature === touchEnabledPropDefault)) {
      this.defaultColor = false;
    }
    if (this.isLastKnownEnabled) {
      if (!(this.textboxValue === powerLevelPropDefault - 1)) {
        this.defaultColor = false;
      }
    }
    if (!this.isLastKnownEnabled) {
      if (
        this.textboxValue >= powerLevelOneMin &&
        this.textboxValue <= powerLevelOneMax
      ) {
        this.defaultColor = false;
      }
    }
    if (!this.isLastKnownEnabled) {
      if (this.inputError) {
        this.defaultColor = false;
      }
    }
  }
  toggleLimitEnabled(event) {
    this.ngModelval["IgnoreToggleLimits"] = event;
    this.checkDefault();
    this.apiService.setTouchDimDefaultColor(this.defaultColor);
    this.sendTouchDimData();
  }
  sendTouchDimData() {
    this.apiService.setTouchDimEditedData(this.ngModelval);
    if (this.isEdited) {
      this.setConfigurationData(this.ngModelval, "TouchDim");
    }
  }
  ngOnDestroy() {
    this.apiService.setTouchDimEditedData("");
  }
}
