import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-read-device-multi-driver-error",
  templateUrl: "./read-device-multi-driver-error.component.html",
  styleUrls: ["./read-device-multi-driver-error.component.scss"],
})
export class ReadDeviceMultiDriverErrorComponent implements OnInit {
  features: any = ["ALO", "AOC", "CLO", "Luminaire info", "Startup time"];
  constructor() {}

  ngOnInit() {}
}
