import { Component, OnInit, Input, ChangeDetectorRef, Output, EventEmitter } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ApiService } from "../core-module/services/api.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

@Component({
  selector: "app-owp-password",
  templateUrl: "./owp-password.component.html",
  styleUrls: ["./owp-password.component.scss"],
})
export class OwpPasswordComponent implements OnInit {
  newPasswordForm: FormGroup;
  column: any;
  tables: any;
  properties: any;
  firstColumn: any[] = [];
  secondColumn: any[] = [];
  verifyPasswordForm: FormGroup;
  ngModelval: any = [];
  feature12Nc = [];
  dataSet = [];
  features = [];
  schemaSet = [];
  verifyPasswordFieldValue: any = {
    verifyPasswordInputBox1: null,
    verifyPasswordInputBox2: null,
    verifyPasswordInputBox3: null,
    verifyPasswordInputBox4: null,
  };
  passwordFieldValue: any = {
    newPasswordInputBox1: null,
    newPasswordInputBox2: null,
    newPasswordInputBox3: null,
    newPasswordInputBox4: null,
  };
  quickConfigCustomCSS;
  setQuickConfig;
  isEdited: any;
  inputError: any = [];
  owpCreateFile: boolean;
  owpDefaultColor;
  submitted: boolean = false;
  resetPass: any = false;
  pathVal: string;
  @Output() sendOwpPwd: EventEmitter<any> = new EventEmitter();
  constructor(
    private activeModal: NgbActiveModal,
    private service: ApiService,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private apiService: ApiService,
    private ref: ChangeDetectorRef
  ) { }
  //@Input() public errorDescription: any='Enter OEM Password';
  @Input() public TitleName: any ='Enter OEM Password';
  @Input() public showCancelButton: boolean = true;
  ngOnInit() {
    this.initNewPasswordForm();
   }

   numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  initNewPasswordForm() {
    this.newPasswordForm = this.fb.group({
      newPasswordInputBox1: [
        "",
        [
          Validators.required,
          Validators.pattern("^[0-9]*$"),
          Validators.minLength(1),
          Validators.maxLength(3),
          Validators.max(256),
        ],
      ],
      newPasswordInputBox2: [
        "",
        [
          Validators.required,
          Validators.pattern("^[0-9]*$"),
          Validators.minLength(1),
          Validators.maxLength(3),
          Validators.max(256),
        ],
      ],
      newPasswordInputBox3: [
        "",
        [
          Validators.required,
          Validators.pattern("^[0-9]*$"),
          Validators.minLength(1),
          Validators.maxLength(3),
          Validators.max(256),
        ],
      ],
      newPasswordInputBox4: [
        "",
        [
          Validators.required,
          Validators.pattern("^[0-9]*$"),
          Validators.minLength(1),
          Validators.maxLength(3),
          Validators.max(256),
        ],
      ],
    });
  }

  onSubmit(event, controlName, formName) {
    if (formName === "newPassword") {
      this.passwordFieldValue[controlName] = event.target.value;
    }
    if (formName === "verify") {
      this.verifyPasswordFieldValue[controlName] = event.target.value;
    }
    var ispasswordFieldValueValid = false;
    for (var type in this.passwordFieldValue) {
      if (this.passwordFieldValue[type] === null) {
        ispasswordFieldValueValid = true;
      }
    }
    var isverifyPasswordFieldValueValid = false;
    for (var type in this.verifyPasswordFieldValue) {
      if (this.verifyPasswordFieldValue[type] === null) {
        isverifyPasswordFieldValueValid = true;
      }
    }
    if (ispasswordFieldValueValid || isverifyPasswordFieldValueValid) {
      return;
    }
    this.submitted = true;
    if (
      this.passwordFieldValue["newPasswordInputBox1"] != "" &&
      this.verifyPasswordFieldValue["verifyPasswordInputBox1"] != "" &&
      this.passwordFieldValue["newPasswordInputBox1"] !=
        this.verifyPasswordFieldValue["verifyPasswordInputBox1"]
    ) {
      this.newPasswordForm.get("newPasswordInputBox1").setErrors({
        incorrect: true,
      });
      this.verifyPasswordForm.get("verifyPasswordInputBox1").setErrors({
        incorrect: true,
      });
    } else {
      this.newPasswordForm.get("newPasswordInputBox1").setErrors(null);
      this.verifyPasswordForm.get("verifyPasswordInputBox1").setErrors(null);
    }
    if (
      this.passwordFieldValue["newPasswordInputBox2"] != "" &&
      this.verifyPasswordFieldValue["verifyPasswordInputBox2"] != "" &&
      this.passwordFieldValue["newPasswordInputBox2"] !=
        this.verifyPasswordFieldValue["verifyPasswordInputBox2"]
    ) {
      this.newPasswordForm.get("newPasswordInputBox2").setErrors({
        incorrect: true,
      });
      this.verifyPasswordForm.get("verifyPasswordInputBox2").setErrors({
        incorrect: true,
      });
    } else {
      this.newPasswordForm.get("newPasswordInputBox2").setErrors(null);
      this.verifyPasswordForm.get("verifyPasswordInputBox2").setErrors(null);
    }
    if (
      this.passwordFieldValue["newPasswordInputBox3"] != "" &&
      this.verifyPasswordFieldValue["verifyPasswordInputBox3"] != "" &&
      this.passwordFieldValue["newPasswordInputBox3"] !=
        this.verifyPasswordFieldValue["verifyPasswordInputBox3"]
    ) {
      this.newPasswordForm.get("newPasswordInputBox3").setErrors({
        incorrect: true,
      });
      this.verifyPasswordForm.get("verifyPasswordInputBox3").setErrors({
        incorrect: true,
      });
    } else {
      this.newPasswordForm.get("newPasswordInputBox3").setErrors(null);
      this.verifyPasswordForm.get("verifyPasswordInputBox3").setErrors(null);
    }
    if (
      this.passwordFieldValue["newPasswordInputBox4"] != "" &&
      this.verifyPasswordFieldValue["verifyPasswordInputBox4"] != "" &&
      this.passwordFieldValue["newPasswordInputBox4"] !=
        this.verifyPasswordFieldValue["verifyPasswordInputBox4"]
    ) {
      this.newPasswordForm.get("newPasswordInputBox4").setErrors({
        incorrect: true,
      });
      this.verifyPasswordForm.get("verifyPasswordInputBox4").setErrors({
        incorrect: true,
      });
    } else {
      this.newPasswordForm.get("newPasswordInputBox4").setErrors(null);
      this.verifyPasswordForm.get("verifyPasswordInputBox4").setErrors(null);
    }
    if (
      parseInt(this.passwordFieldValue["verifyPasswordInputBox1"]) ===
        Number(0) &&
      parseInt(this.passwordFieldValue["verifyPasswordInputBox2"]) ===
        Number(0) &&
      parseInt(this.passwordFieldValue["verifyPasswordInputBox3"]) ===
        Number(0) &&
      parseInt(this.passwordFieldValue["verifyPasswordInputBox4"]) === Number(0)
    ) {
      this.newPasswordForm.get("verifyPasswordInputBox1").setErrors({
        incorrect: true,
      });
      this.newPasswordForm.get("verifyPasswordInputBox2").setErrors({
        incorrect: true,
      });
      this.newPasswordForm.get("verifyPasswordInputBox3").setErrors({
        incorrect: true,
      });
      this.newPasswordForm.get("verifyPasswordInputBox4").setErrors({
        incorrect: true,
      });
    } else {
      this.newPasswordForm.get("verifyPasswordInputBox1").setErrors(null);
      this.newPasswordForm.get("verifyPasswordInputBox2").setErrors(null);
      this.newPasswordForm.get("verifyPasswordInputBox3").setErrors(null);
      this.newPasswordForm.get("verifyPasswordInputBox4").setErrors(null);
    }
    if (
      parseInt(this.passwordFieldValue["verifyPasswordInputBox1"]) ===
        Number(255) &&
      parseInt(this.passwordFieldValue["verifyPasswordInputBox2"]) ===
        Number(255) &&
      parseInt(this.passwordFieldValue["verifyPasswordInputBox3"]) ===
        Number(255) &&
      parseInt(this.passwordFieldValue["verifyPasswordInputBox4"]) ===
        Number(255)
    ) {
      this.newPasswordForm.get("verifyPasswordInputBox1").setErrors({
        incorrect: true,
      });
      this.newPasswordForm.get("verifyPasswordInputBox2").setErrors({
        incorrect: true,
      });
      this.newPasswordForm.get("verifyPasswordInputBox3").setErrors({
        incorrect: true,
      });
      this.newPasswordForm.get("verifyPasswordInputBox4").setErrors({
        incorrect: true,
      });
    } else {
      this.newPasswordForm.get("verifyPasswordInputBox1").setErrors(null);
      this.newPasswordForm.get("verifyPasswordInputBox2").setErrors(null);
      this.newPasswordForm.get("verifyPasswordInputBox3").setErrors(null);
      this.newPasswordForm.get("verifyPasswordInputBox4").setErrors(null);
    }
    if (
      parseInt(this.passwordFieldValue["newPasswordInputBox1"]) ===
        Number(255) &&
      parseInt(this.passwordFieldValue["newPasswordInputBox2"]) ===
        Number(255) &&
      parseInt(this.passwordFieldValue["newPasswordInputBox3"]) ===
        Number(255) &&
      parseInt(this.passwordFieldValue["newPasswordInputBox4"]) === Number(255)
    ) {
      this.newPasswordForm.get("newPasswordInputBox1").setErrors({
        incorrect: true,
      });
      this.newPasswordForm.get("newPasswordInputBox2").setErrors({
        incorrect: true,
      });
      this.newPasswordForm.get("newPasswordInputBox3").setErrors({
        incorrect: true,
      });
      this.newPasswordForm.get("newPasswordInputBox4").setErrors({
        incorrect: true,
      });
    } else {
      this.newPasswordForm.get("newPasswordInputBox1").setErrors(null);
      this.newPasswordForm.get("newPasswordInputBox2").setErrors(null);
      this.newPasswordForm.get("newPasswordInputBox3").setErrors(null);
      this.newPasswordForm.get("newPasswordInputBox4").setErrors(null);
    }
    if (
      parseInt(this.passwordFieldValue["newPasswordInputBox1"]) === Number(0) &&
      parseInt(this.passwordFieldValue["newPasswordInputBox2"]) === Number(0) &&
      parseInt(this.passwordFieldValue["newPasswordInputBox3"]) === Number(0) &&
      parseInt(this.passwordFieldValue["newPasswordInputBox4"]) === Number(0)
    ) {
      this.newPasswordForm.get("newPasswordInputBox1").setErrors({
        incorrect: true,
      });
      this.newPasswordForm.get("newPasswordInputBox2").setErrors({
        incorrect: true,
      });
      this.newPasswordForm.get("newPasswordInputBox3").setErrors({
        incorrect: true,
      });
      this.newPasswordForm.get("newPasswordInputBox4").setErrors({
        incorrect: true,
      });
    } else {
      this.newPasswordForm.get("newPasswordInputBox1").setErrors(null);
      this.newPasswordForm.get("newPasswordInputBox2").setErrors(null);
      this.newPasswordForm.get("newPasswordInputBox3").setErrors(null);
      this.newPasswordForm.get("newPasswordInputBox4").setErrors(null);
    }
    if (this.newPasswordForm.valid && this.verifyPasswordForm.valid) {
      var pass = [];
      for (const [key, value] of Object.entries(
        this.verifyPasswordFieldValue
      )) {
        pass.push(parseInt(this.verifyPasswordFieldValue[key]));
      }
      this.ngModelval["OwpPassword"] = pass;
     // this.apiService.owpInputError(false);
      // this.checkdfault();
      // this.sendowpdata();
    } else {
      //this.ngModelval["OwpPassword"] = [];
     // this.apiService.owpInputError(true);
     // this.checkdfault();
      // this.sendowpdata();
    }
  }

  get f() {
    return this.newPasswordForm.controls;
  }

  sendPassword(){
    this.activeModal.close()
    this.sendOwpPwd.emit(this.passwordFieldValue)
  }
}
