import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  ChangeDetectorRef,
  ViewRef,
} from "@angular/core";
import { FieldType } from "@ngx-formly/core";
import { ApiService } from "src/app/core-module/services/api.service";
import { isNullOrUndefined } from "util";

@Component({
  selector: "app-mtp",
  templateUrl: "./mtp.component.html",
  styleUrls: ["./mtp.component.scss"],
})
export class MtpComponent extends FieldType implements OnInit, OnDestroy {
  @Input() field;
  column: any;
  properties: any;
  ngModelval: any = [];
  inputError: any = [];
  quickConfigvalue: boolean;
  quickConfigCustomCSS: string;
  setQuickConfig: string;
  mtpDefaultColor;
  mtpCreateFile: boolean;
  mtpEnabledval: any;
  selectedInterface = [];
  mtpPhilipsLedLightEnginesSelected: any;
  showFieldsAsPerAllOf: any;
  DimLevel: any;
  DimLevelMin: any;
  DimLevelMax: any;
  DimLevelValue: any;
  DimLevelOption: { floor: any; ceil: any };
  DimStop: any;
  DimStopMin: any;
  DimStopMax: any;
  DimStopValue: any;
  DimStopOption: any;
  DimStart: any;
  DimStartMin: any;
  DimStartMax: any;
  DimStartValue: any;
  DimStartOption: any;
  NtcResistance: any;
  NtcResistanceMin: any;
  NtcResistanceMax: any;
  SelectedNtc: any;
  NtcBeta: any;
  NtcBetaMin: any;
  NtcBetaMax: any;
  PhilipsLedLightEnginesSelected: any;
  isEnabled: boolean;
  isCustomSelected: boolean;
  isDisabledStartStopDim: boolean;
  fileResponse: any;
  tcDeltaValue: any;
  isEdited: any;
  constructor(private service: ApiService, private ref: ChangeDetectorRef) {
    super();
  }
  sliderEvent() {}
  isPhilipsLedLightEnginesSelected(value) {
    this.PhilipsLedLightEnginesSelected = value;
    if (value === true) {
      this.isDisabledStartStopDim = true;
      this.ngModelval["PhilipsLedLightEnginesSelected"] = true;
      this.ngModelval["SelectedNtc"] = this.SelectedNtc =
        "PhilipsLedLightEngines";
      this.ngModelval["DimStartValue"] = this.DimStartValue =
        this.DimStart.propertyDefault;
      this.ngModelval["DimStopValue"] = this.DimStopValue =
        this.DimStop.propertyDefault;
      this.sendMtpdata(this.ngModelval);
    } else {
      this.isDisabledStartStopDim = false;
      this.ngModelval["PhilipsLedLightEnginesSelected"] = false;
      this.ngModelval["SelectedNtc"] = this.SelectedNtc = "Custom";
      this.isCustomSelected = true;
      this.sendMtpdata(this.ngModelval);
    }
  }
  isFeatureEnabled(value) {
    if (value === true) {
      this.isEnabled = false;
      this.service.setMtpDefaultColor(this.mtpDefaultColor);
      this.ngModelval["Enabled"] = value;
      this.sendMtpdata(this.ngModelval);
    } else if (value === false) {
      this.isEnabled = true;
      this.service.setMtpDefaultColor(this.mtpDefaultColor);
      this.ngModelval["Enabled"] = value;
      this.sendMtpdata(this.ngModelval);
    }
  }
  ngOnInit() {
    this.isEdited = false;
    this.service.getMtpGraphData.subscribe((responseGraph) => {
      for (let index = 0; index < responseGraph.length; index++) {
        const element = responseGraph[index];
        if (index === 3) {
          this.DimLevelValue = Math.round(element.dyanYOutput);
        }
        if (index === 2) {
          if (!this.PhilipsLedLightEnginesSelected)
            this.DimStopValue = Math.round(element.dyanXTime);
          this.DimLevelValue = Math.round(element.dyanYOutput);
        }
        if (index === 1) {
          if (!this.PhilipsLedLightEnginesSelected)
            this.DimStartValue = Math.round(element.dyanXTime);
        }
      }
      this.ngModelval["DimLevelValue"] = this.ngModelval["DimLevel"] =
        this.DimLevelValue;
      this.ngModelval["DimStartValue"] = this.ngModelval["DimStart"] =
        this.DimStartValue;
      this.ngModelval["DimStopValue"] = this.ngModelval["DimStop"] =
        this.DimStopValue;
    });
    this.service.getmtpDefaultValue.subscribe((response) => {
      if (response == "valid") {
        this.mtpDefaultColor = true;
        this.resetDefaultMtpValues();
      }
    });
    this.service.getMtpData.subscribe((data) => {
      if (data) {
        this.ngModelval = data;
        this.checkdfault();
        this.checkInvalidValues();
      }
    });
    this.column = this.field.requiredProperties;
    this.properties = this.field.properties;
    this.tcDeltaValue = this.properties.LampOff.TcDelta;
    let mtpStartMax = this.properties.DimStart.maximum;
    let mtpStoptMax = this.properties.DimStop.maximum;
    let mtpDinLevelMax = this.properties.DimLevel.maximum;
    if (this.tcDeltaValue) {
      this.service.setMTPTcDeltaValue(this.tcDeltaValue);
    }
    if (mtpStartMax) {
      this.service.setMTPDimStartMax(mtpStartMax);
    }
    if (mtpStoptMax) {
      this.service.setMTPDimStopMax(mtpStoptMax);
    }
    if (mtpDinLevelMax) {
      this.service.setMTPDimLevelMax(mtpDinLevelMax);
    }
    let type = this.column[0];
    this.service.mtpInputError(false);
    if (this.properties["Enabled"]) {
      this.mtpEnabledval = this.properties["Enabled"].propertyDefault;
      this.ngModelval["Enabled"] = this.properties["Enabled"].propertyDefault;
      if (this.properties["Enabled"].propertyDefault === false) {
        this.isEnabled = true;
      } else {
        this.isEnabled = false;
      }
    }
    if (
      this.properties.SelectedNtc.propertyDefault === "PhilipsLedLightEngines"
    ) {
      this.mtpPhilipsLedLightEnginesSelected = true;
      this.ngModelval["PhilipsLedLightEnginesSelected"] =
        this.isDisabledStartStopDim =
        this.PhilipsLedLightEnginesSelected =
          true;
    } else {
      this.mtpPhilipsLedLightEnginesSelected = false;
      this.ngModelval["PhilipsLedLightEnginesSelected"] =
        this.isDisabledStartStopDim =
        this.PhilipsLedLightEnginesSelected =
          false;
    }
    if (this.properties["SelectedNtc"]) {
      this.selectedInterface = this.properties.SelectedNtc["enum"].filter(
        function (item) {
          return item !== "PhilipsLedLightEngines";
        }
      );
      if (
        this.properties.SelectedNtc.propertyDefault === "PhilipsLedLightEngines"
      ) {
        this.ngModelval["SelectedNtc"] = this.SelectedNtc =
          this.properties.SelectedNtc.propertyDefault;
      } else {
        this.ngModelval["SelectedNtc"] = this.SelectedNtc = "Custom";
        this.isCustomSelected = true;
      }
    }
    if (this.properties.NtcResistance) {
      this.ngModelval["NtcResistance"] = this.NtcResistance =
        this.properties.NtcResistance.propertyDefault;
      this.NtcResistanceMin = this.properties.NtcResistance.minimum;
      this.NtcResistanceMax = this.properties.NtcResistance.maximum;
    }
    if (this.properties.NtcBeta) {
      this.ngModelval["NtcBeta"] = this.NtcBeta =
        this.properties.NtcBeta.propertyDefault;
      this.NtcBetaMin = this.properties.NtcBeta.minimum;
      this.NtcBetaMax = this.properties.NtcBeta.maximum;
    }
    this.DimLevel = this.properties.DimLevel;
    this.DimLevelMin = this.DimLevel.minimum;
    this.DimLevelMax = this.DimLevel.maximum;
    this.ngModelval["DimLevelValue"] =
      this.ngModelval["DimLevel"] =
      this.DimLevelValue =
        this.DimLevel.propertyDefault;
    this.ngModelval["DimLevelValueMax"] = this.DimLevel.maximum;
    this.DimLevelOption = {
      floor: this.DimLevelMin,
      ceil: this.DimLevelMax,
    };
    this.DimStop = this.properties.DimStop;
    this.DimStopMin = this.DimStop.minimum;
    this.DimStopMax = this.DimStop.maximum;
    this.ngModelval["DimStopValue"] =
      this.ngModelval["DimStop"] =
      this.DimStopValue =
        this.DimStop.propertyDefault;
    this.ngModelval["DimStopValueMax"] = this.DimStop.maximum;
    this.DimStopOption = {
      floor: this.DimStopMin,
      ceil: this.DimStopMax,
      minLimit: this.DimStartMin + this.properties.LampOff["TcDelta"],
    };
    this.DimStart = this.properties.DimStart;
    this.DimStartMin = this.DimStart.minimum;
    this.DimStartMax = this.DimStart.maximum;
    this.ngModelval["DimStartValue"] =
      this.ngModelval["DimStart"] =
      this.DimStartValue =
        this.DimStart.propertyDefault;
    this.ngModelval["DimStartValueMax"] = this.DimStart.maximum;
    this.DimStartOption = {
      floor: this.DimStartMin,
      ceil: this.DimStartMax,
    };
    this.showFieldsAsPerAllOf = ["DimStart", "DimStop", "DimLevel"];
    let unSelectedFeat = Array(
      JSON.parse(localStorage.getItem("UnSelectedFeatConfig"))
    );
    let isConfigEdited = false;
    let featIndex: number = 0;
    if (unSelectedFeat[0] != null && unSelectedFeat[0].length > 0) {
      unSelectedFeat[0].forEach((element) => {
        if (element["featureName"].toString().toUpperCase() === "MTP") {
          this.fileResponse = {};
          let mtpUnSlected: any = element["featureParameters"][0];
          let mtpEditedData = {
            resp: {
              Mtp: mtpUnSlected,
            },
          };
          isConfigEdited = true;
          this.fileResponse = mtpEditedData;
          unSelectedFeat[0].splice(featIndex, 1);
          localStorage.setItem(
            "UnSelectedFeatConfig",
            JSON.stringify(unSelectedFeat[0])
          );
          this.addMtpLoadedFeatureReadValues(this.fileResponse.resp.Mtp);
        } else {
          featIndex++;
        }
      });
    }

    if (isConfigEdited) {
    } else {
      setTimeout(() => {
        this.fileResponse = JSON.parse(
          localStorage.getItem("configurationData")
        );
        if (this.fileResponse) {
          if (
            this.fileResponse.resp &&
            (this.fileResponse.resp.Mtp ||
              this.fileResponse.resp.mtp ||
              this.fileResponse.resp.MTP)
          ) {
            if (this.fileResponse.resp.mtp)
              this.addMtpLoadedFeatureReadValues(this.fileResponse.resp.mtp);
            else if (this.fileResponse.resp.Mtp)
              this.addMtpLoadedFeatureReadValues(this.fileResponse.resp.Mtp);
            else if (this.fileResponse.resp.MTP)
              this.addMtpLoadedFeatureReadValues(this.fileResponse.resp.MTP);
          }
        }
      }, 1000);
    }
    this.service.getquickConfig.subscribe((data) => {
      if (data === "quickConfig") {
        this.quickConfigvalue = true;
        this.quickConfigCustomCSS = "quick-custom-inputbox textBoxUnit";
        this.setQuickConfig = "quickConfig";
      } else if (data === "standard") {
        this.quickConfigvalue = false;
        this.quickConfigCustomCSS = "custom-inputbox";
        this.setQuickConfig = "standard";
      }
      setTimeout(() => {
        if (this.ref && !(this.ref as ViewRef).destroyed) {
          this.ref.detectChanges();
        }
      });
    });
    setTimeout(() => {
      this.sendMtpdata(this.ngModelval);
      this.isEdited = true;
    }, 1000);
  }
  sendMtpdata(val) {
    this.service.sendMtpdata(val);
    if (this.isEdited) {
      this.setConfigurationData(this.ngModelval, "MTP");
    }
  }
  addMtpLoadedFeatureReadValues(Mtp: any) {
    let MtpObj = Mtp;
    var keys = Object.keys(MtpObj);
    var values = Object.values(MtpObj);
    keys.forEach((key, i) => {
      this.textValChanged(values[i], key);
    });
    var result = [];
    keys.forEach((key, i) => (result[key] = values[i]));
    this.ngModelval = result;
    this.ngModelval["DimLevelValue"] = this.DimLevelValue = result["DimLevel"]
      ? result["DimLevel"]
      : result["DimLevelValue"];
    this.ngModelval["DimStartValue"] = this.DimStartValue = result["DimStart"]
      ? result["DimStart"]
      : result["DimStartValue"];
    this.ngModelval["DimStopValue"] = this.DimStopValue = result["DimStop"]
      ? result["DimStop"]
      : result["DimStopValue"];
    this.ngModelval["Enabled"] = result["Enabled"];
    if (result["Enabled"] === false) {
      this.isEnabled = true;
    } else {
      this.isEnabled = false;
    }
    this.ngModelval["NtcBeta"] = this.NtcBeta = result["NtcBeta"];
    this.ngModelval["NtcResistance"] = this.NtcResistance =
      result["NtcResistance"];
    this.ngModelval["SelectedNtc"] = this.SelectedNtc = result["SelectedNtc"];
    if (this.SelectedNtc === "Custom" || result["SelectedNtc"] === "Custom") {
      this.isCustomSelected = true;
      this.ngModelval["SelectedNtc"] = this.SelectedNtc = result["SelectedNtc"];
    } else {
      this.ngModelval["SelectedNtc"] = this.SelectedNtc = result["SelectedNtc"];
      this.isCustomSelected = false;
    }
    if (
      this.SelectedNtc === "PhilipsLedLightEngines" ||
      result["SelectedNtc"] === "PhilipsLedLightEngines"
    ) {
      this.mtpPhilipsLedLightEnginesSelected = true;
      this.ngModelval["PhilipsLedLightEnginesSelected"] =
        this.isDisabledStartStopDim =
        this.PhilipsLedLightEnginesSelected =
          true;
    } else {
      this.mtpPhilipsLedLightEnginesSelected = false;
      this.ngModelval["PhilipsLedLightEnginesSelected"] =
        this.isDisabledStartStopDim =
        this.PhilipsLedLightEnginesSelected =
          false;
    }
    this.checkdfault();
    this.checkInvalidValues();
    this.sendMtpdata(this.ngModelval);
  }
  onChangeList(value) {
    if (value === "Custom") {
      this.isCustomSelected = true;
    } else {
      this.isCustomSelected = false;
    }
    this.showFieldsAsPerAllOf = ["DimStart", "DimStop", "DimLevel"];
    this.ngModelval["SelectedNtc"] = value;
    this.sendMtpdata(this.ngModelval);
    this.checkdfault();
    this.checkInvalidValues();
  }
  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  checkInvalidValues() {
    this.mtpCreateFile = false;
    for (var type in this.inputError) {
      if (this.inputError[type]) {
        this.mtpCreateFile = true;
      }
    }
    this.service.mtpInputError(this.mtpCreateFile);
  }

  checkdfault() {
    var newtype;
    this.mtpDefaultColor = true;
    for (var type in this.ngModelval) {
      if (!isNullOrUndefined(this.properties)) {
        if (type == "DimLevelValue") {
          newtype = type.replace("Value", "");
          if (
            this.ngModelval[type] != this.properties[newtype]["propertyDefault"]
          ) {
            this.mtpDefaultColor = false;
          }
        } else if (type == "DimStartValue") {
          newtype = type.replace("Value", "");
          if (
            this.ngModelval[type] != this.properties[newtype]["propertyDefault"]
          ) {
            this.mtpDefaultColor = false;
          }
        } else if (type == "DimStopValue") {
          newtype = type.replace("Value", "");
          if (
            this.ngModelval[type] != this.properties[newtype]["propertyDefault"]
          ) {
            this.mtpDefaultColor = false;
          }
        } else if (
          type == "NtcBeta" ||
          type == "NtcResistance" ||
          type == "Enabled"
        ) {
          if (
            this.ngModelval[type] != this.properties[type]["propertyDefault"]
          ) {
            this.mtpDefaultColor = false;
          }
        } else if (type == "SelectedNtc") {
          if (
            this.ngModelval[type] != this.properties[type]["propertyDefault"]
          ) {
            this.mtpDefaultColor = false;
          }
        }
        this.service.mtpEditedVal(this.mtpDefaultColor);
      }
    }
  }
  resetDefaultMtpValues() {
    this.ngModelval["DimLevelValue"] =
      this.ngModelval["DimLevel"] =
      this.DimLevelValue =
        this.DimLevel.propertyDefault;
    this.ngModelval["DimStartValue"] =
      this.ngModelval["DimStart"] =
      this.DimStartValue =
        this.DimStart.propertyDefault;
    this.ngModelval["DimStopValue"] =
      this.ngModelval["DimStop"] =
      this.DimStopValue =
        this.DimStop.propertyDefault;
    this.ngModelval["Enabled"] = this.properties["Enabled"].propertyDefault;
    this.mtpEnabledval = this.properties["Enabled"].propertyDefault;
    if (this.mtpEnabledval === false) {
      this.isEnabled = true;
    } else {
      this.isEnabled = false;
    }
    this.ngModelval["NtcBeta"] = this.NtcBeta =
      this.properties.NtcBeta.propertyDefault;
    this.ngModelval["NtcResistance"] = this.NtcResistance =
      this.properties.NtcResistance.propertyDefault;
    if (this.properties.SelectedNtc.propertyDefault === "Custom") {
      this.isCustomSelected = true;
      this.ngModelval["SelectedNtc"] = this.SelectedNtc =
        this.properties.SelectedNtc.propertyDefault;
    } else {
      this.ngModelval["SelectedNtc"] = this.SelectedNtc =
        this.properties.SelectedNtc.propertyDefault;
      this.isCustomSelected = false;
    }
    if (
      this.properties.SelectedNtc.propertyDefault === "PhilipsLedLightEngines"
    ) {
      this.mtpPhilipsLedLightEnginesSelected = true;
      this.ngModelval["PhilipsLedLightEnginesSelected"] =
        this.isDisabledStartStopDim =
        this.PhilipsLedLightEnginesSelected =
          true;
    } else {
      this.mtpPhilipsLedLightEnginesSelected = false;
      this.ngModelval["PhilipsLedLightEnginesSelected"] =
        this.isDisabledStartStopDim =
        this.PhilipsLedLightEnginesSelected =
          false;
    }
    for (var type in this.inputError) {
      this.inputError[type] = false;
    }
    this.mtpCreateFile = false;
    this.service.mtpInputError(this.mtpCreateFile);
    this.sendMtpdata(this.ngModelval);
  }
  DimLevelValueChange(value) {
    this.inputError["DimLevelValue"] = false;
    this.inputError["DimLevel"] = false;
    if (
      value >= this.DimLevelMin &&
      this.inputError["DimLevelValue"] === false
    ) {
      this.ngModelval["DimLevelValue"] =
        this.ngModelval["DimLevel"] =
        this.DimLevelValue =
          value;
      this.sendMtpdata(this.ngModelval);
      this.checkdfault();
      this.checkInvalidValues();
    }
  }
  setConfigurationData(value, keyVal) {
    let touchDimObj = {};
    var key = keyVal;
    var data = {};
    var addNew = {
      [keyVal]: {},
    };
    data = JSON.parse(localStorage.getItem("configurationData"));
    if (data && !isNullOrUndefined(data["resp"])) {
      touchDimObj = Object.assign({}, value);
      data["resp"][keyVal] = touchDimObj;
    } else {
      data["resp"] = addNew;
      touchDimObj = Object.assign({}, value);
      data["resp"][keyVal] = touchDimObj;
    }
    localStorage.setItem("configurationData", JSON.stringify(data));
  }
  DimStopValueChange(value) {
    if (value >= this.DimStopMin && value <= this.DimStopMax) {
      this.ngModelval["DimStopValue"] = this.ngModelval["DimStop"] = value;
      this.inputError["DimStop"] = false;
      this.DimStartOption = {
        floor: this.DimStartMin,
        ceil: this.DimStartMax,
      };
      if (
        this.ngModelval["DimStopValue"] - this.tcDeltaValue <=
        this.ngModelval["DimStartValue"]
      ) {
        this.ngModelval["DimStartValue"] =
          this.ngModelval["DimStart"] =
          this.DimStartValue =
            value - this.tcDeltaValue;
        if (
          this.ngModelval["DimStartValue"] < this.DimStartMin ||
          this.ngModelval["DimStartValue"] > this.DimStartMax
        ) {
          this.inputError["DimStart"] = true;
        } else {
          this.inputError["DimStart"] = false;
        }
      }
      if (
        this.ngModelval["DimStartValue"] > this.DimStartMin &&
        this.ngModelval["DimStartValue"] < this.DimStartMax &&
        this.ngModelval["DimStopValue"] - this.tcDeltaValue >=
          this.ngModelval["DimStartValue"]
      ) {
        this.inputError["DimStart"] = false;
      }
      if (
        this.ngModelval["DimStartValue"] < this.DimStartMin ||
        this.ngModelval["DimStartValue"] > this.DimStartMax
      ) {
        this.inputError["DimStart"] = true;
      }
      this.checkInvalidValues();
      this.sendMtpdata(this.ngModelval);
    } else {
      this.inputError["DimStop"] = true;
      this.mtpCreateFile = true;
    }
  }
  DimStartValueChange(value) {
    if (value >= this.DimStartMin && value <= this.DimStartMax) {
      this.ngModelval["DimStartValue"] = this.ngModelval["DimStart"] = value;
      this.inputError["DimStart"] = false;
      if (value > this.ngModelval["DimStopValue"] - this.tcDeltaValue) {
        this.inputError["DimStart"] = true;
        this.ngModelval["DimStartValue"] =
          this.ngModelval["DimStart"] =
          this.DimStartValue =
            value;
      } else {
        this.inputError["DimStart"] = false;
        this.ngModelval["DimStartValue"] =
          this.ngModelval["DimStart"] =
          this.DimStartValue =
            value;
      }
      this.checkInvalidValues();
      this.sendMtpdata(this.ngModelval);
    } else {
      this.inputError["DimStart"] = true;
      this.mtpCreateFile = true;
    }
  }
  textValChanged(value, colName) {
    value = parseInt(value);
    if (isNaN(value) || value.length === 0) {
      value = 0;
    }
    this.mtpDefaultColor = false;
    this.inputError[colName] = false;
    this.mtpCreateFile = false;
    if (colName == "DimLevel") {
      if (value < this.DimLevelMin || value > this.DimLevelMax) {
        this.inputError[colName] = true;
        this.ngModelval["DimLevelValue"] =
          this.ngModelval["DimLevel"] =
          this.DimLevelValue =
            value;
      } else {
        this.ngModelval["DimLevelValue"] =
          this.ngModelval["DimLevel"] =
          this.DimLevelValue =
            value;
      }
    } else if (colName == "DimStop") {
      if (value < this.DimStopMin || value > this.DimStopMax) {
        this.inputError[colName] = true;
        this.ngModelval["DimStopValue"] = this.ngModelval["DimStop"] = value;
      } else if (value <= this.ngModelval["DimStartValue"]) {
        this.ngModelval["DimStopValue"] =
          this.ngModelval["DimStop"] =
          this.DimStopValue =
            value;
        this.ngModelval["DimStartValue"] =
          this.ngModelval["DimStart"] =
          this.DimStartValue =
            this.ngModelval["DimStopValue"] - this.tcDeltaValue;
        this.inputError["DimStart"] = false;
        if (this.DimStartValue < this.DimStartMin) {
          this.inputError["DimStart"] = true;
        }
      } else if (
        /* this.ngModelval["DimStopValue"] */ value - this.tcDeltaValue <=
        this.ngModelval["DimStartValue"]
      ) {
        this.ngModelval["DimStopValue"] =
          this.ngModelval["DimStop"] =
          this.DimStopValue =
            value;
        this.ngModelval["DimStartValue"] =
          this.ngModelval["DimStart"] =
          this.DimStartValue =
            this.ngModelval["DimStopValue"] - this.tcDeltaValue;
        this.inputError["DimStart"] = false;
      } else {
        this.DimStopValue = value;
        this.inputError[colName] = false;
        this.ngModelval["DimStopValue"] =
          this.ngModelval["DimStop"] =
          this.DimStopValue =
            value;
        if (
          this.ngModelval["DimStopValue"] > this.ngModelval["DimStartValue"] &&
          this.ngModelval["DimStartValue"] > this.DimStartMin &&
          this.ngModelval["DimStartValue"] < this.DimStartMax
        ) {
          this.inputError["DimStart"] = false;
        }
      }
      if (
        this.ngModelval["DimStartValue"] < this.DimStartMin ||
        this.ngModelval["DimStartValue"] > this.DimStartMax
      ) {
        this.inputError["DimStart"] = true;
      }
    } else if (colName == "DimStart") {
      if (value < this.DimStartMin || value > this.DimStartMax) {
        this.inputError[colName] = true;
        this.ngModelval["DimStartValue"] = this.ngModelval["DimStart"] = value;
      } else {
        if (value > this.ngModelval["DimStopValue"] - this.tcDeltaValue) {
          this.inputError["DimStart"] = true;
          this.ngModelval["DimStartValue"] =
            this.ngModelval["DimStart"] =
            this.DimStartValue =
              value;
        } else {
          this.inputError["DimStart"] = false;
          this.ngModelval["DimStartValue"] =
            this.ngModelval["DimStart"] =
            this.DimStartValue =
              value;
        }
      }
    } else if (colName == "NtcResistance") {
      if (value < this.NtcResistanceMin || value > this.NtcResistanceMax) {
        this.inputError[colName] = true;
        this.ngModelval["NtcResistance"] = value;
      } else {
        this.NtcResistance = value;
        this.ngModelval["NtcResistance"] = value;
      }
    } else if (colName == "NtcBeta") {
      if (value < this.NtcBetaMin || value > this.NtcBetaMax) {
        this.inputError[colName] = true;
        this.ngModelval["NtcBeta"] = value;
      } else {
        this.NtcBeta = value;
        this.ngModelval["NtcBeta"] = value;
      }
    }
    this.checkdfault();
    for (var type in this.inputError) {
      if (this.inputError[type]) {
        this.mtpCreateFile = true;
      }
    }
    this.service.mtpEditedVal(this.mtpDefaultColor);
    this.service.mtpInputError(this.mtpCreateFile);
    this.sendMtpdata(this.ngModelval);
  }
  ngOnDestroy() {
    this.service.setMtpDefaultColor("");
    //this.sendMtpdata("");
  }
}
