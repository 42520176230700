import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/core-module/services/authentication';
import { Router } from "@angular/router";
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  profileOption=false;
  SSOUrl;
  socialLinks:any=[];
  copyRightYear:string="2023";
  constructor(private authenticationService:AuthenticationService,private router: Router) { }

  ngOnInit() {
    this.socialLinks.linkedin="https://www.linkedin.com/company/signifycompany/";
    this.socialLinks.twitter="https://twitter.com/signifycompany";
    this.socialLinks.instagram="https://www.instagram.com/Signifycompany";
    this.socialLinks.pinterest="https://www.pinterest.com/signifycompany/";
    this.socialLinks.youtube="https://www.youtube.com/c/SignifyCompany";

    
    this.copyRightYear = new Date().getFullYear().toString();
     this.authenticationService.loggedInUserName.subscribe(name => 
      {
        
        if(name==='logout')
        {
          this.profileOption=false;
        }
        if(localStorage.getItem('loggedIn')!=='false')
        {
            if(localStorage.getItem('Name')!=null && name!=='logout'){
                  if(name!=='' && localStorage.getItem('Name')!==null)
                  {
                    this.profileOption=true;
                  }
                  if(name==='' && localStorage.getItem('Name')!==null)
                  {
                    this.profileOption=true;
                  }
              }
        }
        else{
          this.profileOption=false;
        }
      });
  }
  socialLink(link){
        window.open(link, '_blank');

  }
  navigateToAbout() {
    this.router.navigate(["/about"]);
  }

}
