//NOSONAR
import { Component, OnInit, Input, OnChanges, OnDestroy } from "@angular/core";
import { FieldType } from "@ngx-formly/core";
import { ApiService } from "src/app/core-module/services/api.service";
import { restore } from "./../../../../assets/json/restoreTooltip.en";
import { isNullOrUndefined, isObject } from "util";

@Component({
  selector: "app-lum-info",
  templateUrl: "./lum-info.component.html",
  styleUrls: ["./lum-info.component.scss"],
})
export class LumInfoComponent
  extends FieldType
  implements OnInit, OnChanges, OnDestroy
{
  @Input() field;
  filedName: any = [];
  ngModelval: any = [];
  JSONOb: any = [];

  columnList1: any = {
    OemGtin: "GTIN (EAN 13)",
    OemIdentification: "Identification number",
    AdditionalInfo: "Additional info",
    UseDeviceUid: "Use device UID",
    LuminaireIdentification: "Luminaire identification",
    LuminaireManufactureDate: "Luminaire date of manufacture",
    LuminaireManufactureWeek: "Luminaire manufacture week",
    LuminaireManufactureYear: "Luminaire manufacture year",
    UseDynamicDateIndicator: "Use production date",
    LuminaireManufactureName: "Luminaire manufacturer name",
    LuminaireStyle: "Luminaire style",
    LuminaireLightSource: "Luminaire light source",
    LuminaireColor: "Luminaire color",
    LuminaireColorCode: "Luminaire color code",
    NominalInputPower: "Nominal input power",
    MinAcMainsVoltage: "Min AC mains voltage",
    MaxAcMainsVoltage: "Max AC mains voltage",
    PowerMinDim: "Power of min dim level",
    InputpowerBandNominal: "Input power error band at nominal input power",
    InputpowerBandPhysicalMin: "Input power error band at physical min level",
    DimlevelPf90: "DALI dim level at PF = 0.9",
    DimlevelPf70: "DALI dim level at PF = 0.7",
    DimlevelPf80: "DALI dim level at PF = 0.8",
    NominalLightOutput: "Nominal light output",
    CRI: "CRI",
    CCT: "CCT",
    ContentIdentifier: "Content identifier",
    DistributionType: "Light distribution type",
    curvSelection: "Curve selection",
    IndependentOccupancySensor: "Independant occupancy sensor preset",
    LsiDevicePresent: "LSI device preset",
  };

  columnList = [];

  column: any = [];
  properties: any;
  defaultRestoring: boolean = true;
  formatVersion;
  dynamIndicator;
  useDeviceID;
  errorPattern: any = [];
  errorPatternGtin = false;
  isEdited: boolean;
  fieldPatterns: any = [];
  fieldMaxVal: any = [];
  UseDynamicDateIndicator: boolean;
  implemented_209: boolean;
  UseDeviceUidChecking: boolean;
  reqiredProperties: any;
  restoreText = restore;
  fileResponse: any;
  disableCCT: boolean;
  checkMaxString: any = [];
  displayVersion: string;
  typeValue: any;
  maskColversionOne: any = ["OemIdentification", "OemGtin"]; //maskColversionOne:any=['NominalInputPower', 'NominalLightOutput'];
  maskColversionThree: any = [
    "NominalInputPower",
    "PowerMinDim",
    "CCT",
    "DistributionType",
    "CRI",
    "MaxAcMainsVoltage",
    "MinAcMainsVoltage",
    "LuminaireManufactureWeek",
    "LuminaireManufactureYear",
    "NominalLightOutput",
    "OemIdentification",
    "OemGtin",
  ];
  maskFieldMaxValue: any;
  maskMaximum: any = {
    NominalInputPower: 65535,
    PowerMinDim: 65535,
    CCT: 65535,
    DistributionType: 255,
    CRI: 255,
    MaxAcMainsVoltage: 65535,
    MinAcMainsVoltage: 65535,
    LuminaireManufactureWeek: 255,
    LuminaireManufactureYear: 255,
    NominalLightOutput: 16777215,
    OemIdentification: "18446744073709551615",
    OemGtin: "281474976710655",
  };
  fileResponseUnEdited: any;
  isEditedVal: any;
  constructor(private apiService: ApiService) {
    super();
  }
  ngOnChanges(changes: import("@angular/core").SimpleChanges): void {}
  ngOnInit() {
    this.isEditedVal = false;
    this.apiService.getLuminDefaultValue.subscribe((response) => {
      if (response) {
        let luminDefaultRes: any = response;
        this.properties = luminDefaultRes;
        this.resetDefault();
      }
    });
    this.fileResponse = JSON.parse(localStorage.getItem("configurationData"));
    var saveData = JSON.parse(localStorage.getItem("saveSetData"));
    let versionNumber = this.field.properties.ContentIdentifier.const
      ? this.field.properties.ContentIdentifier.const
      : this.field.properties.ContentIdentifier.default;
    this.formatVersion = versionNumber;
    if (versionNumber == 3 || versionNumber == 6) {
      this.displayVersion = "Luminaire info - v3 (DALI Part 251/ANSI C137.4)";
    } else if (versionNumber == 1) {
      this.displayVersion = "Luminaire info version 1";
    } else if (versionNumber == 0) {
      this.displayVersion = "Unformatted Content";
    }

    this.column = this.field.requiredProperties;
    this.properties = this.field.properties;
    this.reqiredProperties = this.field.requiredProperties;
    var i = 0;
    this.columnList = [];
    for (let j = 0; j < this.reqiredProperties.length; j++) {
      let type = this.reqiredProperties[j];
      if (this.column.includes(type)) {
        this.filedName[i] = type;
        if (type !== "") {
          if (
            this.formatVersion == 3 &&
            (type === "OemIdentification" ||
              type === "OemGtin" ||
              type === "DistributionType" ||
              type === "PowerMinDim" ||
              type === "NominalInputPower" ||
              type === "NominalLightOutput" ||
              type === "LuminaireManufactureYear" ||
              type === "LuminaireManufactureWeek" ||
              type === "MinAcMainsVoltage" ||
              type === "CRI" ||
              type === "MaxAcMainsVoltage" ||
              type === "CCT") &&
            (this.properties[type]["default"] === 255 ||
              this.properties[type]["default"] === 65535 ||
              this.properties[type]["default"] === 0 ||
              this.properties[type]["default"] === "18446744073709551615" ||
              this.properties[type]["default"] === 16777215 ||
              this.properties[type]["default"] === "281474976710655")
          ) {
            this.ngModelval[type] = "";
            this.properties[type].default = "";
          } else {
            this.ngModelval[type] = this.properties[type]["default"];
            if (type === "UseDeviceUid") {
              this.useDeviceID = this.properties["UseDeviceUid"]["default"];
              this.UseDeviceUidChecking =
                this.properties["UseDeviceUid"]["default"];
            }
          }
        }

        if (type !== "" && this.formatVersion == 0) {
          if (
            (type === "OemIdentification" || type === "OemGtin") &&
            (this.properties[type]["default"] === "18446744073709551615" ||
              this.properties[type]["default"] === "281474976710655")
          ) {
            this.ngModelval[type] = "";
            this.properties[type].default = "";
          } else {
            this.ngModelval[type] = this.properties[type]["default"];
          }
        }

        this.properties[type]["needValidation"] = true;
        if (this.properties[type]["pattern"]) {
          this.fieldPatterns[type] = this.properties[type]["pattern"];
        }

        if (this.formatVersion != 0) {
          this.field.allOf.forEach((element) => {
            if (
              element &&
              element.else &&
              element.else.properties[type] &&
              (element.else.properties[type].minimum ||
                element.else.properties[type].maximum)
            ) {
              this.properties[type]["minimum"] =
                element.else.properties[type].minimum;
              this.properties[type]["maximum"] =
                element.else.properties[type].maximum;
              this.properties[type]["needValidation"] = false;
            }
          });
        }

        if (this.properties[type]["maximum"]) {
          this.properties[type]["maxLength"] =
            this.properties[type]["maximum"].toString().length;
          this.fieldMaxVal[type] = this.properties[type]["maximum"];
        }
        if (this.properties[type]["maxLength"]) {
          this.checkMaxString[type] = this.properties[type]["maxLength"];
        }
        i++;
      }
    }

    if (
      this.fileResponse["resp"] &&
      this.fileResponse["resp"]["LumInfoFormat"] &&
      this.fileResponse["resp"]["LumInfoFormat"]["LuminaireManufactureWeek"] &&
      this.fileResponse["resp"]["LumInfoFormat"]["LuminaireManufactureYear"]
    ) {
      this.ngModelval["LuminaireManufactureYear"] =
        this.fileResponse["resp"]["LumInfoFormat"]["LuminaireManufactureYear"];
      this.ngModelval["LuminaireManufactureWeek"] =
        this.fileResponse["resp"]["LumInfoFormat"]["LuminaireManufactureWeek"];
    }
    let unSelectedFeat = Array(
      JSON.parse(localStorage.getItem("UnSelectedFeatConfig"))
    );

    let isConfigEdited = false;
    let featIndex: number = 0;
    if (unSelectedFeat[0] != null) {
      unSelectedFeat[0].forEach((element) => {
        if (
          !isNullOrUndefined(element) &&
          !isNullOrUndefined(element["featureName"]) &&
          element["featureName"].toString().toUpperCase() === "LUMINFOFORMAT"
        ) {
          isConfigEdited = true;
          this.fileResponseUnEdited = element["featureParameters"][0];
          var i = 0;
          this.columnList = [];
          for (let j = 0; j < this.reqiredProperties.length; j++) {
            let type = this.reqiredProperties[j];
            if (this.column.includes(type)) {
              this.filedName[i] = type;
              if (
                this.fileResponseUnEdited[type] ||
                this.fileResponseUnEdited[type] === false
              ) {
                this.typeValue = this.fileResponseUnEdited[type];
                this.maskFieldMaxValue = this.maskMaximum[type];
                if (
                  ((this.maskColversionOne.includes(type) &&
                    this.formatVersion === 0) ||
                    (this.maskColversionThree.includes(type) &&
                      this.formatVersion === 3)) &&
                  this.typeValue === this.maskFieldMaxValue
                ) {
                  this.ngModelval[type] = "";
                } else {
                  this.ngModelval[type] = this.fileResponseUnEdited[type];
                }
              } else {
                this.ngModelval[type] = this.properties[type]["default"];
              }
              if (type === "CCT" && this.ngModelval[type] === 65534) {
                this.partImplemented(true);
              }
              if (
                type === "UseDynamicDateIndicator" ||
                type === "UseDeviceUid"
              ) {
                this.checkboxChanged(type, this.ngModelval[type]);
              }
              i++;
            }
          }
          unSelectedFeat[0].splice(featIndex, 1);
          localStorage.setItem(
            "UnSelectedFeatConfig",
            JSON.stringify(unSelectedFeat[0])
          );
        } else {
          featIndex++;
        }
      });
    }
    /////
    if (isConfigEdited) {
    } else if (
      this.fileResponse["resp"] &&
      this.fileResponse["resp"]["LumInfoFormat"]
    ) {
      var i = 0;
      this.columnList = [];
      for (let j = 0; j < this.reqiredProperties.length; j++) {
        let type = this.reqiredProperties[j];
        if (this.column.includes(type)) {
          this.filedName[i] = type;
          var lumInfoFormatValue: any;
          if (isObject(this.fileResponse["resp"]["LumInfoFormat"][0])) {
            var values = Object.values(
              this.fileResponse["resp"]["LumInfoFormat"]
            );
            values.forEach((element, index) => {
              element["key"];
              if (type === element["key"]) {
                lumInfoFormatValue = element["value"];
              }
            });
          } else {
            lumInfoFormatValue =
              this.fileResponse["resp"]["LumInfoFormat"][type];
          }
          if (lumInfoFormatValue || lumInfoFormatValue === false) {
            this.typeValue = lumInfoFormatValue;
            this.maskFieldMaxValue = this.maskMaximum[type];
            if (
              ((this.maskColversionOne.includes(type) &&
                this.formatVersion === 0) ||
                (this.maskColversionThree.includes(type) &&
                  this.formatVersion === 3)) &&
              this.typeValue === this.maskFieldMaxValue
            ) {
              this.ngModelval[type] = "";
            } else {
              this.ngModelval[type] = lumInfoFormatValue;
            }
          } else {
            this.ngModelval[type] = lumInfoFormatValue;
          }
          if (type === "CCT" && this.ngModelval[type] === 65534) {
            this.partImplemented(true);
          }
          if (type === "CCT" && JSON.parse(localStorage.getItem("CCTBoolen"))) {
            this.partImplemented(JSON.parse(localStorage.getItem("CCTBoolen")));
          }
          if (type === "UseDynamicDateIndicator" || type === "UseDeviceUid") {
            this.checkboxChanged(type, this.ngModelval[type]);
          }
          i++;
        }
      }
    } else if (
      saveData &&
      saveData["resp"]["LumInfoFormat"] &&
      Object.keys(saveData["resp"]["LumInfoFormat"]).length > 0
    ) {
      this.fileResponse = saveData;
      var i = 0;
      this.columnList = [];

      for (let j = 0; j < this.reqiredProperties.length; j++) {
        let type = this.reqiredProperties[j];
        if (this.column.includes(type)) {
          this.filedName[i] = type;
          if (this.fileResponse["resp"]["LumInfoFormat"][type]) {
            this.ngModelval[type] =
              this.fileResponse["resp"]["LumInfoFormat"][type];
          } else {
            this.ngModelval[type] = this.properties[type]["default"];
          }
          if (type === "CCT" && this.formatVersion == 3) {
            if (localStorage.getItem("CCTBoolen")) {
              this.partImplemented(true);
            }
          }
          if (type === "UseDynamicDateIndicator" || type === "UseDeviceUid") {
            this.checkboxChanged(type, this.ngModelval[type]);
          }
          i++;
        }
      }
    }
    this.checkDefault();
    this.setLuminValue(this.JSONOb);
    this.isEditedVal = true;
  }
  setLuminValue(val) {
    this.apiService.setLuminValue(val);
    if (this.isEdited) {
      this.setConfigurationData(val, "LumInfoFormat");
    }
  }
  setConfigurationData(value, keyVal) {
    let touchDimObj = {};
    var key = keyVal;
    var data = {};
    var addNew = {
      [keyVal]: {},
    };
    data = JSON.parse(localStorage.getItem("configurationData"));
    if (data && !isNullOrUndefined(data["resp"])) {
      touchDimObj = Object.assign({}, value);
      data["resp"][keyVal] = touchDimObj;
    } else {
      data["resp"] = addNew;
      touchDimObj = Object.assign({}, value);
      data["resp"][keyVal] = touchDimObj;
    }
    localStorage.setItem("configurationData", JSON.stringify(data));
  }
  checkDefault() {
    this.defaultRestoring = true;
    this.apiService.setLuminDefaultColor(this.defaultRestoring);
    this.isEdited = false;
    this.apiService.setLuminValueChanges(false);
    this.JSONOb = [];
    this.apiService.setLuminFormInputValidation(false);
    //for (var type in this.ngModelval) {
    for (let j = 0; j < this.reqiredProperties.length; j++) {
      let type = this.reqiredProperties[j];
      this.JSONOb.push({ key: type, value: this.ngModelval[type] });
      if (this.fieldPatterns[type]) {
        this.validatePattern(type);
      }
      if (this.fieldMaxVal[type]) {
        this.validateMinMax(type);
      }
      let cctValue;
      if (localStorage.getItem("CCTBoolen")) {
        cctValue = JSON.parse(localStorage.getItem("CCTBoolen"));
      }
      if (
        this.ngModelval[type] != this.properties[type]["default"] ||
        cctValue
      ) {
        this.defaultRestoring = false;
        this.apiService.setLuminDefaultColor(this.defaultRestoring);
        this.isEdited = true;
        this.apiService.setLuminValueChanges(true);
      }
    }
  }

  valueChanged(fieldName?) {
    this.checkDefault();
    // this.formControl.setValue(this.ngModelval);
    this.setLuminValue(this.JSONOb);
    if (
      fieldName === "CCT" &&
      parseInt(this.ngModelval[fieldName]) === parseInt("65534")
    ) {
      this.errorPattern["CCT"] = false;
      this.partImplemented(true);
    }
    for (let j = 0; j < this.JSONOb.length; j++) {
      if (
        parseInt(this.ngModelval[fieldName]) ===
          parseInt(this.maskMaximum[fieldName]) &&
        this.JSONOb[j].key == fieldName
      ) {
        delete this.JSONOb[j];
        this.ngModelval[fieldName] = "";
        if (fieldName === 'CRI') {
          this.errorPattern['CRI'] = false;
          this.apiService.setLuminFormInputValidation(this.errorPattern['CRI']);
        }
        this.JSONOb.push({ key: fieldName, value: this.ngModelval[fieldName] });
        this.setLuminValue(this.JSONOb);
        this.defaultRestoring = true;
        this.apiService.setLuminDefaultColor(this.defaultRestoring);
      }
    }
    this.checkDefault() //to Check Default;
    // this.validatePattern()
  }

  resetDefault() {
    this.defaultRestoring = true;
    if (this.properties["UseDynamicDateIndicator"]) {
      this.dynamIndicator =
        this.properties["UseDynamicDateIndicator"]["default"];
      this.UseDynamicDateIndicator =
        this.properties["UseDynamicDateIndicator"]["default"];
    }
    if (this.properties["UseDeviceUid"]) {
      this.useDeviceID = this.properties["UseDeviceUid"]["default"];
      this.UseDeviceUidChecking = this.properties["UseDeviceUid"]["default"];
    }

    let loadedLuminNg = false;
    for (var type in this.ngModelval) {
      this.ngModelval[type] = this.properties[type]["default"];
      this.errorPattern[this.ngModelval[type]] = false;
      loadedLuminNg = true;
    }
    if (loadedLuminNg === true) {
      if (this.ngModelval["CCT"] != undefined) this.partImplemented(false);

      this.valueChanged();
    }
  }

  checkboxChanged(e, checked?) {
    var filedid = e.target ? e.target.id : e;
    var checking = e.target ? e.target.checked : checked;
    if (checking) {
      this.ngModelval[filedid] = true;
      if (filedid === "UseDynamicDateIndicator") {
        this.dynamIndicator = true;
        this.UseDynamicDateIndicator = true;
        if (this.column.includes("LuminaireManufactureDate")) {
          this.ngModelval["LuminaireManufactureDate"] =
            this.ngModelval["LuminaireManufactureDate"];
        }
        if (
          this.column.includes("LuminaireManufactureWeek") ||
          this.column.includes("LuminaireManufactureYear")
        ) {
          this.ngModelval["LuminaireManufactureWeek"] = null;
          this.ngModelval["LuminaireManufactureYear"] = null;
        }
      }
      if (filedid === "UseDeviceUid") {
        this.useDeviceID = true;
        this.ngModelval["OemIdentification"] = "";
        this.UseDeviceUidChecking = true;
      }
    } else {
      this.ngModelval[filedid] = false;
      if (filedid === "UseDynamicDateIndicator") {
        this.dynamIndicator = false;
        this.UseDynamicDateIndicator = false;
      }
      if (filedid === "UseDeviceUid") {
        this.useDeviceID = false;
        this.UseDeviceUidChecking = false;
      }
    }
    this.valueChanged();
  }

  validatePattern(e) {
    var trigger = this.ngModelval[e];
    //var regex = /(\d){13}$/g
    var regexp = new RegExp(this.fieldPatterns[e]);
    if (regexp.test(trigger)) {
      this.errorPattern[e] = false;
    } else {
      if (
        (this.dynamIndicator === true && e == "LuminaireManufactureDate") ||
        (this.useDeviceID === true && e == "OemIdentification")
      ) {
        this.errorPattern[e] = false;
      } else {
        console.log(this.errorPattern[e]);
        this.errorPattern[e] = true;
        this.apiService.setLuminFormInputValidation(true);
      }
    }
  }

  validateMinMax(filed) {
    var num = parseInt(this.ngModelval[filed], 10),
      min = this.properties[filed]["minimum"],
      max = this.properties[filed]["maximum"];
    if (
      this.formatVersion == 3 &&
      (filed === "DistributionType" ||
        filed === "PowerMinDim" ||
        filed === "NominalInputPower" ||
        filed === "NominalLightOutput" ||
        filed === "LuminaireManufactureYear" ||
        filed === "LuminaireManufactureWeek" ||
        filed === "MinAcMainsVoltage" ||
        filed === "CRI" ||
        filed === "MaxAcMainsVoltage" ||
        filed === "CCT")
    ) {
      if (this.ngModelval[filed] === "" || this.ngModelval[filed] === null) {
        this.ngModelval[filed] = "";
        this.errorPattern[filed] = false;
      } else if (filed === "CCT" && this.ngModelval[filed] == 65535) {
        this.ngModelval[filed] = 65535;
        this.errorPattern[filed] = false;
      } else if (isNaN(num) || num < min || num > max) {
        console.log(this.errorPattern[filed])
        this.errorPattern[filed] = true;
        this.apiService.setLuminFormInputValidation(true);
      } else {
        this.errorPattern[filed] = false;
      }
    } else if (
      this.formatVersion == 1 &&
      (filed === "MinAcMainsVoltage" || filed === "MaxAcMainsVoltage")
    ) {
      if (this.ngModelval[filed] === 0 || this.ngModelval[filed] === "0") {
        this.ngModelval[filed] === 0;
        this.errorPattern[filed] = false;
      } else if (isNaN(num) || num < min || num > max) {
        this.errorPattern[filed] = true;
        console.log(this.errorPattern[filed]);
        this.apiService.setLuminFormInputValidation(true);
      } else {
        this.errorPattern[filed] = false;
      }
    } else {
      //for all field
      if (isNaN(num) || num < min || num > max) {
        console.log(this.errorPattern[filed]);
        this.errorPattern[filed] = true;
        this.apiService.setLuminFormInputValidation(true);
      } else {
        this.errorPattern[filed] = false;
      }
    }
  }

  partImplemented(e) {
    this.disableCCT = false;
    e = e.target ? e.target.checked : e;
    if (e) {
      this.ngModelval["CCT"] = "";
      this.disableCCT = true;
      this.implemented_209 = true;
      localStorage.setItem("CCTBoolen", JSON.stringify(true));
    } else {
      if (this.ngModelval["CCT"] != "") {
        this.ngModelval["CCT"] = e;
      }
      this.implemented_209 = false;
      localStorage.removeItem("CCTBoolen");
    }
    this.valueChanged();
  }

  otherVersionFields(param) {
    var num = parseInt(this.ngModelval[param], 10),
      min = this.properties[param]["minimum"],
      max = this.properties[param]["maximum"];
    if (
      this.formatVersion == 1 &&
      num == 0 &&
      (param === "MinAcMainsVoltage" || param === "MaxAcMainsVoltage")
    ) {
      this.ngModelval[param] = 0;
    } else if (this.formatVersion == 1 && (isNaN(num) || num > max)) {
      this.ngModelval[param] = this.properties[param]["maximum"];
    } else if (num < min) {
      this.ngModelval[param] = this.properties[param]["minimum"];
    }
    this.valueChanged();
  }

  ngOnDestroy() {
    //this.ngModelval = [];
  }
}
