import { Component, OnInit } from '@angular/core';
import { ApiService } from "../core-module/services/api.service";

@Component({
  selector: 'app-dynadimmer-graphtoggle',
  templateUrl: './dynadimmer-graphtoggle.component.html',
  styleUrls: ['./dynadimmer-graphtoggle.component.scss']
})
export class DynadimmerGraphtoggleComponent implements OnInit {

  timReferenceToggle:boolean=false;
  constructor(
    private service: ApiService,
 
  ) {}

  ngOnInit() {

  this.service.getDynaTimeReferencedMode.subscribe(response=>{

    //console.log(response+ "TOGGLE CJECK DYNA TIME REFERENCE MODE")
    if(response===true){
      this.timReferenceToggle = true;
    }
    else
    this.timReferenceToggle = false;
  })
  }

}
