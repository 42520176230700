import {
  Component,
  OnInit,
  Input,
  ChangeDetectorRef,
  ViewRef,
  OnDestroy,
} from "@angular/core";
import { FieldType } from "@ngx-formly/core";
import { ApiService } from "src/app/core-module/services/api.service";
import { restore } from "./../../../../assets/json/restoreTooltip.en";
import { Options } from "ng5-slider";
import { CompileShallowModuleMetadata } from "@angular/compiler";
import { isNullOrUndefined, isNumber } from "util";
import { Subscription } from "rxjs";


@Component({
  selector: "app-ztv",
  templateUrl: "./ztv.component.html",
  styleUrls: ["./ztv.component.scss"],
})
export class ZtvComponent extends FieldType implements OnInit, OnDestroy {
  @Input() field;
  formatVersion: any;
  column: any;
  properties: any = [];
  ztvoptions: Options = {};
  ztvval: any;
  ZtvMinDimLevel: any;
  ztvvalMin: any;
  ztvvalMax: any;
  defaultColor: boolean = true;
  ztvTextVal: any;
  inputError: boolean = false;
  ZtvCurveSelection: any;
  ZtvCurveSelectionVal: any;
  ZtvDimToOffEnabled: any;
  ztvdisbaled: boolean = false;
  ngModelval: any = [];
  restoreText = restore;
  ZtvEnabledval: any;
  ZtvDimToOffEnabledVal: any;
  FadeOnTime: any;
  hideZtvMinDimLevel: boolean = false;
  //userSpecified:boolean=false;
  curveSurveyValues = {
    // 1: "0-10V linear",
    // 2: "1-9V linear",
    // 3: "0-10V logarithmic",
    // 4: "1-9V reversed linear",
    // 5: "0-5V reversed linear",
  };
  curveSelectionStringValues={};
  sampleEnum = [];
  sampleEnumFadeOffTime = [];
  quickConfigvalue;
  quickConfigCustomCSS;
  setQuickConfig;
  fileResponse: any;
  FadeOffTime: any;
  FadeOnTimeMin: any;
  FadeOnTimeMax: any;
  FadeOffTimeMin: any;
  FadeOffTimeMax: any;
  isEdited: any;
  getZtvValuesSub: Subscription;
  resetZtvSub: Subscription;
  constructor(private service: ApiService, private ref: ChangeDetectorRef) {
    super();
  }
  ngOnInit() {
    this.isEdited = false;
    this.getZtvValuesSub = this.service.getZtvValues.subscribe((response) => {
      if (response) {
        if (response["ZtvDimToOffEnabled"] !== undefined) {
          this.ngModelval["ZtvDimToOffEnabled"] =
            response["ZtvDimToOffEnabled"];
          this.ZtvDimToOffEnabledVal = response["ZtvDimToOffEnabled"];
        }
        if (response["FadeOffTime"] !== undefined) {
          this.ngModelval["FadeOffTime"] = response["FadeOffTime"];
        }
        if (response["FadeOnTime"] !== undefined) {
          this.ngModelval["FadeOnTime"] = response["FadeOnTime"];
        }
        if (response["ZtvCurveSelection"] !== undefined) {
          if (isNumber(response["ZtvCurveSelection"]) && Object.keys(this.curveSurveyValues).length > 0) {
            this.ngModelval["ZtvCurveSelection"] = this.curveSurveyValues[response["ZtvCurveSelection"]];
          } else {
            this.ngModelval["ZtvCurveSelection"] = response["ZtvCurveSelection"];
          }
        }
        if (response["ZtvEnabled"] !== undefined) {
          this.ngModelval["ZtvEnabled"] = response["ZtvEnabled"];
        }
        this.CheckHideZtvMinDimLevel();
        this.ngModelval["ZtvMinDimLevel"] = response["ZtvMinDimLevel"];
        this.ztvval = response["ZtvMinDimLevel"];
        this.inputError = false;
      }
    });
    this.resetZtvSub = this.service.getztvRestoreBtnClick.subscribe((response) => {
      if (response == "clicked") {
        this.ztvdisbaled = false;
        this.rSetChanged(true);
      }
    });
    this.formatVersion = this.field.featureVersion;
    this.column = this.field.requiredProperties;
    this.properties = this.field.properties;
    this.service.ztvInputError(this.inputError);
    //ZTV dim val start
    this.ZtvMinDimLevel = this.properties.ZtvMinDimLevel;
    this.ngModelval["ZtvMinDimLevel"] = this.ztvval =
      this.ZtvMinDimLevel.default;
    this.ztvvalMin = this.ZtvMinDimLevel.minimum;
    this.ztvvalMax = this.ZtvMinDimLevel.maximum;
    this.ztvoptions = {
      floor: this.ztvvalMin,
      ceil: this.ztvvalMax,
    };
    //curve slection
    if (this.properties.ZtvCurveSelection) {
      let ztvCurveSelectionEnum = [];
      ztvCurveSelectionEnum = this.properties.ZtvCurveSelection.enum;
      let enumCurveValues = {};
      let enumStringkeys = {};
      let enumKeys = [];
      let enumValues =[];
      ztvCurveSelectionEnum.forEach((item, i) => {
        if (isNumber(item)) {
          enumCurveValues[item] = ztvCurveSelectionEnum[i + 1];
          enumStringkeys[ztvCurveSelectionEnum[i + 1]] = item;
          enumKeys.push(item);
          enumValues.push(enumCurveValues[item]);
        }
        
        this.curveSurveyValues = enumCurveValues;
        this.curveSelectionStringValues = enumStringkeys;
      });
      
      this.properties.ZtvCurveSelection['enum'] = enumValues;
      this.ZtvCurveSelection = this.properties.ZtvCurveSelection;
      this.ngModelval["ZtvCurveSelection"] = this.curveSurveyValues[this.ZtvCurveSelection.default];
      this.CheckHideZtvMinDimLevel();
    } else {
      this.hideZtvMinDimLevel = false;
    }
    //ZtvDimToOffEnabled
    if (this.properties.ZtvDimToOffEnabled) {
      this.ZtvDimToOffEnabled = this.properties.ZtvDimToOffEnabled;
      this.ngModelval["ZtvDimToOffEnabled"] = this.ZtvDimToOffEnabled.default;
      this.ZtvDimToOffEnabledVal = this.ZtvDimToOffEnabled.default;
      this.changedZtvDimToOffEnabledVal(this.ZtvDimToOffEnabledVal);
    }
    //FadeOnTime
    if (this.properties.FadeOnTime) {
      this.FadeOnTime = this.properties.FadeOnTime;
      this.ngModelval["FadeOnTime"] = this.FadeOnTime.default;
      for (
        var i = this.FadeOnTime.minimum;
        i <= this.FadeOnTime.maximum / 0.5;
        i++
      ) {
        this.sampleEnum.push(i * this.FadeOnTime.multipleOf);
      }
      this.FadeOnTimeMin = this.FadeOnTime.minimum;
      this.FadeOnTimeMax = this.FadeOnTime.maximum;
    }
    //FadeOffTime
    if (this.properties.FadeOffTime) {
      this.FadeOffTime = this.properties.FadeOffTime;
      this.ngModelval["FadeOffTime"] = this.FadeOffTime.default;
      for (
        var i = this.FadeOffTime.minimum;
        i <= this.FadeOffTime.maximum / 0.5;
        i++
      ) {
        this.sampleEnumFadeOffTime.push(i * this.FadeOffTime.multipleOf);
      }
      this.FadeOffTimeMin = this.FadeOffTime.minimum;
      this.FadeOffTimeMax = this.FadeOffTime.maximum;
    }
    let aaa = JSON.parse(localStorage.getItem("saveSetData"));
    this.fileResponse = JSON.parse(localStorage.getItem("configurationData"));
    //ZTV enabled start
    if (
      this.properties["ZtvEnabled"] &&
      (this.properties["ZtvEnabled"].default ||
        this.properties["ZtvEnabled"].const)
    ) {
      this.ZtvEnabledval = this.properties["ZtvEnabled"].default
        ? this.properties["ZtvEnabled"].default
        : this.properties["ZtvEnabled"].const;
      this.ngModelval["ZtvEnabled"] = this.properties["ZtvEnabled"].default
        ? this.properties["ZtvEnabled"].default
        : this.properties["ZtvEnabled"].const;
      this.rSetChanged(this.ZtvEnabledval);
    }
    this.service.getquickConfig.subscribe((data) => {
      if (data === "quickConfig") {
        this.quickConfigvalue = true;
        this.quickConfigCustomCSS = "quick-custom-inputbox";
        this.setQuickConfig = "quickConfig";
      } else {
        this.quickConfigvalue = false;
        this.quickConfigCustomCSS = "custom-inputbox";
        this.setQuickConfig = "standard";
      }

      setTimeout(() => {
        if (this.ref && !(this.ref as ViewRef).destroyed) {
          this.ref.detectChanges();
        }
      });
    });
    this.sendZtvObj(this.ngModelval);
    this.isEdited = true;
    this.service.ztvEditedVal(this.defaultColor);
    let unSelectedFeat = Array(
      JSON.parse(localStorage.getItem("UnSelectedFeatConfig"))
    );
    let isConfigEdited = false;
    let featIndex: number = 0;
    if (unSelectedFeat[0] != null) {
      unSelectedFeat[0].forEach((element) => {
        if (element["featureName"].toString().toUpperCase() === "ZTV") {
          isConfigEdited = true;
          this.fileResponse = {};
          let ztvUnSelectedVal: any = element["featureParameters"][0];
          let ztvEditedData = {
            resp: {
              ZTV: ztvUnSelectedVal,
            },
          };
          this.fileResponse = ztvEditedData;
          unSelectedFeat[0].splice(featIndex, 1);
          localStorage.setItem(
            "UnSelectedFeatConfig",
            JSON.stringify(unSelectedFeat[0])
          );
        } else {
          featIndex++;
        }
      });
    }
    if (this.fileResponse["resp"]) {
      if (this.fileResponse["resp"]["ZTV"]) {
        if (
          this.fileResponse["resp"]["ZTV"]["ZtvEnabled"] === false ||
          this.fileResponse["resp"]["ZTV"]["ZtvEnabled"] === true
        ) {
          this.ZtvEnabledval = this.fileResponse["resp"]["ZTV"]["ZtvEnabled"];
          this.ngModelval["ZtvEnabled"] = this.ZtvEnabledval;
          if (this.ZtvEnabledval === false) {
            this.ztvdisbaled = true;
          }
        }
        if (this.fileResponse["resp"]["ZTV"]["ZtvCurveSelection"]) {
            this.ngModelval["ZtvCurveSelection"] =
              this.fileResponse["resp"]["ZTV"]["ZtvCurveSelection"];
        }
        if (this.fileResponse["resp"]["ZTV"]["FadeOffTime"] === 0) {
          this.ngModelval["FadeOffTime"] = 0;
        } else {
          this.ngModelval["FadeOffTime"] =
            this.fileResponse["resp"]["ZTV"]["FadeOffTime"];
        }
        if (this.fileResponse["resp"]["ZTV"]["FadeOnTime"] === 0) {
          this.ngModelval["FadeOnTime"] = 0;
        } else {
          this.ngModelval["FadeOnTime"] =
            this.fileResponse["resp"]["ZTV"]["FadeOnTime"];
        }
        if (this.fileResponse["resp"]["ZTV"]["ZtvDimToOffEnabled"]) {
          this.ngModelval["ZtvDimToOffEnabled"] =
            this.fileResponse["resp"]["ZTV"]["ZtvDimToOffEnabled"];
        }
        if (this.fileResponse["resp"]["ZTV"]["ZtvMinDimLevel"]) {
          this.ngModelval["ZtvMinDimLevel"] =
            this.fileResponse["resp"]["ZTV"]["ZtvMinDimLevel"];
        }
        this.ztvval = this.ngModelval["ZtvMinDimLevel"];
        this.ztvSlideValuechange(this.ngModelval["ZtvMinDimLevel"]);

      }
    }
    this.checkValidValue(this.ngModelval["ZtvMinDimLevel"]);
  }

  CheckHideZtvMinDimLevel() {

    if (isNumber(this.ngModelval["ZtvCurveSelection"]) || this.ngModelval["ZtvCurveSelection"] === undefined) {
      if (
        this.ngModelval["ZtvCurveSelection"] === 3 ||
        this.ngModelval["ZtvCurveSelection"] === 1 ||
        this.ngModelval["ZtvCurveSelection"] === undefined
      ) {
        this.hideZtvMinDimLevel = false;
      } else {
        this.hideZtvMinDimLevel = true;
      }
    } else {
      if (Object.keys(this.curveSelectionStringValues).length > 0) {
        if (
          this.curveSelectionStringValues[this.ngModelval["ZtvCurveSelection"]] === 3 ||
          this.curveSelectionStringValues[this.ngModelval["ZtvCurveSelection"]] === 1) {
          this.hideZtvMinDimLevel = false;
        } else {
          this.hideZtvMinDimLevel = true;
        }
      }
    }
  }

  checkValidValue(value) {
    this.inputError = false;
    if (value < this.ztvvalMin || value > this.ztvvalMax) {
      this.inputError = true;
    }
    this.service.ztvInputError(this.inputError);
  }
  setConfigurationData(value, keyVal) {
    if(!isNullOrUndefined(value)){
      let touchDimObj = {};
      var key = keyVal;
      var data = {};
      var addNew = {
        [keyVal]: {},
      };
      data = JSON.parse(localStorage.getItem("configurationData"));
      if (data && !isNullOrUndefined(data["resp"])) {
        touchDimObj = Object.assign({}, value);
        data["resp"][keyVal] = touchDimObj;
      } else {
        data["resp"] = addNew;
        touchDimObj = Object.assign({}, value);
        data["resp"][keyVal] = touchDimObj;
      }
      localStorage.setItem("configurationData", JSON.stringify(data));
    }
  }
  sendZtvObj(val) {
    this.service.sendZtvObj(val);
    if (this.isEdited) {
      this.setConfigurationData(this.ngModelval, "ZTV");
    }
  }
  ztvSlideValuechange(value) {
    this.inputError = false;
    if (value >= this.ztvvalMin && this.inputError === false) {
      this.ngModelval["ZtvMinDimLevel"] = value;
    }
    this.ZtvvalueChange();
    this.service.ztvInputError(this.inputError);
  }
  textValChanged(value) {
    this.defaultColor = false;
    this.inputError = false;
    if (value < this.ztvvalMin || value > this.ztvvalMax) {
      // this.inputError=true;
      if (value > this.ztvvalMax) {
        this.ztvval = this.ztvvalMax;
        this.ngModelval["ZtvMinDimLevel"] = this.ztvvalMax;
        this.sendZtvObj(this.ngModelval);
      } else if (value < this.ztvvalMax) {
        this.inputError = true;
      }
    } else {
      this.ztvval = value;
      this.ZtvvalueChange();
    }

    this.service.ztvEditedVal(this.defaultColor);
    this.service.ztvInputError(this.inputError);
  }
  resetZtv() {
    this.defaultColor = true;
    this.inputError = false;
    this.ztvval = this.properties["ZtvMinDimLevel"]["default"];
    for (var i = 0; i < this.column.length; i++) {
      let type = this.column[i];
      this.ngModelval[type] = this.properties[type]["default"];
    }
    this.hideZtvMinDimLevel = false;
    if (this.ngModelval["ZtvCurveSelection"] === 2 || this.curveSelectionStringValues[this.ngModelval["ZtvCurveSelection"]] === 2) {
      this.hideZtvMinDimLevel = true;
    }
    this.ZtvEnabledval = this.properties["ZtvEnabled"].default;
    this.rSetChanged(this.ZtvEnabledval);

    this.service.ztvEditedVal(this.defaultColor);
    this.sendZtvObj(this.ngModelval);
    this.service.ztvInputError(this.inputError);
  }
  sliderEvent() {}
  ZtvDimToOffEnabledvalueChange() {
    this.defaultColor = true;
    for (var i = 0; i < this.column.length; i++) {
      let type = this.column[i];
      const value = this.properties[type]["default"];
      for (var i = 0; i < this.column.length; i++) {
        let type = this.column[i];
        const value = this.properties[type]["default"];
        if (value != undefined) {
          if (
            this.ngModelval[type] != undefined &&
            this.ngModelval[type] != value
          ) {
            this.defaultColor = false;
          }
        }
      }
    }
    this.service.ztvEditedVal(this.defaultColor);
    this.sendZtvObj(this.ngModelval);
  }
  FadeOnTimevalueChange() {
    this.defaultColor = true;
    if (
      this.ngModelval["FadeOnTime"] < this.FadeOnTimeMin ||
      this.ngModelval["FadeOnTime"] > this.FadeOnTimeMax
    ) {
      this.inputError = true;
      this.service.ztvInputError(this.inputError);
    }
    for (var i = 0; i < this.column.length; i++) {
      let type = this.column[i];
      const value = this.properties[type]["default"];
      for (var i = 0; i < this.column.length; i++) {
        let type = this.column[i];
        const value = this.properties[type]["default"];
        if (value != undefined) {
          if (
            this.ngModelval[type] != undefined &&
            this.ngModelval[type] != value
          ) {
            this.defaultColor = false;
          }
        }
      }
    }
    this.service.ztvEditedVal(this.defaultColor);
    this.sendZtvObj(this.ngModelval);
  }

  FadeOffTimevalueChange() {
    this.defaultColor = true;
    if (
      this.ngModelval["FadeOffTime"] < this.FadeOffTimeMin ||
      this.ngModelval["FadeOffTime"] > this.FadeOffTimeMax
    ) {
      this.inputError = true;
      this.service.ztvInputError(this.inputError);
    }
    for (var i = 0; i < this.column.length; i++) {
      let type = this.column[i];
      const value = this.properties[type]["default"];
      for (var i = 0; i < this.column.length; i++) {
        let type = this.column[i];
        const value = this.properties[type]["default"];
        if (value != undefined) {
          if (
            this.ngModelval[type] != undefined &&
            this.ngModelval[type] != value
          ) {
            this.defaultColor = false;
          }
        }
      }
    }
    this.service.ztvEditedVal(this.defaultColor);
    this.sendZtvObj(this.ngModelval);
  }
  ZtvvalueChange() {
    this.defaultColor = true;
    this.hideZtvMinDimLevel = false;
    if (this.ngModelval["ZtvCurveSelection"] === 2 || this.curveSelectionStringValues[this.ngModelval["ZtvCurveSelection"]] === 2) {
      this.hideZtvMinDimLevel = true;
      this.ngModelval["ZtvMinDimLevel"] = this.ztvval;
      this.inputError = false;
      this.service.ztvInputError(this.inputError);
    } else {
      if (
        this.ngModelval["ZtvMinDimLevel"] < this.ztvvalMin ||
        this.ngModelval["ZtvMinDimLevel"] > this.ztvvalMax
      ) {
        this.inputError = true;
        this.service.ztvInputError(this.inputError);
      }
    }

    if (!isNullOrUndefined(this.column) && this.column.length > 0) {
        for (var i = 0; i < this.column.length; i++) {
          let type = this.column[i];
          const value = type === 'ZtvCurveSelection' ? this.curveSurveyValues[this.properties[type]["default"]] : this.properties[type]["default"];
          if (value != undefined) {
            if (
              this.ngModelval[type] != undefined &&
              this.ngModelval[type] != value
            ) {
              this.defaultColor = false;
            }
          }
        };
    }

    this.service.ztvEditedVal(this.defaultColor);
    this.sendZtvObj(this.ngModelval);
  }

  rSetChanged(val) {
    var changeVal = true;
    this.defaultColor = true;
    if (val === true) {
      changeVal = false;
    } else {
      this.defaultColor = false;
    }
    if (!isNullOrUndefined(this.column) && this.column.length > 0) {
      for (var i = 0; i < this.column.length; i++) {
        let type = this.column[i];
        const value = type === 'ZtvCurveSelection' ? this.curveSurveyValues[this.properties[type]["default"]] : this.properties[type]["default"];
        if (value != undefined) {
          if (
            this.ngModelval[type] != undefined &&
            this.ngModelval[type] != value
          ) {
            this.defaultColor = false;
          }
        }
      }
    };  
    this.ztvdisbaled = changeVal;
    this.ztvoptions = {
      floor: this.ztvvalMin,
      ceil: this.ztvvalMax,
      disabled: changeVal,
    };
    this.sendZtvObj(this.ngModelval);
    this.service.ztvEditedVal(this.defaultColor);
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  changedZtvDimToOffEnabledVal(val) {
    this.defaultColor = true;
    for (var i = 0; i < this.column.length; i++) {
      let type = this.column[i];
      const value = this.properties[type]["default"];
      for (var i = 0; i < this.column.length; i++) {
        let type = this.column[i];
        const value = this.properties[type]["default"];
        if (value != undefined) {
          if (
            this.ngModelval[type] != undefined &&
            this.ngModelval[type] != value
          ) {
            this.defaultColor = false;
          }
        }
      }
    }
    this.ztvoptions = {
      floor: this.ztvvalMin,
      ceil: this.ztvvalMax,
      disabled: false,
    };
    this.sendZtvObj(this.ngModelval);
    this.service.ztvEditedVal(this.defaultColor);
  }
  ngOnDestroy() {
    //this.sendZtvObj("");
    if (this.getZtvValuesSub) {
      this.getZtvValuesSub.unsubscribe();
    }
    if(this.resetZtvSub){
    this.resetZtvSub.unsubscribe();
    }
    this.ngModelval = [];
  }
}
