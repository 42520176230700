import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ApiService } from 'src/app/core-module/services/api.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { ReadDeviceComponent } from '../read-device/read-device.component';

@Component({
  selector: 'app-read-default-values-popup',
  templateUrl: './read-default-values-popup.component.html',
  styleUrls: ['./read-default-values-popup.component.scss']
})
export class ReadDefaultValuesPopupComponent implements OnInit, OnDestroy {
  isWritingPopup: boolean=false;
  @Input() public data;
  countOrangereen = 0;
  modalTitle: string = "Proceed with read / default values?";
  modalMessage: string = "System will proceed with partially read values if features are partially read or will proceed with default values if features are not read at all.";
  

  constructor(public activemodal: NgbActiveModal, private service: ApiService, public route:Router, private modalService: NgbModal) { }

  ngOnInit() {
    this.countOrangereen = 0;
    let corruptedFeatures=' ';
    this.data.forEach((element) => {
      if (element["flag"] === "orange" || element["flag"] === "red") {
        corruptedFeatures=corruptedFeatures+element["name"]+',';
        this.countOrangereen++;
      }
    });

    if(this.countOrangereen==0){
      this.data.forEach((element) => {
        corruptedFeatures=corruptedFeatures+element+',';
        this.countOrangereen++;
    });
    }

    if (this.countOrangereen > 0) {
      corruptedFeatures = corruptedFeatures.substring(0, corruptedFeatures.length - 1)+' ';
      this.modalTitle="Proceed with default values?";
      this.modalMessage="Device contains"+corruptedFeatures+"corrupted features, system will proceed with default values for these features."

    } 
    if(JSON.parse(localStorage.getItem('isWriting'))) {
      this.isWritingPopup = true;
    }
  }

  cancel() {
    this.activemodal.close();
    this.service.modalClose('close');
  }

  selectreader() {
    this.cancel();
    this.activemodal.close();
    localStorage.setItem('normalFlow', JSON.stringify(false));
    this.route.navigate(['/featureLanding']);
    // setTimeout(() => {
    // window.location.reload();      
    // }, 100);
  }

  retry() {
    this.activemodal.close();
    this.service.modalClose('close');
    this.service.modalConfigurationData('readDevice');
    const modalRef = this.modalService.open(ReadDeviceComponent,{windowClass: 'select-feature-modal',backdropClass: "customBackdrop"});
  }

  ngOnDestroy() {
    this.service.sendCorruptFeat('');
    this.service.sendNonCorrupt('');
    this.service.redFailedFeatures('');
    this.service.modalClose('');
    this.service.readerInfo('');
    this.service.sendFeatures('');
    this.countOrangereen = 0;
  }
}
