import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { HomeComponent } from "./shared-module/components/home-component/home.component";
import { LoginComponent } from "./shared-module/components/login-component/login.component";
import { LandingPageComponent } from "./shared-module/components/landing-page/landing-page.component";
import { AuthenticationService } from "./core-module/services/authentication";
import { AuthGuard } from "./core-module/guards/authGuard";
import { FeatureSupportedComponent } from "./shared-module/components/feature-supported/feature-supported.component";
import { SetFeatureLandingComponent } from "./shared-module/components/set-feature-landing/set-feature-landing.component";
import { FeatureNonSupportedComponent } from "./shared-module/components/feature-non-supported/feature-non-supported.component";
import { AboutComponent } from "./about/about.component";

const routes: Routes = [
  { path: "home", component: HomeComponent },
  { path: "about", component: AboutComponent },
  { path: "deviceList", component: LandingPageComponent },
  { path: "", redirectTo: "/home", pathMatch: "full" },
  //{ path: 'login', loadChildren: () => import(`./shared-module/components/login-component/login.module`).then(m => m.LoginModule) },
  { path: "login", component: HomeComponent },
  { path: "featureSupported", component: FeatureSupportedComponent },
  {
    path: "featureNonSupported",
    component: FeatureNonSupportedComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "featureLanding",
    component: SetFeatureLandingComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: "reload" })],
  exports: [RouterModule],
  providers: [AuthenticationService, AuthGuard],
})
export class AppRoutingModule {}
