import {
  Component,
  OnInit,
  ViewEncapsulation,
  OnDestroy,
  ChangeDetectorRef,
  HostListener,
  ViewChild,
  ElementRef,
} from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
  FormArray,
  NgForm,
} from "@angular/forms";
import { ApiService } from "../../../core-module/services/api.service";
import { NgbTabChangeEvent } from "@ng-bootstrap/ng-bootstrap";
import { pairwise, startWith, takeUntil } from "rxjs/operators";
import { Subscription, Subject } from "rxjs";
import { restore } from "./../../../../assets/json/restoreTooltip.en";

@Component({
  selector: "app-chartdata",
  templateUrl: "./chartdata.component.html",
  styleUrls: ["./chartdata.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ChartdataComponent implements OnInit, OnDestroy {
  addForm: FormGroup;
  rows: FormArray;
  rowLength = 0;

  registerForm: FormGroup;
  submitted = false;
  activeIdString: any = "1";
  checkValid = true;

  fewFieldValuePresent = false;

  dataToShow = [];
  Max;
  Steps;
  Initial;
  Final;

  hrs;
  powerLevel;

  unsubscribe = new Subject<any>();

  isTableValuesChanged = false;
  isValuesNumericGenerated = false;
  tableValueSubmit = false;

  isNumericCreatedRows = false;

  isGraphUpdated = false;

  disableGraph = false;

  defaultColor = true;

  disableAddBtn = false;

  workingHrsValuesArr: any = [];
  isAscending = false;
  isGraphDragUpdate = false;

  unsubscribeNumeric = new Subject<any>();

  enableCOnfigureFlow = false;
  menuState;
  menuPosition;
  menuPositionX;
  menuPositionY;
  menuWidth;
  menuHeight;
  windowWidth;
  windowHeight;

  selectedRow;
  disableAddAboveBtn = false;
  disableAddBelowBtn = false;

  disableNumericClear = true;

  unsubscribeGraphType = new Subject<any>();
  enableChecked = false;
  cloLite;
  showCloLite;
  activeHours;
  initialLevel;
  subscription;
  restoreText = restore;

  @ViewChild("contextMenu", { static: false }) contextMenu: ElementRef;

  graphRepresentationType = "";
  afterClearField = false;
  fileResponse: any = {};
  count: any;
  respVal: string;
  constructor(
    private fb: FormBuilder,
    private apiService: ApiService,
    private cdRef: ChangeDetectorRef
  ) {
    this.addForm = this.fb.group({});

    this.rows = this.fb.array([]);
  }

  ngOnInit() {
    
 
    localStorage.setItem("cloPointsStepValue", "0");
    try {
      let savesetCloRestoreCheck = JSON.parse(
        localStorage.getItem("saveSetData")
      );

      if (savesetCloRestoreCheck["resp"]["CLO"]["CloPoint2"] != undefined) {
        delete savesetCloRestoreCheck["resp"]["CLO"]["CloPoint2"];
        localStorage.setItem(
          "saveSetData",
          JSON.stringify(savesetCloRestoreCheck)
        );
      }
    } catch (error) {}
    this.apiService.getCloLoadFeatureFileConfigure.subscribe((response) => {
      if (response === "Configure_CLOLite") {
        this.enableCOnfigureFlow = true;
        let conf = JSON.parse(localStorage.getItem("configurationData"));
        let savesetClo = JSON.parse(localStorage.getItem("saveSetData"));
        //setTimeout(() => {
        if (conf.resp.CLOLite) {
          var cloLiteEnabled = conf.resp.CLOLite.CloLiteEnabled;
          this.apiService.setEnableGraph(cloLiteEnabled);
          this.apiService.sendliteHour(
            "" + conf.resp.CLOLite.CloLiteMaximumWorkingHours
          );
          this.apiService.sendLitePower(
            "" + conf.resp.CLOLite.CloLiteInitialPowerLevel
          );
          this.enableGraph(cloLiteEnabled);
          savesetClo["resp"]["CLOLite"]["CloLiteEnabled"] = cloLiteEnabled;
          savesetClo["resp"]["CLOLite"]["CloLiteInitialPowerLevel"] =
            "" + conf.resp.CLOLite.CloLiteInitialPowerLevel;
          savesetClo["resp"]["CLOLite"]["CloLiteMaximumWorkingHours"] =
            "" + conf.resp.CLOLite.CloLiteMaximumWorkingHours;
          localStorage.setItem("saveSetData", JSON.stringify(savesetClo));
          // this.enableChecked = Boolean(conf.resp.CLOLite.CloLiteEnabled);
          // this.disableGraph = !Boolean(conf.resp.CLOLite.CloLiteEnabled);
        }
        //}, 5000);
      } else if (response === "Configure_CLO") {
        this.enableCOnfigureFlow = true;
        let conf = JSON.parse(localStorage.getItem("configurationData"));
        let savesetClo = JSON.parse(localStorage.getItem("saveSetData"));

        if (
          savesetClo["resp"] != undefined &&
          savesetClo["resp"]["CLO"] != undefined
        ) {
          delete savesetClo["resp"]["CLO"];

          localStorage.setItem("saveSetData", JSON.stringify(savesetClo));
        }

        if (conf.resp.CLO) {
          var cloEnabled = conf.resp.CLO.CloEnabled;
          if (cloEnabled === true) {
            this.apiService.setCloDefaultColor(false);
          }
          this.apiService.setEnableGraph(cloEnabled);
          this.enableGraph(cloEnabled);
          this.enableChecked = cloEnabled;
          this.disableGraph = !Boolean(cloEnabled);
        }
      } else if (response === "Normal") {
        this.enableCOnfigureFlow = false;
        let conf = JSON.parse(localStorage.getItem("configurationData"));
        let savesetClo = JSON.parse(localStorage.getItem("saveSetData"));
        try {
          if (conf.resp.CLOLite) {
            var cloLiteEnabled = conf.resp.CLOLite.CloLiteEnabled;
            this.apiService.setEnableGraph(cloLiteEnabled);
            this.apiService.sendliteHour(
              "" + conf.resp.CLOLite.CloLiteMaximumWorkingHours
            );
            this.apiService.sendLitePower(
              "" + conf.resp.CLOLite.CloLiteInitialPowerLevel
            );
            savesetClo["resp"]["CLOLite"]["CloLiteEnabled"] = cloLiteEnabled;
            savesetClo["resp"]["CLOLite"]["CloLiteInitialPowerLevel"] =
              "" + conf.resp.CLOLite.CloLiteInitialPowerLevel;
            savesetClo["resp"]["CLOLite"]["CloLiteMaximumWorkingHours"] =
              "" + conf.resp.CLOLite.CloLiteMaximumWorkingHours;
            localStorage.setItem("saveSetData", JSON.stringify(savesetClo));
            // this.enableChecked = Boolean(conf.resp.CLOLite.CloLiteEnabled);
            // this.disableGraph = !Boolean(conf.resp.CLOLite.CloLiteEnabled);
          }
        } catch (error) {
          this.apiService.setEnableGraph(false);
          this.apiService.sendliteHour("" + 120);
          this.apiService.sendLitePower("" + 100);
          if (
            savesetClo &&
            savesetClo["resp"].hasOwnProperty("CLOLite") &&
            savesetClo["resp"]["CLOLite"].hasOwnProperty("CloLiteEnabled")
          ) {
            savesetClo["resp"]["CLOLite"]["CloLiteEnabled"] = false;
          }
          if (
            savesetClo &&
            savesetClo["resp"].hasOwnProperty("CLOLite") &&
            savesetClo["resp"]["CLOLite"].hasOwnProperty(
              "CloLiteInitialPowerLevel"
            )
          ) {
            savesetClo["resp"]["CLOLite"]["CloLiteInitialPowerLevel"] = "" + 80;
          }
          if (
            savesetClo &&
            savesetClo["resp"].hasOwnProperty("CLOLite") &&
            savesetClo["resp"]["CLOLite"].hasOwnProperty(
              "CloLiteMaximumWorkingHours"
            )
          ) {
            savesetClo["resp"]["CLOLite"]["CloLiteMaximumWorkingHours"] =
              "" + 100;
          }
          localStorage.setItem("saveSetData", JSON.stringify(savesetClo));
        }
      }
    });
    this.apiService.getCloDefaultValue.subscribe((response) => {
      if (response == "valid") {
        this.disableGraph = false;
        this.setDefaultGraph();
      }
    });
    this.fileResponse = JSON.parse(localStorage.getItem("configurationData"));
    let saveData = JSON.parse(localStorage.getItem("saveSetData"));
    if (
      this.fileResponse["resp"] != undefined &&
      this.fileResponse["resp"]["CLO"] != undefined
    ) {
      if (
        saveData &&
        saveData["resp"] != undefined &&
        saveData["resp"]["CLO"] != undefined
      ) {
        delete saveData["resp"]["CLO"];
        localStorage.setItem("saveSetData", JSON.stringify(saveData));
      }
    }
    saveData = JSON.parse(localStorage.getItem("saveSetData"));
    if (
      saveData &&
      saveData["resp"] &&
      (saveData["resp"]["CLO"] || saveData["resp"]["CLOLite"])
    ) {
      this.fileResponse = saveData;
    }
    ////
    let unSelectedFeat = Array(
      JSON.parse(localStorage.getItem("UnSelectedFeatConfig"))
    );

    let featIndex: number = 0;
    if (unSelectedFeat[0] != null && unSelectedFeat[0].length > 0) {
      unSelectedFeat[0].forEach((element) => {
        if (element["featureName"].toString().toUpperCase() === "CLO") {
          this.fileResponse = {};
          let CLOUnSelected: any = element["featureParameters"][0];
          let clonewEditedData = {
            resp: {
              CLO: CLOUnSelected,
            },
          };
          this.fileResponse = clonewEditedData;
          unSelectedFeat[0].splice(featIndex, 1);
          localStorage.setItem(
            "UnSelectedFeatConfig",
            JSON.stringify(unSelectedFeat[0])
          );
        } else if (
          element["featureName"].toString().toUpperCase() === "CLOLITE"
        ) {
          this.fileResponse = {};
          let CLOUnSelected: any = element["featureParameters"][0];
          let clonewEditedData = {
            resp: {
              CLOLite: CLOUnSelected,
            },
          };
          this.fileResponse = clonewEditedData;
          unSelectedFeat[0].splice(featIndex, 1);
          localStorage.setItem(
            "UnSelectedFeatConfig",
            JSON.stringify(unSelectedFeat[0])
          );
        } else {
          featIndex++;
        }
      });
    }

    /////

    this.dataToShow = [];
    this.rows.clear();
    this.onAddRow();
    this.defaultColor = true;
    this.dataToShow.push({ hour: 0, power: 100 });
    this.rows.controls[0]["controls"].workingHours.setValue(0);
    this.rows.controls[0]["controls"].outputLevel.setValue(100);
    this.checkAddesRowValuesChanged(this.rows.length - 1);
    this.apiService.sendGraphIsEditedData([{ hour: 0, power: 100 }]);
    this.apiService.getGraphRepresentation
      .pipe(takeUntil(this.unsubscribeGraphType))
      .subscribe((data) => {
        if (data != "") {
          this.graphRepresentationType = data;
          if (this.fileResponse["resp"] && this.fileResponse["resp"]["CLO"]) {
            this.dataToShow.pop();
          } else {
            let saveData = JSON.parse(localStorage.getItem("saveSetData"));
            if (!this.showCloLite && saveData) {
              if (
                saveData["resp"]["CLOLite"]["CloLiteInitialPowerLevel"] ===
                  100 &&
                saveData["resp"]["CLOLite"]["CloLiteMaximumWorkingHours"] ===
                  120
              ) {
                this.f.cloHour.setValue(100);
                this.f.initialOutput.setValue(80);
              }
            }
          }

          this.apiService.sendGraphData(
            this.graphRepresentationType,
            this.dataToShow
          );
        }
      });

    if (this.enableCOnfigureFlow === true) this.enableGraph(true);
    else this.enableGraph(false);
    this.addForm.addControl("rows", this.rows);
    this.rowLength = this.rows.length;
    this.registerForm = this.fb.group({
      cloHour: [
        "",
        [
          Validators.required,
          Validators.pattern("^[0-9]*$"),
          Validators.min(1),
          Validators.max(120),
        ],
      ],
      cloSteps: [
        "",
        [
          Validators.required,
          Validators.pattern("^[0-9]*$"),
          Validators.min(1),
          Validators.max(16),
        ],
      ],
      initialOutput: [
        "",
        [
          Validators.required,
          Validators.pattern("^[0-9]*$"),
          Validators.min(0),
          Validators.max(100),
        ],
      ],
      finalOutput: [
        "",
        [
          Validators.required,
          Validators.pattern("^[0-9]*$"),
          Validators.min(0),
          Validators.max(100),
        ],
      ],
    });

    this.subscription = this.apiService.getCloLiteHead.subscribe((data) => {
      if (data === "Clo") {
        this.showCloLite = true;
      } else if (data === "CloLite" || data === "Clolite") {
        this.showCloLite = false;
        this.setAllValidators();
        this.checkValidator();
      }
    });
    this.f.cloHour.valueChanges
      .pipe(takeUntil(this.unsubscribeNumeric))
      .subscribe((data) => {
        if (data !== "") {
          this.autoDetectNumericGenerator();
        }
      });
    this.f.cloSteps.valueChanges
      .pipe(takeUntil(this.unsubscribeNumeric))
      .subscribe((data) => {
        if (data !== "") {
          localStorage.setItem("cloPointsStepValue", String(data));
          this.autoDetectNumericGenerator();
        }
      });
    this.f.initialOutput.valueChanges
      .pipe(takeUntil(this.unsubscribeNumeric))
      .subscribe((data) => {
        if (data !== "") {
          this.autoDetectNumericGenerator();
        }
      });
    this.f.finalOutput.valueChanges
      .pipe(takeUntil(this.unsubscribeNumeric))
      .subscribe((data) => {
        if (data !== "") {
          this.autoDetectNumericGenerator();
        }
      });

    //Numeric Generator Values Auto Detect - Starts Here
    if (
      this.fileResponse &&
      this.fileResponse["resp"] &&
      (this.fileResponse["resp"]["CLOLite"] || this.fileResponse["resp"]["CLO"])
    ) {
      setTimeout(() => {
        this.clearAllValidators();
      }, 10);
      if (
        this.fileResponse["resp"]["CLO"] ||
        this.fileResponse["resp"]["CLOLite"]
      ) {
        this.enableChecked = this.fileResponse["resp"]["CLO"]
          ? this.fileResponse["resp"]["CLO"]["CloEnabled"]
          : this.fileResponse["resp"]["CLOLite"]["CloLiteEnabled"];
        if (this.enableChecked) {
          this.disableGraph = false;
          this.defaultColor = false;
          this.apiService.setEnableGraph(true);
        } else {
          this.disableGraph = true;
          if (
            this.fileResponse["resp"] &&
            this.fileResponse["resp"]["CLOLite"]
          ) {
            if (
              this.fileResponse["resp"]["CLOLite"][
                "CloLiteMaximumWorkingHours"
              ] == 100 &&
              this.fileResponse["resp"]["CLOLite"][
                "CloLiteInitialPowerLevel"
              ] == 80
            ) {
              this.defaultColor = true;
            } else {
              this.defaultColor = false;
            }
          }
          if (this.fileResponse["resp"] && this.fileResponse["resp"]["CLO"]) {
            if (this.fileResponse["resp"]["CLO"]["CloPoint1"] && this.fileResponse["resp"]["CLO"]["CloPoint1"]["Time"] &&
              this.fileResponse["resp"]["CLO"]["CloPoint1"]["Time"] == 0 &&
              this.fileResponse["resp"]["CLO"]["CloPoint1"]["Percentage"] == 100
            ) {
              this.defaultColor = true;
            } else {
              this.defaultColor = false;
            }
          }
          this.apiService.setEnableGraph(false);
        }
        this.autoDetectNumericGenerator();
        if (this.fileResponse["resp"]["CLOLite"]) {
          setTimeout(() => {
            this.fileResponse = "";
          }, 1000);
        } else {
          this.fileResponse = "";
        }
        setTimeout(() => {
          this.setAllValidators();
        }, 100);
      }
    }
    //Numeric Generator Values Auto Detect - Ends Here

    this.apiService.getChangedGraphData.subscribe((data) => {
      //change rows when drag graph changes -start here
      if (this.registerForm.valid) {
        this.isNumericCreatedRows = true;
      }
      if (!this.showCloLite) {
        if (data.length) {
          this.updateGraphDataToTable(data);
          this.f.cloHour.setValue(this.dataToShow[1].hour);
          this.f.initialOutput.setValue(this.dataToShow[0].power);
        }
      } else {
        if (data.length) {
          if (this.isGraphUpdated && this.isNumericCreatedRows) {
            this.confirmGraphChangeValues(data);
          }
          if (!this.isGraphUpdated && this.isNumericCreatedRows) {
            this.confirmGraphChangeValues(data);
          } else {
            if (!this.isNumericCreatedRows) {
              this.updateGraphDataToTable(data);
            }
          }
        }
      }
    }); //change rows when drag graph changes -ends here
    this.apiService.setCloDefaultColor(this.defaultColor);
    setTimeout(() => {
      if (this.enableCOnfigureFlow === true) {
        if (this.enableChecked) {
          this.disableGraph = false;
          this.enableChecked = true;
        } else {
          this.disableGraph = true;
          this.enableChecked = false;
        }
      }
    }, 5000);

    this.checkActualCloPoints();

    this.checkFinalDefaultTable();

  }
  checkFinalDefaultTable() {
    if (this.rows.length === 1) {
      let wH = this.rows.controls[0]["controls"].workingHours.value;
      let oL = this.rows.controls[0]["controls"].outputLevel.value;

      if (!(Number(wH) === 0) || !Number(oL === 100)) {
        this.apiService.setCloDefaultColor(false);
        this.defaultColor = false;
      }
    }
    if (this.enableChecked === true) {
      this.defaultColor = false;
      this.apiService.setCloDefaultColor(false);
    }
  
  
  
  }
  checkActualCloPoints() {
    localStorage.removeItem("cloPoints");
    let cloPointsAdded = this.rows.length;
    localStorage.setItem("cloPoints", String(cloPointsAdded));
  }
  setAllValidators() {
    if (this.showCloLite) {
      this.registerForm.controls["cloSteps"].setValidators([
        Validators.required,
        Validators.pattern("^[0-9]*$"),
        Validators.min(1),
        Validators.max(16),
      ]);
      this.registerForm.controls["finalOutput"].setValidators([
        Validators.required,
        Validators.pattern("^[0-9]*$"),
        Validators.min(0),
        Validators.max(100),
      ]);
      this.registerForm.controls["initialOutput"].setValidators([
        Validators.required,
        Validators.pattern("^[0-9]*$"),
        Validators.min(0),
        Validators.max(100),
      ]);
      this.registerForm.controls["cloHour"].setValidators([
        Validators.required,
        Validators.pattern("^[0-9]*$"),
        Validators.min(1),
        Validators.max(120),
      ]);
    } else {
      this.registerForm.controls["cloSteps"].setValidators([
        Validators.required,
        Validators.pattern("^[0-9]*$"),
        Validators.min(1),
        Validators.max(16),
      ]);
      this.registerForm.controls["finalOutput"].setValidators([
        Validators.required,
        Validators.pattern("^[0-9]*$"),
        Validators.min(0),
        Validators.max(100),
      ]);
      this.registerForm.controls["initialOutput"].setValidators([
        Validators.required,
        Validators.pattern("^[0-9]*$"),
        Validators.min(1),
        Validators.max(100),
      ]);
      this.registerForm.controls["cloHour"].setValidators([
        Validators.required,
        Validators.pattern("^[0-9]*$"),
        Validators.min(1),
        Validators.max(120),
      ]);
    }
  }
  clearAllValidators() {
    const closteps = this.registerForm.get("cloSteps");
    const cloFinal = this.registerForm.get("finalOutput");
    const initialOutput = this.registerForm.get("initialOutput");
    const cloHour = this.registerForm.get("cloHour");
    closteps.clearValidators();
    closteps.updateValueAndValidity();

    cloFinal.clearValidators();
    cloFinal.updateValueAndValidity();

    initialOutput.clearValidators();
    initialOutput.updateValueAndValidity();

    cloHour.clearValidators();
    cloHour.updateValueAndValidity();

    this.checkActualCloPoints();
  }
  checkValidator() {
    setTimeout(() => {
      const closteps = this.registerForm.controls["cloSteps"];
      const cloFinal = this.registerForm.controls["finalOutput"];
      if (!this.showCloLite) {
        closteps.clearValidators();
        cloFinal.clearValidators();
        closteps.updateValueAndValidity();
        cloFinal.updateValueAndValidity();
        this.checkActualCloPoints();
      }
    }, 100);
    // } else {
    //   closteps.setValidators([Validators.required,Validators.pattern("^[0-9]*$"),Validators.min(1),Validators.max(16)]);
    //   cloFinal.setValidators([Validators.required,Validators.pattern("^[0-9]*$"),Validators.min(0),Validators.max(100)]);
    // }
  }
  autoDetectNumericGenerator() {
    //Numeric Generator Values Auto Detect - Starts Here
    setTimeout(() => {
      this.checkValidator();
      this.disableNumericClear = false;
      if (
        this.registerForm.valid ||
        (this.fileResponse["resp"] &&
          (this.fileResponse["resp"]["CLO"] ||
            this.fileResponse["resp"]["CLOLite"]))
      ) {
        if (
          this.fileResponse["resp"] &&
          this.fileResponse["resp"]["CLOLite"] &&
          this.fileResponse["resp"]["CLOLite"]["CloLiteMaximumWorkingHours"]
        ) {
          this.Max =
            this.fileResponse["resp"]["CLOLite"]["CloLiteMaximumWorkingHours"];
        } else {
          this.Max = Number(this.f.cloHour.value);
          if (!this.showCloLite) {
            this.apiService.sendliteHour(this.Max);
          }
        }
        if (this.fileResponse["resp"] && this.fileResponse["resp"]["CLO"]) {
          this.Steps = Object.keys(this.fileResponse["resp"]["CLO"]).length - 1;
        } else {
          this.Steps = Number(this.f.cloSteps.value);
        }
        if (this.fileResponse["resp"] && this.fileResponse["resp"]["CLOLite"]) {
          this.Initial =
            this.fileResponse["resp"]["CLOLite"]["CloLiteInitialPowerLevel"];
          // this.dataToShow.push({ hour: 120, power: 100 })
          //this.Initial = 80;
          this.dataToShow.push({ hour: 100, power: 80 });
        } else {
          this.Initial = Number(this.f.initialOutput.value);
          if (!this.showCloLite) {
            this.apiService.sendLitePower(this.Initial);
          }
          // this.dataToShow.push({hour: 120 ,power:100})
        }
        this.Final = Number(this.f.finalOutput.value);
        if (!this.showCloLite) {
          this.hrs = this.Max;
          this.powerLevel = this.Initial;
          var intialHour, finalPower;
          if (this.dataToShow.length > 0) {
            intialHour = this.dataToShow[0].hour;
            finalPower = this.dataToShow[1].power;
            if (
              intialHour < 0 ||
              finalPower < 0 ||
              finalPower > 100 ||
              intialHour > 120
            ) {
              intialHour = 0;
              finalPower = 100;
            }
          } else {
            intialHour = 0;
            finalPower = 100;
          }
          this.dataToShow = [];
          this.dataToShow.push({ hour: intialHour, power: this.powerLevel });
          this.dataToShow.push({ hour: this.hrs, power: finalPower });
          if (
            this.fileResponse["resp"] &&
            this.fileResponse["resp"]["CLOLite"]
          ) {
            this.f.cloHour.setValue(this.dataToShow[1].hour);
            this.f.initialOutput.setValue(this.dataToShow[0].power);
          }
          this.fileResponse = "";
        } else {
          this.dataToShow = [];
          if (this.Steps !== 1) {
            for (let n = 0; n <= this.Steps - 1; n++) {
              this.onAddRow();
              if (this.fileResponse["resp"]) {
                if (
                  this.fileResponse["resp"]["CLO"] &&
                  this.fileResponse["resp"]["CLO"]["CloPoint" + [n + 1]]
                ) {
                  this.dataToShow.push({
                    hour: this.fileResponse["resp"]["CLO"][
                      "CloPoint" + [n + 1]
                    ]["Time"],
                    power:
                      this.fileResponse["resp"]["CLO"]["CloPoint" + [n + 1]][
                        "Percentage"
                      ],
                  });
                } else {
                  this.hrs = Math.round(
                    0 + (n * (this.Max - 0)) / (this.Steps - 1)
                  );
                  this.powerLevel = Math.round(
                    this.Initial +
                      (n * (this.Final - this.Initial)) / (this.Steps - 1)
                  );
                  this.dataToShow.push({
                    hour: this.hrs,
                    power: this.powerLevel,
                  });
                }
              } else {
                this.hrs = Math.round(
                  0 + (n * (this.Max - 0)) / (this.Steps - 1)
                );
                this.powerLevel = Math.round(
                  this.Initial +
                    (n * (this.Final - this.Initial)) / (this.Steps - 1)
                );
                this.dataToShow.push({
                  hour: this.hrs,
                  power: this.powerLevel,
                });
              }
            }
          }
          if (this.Steps === 1) {
            for (let n = 0; n <= this.Steps - 1; n++) {
              this.onAddRow();
              if (this.fileResponse["resp"]) {
                if (this.fileResponse["resp"]["CLO"]) {
                  this.defaultColor = true;
                  this.dataToShow.push({
                    hour: this.fileResponse["resp"]["CLO"][
                      "CloPoint" + [n + 1]
                    ]["Time"],
                    power:
                      this.fileResponse["resp"]["CLO"]["CloPoint" + [n + 1]][
                        "Percentage"
                      ],
                  });
                } else {
                  this.hrs = Math.round(0 + n * (this.Max - 0));
                  this.powerLevel = Math.round(
                    this.Initial + n * (this.Final - this.Initial)
                  );
                  this.dataToShow.push({
                    hour: this.hrs,
                    power: this.powerLevel,
                  });
                }
              } else {
                this.hrs = Math.round(0 + n * (this.Max - 0));
                this.powerLevel = Math.round(
                  this.Initial + n * (this.Final - this.Initial)
                );
                this.dataToShow.push({
                  hour: this.hrs,
                  power: this.powerLevel,
                });
              }
            }
          }
        }
        this.apiService.sendGraphIsEditedData(this.dataToShow);
        this.apiService.sendGraphData(
          this.graphRepresentationType,
          this.dataToShow
        );
        //this.checkActualCloPoints();
      } else {
        if (
          this.registerForm.valid === false &&
          this.fewFieldValuePresent === true
        ) {
          this.unsubscribe.next();
          this.rows.clear();
          this.onAddRow();
          this.dataToShow = [];
          this.dataToShow.push({ hour: 0, power: 100 });
          this.rows.controls[0]["controls"].workingHours.setValue(0);
          this.rows.controls[0]["controls"].outputLevel.setValue(100);
          // this.defaultColor=true;
          this.apiService.sendGraphIsEditedData([{ hour: 0, power: 100 }]);
          this.apiService.sendGraphData(
            this.graphRepresentationType,
            this.dataToShow
          );
          this.tableValueSubmit = false;
          this.isTableValuesChanged = false;
          this.checkAddesRowValuesChanged(this.rows.length - 1);
          //this.checkActualCloPoints();
        }
      }
    }, 0);
    setTimeout(() => {
      if (this.registerForm.valid) {
        this.apiService.sendGraphValidData("valid");
      } else {
        this.apiService.sendGraphValidData("invalid");
      }
    }, 100);
    if (this.fileResponse["resp"]) {
      if (this.fileResponse["resp"]["CLO"]) {
        const evt = {
          activeId: "1",
          nextId: "2",
        };
        this.onSubmit(evt);
        this.activeIdString = "2";
      }
    }
    this.afterClearField = false;
  } //Numeric Generator Values Auto Detect - Ends Here

  confirmGraphChangeValues(data) {
    if (
      confirm(
        "Values are changed from the numeric generator. Are you sure you want to proceed"
      )
    ) {
      this.updateGraphDataToTable(data);
    } else {
      this.dataToShow = [];
      for (let n = 0; n <= this.Steps - 1; n++) {
        this.hrs = Math.round(0 + (n * (this.Max - 0)) / (this.Steps - 1));
        this.powerLevel = Math.round(
          this.Initial + (n * (this.Final - this.Initial)) / (this.Steps - 1)
        );
        this.dataToShow.push({ hour: this.hrs, power: this.powerLevel });
      }
      this.apiService.sendGraphData(
        this.graphRepresentationType,
        this.dataToShow
      );
      if (!this.isNumericCreatedRows) {
        this.rows.clear();
        this.dataToShow.forEach((content, index) => {
          this.onAddRow();
          this.checkAddesRowValuesChanged(index);
        });
      }
    }
    this.checkActualCloPoints();
  }

  updateGraphDataToTable(data) {
    this.checkValidator();
    this.isNumericCreatedRows = false;
    this.unsubscribe.next();
    this.dataToShow = [];
    data.forEach((content, index) => {
      this.dataToShow.push({
        hour: Math.round(content.hour),
        power: Math.round(content.power),
      });
    });
    this.isGraphUpdated = true;
    if (this.showCloLite) {
      this.reInitializeValue();
    }
    //Drag Invalid Data Check - starts here
    this.dataToShow.forEach((content, index) => {
      if (index !== this.dataToShow.length - 1) {
        if (this.dataToShow[index].hour > this.dataToShow[index + 1].hour) {
          setTimeout(() => {
            this.rows.controls[index + 1]["controls"].workingHours.setErrors({
              incorrect: true,
            });
            this.apiService.sendGraphValidData("invalid");
            this.disableAddBtn = true;
          }, 0);
        }
      }
      this.checkActualCloPoints();
    });
    //Drag Invalid Data Check - ends here
    this.isNumericCreatedRows = false;
    if (this.showCloLite) {
      for (let key of Object.keys(this.f)) {
        this.f[key].setValue("");
      }
    }
    this.isTableValuesChanged = true;
    this.isGraphDragUpdate = true;
    this.apiService.getGraphRepresentation
      .pipe(takeUntil(this.unsubscribeGraphType))
      .subscribe((data) => {
        if (data != "") {
          this.graphRepresentationType = data;
          this.apiService.sendGraphData(
            this.graphRepresentationType,
            this.dataToShow
          );
        }
      });
    this.checkActualCloPoints();
  }

  tabChanged($event: NgbTabChangeEvent) {
    if ($event.nextId === "1") {
      this.isNumericCreatedRows = false;
    }
    if ($event.nextId === "2") {
      if (
        this.registerForm.valid ||
        (this.fileResponse["resp"] && this.fileResponse["resp"]["CLO"])
      ) {
        this.isValuesNumericGenerated = true;
      }
      this.onSubmit($event);
      // this.reInitializeValue();
    }
    this.fileResponse = "";
    if (this.isNumericCreatedRows) {
      this.defaultColor = false;
    }
    this.apiService.setCloDefaultColor(this.defaultColor);
  }

  get f() {
    return this.registerForm.controls;
  }

  onSubmit($event) {
    this.submitted = false;
    this.checkIfValueEntered();
    if (this.isTableValuesChanged !== true) {
      if (this.fewFieldValuePresent === true && this.registerForm.invalid) {
        //one of the values entered
        $event.preventDefault();
        this.confirmResetFields();
      } else {
        //No Values given
        if (!this.dataToShow.length) {
          //If value entered in table values retain it on tab change
          this.rows.clear();
          this.onAddRow();
          this.dataToShow = [];
          this.dataToShow.push({ hour: 0, power: 100 });
          this.rows.controls[0]["controls"].workingHours.setValue(0);
          this.rows.controls[0]["controls"].outputLevel.setValue(100);
          this.checkAddesRowValuesChanged(this.rows.length - 1);
          setTimeout(() => {
            this.isTableValuesChanged = false;
          }, 0);
          this.defaultColor = true;
          this.apiService.setCloDefaultColor(this.defaultColor);
          this.apiService.sendGraphIsEditedData([{ hour: 0, power: 100 }]);
          this.apiService.sendGraphData(
            this.graphRepresentationType,
            this.dataToShow
          );
        }
      }
    }

    if (
      this.registerForm.valid ||
      (this.fileResponse["resp"] &&
        this.fileResponse["resp"]["CLO"] &&
        this.fileResponse["resp"]["CLO"]["CloPoint1"])
    ) {
      this.isGraphUpdated = true;
      this.unsubscribe.next(); //clear rows on changing between tabs once numeric generated - starts here
      this.isNumericCreatedRows = false;
      this.rows.clear();
      this.dataToShow = [];
      this.Max = Number(this.f.cloHour.value);
      if (this.fileResponse["resp"] && this.fileResponse["resp"]["CLO"]) {
        this.Steps = Object.keys(this.fileResponse["resp"]["CLO"]).length - 1;
      } else {
        this.Steps = Number(this.f.cloSteps.value);
      }
      this.Initial = Number(this.f.initialOutput.value);
      this.Final = Number(this.f.finalOutput.value);
      this.dataToShow = [];
      if (this.Steps !== 1) {
        for (let n = 0; n <= this.Steps - 1; n++) {
          this.onAddRow();
          if (this.fileResponse["resp"]) {
            if (
              this.fileResponse["resp"]["CLO"] &&
              this.fileResponse["resp"]["CLO"]["CloPoint" + [n + 1]]
            ) {
              this.dataToShow.push({
                hour: this.fileResponse["resp"]["CLO"]["CloPoint" + [n + 1]][
                  "Time"
                ],
                power:
                  this.fileResponse["resp"]["CLO"]["CloPoint" + [n + 1]][
                    "Percentage"
                  ],
              });
            } else {
              this.hrs = Math.round(
                0 + (n * (this.Max - 0)) / (this.Steps - 1)
              );
              this.powerLevel = Math.round(
                this.Initial +
                  (n * (this.Final - this.Initial)) / (this.Steps - 1)
              );
              this.dataToShow.push({ hour: this.hrs, power: this.powerLevel });
            }
          } else {
            this.hrs = Math.round(0 + (n * (this.Max - 0)) / (this.Steps - 1));
            this.powerLevel = Math.round(
              this.Initial +
                (n * (this.Final - this.Initial)) / (this.Steps - 1)
            );
            this.dataToShow.push({ hour: this.hrs, power: this.powerLevel });
          }
        }
        setTimeout(() => {
          for (let n = 0; n <= this.Steps - 1; n++) {
            this.checkAddesRowValuesChanged(n);
          }
        }, 1000);
      }
      if (this.Steps === 1) {
        for (let n = 0; n <= this.Steps - 1; n++) {
          this.onAddRow();
          if (this.fileResponse["resp"]) {
            if (this.fileResponse["resp"]["CLO"]) {
              this.dataToShow.push({
                hour: this.fileResponse["resp"]["CLO"]["CloPoint" + [n + 1]][
                  "Time"
                ],
                power:
                  this.fileResponse["resp"]["CLO"]["CloPoint" + [n + 1]][
                    "Percentage"
                  ],
              });
              this.defaultColor = true;
              this.apiService.setCloDefaultColor(this.defaultColor);
            } else {
              this.hrs = Math.round(0 + n * (this.Max - 0));
              this.powerLevel = Math.round(
                this.Initial + n * (this.Final - this.Initial)
              );
              this.dataToShow.push({ hour: this.hrs, power: this.powerLevel });
            }
          } else {
            this.hrs = Math.round(0 + n * (this.Max - 0));
            this.powerLevel = Math.round(
              this.Initial + n * (this.Final - this.Initial)
            );
            this.dataToShow.push({ hour: this.hrs, power: this.powerLevel });
          }
        }
        setTimeout(() => {
          for (let n = 0; n <= this.Steps - 1; n++) {
            this.checkAddesRowValuesChanged(n);
          }
        }, 1000);
        this.isNumericCreatedRows = true;
      }
      setTimeout(() => {
        if (this.rowLength === 16) {
          this.disableAddBtn = true;
        } else {
          this.disableAddBtn = false;
        }
      }, 0);

      this.apiService.sendGraphIsEditedData(this.dataToShow);
      this.reInitializeValue();
      this.verifyTableValueChange();
      this.checkActualCloPoints();
    }
    if (this.submitted === false) {
      this.apiService.sendGraphValidData("valid");
    }
  }

  inputValueChanged($event) {
    this.fewFieldValuePresent = false;
    if ($event.key !== "Tab") {
      this.submitted = true;
    }
    if ($event.key === "Backspace" || $event.key === "Delete") {
      this.submitted = false;
      this.checkIfValueEntered();
      if (this.fewFieldValuePresent === false) {
        this.apiService.sendGraphValidData("valid");
      }
    }
  }

  onAddRow() {
    this.defaultColor = false;
    this.apiService.setCloDefaultColor(this.defaultColor);
    if (!this.isNumericCreatedRows) {
      this.rows.push(this.createItemFormGroup());
      this.rowLength = this.rows.length;
    }

    // if (this.isNumericCreatedRows && this.fileResponse['resp']) {//Release 1.0 chnages
    if (this.isNumericCreatedRows) {
      if (
        confirm(
          "Values are changed from the numeric generator. Are you sure you want to proceed"
        )
      ) {
        this.unsubscribe.next();
        this.isNumericCreatedRows = false;
        this.rows.push(this.createItemFormGroup());
        this.rowLength = this.rows.length;
        for (let key of Object.keys(this.f)) {
          this.f[key].setValue("");
        }
        this.submitted = false;
        this.isTableValuesChanged = true;
      }
    }
    setTimeout(() => {
      if (this.rowLength === 16) {
        this.disableAddBtn = true;
      } else {
        this.disableAddBtn = false;
      }
      if (!this.addForm.valid) {
        this.disableAddBtn = true;
        this.apiService.sendGraphValidData("invalid");
      } else {
        this.disableAddBtn = false;
        this.apiService.sendGraphValidData("valid");
      }
    }, 0);
    this.checkActualCloPoints();
  }
  //Enter Table Invalid Data Check - starts here
  checkWorkingHrsValuesOrder(position) {
    this.workingHrsValuesArr = [];
    // setTimeout(() => {
    this.rows.controls.forEach((content, index) => {
      if (index !== this.dataToShow.length) {
        //this.workingHrsValuesArr.push(Number(content.value.workingHours));
        this.workingHrsValuesArr.push(+content.value.workingHours);
      }
    });
    this.isAscending = true;
    this.workingHrsValuesArr.forEach((content, index) => {
      if (index == 0 && content > 0) {
        this.rows.controls[index]["controls"].workingHours.setErrors({
          incorrect: true,
        });
      }
      if (
        this.workingHrsValuesArr[index] < 0 ||
        this.workingHrsValuesArr[index] > 120
      ) {
        this.rows.controls[index]["controls"].workingHours.setErrors({
          incorrect: true,
        });
      }
      if (this.workingHrsValuesArr[index] === "") {
        this.rows.controls[index]["controls"].workingHours.setErrors({
          incorrect: true,
        });
      }
      let valueEnteredCheck = this.workingHrsValuesArr[index];

      if (Number.isNaN(Number(valueEnteredCheck))) {
        this.rows.controls[index]["controls"].workingHours.setErrors({
          incorrect: true,
        });
      }
      if (index !== this.workingHrsValuesArr.length - 1) {
        if (
          this.workingHrsValuesArr[index] > this.workingHrsValuesArr[index + 1]
        ) {
          this.isAscending = false;
          this.rows.controls[index + 1]["controls"].workingHours.setErrors({
            incorrect: true,
          });
        } else if (this.workingHrsValuesArr[index] === "") {
          this.rows.controls[index]["controls"].workingHours.setErrors({
            incorrect: true,
          });
        } else if (Number.isNaN(Number(valueEnteredCheck))) {
          this.rows.controls[index]["controls"].workingHours.setErrors({
            incorrect: true,
          });
        } else {
          this.rows.controls[index + 1]["controls"].workingHours.setErrors(
            null
          );
        }
      }
    });

    if (!this.isAscending) {
      setTimeout(() => {
        this.apiService.sendGraphValidData("invalid");
        this.disableAddBtn = true;
      }, 0);
    } else {
      //NO Error
      for (let r = 0; r < this.rows.length; r++) {
        // this.rows.controls[r]['controls'].workingHours.setErrors(null);
      }

      this.disableAddBtn = false;
      if (this.rowLength === 16) {
        this.disableAddBtn = true;
      }
    }
    // }, 0);
  }
  //Enter Table Invalid Data Check - ends here

  checkAddesRowValuesChanged(index) {
    //Not numeric generated, value changes/added row show graph - Starts Here
    this.rows.controls[index]["controls"].workingHours.valueChanges.subscribe(
      (value) => {
        this.isGraphDragUpdate = false;
        if (!this.isValuesNumericGenerated) {
          for (let key of Object.keys(this.f)) {
            this.f[key].setValue("");
          }
        } //clear numeric generator fields - starts here
        this.submitted = false;
        this.isTableValuesChanged = true; //clear numeric generator fields - ends here
        this.tableValueSubmit = true;
        setTimeout(() => {
          if (this.addForm.valid) {
            this.dataToShow = [];
            for (let r = 0; r < this.rows.length; r++) {
              this.dataToShow.push({
                hour: this.rows.controls[r]["controls"].workingHours.value,
                power: this.rows.controls[r]["controls"].outputLevel.value,
              });
            }
            this.apiService.sendGraphIsEditedData(this.dataToShow);
            this.apiService.sendGraphData(
              this.graphRepresentationType,
              this.dataToShow
            );
            if (this.rowLength === 16) {
              this.disableAddBtn = true;
            } else {
              this.disableAddBtn = false;
            }
            if (!this.isGraphDragUpdate) {
              //Enter Table Invalid Data Check - starts here
              this.checkWorkingHrsValuesOrder(index);
            }
          } else {
            if (!this.isGraphDragUpdate) {
              this.checkWorkingHrsValuesOrder(index);
              this.apiService.sendGraphValidData("invalid");
              this.disableAddBtn = true;
            }
          }
          if (!this.isGraphDragUpdate) {
            setTimeout(() => {
              if (this.addForm.valid) {
                this.apiService.sendGraphValidData("");
                this.dataToShow = [];
                for (let r = 0; r < this.rows.length; r++) {
                  this.dataToShow.push({
                    hour: this.rows.controls[r]["controls"].workingHours.value,
                    power: this.rows.controls[r]["controls"].outputLevel.value,
                  });
                }
                this.apiService.sendGraphIsEditedData(this.dataToShow);
                this.apiService.sendGraphData(
                  this.graphRepresentationType,
                  this.dataToShow
                );
              }
            }, 0);
          } //Enter Table Invalid Data Check - ends here
        }, 0);

        setTimeout(() => {
          if (this.addForm.valid) {
            this.disableAddAboveBtn = false;
            this.disableAddBelowBtn = false;
            this.apiService.sendGraphValidData("valid");
          } else {
            this.disableAddAboveBtn = true;
            this.disableAddBelowBtn = true;
            this.apiService.sendGraphValidData("invalid");
          }
        }, 100);
      }
    );
    this.rows.controls[index]["controls"].outputLevel.valueChanges.subscribe(
      (value) => {
        this.isGraphDragUpdate = false;
        if (!this.isValuesNumericGenerated) {
          for (let key of Object.keys(this.f)) {
            this.f[key].setValue("");
          }
        } //clear numeric generator fields - starts here
        this.submitted = false;
        this.isTableValuesChanged = true; //clear numeric generator fields - ends here
        this.tableValueSubmit = true;
        setTimeout(() => {
          if (this.addForm.valid) {
            this.dataToShow = [];
            for (let r = 0; r < this.rows.length; r++) {
              this.dataToShow.push({
                hour: this.rows.controls[r]["controls"].workingHours.value,
                power: this.rows.controls[r]["controls"].outputLevel.value,
              });
            }
            this.apiService.sendGraphIsEditedData(this.dataToShow);
            this.apiService.sendGraphData(
              this.graphRepresentationType,
              this.dataToShow
            );
            if (this.rowLength === 16) {
              this.disableAddBtn = true;
            } else {
              this.disableAddBtn = false;
            }
            if (!this.isGraphDragUpdate) {
              //Enter Table Invalid Data Check - starts here
              this.checkWorkingHrsValuesOrder(index);
            }
          } else {
            if (!this.isGraphDragUpdate) {
              this.checkWorkingHrsValuesOrder(index);
              this.apiService.sendGraphValidData("invalid");
              this.disableAddBtn = true;
            }
          }
          if (!this.isGraphDragUpdate) {
            setTimeout(() => {
              if (this.addForm.valid) {
                this.apiService.sendGraphValidData("");
              }
            }, 0);
          } //Enter Table Invalid Data Check - ends here
        }, 0);
        setTimeout(() => {
          if (this.addForm.valid) {
            this.disableAddAboveBtn = false;
            this.disableAddBelowBtn = false;
            this.apiService.sendGraphValidData("valid");
          } else {
            this.disableAddAboveBtn = true;
            this.disableAddBelowBtn = true;
            this.apiService.sendGraphValidData("invalid");
          }
        }, 100);
      }
    ); //Not numeric generated, value changes/added row show graph - Ends Here
    this.checkActualCloPoints();
  }

  onRemoveRow(rowIndex: number) {
    this.defaultColor = false;
    this.apiService.setCloDefaultColor(this.defaultColor);
    if (!this.isNumericCreatedRows) {
      this.rows.removeAt(rowIndex);
      this.rowLength = this.rows.length;
    }
    if (this.isNumericCreatedRows) {
      if (
        confirm(
          "Values are changed from the numeric generator. Are you sure you want to proceed"
        )
      ) {
        this.unsubscribe.next();
        this.isNumericCreatedRows = false;
        this.rows.removeAt(rowIndex);
        this.rowLength = this.rows.length;
        for (let key of Object.keys(this.f)) {
          this.f[key].setValue("");
        }
        this.submitted = false;
        this.isTableValuesChanged = true;
      }
    }

    setTimeout(() => {
      if (this.addForm.valid) {
        this.apiService.sendGraphValidData("valid");
        this.dataToShow = []; // remove row update grapg - starts here
        for (let r = 0; r < this.rows.length; r++) {
          this.dataToShow.push({
            hour: this.rows.controls[r]["controls"].workingHours.value,
            power: this.rows.controls[r]["controls"].outputLevel.value,
          });
        }
        this.apiService.sendGraphData(
          this.graphRepresentationType,
          this.dataToShow
        ); // remove row update grapg - ends here
      } else {
        this.apiService.sendGraphValidData("invalid");
      }
    }, 0);
    this.checkActualCloPoints();
  }

  createItemFormGroup(): FormGroup {
    return this.fb.group({
      workingHours: [
        null,
        [
          Validators.required,
          Validators.pattern("^[0-9]*$"),
          Validators.min(0),
          Validators.max(120),
        ],
      ],
      outputLevel: [
        null,
        [
          Validators.required,
          Validators.pattern("^[0-9]*$"),
          Validators.min(0),
          Validators.max(100),
        ],
      ],
    });
  }

  checkIfValueEntered() {
    if (this.registerForm.valid && !this.fileResponse["resp"]) {
      this.apiService.sendGraphValidData("valid");
    } else if (!this.registerForm.valid) {
      this.apiService.sendGraphValidData("invalid");
    }
    for (let key of Object.keys(this.f)) {
      //check any one value entered
      if (this.f[key].value === "") {
        this.fewFieldValuePresent = false;
      } else {
        this.fewFieldValuePresent = true;
        this.submitted = true;
        break;
      }
    }
    this.checkActualCloPoints();
  }

  reInitializeValue() {
    if (!this.isNumericCreatedRows) {
      this.rows.clear();
      this.dataToShow.forEach((content, index) => {
        this.onAddRow();
        this.checkAddesRowValuesChanged(index);
      });
    }
    if (this.isGraphUpdated) {
      this.dataToShow.forEach((content, index) => {
        this.rows.controls[index]["controls"].workingHours.setValue(
          content.hour
        );
        this.rows.controls[index]["controls"].outputLevel.setValue(
          content.power
        );
      });
    }

    if (!this.isGraphUpdated) {
      this.dataToShow.forEach((content, index) => {
        if (index !== this.dataToShow.length - 1) {
          this.rows.controls[index]["controls"].workingHours.setValue(
            content.hour
          );
          this.rows.controls[index]["controls"].outputLevel.setValue(
            content.power
          );
        }
      });
    }
    this.checkActualCloPoints();
  }

  verifyTableValueChange() {
    if (this.isNumericCreatedRows && this.Steps < this.dataToShow.length) {
      this.dataToShow.pop();
    }
    this.dataToShow.forEach((content, index) => {
      this.rows.controls[index]["controls"].workingHours.valueChanges
        .pipe(startWith(content.hour), pairwise())
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(([prev, next]: [any, any]) => {
          if (prev !== next) {
            if (
              confirm(
                "Values are changed from the numeric generator. Are you sure you want to proceed"
              )
            ) {
              this.unsubscribe.next();
              for (let key of Object.keys(this.f)) {
                this.f[key].setValue("");
              }
              this.submitted = false;
              this.isTableValuesChanged = true;
              this.isNumericCreatedRows = false;
            } else {
              this.unsubscribe.next();
              this.isTableValuesChanged = false;
              const tempData = this.dataToShow;
              this.rows.clear();
              setTimeout(() => {
                for (let n = 0; n <= this.Steps - 1; n++) {
                  this.rows.push(this.createItemFormGroup());
                }
                this.rowLength = this.rows.length;
                this.dataToShow = [];
                this.dataToShow = tempData;
                this.reInitializeValue();
                for (let n = 0; n <= this.Steps - 1; n++) {
                  this.checkAddesRowValuesChanged(n);
                }
                this.verifyTableValueChange();
              }, 0);
              // this.rows.controls[index]['controls'].workingHours.setValue(content.hour,{ onlySelf: true });
            }
          }
        });
      this.rows.controls[index]["controls"].outputLevel.valueChanges
        .pipe(startWith(content.power), pairwise())
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(([prev, next]: [any, any]) => {
          if (prev !== next) {
            if (
              confirm(
                "Values are changed from the numeric generator. Are you sure you want to proceed"
              )
            ) {
              this.unsubscribe.next();
              for (let key of Object.keys(this.f)) {
                this.f[key].setValue("");
              }
              this.submitted = false;
              this.isTableValuesChanged = true;
              this.isNumericCreatedRows = false;
            } else {
              this.unsubscribe.next();
              this.isTableValuesChanged = false;
              const tempData = this.dataToShow;
              this.rows.clear();
              setTimeout(() => {
                for (let n = 0; n <= this.Steps - 1; n++) {
                  this.rows.push(this.createItemFormGroup());
                }
                this.rowLength = this.rows.length;
                this.dataToShow = [];
                this.dataToShow = tempData;
                this.reInitializeValue();
                for (let n = 0; n <= this.Steps - 1; n++) {
                  this.checkAddesRowValuesChanged(n);
                }
                this.verifyTableValueChange();
              }, 0);
            }
          }
        });
      // }
    });
    this.checkActualCloPoints();
  }

  confirmResetFields() {
    if (
      confirm(
        "Action not complete or invalid entry. Values will be reset. Do you want to continue?"
      )
    ) {
      setTimeout(() => {
        for (let key of Object.keys(this.f)) {
          this.f[key].setValue("");
        }
        this.submitted = false;
        this.setDefaultGraph();
      }, 0);
    }
    this.checkActualCloPoints();
  }

  clearTableValueFields() {
    this.fileResponse = "";
    if (this.isNumericCreatedRows !== false || this.dataToShow.length > 1) {
      if (
        confirm(
          "Values are changed from the numeric generator. Are you sure you want to proceed"
        )
      ) {
        this.clearValues();
      }
    }
    if (this.isNumericCreatedRows === false) {
      this.clearValues();
    }
    this.defaultColor = false;
    this.apiService.setCloDefaultColor(this.defaultColor);
    this.disableAddAboveBtn = false;
    this.disableAddBelowBtn = false;
    this.checkActualCloPoints();
  }

  clearValues() {
    this.unsubscribe.next();
    this.isNumericCreatedRows = false;
    this.rows.clear();
    this.onAddRow();
    this.dataToShow = [];
    this.dataToShow.push({ hour: 0, power: 100 });
    this.rows.controls[0]["controls"].workingHours.setValue(0);
    this.rows.controls[0]["controls"].outputLevel.setValue(100);
    this.defaultColor = true;
    this.apiService.setCloDefaultColor(this.defaultColor);
    this.apiService.sendGraphIsEditedData([{ hour: 0, power: 100 }]);
    this.apiService.sendGraphData(
      this.graphRepresentationType,
      this.dataToShow
    );
    for (let key of Object.keys(this.f)) {
      this.f[key].setValue("");
    }
    this.tableValueSubmit = false;
    this.isTableValuesChanged = false;
    this.checkAddesRowValuesChanged(this.rows.length - 1);
    this.checkActualCloPoints();
  }

  enableGraph(value) {
    this.disableGraph = !value;
    this.defaultColor = !value;
    if (this.disableGraph) {
      //this.defaultColor = true;
      this.enableChecked = false;
    } else {
      //this.defaultColor = false;
      this.enableChecked = true;
    }

    if (this.registerForm) {
      var controls = this.registerForm.controls;
      if (
        controls["cloHour"].value == 100 &&
        controls["cloSteps"].value === "" &&
        controls["finalOutput"].value === "" &&
        controls["initialOutput"].value == 80 &&
        value === false
      ) {
        // if(this.rows.length>1)
        // {
        //   this.defaultColor = false;
        // }
        // else if(this.rows.length===1){
        //   var wHrs = this.rows.controls[0]['controls'].workingHours;
        //   var oLevel = this.rows.controls[0]['controls'].outputLevel;
        //   if(wHrs===0 && oLevel ===120)
        //   {
        //     this.defaultColor = true;
        //   }
        //   else
        //   this.defaultColor=false;
        // }
        // else
        this.defaultColor = true;
      } else {
        this.defaultColor = false;
      }
    }
    if (this.enableChecked) {
      this.defaultColor = false;
    }
    this.apiService.setEnableGraph(value);
    this.apiService.setCloDefaultColor(this.defaultColor);
  }

  setDefaultGraph() {
    if (!this.disableGraph) {
      this.fileResponse = "";
      this.enableChecked = false;
      this.disableGraph = true;
      this.apiService.setEnableGraph(false);
      this.activeIdString = "2";
      this.clearNumericFields();
      // this.defaultColor=true;
      this.unsubscribe.next();
      this.isNumericCreatedRows = false;
      this.rows.clear();
      this.onAddRow();
      this.dataToShow = [];
      this.dataToShow.push({ hour: 0, power: 100 });
      this.rows.controls[0]["controls"].workingHours.setValue(0);
      this.rows.controls[0]["controls"].outputLevel.setValue(100);
      this.rows.controls[0]["controls"].workingHours.valueChanges.subscribe(
        (value) => {
          // this.defaultColor=false;
        }
      );
      this.rows.controls[0]["controls"].outputLevel.valueChanges.subscribe(
        (value) => {
          // this.defaultColor=false;
        }
      );
      this.apiService.sendGraphIsEditedData([{ hour: 0, power: 80 }]);
      this.apiService.sendGraphData(
        this.graphRepresentationType,
        this.dataToShow
      );
      for (let key of Object.keys(this.f)) {
        this.f[key].setValue("");
      }
      if (!this.showCloLite) {
        this.f.cloHour.setValue(100);
        this.f.initialOutput.setValue(80);
      }
      this.tableValueSubmit = false;
      this.isTableValuesChanged = false;
      this.checkAddesRowValuesChanged(this.rows.length - 1);
    } else {
      this.enableChecked = true;
    }
    this.defaultColor = true;
    this.apiService.setCloDefaultColor(this.defaultColor);
    this.checkActualCloPoints();
  }

  AddRow() {
    this.onAddRow();
    this.checkAddesRowValuesChanged(this.rows.length - 1);
    this.checkActualCloPoints();
  }

  RemoveRow(index) {
    this.onRemoveRow(index);
    setTimeout(() => {
      if (!this.addForm.valid) {
        this.disableAddBtn = true;
        this.disableAddAboveBtn = true;
        this.disableAddBelowBtn = true;
      } else {
        this.disableAddBtn = false;
        this.disableAddAboveBtn = false;
        this.disableAddBelowBtn = false;
      }
      if (this.rowLength < 16) {
        this.disableAddBtn = false;
      }
    }, 0);
    this.checkWorkingHrsValuesOrder(index);
    this.checkActualCloPoints();
  }
  onRightClick($event, index) {
    this.selectedRow = index;
    $event.stopPropagation();
    this.menuState = true;
    this.positionMenu(event);

    if (this.rowLength === 16 || this.rowLength > 16) {
      this.disableAddBtn = true;
      this.disableAddAboveBtn = true;
      this.disableAddBelowBtn = true;
    }
  }

  getPosition(e) {
    let posx = 0;
    let posy = 0;
    if (e.pageX || e.pageY) {
      posx = e.pageX;
      posy = e.pageY;
    } else if (e.clientX || e.clientY) {
      posx =
        e.clientX +
        document.body.scrollLeft +
        document.documentElement.scrollLeft;
      posy =
        e.clientY +
        document.body.scrollTop +
        document.documentElement.scrollTop;
    }
    return { x: posx, y: posy };
  }

  positionMenu(e) {
    this.menuPosition = this.getPosition(e);
    this.menuPositionX = this.menuPosition.x;
    this.menuPositionY = this.menuPosition.y;
    this.cdRef.detectChanges();
    this.menuWidth = this.contextMenu.nativeElement.offsetWidth;
    this.menuHeight = this.contextMenu.nativeElement.offsetHeight;
    this.windowWidth = window.innerWidth;
    this.windowHeight = window.innerHeight;
    if (Math.abs(this.windowWidth - this.menuPositionX) < this.menuWidth) {
      this.menuPositionX = this.windowWidth - this.menuWidth - 250 + "px";
    } else {
      //this.menuPositionX = this.menuPositionX - 250+ "px";
      this.menuPositionX = 190 + "px";
    }
    if (Math.abs(this.windowHeight - this.menuPositionY) < this.menuHeight) {
      this.menuPositionY = this.windowHeight - this.menuHeight - 50 + "px";
    } else {
      this.menuPositionY = this.menuPositionY - 50 + "px";
    }
  }
  @HostListener("document:click", ["$event"])
  public documentClick(event: Event): void {
    this.menuState = false;
  }
  @HostListener("document:contextmenu", ["$event"])
  public documentRClick(event: Event): void {
    this.menuState = false;
  }

  addRowAbove() {
    if (this.rowLength <= 16) {
      this.defaultColor = false;
      this.apiService.setCloDefaultColor(this.defaultColor);
      if (!this.isNumericCreatedRows) {
        this.rows.insert(this.selectedRow, this.createItemFormGroup());
        this.rowLength = this.rows.length;
      }

      if (this.isNumericCreatedRows) {
        if (
          confirm(
            "Values are changed from the numeric generator. Are you sure you want to proceed"
          )
        ) {
          this.unsubscribe.next();
          this.isNumericCreatedRows = false;
          this.rows.insert(this.selectedRow, this.createItemFormGroup());
          this.rowLength = this.rows.length;
          for (let key of Object.keys(this.f)) {
            this.f[key].setValue("");
          }
          this.submitted = false;
          this.isTableValuesChanged = true;
        }
      }

      setTimeout(() => {
        if (this.rowLength >= 16) {
          this.disableAddBtn = true;
          this.disableAddAboveBtn = true;
          this.disableAddBelowBtn = true;
        } else {
          this.disableAddBtn = false;
          this.disableAddAboveBtn = false;
          this.disableAddBelowBtn = false;
        }
        if (!this.addForm.valid) {
          this.disableAddBtn = true;
          this.disableAddAboveBtn = true;
          this.disableAddBelowBtn = true;
          this.apiService.sendGraphValidData("invalid");
        } else {
          this.disableAddBtn = false;
          this.disableAddAboveBtn = false;
          this.disableAddBelowBtn = false;
          this.apiService.sendGraphValidData("valid");
        }
      }, 0);

      this.checkAddesRowValuesChanged(this.selectedRow);
      this.checkActualCloPoints();
    }
  }

  addRowBelow() {
    if (this.rowLength <= 16) {
      this.defaultColor = false;
      this.apiService.setCloDefaultColor(this.defaultColor);
      if (!this.isNumericCreatedRows) {
        this.rows.insert(this.selectedRow + 1, this.createItemFormGroup());
        this.rowLength = this.rows.length;
      }

      if (this.isNumericCreatedRows) {
        if (
          confirm(
            "Values are changed from the numeric generator. Are you sure you want to proceed"
          )
        ) {
          this.unsubscribe.next();
          this.isNumericCreatedRows = false;
          this.rows.insert(this.selectedRow + 1, this.createItemFormGroup());
          this.rowLength = this.rows.length;
          for (let key of Object.keys(this.f)) {
            this.f[key].setValue("");
          }
          this.submitted = false;
          this.isTableValuesChanged = true;
        }
      }

      setTimeout(() => {
        if (this.rowLength >= 16) {
          this.disableAddBtn = true;
          this.disableAddAboveBtn = true;
          this.disableAddBelowBtn = true;
        } else {
          this.disableAddBtn = false;
          this.disableAddAboveBtn = false;
          this.disableAddBelowBtn = false;
        }
        if (!this.addForm.valid) {
          this.disableAddBtn = true;
          this.disableAddAboveBtn = true;
          this.disableAddBelowBtn = true;
          this.apiService.sendGraphValidData("invalid");
        } else {
          this.disableAddBtn = false;
          this.disableAddAboveBtn = false;
          this.disableAddBelowBtn = false;
          this.apiService.sendGraphValidData("valid");
        }
      }, 0);

      this.checkAddesRowValuesChanged(this.selectedRow + 1);
      this.checkActualCloPoints();
    }
  }
  clearNumericFields() {
    this.disableNumericClear = true;
    for (let key of Object.keys(this.f)) {
      this.f[key].setValue("");
    }
    this.unsubscribe.next();
    this.isNumericCreatedRows = false;
    this.rows.clear();
    this.onAddRow();
    this.dataToShow = [];
    this.dataToShow.push({ hour: 0, power: 100 });
    this.rows.controls[0]["controls"].workingHours.setValue(0);
    this.rows.controls[0]["controls"].outputLevel.setValue(100);
    this.rows.controls[0]["controls"].workingHours.valueChanges.subscribe(
      (value) => {
        this.defaultColor = false;
        this.apiService.setCloDefaultColor(this.defaultColor);
      }
    );
    this.rows.controls[0]["controls"].outputLevel.valueChanges.subscribe(
      (value) => {
        this.defaultColor = false;
        this.apiService.setCloDefaultColor(this.defaultColor);
      }
    );
    this.apiService.sendGraphIsEditedData([{ hour: 0, power: 100 }]);
    this.apiService.sendGraphData(
      this.graphRepresentationType,
      this.dataToShow
    );
    this.tableValueSubmit = false;
    this.isTableValuesChanged = false;
    this.checkAddesRowValuesChanged(this.rows.length - 1);
    this.afterClearField = true;
    this.checkActualCloPoints();
  }
  ngOnDestroy() {
    this.unsubscribeGraphType.next();
    this.unsubscribeNumeric.next();
    // this.subscription.unsubscribe();
  }
}
