import {
  Component,
  OnInit,
  ChangeDetectorRef,
  Input,
  OnDestroy,
  ViewRef,
} from "@angular/core";
import { ApiService } from "src/app/core-module/services/api.service";
import { FieldType } from "@ngx-formly/core";
import { isNullOrUndefined } from "util";

@Component({
  selector: "app-apm-dim",
  templateUrl: "./apm-dim.component.html",
  styleUrls: ["./apm-dim.component.scss"],
})
export class ApmDimComponent extends FieldType implements OnInit, OnDestroy {
  @Input() field;
  column: any;
  properties: any;
  ngModelval: any = [];
  inputError: any = [];
  quickConfigvalue: boolean;
  quickConfigCustomCSS: string;
  setQuickConfig: string;
  ampdimDefaultColor;
  ampDimCreateFile: boolean;
  mtpEnabledval: any;
  selectedInterface = [];

  showFieldsAsPerAllOf: any;

  VmainsStop: any;
  VmainsStopMin: any;
  VmainsStopMax: any;
  VmainsStopValue: any;
  VmainsStopOption: { floor: any; ceil: any; minLimit: any };
  VmainsStart: any;
  VmainsStartMin: any;
  VmainsStartValue: any;
  VmainsStartOption: { floor: any; ceil: any; minLimit: any };
  StopDimLevel: any;
  StopDimLevelMin: any;
  StopDimLevelMax: any;
  StopDimLevelValue: any;
  StopDimLevelOption: { floor: any; ceil: any; minLimit: any };
  StartDimLevel: any;
  StartDimLevelMin: any;
  StartDimLevelValue: any;
  StartDimLevelOption: { floor: any; ceil: any; minLimit: any };
  mtpDefaultColor: boolean;
  DimLevel: any;
  mtpPhilipsLedLightEnginesSelected: boolean;
  DimLevelMin: any;
  DimLevelMax: any;
  VmainsStartMax: any;
  StartDimLevelMax: any;
  constructor(private service: ApiService, private ref: ChangeDetectorRef) {
    super();
  }
  sliderEvent() {}

  ngOnInit() {
    this.service.getAmpDimGraphdata.subscribe((responseGraph) => {
      for (let index = 0; index < responseGraph.length; index++) {
        const element = responseGraph[index];
        if (index === 0) {
          this.StopDimLevelValue = Math.round(element.dyanYOutput);
        }
        if (index === 1) {
          this.VmainsStopValue = Math.round(element.dyanXTime);
        }
        if (index === 2) {
          this.StartDimLevelValue = Math.round(element.dyanYOutput);
          this.VmainsStartValue = Math.round(element.dyanXTime);
        }
      }
      this.ngModelval["StopDimLevelValue"] = this.StopDimLevelValue;
      this.ngModelval["VmainsStopValue"] = this.VmainsStopValue;
      this.ngModelval["StartDimLevelValue"] = this.StartDimLevelValue;
      this.ngModelval["VmainsStartValue"] = this.VmainsStartValue;

      this.checkDefaults();
      this.checkInvalidValues();
    });
    this.service.getAmpDimDefaultValue.subscribe((response) => {
      if (response == "valid") {
        this.ampdimDefaultColor = true;

        this.resetDefaultAmpDimValues();
      }
    });
    // this.service.getAmpDimDefault.subscribe((response) => {
    //   if (response) {
    //     this.ampdimDefaultColor = true;
    //     this.resetDefaultAmpDimValues();
    //   }
    // });
    // this.service.getAmpDimData.subscribe((data) => {
    //   if (data) {
    //     this.ngModelval = data;
    //     this.ngModelval["VmainsStopValue"] = this.VmainsStopValue =
    //       data["VmainsStopValue"];
    //     this.ngModelval["VmainsStartValue"] = this.VmainsStartValue =
    //       data["VmainsStartValue"];
    //     this.ngModelval["StopDimLevelValue"] = this.StopDimLevelValue =
    //       data["StopDimLevelValue"];
    //     this.ngModelval["StartDimLevelValue"] = this.StartDimLevelValue =
    //       data["StartDimLevelValue"];
    //     this.checkInvalidValues();
    //   }
    // });
    ///From API
    this.column = this.field.requiredProperties;
    this.properties = this.field.properties;
    let type = this.column[0];
    this.service.ampdimInputError(false);

    this.VmainsStop = this.properties.VmainsStop;
    this.VmainsStopMin = this.VmainsStop.minimum;
    this.VmainsStopMax = this.VmainsStop.maximum;
    this.ngModelval["VmainsStopValue"] = this.VmainsStopValue =
      this.VmainsStop.default;
    this.ngModelval["VmainsStopMax"] = this.VmainsStop.maximum;
    this.VmainsStopOption = {
      floor: this.VmainsStopMin,
      ceil: this.VmainsStopMax,
      minLimit: this.VmainsStartMin,
    };
    this.VmainsStart = this.properties.VmainsStart;
    this.VmainsStartMin = this.VmainsStart.minimum;
    this.VmainsStartMax = this.VmainsStart.maximum;
    this.ngModelval["VmainsStartValue"] = this.VmainsStartValue =
      this.VmainsStart.default;
    this.ngModelval["VmainsStartMax"] = this.VmainsStart.maximum;
    this.VmainsStartOption = {
      floor: this.VmainsStartMin,
      ceil: this.VmainsStartMax,
      minLimit: this.VmainsStopMin,
    };

    this.StopDimLevel = this.properties.StopDimLevel;
    this.StopDimLevelMin = this.StopDimLevel.minimum;
    this.StopDimLevelMax = this.StopDimLevel.maximum;
    this.ngModelval["StopDimLevelValue"] = this.StopDimLevelValue =
      this.StopDimLevel.default;
    this.ngModelval["StopDimLevelMax"] = this.StopDimLevel.maximum;
    this.StopDimLevelOption = {
      floor: this.StopDimLevelMin,
      ceil: this.StopDimLevelMax,
      minLimit: this.StartDimLevelMin,
    };
    this.StartDimLevel = this.properties.StartDimLevel;
    this.StartDimLevelMin = this.StartDimLevel.minimum;
    this.StartDimLevelMax = this.StartDimLevel.maximum;
    this.ngModelval["StartDimLevelValue"] = this.StartDimLevelValue =
      this.StartDimLevel.default;
    this.ngModelval["StartDimLevelMax"] = this.StartDimLevel.maximum;
    this.StartDimLevelOption = {
      floor: this.StartDimLevelMin,
      ceil: this.StartDimLevelMax,
      minLimit: this.StopDimLevelMin,
    };

    ////
    let unSelectedFeat = Array(
      JSON.parse(localStorage.getItem("UnSelectedFeatConfig"))
    );

    let isConfigEdited = false;
    let featIndex: number = 0;
    if (unSelectedFeat[0] != null) {
      unSelectedFeat[0].forEach((element) => {
        if (
          !isNullOrUndefined(element) &&
          !isNullOrUndefined(element["featureName"]) &&
          element["featureName"].toString().toUpperCase() === "AMPDIM"
        ) {
          isConfigEdited = true;
          this.ngModelval["StopDimLevelValue"] = this.StopDimLevelValue =
            element["featureParameters"][0]["StopDimLevel"];
          this.ngModelval["VmainsStopValue"] = this.VmainsStopValue =
            element["featureParameters"][0]["VmainsStop"];
          this.ngModelval["StartDimLevelValue"] = this.StartDimLevelValue =
            element["featureParameters"][0]["StartDimLevel"];
          this.ngModelval["VmainsStartValue"] = this.VmainsStartValue =
            element["featureParameters"][0]["VmainsStart"];

          unSelectedFeat[0].splice(featIndex, 1);
          localStorage.setItem(
            "UnSelectedFeatConfig",
            JSON.stringify(unSelectedFeat[0])
          );
          this.checkInvalidValues();
          this.checkDefaults();
        } else {
          featIndex++;
        }
      });
    }

    /////

    this.service.getquickConfig.subscribe((data) => {
      if (data === "quickConfig") {
        this.quickConfigvalue = true;
        this.quickConfigCustomCSS = "quick-custom-inputbox textBoxUnit";
        this.setQuickConfig = "quickConfig";
      } else if (data === "standard") {
        this.quickConfigvalue = false;
        this.quickConfigCustomCSS = "custom-inputbox";
        this.setQuickConfig = "standard";
      }
      setTimeout(() => {
        if (this.ref && !(this.ref as ViewRef).destroyed) {
          this.ref.detectChanges();
        }
      });
    });
    if (isConfigEdited) {
    } else {
      setTimeout(() => {
        const ampDimloaddedFetaure = JSON.parse(
          localStorage.getItem("configurationData")
        );
        if (ampDimloaddedFetaure) {
          if (
            ampDimloaddedFetaure.resp &&
            (ampDimloaddedFetaure.resp.AmpDim ||
              ampDimloaddedFetaure.resp.ampdim)
          ) {
            if (ampDimloaddedFetaure.resp.AmpDim)
              this.addAmpDimLoadedFeatureReadValues(
                ampDimloaddedFetaure.resp.AmpDim
              );
            if (ampDimloaddedFetaure.resp.ampdim)
              this.addAmpDimLoadedFeatureReadValues(
                ampDimloaddedFetaure.resp.ampdim
              );
          }
        }
      }, 1000);
    }

    setTimeout(() => {
      this.sendAmpDimdata();
    }, 1000);
  }
  addAmpDimLoadedFeatureReadValues(ampDimLoad: any) {
    let ampDimLoadOnj = ampDimLoad;
    var keys = Object.keys(ampDimLoadOnj);
    var values = Object.values(ampDimLoadOnj);
    keys.forEach((key, i) => {
      this.textValChanged(values[i], key + "Value");
    });
    var result = [];
    keys.forEach((key, i) => (result[key] = values[i]));
    this.ngModelval["StopDimLevelValue"] = this.StopDimLevelValue = result[
      "StopDimLevel"
    ]
      ? result["StopDimLevel"]
      : result["StopDimLevelValue"];
    this.ngModelval["VmainsStopValue"] = this.VmainsStopValue = result[
      "VmainsStop"
    ]
      ? result["VmainsStop"]
      : result["VmainsStopValue"];
    this.ngModelval["StartDimLevelValue"] = this.StartDimLevelValue = result[
      "StartDimLevel"
    ]
      ? result["StartDimLevel"]
      : result["StartDimLevelValue"];
    this.ngModelval["VmainsStartValue"] = this.VmainsStartValue = result[
      "VmainsStart"
    ]
      ? result["VmainsStart"]
      : result["VmainsStartValue"];
    this.checkInvalidValues();
    this.checkDefaults();
  }

  setConfigurationData(value, keyVal) {
    if (!isNullOrUndefined(value)) {
      let ampDimObj = {};
      var key = keyVal;
      var data = {};
      var addNew = {
        [keyVal]: {},
      };
      data = JSON.parse(localStorage.getItem("configurationData"));
      if (data && !isNullOrUndefined(data["resp"])) {
        ampDimObj = Object.assign({}, value);
        data["resp"][keyVal] = ampDimObj;
      } else {
        data["resp"] = addNew;
        ampDimObj = Object.assign({}, value);
        data["resp"][keyVal] = ampDimObj;
      }
      localStorage.setItem("configurationData", JSON.stringify(data));
    }
  }

  sendAmpDimdata() {
    this.service.sendAmpDimdata(this.ngModelval);
    this.setConfigurationData(this.ngModelval, "AmpDim");
  }

  checkDefaults() {
    try {
      if (this.VmainsStop.default != this.VmainsStopValue) {
        this.service.setAmpDimDefaultColor(false);
      }

      if (
        this.StopDimLevel.default != this.StopDimLevelValue ||
        this.StopDimLevelValue == null
      ) {
        this.service.setAmpDimDefaultColor(false);
      }

      if (this.VmainsStart.default != this.VmainsStartValue) {
        this.service.setAmpDimDefaultColor(false);
      }

      if (this.StartDimLevel.default != this.StartDimLevelValue) {
        this.service.setAmpDimDefaultColor(false);
      }
    } catch {}
  }
  resetDefaultAmpDimValues() {
    try {
      this.ngModelval["StopDimLevelValue"] = this.StopDimLevelValue =
        this.StopDimLevel.default;
      this.ngModelval["VmainsStopValue"] = this.VmainsStopValue =
        this.VmainsStop.default;
      this.ngModelval["StartDimLevelValue"] = this.StartDimLevelValue =
        this.StartDimLevel.default;
      this.ngModelval["VmainsStartValue"] = this.VmainsStartValue =
        this.VmainsStart.default;
      // this.ngModelval["PhilipsLedLightEnginesSelected"] =
      //   this.PhilipsLedLightEnginesSelected =
      //     this.properties.PhilipsLedLightEnginesSelected.const;
      for (var type in this.inputError) {
        this.inputError[type] = false;
      }
    } catch {}
    setTimeout(() => {
      this.checkDefaults();
      this.ampDimCreateFile = false;
      this.service.ampdimInputError(this.ampDimCreateFile);
      this.sendAmpDimdata();
    }, 1500);
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  checkInvalidValues() {
    this.ampDimCreateFile = false;
    for (var type in this.inputError) {
      if (this.inputError[type]) {
        this.ampDimCreateFile = true;
      }
    }
    this.checkDefaults();
    this.service.ampdimInputError(this.ampDimCreateFile);
  }
  //Low
  VmainsStopValueChange(value) {
    this.inputError["VmainsStopValue"] = false;
    if (value > this.VmainsStartValue) {
      this.ngModelval["VmainsStopValue"] = this.ngModelval["VmainsStartValue"];
      this.VmainsStopValue = this.ngModelval["VmainsStartValue"];
    } else if (
      (value >= this.VmainsStopMin || value <= this.VmainsStopMax) &&
      value <= this.VmainsStartValue
    ) {
      this.ngModelval["VmainsStopValue"] = value;
      this.VmainsStopValue = value;
    }

    if (this.VmainsStop.default != this.VmainsStopValue) {
      this.service.setAmpDimDefaultColor(false);
    }
    this.checkInvalidValues();
    this.sendAmpDimdata();
  }
  //Low V %
  StopDimLevelValueChange(value) {
    this.inputError["StopDimLevelValue"] = false;

    if (value > this.StartDimLevelValue) {
      this.ngModelval["StopDimLevelValue"] =
        this.ngModelval["StartDimLevelValue"];
      this.StopDimLevelValue = this.ngModelval["StartDimLevelValue"];
    } else if (
      (value >= this.StopDimLevelMin || value <= this.StopDimLevelMax) &&
      value <= this.StartDimLevelValue
    ) {
      this.ngModelval["StopDimLevelValue"] = value;
      this.StopDimLevelValue = value;
    }

    if (this.StopDimLevel.default != this.StopDimLevelValue) {
      this.service.setAmpDimDefaultColor(false);
    }
    this.checkInvalidValues();
    this.sendAmpDimdata();
  }

  //High
  VmainsStartValueChange(value) {
    this.inputError["VmainsStartValue"] = false;
    this.ngModelval["VmainsStartValue"] = value;
    this.VmainsStartValue = value;

    if (value >= this.VmainsStartMin || value <= this.VmainsStartMax) {
      this.ngModelval["VmainsStartValue"] = value;
      this.VmainsStartValue = value;
    }
    if (this.VmainsStopValue > this.VmainsStartValue) {
      this.ngModelval["VmainsStopValue"] = value;
      this.VmainsStopValue = value;
    }

    if (this.VmainsStart.default != this.VmainsStartValue) {
      this.service.setAmpDimDefaultColor(false);
    }
    this.checkInvalidValues();
    this.sendAmpDimdata();
  }
  //High %
  StartDimLevelValueChange(value) {
    this.inputError["StartDimLevelValue"] = false;
    if (value >= this.StartDimLevelMin || value <= this.StartDimLevelMax) {
      this.ngModelval["StartDimLevelValue"] = value;
      this.StartDimLevelValue = value;
    }
    if (this.StopDimLevelValue > this.StartDimLevelValue) {
      this.ngModelval["StopDimLevelValue"] = value;
      this.StopDimLevelValue = value;
    }

    if (this.StartDimLevel.default != this.StartDimLevelValue) {
      this.service.setAmpDimDefaultColor(false);
    }
    this.checkInvalidValues();
    this.sendAmpDimdata();
  }

  textValChanged(value, colName) {
    value = parseInt(value);
    if (isNaN(value) || value.length === 0) {
      value = 0;
    }
    this.ampdimDefaultColor = false;
    this.inputError[colName] = false;
    this.ampDimCreateFile = false;
    if (colName == "VmainsStopValue") {
      this.inputError[colName] = false;
      if (value < this.VmainsStopMin || value > this.VmainsStopMax) {
        this.inputError[colName] = true;
        this.service.setAmpDimDefaultColor(false);
        this.ngModelval["VmainsStopValue"] = value;
      } else {
        if (value > this.VmainsStartValue) {
          this.inputError[colName] = true;
          this.ngModelval["VmainsStopValue"] = value;
        } else {
          this.ngModelval["VmainsStopValue"] = value;
          this.VmainsStopValue = value;
          this.inputError[colName] = false;
        }
      }
      if (this.VmainsStop.default != this.VmainsStopValue) {
        this.service.setAmpDimDefaultColor(false);
      }
    } else if (colName == "StopDimLevelValue") {
      this.inputError[colName] = false;
      if (value < this.StopDimLevelMin || value > this.StopDimLevelMax) {
        this.inputError[colName] = true;
        this.service.setAmpDimDefaultColor(false);
        this.ngModelval["StopDimLevelValue"] = value;
      } else {
        if (value > this.StartDimLevelValue) {
          this.inputError[colName] = true;
          this.ngModelval["StopDimLevelValue"] = value;
        } else {
          this.ngModelval["StopDimLevelValue"] = value;
          this.StopDimLevelValue = value;
          this.inputError[colName] = false;
        }
      }
      if (this.StopDimLevel.default != this.StopDimLevelValue) {
        this.service.setAmpDimDefaultColor(false);
      }
      this.StopDimLevelValue = value;
    } else if (colName == "VmainsStartValue") {
      this.inputError[colName] = false;
      if (value < this.VmainsStartMin || value > this.VmainsStartMax) {
        this.inputError[colName] = true;
        this.service.setAmpDimDefaultColor(false);
        this.ngModelval["VmainsStartValue"] = value;
        // this.ngModelval["VmainsStopValue"] = value;
        // this.VmainsStopValue = value;
        if (
          this.ngModelval["VmainsStopValue"] < this.VmainsStopMin ||
          this.ngModelval["VmainsStopValue"] > this.VmainsStopMax
        ) {
          this.inputError["VmainsStopValue"] = true;
        }
      } else {
        if (value < this.VmainsStopValue) {
          this.ngModelval["VmainsStopValue"] = value;
          this.VmainsStopValue = value;
          this.ngModelval["VmainsStartValue"] = value;
          this.VmainsStartValue = value;
        } else {
          this.ngModelval["VmainsStartValue"] = value;
          this.VmainsStartValue = value;
          this.inputError[colName] = false;
          // this.ngModelval["VmainsStopValue"] = value;
          // this.VmainsStopValue = value;
          if (
            this.ngModelval["VmainsStopValue"] < this.VmainsStopMin ||
            this.ngModelval["VmainsStopValue"] > this.VmainsStopMax
          ) {
            this.inputError["VmainsStopValue"] = true;
          } else {
            this.inputError["VmainsStopValue"] = false;
          }
        }
      }
      if (this.VmainsStart.default != this.VmainsStartValue) {
        this.service.setAmpDimDefaultColor(false);
      }
    } else if (colName == "StartDimLevelValue") {
      this.inputError[colName] = false;
      if (value < this.StartDimLevelMin || value > this.StartDimLevelMax) {
        this.inputError[colName] = true;
        this.service.setAmpDimDefaultColor(false);
        this.ngModelval["StartDimLevelValue"] = value;
        // this.ngModelval["StopDimLevelValue"] = value;
        // this.StopDimLevelValue = value;
        if (
          this.ngModelval["StopDimLevelValue"] < this.StopDimLevelMin ||
          this.ngModelval["StopDimLevelValue"] > this.StopDimLevelMax
        ) {
          this.inputError["StopDimLevelValue"] = true;
        }
      } else {
        if (value < this.StopDimLevelValue) {
          this.ngModelval["StopDimLevelValue"] = value;
          this.StopDimLevelValue = value;
          this.ngModelval["StartDimLevelValue"] = value;
          this.StartDimLevelValue = value;
          this.inputError["StopDimLevelValue"] = false;
        } else {
          this.ngModelval["StartDimLevelValue"] = value;
          this.StartDimLevelValue = value;
          this.inputError[colName] = false;
          // this.ngModelval["StopDimLevelValue"] = value;
          // this.StopDimLevelValue = value;
          this.inputError["StopDimLevelValue"] = false;
        }
      }
      if (this.StartDimLevel.default != this.StartDimLevelValue) {
        this.service.setAmpDimDefaultColor(false);
      }
    }
    this.checkInvalidValues();
    this.sendAmpDimdata();
  }

  ngOnDestroy() {}
}
