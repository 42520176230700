import { Component, OnInit } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ConfigureDeviceModalInputComponent } from "../configure-device-modal-input/configure-device-modal-input.component";
import { ApiService } from "../core-module/services/api.service";
@Component({
  selector: "app-configure-device-modal-input-error",
  templateUrl: "./configure-device-modal-input-error.component.html",
  styleUrls: ["./configure-device-modal-input-error.component.scss"],
})
export class ConfigureDeviceModalInputErrorComponent implements OnInit {
  pathVal: string;
  constructor(
    private apiService: ApiService,
    public activemodal: NgbActiveModal,
    private modalService: NgbModal
  ) {}
  goBack() {
    const modalRef = this.modalService.open(
      ConfigureDeviceModalInputComponent,
      {
        windowClass: "select-feature-modal",
        backdropClass: "customBackdrop",
      }
    );
  }
  ngOnInit() {
    this.pathVal = this.apiService.getImageUrl(window.location.hostname);
  }
}
