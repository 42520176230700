import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  ChangeDetectorRef,
  ViewRef,
} from "@angular/core";
import { FieldType } from "@ngx-formly/core";
import { ApiService } from "src/app/core-module/services/api.service";
import { isNullOrUndefined } from "util";

@Component({
  selector: "app-codedmains",
  templateUrl: "./codedmains.component.html",
  styleUrls: ["./codedmains.component.scss"],
})
export class CodedmainsComponent
  extends FieldType
  implements OnInit, OnDestroy
{
  @Input() field;
  codedmainsDefaultColor;
  featureVersion: any;
  column: any;
  properties: any;
  ngModelval: any = [];
  inputError: any = [];
  codedmainsCreateFile: boolean;
  quickConfigvalue: boolean;
  quickConfigCustomCSS: string;
  quickConfigCustomCSSFallbackScene: string;
  setQuickConfig: string;
  isCodedMainsEnabled: any;
  valueSet = [];
  valueSetSecond = [];
  FadeTime: any;
  FadeTimeMin: any;
  FadeTimeMax: any;
  FastFadeTime: any;
  FastFadeTimeMin: any;
  FastFadeTimeMax: any;
  isFastFadeTimeEnabled: boolean;
  isLastDefinedDimLevelEnabled: boolean;
  FallbackDetectionTime: any;
  FallbackDetectionTimeMin: any;
  FallbackDetectionTimeMax: any;
  FallbackDetectionTimeValue: any;
  FallbackDetectionTimeOption: { floor: any; ceil: any };
  PowerOnLevel: any;
  PowerOnLevelMin: any;
  PowerOnLevelMax: any;
  PowerOnLevelValue: any;
  PowerOnLevelOption: { floor: any; ceil: any };
  Scene1: any;
  Scene1Min: any;
  Scene1Max: any;
  Scene1Value: any;
  Scene2: any;
  Scene2Min: any;
  Scene2Max: any;
  Scene2Value: any;
  Scene5: any;
  Scene5Min: any;
  Scene5Max: any;
  Scene5Value: any;
  Scene6: any;
  Scene6Min: any;
  Scene6Max: any;
  Scene6Value: any;
  Scene9: any;
  Scene9Min: any;
  Scene9Max: any;
  Scene9Value: any;
  Scene3: any;
  Scene3Min: any;
  Scene3Max: any;
  Scene3Value: any;
  Scene7: any;
  Scene7Min: any;
  Scene7Max: any;
  Scene7Value: any;
  Scene10: any;
  fadeTimeDynamicVal: any;
  fastfadeTimeDynamicVal: any;
  Scene10Min: any;
  Scene10Max: any;
  Scene10Value: any;
  Scene11: any;
  Scene11Min: any;
  Scene11Max: any;
  Scene11Value: any;
  Scene4: any;
  Scene4Min: any;
  Scene4Max: any;
  Scene4Value: any;
  Scene8: any;
  Scene8Min: any;
  Scene8Max: any;
  Scene8Value: any;
  Scene12: any;
  Scene12Min: any;
  Scene12Max: any;
  Scene12Value: any;
  FallbackScene: any;
  FallbackSceneMin: any;
  FallbackSceneMax: any;
  FallbackSceneValue: any;
  ischeckboxChecked: boolean;
  fadeTimeDataset = {
    "0": "0",
    "1": "0.7",
    "2": "1.0",
    "3": "1.4",
    "4": "2.0",
    "5": "2.8",
    "6": "4.0",
    "7": "5.7",
    "8": "8.0",
    "9": "11.3",
    "10": "16.0",
    "11": "22.6",
    "12": "32.0",
    "13": "45.3",
    "14": "64.0",
    "15": "90.5",
  };
  pathVal:string;
  fastfadeTimeDataset = {
    "0": "<25",
    "1": "25",
    "2": "50",
    "3": "75",
    "4": "100",
    "5": "125",
    "6": "150",
    "7": "175",
    "8": "200",
    "9": "225",
    "10": "250",
    "11": "275",
    "12": "300",
    "13": "325",
    "14": "350",
    "15": "375",
    "16": "400",
    "17": "425",
    "18": "450",
    "19": "475",
    "20": "500",
    "21": "525",
    "22": "550",
    "23": "575",
    "24": "600",
    "25": "625",
    "26": "650",
    "27": "675",
  };
  constructor(private service: ApiService, private ref: ChangeDetectorRef) {
    super();
  }
  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  ngOnInit() {
    this.pathVal = this.service.getImageUrl(window.location.hostname);
    this.service.getcodedmainsDefaultValue.subscribe((response) => {
      if (response == "valid") {
        this.codedmainsDefaultColor = true;
        this.resetDefaultcodedmains();
      }
    });
    this.service.getcodedmainsDefault.subscribe((response) => {
      if (response) {
        this.codedmainsDefaultColor = true;
        this.resetDefaultcodedmains();
      }
    });
    this.service.getcodedmainsData.subscribe((data) => {
      if (data) {
        this.ngModelval = data;
        this.ngModelval["FadeTime"] = data["FadeTime"];
        this.ngModelval["FastFadeTime"] = data["FastFadeTime"];
        this.ngModelval["FallbackDetectionTime"] =
          data["FallbackDetectionTime"];
        this.ngModelval["PowerOnLevel"] = data["PowerOnLevel"];
        this.ngModelval["FallbackScene"] = data["FallbackScene"];
        this.ngModelval["Scene1"] = data["Scene1"];
        this.ngModelval["Scene2"] = data["Scene2"];
        this.ngModelval["Scene3"] = data["Scene3"];
        this.ngModelval["Scene4"] = data["Scene4"];
        this.ngModelval["Scene5"] = data["Scene5"];
        this.ngModelval["Scene6"] = data["Scene6"];
        this.ngModelval["Scene7"] = data["Scene7"];
        this.ngModelval["Scene8"] = data["Scene8"];
        this.ngModelval["Scene9"] = data["Scene9"];
        this.ngModelval["Scene10"] = data["Scene10"];
        this.ngModelval["Scene11"] = data["Scene11"];
        this.ngModelval["Scene12"] = data["Scene12"];
        this.checkdfault();
        this.checkInvalidValues();
      }
    });
    this.ngModelval["ischeckboxChecked"] = this.ischeckboxChecked = false;
    this.featureVersion = this.field.featureVersion;
    this.column = this.field.requiredProperties;
    this.properties = this.field.properties;
    this.fadeFastFadeOptions();
    this.FallbackScene = this.properties.FallbackScene;
    this.FallbackSceneMin =
      this.field.oneOf[0].properties.FallbackScene.minimum;
    this.FallbackSceneMax =
      this.field.oneOf[0].properties.FallbackScene.maximum;
    this.ngModelval["FallbackScene"] = this.FallbackSceneValue =
      this.FallbackScene.default;
    this.FallbackDetectionTime = this.properties.FallbackDetectionTime;
    this.FallbackDetectionTimeMin = this.FallbackDetectionTime.minimum;
    this.FallbackDetectionTimeMax = this.FallbackDetectionTime.maximum;
    this.ngModelval["FallbackDetectionTime"] = this.FallbackDetectionTimeValue =
      this.FallbackDetectionTime.default;
    this.FallbackDetectionTimeOption = {
      floor: this.FallbackDetectionTimeMin,
      ceil: this.FallbackDetectionTimeMax,
    };
    this.PowerOnLevel = this.properties.PowerOnLevel;
    this.PowerOnLevelMin = this.PowerOnLevel.minimum;
    this.PowerOnLevelMax = this.PowerOnLevel.maximum;
    this.ngModelval["PowerOnLevel"] = this.PowerOnLevelValue =
      this.PowerOnLevel.default;
    this.PowerOnLevelOption = {
      floor: this.PowerOnLevelMin,
      ceil: this.PowerOnLevelMax,
    };
    this.Scene12 = this.properties.Scene12;
    this.Scene12Min = this.Scene12.minimum;
    this.Scene12Max = this.Scene12.maximum;
    this.ngModelval["Scene12"] = this.Scene12Value = this.Scene12.default;
    this.Scene4 = this.properties.Scene4;
    this.Scene4Min = this.Scene4.minimum;
    this.Scene4Max = this.Scene4.maximum;
    this.ngModelval["Scene4"] = this.Scene4Value = this.Scene4.default;
    this.Scene1 = this.properties.Scene1;
    this.Scene1Min = this.Scene1.minimum;
    this.Scene1Max = this.Scene1.maximum;
    this.ngModelval["Scene1"] = this.Scene1Value = this.Scene1.default;
    this.Scene11 = this.properties.Scene11;
    this.Scene11Min = this.Scene11.minimum;
    this.Scene11Max = this.Scene11.maximum;
    this.ngModelval["Scene11"] = this.Scene11Value = this.Scene11.default;
    this.Scene2 = this.properties.Scene2;
    this.Scene2Min = this.Scene2.minimum;
    this.Scene2Max = this.Scene2.maximum;
    this.ngModelval["Scene2"] = this.Scene2Value = this.Scene2.default;
    this.Scene5 = this.properties.Scene5;
    this.Scene5Min = this.Scene5.minimum;
    this.Scene5Max = this.Scene5.maximum;
    this.ngModelval["Scene5"] = this.Scene5Value = this.Scene5.default;
    this.Scene6 = this.properties.Scene6;
    this.Scene6Min = this.Scene6.minimum;
    this.Scene6Max = this.Scene6.maximum;
    this.ngModelval["Scene6"] = this.Scene6Value = this.Scene6.default;
    this.Scene9 = this.properties.Scene9;
    this.Scene9Min = this.Scene9.minimum;
    this.Scene9Max = this.Scene9.maximum;
    this.ngModelval["Scene9"] = this.Scene9Value = this.Scene9.default;
    this.Scene3 = this.properties.Scene3;
    this.Scene3Min = this.Scene3.minimum;
    this.Scene3Max = this.Scene3.maximum;
    this.ngModelval["Scene3"] = this.Scene3Value = this.Scene3.default;
    this.Scene7 = this.properties.Scene7;
    this.Scene7Min = this.Scene7.minimum;
    this.Scene7Max = this.Scene7.maximum;
    this.ngModelval["Scene7"] = this.Scene7Value = this.Scene7.default;
    this.Scene10 = this.properties.Scene10;
    this.Scene10Min = this.Scene10.minimum;
    this.Scene10Max = this.Scene10.maximum;
    this.ngModelval["Scene10"] = this.Scene10Value = this.Scene10.default;
    this.Scene8 = this.properties.Scene8;
    this.Scene8Min = this.Scene8.minimum;
    this.Scene8Max = this.Scene8.maximum;
    this.ngModelval["Scene8"] = this.Scene8Value = this.Scene8.default;
    this.service.getquickConfig.subscribe((data) => {
      if (data === "quickConfig") {
        this.quickConfigvalue = true;
        this.quickConfigCustomCSS = "quick-custom-inputbox textBoxUnit";
        this.quickConfigCustomCSSFallbackScene = "quick-custom-inputbox";
        this.setQuickConfig = "quickConfig";
      } else if (data === "standard") {
        this.quickConfigvalue = false;
        this.quickConfigCustomCSS = "custom-inputbox";
        this.quickConfigCustomCSSFallbackScene = "custom-inputbox";
        this.setQuickConfig = "standard";
      }
      setTimeout(() => {
        if (this.ref && !(this.ref as ViewRef).destroyed) {
          this.ref.detectChanges();
        }
      });
    });

    let unSelectedFeat = Array(
      JSON.parse(localStorage.getItem("UnSelectedFeatConfig"))
    );
    let isConfigEdited = false;
    let featIndex: number = 0;
    if (unSelectedFeat[0] != null) {
      unSelectedFeat[0].forEach((element) => {
        if (!isNullOrUndefined(element) && !isNullOrUndefined(element["featureName"]) && element["featureName"].toString().toUpperCase() === "CODEDMAINS") {
          isConfigEdited = true;
          let codedMains: any = element["featureParameters"][0];
          this.codedMainsLoadValues(codedMains);
          unSelectedFeat[0].splice(featIndex, 1);
          localStorage.setItem(
            "UnSelectedFeatConfig",
            JSON.stringify(unSelectedFeat[0])
          );
        } else {
          featIndex++;
        }
      });
    }

    /////

    if (isConfigEdited) {

    } else {
      setTimeout(() => {
        const codedMainsLoadFeaturesProps = JSON.parse(
          localStorage.getItem("configurationData")
        );
        if (codedMainsLoadFeaturesProps) {
          if (
            codedMainsLoadFeaturesProps.resp &&
            (codedMainsLoadFeaturesProps.resp.CodedMains)
          ) {
            if (codedMainsLoadFeaturesProps.resp.CodedMains) {
              this.codedMainsLoadValues(
                codedMainsLoadFeaturesProps.resp.CodedMains
              );
            }
          }
        }
      }, 1000);
    }

    setTimeout(() => {
      this.sendcodedmainsdata();
    }, 1000);

  }

  sendcodedmainsdata(){
    this.service.sendcodedmainsdata(this.ngModelval);
    this.setConfigurationData(this.ngModelval, "CodedMains"); 
  }

  setConfigurationData(value, keyVal) {
    if(!isNullOrUndefined(value)){
      let codedMainsObj = {};
      var key = keyVal;
      var data = {};
      var addNew = {
        [keyVal]: {},
      };
      data = JSON.parse(localStorage.getItem("configurationData"));
      if (data && !isNullOrUndefined(data["resp"])) {
        codedMainsObj = Object.assign({}, value);
        data["resp"][keyVal] = codedMainsObj;
      } else {
        data["resp"] = addNew;
        codedMainsObj = Object.assign({}, value);
        data["resp"][keyVal] = codedMainsObj;
      }
      localStorage.setItem("configurationData", JSON.stringify(data));
    }
  }


  fadeFastFadeOptions() {
    this.valueSet = [];
    this.valueSetSecond = [];
    if (this.properties.FadeTime) {
      this.FadeTime = this.properties.FadeTime;
      this.ngModelval["FadeTime"] = this.FadeTime.default;
      this.fadeTimeDynamicVal = this.fadeTimeDataset[this.FadeTime.default];
      this.FadeTimeMin = this.properties.FadeTime.minimum;
      this.FadeTimeMax = this.properties.FadeTime.maximum;
      for (let i = this.FadeTimeMin; i <= this.FadeTimeMax; i++) {
        this.valueSet.push(i);
      }
    }
    if (this.properties.FastFadeTime) {
      this.FastFadeTime = this.properties.FastFadeTime;
      this.ngModelval["FastFadeTime"] = this.FastFadeTime.default;
      this.fastfadeTimeDynamicVal =
        this.fastfadeTimeDataset[this.FastFadeTime.default];
      this.FastFadeTimeMin = this.properties.FastFadeTime.minimum;
      this.FastFadeTimeMax = this.properties.FastFadeTime.maximum;
      this.FadeTime = this.properties.FadeTime;
      if (this.FadeTime.default != 0) {
        this.isFastFadeTimeEnabled = true;
      } else {
        this.isFastFadeTimeEnabled = false;
      }
      for (let i = this.FastFadeTimeMin; i <= this.FastFadeTimeMax; i++) {
        this.valueSetSecond.push(i);
      }
    }
  }
  codedMainsLoadValues(CodedMains: any) {
    let CodedMainsObj = CodedMains;
    var keys = Object.keys(CodedMainsObj);
    var values = Object.values(CodedMainsObj);
    keys.forEach((key, i) => {
      this.textValChanged(values[i], key);
    }); 
    var result = [];
    keys.forEach((key, i) => (result[key] = values[i]));
    this.ngModelval = result;
    //this.fadeFastFadeOptions();
    this.ngModelval["FadeTime"] = result["FadeTime"];
    //this.FadeTime = result["FadeTime"];
  //this.onChangeList(result["FadeTime"]);
        this.ngModelval["FastFadeTime"] = result["FastFadeTime"];
        //this.FastFadeTime = result["FastFadeTime"];
        //this.onChangeFastFadeTimeList(result["FastFadeTime"]);
        this.ngModelval["FallbackDetectionTime"] =
        result["FallbackDetectionTime"];
        this.FallbackDetectionTimeValue = result["FallbackDetectionTime"];
        this.ngModelval["PowerOnLevel"] = result["PowerOnLevel"];
        this.PowerOnLevelValue =  result["PowerOnLevel"];
        this.ngModelval["FallbackScene"] = result["FallbackScene"];
        this.FallbackSceneValue =  result["FallbackScene"];
      if(this.FallbackSceneValue === this.field.oneOf[1].properties.FallbackScene.const){
        this.ngModelval["ischeckboxChecked"] = this.ischeckboxChecked = true;
        this.changedVal(this.ischeckboxChecked);
        this.ngModelval["FallbackScene"] = this.FallbackScene.default;
        this.FallbackSceneValue =  this.FallbackScene.default;
      }
        this.ngModelval["Scene1"] = result["Scene1"];
        this.Scene1Value = result["Scene1"];
        this.ngModelval["Scene2"] = result["Scene2"];
        this.Scene2Value = result["Scene2"];
        this.ngModelval["Scene3"] = result["Scene3"];
        this.Scene3Value = result["Scene3"];
        this.ngModelval["Scene4"] = result["Scene4"];
        this.Scene4Value = result["Scene4"];
        this.ngModelval["Scene5"] = result["Scene5"];
        this.Scene5Value = result["Scene5"];
        this.ngModelval["Scene6"] = result["Scene6"];
        this.Scene6Value = result["Scene6"];
        this.ngModelval["Scene7"] = result["Scene7"];
        this.Scene7Value = result["Scene7"];
        this.ngModelval["Scene8"] = result["Scene8"];
        this.Scene8Value = result["Scene8"];
        this.ngModelval["Scene9"] = result["Scene9"];
        this.Scene9Value = result["Scene9"];
        this.ngModelval["Scene10"] = result["Scene10"];
        this.Scene10Value = result["Scene10"];
        this.ngModelval["Scene11"] = result["Scene11"];
        this.Scene11Value = result["Scene11"];
        this.ngModelval["Scene12"] = result["Scene12"];
        this.Scene12Value = result["Scene12"];
    this.checkdfault();
    this.checkInvalidValues();
    this.sendcodedmainsdata();
  }
  powerOnLevelOptionValueChange(value) {
    if (value >= this.PowerOnLevelMin && value <= this.PowerOnLevelMax) {
      this.ngModelval["PowerOnLevel"] = value;
      this.inputError["PowerOnLevel"] = false;
      this.PowerOnLevelOption = {
        floor: this.PowerOnLevelMin,
        ceil: this.PowerOnLevelMax,
      };
      this.checkInvalidValues();
      this.sendcodedmainsdata();
    } else {
      this.inputError["PowerOnLevel"] = true;
      this.codedmainsCreateFile = true;
    }
  }
  scene1ValChanged(val) {
    let value: number = Number(val);
    setTimeout(() => {
      if (value > this.Scene1Max) {
        this.ngModelval["Scene1"] = this.Scene1Value = 100;
        this.inputError["Scene1"] = false;
      } else if (value < 0) {
        this.ngModelval["Scene1"] = this.Scene1Value = Math.round(0);
        this.inputError["Scene1"] = false;
      } else if (val === "" || val.length === 0) {
        this.inputError["Scene1"] = true;
        this.ngModelval["Scene1"] = this.Scene1Value = val;
      } else {
        if (!(val === "")) {
          this.ngModelval["Scene1"] = this.Scene1Value = value;
          this.inputError["Scene1"] = false;
        }
      }
      setTimeout(() => {
        this.sendcodedmainsdata();
      }, 500);
      this.checkdfault();
      this.checkInvalidValues();
    }, 0);
  }
  scene2ValChanged(val) {
    let value: number = Number(val);
    setTimeout(() => {
      if (value > this.Scene2Max) {
        this.ngModelval["Scene2"] = this.Scene2Value = 100;
        this.inputError["Scene2"] = false;
      } else if (value < 0) {
        this.ngModelval["Scene2"] = this.Scene2Value = Math.round(0);
        this.inputError["Scene2"] = false;
      } else if (val === "" || val.length === 0) {
        this.inputError["Scene2"] = true;
        this.ngModelval["Scene2"] = this.Scene2Value = val;
      } else {
        if (!(val === "")) {
          this.ngModelval["Scene2"] = this.Scene2Value = value;
          this.inputError["Scene2"] = false;
        }
      }
      setTimeout(() => {
        this.sendcodedmainsdata();
      }, 500);
      this.checkdfault();
      this.checkInvalidValues();
    }, 0);
  }
  scene3ValChanged(val) {
    let value: number = Number(val);
    setTimeout(() => {
      if (value > this.Scene3Max) {
        this.ngModelval["Scene3"] = this.Scene3Value = 100;
        this.inputError["Scene3"] = false;
      } else if (value < 0) {
        this.ngModelval["Scene3"] = this.Scene3Value = Math.round(0);
        this.inputError["Scene3"] = false;
      } else if (val === "" || val.length === 0) {
        this.inputError["Scene3"] = true;
        this.ngModelval["Scene3"] = this.Scene3Value = val;
      } else {
        if (!(val === "")) {
          this.ngModelval["Scene3"] = this.Scene3Value = value;
          this.inputError["Scene3"] = false;
        }
      }
      setTimeout(() => {
        this.sendcodedmainsdata();
      }, 500);
      this.checkdfault();
      this.checkInvalidValues();
    }, 0);
  }
  scene4ValChanged(val) {
    let value: number = Number(val);
    setTimeout(() => {
      if (value > this.Scene4Max) {
        this.ngModelval["Scene4"] = this.Scene4Value = 100;
        this.inputError["Scene4"] = false;
      } else if (value < 0) {
        this.ngModelval["Scene4"] = this.Scene4Value = Math.round(0);
        this.inputError["Scene4"] = false;
      } else if (val === "" || val.length === 0) {
        this.inputError["Scene4"] = true;
        this.ngModelval["Scene4"] = this.Scene4Value = val;
      } else {
        if (!(val === "")) {
          this.ngModelval["Scene4"] = this.Scene4Value = value;
          this.inputError["Scene4"] = false;
        }
      }
      setTimeout(() => {
        this.sendcodedmainsdata();
      }, 500);
      this.checkdfault();
      this.checkInvalidValues();
    }, 0);
  }
  scene5ValChanged(val) {
    let value: number = Number(val);
    setTimeout(() => {
      if (value > this.Scene5Max) {
        this.ngModelval["Scene5"] = this.Scene5Value = 100;
        this.inputError["Scene5"] = false;
      } else if (value < 0) {
        this.ngModelval["Scene5"] = this.Scene5Value = Math.round(0);
        this.inputError["Scene5"] = false;
      } else if (val === "" || val.length === 0) {
        this.inputError["Scene5"] = true;
        this.ngModelval["Scene5"] = this.Scene5Value = val;
      } else {
        if (!(val === "")) {
          this.ngModelval["Scene5"] = this.Scene5Value = value;
          this.inputError["Scene5"] = false;
        }
      }
      setTimeout(() => {
        this.sendcodedmainsdata();
      }, 500);
      this.checkdfault();
      this.checkInvalidValues();
    }, 0);
  }
  scene6ValChanged(val) {
    let value: number = Number(val);
    setTimeout(() => {
      if (value > this.Scene6Max) {
        this.ngModelval["Scene6"] = this.Scene6Value = 100;
        this.inputError["Scene6"] = false;
      } else if (value < 0) {
        this.ngModelval["Scene6"] = this.Scene6Value = Math.round(0);
        this.inputError["Scene6"] = false;
      } else if (val === "" || val.length === 0) {
        this.inputError["Scene6"] = true;
        this.ngModelval["Scene6"] = this.Scene6Value = val;
      } else {
        if (!(val === "")) {
          this.ngModelval["Scene6"] = this.Scene6Value = value;
          this.inputError["Scene6"] = false;
        }
      }
      setTimeout(() => {
        this.sendcodedmainsdata();
      }, 500);
      this.checkdfault();
      this.checkInvalidValues();
    }, 0);
  }
  scene7ValChanged(val) {
    let value: number = Number(val);
    setTimeout(() => {
      if (value > this.Scene7Max) {
        this.ngModelval["Scene7"] = this.Scene7Value = 100;
        this.inputError["Scene7"] = false;
      } else if (value < 0) {
        this.ngModelval["Scene7"] = this.Scene7Value = Math.round(0);
        this.inputError["Scene7"] = false;
      } else if (val === "" || val.length === 0) {
        this.inputError["Scene7"] = true;
        this.ngModelval["Scene7"] = this.Scene7Value = val;
      } else {
        if (!(val === "")) {
          this.ngModelval["Scene7"] = this.Scene7Value = value;
          this.inputError["Scene7"] = false;
        }
      }
      setTimeout(() => {
        this.sendcodedmainsdata();
      }, 500);
      this.checkdfault();
      this.checkInvalidValues();
    }, 0);
  }
  scene8ValChanged(val) {
    let value: number = Number(val);
    setTimeout(() => {
      if (value > this.Scene8Max) {
        this.ngModelval["Scene8"] = this.Scene8Value = 100;
        this.inputError["Scene8"] = false;
      } else if (value < 0) {
        this.ngModelval["Scene8"] = this.Scene8Value = Math.round(0);
        this.inputError["Scene8"] = false;
      } else if (val === "" || val.length === 0) {
        this.inputError["Scene8"] = true;
        this.ngModelval["Scene8"] = this.Scene8Value = val;
      } else {
        if (!(val === "")) {
          this.ngModelval["Scene8"] = this.Scene8Value = value;
          this.inputError["Scene8"] = false;
        }
      }
      setTimeout(() => {
        this.sendcodedmainsdata();
      }, 500);
      this.checkdfault();
      this.checkInvalidValues();
    }, 0);
  }
  scene9ValChanged(val) {
    let value: number = Number(val);
    setTimeout(() => {
      if (value > this.Scene9Max) {
        this.ngModelval["Scene9"] = this.Scene9Value = 100;
        this.inputError["Scene9"] = false;
      } else if (value < 0) {
        this.ngModelval["Scene9"] = this.Scene9Value = Math.round(0);
        this.inputError["Scene9"] = false;
      } else if (val === "" || val.length === 0) {
        this.inputError["Scene9"] = true;
        this.ngModelval["Scene9"] = this.Scene9Value = val;
      } else {
        if (!(val === "")) {
          this.ngModelval["Scene9"] = this.Scene9Value = value;
          this.inputError["Scene9"] = false;
        }
      }
      setTimeout(() => {
        this.sendcodedmainsdata();
      }, 500);
      this.checkdfault();
      this.checkInvalidValues();
    }, 0);
  }
  scene10ValChanged(val) {
    let value: number = Number(val);
    setTimeout(() => {
      if (value > this.Scene10Max) {
        this.ngModelval["Scene10"] = this.Scene10Value = 100;
        this.inputError["Scene10"] = false;
      } else if (value < 0) {
        this.ngModelval["Scene10"] = this.Scene10Value = Math.round(0);
        this.inputError["Scene10"] = false;
      } else if (val === "" || val.length === 0) {
        this.inputError["Scene10"] = true;
        this.ngModelval["Scene10"] = this.Scene10Value = val;
      } else {
        if (!(val === "")) {
          this.ngModelval["Scene10"] = this.Scene10Value = value;
          this.inputError["Scene10"] = false;
        }
      }
      setTimeout(() => {
        this.sendcodedmainsdata();
      }, 500);
      this.checkdfault();
      this.checkInvalidValues();
    }, 0);
  }
  scene11ValChanged(val) {
    let value: number = Number(val);
    setTimeout(() => {
      if (value > this.Scene11Max) {
        this.ngModelval["Scene11"] = this.Scene11Value = 100;
        this.inputError["Scene11"] = false;
      } else if (value < 0) {
        this.ngModelval["Scene11"] = this.Scene11Value = Math.round(0);
        this.inputError["Scene11"] = false;
      } else if (val === "" || val.length === 0) {
        this.inputError["Scene11"] = true;
        this.ngModelval["Scene11"] = this.Scene11Value = val;
      } else {
        if (!(val === "")) {
          this.ngModelval["Scene11"] = this.Scene11Value = value;
          this.inputError["Scene11"] = false;
        }
      }
      setTimeout(() => {
        this.sendcodedmainsdata();
      }, 500);
      this.checkdfault();
      this.checkInvalidValues();
    }, 0);
  }
  scene12ValChanged(val) {
    let value: number = Number(val);
    setTimeout(() => {
      if (value > this.Scene12Max) {
        this.ngModelval["Scene12"] = this.Scene12Value = 100;
        this.inputError["Scene12"] = false;
      } else if (value < 0) {
        this.ngModelval["Scene12"] = this.Scene12Value = Math.round(0);
        this.inputError["Scene12"] = false;
      } else if (val === "" || val.length === 0) {
        this.inputError["Scene12"] = true;
        this.ngModelval["Scene12"] = this.Scene12Value = val;
      } else {
        if (!(val === "")) {
          this.ngModelval["Scene12"] = this.Scene12Value = value;
          this.inputError["Scene12"] = false;
        }
      }
      setTimeout(() => {
        this.sendcodedmainsdata();
      }, 500);
      this.checkdfault();
      this.checkInvalidValues();
    }, 0);
  }
  fallbackDetectionTimeValueChange(value) {
    if (
      value >= this.FallbackDetectionTimeMin &&
      value <= this.FallbackDetectionTimeMax
    ) {
      this.ngModelval["FallbackDetectionTime"] = value;
      this.inputError["FallbackDetectionTime"] = false;
      this.inputError["FallbackDetectionTime"] = false;
      this.FallbackDetectionTimeOption = {
        floor: this.FallbackDetectionTimeMin,
        ceil: this.FallbackDetectionTimeMax,
      };
      this.checkInvalidValues();
      this.sendcodedmainsdata();
    } else {
      this.inputError["FallbackDetectionTime"] = true;
      this.codedmainsCreateFile = true;
    }
  }
  onChangeList(value) {
    let newValue: number = Number(value);
    if (newValue != 0) {
      this.isFastFadeTimeEnabled = true;
      this.ngModelval["FastFadeTime"] = this.FastFadeTime.default;
      this.fastfadeTimeDynamicVal =
        this.fastfadeTimeDataset[this.FastFadeTime.default];
    } else {
      this.isFastFadeTimeEnabled = false;
    }
    this.fadeTimeDynamicVal = this.fadeTimeDataset[value];
    this.ngModelval["FadeTime"] = newValue;
    this.sendcodedmainsdata();
    this.checkdfault();
    this.checkInvalidValues();
  }
  onChangeFastFadeTimeList(value) {
    let newValue: number = Number(value);
    this.fastfadeTimeDynamicVal = this.fastfadeTimeDataset[value];
    this.ngModelval["FastFadeTime"] = newValue;
    this.sendcodedmainsdata();
    this.checkdfault();
    this.checkInvalidValues();
  }
  textValChanged(value, colName) {
    let val = value;
    value = parseInt(value);
    if (isNaN(value) || value.length === 0) {
      value = 0;
    }
    this.codedmainsDefaultColor = false;
    this.inputError[colName] = false;
    this.codedmainsCreateFile = false;
    if (colName == "FallbackDetectionTime") {
      if (
        value < this.FallbackDetectionTimeMin ||
        value > this.FallbackDetectionTimeMax
      ) {
        this.inputError[colName] = true;
        this.ngModelval["FallbackDetectionTime"] = value;
      } else {
        this.FallbackDetectionTimeValue = value;
        this.ngModelval["FallbackDetectionTime"] = value;
      }
    } else if (colName == "PowerOnLevel") {
      if (val.length === 0) {
        this.inputError[colName] = true;
        this.ngModelval["PowerOnLevel"] = val;
      } else if (value < this.PowerOnLevelMin || value > this.PowerOnLevelMax) {
        this.inputError[colName] = true;
        this.ngModelval["PowerOnLevel"] = value;
      } else {
        this.PowerOnLevelValue = value;
        this.ngModelval["PowerOnLevel"] = value;
      }
    } else if (colName == "FallbackScene") {
      if (val.length === 0) {
        this.inputError[colName] = true;
        this.ngModelval["FallbackScene"] = val;
      } else if (
        (value < this.FallbackSceneMin ||
        value > this.FallbackSceneMax) && (value != 255)
      ) {
        this.inputError[colName] = true;
        this.ngModelval["FallbackScene"] = value;
      } else {
        this.FallbackSceneValue = value;
        this.ngModelval["FallbackScene"] = value;
      }
    }
    this.checkdfault();
    for (var type in this.inputError) {
      if (this.inputError[type]) {
        this.codedmainsCreateFile = true;
      }
    }
    this.service.codedmainsEditedVal(this.codedmainsDefaultColor);
    this.service.codedmainsInputError(this.codedmainsCreateFile);
    this.sendcodedmainsdata();
  }
  isFeatureEnabled(value) {
    if (value === true) {
      this.service.setcodedmainsDefaultColor(this.codedmainsDefaultColor);
      this.ngModelval["CodedMainsEnabled"] = value;
      this.sendcodedmainsdata();
    } else if (value === false) {
      this.service.setcodedmainsDefaultColor(this.codedmainsDefaultColor);
      this.ngModelval["CodedMainsEnabled"] = value;
      this.sendcodedmainsdata();
    }
  }
  changedVal(value) {
    this.ngModelval["ischeckboxChecked"] = this.ischeckboxChecked = value;
    this.codedmainsDefaultColor = value;
    this.isLastDefinedDimLevelEnabled = value;
    this.checkdfault();
    this.service.setcodedmainsDefaultColor(this.codedmainsDefaultColor);
    this.sendcodedmainsdata();
  }
  checkInvalidValues() {
    this.codedmainsCreateFile = false;
    for (var type in this.inputError) {
      if (this.inputError[type]) {
        this.codedmainsCreateFile = true;
      }
    }
    this.service.codedmainsInputError(this.codedmainsCreateFile);
  }
  resetDefaultcodedmains() {
    this.ngModelval["ischeckboxChecked"] =
      this.ischeckboxChecked =
      this.isLastDefinedDimLevelEnabled =
        false;
    this.ngModelval["FadeTime"] = this.FadeTime.default;
    this.fadeTimeDynamicVal = this.fadeTimeDataset[this.FadeTime.default];
    if (this.FadeTime.default != 0) {
      this.isFastFadeTimeEnabled = true;
    } else {
      this.isFastFadeTimeEnabled = false;
    }
    this.ngModelval["FastFadeTime"] = this.FastFadeTime.default;
    this.fastfadeTimeDynamicVal =
      this.fastfadeTimeDataset[this.FastFadeTime.default];
    this.ngModelval["FallbackDetectionTime"] = this.FallbackDetectionTimeValue =
      this.FallbackDetectionTime.default;
    this.ngModelval["PowerOnLevel"] = this.PowerOnLevelValue =
      this.PowerOnLevel.default;
    this.ngModelval["FallbackScene"] = this.FallbackSceneValue =
      this.FallbackScene.default;
    this.ngModelval["Scene12"] = this.Scene12Value = this.Scene12.default;
    this.ngModelval["Scene11"] = this.Scene11Value = this.Scene11.default;
    this.ngModelval["Scene10"] = this.Scene10Value = this.Scene10.default;
    this.ngModelval["Scene9"] = this.Scene9Value = this.Scene9.default;
    this.ngModelval["Scene8"] = this.Scene8Value = this.Scene8.default;
    this.ngModelval["Scene7"] = this.Scene7Value = this.Scene7.default;
    this.ngModelval["Scene6"] = this.Scene6Value = this.Scene6.default;
    this.ngModelval["Scene5"] = this.Scene5Value = this.Scene5.default;
    this.ngModelval["Scene4"] = this.Scene4Value = this.Scene4.default;
    this.ngModelval["Scene3"] = this.Scene3Value = this.Scene3.default;
    this.ngModelval["Scene2"] = this.Scene2Value = this.Scene2.default;
    this.ngModelval["Scene1"] = this.Scene1Value = this.Scene1.default;
    for (var type in this.inputError) {
      this.inputError[type] = false;
    }
    this.codedmainsCreateFile = false;
    this.service.codedmainsInputError(this.codedmainsCreateFile);
    this.sendcodedmainsdata();
  }
  sliderEvent() {}
  checkdfault() {
    this.codedmainsDefaultColor = true;
    for (var type in this.ngModelval) {
      if (
        type == "FadeTime" ||
        type == "FastFadeTime" ||
        type == "FallbackDetectionTime" ||
        type == "PowerOnLevel" ||
        type == "FallbackScene" ||
        type == "Scene1" ||
        type == "Scene2" ||
        type == "Scene3" ||
        type == "Scene4" ||
        type == "Scene5" ||
        type == "Scene6" ||
        type == "Scene7" ||
        type == "Scene8" ||
        type == "Scene9" ||
        type == "Scene10" ||
        type == "Scene11" ||
        type == "Scene12"
      ) {
        if (this.ngModelval[type] != this.properties[type]["default"]) {
          this.codedmainsDefaultColor = false;
        }
        if (this.ngModelval["ischeckboxChecked"] === true) {
          this.codedmainsDefaultColor = false;
        }
      }
      this.service.codedmainsEditedVal(this.codedmainsDefaultColor);
    }
  }
  ngOnDestroy() {
    this.service.setcodedmainsDefaultColor("");
    this.service.sendcodedmainsdata("");
  }
}
