import {
  Component,
  OnInit,
  Input,
  ChangeDetectorRef,
  ViewRef,
  OnDestroy,
} from "@angular/core";
import { restore } from "../../assets/json/restoreTooltip.en";
import { Options } from "ng5-slider";
import { CompileShallowModuleMetadata } from "@angular/compiler";
import { isNullOrUndefined, isNumber } from "util";
import { FieldType } from "@ngx-formly/core";
import { ApiService } from "src/app/core-module/services/api.service";
import { FormBuilder, NgModel, Validators } from "@angular/forms";
import { FormControl, FormGroup } from "@angular/forms";
import { Subject } from "rxjs";

@Component({
  selector: "app-lip",
  templateUrl: "./lip.component.html",
  styleUrls: ["./lip.component.scss"],
})
export class LipComponent implements OnInit, OnDestroy {
  @Input() field;
  formatVersion: any;
  column: any;
  properties: any = [];
  defaultColor: boolean = true;
  inputError: any = [];
  CurveSelection: any;
  CurveSelectionVal: any;
  ngModelval: any = [];
  restoreText = restore;
  userSpecified: boolean = false;
  curveSurveyValues = {};
  dimLevelColumn = [];
  outputVoltageColumn = [];
  quickConfigvalue;
  quickConfigCustomCSS;
  setQuickConfig;
  fileResponse: any;
  isEdited: any;
  lipDefaultColor;
  lipCreateFile: boolean;
  form: FormGroup;
  isInputPower100: boolean;
  isInputPower100andDim: boolean;
  showboth: boolean;
  unsubscribe = new Subject<any>();
  InputPower100Min: any;
  InputPower100Max: any;
  InputPowerDimMin: any;
  InputPowerDimMax: any;
  DimLevelMax: any;
  DimLevelMin: any;
  constructor(
    private service: ApiService,
    private ref: ChangeDetectorRef,
    private fb: FormBuilder,
    private changeDet: ChangeDetectorRef
  ) {
    this.form = this.fb.group({
      Options: new FormControl("", [Validators.required]),
    });
  }
  ngOnInit() {
    this.isEdited = false;
    this.column = this.field.requiredProperties;
    this.properties = this.field.properties;
    this.service.getlipDefaultValue.subscribe((response) => {
      if (response == "valid") {
        this.lipDefaultColor = true;
        this.resetDefaultlipValues();
      }
    });
    this.service.getlipData.subscribe((data) => {
      if (data) {
        this.ngModelval = data;
        this.checkdfault();
        this.checkInvalidValues();
      }
    });
    if (
      this.properties.Options.enum.includes(`Input power at 100% only`) &&
      this.properties.Options.enum.includes(`Input power at 100% and dim`) &&
      this.properties.Options.enum.length > 1
    ) {
      this.showboth = true;
      this.isInputPower100 = this.isInputPower100andDim = false;
    } else if (
      this.properties.Options.enum.includes(`Input power at 100% only`) &&
      this.properties.Options.enum.length < 2
    ) {
      this.isInputPower100 = true;
      this.showboth = false;
    } else if (
      this.properties.Options.enum.includes(`Input power at 100% and dim`) &&
      this.properties.Options.enum.length < 2
    ) {
      this.isInputPower100andDim = true;
      this.showboth = false;
    }
    this.ngModelval["InputPower100"] = this.ngModelval["InputPower1001"] =
      this.properties.InputPower100.default;
    this.InputPower100Min = this.properties.InputPower100.minimum;
    this.InputPower100Max = this.properties.InputPower100.maximum;
    if (!isNullOrUndefined(this.properties.InputPowerDim.propertyDefault)) {
      this.ngModelval["InputPowerDim"] =
        this.properties.InputPowerDim.propertyDefault;
    } else {
      this.ngModelval["InputPowerDim"] = this.properties.InputPowerDim.default;
    }
    this.InputPowerDimMin = this.properties.InputPowerDim.minimum;
    this.InputPowerDimMax = this.properties.InputPowerDim.maximum;
    if (!isNullOrUndefined(this.properties.DimLevel.propertyDefault)) {
      this.ngModelval["DimLevel"] = this.properties.DimLevel.propertyDefault;
    } else {
      this.ngModelval["DimLevel"] = this.properties.DimLevel.default;
    }
    this.DimLevelMin = this.properties.DimLevel.minimum;
    this.DimLevelMax = this.properties.DimLevel.maximum;
    if (this.properties.Options.default === `Input power at 100% only`) {
      this.form.controls["Options"].setValue(`Input power at 100% only`);
      this.ngModelval["Options"] = `Input power at 100% only`;
    } else {
      this.form.controls["Options"].setValue(`Input power at 100% and dim`);
      this.ngModelval["Options"] = `Input power at 100% and dim`;
    }
    let unSelectedFeat = Array(
      JSON.parse(localStorage.getItem("UnSelectedFeatConfig"))
    );
    var isConfigEdited = false;
    let featIndex: number = 0;
    if (unSelectedFeat[0] != null) {
      unSelectedFeat[0].forEach((element) => {
        if (element["featureName"].toString().toUpperCase() === "LIP") {
          isConfigEdited = true;
          this.fileResponse = {};
          let lipUnSelectedVal: any = element["featureParameters"][0];
          this.addlipLoadedFeatureReadValues(lipUnSelectedVal);
          unSelectedFeat[0].splice(featIndex, 1);
          localStorage.setItem(
            "UnSelectedFeatConfig",
            JSON.stringify(unSelectedFeat[0])
          );
        } else {
          featIndex++;
        }
      });
    }

    if (isConfigEdited) {
    } else {
      setTimeout(() => {
        this.fileResponse = JSON.parse(
          localStorage.getItem("configurationData")
        );
        if (this.fileResponse) {
          if (this.fileResponse.resp && this.fileResponse.resp.LIP) {
            if (this.fileResponse.resp.LIP)
              this.addlipLoadedFeatureReadValues(this.fileResponse.resp.LIP);
          }
        }
      }, 1000);
    }

    this.service.getquickConfig.subscribe((data) => {
      if (data === "quickConfig") {
        this.quickConfigvalue = true;
        this.quickConfigCustomCSS = "quick-custom-inputbox";
        this.setQuickConfig = "quickConfig";
      } else {
        this.quickConfigvalue = false;
        this.quickConfigCustomCSS = "custom-inputbox";
        this.setQuickConfig = "standard";
      }
      setTimeout(() => {
        if (this.ref && !(this.ref as ViewRef).destroyed) {
          this.ref.detectChanges();
        }
      });
    });
    setTimeout(() => {
      this.sendlipdata(this.ngModelval);
      this.isEdited = true;
    }, 1000);
  }
  addlipLoadedFeatureReadValues(lip: any) {
    let lipObj = lip;
    var keys = Object.keys(lipObj);
    var values = Object.values(lipObj);
    var result = [];
    keys.forEach((key, i) => {
      result[key] = values[i];
      this.textValChanged(values[i], key, result["Options"]);
    });
    this.ngModelval = result;
    if (
      !isNullOrUndefined(result["Options"]) &&
      result["Options"] === `Input power at 100% and dim`
    ) {
      this.ngModelval["InputPower1001"] = result["InputPower1001"]
        ? result["InputPower1001"]
        : result["InputPower100"];
      this.ngModelval["InputPower100"] = localStorage.getItem("InputPower100")
        ? localStorage.getItem("InputPower100")
        : this.properties.InputPower100.default;
    } else {
      this.ngModelval["InputPower1001"] = localStorage.getItem("InputPower1001")
        ? localStorage.getItem("InputPower1001")
        : this.properties.InputPower100.default;
      this.ngModelval["InputPower100"] = result["InputPower100"];
    }

    this.ngModelval["InputPowerDim"] = !isNullOrUndefined(
      result["InputPowerDim"]
    )
      ? result["InputPowerDim"]
      : !isNullOrUndefined(localStorage.getItem("InputPowerDim"))
      ? localStorage.getItem("InputPowerDim")
      : this.properties.InputPowerDim.default;

    this.ngModelval["DimLevel"] = !isNullOrUndefined(result["DimLevel"])
      ? result["DimLevel"]
      : !isNullOrUndefined(localStorage.getItem("DimLevel"))
      ? localStorage.getItem("DimLevel")
      : this.properties.DimLevel.default;

    if (
      !isNullOrUndefined(result["Options"]) &&
      result.hasOwnProperty("Options")
    ) {
      this.ngModelval["Options"] = result["Options"];
      this.form.controls["Options"].setValue(result["Options"]);
    }
    if (
      this.properties.Options.enum.includes(`Input power at 100% only`) &&
      this.properties.Options.enum.includes(`Input power at 100% and dim`) &&
      this.properties.Options.enum.length > 1
    ) {
      this.showboth = true;
      this.isInputPower100 = this.isInputPower100andDim = false;
    } else if (
      this.properties.Options.enum.includes(`Input power at 100% only`) &&
      this.properties.Options.enum.length < 2
    ) {
      this.isInputPower100 = true;
      this.showboth = false;
    } else if (
      this.properties.Options.enum.includes(`Input power at 100% and dim`) &&
      this.properties.Options.enum.length < 2
    ) {
      this.isInputPower100andDim = true;
      this.showboth = false;
    }
    this.checkdfault();
    this.checkInvalidValues();
    this.sendlipdata(this.ngModelval);
  }
  sendlipdata(val) {
    this.service.sendlipdata(val);
    if (this.isEdited) {
      this.setConfigurationData(this.ngModelval, "LIP");
    }
  }
  checkdfault() {
    this.lipDefaultColor = true;
    this.properties = this.field.properties;
    for (var type in this.ngModelval) {
      if (this.ngModelval[type] === "") {
        this.lipDefaultColor = false;
      }
      if (type == "InputPower100") {
        if (this.ngModelval[type] != this.properties[type]["default"]) {
          this.lipDefaultColor = false;
        }
      } else if (type == "InputPower1001") {
        if (
          this.ngModelval[type] != this.properties["InputPower100"]["default"]
        ) {
          this.lipDefaultColor = false;
        }
      } else if (type == "DimLevel") {
        if (
          !isNullOrUndefined(this.properties[type]["propertyDefault"]) &&
          this.ngModelval[type] != this.properties[type]["propertyDefault"]
        ) {
          this.lipDefaultColor = false;
        } else if (
          !isNullOrUndefined(this.properties[type]["default"]) &&
          this.ngModelval[type] != this.properties[type]["default"]
        ) {
          this.lipDefaultColor = false;
        }
      } else if (type == "InputPowerDim") {
        if (
          !isNullOrUndefined(this.properties[type]["propertyDefault"]) &&
          this.ngModelval[type] != this.properties[type]["propertyDefault"]
        ) {
          this.lipDefaultColor = false;
        } else if (
          !isNullOrUndefined(this.properties[type]["default"]) &&
          this.ngModelval[type] != this.properties[type]["default"]
        ) {
          this.lipDefaultColor = false;
        }
      } else if (type == "Options") {
        if (this.properties[type].hasOwnProperty("default")) {
          if (this.ngModelval[type] != this.properties[type]["default"]) {
            this.lipDefaultColor = false;
          }
        }
      }
    }
    this.service.lipEditedVal(this.lipDefaultColor);
  }
  checkInvalidValues() {
    this.lipCreateFile = false;
    for (var type in this.inputError) {
      if (this.inputError[type]) {
        this.lipCreateFile = true;
      }
    }
    this.service.lipInputError(this.lipCreateFile);
  }
  resetDefaultlipValues() {
    if (
      this.properties.hasOwnProperty("InputPower100") &&
      !isNullOrUndefined(this.properties.InputPower100.propertyDefault)
    ) {
      this.ngModelval["InputPower100"] = this.ngModelval["InputPower1001"] =
        this.properties.InputPower100.propertyDefault;
    } else {
      this.ngModelval["InputPower100"] = this.ngModelval["InputPower1001"] =
        this.properties.InputPower100.default;
    }
    if (
      this.properties.hasOwnProperty("InputPowerDim") &&
      !isNullOrUndefined(this.properties.InputPowerDim.propertyDefault)
    ) {
      this.ngModelval["InputPowerDim"] =
        this.properties.InputPowerDim.propertyDefault;
    } else {
      this.ngModelval["InputPowerDim"] = this.properties.InputPowerDim.default;
    }
    if (
      this.properties.hasOwnProperty("DimLevel") &&
      !isNullOrUndefined(this.properties.DimLevel.propertyDefault)
    ) {
      this.ngModelval["DimLevel"] = this.properties.DimLevel.propertyDefault;
    } else {
      this.ngModelval["DimLevel"] = this.properties.DimLevel.default;
    }
    this.ngModelval["Options"] = this.properties.Options.default;
    if (this.properties.Options.default === `Input power at 100% and dim`) {
      this.form.controls["Options"].setValue("Input power at 100% and dim");
    } else {
      this.form.controls["Options"].setValue(`Input power at 100% only`);
    }
    for (var type in this.inputError) {
      this.inputError[type] = false;
    }
    this.lipCreateFile = false;
    this.service.lipInputError(this.lipCreateFile);
    this.sendlipdata(this.ngModelval);
  }
  setConfigurationData(value, keyVal) {
    if (!isNullOrUndefined(value)) {
      let lipObj = {};
      var key = keyVal;
      var data = {};
      var addNew = {
        [keyVal]: {},
      };
      data = JSON.parse(localStorage.getItem("configurationData"));
      if (data && !isNullOrUndefined(data["resp"])) {
        lipObj = Object.assign({}, value);
        data["resp"][keyVal] = lipObj;
      } else {
        data["resp"] = addNew;
        lipObj = Object.assign({}, value);
        data["resp"][keyVal] = lipObj;
      }
      localStorage.setItem("configurationData", JSON.stringify(data));
    }
  }
  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  numberOnlyWithDec(evt): boolean {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (
      charCode == 8 ||
      charCode == 13 ||
      charCode == 99 ||
      charCode == 118 ||
      charCode == 46
    ) {
      if (
        !isNullOrUndefined(evt.target.value) &&
        evt.target.value.toString().match(/^\d*\.?\d{0,0}$/g)
      ) {
        return true;
      } else if (
        !isNullOrUndefined(evt.key) &&
        evt.key.toString().match(/^\d*\.?\d{0,0}$/g)
      ) {
        return true;
      }
      return false;
    }

    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    if (
      !isNullOrUndefined(evt.target.value) &&
      evt.target.value.toString().match(/^\d*\.?\d{0,0}$/g)
    ) {
      return true;
    } else if (
      !isNullOrUndefined(evt.key) &&
      evt.key.toString().match(/^\d*\.?\d{0,0}$/g)
    ) {
      return true;
    }

    return false;
  }

  validateInputValue(value: any) {
    value = value.toString();
    if (value === "" || value === "." || value === "..") {
      return true;
    } else if (value.includes(".")) {
      var count = value.split(".").length - 1;
      if (count > 1) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  textValChanged(value, colName, options?) {
    this.lipDefaultColor = false;
    this.inputError[colName] = false;
    this.lipCreateFile = false;
    if (colName == "InputPower100") {
      if (
        value < this.InputPower100Min ||
        value > this.InputPower100Max ||
        this.validateInputValue(value)
      ) {
        this.inputError[colName] = true;
        this.ngModelval["InputPower100"] = value;
      } else {
        this.ngModelval["InputPower100"] = value;
      }
    } else if (
      colName == "InputPower1001" ||
      (options === `Input power at 100% and dim` &&
        colName != "DimLevel" &&
        colName != "InputPowerDim")
    ) {
      if (
        value < this.InputPower100Min ||
        value > this.InputPower100Max ||
        value.length <= 0 ||
        parseFloat(value) < parseFloat(this.ngModelval["InputPowerDim"])
      ) {
        this.inputError[colName] = true;
        this.ngModelval["InputPower1001"] = value;
      } else {
        this.inputError["InputPower1001"] = false;
        this.ngModelval["InputPower1001"] = value;
      }
    } else if (colName == "InputPowerDim") {
      if (
        value < this.InputPowerDimMin ||
        value > this.InputPowerDimMax ||
        parseFloat(value) > parseFloat(this.ngModelval["InputPower1001"]) ||
        value.length <= 0
      ) {
        this.inputError[colName] = true;
        this.ngModelval["InputPowerDim"] = value;
      } else {
        this.inputError["InputPowerDim"] = false;
        this.ngModelval["InputPowerDim"] = value;
      }
    } else if (colName == "DimLevel") {
      if (
        value < this.DimLevelMin ||
        value > this.DimLevelMax ||
        value.length <= 0
      ) {
        this.inputError[colName] = true;
        this.ngModelval["DimLevel"] = value;
      } else {
        this.inputError[colName] = false;
        this.ngModelval["DimLevel"] = value;
      }
    }
    this.changeDet.detectChanges();
    this.checkdfault();
    for (var type in this.inputError) {
      if (this.inputError[type]) {
        this.lipCreateFile = true;
      }
    }
    this.checkInvalidValues();
    this.service.lipEditedVal(this.lipDefaultColor);
    this.service.lipInputError(this.lipCreateFile);
    this.sendlipdata(this.ngModelval);
  }
  handleChange(eventVal) {
    this.ngModelval["Options"] = eventVal.target.value;
    this.sendlipdata(this.ngModelval);
  }
  ngOnDestroy() {
    this.unsubscribe.next();
  }
}
