import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import {
  NgbActiveModal,
  NgbModal,
  NgbModalOptions,
} from "@ng-bootstrap/ng-bootstrap";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ApiService } from "../core-module/services/api.service";

@Component({
  selector: "app-feature-file-partial-compatible-modal",
  templateUrl: "./feature-file-partial-compatible-modal.component.html",
  styleUrls: ["./feature-file-partial-compatible-modal.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class FeatureFilePartialCompatibleModalComponent implements OnInit {
  modalOptions: NgbModalOptions;
  @Input() public hasSuccessfulFeatures: boolean;
  @Input() public device;
  uploadFeature;
  featureArray: any[] = [];
  popup: string;
  isFeatureSelected = false;
  loading = false;
  featureFile;
  newConfigId: any;
  luminSoftwareVersionSchema: any;
  startConfigForm: FormGroup;
  url;
  softwareVer: any = [];
  feature12NcName: any = [];
  subscription;
  envScehmaLIstApi;
  isLightControl = 0;
  isLuminaireInfo = 0;
  isLuminaire = 0;
  isRelaod: boolean = false;
  latestDvValue: any;
  unsubscribe = new Subject<any>();
  schemaResp: any;
  isFeatureAvailable: boolean;
  noCompatibleFeatures: boolean;
  dtlSoftwareVersionSchema: any;
  filteredFeatureArray: any[] = [];
  softwareVersionSchema;
  envAloschemaDetails;
  envStartConfigURL;
  startConfigurationData = {};
  constructor(
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private service: ApiService,
    private fb: FormBuilder,
    public route: Router
  ) {}
  configureDevice() {
    this.activeModal.close();
    this.service.triggerConfigureDevice.next(true);
  }
  goBack() {
    this.activeModal.close();
  }
  remove() {
    localStorage.setItem("newDeviceBoolen", JSON.stringify(true));
    this.service.removeModes("invalid");
    this.activeModal.close();
    this.service.closeConfigureDeviceModal.next(true);
    this.modalService.dismissAll();
  }
  ngOnInit() {
    this.startConfigForm = this.fb.group({
      selectedFeatures: this.fb.array([]),
    });
    this.service.getEnvValue();
    this.service.getEnvData
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((url) => {
        this.url = url;
      });
    this.service.getSelectedModal.subscribe((resp) => {
      this.popup = resp;
    });
    this.uploadFeature = JSON.parse(localStorage.getItem("importFeatureData"));
    if (this.uploadFeature.successfulFeatures.length > 0) {
      this.noCompatibleFeatures = false;
    } else {
      this.noCompatibleFeatures = true;
    }
    this.isFeatureSelected = this.startConfigForm.value.selectedFeatures.length;
  }
}
