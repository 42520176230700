import { Component, OnInit, Input } from "@angular/core";
import { FieldType } from "@ngx-formly/core";
import { ApiService } from "src/app/core-module/services/api.service";
import { restore } from "./../../../../assets/json/restoreTooltip.en";
import { isNullOrUndefined } from "util";

@Component({
  selector: "app-psu-feature",
  templateUrl: "./psu-feature.component.html",
  styleUrls: ["./psu-feature.component.scss"],
})
export class PsuFeatureComponent extends FieldType implements OnInit {
  @Input() field;
  psuParameter = [];
  defaultValue;
  checkboxdefaultValue;
  paramterCheckedValue;
  selectedParameter = [];
  hearBeat;
  gaspValue;
  paramterCheckedValueHB: boolean;
  paramterCheckedValueLG;
  defaultRestoring;
  countparam = 0;
  countparamLG = 0;
  restoreText = restore;
  fileResponse: any;
  countArray: any = [];
  ngModelval: any = {};
  properties;
  isEdited: any;
  constructor(private apiService: ApiService) {
    super();
  }
  setConfigurationData(value, keyVal) {
    let touchDimObj = {};
    var key = keyVal;
    var data = {};
    var addNew = {
      [keyVal]: {},
    };
    data = JSON.parse(localStorage.getItem("configurationData"));
    if (data && !isNullOrUndefined(data["resp"])) {
      touchDimObj = Object.assign({}, value);
      data["resp"][keyVal] = touchDimObj;
    } else {
      data["resp"] = addNew;
      touchDimObj = Object.assign({}, value);
      data["resp"][keyVal] = touchDimObj;
    }
    localStorage.setItem("configurationData", JSON.stringify(data));
  }
  ngOnInit() {
    this.isEdited = false;
    this.apiService.getSrPsuDefaultValue.subscribe((response) => {
      if (response) {
        this.resetDefault();
        this.countArray = [];
      }
    });
    this.fileResponse = JSON.parse(localStorage.getItem("configurationData"));
    let saveData = JSON.parse(localStorage.getItem("saveSetData"));
    if (saveData && saveData["resp"] && saveData["resp"]["SrPsu"]) {
      if (!this.fileResponse.DALIPSU) {
        this.fileResponse = saveData;
      }
      //this.fileResponse = saveData;
    }
    this.properties = this.field;
    let reqPSUEable = false;
    let reqHeartBeat = false;
    let reqLastGasp = false;

    let reqProps = this.field.requiredProperties;
    reqProps.forEach((element) => {
      if (element === "Enabled") reqPSUEable = true;
      if (element === "HbEnable") reqHeartBeat = true;
      if (element === "LastGaspEnable") reqLastGasp = true;
    });
    if (this.field.properties.Enabled && reqPSUEable) {
      this.ngModelval["Enabled"] = this.field.properties.Enabled.default;
      this.rSetChanged(this.ngModelval["Enabled"]);
    }
    if (this.field.properties.HbEnable && reqHeartBeat) {
      this.ngModelval["HbEnable"] = this.field.properties.HbEnable.default;
      this.paramterCheckedValueHB = this.ngModelval["HbEnable"];
      this.psuParameter.push("Heart beat");
    }
    if (this.field.properties.LastGaspEnable && reqLastGasp) {
      this.ngModelval["LastGaspEnable"] =
        this.field.properties.LastGaspEnable.default;
      this.paramterCheckedValueLG = this.ngModelval["LastGaspEnable"];
      this.psuParameter.push("Last gasp");
    }
    ////
    let unSelectedFeat = Array(
      JSON.parse(localStorage.getItem("UnSelectedFeatConfig"))
    );

    let featIndex: number = 0;
    if (unSelectedFeat[0] != null && unSelectedFeat[0].length > 0) {
      unSelectedFeat[0].forEach((element) => {
        if (element["featureName"].toString().toUpperCase() === "DALIPSU") {
          this.fileResponse = {};
          let srPsuUnSelected: any = element["featureParameters"][0];
          let SRPSEditedData = {
            resp: {
              SrPsu: srPsuUnSelected,
            },
          };
          this.fileResponse = SRPSEditedData;
          unSelectedFeat[0].splice(featIndex, 1);
          localStorage.setItem(
            "UnSelectedFeatConfig",
            JSON.stringify(unSelectedFeat[0])
          );
        } else {
          featIndex++;
        }
      });
    }

    /////
    let daliPSUname = "SrPsu";
    if (
      this.fileResponse &&
      this.fileResponse["resp"] &&
      this.fileResponse["resp"].DALIPSU
    )
      daliPSUname = "DALIPSU";

    if (
      this.fileResponse &&
      this.fileResponse["resp"] &&
      this.fileResponse["resp"][daliPSUname]
    ) {
      for (let key in this.fileResponse["resp"][daliPSUname]) {
        if (key === "Enabled") {
          this.rSetChanged(this.fileResponse["resp"][daliPSUname][key]);
        } else {
          let keyObj;
          let heartBeat_LastGasp = false;
          if (key === "HbEnable") {
            keyObj = "Heart beat";
            heartBeat_LastGasp = true;
          } else if (key === "LastGaspEnable") {
            keyObj = "Last gasp";
            heartBeat_LastGasp = true;
          }
          if (heartBeat_LastGasp)
            this.paramterChecked(
              this.fileResponse["resp"][daliPSUname][key],
              keyObj
            );
        }
      }
    }
    this.checkDefault();
    this.sentPSUEnable(this.ngModelval);
    this.isEdited = true;
  }
  sentPSUEnable(val) {
    this.apiService.sentPSUEnable(val);
    if (this.isEdited) {
      this.setConfigurationData(val, "DALIPSU");
    }
  }
  paramterChecked(event, param) {
    if (param === "Heart beat") {
      this.ngModelval["HbEnable"] = this.paramterCheckedValueHB = event;
    }
    if (param === "Last gasp") {
      this.ngModelval["LastGaspEnable"] = this.paramterCheckedValueLG = event;
    }
    this.checkDefault();
    this.sentPSUEnable(this.ngModelval);
    this.apiService.setSrpsuDefaultColor(this.defaultRestoring);
  }

  rSetChanged(event) {
    if (event) {
      this.ngModelval["Enabled"] = event;
      this.checkboxdefaultValue = true;
    } else {
      this.ngModelval["Enabled"] = event;
      this.checkboxdefaultValue = false;
    }
    this.checkDefault();
    this.sentPSUEnable(this.ngModelval);
    this.apiService.setSrpsuDefaultColor(this.defaultRestoring);
  }

  checkDefault() {
    var newtype;
    this.defaultRestoring = true;
    for (var type in this.ngModelval) {
      newtype = type.replace("Val", "");
      if (!isNullOrUndefined(this.properties &&
        this.properties.properties[newtype] &&
        this.properties.properties[newtype]["default"]) &&
        this.ngModelval[type] != this.properties.properties[newtype]["default"]
      ) {
        this.defaultRestoring = false;
      }
    }
    this.apiService.setSrpsuDefaultColor(this.defaultRestoring);
  }

  resetDefault() {
    if (this.field.properties.Enabled) {
      this.ngModelval["Enabled"] = this.field.properties.Enabled.default;
      this.checkboxdefaultValue = true;
    }
    if (this.field.properties.HbEnable) {
      this.ngModelval["HbEnable"] = this.field.properties.HbEnable.default;
      this.paramterCheckedValueHB = this.ngModelval["HbEnable"];
    }
    if (this.field.properties.LastGaspEnable) {
      this.ngModelval["LastGaspEnable"] =
        this.field.properties.LastGaspEnable.default;
      this.paramterCheckedValueLG = this.ngModelval["LastGaspEnable"];
    }
    this.sentPSUEnable(this.ngModelval);
    this.defaultRestoring = true;
    this.apiService.setSrpsuDefaultColor(this.defaultRestoring);
  }
}
