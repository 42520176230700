import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ApiService } from "../core-module/services/api.service";
import { ErrorComponent } from "../error/error.component";
@Component({
  selector: "app-midnight-shift-calculation",
  templateUrl: "./midnight-shift-calculation.component.html",
  styleUrls: ["./midnight-shift-calculation.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class MidnightShiftCalculationComponent implements OnInit, OnDestroy {
  calculateMidnightShiftForm: FormGroup;
  midNightShiftVal: number;
  unsubscribe = new Subject<any>();
  startConfigurationURL: any[];
  constructor(
    private fb: FormBuilder,
    private service: ApiService,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal
  ) {}
  ngOnInit() {
    this.service.getEnvValue();
    this.service.getEnvData
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((url) => {
        if (url.length > 0) {
          this.unsubscribe.next();
          this.startConfigurationURL = url;
        }
      });
    this.calculateMidnightShiftForm = this.fb.group({
      daylightSaving: new FormControl(""),
      latitude: new FormGroup({
        degrees: new FormControl("", Validators.required),
        hemisphere: new FormControl("", Validators.required),
        minutes: new FormControl("", Validators.required),
        seconds: new FormControl("", Validators.required),
      }),
      longitude: new FormGroup({
        degrees: new FormControl("", Validators.required),
        hemisphere: new FormControl("", Validators.required),
        minutes: new FormControl("", Validators.required),
        seconds: new FormControl("", Validators.required),
      }),
      timeZone: new FormControl("", [Validators.required]),
    });
    this.onChanges();
    this.calculateMidnightShiftForm.valueChanges.subscribe(() => {
      if (this.calculateMidnightShiftForm.valid) {
        this.prepearePayloadAndSendToApi();
      }
    });
  }
  ngOnDestroy() {
    this.unsubscribe.next();
  }
  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 45 || charCode > 57)) {
      return false;
    }
    return true;
  }
  inSingleDigitRange(): number[] {
    const restrictedSingleDigitVal = [];
    for (let i = 0; i < 10; i++) {
      restrictedSingleDigitVal.push("0" + i);
    }
    return restrictedSingleDigitVal;
  }
  onChanges(): void {
    this.calculateMidnightShiftForm
      .get("latitude.degrees")
      .valueChanges.subscribe((val) => {
        if (val > 89) {
          this.calculateMidnightShiftForm
            .get("latitude.degrees")
            .patchValue("89");
        } else if (this.inSingleDigitRange().includes(val)) {
          this.calculateMidnightShiftForm
            .get("latitude.degrees")
            .patchValue(parseInt(val, 10));
        } else if (val < 0) {
          this.calculateMidnightShiftForm
            .get("latitude.degrees")
            .patchValue("0");
        }
      });
    this.calculateMidnightShiftForm
      .get("latitude.minutes")
      .valueChanges.subscribe((val) => {
        if (val > 59) {
          this.calculateMidnightShiftForm
            .get("latitude.minutes")
            .patchValue("59");
        } else if (this.inSingleDigitRange().includes(val)) {
          this.calculateMidnightShiftForm
            .get("latitude.minutes")
            .patchValue(parseInt(val, 10));
        } else if (val < 0) {
          this.calculateMidnightShiftForm
            .get("latitude.minutes")
            .patchValue("0");
        }
      });
    this.calculateMidnightShiftForm
      .get("latitude.seconds")
      .valueChanges.subscribe((val) => {
        if (val > 59) {
          this.calculateMidnightShiftForm
            .get("latitude.seconds")
            .patchValue("59");
        } else if (this.inSingleDigitRange().includes(val)) {
          this.calculateMidnightShiftForm
            .get("latitude.seconds")
            .patchValue(parseInt(val, 10));
        } else if (val < 0) {
          this.calculateMidnightShiftForm
            .get("latitude.seconds")
            .patchValue("0");
        }
      });
    this.calculateMidnightShiftForm
      .get("longitude.degrees")
      .valueChanges.subscribe((val) => {
        if (val > 179) {
          this.calculateMidnightShiftForm
            .get("longitude.degrees")
            .patchValue("179");
        } else if (this.inSingleDigitRange().includes(val)) {
          this.calculateMidnightShiftForm
            .get("longitude.degrees")
            .patchValue(parseInt(val, 10));
        } else if (val < 0) {
          this.calculateMidnightShiftForm
            .get("longitude.degrees")
            .patchValue("0");
        }
      });
    this.calculateMidnightShiftForm
      .get("longitude.minutes")
      .valueChanges.subscribe((val) => {
        if (val > 59) {
          this.calculateMidnightShiftForm
            .get("longitude.minutes")
            .patchValue("59");
        } else if (this.inSingleDigitRange().includes(val)) {
          this.calculateMidnightShiftForm
            .get("longitude.minutes")
            .patchValue(parseInt(val, 10));
        } else if (val < 0) {
          this.calculateMidnightShiftForm
            .get("longitude.minutes")
            .patchValue("0");
        }
      });
    this.calculateMidnightShiftForm
      .get("longitude.seconds")
      .valueChanges.subscribe((val) => {
        if (val > 59) {
          this.calculateMidnightShiftForm
            .get("longitude.seconds")
            .patchValue("59");
        } else if (this.inSingleDigitRange().includes(val)) {
          this.calculateMidnightShiftForm
            .get("longitude.seconds")
            .patchValue(parseInt(val, 10));
        } else if (val < 0) {
          this.calculateMidnightShiftForm
            .get("longitude.seconds")
            .patchValue("0");
        }
      });

    this.calculateMidnightShiftForm
      .get("timeZone")
      .valueChanges.subscribe((val) => {
        if (val > 12) {
          this.calculateMidnightShiftForm.get("timeZone").patchValue("12");
        } else if (this.inSingleDigitRange().includes(val)) {
          this.calculateMidnightShiftForm
            .get("timeZone")
            .patchValue(parseInt(val, 10));
        } else if (val < -12) {
          this.calculateMidnightShiftForm.get("timeZone").patchValue("-12");
        }
      });
  }
  setMidNightShiftVal(event) {
    if (this.calculateMidnightShiftForm.valid) {
      this.service.dynaMidPointShiftValue.next(this.midNightShiftVal);

      this.activeModal.close();
    } else {
      this.markFormGroupTouched(this.calculateMidnightShiftForm);
    }
  }
  prepearePayloadAndSendToApi() {
    if (
      this.calculateMidnightShiftForm.valid &&
      this.calculateMidnightShiftForm.get("timeZone").value !== "-"
    ) {
      this.service
        .getMidnightShiftCalculation(
          this.calculateMidnightShiftForm.value,
          this.startConfigurationURL
        )
        .subscribe(
          (result) => {
            if (result.hasOwnProperty("MidnightShift")) {
              // this.service.dynaMidPointShiftValue.next(result["MidnightShift"]);
              this.midNightShiftVal = result["MidnightShift"];
              // this.service.getDynaMidPointShiftValue.subscribe((response) => {
              //   this.midNightShiftVal = response;
              // });
            }
          },
          (err) => {
            const modalRef = this.modalService.open(ErrorComponent, {
              keyboard: false,
              centered: true,
              windowClass: "error-style, error-style1",
            });
            modalRef.componentInstance.errorName = err["message"];
            modalRef.componentInstance.errorDescription = err["description"];
          }
          //window.alert(err["description"])
        );
    } else {
      this.markFormGroupTouched(this.calculateMidnightShiftForm);
    }
  }
  markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach((control) => {
      control.markAsTouched();
      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }
  onCancel() {
    this.activeModal.close();
  }
}
