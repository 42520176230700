import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BehaviorSubject, Observable, fromEvent, Subject } from "rxjs";
import { map } from "rxjs/operators";
import jwt_decode from "jwt-decode";
import { User } from "../models/users";
import { ApiService } from "./api.service";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DataLayerService } from "./data-layer.service";
// const Authenticate_URL="https://signify-portal-acc.trimm.net/.rest/multione/v1/authenticate";
const Authenticate_URL =
  environment.multiOneAPIUrl + "auth/api/v1/authenticate";

@Injectable({ providedIn: "root" })
export class AuthenticationService {
  public currentUserSubject: BehaviorSubject<any>;
  private currentUserName: BehaviorSubject<any>;
  public loggedInUserName: Observable<any>;
  loading = false;
  watchTokenExpire;
  idleTime = 0;
  refereshTokenURL =
    "https://signify-portal-prod.trimm.net/.rest/multione/v1/refresh/";
  idleTimeTracker;
  subscription: any;
  JWTToken: any;
  loggedIn: boolean;
  newToken: any;
  returnUrl: any;
  rfreshToken: string;
  tokenRefreshedFlag: Boolean = false;
  unsubscribe = new Subject<any>();

  constructor(
    private modalService: NgbModal,
    private http: HttpClient,
    private service: ApiService,
    private router: Router,
    private _dataLayerService: DataLayerService
  ) {
    this.currentUserSubject = new BehaviorSubject<any>(
      JSON.parse(localStorage.getItem("currentUser"))
    );
    this.currentUserName = new BehaviorSubject<any>("");
    this.loggedInUserName = this.currentUserName.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }
  setCurrentUser(setUser) {
    this.currentUserName.next(setUser);
  }

  login(username: string, password: string) {
    return this.service
      .apiCall("post", Authenticate_URL, {
        userName: username,
        password: password,
      })
      .pipe(
        map((user: any) => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          this.currentUserSubject.next(user);
          return user;
        })
      );
  }

  logout() {
    localStorage.removeItem("latestDcsVersion");
    // remove user from local storage to log user out
    this.currentUserSubject.next(
      JSON.parse(localStorage.getItem("currentUser"))
    );
  }

  async isTokenExpired() {
    let envURL = this.service.getEnvirnomentUrl(window.location.hostname);
    if (
      envURL
        .toString()
        .includes("https://signify-portal-oem-tools-acc.trimm.net")
    ) {
      this.refereshTokenURL =
        "https://signify-portal-acc.trimm.net/.rest/multione/v1/refresh";
    } else if (
      envURL
        .toString()
        .includes("https://www.tools.mytechnology.portal.signify.com") ||
      envURL.toString().includes("https://signify-portal-prod.trimm.net")
    ) {
      this.refereshTokenURL =
        "https://signify-portal-prod.trimm.net/.rest/multione/v1/refresh";
    } else {
      this.refereshTokenURL =
        "https://signify-portal-prod.trimm.net/.rest/multione/v1/refresh";
    }
    let expiresIn = JSON.parse(localStorage.getItem("expiresIn"));
    let now = Date.now();
    if (expiresIn != null) {
      this.tokenRefreshedFlag = JSON.parse(
        localStorage.getItem("tokenRefresh")
      );

      if (expiresIn < now) {
        // Expired
        this.stopTokenExpiredCheck();
        localStorage.removeItem("currentUser");
        localStorage.setItem("loggedIn", "false");
  
        setTimeout(() => {
          this.currentUserSubject.next(null);
          this.setCurrentUser("logout");
          this.service.logout(false);
          this.router.navigate(["/home"]);
          this.modalService.dismissAll();
          this.service.sendCloseSelectDevicePopup("close");
          this.service.sendCloseConfigPopup("close");
        }, 0);
      } else if (expiresIn - now <= 180000 && this.idleTime < 116) {
        //if(JSON.parse(expiresIn) - now<=50000){}else if(JSON.parse(expiresIn) - now<=50000 && !this.tokenRefreshedFlag){
        this.loading = true;
        localStorage.setItem("tokenRefresh", JSON.stringify(true));
        this.rfreshToken = localStorage.getItem("refreshToken");
        this.idleTime = 0;
        let header = new HttpHeaders({ "content-type": "application/json" });
        this.service
          .apiCall(
            "post",
            //"https://signify-portal-acc.trimm.net/.rest/multione/v1/refresh",MOWEB 1.1
            this.refereshTokenURL,
            { refresh: this.rfreshToken }
          )
          .subscribe((resp) => {
            // setTimeout(async ()=>{
            if (resp) {
              this.newToken = resp;
              this.LoginusingRefreshToken(resp);
            }
            // },500);
          });
        localStorage.removeItem("tokenRefresh");
        localStorage.setItem("tokenRefresh", JSON.stringify(false));
        this.unsubscribe.next();
      }
    }
    if (localStorage.getItem("loggedIn") != "true") {
      this.stopTokenExpiredCheck();
      localStorage.removeItem("currentUser");
      localStorage.setItem("loggedIn", "false");
      setTimeout(() => {
        this.currentUserSubject.next(null);
        this.setCurrentUser("logout");
        this.router.navigate(["/home"]);
        this.modalService.dismissAll();
      }, 0);
    }
  }

  startTokenExpiredCheck() {
    this.watchTokenExpire = setInterval(() => {
      this.isTokenExpired();
    }, 60000);

    this.idleTimeTracker = setInterval(() => {
      this.idleTime = this.idleTime + 1;
    }, 60000);
    this.subscription = fromEvent(document, "mousemove").subscribe((e) => {
      this.resetTimer();
    });
    this.subscription = fromEvent(document, "keypress").subscribe((e) => {
      this.resetTimer();
    });
    this.subscription = fromEvent(document, "load").subscribe((e) => {
      this.resetTimer();
    });
  }

  stopTokenExpiredCheck() {
    if (this.watchTokenExpire) {
      clearInterval(this.watchTokenExpire);
    }
  }
  resetTimer() {
    this.idleTime = 0;
    //clearInterval(this.idleTimeTracker);
  }

  ngOnDestroy() {
    if (this.idleTimeTracker) {
      clearInterval(this.idleTimeTracker);
    }
    this.subscription.unsubscribe();
  }

  LoginusingRToken(jwtToken) {
    var response: any;
    var mowebDecodeToken: any;
    var decodeToken: any;
    mowebDecodeToken = JSON.parse(atob(jwtToken));
    decodeToken = JSON.parse(atob(mowebDecodeToken.token.split(".")[1]));
    response = { token: mowebDecodeToken.token };
    this.loading = false;
    this.loggedIn = false;
    let now = Date.now();
    let schedule = now + 60 * 60 * 1000 * 2;
    localStorage.setItem("expiresIn", JSON.stringify(schedule));
    localStorage.setItem("loggedIn", "true");
    localStorage.setItem("currentUser", JSON.stringify(response));
    var decodedObj: any = jwt_decode(JSON.stringify(response));
    localStorage.setItem("Name", decodeToken.name);
    localStorage.setItem("UserId", decodeToken.sub);
    localStorage.setItem("refreshToken", mowebDecodeToken.refresh);
    this._dataLayerService.logUserDetails({
      UserId: localStorage.getItem("UserId"),
      companyId: decodedObj.company["companyId"],
      companyName: decodedObj.company["companyName"],
      userRole: decodedObj.role,
    });
    this.setCurrentUser(decodeToken.name);
    this.currentUserSubject.next(response);
    // this.router.navigate(['/home']);
  }

  LoginusingRefreshToken(jwtToken) {
    var response: any;
    var mowebDecodeToken: any;
    var decodeToken: any;
    // mowebDecodeToken =JSON.parse(atob(jwtToken));

    // mowebDecodeToken = atob(jwtToken);
    // decodeToken = atob(mowebDecodeToken.split('.')[1]).toString;
    response = { token: jwtToken.token };
    localStorage.setItem("refreshToken", jwtToken.refresh);
    this.loading = false;
    this.loggedIn = false;
    let now = Date.now();
    let schedule = now + 60 * 60 * 1000 * 2;
    localStorage.setItem("expiresIn", JSON.stringify(schedule));
    localStorage.setItem("loggedIn", "true");
    localStorage.setItem("currentUser", JSON.stringify(response));
    // localStorage.setItem('Name', decodeToken.name);
    // this.setCurrentUser(decodeToken.name);
    this.currentUserSubject.next(response);
    // this.router.navigate(['/home']);
  }
}
