import { Component, OnInit } from "@angular/core";
import { ApiService } from "src/app/core-module/services/api.service";
import * as d3 from "d3";
import { isNullOrUndefined } from "util";

@Component({
  selector: "app-dtl-graph",
  templateUrl: "./dtl-graph.component.html",
  styleUrls: ["./dtl-graph.component.scss"],
})
export class DtlGraphComponent implements OnInit {
  inputXRange = [0, 220];
  inputYRange = [0, 100];

  data: any[] = [
    { temperature: 0, dimLevel: 0 },
    { temperature: 10, dimLevel: 0 },
    { temperature: 10, dimLevel: 82 },
    { temperature: 100, dimLevel: 82 },
  ];
  forBiddenData: any[] = [
    { temperature: 92, dimLevel: 100 },
    { temperature: 100, dimLevel: 10 },
    { temperature: 100, dimLevel: 0 },
    { temperature: 125, dimLevel: 0 },
    { temperature: 125, dimLevel: 100 },
    { temperature: 92, dimLevel: 100 },
  ];
  focus;
  drag;
  public margin = { top: 50, right: 50, bottom: 50, left: 50 };
  public width: number;
  public height: number;
  public x: any;
  public y: any;
  public svg3: any;
  public line: d3.Line<[number, number]>; // this is line defination
  public area: d3.area<[number, number]>; // this is area defination
  public id = "lineChart";
  public color2 = "#00b36b";
  newData: any;
  changedPoint: any;
  feedMaxValue: any = 112;
  NormalLevelMaximum: any;
  BackgroundLevelMaximum: any;
  Maxdata: any = [];
  Tmaxstart: any;
  TmaxEnd: any;
  TmaxShutDown: any;
  scrolPoint: any;
  deRatingDimLevel: any;
  LamOffValue: any;
  DtlDimStopValue: any;
  TmaxdeRatingDim: any;
  Forbidenarea: d3.area<[number, number]>; // this is area defination
  deratingLabelYaxis: number;
  TminEnd: any;
  MaxLimit: any;
  TmindeRatingDim: any;
  dtlPercentageMaxValue:number;

  constructor(private apiService: ApiService) {}

  ngOnInit() {
    this.apiService.getMinMaxData.subscribe((data) => {
      this.Maxdata = data;
      this.Maxdata.forEach(element => {
        if (Object.keys(element)[0] === 'dtlPercentage') {
          this.dtlPercentageMaxValue = element['dtlPercentage']['ceil']
        }
      });
    });
    this.apiService.getDtldData.subscribe((data) => {
      if (data) {
        this.newData = data;
        this.convertData(data);
        this.buildSvg();
        this.addXandYAxis();
        this.drawLineAndPath();
        this.addDrag();
      }
    });
  }

  public buildSvg() {
    this.svg3 = d3.select("#svg10");
    this.width = 990 - this.margin.left - this.margin.right;
    this.height = 500 - this.margin.top - this.margin.bottom;
  }
  public addXandYAxis() {
    // range of data configuring
    this.x = d3.scaleLinear().range([0, this.width]);
    this.y = d3.scaleLinear().range([this.height, 0]);
    this.x.domain(this.inputXRange);
    this.y.domain(this.inputYRange);
  }

  public drawLineAndPath() {
    this.line = d3
      .line()
      .x((d: any) => this.x(d.temperature))
      .y((d: any) => this.y(d.dimLevel));
    d3.selectAll("#svg10 > *").remove();
    this.area = d3
      .area()
      .x((d: any) => this.x(d.temperature))
      .y0(this.height)
      .y1((d: any) => this.y(d.dimLevel));

    this.Forbidenarea = d3
      .area()
      .x((d: any) => this.x(d.temperature))
      .y0(20)
      .y1((d: any) => this.y(d.dimLevel));

    this.focus = this.svg3
      .append("g")
      .attr(
        "transform",
        "translate(" + this.margin.left + "," + this.margin.top + ")"
      );
    //ploting Area
    this.focus
      .append("path")
      .datum(this.data)
      .attr("fill", "#1ECBD0")
      .attr("fill-opacity", 0.3)
      .attr("stroke", "#1ECBD0")
      .attr("stroke-width", "1px")
      .attr("fill", "url('#gradient10')")
      .attr("d", this.area);

    this.addDeratingLine();
    this.addForbiddenArea();
    this.addTMAXStart();

    //if (this.newData["Dtl"]) {
    this.addTMAXEnd();
    //}
    if (this.newData["DtlLampOffVal"]) {
      this.addTMAXShutDown();
    }
    //ploting circle
    this.focus
      .selectAll("circle")
      .data(this.data)
      .enter()
      .append("circle")
      .attr("r", 4.0)
      .attr("cx", (d: any) => this.x(d.temperature))
      .attr("cy", (d: any) => this.y(d.dimLevel))
      .style("cursor", "pointer")
      .style("fill", "#1ECBD0");

    const maxY = d3.max(this.data, function (d) {
      return d.dimLevel;
    });
    // Add Gradient
    this.svg3
      .append("linearGradient")
      .attr("id", "gradient10")
      .attr("gradientUnits", "userSpaceOnUse")
      .attr("x1", 0)
      .attr("y1", this.y(0))
      .attr("x2", 0)
      .attr("y2", this.y(maxY))
      .selectAll("stop")
      .data([
        {
          offset: "10%",
          color: "transparent",
        },
        {
          offset: "30%",
          color: "#00E487",
        },
        {
          offset: "100%",
          color: this.color2,
        },
      ])
      .enter()
      .append("stop")
      .attr("offset", function (d) {
        return d.offset;
      })
      .attr("stop-color", function (d) {
        return d.color;
      });

    this.focus
      .append("g")
      .attr("class", "axis axis--x")
      //.style("color", "white")
      .attr("transform", "translate(0," + this.height + ")")
      .call(d3.axisBottom(this.x));
    // text label for the x axis
    this.svg3
      .append("text")
      /*.attr("transform",
        "translate(" + ((this.width / 2) + 20) + " ," +
        (this.height + this.margin.top + 40) + ")")*/
      .attr("x", this.x(50)+100)
      .attr("y", this.y(-22))
      .style("text-anchor", "middle")
      .style("font", "16px Signify-Relative-Bold")
      .html("Temperature (&degC)");

    // Configure the Y Axis
    this.focus
      .append("g")
      .attr("class", "axis axis--y")
      .call(d3.axisLeft(this.y));

    // text label for the y axis
    this.svg3
      .append("text")
      .attr("transform", "rotate(-90)")
      .attr("y", 0 - this.margin.left + 62)
      .attr("x", 0 - this.height / 3 -117)
      .style("text-anchor", "middle")
      .style("font", "16px Signify-Relative-Bold")
      .text("Dim level (%)");

    // add the X gridlines
    this.focus
      .append("g")
      // .attr("class", "grid")
      .attr("transform", "translate(0," + this.height + ")")
      .call(this.make_x_gridlines().tickSize(-this.height).tickFormat(""));

    // add the Y gridlines
    this.focus
      .append("g")
      // .attr("class", "grid")
      .call(this.make_y_gridlines().tickSize(-this.width).tickFormat(""));
  }

  // gridlines in x axis function
  make_x_gridlines() {
    return d3.axisBottom(this.x).ticks(10);
  }
  // gridlines in y axis function
  make_y_gridlines() {
    return d3.axisLeft(this.y).ticks(10);
  }

  public addDrag() {
    const _this = this;
    let selection = this.focus.selectAll("circle").call(
      d3
        .drag()
        .on("start", function (event, d) {
          const control = _this.dragstarted();
        })
        .on("drag", function (event, d) {
          const control = _this.dragstarted();
          const n = selection.nodes();
          const i = n.indexOf(this);
          if (i >= 1 && i <= 3) {
            control.changedPoint = i;
            const m = d3.select(n[i]).node();
            d.temperature = control.x.invert(event.x);
            if(d.temperature<0){
              if(i===1){
                d.temperature = 0;
              }
              else
              return;
            }
            
            if (i == control.scrolPoint) {
              d.dimLevel = control.y.invert(event.y);
              if (_this.dtlPercentageMaxValue && i === 2 && (d.dimLevel > _this.dtlPercentageMaxValue)) {
                d.dimLevel = _this.dtlPercentageMaxValue;
              }
            }
            d3.select(m)
              .raise()
              .attr("cx", control.x(d.temperature))
              .attr("cy", control.y(d.dimLevel));
            control.focus.select("path").attr("d", control.area);
          }
        })
        .on("end", this.dragended.bind(this))
    );
  }

  dragstarted() {
    return this;
  }
  public dragended(d) {
    var newArrayData = [];
    if (this.newData["DtlEnabled"]) {
      newArrayData["DtlEnabled"] = this.newData["DtlEnabled"];
    }
    if (
      this.newData["DtlLampOffDisabled"] === true ||
      this.newData["DtlLampOffDisabled"] === false
    ) {
      newArrayData["DtlLampOffDisabled"] = this.newData["DtlLampOffDisabled"];
    }
    if (this.changedPoint == 1) {
      var DtlDimStartVal = this.data[1].temperature;
      if (this.data[1].temperature >= this.Tmaxstart) {
        DtlDimStartVal = this.Tmaxstart;
      }
    } else {
      var DtlDimStartVal = this.newData["DtlDimStartVal"];
    }
    if (this.changedPoint == 2 && this.newData["DtlDimStopVal"]) {
      var DtlDimStopVal = this.data[2].temperature;
      if (this.data[2].temperature >= this.TmaxEnd) {
        DtlDimStopVal = this.TmaxEnd;
      } else if (this.data[2].temperature <= DtlDimStartVal + 5) {
        DtlDimStopVal = DtlDimStartVal + 5;
      }
    } else {
      if (this.newData["DtlDimStopVal"] != undefined) {
        var DtlDimStopVal = this.newData["DtlDimStopVal"];
      }
    }
    if (this.newData["DtlLampOffVal"]) {
      if (this.changedPoint == 3) {
        if (this.data[3].temperature >= this.TmaxShutDown) {
          this.data[3].temperature = this.TmaxShutDown;
        } else if (this.data[3].temperature <= DtlDimStopVal + 2) {
          this.data[3].temperature = DtlDimStopVal + 2;
        }

        newArrayData["DtlLampOffVal"] = Math.round(this.data[3].temperature);
        newArrayData["DtlOutputPercentageVal"] = Math.round(
          this.data[3].dimLevel
        );
        if (this.data[3].dimLevel >= this.TmaxdeRatingDim) {
          newArrayData["DtlOutputPercentageVal"] = this.TmaxdeRatingDim;
        } else if (this.data[3].dimLevel <= this.TmindeRatingDim) {
          newArrayData["DtlOutputPercentageVal"] = this.TmindeRatingDim;
        }
      } else {
        newArrayData["DtlLampOffVal"] = this.newData["DtlLampOffVal"];
        newArrayData["DtlOutputPercentageVal"] =
          this.newData["DtlOutputPercentageVal"];
      }
    } else {
      if (this.changedPoint == 2) {
        newArrayData["DtlOutputPercentageVal"] = Math.round(
          this.data[2].dimLevel
        );
      } else {
        newArrayData["DtlOutputPercentageVal"] =
          this.newData["DtlOutputPercentageVal"];
      }
    }

    newArrayData["DtlDimStartVal"] = Math.round(DtlDimStartVal);
    if (this.newData["DtlDimStopVal"] != undefined) {
      newArrayData["DtlDimStopVal"] = Math.round(DtlDimStopVal);
    }
    //newArrayData['DtlOutputPercentageVal']=this.newData['DtlOutputPercentageVal'];
    if (this.newData["DtlLampOffVal"] && this.newData["DtlLampOffDisabled"]) {
      newArrayData["DtlLampOffVal"] = this.newData["DtlLampOffVal"];
      /* newArrayData["DtlOutputPercentageVal"] =
        this.newData["DtlOutputPercentageVal"]; *///MOWEB 1.1
      newArrayData["DtlLampOffDisabled"] = this.newData["DtlLampOffDisabled"];
    }
    this.apiService.sendDtldata(newArrayData);
  }

  convertData(data) {
    this.deRatingDimLevel = data["DtlOutputPercentageVal"];
    if (this.Maxdata[0]["dtlStart"]["ceil"]) {
      this.Tmaxstart = this.Maxdata[0]["dtlStart"]["ceil"];
      this.MaxLimit = this.Maxdata[0]["dtlStart"]["maxLimit"];
    }
    if (this.Maxdata[1]["dtlPercentage"]["ceil"]) {
      this.TmaxdeRatingDim = this.Maxdata[1]["dtlPercentage"]["ceil"];
      this.TmindeRatingDim = this.Maxdata[1]["dtlPercentage"]["floor"];
    }
    if (this.Maxdata[2] != undefined && this.Maxdata[2]["dtlStop"]["ceil"]) {
      this.TmaxEnd = this.Maxdata[2]["dtlStop"]["ceil"];
    }

    if (!isNullOrUndefined(data["DtlLampOffVal"]) && 
    
        (!isNullOrUndefined(data["DtlLampOffDisabled"]) && (data["DtlLampOffDisabled"] === false || data["DtlLampOffDisabled"] === true))) {
      this.scrolPoint = 3;
      this.deratingLabelYaxis = 6;
      if (this.Maxdata[3]["dtlLampOff"]["ceil"]) {
        this.TmaxShutDown = this.Maxdata[3]["dtlLampOff"]["ceil"];
      }
      this.inputXRange = [0, this.TmaxShutDown + 25];
      this.LamOffValue = data["DtlLampOffVal"];
      this.DtlDimStopValue = data["DtlDimStopVal"];
      this.data = [
        { temperature: 0, dimLevel: 100 },
        { temperature: data["DtlDimStartVal"], dimLevel: 100 },
        {
          temperature: data["DtlDimStopVal"],
          dimLevel: data["DtlOutputPercentageVal"],
        },
        {
          temperature: data["DtlLampOffVal"],
          dimLevel: data["DtlOutputPercentageVal"],
        },
        { temperature: data["DtlLampOffVal"], dimLevel: 0 },
      ];
      this.forBiddenData = [
        { temperature: this.Tmaxstart, dimLevel: 100 },
        { temperature: this.TmaxEnd, dimLevel: 10 },
        { temperature: this.TmaxShutDown, dimLevel: 10 },
        { temperature: this.TmaxShutDown, dimLevel: 0 },
        { temperature: this.TmaxShutDown + 25, dimLevel: 0 },
        { temperature: this.TmaxShutDown + 25, dimLevel: 100 },
        { temperature: this.Tmaxstart, dimLevel: 100 },
      ];
    } else if (data["DtlDimStopVal"] != undefined) {
      this.scrolPoint = 2;
      this.deratingLabelYaxis = 4;
      this.inputXRange = [0, this.TmaxEnd + 25];
      this.data = [
        { temperature: 0, dimLevel: 100 },
        { temperature: data["DtlDimStartVal"], dimLevel: 100 },
        {
          temperature: data["DtlDimStopVal"],
          dimLevel: data["DtlOutputPercentageVal"],
        },
        { temperature: data["DtlDimStopVal"], dimLevel: 0 },
      ];
      this.forBiddenData = [
        { temperature: this.Tmaxstart, dimLevel: 100 },
        { temperature: this.TmaxEnd, dimLevel: 10 },
        { temperature: this.inputXRange[1], dimLevel: 10 },
        { temperature: this.inputXRange[1], dimLevel: 100 },
        { temperature: this.Tmaxstart, dimLevel: 100 },
      ];
    } else {
      this.scrolPoint = 2;
      this.deratingLabelYaxis = 2;
      this.inputXRange = [0, this.Tmaxstart + 25];
      this.data = [
        { temperature: 0, dimLevel: 100 },
        { temperature: data["DtlDimStartVal"], dimLevel: 100 },
        {
          temperature: data["DtlDimStartVal"],
          dimLevel: data["DtlOutputPercentageVal"],
        },
        { temperature: data["DtlDimStartVal"], dimLevel: 0 },
      ];
      this.forBiddenData = [
        { temperature: this.Tmaxstart, dimLevel: 100 },
        { temperature: this.Tmaxstart, dimLevel: 10 },
        { temperature: this.inputXRange[1], dimLevel: 10 },
        { temperature: this.inputXRange[1], dimLevel: 100 },
        { temperature: this.Tmaxstart, dimLevel: 100 },
      ];
    }
  }

  addDeratingLine() {
    this.focus
      .append("line")
      .style("stroke", "grey")
      .style("stroke-dasharray", "5, 5")
      .text("Derating")
      .attr("x1", 0)
      .attr("y1", this.y(this.deRatingDimLevel))
      .attr("x2", this.x(this.inputXRange[1]))
      .attr("y2", this.y(this.deRatingDimLevel));

    this.focus
      .append("text")
      .attr("x", this.x(-this.deratingLabelYaxis))
      .attr("y", this.y(this.deRatingDimLevel))
      .attr(
        "transform",
        "rotate(-90," +
          this.x(-this.deratingLabelYaxis) +
          "," +
          this.y(this.deRatingDimLevel) +
          ")"
      )
      .attr("text-anchor", "start")
      .style("font", "10px Signify-Relative-Bold")
      .style("color", "lightgrey")
      .style("text-anchor", "middle")
      //.text("Derating");
  }

  addTMAXShutDown() {
    this.focus
      .append("line")
      .style("stroke", "grey")
      .style("stroke-dasharray", "5, 5")
      .text("T_MAX_START")
      .attr("x1", this.x(this.TmaxShutDown))
      .attr("y1", this.y(100))
      .attr("x2", this.x(this.TmaxShutDown))
      .attr("y2", this.y(0));

    this.focus
      .append("text")
      .attr("x", this.x(this.TmaxShutDown))
      .attr("y", this.y(-7))
      .attr("text-anchor", "end")
      .style("font", "10px Signify-Relative-Bold")
      .style("color", "lightgrey")
      .style("text-anchor", "middle")
      //.text("T_Max_Shutdown");
  }

  addTMAXStart() {
    this.focus
      .append("line")
      .style("stroke", "grey")
      .style("stroke-dasharray", "5, 5")
      .attr("x1", this.x(this.Tmaxstart))
      .attr("y1", this.y(100))
      .attr("x2", this.x(this.Tmaxstart))
      .attr("y2", this.y(0));
    this.focus
      .append("text")
      .attr("x", this.x(this.Tmaxstart))
      .attr("y", this.y(-7))
      .attr("text-anchor", "end")
      .style("font", "10px Signify-Relative-Bold")
      .style("color", "lightgrey")
      .style("text-anchor", "middle")
      //.text("T_Max_Start");
  }

  addTMAXEnd() {
    this.focus
      .append("line")
      .style("stroke", "grey")
      .style("stroke-dasharray", "5, 5")
      //.text("T_MAX_END")
      .attr("x1", this.x(this.TmaxEnd))
      .attr("y1", this.y(100))
      .attr("x2", this.x(this.TmaxEnd))
      .attr("y2", this.y(0));
    this.focus
      .append("text")
      .attr("x", this.x(this.TmaxEnd))
      .attr("y", this.y(-12))
      .attr("text-anchor", "end")
      .style("font", "10px Signify-Relative-Bold")
      .style("color", "lightgrey")
      .style("text-anchor", "middle")
      //.text("T_Max_End");
  }

  addForbiddenArea() {
    let fontSizeForBiddenText: string = "16px Signify-Relative-Bold";
    let xForBiddenTextPlacment = (this.inputXRange[1] + this.Tmaxstart) / 2 + 5;

    if (this.inputXRange[1] > 200) {
      xForBiddenTextPlacment = (this.inputXRange[1] + this.TmaxShutDown) / 2;
      fontSizeForBiddenText = "14px Signify-Relative-Bold";
    }

    this.focus
      .append("path")
      .datum(this.forBiddenData)
      .attr("fill", "gray")
      .attr("fill-opacity", 0.3)
      .attr("stroke", "gray")
      .attr("stroke-width", "1px")
      .attr("d", this.Forbidenarea);
    this.focus
      .append("text")
      .attr("x", this.x(xForBiddenTextPlacment))
      .attr("y", this.y((this.inputYRange[1] - 15) / 2))
      .attr("text-anchor", "end")
      .style("font", fontSizeForBiddenText)
      .style("color", "Red")
      .style("text-anchor", "middle")
      .text("Forbidden Area");
  }
}
