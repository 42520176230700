import {
  Component,
  OnInit,
  ViewEncapsulation,
  OnDestroy,
  ChangeDetectorRef,
  HostListener,
  ViewChild,
  ElementRef,
  Input,
} from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
  FormArray,
  NgForm,
} from "@angular/forms";
import { ApiService } from "../../../core-module/services/api.service";
import { NgbTabChangeEvent } from "@ng-bootstrap/ng-bootstrap";
import { pairwise, startWith, take, takeUntil } from "rxjs/operators";
import { Subscription, Subject } from "rxjs";
import { restore } from "./../../../../assets/json/restoreTooltip.en";
import { isNullOrUndefined } from "util";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ModalService } from "src/app/core-module/services/modal.service";

@Component({
  selector: "app-clo",
  templateUrl: "./clo.component.html",
  styleUrls: ["./clo.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class CloComponent implements OnInit {
  addForm: FormGroup;
  rows: FormArray;
  rowLength = 0;
  registerForm: FormGroup;
  submitted = false;
  activeIdString: any = "1";
  checkValid = true;
  fewFieldValuePresent = false;
  dataToShow = [];
  Max;
  Steps;
  Initial;
  Final;
  hrs;
  powerLevel;
  unsubscribe = new Subject<any>();
  isTableValuesChanged = false;
  isValuesNumericGenerated = false;
  tableValueSubmit = false;
  isNumericCreatedRows = false;
  isGraphUpdated = false;
  disableGraph = false;
  defaultColor = true;
  disableAddBtn = false;
  workingHrsValuesArr: any = [];
  isAscending = false;
  isGraphDragUpdate = false;
  unsubscribeNumeric = new Subject<any>();
  enableCOnfigureFlow = false;
  menuState;
  menuPosition;
  menuPositionX;
  menuPositionY;
  menuWidth;
  menuHeight;
  windowWidth;
  windowHeight;
  selectedRow;
  disableAddAboveBtn = false;
  disableAddBelowBtn = false;
  disableNumericClear = true;
  unsubscribeGraphType = new Subject<any>();
  enableChecked = false;
  cloLite;
  showClo;
  activeHours;
  initialLevel;
  subscription;
  restoreText = restore;
  @ViewChild("contextMenu", { static: false }) contextMenu: ElementRef;
  graphRepresentationType = "";
  afterClearField = false;
  fileResponse: any = {};
  count: any;
  respVal: string;
  @Input() public cloSchema;
  graphData: Subscription;
  clonedDataToShow: any[] = [];
  clearFields:boolean=false;
  getCloLoadFeatureFileConfigureSub: Subscription;
  constructor(
    private fb: FormBuilder,
    private apiService: ApiService,
    private modalService: ModalService,
    private cdRef: ChangeDetectorRef
  ) {
    this.addForm = this.fb.group({});
    this.rows = this.fb.array([]);
  }

  ngOnInit() {
    localStorage.setItem("cloPointsStepValue", "0");
    this.getCloLoadFeatureFileConfigureSub = this.apiService.getCloLoadFeatureFileConfigure.subscribe((response) => {
      if (response === "Configure_CLO") {
        this.enableCOnfigureFlow = true;
        let conf = JSON.parse(localStorage.getItem("configurationData"));
        if (conf.resp.CLO) {
          var cloEnabled = conf.resp.CLO.CloEnabled;
          if (cloEnabled === true) {
            this.apiService.setCloDefaultColor(false);

          }
          this.apiService.setEnableGraph(cloEnabled);
          this.enableGraph(cloEnabled);
          this.enableChecked = cloEnabled;
          this.disableGraph = !Boolean(cloEnabled);
        }
      }
    });
    this.apiService.getCloDefaultValue.subscribe((response) => {
      if (response == "valid") {
        this.disableGraph = false;
        this.setDefaultGraph("valid");
      }
    });
    this.fileResponse = JSON.parse(localStorage.getItem("configurationData"));
    let unSelectedFeat = Array(
      JSON.parse(localStorage.getItem("UnSelectedFeatConfig"))
    );

    let featIndex: number = 0;
    if (unSelectedFeat[0] != null && unSelectedFeat[0].length > 0) {
      unSelectedFeat[0].forEach((element) => {
        if (!isNullOrUndefined(element) && !isNullOrUndefined(element["featureName"]) && element["featureName"].toString().toUpperCase() === "CLO") {
          this.fileResponse = {};
          let CLOUnSelected: any = element["featureParameters"][0];
          let clonewEditedData = {
            resp: {
              CLO: CLOUnSelected,
            },
          };
          this.fileResponse = clonewEditedData;
          unSelectedFeat[0].splice(featIndex, 1);
          localStorage.setItem(
            "UnSelectedFeatConfig",
            JSON.stringify(unSelectedFeat[0])
          );
        } else {
          featIndex++;
        }
      });
    }


    this.dataToShow = [];
    this.rows.clear();
    this.onAddRow();
    this.defaultColor = true;
    this.dataToShow.push({
      hour: this.cloSchema["properties"]["CloPoint1"]["properties"]["Time"][
        "const"
      ],
      power:
        this.cloSchema["properties"]["CloPoint1"]["properties"]["Percentage"][
        "default"
        ],
    });
    this.rows.controls[0]["controls"].workingHours.setValue(
      this.cloSchema["properties"]["CloPoint1"]["properties"]["Time"]["const"]
    );
    this.rows.controls[0]["controls"].outputLevel.setValue(
      this.cloSchema["properties"]["CloPoint1"]["properties"]["Percentage"][
      "default"
      ]
    );
    this.checkAddesRowValuesChanged(this.rows.length - 1);
    this.apiService.sendGraphIsEditedData([
      {
        hour: this.cloSchema["properties"]["CloPoint1"]["properties"]["Time"][
          "const"
        ],
        power:
          this.cloSchema["properties"]["CloPoint1"]["properties"]["Percentage"][
          "default"
          ],
      },
    ]);
    this.apiService.getGraphRepresentation
      .pipe(takeUntil(this.unsubscribeGraphType))
      .subscribe((data) => {
        if (data != "") {
          this.graphRepresentationType = data;
          if (this.fileResponse["resp"] && this.fileResponse["resp"]["CLO"]) {
            this.dataToShow.pop();
          }
          this.apiService.sendCloGraphData(
            this.graphRepresentationType,
            this.dataToShow
          );
        }
      });

    if (this.enableCOnfigureFlow === true) this.enableGraph(true);
    else this.enableGraph(false);
    this.addForm.addControl("rows", this.rows);
    this.rowLength = this.rows.length;
    this.registerForm = this.fb.group({
      cloHour: [
        "",
        [
          Validators.required,
          Validators.pattern("^[0-9]*$"),
          Validators.min(
            this.cloSchema["properties"]["CloPoint1"]["properties"]["Time"][
            "const"
            ]
          ),
          Validators.max(
            this.cloSchema["properties"]["CloPoint16"]["properties"]["Time"][
            "maximum"
            ]
          ),
        ],
      ],
      cloSteps: [
        "",
        [
          Validators.required,
          Validators.pattern("^[0-9]*$"),
          Validators.min(1),
          Validators.max(16),
        ],
      ],
      initialOutput: [
        "",
        [
          Validators.required,
          Validators.pattern("^[0-9]*$"),
          Validators.min(
            this.cloSchema["properties"]["CloPoint1"]["properties"][
            "Percentage"
            ]["minimum"]
          ),
          Validators.max(
            this.cloSchema["properties"]["CloPoint16"]["properties"][
            "Percentage"
            ]["maximum"]
          ),
        ],
      ],
      finalOutput: [
        "",
        [
          Validators.required,
          Validators.pattern("^[0-9]*$"),
          Validators.min(
            this.cloSchema["properties"]["CloPoint1"]["properties"][
            "Percentage"
            ]["minimum"]
          ),
          Validators.max(
            this.cloSchema["properties"]["CloPoint16"]["properties"][
            "Percentage"
            ]["maximum"]
          ),
        ],
      ],
    });

    this.subscription = this.apiService.getCloHead.subscribe((data) => {
      if (data === "Clo") {
        this.showClo = true;
      }
    });
    this.f.cloHour.valueChanges
      .pipe(takeUntil(this.unsubscribeNumeric))
      .subscribe((data) => {
        if (data !== "") {
          this.autoDetectNumericGenerator();
        }
      });
    this.f.cloSteps.valueChanges
      .pipe(takeUntil(this.unsubscribeNumeric))
      .subscribe((data) => {
        if (data !== "") {
          localStorage.setItem("cloPointsStepValue", String(data));
          this.autoDetectNumericGenerator();
        }
      });
    this.f.initialOutput.valueChanges
      .pipe(takeUntil(this.unsubscribeNumeric))
      .subscribe((data) => {
        if (data !== "") {
          this.autoDetectNumericGenerator();
        }
      });
    this.f.finalOutput.valueChanges
      .pipe(takeUntil(this.unsubscribeNumeric))
      .subscribe((data) => {
        if (data !== "") {
          this.autoDetectNumericGenerator();
        }
      });

    //Numeric Generator Values Auto Detect - Starts Here
    if (
      this.fileResponse &&
      this.fileResponse["resp"] &&
      this.fileResponse["resp"]["CLO"]
    ) {
      setTimeout(() => {
        this.clearAllValidators();
      }, 10);
      if (this.fileResponse["resp"]["CLO"]) {
        this.enableChecked = this.fileResponse["resp"]["CLO"]["CloEnabled"];

        if (this.enableChecked) {
          this.disableGraph = false;
          this.defaultColor = false;
          this.apiService.setEnableGraph(true);
        } else {
          this.disableGraph = true;
          if (
            this.fileResponse["resp"] &&
            this.fileResponse["resp"]["CLO"] &&
            !isNullOrUndefined(this.fileResponse["resp"]["CLO"]["CloPoint1"])
          ) {
            if (
              this.fileResponse["resp"]["CLO"]["CloPoint1"]["Time"] ==
              this.cloSchema["properties"]["CloPoint1"]["properties"]["Time"][
              "const"
              ] &&
              this.fileResponse["resp"]["CLO"]["CloPoint1"]["Percentage"] ==
              this.cloSchema["properties"]["CloPoint1"]["properties"][
              "Percentage"
              ]["default"]
            ) {
              this.defaultColor = true;
            } else {
              this.defaultColor = false;
            }
          }
          this.apiService.setEnableGraph(false);
        }
        this.autoDetectNumericGenerator();
        this.fileResponse = "";
        setTimeout(() => {
          this.setAllValidators();
        }, 100);
      }
    }
    //Numeric Generator Values Auto Detect - Ends Here

    this.apiService.getChangedGraphData.subscribe((data) => {
      //change rows when drag graph changes -start here
      if (this.registerForm.valid) {
        this.isNumericCreatedRows = true;
      }
      if (data.length) {
        if (this.isGraphUpdated && this.isNumericCreatedRows) {
          this.confirmGraphChangeValues(data);
        }
        if (!this.isGraphUpdated && this.isNumericCreatedRows) {
          this.confirmGraphChangeValues(data);
        } else {
          if (!this.isNumericCreatedRows) {
            this.updateGraphDataToTable(data);
          }
        }
      }
    }); //change rows when drag graph changes -ends here
    this.apiService.setCloDefaultColor(this.defaultColor);

    setTimeout(() => {
      if (this.enableCOnfigureFlow === true) {
        if (this.enableChecked) {
          this.disableGraph = false;
          this.enableChecked = true;
        } else {
          this.disableGraph = true;
          this.enableChecked = false;
        }
      }
    }, 5000);

    this.checkActualCloPoints();

    this.checkFinalDefaultTable();
  }
  checkFinalDefaultTable() {
    if (this.rows.length === 1) {
      let wH = this.rows.controls[0]["controls"].workingHours.value;
      let oL = this.rows.controls[0]["controls"].outputLevel.value;

      if (
        !(
          Number(wH) ===
          this.cloSchema["properties"]["CloPoint1"]["properties"]["Time"][
          "const"
          ]
        ) ||
        !Number(
          oL ===
          this.cloSchema["properties"]["CloPoint1"]["properties"][
          "Percentage"
          ]["default"]
        )
      ) {
        this.apiService.setCloDefaultColor(false);

        this.defaultColor = false;
      }
    }
    if (this.enableChecked === true) {
      this.defaultColor = false;
      this.apiService.setCloDefaultColor(false);

    }
  }
  checkActualCloPoints() {
    localStorage.removeItem("cloPoints");
    let cloPointsAdded = this.rows.length;
    localStorage.setItem("cloPoints", String(cloPointsAdded));
  }
  setAllValidators() {
    if (this.showClo) {
      this.registerForm.controls["cloSteps"].setValidators([
        Validators.required,
        Validators.pattern("^[0-9]*$"),
        Validators.min(1),
        Validators.max(16),
      ]);
      this.registerForm.controls["finalOutput"].setValidators([
        Validators.required,
        Validators.pattern("^[0-9]*$"),
        Validators.min(
          this.cloSchema["properties"]["CloPoint1"]["properties"]["Percentage"][
          "minimum"
          ]
        ),
        Validators.max(
          this.cloSchema["properties"]["CloPoint16"]["properties"][
          "Percentage"
          ]["maximum"]
        ),
      ]);
      this.registerForm.controls["initialOutput"].setValidators([
        Validators.required,
        Validators.pattern("^[0-9]*$"),
        Validators.min(
          this.cloSchema["properties"]["CloPoint1"]["properties"]["Percentage"][
          "minimum"
          ]
        ),
        Validators.max(
          this.cloSchema["properties"]["CloPoint16"]["properties"][
          "Percentage"
          ]["maximum"]
        ),
      ]);
      this.registerForm.controls["cloHour"].setValidators([
        Validators.required,
        Validators.pattern("^[0-9]*$"),
        Validators.min(
          this.cloSchema["properties"]["CloPoint1"]["properties"]["Time"][
          "const"
          ]
        ),
        Validators.max(
          this.cloSchema["properties"]["CloPoint16"]["properties"]["Time"][
          "maximum"
          ]
        ),
      ]);
    }
  }
  clearAllValidators() {
    const closteps = this.registerForm.get("cloSteps");
    const cloFinal = this.registerForm.get("finalOutput");
    const initialOutput = this.registerForm.get("initialOutput");
    const cloHour = this.registerForm.get("cloHour");
    closteps.clearValidators();
    closteps.updateValueAndValidity();

    cloFinal.clearValidators();
    cloFinal.updateValueAndValidity();

    initialOutput.clearValidators();
    initialOutput.updateValueAndValidity();

    cloHour.clearValidators();
    cloHour.updateValueAndValidity();

    this.checkActualCloPoints();
  }
  checkValidator() {
    setTimeout(() => {
      const closteps = this.registerForm.controls["cloSteps"];
      const cloFinal = this.registerForm.controls["finalOutput"];
    }, 100);
  }
  autoDetectNumericGenerator() {
    //Numeric Generator Values Auto Detect - Starts Here
    setTimeout(() => {
      this.checkValidator();
      this.disableNumericClear = false;
      if (
        this.registerForm.valid ||
        (this.fileResponse["resp"] && this.fileResponse["resp"]["CLO"])
      ) {
        this.Max = Number(this.f.cloHour.value);
        if (this.fileResponse["resp"] && this.fileResponse["resp"]["CLO"]) {
          this.Steps = Object.keys(this.fileResponse["resp"]["CLO"]).length - 1;
        } else {
          this.Steps = Number(this.f.cloSteps.value);
        }
        this.Initial = Number(this.f.initialOutput.value);
        this.Final = Number(this.f.finalOutput.value);
        this.dataToShow = [];
        if (this.Steps !== 1) {
          let prevHour = null;
          let prevpowerLevel = null;
          for (let n = 0; n <= this.Steps - 1; n++) {
            this.onAddRow();
            if (this.fileResponse["resp"]) {
              if (
                this.fileResponse["resp"]["CLO"] &&
                this.fileResponse["resp"]["CLO"]["CloPoint" + [n + 1]]
              ) {
                this.dataToShow.push({
                  hour: this.fileResponse["resp"]["CLO"]["CloPoint" + [n + 1]][
                    "Time"
                  ],
                  power:
                    this.fileResponse["resp"]["CLO"]["CloPoint" + [n + 1]][
                    "Percentage"
                    ],
                });
              } else {
                this.hrs = Math.round(
                  0 + (n * (this.Max - 0)) / (this.Steps - 1)
                );
                this.powerLevel = Math.floor(
                  this.Initial +
                  (n * (this.Final - this.Initial)) / (this.Steps - 1)
                );
                if (prevHour != null && this.hrs === prevHour) {
                  this.powerLevel = prevpowerLevel;
                }
                prevHour = this.hrs;
                prevpowerLevel = this.powerLevel;
                this.dataToShow.push({
                  hour: this.hrs,
                  power: this.powerLevel,
                });
              }
            } else {
              this.hrs = Math.round(
                0 + (n * (this.Max - 0)) / (this.Steps - 1)
              );
              this.powerLevel = Math.floor(
                this.Initial +
                (n * (this.Final - this.Initial)) / (this.Steps - 1)
              );
              if (prevHour != null && this.hrs === prevHour) {
                this.powerLevel = prevpowerLevel;
              }
              prevHour = this.hrs;
              prevpowerLevel = this.powerLevel;
              this.dataToShow.push({
                hour: this.hrs,
                power: this.powerLevel,
              });
            }
          }
        }
        if (this.Steps === 1) {
          for (let n = 0; n <= this.Steps - 1; n++) {
            this.onAddRow();
            if (this.fileResponse["resp"]) {
              if (this.fileResponse["resp"]["CLO"]) {
                this.defaultColor = true;
                this.dataToShow.push({
                  hour: this.fileResponse["resp"]["CLO"]["CloPoint" + [n + 1]][
                    "Time"
                  ],
                  power:
                    this.fileResponse["resp"]["CLO"]["CloPoint" + [n + 1]][
                    "Percentage"
                    ],
                });
              } else {
                this.hrs = Math.round(0 + n * (this.Max - 0));
                this.powerLevel = Math.floor(
                  this.Initial + n * (this.Final - this.Initial)
                );
                this.dataToShow.push({
                  hour: this.hrs,
                  power: this.powerLevel,
                });
              }
            } else {
              this.hrs = Math.round(0 + n * (this.Max - 0));
              this.powerLevel = Math.floor(
                this.Initial + n * (this.Final - this.Initial)
              );
              this.dataToShow.push({
                hour: this.hrs,
                power: this.powerLevel,
              });
            }
          }
        }
        this.apiService.sendGraphIsEditedData(this.dataToShow);
        this.apiService.sendCloGraphData(
          this.graphRepresentationType,
          this.dataToShow
        );
        //this.checkActualCloPoints();
      }
    }, 0);
    setTimeout(() => {
      if (this.registerForm.valid) {
        this.apiService.sendGraphValidData("valid");
      } else {
        this.apiService.sendGraphValidData("invalid");
      }
    }, 100);
    if (this.fileResponse["resp"]) {
      if (this.fileResponse["resp"]["CLO"]) {
        const evt = {
          activeId: "1",
          nextId: "2",
        };
        this.onSubmit(evt);
        this.activeIdString = "2";
      }
    }
    this.afterClearField = false;
  } //Numeric Generator Values Auto Detect - Ends Here

  confirmGraphChangeValues(data) {
    this.modalService.confirm('Values are changed from the numeric generator. Are you sure you want to proceed', "Confirm", true).pipe(take(1))
      .subscribe(confirm => {
        if (confirm) {
          this.updateGraphDataToTable(data);
        } else {
          this.dataToShow = [];
          for (let n = 0; n <= this.Steps - 1; n++) {
            this.hrs = Math.round(0 + (n * (this.Max - 0)) / (this.Steps - 1));
            this.powerLevel = Math.floor(
              this.Initial + (n * (this.Final - this.Initial)) / (this.Steps - 1)
            );
            this.dataToShow.push({ hour: this.hrs, power: this.powerLevel });
          }
          this.apiService.sendCloGraphData(
            this.graphRepresentationType,
            this.dataToShow
          );
          if (!this.isNumericCreatedRows) {
            this.rows.clear();
            this.dataToShow.forEach((content, index) => {
              this.onAddRow();
              this.checkAddesRowValuesChanged(index);
            });
          }
        }
        this.checkActualCloPoints();

      }
    );



  }

  updateGraphDataToTable(data) {
    this.checkValidator();
    this.isNumericCreatedRows = false;
    this.unsubscribe.next();
    this.dataToShow = [];
    data.forEach((content, index) => {
      this.dataToShow.push({
        hour: Math.round(content.hour),
        power: Math.floor(content.power),
      });
    });
    this.isGraphUpdated = true;
    if (this.showClo) {
      this.reInitializeValue();
    }
    //Drag Invalid Data Check - starts here
    this.dataToShow.forEach((content, index) => {
      if (index !== this.dataToShow.length - 1) {
        if (this.dataToShow[index].hour > this.dataToShow[index + 1].hour) {
          setTimeout(() => {
            this.rows.controls[index + 1]["controls"].workingHours.setErrors({
              incorrect: true,
            });
            this.apiService.sendGraphValidData("invalid");
            this.disableAddBtn = true;
          }, 0);
        }
      }
      this.checkActualCloPoints();
    });
    //Drag Invalid Data Check - ends here
    this.isNumericCreatedRows = false;
    if (this.showClo) {
      for (let key of Object.keys(this.f)) {
        this.f[key].setValue("");
      }
    }
    this.isTableValuesChanged = true;
    this.isGraphDragUpdate = true;
    this.apiService.getGraphRepresentation
      .pipe(takeUntil(this.unsubscribeGraphType))
      .subscribe((data) => {
        if (data != "") {
          this.graphRepresentationType = data;
          this.apiService.sendCloGraphData(
            this.graphRepresentationType,
            this.dataToShow
          );
        }
      });
    this.checkActualCloPoints();
  }

  tabChanged($event: NgbTabChangeEvent) {
    if ($event.nextId === "1") {
      this.isNumericCreatedRows = false;
    }
    if ($event.nextId === "2") {
      if (
        this.registerForm.valid ||
        (this.fileResponse["resp"] && this.fileResponse["resp"]["CLO"])
      ) {
        this.isValuesNumericGenerated = true;
      }
      this.onSubmit($event);
    }
    this.fileResponse = "";
    if (this.isNumericCreatedRows) {
      this.defaultColor = false;
    }
    this.apiService.setCloDefaultColor(this.defaultColor);

  }

  get f() {
    return this.registerForm.controls;
  }

  onSubmit($event) {
    this.submitted = false;
    this.checkIfValueEntered();
    if (this.isTableValuesChanged !== true) {
      if (this.fewFieldValuePresent === true && this.registerForm.invalid) {
        //one of the values entered
        $event.preventDefault();
        this.confirmResetFields();
      } else {
        //No Values given
        if (!this.dataToShow.length) {
          //If value entered in table values retain it on tab change
          this.rows.clear();
          this.onAddRow();
          this.dataToShow = [];
          this.dataToShow.push({
            hour: this.cloSchema["properties"]["CloPoint1"]["properties"][
              "Time"
            ]["const"],
            power:
              this.cloSchema["properties"]["CloPoint1"]["properties"][
              "Percentage"
              ]["default"],
          });
          this.rows.controls[0]["controls"].workingHours.setValue(
            this.cloSchema["properties"]["CloPoint1"]["properties"]["Time"][
            "const"
            ]
          );
          this.rows.controls[0]["controls"].outputLevel.setValue(
            this.cloSchema["properties"]["CloPoint1"]["properties"][
            "Percentage"
            ]["default"]
          );

          this.checkAddesRowValuesChanged(this.rows.length - 1);
          setTimeout(() => {
            this.isTableValuesChanged = false;
          }, 0);
          this.defaultColor = true;
          this.apiService.setCloDefaultColor(this.defaultColor);

          this.apiService.sendGraphIsEditedData([
            {
              hour: this.cloSchema["properties"]["CloPoint1"]["properties"][
                "Time"
              ]["const"],
              power:
                this.cloSchema["properties"]["CloPoint1"]["properties"][
                "Percentage"
                ]["default"],
            },
          ]);
          this.apiService.sendCloGraphData(
            this.graphRepresentationType,
            this.dataToShow
          );
        }
      }
    }

    if (
      this.registerForm.valid ||
      (this.fileResponse["resp"] &&
        this.fileResponse["resp"]["CLO"] &&
        this.fileResponse["resp"]["CLO"]["CloPoint1"])
    ) {
      this.isGraphUpdated = true;
      this.unsubscribe.next(); //clear rows on changing between tabs once numeric generated - starts here
      this.isNumericCreatedRows = false;
      this.rows.clear();
      this.dataToShow = [];
      this.Max = Number(this.f.cloHour.value);
      if (this.fileResponse["resp"] && this.fileResponse["resp"]["CLO"]) {
        this.Steps = Object.keys(this.fileResponse["resp"]["CLO"]).length - 1;
      } else {
        this.Steps = Number(this.f.cloSteps.value);
      }
      this.Initial = Number(this.f.initialOutput.value);
      this.Final = Number(this.f.finalOutput.value);
      this.dataToShow = [];
      if (this.Steps !== 1) {
        let prevHour = null;
        let prevpowerLevel = null;
        for (let n = 0; n <= this.Steps - 1; n++) {
          this.onAddRow();
          if (this.fileResponse["resp"]) {
            if (
              this.fileResponse["resp"]["CLO"] &&
              this.fileResponse["resp"]["CLO"]["CloPoint" + [n + 1]]
            ) {
              this.dataToShow.push({
                hour: this.fileResponse["resp"]["CLO"]["CloPoint" + [n + 1]][
                  "Time"
                ],
                power:
                  this.fileResponse["resp"]["CLO"]["CloPoint" + [n + 1]][
                  "Percentage"
                  ],
              });
            } else {
              this.hrs = Math.round(
                0 + (n * (this.Max - 0)) / (this.Steps - 1)
              );
              this.powerLevel = Math.floor(
                this.Initial +
                (n * (this.Final - this.Initial)) / (this.Steps - 1)
              );
              if (prevHour != null && this.hrs === prevHour) {
                this.powerLevel = prevpowerLevel;
              }
              prevHour = this.hrs;
              prevpowerLevel = this.powerLevel;
              this.dataToShow.push({ hour: this.hrs, power: this.powerLevel });
            }
          } else {
            this.hrs = Math.round(0 + (n * (this.Max - 0)) / (this.Steps - 1));
            this.powerLevel = Math.floor(
              this.Initial +
              (n * (this.Final - this.Initial)) / (this.Steps - 1)
            );
            if (prevHour != null && this.hrs === prevHour) {
              this.powerLevel = prevpowerLevel;
            }
            prevHour = this.hrs;
            prevpowerLevel = this.powerLevel;
            this.dataToShow.push({ hour: this.hrs, power: this.powerLevel });
          }
        }
        setTimeout(() => {
          for (let n = 0; n <= this.Steps - 1; n++) {
            this.checkAddesRowValuesChanged(n);
          }
        }, 1000);
      }
      if (this.Steps === 1) {
        for (let n = 0; n <= this.Steps - 1; n++) {
          this.onAddRow();
          if (this.fileResponse["resp"]) {
            if (this.fileResponse["resp"]["CLO"]) {
              this.dataToShow.push({
                hour: this.fileResponse["resp"]["CLO"]["CloPoint" + [n + 1]][
                  "Time"
                ],
                power:
                  this.fileResponse["resp"]["CLO"]["CloPoint" + [n + 1]][
                  "Percentage"
                  ],
              });
              this.defaultColor = true;
              this.apiService.setCloDefaultColor(this.defaultColor);

            } else {
              this.hrs = Math.round(0 + n * (this.Max - 0));
              this.powerLevel = Math.floor(
                this.Initial + n * (this.Final - this.Initial)
              );
              this.dataToShow.push({ hour: this.hrs, power: this.powerLevel });
            }
          } else {
            this.hrs = Math.round(0 + n * (this.Max - 0));
            this.powerLevel = Math.floor(
              this.Initial + n * (this.Final - this.Initial)
            );
            this.dataToShow.push({ hour: this.hrs, power: this.powerLevel });
          }
        }
        setTimeout(() => {
          for (let n = 0; n <= this.Steps - 1; n++) {
            this.checkAddesRowValuesChanged(n);
          }
        }, 1000);
        this.isNumericCreatedRows = true;
      }
      setTimeout(() => {
        if (this.rowLength === 16) {
          this.disableAddBtn = true;
        } else {
          this.disableAddBtn = false;
        }
      }, 0);

      this.apiService.sendGraphIsEditedData(this.dataToShow);
      this.reInitializeValue();
      this.verifyTableValueChange();
      this.checkActualCloPoints();
    }
    if (this.submitted === false) {
      this.apiService.sendGraphValidData("valid");
    }
  }

  inputValueChanged($event) {
    this.fewFieldValuePresent = false;
    if ($event.key !== "Tab") {
      this.submitted = true;
    }
    if ($event.key === "Backspace" || $event.key === "Delete") {
      this.submitted = false;
      this.checkIfValueEntered();
      if (this.fewFieldValuePresent === false) {
        this.apiService.sendGraphValidData("valid");
      }
    }
  }

  onAddRow(restoreDefaultStatus = "default") {
    if (restoreDefaultStatus === 'valid') {
      this.defaultColor = true;
    } else {
      this.defaultColor = false;
    }
    this.apiService.setCloDefaultColor(this.defaultColor);

    if (!this.isNumericCreatedRows) {
      this.rows.push(this.createItemFormGroup());
      this.rowLength = this.rows.length;
    }

    // if (this.isNumericCreatedRows && this.fileResponse['resp']) {//Release 1.0 chnages
    if (this.isNumericCreatedRows) {

      this.modalService.confirm('Values are changed from the numeric generator. Are you sure you want to proceed', "Confirm", true).pipe(take(1))
        .subscribe(confirm => {
          if (confirm) {
            this.unsubscribe.next();
            this.isNumericCreatedRows = false;
            this.rows.push(this.createItemFormGroup());
            this.rowLength = this.rows.length;
            for (let key of Object.keys(this.f)) {
              this.f[key].setValue("");
            }
            this.submitted = false;
            this.isTableValuesChanged = true;
          } else {
            return;
          }

        }
      );
    }

    if (restoreDefaultStatus === 'valid' || this.clearFields) {
      setTimeout(() => {
        if (this.rowLength === 16) {
          this.disableAddBtn = true;
        } else {
          this.disableAddBtn = false;
        }
        if (!this.addForm.valid) {
          this.disableAddBtn = true;
         this.apiService.sendGraphValidData("invalid");
        } else {
          this.disableAddBtn = false;
         this.apiService.sendGraphValidData("valid");
        }
        this.clearFields=false;
      }, 0);

    } else {
      //  setTimeout(() => {
      if (this.rowLength === 16) {
        this.disableAddBtn = true;
      } else {
        this.disableAddBtn = false;
      }
      if (!this.addForm.valid) {
        this.disableAddBtn = true;
        this.apiService.sendGraphValidData("invalid");
      } else {
        this.disableAddBtn = false;
        this.apiService.sendGraphValidData("valid");
      }
     // }, 0);
    } 


    this.checkActualCloPoints();

  }
  //Enter Table Invalid Data Check - starts here
  checkWorkingHrsValuesOrder(position) {
    this.workingHrsValuesArr = [];
    this.rows.controls.forEach((content, index) => {
      if (index !== this.dataToShow.length) {
        this.workingHrsValuesArr.push(+content.value.workingHours);
      }
    });
    this.isAscending = true;
    this.workingHrsValuesArr.forEach((content, index) => {
      if (index == 0 && content > 0) {
        this.rows.controls[index]["controls"].workingHours.setErrors({
          incorrect: true,
        });
      }
      if (
        this.workingHrsValuesArr[index] < 0 ||
        this.workingHrsValuesArr[index] > 120
      ) {
        this.rows.controls[index]["controls"].workingHours.setErrors({
          incorrect: true,
        });
      }
      if (
        this.rows.controls[index]["controls"].workingHours.value.toString() ===
        ""
      ) {
        this.rows.controls[index]["controls"].workingHours.setErrors({
          incorrect: true,
        });
      } else if (
        this.workingHrsValuesArr[index] ===
        this.workingHrsValuesArr[index - 1] &&
        this.rows.controls[index]["controls"].outputLevel.value !=
        this.rows.controls[index - 1]["controls"].outputLevel.value
      ) {
        this.rows.controls[index]["controls"].outputLevel.setErrors({
          incorrect: true,
        });
      } else {
        if (isNaN(this.rows.controls[index]["controls"].outputLevel.value) || this.rows.controls[index]["controls"].outputLevel.value === "" ||
          this.rows.controls[index]["controls"].outputLevel.value > this.cloSchema["properties"]["CloPoint16"]["properties"]["Percentage"]["maximum"]) {
          this.rows.controls[index]["controls"].outputLevel.setErrors({
            incorrect: true,
          });
        } else {
          this.rows.controls[index]["controls"].outputLevel.setErrors(null);

        } 
      }
      if (this.workingHrsValuesArr[index] === "") {
        this.rows.controls[index]["controls"].workingHours.setErrors({
          incorrect: true,
        });
      }
      let valueEnteredCheck = this.workingHrsValuesArr[index];

      if (Number.isNaN(Number(valueEnteredCheck))) {
        this.rows.controls[index]["controls"].workingHours.setErrors({
          incorrect: true,
        });
      }
      if (index !== this.workingHrsValuesArr.length - 1) {
        if (
          this.workingHrsValuesArr[index] > this.workingHrsValuesArr[index + 1]
        ) {
          this.isAscending = false;
          this.rows.controls[index + 1]["controls"].workingHours.setErrors({
            incorrect: true,
          });
        } else if (this.workingHrsValuesArr[index] === "") {
          this.rows.controls[index]["controls"].workingHours.setErrors({
            incorrect: true,
          });
        } else if (Number.isNaN(Number(valueEnteredCheck))) {
          this.rows.controls[index]["controls"].workingHours.setErrors({
            incorrect: true,
          });
        } else {
          this.rows.controls[index + 1]["controls"].workingHours.setErrors(
            null
          );
        }
      }
    });

    if (!this.isAscending) {
      setTimeout(() => {
        this.apiService.sendGraphValidData("invalid");
        this.disableAddBtn = true;
      }, 0);
    } else {
      //NO Error
      for (let r = 0; r < this.rows.length; r++) {
        // this.rows.controls[r]['controls'].workingHours.setErrors(null);
      }

      this.disableAddBtn = false;
      if (this.rowLength === 16) {
        this.disableAddBtn = true;
      }
    }
    // }, 0);
  }
  //Enter Table Invalid Data Check - ends here

  checkAddesRowValuesChanged(index) {
    //Not numeric generated, value changes/added row show graph - Starts Here
    this.rows.controls[index]["controls"].workingHours.valueChanges.subscribe(
      (value) => {
        this.isGraphDragUpdate = false;
        if (!this.isValuesNumericGenerated) {
          for (let key of Object.keys(this.f)) {
            this.f[key].setValue("");
          }
        } //clear numeric generator fields - starts here
        this.submitted = false;
        this.isTableValuesChanged = true; //clear numeric generator fields - ends here
        this.tableValueSubmit = true;
        setTimeout(() => {
          if (this.addForm.valid) {
            this.dataToShow = [];
            for (let r = 0; r < this.rows.length; r++) {
              this.dataToShow.push({
                hour: this.rows.controls[r]["controls"].workingHours.value,
                power: this.rows.controls[r]["controls"].outputLevel.value,
              });
            }
            this.apiService.sendGraphIsEditedData(this.dataToShow);
            this.apiService.sendCloGraphData(
              this.graphRepresentationType,
              this.dataToShow
            );
            if (this.rowLength === 16) {
              this.disableAddBtn = true;
            } else {
              this.disableAddBtn = false;
            }
            if (!this.isGraphDragUpdate) {
              //Enter Table Invalid Data Check - starts here
              this.checkWorkingHrsValuesOrder(index);
            }
          } else {
            if (!this.isGraphDragUpdate) {
              this.checkWorkingHrsValuesOrder(index);
              this.apiService.sendGraphValidData("invalid");
              this.disableAddBtn = true;
            }
          }
          if (!this.isGraphDragUpdate) {
            setTimeout(() => {
              if (this.addForm.valid) {
                this.apiService.sendGraphValidData("");
                this.dataToShow = [];
                for (let r = 0; r < this.rows.length; r++) {
                  this.dataToShow.push({
                    hour: this.rows.controls[r]["controls"].workingHours.value,
                    power: this.rows.controls[r]["controls"].outputLevel.value,
                  });
                }
                this.apiService.sendGraphIsEditedData(this.dataToShow);
                this.apiService.sendCloGraphData(
                  this.graphRepresentationType,
                  this.dataToShow
                );
              }
            }, 0);
          } //Enter Table Invalid Data Check - ends here
        }, 0);

        setTimeout(() => {
          if (this.addForm.valid) {
            this.disableAddAboveBtn = false;
            this.disableAddBelowBtn = false;
            this.apiService.sendGraphValidData("valid");
          } else {
            this.disableAddAboveBtn = true;
            this.disableAddBelowBtn = true;
            this.apiService.sendGraphValidData("invalid");
          }
        }, 100);
      }
    );
    this.rows.controls[index]["controls"].outputLevel.valueChanges.subscribe(
      (value) => {
        if (
          !isNullOrUndefined(this.rows.controls[index - 1]) &&
          this.rows.controls[index]["controls"].workingHours.value ===
          this.rows.controls[index - 1]["controls"].workingHours.value &&
          this.rows.controls[index]["controls"].outputLevel.value !=
          this.rows.controls[index - 1]["controls"].outputLevel.value
        ) {
          this.rows.controls[index]["controls"].outputLevel.setErrors({
            incorrect: true,
          });
        } else {
          if (isNaN(this.rows.controls[index]["controls"].outputLevel.value) || this.rows.controls[index]["controls"].outputLevel.value === "" ||
            this.rows.controls[index]["controls"].outputLevel.value > this.cloSchema["properties"]["CloPoint16"]["properties"]["Percentage"]["maximum"]) {
            this.rows.controls[index]["controls"].outputLevel.setErrors({
              incorrect: true,
            });
          } else {
            if (!isNullOrUndefined(this.rows.controls[index])) {
              this.rows.controls[index]["controls"].outputLevel.setErrors(null);
            }
          }
        }


        this.isGraphDragUpdate = false;
        if (!this.isValuesNumericGenerated) {
          for (let key of Object.keys(this.f)) {
            this.f[key].setValue("");
          }
        } //clear numeric generator fields - starts here
        this.submitted = false;
        this.isTableValuesChanged = true; //clear numeric generator fields - ends here
        this.tableValueSubmit = true;
        setTimeout(() => {
          if (this.addForm.valid) {
            this.dataToShow = [];
            for (let r = 0; r < this.rows.length; r++) {
              this.dataToShow.push({
                hour: this.rows.controls[r]["controls"].workingHours.value,
                power: this.rows.controls[r]["controls"].outputLevel.value,
              });
            }
            this.apiService.sendGraphIsEditedData(this.dataToShow);
            this.apiService.sendCloGraphData(
              this.graphRepresentationType,
              this.dataToShow
            );
            if (this.rowLength === 16) {
              this.disableAddBtn = true;
            } else {
              this.disableAddBtn = false;
            }
            if (!this.isGraphDragUpdate) {
              //Enter Table Invalid Data Check - starts here
              this.checkWorkingHrsValuesOrder(index);
            }
          } else {
            if (!this.isGraphDragUpdate) {
              this.checkWorkingHrsValuesOrder(index);
              this.apiService.sendGraphValidData("invalid");
              this.disableAddBtn = true;
            }
          }
          if (!this.isGraphDragUpdate) {
            setTimeout(() => {
              if (this.addForm.valid) {
                this.apiService.sendGraphValidData("");
              }
            }, 0);
          } //Enter Table Invalid Data Check - ends here
        }, 0);
        setTimeout(() => {
          if (this.addForm.valid) {
            this.disableAddAboveBtn = false;
            this.disableAddBelowBtn = false;
            this.apiService.sendGraphValidData("valid");
          } else {
            this.disableAddAboveBtn = true;
            this.disableAddBelowBtn = true;
            this.apiService.sendGraphValidData("invalid");
          }
        }, 100);
      }
    ); //Not numeric generated, value changes/added row show graph - Ends Here
    this.checkActualCloPoints();
  }
  onRemoveRow(rowIndex: number) {
    this.defaultColor = false;
    this.apiService.setCloDefaultColor(this.defaultColor);

    if (!this.isNumericCreatedRows) {
      this.rows.removeAt(rowIndex);
      this.rowLength = this.rows.length;
    }
    if (this.isNumericCreatedRows) {

      this.modalService.confirm('Values are changed from the numeric generator. Are you sure you want to proceed', "Confirm", true).pipe(take(1))
        .subscribe(confirm => {
          if (confirm) {
            this.unsubscribe.next();
            this.isNumericCreatedRows = false;
            this.rows.removeAt(rowIndex);
            this.rowLength = this.rows.length;
            for (let key of Object.keys(this.f)) {
              this.f[key].setValue("");
            }
            this.submitted = false;
            this.isTableValuesChanged = true;
          } else {
            return;
          }
        }
      );

    }

    setTimeout(() => {
      if (this.addForm.valid) {
        this.apiService.sendGraphValidData("valid");
        this.dataToShow = []; // remove row update grapg - starts here
        for (let r = 0; r < this.rows.length; r++) {
          this.dataToShow.push({
            hour: this.rows.controls[r]["controls"].workingHours.value,
            power: this.rows.controls[r]["controls"].outputLevel.value,
          });
        }
        this.apiService.sendCloGraphData(
          this.graphRepresentationType,
          this.dataToShow
        ); // remove row update grapg - ends here
      } else {
        this.apiService.sendGraphValidData("invalid");
      }
    }, 0);
    this.checkActualCloPoints();
  }

  createItemFormGroup(): FormGroup {
    return this.fb.group({
      workingHours: [
        null,
        [
          Validators.required,
          Validators.pattern("^[0-9]*$"),
          Validators.min(0),
          Validators.max(120),
        ],
      ],
      outputLevel: [
        null,
        [
          Validators.required,
          Validators.pattern("^[0-9]*$"),
          Validators.min(0),
          Validators.max(100),
        ],
      ],
    });
  }

  checkIfValueEntered() {
    if (this.registerForm.valid && !this.fileResponse["resp"]) {
      this.apiService.sendGraphValidData("valid");
    } else if (!this.registerForm.valid) {
      this.apiService.sendGraphValidData("invalid");
    }
    for (let key of Object.keys(this.f)) {
      //check any one value entered
      if (this.f[key].value === "") {
        this.fewFieldValuePresent = false;
      } else {
        this.fewFieldValuePresent = true;
        this.submitted = true;
        break;
      }
    }
    this.checkActualCloPoints();
  }

  reInitializeValue() {
    if (!this.isNumericCreatedRows) {
      this.rows.clear();
      this.dataToShow.forEach((content, index) => {
        this.onAddRow();
        this.checkAddesRowValuesChanged(index);
      });
    }
    if (this.isGraphUpdated) {
      this.dataToShow.forEach((content, index) => {
        this.rows.controls[index]["controls"].workingHours.setValue(
          content.hour
        );
        this.rows.controls[index]["controls"].outputLevel.setValue(
          content.power
        );
      });
    }

    if (!this.isGraphUpdated) {
      this.dataToShow.forEach((content, index) => {
        if (index !== this.dataToShow.length - 1) {
          this.rows.controls[index]["controls"].workingHours.setValue(
            content.hour
          );
          this.rows.controls[index]["controls"].outputLevel.setValue(
            content.power
          );
        }
      });
    }
    this.checkActualCloPoints();
  }

  verifyTableValueChange() {
    this.clonedDataToShow = Object.assign([], this.dataToShow);
    if (this.isNumericCreatedRows && this.Steps < this.dataToShow.length) {
      this.dataToShow.pop();
    }
    this.dataToShow.forEach((content, index) => {
      this.rows.controls[index]["controls"].workingHours.valueChanges
        .pipe(startWith(content.hour), pairwise())
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(([prev, next]: [any, any]) => {
          if (prev !== next) {
            this.modalService.confirm('Values are changed from the numeric generator. Are you sure you want to proceed', "Confirm", true).pipe(take(1))
              .subscribe(confirm => {
                if (confirm) {
                  this.unsubscribe.next();
                  for (let key of Object.keys(this.f)) {
                    this.f[key].setValue("");
                  }
                  this.submitted = false;
                  this.isTableValuesChanged = true;
                  this.isNumericCreatedRows = false;
                } else {
                  this.unsubscribe.next();
                  this.isTableValuesChanged = false;
                  const tempData = this.clonedDataToShow;
                  this.rows.clear();
                  setTimeout(() => {
                    for (let n = 0; n <= this.Steps - 1; n++) {
                      this.rows.push(this.createItemFormGroup());
                    }
                    this.rowLength = this.rows.length;
                    this.dataToShow = [];
                    this.dataToShow = tempData;
                    this.reInitializeValue();
                    for (let n = 0; n <= this.Steps - 1; n++) {
                      this.checkAddesRowValuesChanged(n);
                    }
                    this.verifyTableValueChange();
                  }, 0);
                  // this.rows.controls[index]['controls'].workingHours.setValue(content.hour,{ onlySelf: true });
                }
              }
              );
          }
        });
      this.rows.controls[index]["controls"].outputLevel.valueChanges
        .pipe(startWith(content.power), pairwise())
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(([prev, next]: [any, any]) => {
          if (prev !== next) {
            this.modalService.confirm('Values are changed from the numeric generator. Are you sure you want to proceed', "Confirm", true).pipe(take(1))
              .subscribe(confirm => {
                if (confirm) {
                  this.unsubscribe.next();
                  for (let key of Object.keys(this.f)) {
                    this.f[key].setValue("");
                  }
                  this.submitted = false;
                  this.isTableValuesChanged = true;
                  this.isNumericCreatedRows = false;
                } else {
                  this.unsubscribe.next();
                  this.isTableValuesChanged = false;
                  const tempData = this.clonedDataToShow;
                  this.rows.clear();
                  setTimeout(() => {
                    for (let n = 0; n <= this.Steps - 1; n++) {
                      this.rows.push(this.createItemFormGroup());
                    }
                    this.rowLength = this.rows.length;
                    this.dataToShow = [];
                    this.dataToShow = tempData;
                    this.reInitializeValue();
                    for (let n = 0; n <= this.Steps - 1; n++) {
                      this.checkAddesRowValuesChanged(n);
                    }
                    this.verifyTableValueChange();
                  }, 0);
                }
              }
              );
          }
        });
      // }
    });
    this.checkActualCloPoints();
  }

  confirmResetFields() {


    this.modalService.confirm('Action not complete or invalid entry. Values will be reset. Do you want to continue?', "Confirm", true).pipe(take(1))
      .subscribe(confirm => {
        if (confirm) {
          setTimeout(() => {
            for (let key of Object.keys(this.f)) {
              this.f[key].setValue("");
            }
            this.submitted = false;
            this.setDefaultGraph();
          }, 0);

        } else {
          return;
        }
        this.checkActualCloPoints();

      }
      );
  }


  clearTableValueFields() {
    this.fileResponse = "";
    if (this.isNumericCreatedRows !== false || this.dataToShow.length > 1) {
      this.modalService.confirm('Values are changed from the numeric generator. Are you sure you want to proceed', "Confirm", true).pipe(take(1))
        .subscribe(confirm => {
          if (confirm) {
            this.clearFields = true;
            this.clearValues();
          } else {
            return;
          }
          this.defaultColor = false;
          this.apiService.setCloDefaultColor(this.defaultColor);

          this.disableAddAboveBtn = false;
          this.disableAddBelowBtn = false;
          this.checkActualCloPoints();
        }
        );
    }

  }

  clearValues() {
    this.unsubscribe.next();
    this.isNumericCreatedRows = false;
    this.rows.clear();
    this.onAddRow();
    this.dataToShow = [];
    this.dataToShow.push({
      hour: this.cloSchema["properties"]["CloPoint1"]["properties"]["Time"][
        "const"
      ],
      power:
        this.cloSchema["properties"]["CloPoint1"]["properties"]["Percentage"][
        "default"
        ],
    });
    this.rows.controls[0]["controls"].workingHours.setValue(
      this.cloSchema["properties"]["CloPoint1"]["properties"]["Time"]["const"]
    );
    this.rows.controls[0]["controls"].outputLevel.setValue(
      this.cloSchema["properties"]["CloPoint1"]["properties"]["Percentage"][
      "default"
      ]
    );
    this.defaultColor = true;
    this.apiService.setCloDefaultColor(this.defaultColor);

    this.apiService.sendGraphIsEditedData([
      {
        hour: this.cloSchema["properties"]["CloPoint1"]["properties"]["Time"][
          "const"
        ],
        power:
          this.cloSchema["properties"]["CloPoint1"]["properties"]["Percentage"][
          "default"
          ],
      },
    ]);
    // this.apiService.sendGraphIsEditedData([{ hour: 0, power: 100 }]);
    this.apiService.sendCloGraphData(
      this.graphRepresentationType,
      this.dataToShow
    );
    for (let key of Object.keys(this.f)) {
      this.f[key].setValue("");
    }
    this.tableValueSubmit = false;
    this.isTableValuesChanged = false;
    this.checkAddesRowValuesChanged(this.rows.length - 1);
    this.checkActualCloPoints();
  }

  enableGraph(value) {
    this.disableGraph = !value;
    this.defaultColor = !value;
    if (this.disableGraph) {
      //this.defaultColor = true;
      this.enableChecked = false;
    } else {
      //this.defaultColor = false;
      this.enableChecked = true;
    }

    if (this.enableChecked) {
      this.defaultColor = false;
    }
    this.apiService.setCloDefaultColor(this.defaultColor);

    this.apiService.setEnableGraph(value);
  }

  setDefaultGraph(restoreDefaultStatus = "default") {
    if (!this.disableGraph) {
      this.fileResponse = "";
      this.enableChecked = false;
      this.disableGraph = true;
      this.apiService.setEnableGraph(false);
      this.activeIdString = "2";
      this.clearNumericFields(restoreDefaultStatus);
      // this.defaultColor=true;
      this.unsubscribe.next();
      this.isNumericCreatedRows = false;
      this.rows.clear();
      this.onAddRow(restoreDefaultStatus);
      this.dataToShow = [];
      this.dataToShow.push({
        hour: this.cloSchema["properties"]["CloPoint1"]["properties"]["Time"][
          "const"
        ],
        power:
          this.cloSchema["properties"]["CloPoint1"]["properties"]["Percentage"][
          "default"
          ],
      });
      this.rows.controls[0]["controls"].workingHours.setValue(
        this.cloSchema["properties"]["CloPoint1"]["properties"]["Time"]["const"]
      );
      this.rows.controls[0]["controls"].outputLevel.setValue(
        this.cloSchema["properties"]["CloPoint1"]["properties"]["Percentage"][
        "default"
        ]
      );
      this.rows.controls[0]["controls"].workingHours.valueChanges.subscribe(
        (value) => {
          // this.defaultColor=false;
        }
      );
      this.rows.controls[0]["controls"].outputLevel.valueChanges.subscribe(
        (value) => {
          // this.defaultColor=false;
        }
      );
      this.apiService.sendGraphIsEditedData([
        {
          hour: this.cloSchema["properties"]["CloPoint1"]["properties"]["Time"][
            "const"
          ],
          power:
            this.cloSchema["properties"]["CloPoint1"]["properties"][
            "Percentage"
            ]["default"],
        },
      ]);
      this.apiService.sendCloGraphData(
        this.graphRepresentationType,
        this.dataToShow
      );
      for (let key of Object.keys(this.f)) {
        this.f[key].setValue("");
      }

      this.tableValueSubmit = false;
      this.isTableValuesChanged = false;
      this.checkAddesRowValuesChanged(this.rows.length - 1);
    } else {
      this.enableChecked = true;
    }
    this.defaultColor = true;
    this.apiService.setCloDefaultColor(this.defaultColor);

    this.checkActualCloPoints();
  }

  AddRow() {
    this.tableValueSubmit = true;
    this.onAddRow();
    this.checkAddesRowValuesChanged(this.rows.length - 1);
    this.checkActualCloPoints();
  }

  RemoveRow(index) {
    this.onRemoveRow(index);
    setTimeout(() => {
      if (!this.addForm.valid) {
        this.disableAddBtn = true;
        this.disableAddAboveBtn = true;
        this.disableAddBelowBtn = true;
      } else {
        this.disableAddBtn = false;
        this.disableAddAboveBtn = false;
        this.disableAddBelowBtn = false;
      }
      if (this.rowLength < 16) {
        this.disableAddBtn = false;
      }
    }, 0);
    this.checkWorkingHrsValuesOrder(index);
    this.checkActualCloPoints();
  }
  onRightClick($event, index) {
    this.selectedRow = index;
    $event.stopPropagation();
    this.menuState = true;
    this.positionMenu(event);

    if (this.rowLength === 16 || this.rowLength > 16) {
      this.disableAddBtn = true;
      this.disableAddAboveBtn = true;
      this.disableAddBelowBtn = true;
    }
  }

  getPosition(e) {
    let posx = 0;
    let posy = 0;
    if (e.pageX || e.pageY) {
      posx = e.pageX;
      posy = e.pageY;
    } else if (e.clientX || e.clientY) {
      posx =
        e.clientX +
        document.body.scrollLeft +
        document.documentElement.scrollLeft;
      posy =
        e.clientY +
        document.body.scrollTop +
        document.documentElement.scrollTop;
    }
    return { x: posx, y: posy };
  }

  positionMenu(e) {
    this.menuPosition = this.getPosition(e);
    this.menuPositionX = this.menuPosition.x;
    this.menuPositionY = this.menuPosition.y;
    this.cdRef.detectChanges();
    this.menuWidth = this.contextMenu.nativeElement.offsetWidth;
    this.menuHeight = this.contextMenu.nativeElement.offsetHeight;
    this.windowWidth = window.innerWidth;
    this.windowHeight = window.innerHeight;
    if (Math.abs(this.windowWidth - this.menuPositionX) < this.menuWidth) {
      this.menuPositionX = this.windowWidth - this.menuWidth - 250 + "px";
    } else {
      //this.menuPositionX = this.menuPositionX - 250+ "px";
      this.menuPositionX = 190 + "px";
    }
    if (Math.abs(this.windowHeight - this.menuPositionY) < this.menuHeight) {
      this.menuPositionY = this.windowHeight - this.menuHeight - 50 + "px";
    } else {
      this.menuPositionY = this.menuPositionY - 50 + "px";
    }
  }
  @HostListener("document:click", ["$event"])
  public documentClick(event: Event): void {
    this.menuState = false;
  }
  @HostListener("document:contextmenu", ["$event"])
  public documentRClick(event: Event): void {
    this.menuState = false;
  }

  addRowAbove() {
    if (this.rowLength <= 16) {
      this.defaultColor = false;
      this.apiService.setCloDefaultColor(this.defaultColor);

      if (!this.isNumericCreatedRows) {
        this.rows.insert(this.selectedRow, this.createItemFormGroup());
        this.rowLength = this.rows.length;
      }

      if (this.isNumericCreatedRows) {

        this.modalService.confirm('Values are changed from the numeric generator. Are you sure you want to proceed', "Confirm", true).pipe(take(1))
          .subscribe(confirm => {
            if (confirm) {
              this.unsubscribe.next();
              this.isNumericCreatedRows = false;
              this.rows.insert(this.selectedRow, this.createItemFormGroup());
              this.rowLength = this.rows.length;
              for (let key of Object.keys(this.f)) {
                this.f[key].setValue("");
              }
              this.submitted = false;
              this.isTableValuesChanged = true;
            } else {
              return;
            }
            setTimeout(() => {
              if (this.rowLength >= 16) {
                this.disableAddBtn = true;
                this.disableAddAboveBtn = true;
                this.disableAddBelowBtn = true;
              } else {
                this.disableAddBtn = false;
                this.disableAddAboveBtn = false;
                this.disableAddBelowBtn = false;
              }
              if (!this.addForm.valid) {
                this.disableAddBtn = true;
                this.disableAddAboveBtn = true;
                this.disableAddBelowBtn = true;
                this.apiService.sendGraphValidData("invalid");
              } else {
                this.disableAddBtn = false;
                this.disableAddAboveBtn = false;
                this.disableAddBelowBtn = false;
                this.apiService.sendGraphValidData("valid");
              }
            }, 0);

            this.checkAddesRowValuesChanged(this.selectedRow);
            this.checkActualCloPoints();
          }
          );
      }


    }
  }

  addRowBelow() {
    if (this.rowLength <= 16) {
      this.defaultColor = false;
      this.apiService.setCloDefaultColor(this.defaultColor);

      if (!this.isNumericCreatedRows) {
        this.rows.insert(this.selectedRow + 1, this.createItemFormGroup());
        this.rowLength = this.rows.length;
      }

      if (this.isNumericCreatedRows) {

        this.modalService.confirm('Values are changed from the numeric generator. Are you sure you want to proceed', "Confirm", true).pipe(take(1))
          .subscribe(confirm => {
            if (confirm) {
              this.unsubscribe.next();
              this.isNumericCreatedRows = false;
              this.rows.insert(this.selectedRow + 1, this.createItemFormGroup());
              this.rowLength = this.rows.length;
              for (let key of Object.keys(this.f)) {
                this.f[key].setValue("");
              }
              this.submitted = false;
              this.isTableValuesChanged = true;
            } else {
              return;
            }
            setTimeout(() => {
              if (this.rowLength >= 16) {
                this.disableAddBtn = true;
                this.disableAddAboveBtn = true;
                this.disableAddBelowBtn = true;
              } else {
                this.disableAddBtn = false;
                this.disableAddAboveBtn = false;
                this.disableAddBelowBtn = false;
              }
              if (!this.addForm.valid) {
                this.disableAddBtn = true;
                this.disableAddAboveBtn = true;
                this.disableAddBelowBtn = true;
                this.apiService.sendGraphValidData("invalid");
              } else {
                this.disableAddBtn = false;
                this.disableAddAboveBtn = false;
                this.disableAddBelowBtn = false;
                this.apiService.sendGraphValidData("valid");
              }
            }, 0);

            this.checkAddesRowValuesChanged(this.selectedRow + 1);
            this.checkActualCloPoints();
          }
          );
      }


    }
  }
  clearNumericFields(restoreDefaultStatus = "default", isClearFields=false) {
    this.clearFields=isClearFields;
    this.disableNumericClear = true;
    for (let key of Object.keys(this.f)) {
      this.f[key].setValue("");
    }
    this.unsubscribe.next();
    this.isNumericCreatedRows = false;
    this.rows.clear();
    this.onAddRow(restoreDefaultStatus);
    this.dataToShow = [];
    this.dataToShow.push({
      hour: this.cloSchema["properties"]["CloPoint1"]["properties"]["Time"][
        "const"
      ],
      power:
        this.cloSchema["properties"]["CloPoint1"]["properties"]["Percentage"][
        "default"
        ],
    });
    this.rows.controls[0]["controls"].workingHours.setValue(
      this.cloSchema["properties"]["CloPoint1"]["properties"]["Time"]["const"]
    );
    this.rows.controls[0]["controls"].outputLevel.setValue(
      this.cloSchema["properties"]["CloPoint1"]["properties"]["Percentage"][
      "default"
      ]
    );
    this.rows.controls[0]["controls"].workingHours.valueChanges.subscribe(
      (value) => {
        this.defaultColor = false;
        this.apiService.setCloDefaultColor(this.defaultColor);

      }
    );
    this.rows.controls[0]["controls"].outputLevel.valueChanges.subscribe(
      (value) => {
        this.defaultColor = false;
        this.apiService.setCloDefaultColor(this.defaultColor);

      }
    );
    this.apiService.sendGraphIsEditedData([
      {
        hour: this.cloSchema["properties"]["CloPoint1"]["properties"]["Time"][
          "const"
        ],
        power:
          this.cloSchema["properties"]["CloPoint1"]["properties"]["Percentage"][
          "default"
          ],
      },
    ]);
    this.apiService.sendCloGraphData(
      this.graphRepresentationType,
      this.dataToShow
    );
    this.tableValueSubmit = false;
    this.isTableValuesChanged = false;
    this.checkAddesRowValuesChanged(this.rows.length - 1);
    this.afterClearField = true;
    this.checkActualCloPoints();
  }
  ngOnDestroy() {
    this.unsubscribeGraphType.next();
    this.unsubscribeNumeric.next();
    this.apiService.sendCloGraphData("", []);
    this.apiService.setCloLoadConfigureExtraValidation("");    
    this.getCloLoadFeatureFileConfigureSub.unsubscribe();
  }
}
