import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'converToNumber'
})
export class ConverToNumberPipe implements PipeTransform {

  transform(value: string):any {
    let retNumber = parseFloat(value);
    return retNumber;
}

}
