import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  ChangeDetectorRef,
  ViewRef,
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { FieldType } from "@ngx-formly/core";
import { ApiService } from "src/app/core-module/services/api.service";
import { isNullOrUndefined } from "util";

@Component({
  selector: "app-flextune",
  templateUrl: "./flextune.component.html",
  styleUrls: ["./flextune.component.scss"],
})
export class FlextuneComponent extends FieldType implements OnInit {
  @Input() field;

  properties: any;
  quickConfigvalue: boolean;
  quickConfigCustomCSS;
  allDropDownItems = {};
  propertiesInfo = {};

  isOutdoor: boolean = false;
  applicableProperties = [];

  showDefaultsOnInit = ['LightOutputOverCct', 'CoolAOC', 'WarmAOC', 'PowerOnCct', 'SystemFailureCct', 'CctCoolest', 'CctWarmest', 'CctOffset']
  flextuneFormGroup: FormGroup = new FormGroup({
    flextuneEnabled: new FormControl(false),
    CoolCct: new FormControl(null),
    CoolRatedLm: new FormControl(null),
    CoolRatedCurrent: new FormControl(null),
    WarmCct: new FormControl(null),
    WarmRatedLm: new FormControl(null),
    WarmRatedCurrent: new FormControl(null),
    LightOutputOverCct: new FormControl(null),
    coolLightRatedLumen: new FormControl(null),
    CoolAOC: new FormControl(null),
    warmLightRatedLumen: new FormControl(null),
    WarmAOC: new FormControl(null),
    PowerOnCct: new FormControl(null),
    SystemFailureCct: new FormControl(null),
    CctCoolest: new FormControl(null),
    CctWarmest: new FormControl(null),
    CctOffset: new FormControl(null),
  });

  constructor(private service: ApiService, private ref: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {
    this.service.getquickConfig.subscribe((data) => {
      if (data === "quickConfig") {
        this.quickConfigvalue = true;
        this.quickConfigCustomCSS = "quick-custom-inputbox textBoxUnit";
      } else if (data === "standard") {
        this.quickConfigvalue = false;
        this.quickConfigCustomCSS = "custom-inputbox";
      }
    });
    

    if (this.field) {
      this.properties = this.field.properties;
      this.applicableProperties= this.field.requiredProperties;
      if(this.applicableProperties.length == 13){
        this.isOutdoor = true;
      }

      this.applicableProperties.push('WarmAOC');
      this.allDropDownItems["LightOutputOverCct"] = [
        ...this.properties.LightOutputOverCct.enum,
      ];
      this.applicableProperties.forEach(prop => {
        this.preparePropertyInfo(prop);
        this.addValidatorForProp(prop);
        this.syncFormControlWithPropInfoValue(prop);
      })
      this.buildForm();
      
      this.flextuneFormGroup.valueChanges.subscribe((value) => {
        console.log('formValueChanges');
        this.setValueInLocal();
      });
    }
    
  }

  preparePropertyInfo(propName, initialValue = null) {
    const propData = this.properties[propName];
    if(!propData) return;
    const defaultValue = propData.default;
    const value = initialValue ? initialValue : defaultValue;
    const min = 1;
    const max = value + 300;
    
    this.propertiesInfo[propName] = {
      default: defaultValue,
      min,
      max,
      value,
      options:{
        floor: min,
        ceil: max
      }
    }
  }

  addValidatorForProp(propName){
    const control = this.flextuneFormGroup.controls[propName];
    const max = this.propertiesInfo[propName].max
    const min = this.propertiesInfo[propName].min
    control.setValidators([Validators.max(max), Validators.min(min)]);
  }

  syncFormControlWithPropInfoValue(propName) {
    const control = this.flextuneFormGroup.controls[propName];
    control.valueChanges.subscribe(value => {
      if(control.valid) this.propertiesInfo[propName].value = value;
      if(propName == 'LightOutputOverCct'){
        const warmAOCControl = this.flextuneFormGroup.get('WarmAOC');
        if(value == 'Flexible'){
          warmAOCControl.enable();
        }else{
          warmAOCControl.disable();
        }
      }
    })
  }

  onSliderValueChange(value, property) {
    let formvalue = {};
    formvalue[property] = value;
    this.flextuneFormGroup.patchValue({
      ...formvalue,
    });
  }

  

  setValueInLocal() {
    let value = {};
    for (let key in this.flextuneFormGroup.controls) {
      value[key] = this.flextuneFormGroup.get(key).value;
    }
    let data = JSON.parse(localStorage.getItem("configurationData"));
    if (data && !isNullOrUndefined(data["resp"])) {
      data["resp"]["flextuneObj"] = value;
    } else {
      data["resp"] = { flextuneObj: value };
    }
    localStorage.setItem("configurationData", JSON.stringify(data));
  }

  getValueFromLocal() {
    let data = JSON.parse(localStorage.getItem("configurationData"));
    if (data && !isNullOrUndefined(data["resp"])) {
      return data["resp"]["flextuneObj"];
    }
    return null;
  }

  isFeatureEnabled(value) {
    if (!value) this.disableFlextuneForm();
    else this.enableFlexTuneForm();
  }

  enableFlexTuneForm(){
    const value = this.flextuneFormGroup.value;
    for (let key in this.flextuneFormGroup.controls) {
      const control = this.flextuneFormGroup.get(key);
      if (key != "flextuneEnabled" && key != 'coolLightRatedLumen' && key != 'warmLightRatedLumen' && key != 'WarmAOC') control.enable();
      if(key == 'WarmAOC' && value['LightOutputOverCct'] == 'Flexible'){
        control.enable();
      }
    }
  }

  disableFlextuneForm() {
    for (let key in this.flextuneFormGroup.controls) {
      if (key != "flextuneEnabled") this.flextuneFormGroup.get(key).disable();
    }
  }

  buildForm() {
    let data = this.getValueFromLocal();
    if(!data){
      data = {};
      data['flextuneEnabled'] = false;
      this.applicableProperties.forEach(prop => {
        if(this.showDefaultsOnInit.includes(prop)){
          data[prop] = this.propertiesInfo[prop].default;
        }
      })
    }
    this.isFeatureEnabled(data['flextuneEnabled']);
    this.flextuneFormGroup.patchValue({
      ...data
    }, {emitEvent: false});
    const warmAOCControl = this.flextuneFormGroup.get('WarmAOC');
    if(data['LightOutputOverCct'] == 'Flexible'){
      warmAOCControl.enable();
    }else{
      warmAOCControl.disable();
    }
  }

  isControlEnabled(controlName){
    return this.flextuneFormGroup.get(controlName).enabled;
  }
}
