import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/core-module/services/authentication';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit {
  spinnerLoad;
  filterChekcedValue;

  constructor(private authenticationService: AuthenticationService,private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {            
      if(params['response']){
        this.authenticationService.LoginusingRToken(params['response']);
      }
  });
    this.authenticationService.isTokenExpired();
    this.authenticationService.startTokenExpiredCheck();
  }
  filterChekced(e){
    this.filterChekcedValue=e;
  }

  spinner(e) {
    this.spinnerLoad = e;
  }
}
