//NOSONAR
import {
  Component,
  OnInit,
  DoCheck,
  Input,
  OnChanges,
  SimpleChanges,
  ChangeDetectorRef,
} from "@angular/core";
import { AuthenticationService } from "src/app/core-module/services/authentication";
import { Router } from "@angular/router";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { ApiService } from "src/app/core-module/services/api.service";
import { takeUntil } from "rxjs/operators";
import { Subject, forkJoin } from "rxjs";
import { isNullOrUndefined } from "util";
import { ReadDeviceSelectionPopupComponent } from "../read-device-selection-popup/read-device-selection-popup.component";
@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit, DoCheck {
  redirectUrl = window.location.origin;
  userData = "";
  profileOption = false;
  valueChange = {};
  newval: boolean;
  omitValue: string;
  sortCheck: string;
  visibleCheck: string;
  checkbox1 = "";
  isOmitChecked;
  isGuidenceChecked;
  isSortedChecked;
  currentURL;
  showConfig = false;
  logOutUrl =
    "https://signify-portal-oem-tools-test.trimm.net?mgnlLogout=true&logoutRedirect=" +
    window.location.href;
  dashboardUrl = "https://signify-portal-oem-tools-acc.trimm.net/dashboard";
  SSOUrl;
  options = [
    { title: "Device Info popup ", value: "omit", name: "omitPopup" },
    { title: "Guidance", value: "guide", name: "guidancePopup" },
    {
      title: "Remember last sorted column",
      value: "sort",
      name: "sortedPopup",
    },
  ];

  isVisibleChecked;
  isFilterChecked;
  visibleCheckedValue;
  filterCheckedValue;

  columnList = [
    "12nc",
    "deviceName",
    "datasheet",
    "applicationArea",
    "pOut",
    "iOutMin",
    "iOutMax",
    "vOutMax",
    "vOutMin",
    "configInterface",
    "dimming",
    "efficiency",
    "operating",
    "isolation",
    "moutning",
    "length",
    "width",
    "height",
  ];

  dummyStorage = {};
  featurePage;

  preferencesAPI = "config/api/v1/preferences";
  unsubscribe = new Subject<any>();
  lastSortCheckbox;
  isSortVisble;

  configModeCheckedValue;
  storeConfigMode;
  isconfigurationModeChecked;
  isFeatureSaveFileChecked;
  isDCFSaveFileChecked;
  modes = ["Advanced", "Standard"];
  firstMode: any;
  showMode: boolean;
  isRemoved: boolean = true;
  isModeChecked: any;
  readerBaseUrl: any[];
  readerListUrl = "/peripheral/v1/types";
  readingStatus;
  readerData;
  listReader = [];
  readerType = "SimpleSet";
  readerConnected: boolean = false;
  readerInfo = {
    readerType: "SimpleSet",
    message: "Not connected",
    readerConnected: false,
  };
  modalRefUnSubscribe: NgbModalRef;
  isLoggedin: boolean = false;
  pathVal: string;
  constructor(
    private apiService: ApiService,
    private authenticationService: AuthenticationService,
    public router: Router,
    private modalService: NgbModal,
    private cdRef: ChangeDetectorRef
  ) {}

  subscription;
  ngOnInit() {
    this.pathVal = this.apiService.getImageUrl(window.location.hostname);
    let envURL = this.apiService.getEnvirnomentUrl(window.location.hostname);
    if (window.location.hostname == "localhost") {
      this.SSOUrl = envURL + this.redirectUrl + "/home";
    } else {
      this.SSOUrl = envURL + this.redirectUrl + "/deviceList";
    }
    this.authenticationService.loggedInUserName.subscribe((name) => {
      if (name === "logout") {
        this.userData = "";
        this.profileOption = false;
      }
      if (localStorage.getItem("loggedIn") != "false") {
        if (localStorage.getItem("Name") !== null && name !== "logout") {
          if (name !== "" && localStorage.getItem("Name") !== null) {
            this.profileOption = true;
            this.userData = name;
          }
          if (name === "" && localStorage.getItem("Name") !== null) {
            this.profileOption = true;
            this.userData = localStorage.getItem("Name");
          }
        }
      } else {
        this.profileOption = false;
      }
    });
    // var mode = JSON.parse(localStorage.getItem('modes'));
    // if(mode) {
    //   this.apiService.sendModes(mode);
    // }
    this.apiService.getEnvValue();
    this.apiService.getEnvData
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((url) => {
        if (url.length) {
          this.unsubscribe.next();
          this.subscription = this.apiService
            .apiCall("get", url + this.preferencesAPI)
            .subscribe((response) => {
              let configModeObj = {};
              let saveOmitOption = {};
              let sortObj = {};
              let filterObj = {};
              let storeColumn = {};
              let storeSaveFeatureFile = {};
              let storeDCFfile = {};
              if (response !== null) {
                saveOmitOption["storeDevicePopupValue"] =
                  response["storeOmitOption"]["storeDevicePopupValue"];
                configModeObj["storeConfigModeCheck"] =
                  response["storeConfigMode"]["storeConfigModeCheck"];
                sortObj["storeLastSortedColumn"] =
                  response["lastSorting"]["storeLastSortedColumn"];
                filterObj["storeFilterData"] =
                  response["storeFilter"]["storeFilterData"];
                storeColumn["storeColumnsData"] =
                  response["storeColumn"]["storeColumnsData"];
                if (
                  response &&
                  response["storeConfigMode"] &&
                  response["storeConfigMode"]["storeConfigModeCheck"]
                ) {
                  configModeObj["storeConfigModeData"] =
                    response["storeConfigMode"]["storeConfigModeData"];
                  // configModeObj['storeConfigModeCheck'] = response['storeConfigMode']['storeConfigModeCheck'];
                  this.isModeChecked =
                    response["storeConfigMode"]["storeConfigModeCheck"];
                  this.firstMode =
                    response["storeConfigMode"]["storeConfigModeData"];
                  localStorage.setItem(
                    "storeConfigMode",
                    JSON.stringify(configModeObj)
                  );
                } else {
                  var modes = JSON.parse(localStorage.getItem("modes"));
                  if (modes) {
                    this.firstMode = modes;
                  } else {
                    this.firstMode = "Advanced";
                  }
                  configModeObj["storeConfigModeData"] = this.firstMode;
                  localStorage.setItem(
                    "storeConfigMode",
                    JSON.stringify(configModeObj)
                  );
                }
                if (response && response["storeOmitOption"]) {
                  if (response["storeOmitOption"]["storeDevicePopupCheck"]) {
                    saveOmitOption["storeDevicePopupCheck"] =
                      response["storeOmitOption"]["storeDevicePopupCheck"];
                  } else {
                    saveOmitOption["storeDevicePopupCheck"] = false;
                  }
                  this.isOmitChecked = saveOmitOption["storeDevicePopupCheck"];
                  localStorage.setItem(
                    "storeOmitOption",
                    JSON.stringify(saveOmitOption)
                  );
                }
                if (response && response["lastSorting"]) {
                  if (response["lastSorting"]["storeLastSortedColumn"]) {
                    sortObj["storeLastColumnCheck"] =
                      response["lastSorting"]["storeLastColumnCheck"];
                  } else {
                    sortObj["storeLastColumnCheck"] = false;
                  }
                  this.isSortedChecked = sortObj["storeLastColumnCheck"];
                  localStorage.setItem("lastSorting", JSON.stringify(sortObj));
                }
                if (response && response["storeFilter"]) {
                  if (response["storeFilter"]["storeFilterCheck"]) {
                    filterObj["storeFilterCheck"] =
                      response["storeFilter"]["storeFilterCheck"];
                  } else {
                    filterObj["storeFilterCheck"] = false;
                  }
                  this.isFilterChecked =
                    response["storeFilter"]["storeFilterCheck"];
                  localStorage.setItem(
                    "storeFilter",
                    JSON.stringify(filterObj)
                  );
                }
                if (response && response["storeColumn"]) {
                  if (response["storeColumn"]["storeColumnsCheck"]) {
                    storeColumn["storeColumnsCheck"] =
                      response["storeColumn"]["storeColumnsCheck"];
                  } else {
                    storeColumn["storeColumnsCheck"] = false;
                  }
                  this.isVisibleChecked = storeColumn["storeColumnsCheck"];
                  localStorage.setItem(
                    "storeColumn",
                    JSON.stringify(storeColumn)
                  );
                }

                if (response && response["storeSaveFeatureFile"]) {
                  this.isFeatureSaveFileChecked =
                    response["storeSaveFeatureFile"][
                      "storeSaveFeatureFileCheck"
                    ];
                  localStorage.setItem(
                    "storeSaveFeatureFile",
                    JSON.stringify(response["storeSaveFeatureFile"])
                  );
                } else {
                  this.isFeatureSaveFileChecked = false;
                  storeSaveFeatureFile["storeSaveFeatureFileCheck"] = false;
                  storeSaveFeatureFile["storeSaveFeatureFileCheckValue"] =
                    "savefeaturefile";
                  localStorage.setItem(
                    "storeSaveFeatureFile",
                    JSON.stringify(storeSaveFeatureFile)
                  );
                }

                if (response && response["storeDCFFile"]) {
                  this.isDCFSaveFileChecked =
                    response["storeDCFFile"]["storeDCFFileCheck"];

                  localStorage.setItem(
                    "storeDCFFile",
                    JSON.stringify(response["storeDCFFile"])
                  );
                } else {
                  this.isDCFSaveFileChecked = false;
                  storeDCFfile["storeDCFFileCheck"] = false;
                  storeDCFfile["storeDCFFileCheckValue"] = "dcffile";

                  localStorage.setItem(
                    "storeDCFFile",
                    JSON.stringify(storeDCFfile)
                  );
                }

                // var newmode;
                // if(response['storeConfigMode']['storeConfigModeData'] === 'standard'){
                //   newmode = 'Advanced';
                //   this.firstMode = 'Advanced';
                // } else if(response['storeConfigMode']['storeConfigModeData'] === 'quickConfig') {
                //   newmode = 'Standard';
                //   this.firstMode = 'Standard';
                // }
                this.apiService.sendModes(this.firstMode);
              } else {
                this.isFeatureSaveFileChecked = false;
                this.isDCFSaveFileChecked = false;
                configModeObj["storeConfigMode"] = false;
                configModeObj["storeConfigModeData"] = "Advanced";

                storeColumn["storeColumnsData"] = this.columnList;
                storeColumn["storeColumnsCheck"] = false;

                filterObj["storeFilterData"] = [];
                filterObj["storeFilterCheck"] = false;

                sortObj["storeLastSortedColumn"] = ["12nc"];
                sortObj["storeLastColumnCheck"] = false;

                saveOmitOption["storeDevicePopupCheck"] = false;

                storeSaveFeatureFile["storeSaveFeatureFileCheck"] = false;
                storeSaveFeatureFile["storeSaveFeatureFileCheckValue"] =
                  "savefeaturefile";

                storeDCFfile["storeDCFFileCheck"] = false;
                storeDCFfile["storeDCFFileCheckValue"] = "dcffile";

                localStorage.setItem(
                  "storeSaveFeatureFile",
                  JSON.stringify(storeSaveFeatureFile)
                );
                localStorage.setItem(
                  "storeDCFFile",
                  JSON.stringify(storeDCFfile)
                );

                localStorage.setItem(
                  "storeConfigMode",
                  JSON.stringify(configModeObj)
                );
                localStorage.setItem(
                  "storeColumn",
                  JSON.stringify(storeColumn)
                );
                localStorage.setItem("storeFilter", JSON.stringify(filterObj));
                localStorage.setItem(
                  "storeOmitOption",
                  JSON.stringify(saveOmitOption)
                );
                localStorage.setItem("lastSorting", JSON.stringify(sortObj));
                this.apiService.postCallForUserPreferences();
              }
            });
        }
      });
    this.subscription = this.apiService.checkConfigData.subscribe((data) => {
      if (data === "featureConfiguration") {
        this.currentURL = window.location.href;
        if (this.currentURL.includes("featureLanding")) {
          this.showConfig = true;
        }
      } else {
        this.showConfig = false;
      }
    });

    this.subscription = this.apiService.getFeaturePage.subscribe((resp) => {
      if (resp === "Isvalid") {
        this.featurePage = true;
      } else {
        this.featurePage = false;
      }
    });
    this.apiService.getSelect.subscribe((resp) => {
      if (resp === "valid") {
        this.isRemoved = true;
        this.cdRef.detectChanges();
      } else if (resp === "invalid") {
        this.isRemoved = false;
        this.cdRef.detectChanges();
      }
    });
    var Url = window.location.href;
    if (Url.includes("home") || Url.includes("deviceList?response=")) {
      this.apiService.removeModes("invalid");
    } else {
      this.apiService.removeModes("valid");
    }

    this.apiService.getmodes.subscribe((resp) => {
      // var mode = JSON.parse(localStorage.getItem('modes'));
      // setTimeout(() => {
      if (resp === "Standard" || resp === "quickConfig") {
        this.firstMode = "Standard";
        localStorage.setItem("modes", JSON.stringify(this.firstMode));
      } else if (resp === "standard" || resp === "Advanced") {
        this.firstMode = "Advanced";
        localStorage.setItem("modes", JSON.stringify(this.firstMode));
      }
      let obj = {};
      if (this.configModeCheckedValue || this.isModeChecked) {
        var newmode;
        if (resp === "Standard" || resp === "quickConfig") {
          newmode = "Standard";
        } else if (resp === "Advanced" || resp === "standard") {
          newmode = "Advanced";
        }
        if (this.isconfigurationModeChecked) {
          obj["storeConfigModeData"] = newmode;
          obj["storeConfigModeCheck"] = true;
          localStorage.setItem("storeConfigMode", JSON.stringify(obj));
          this.apiService.postCallForUserPreferences();
        }
      }
      // }, 500);
    });
  }

  ngDoCheck() {
    if (localStorage.getItem("storeOmitOption")) {
      this.omitValue = JSON.parse(localStorage.getItem("storeOmitOption"));
      this.isOmitChecked = this.omitValue["storeDevicePopupCheck"];
    } else {
      this.isOmitChecked = null;
    }

    this.isLoggedin = JSON.parse(localStorage.getItem("loggedIn"));

    if (
      localStorage.getItem("detectedReaderInfo") &&
      localStorage.getItem("detectedReaderInfo") != "undefined"
    ) {
      let reader = JSON.parse(localStorage.getItem("detectedReaderInfo"));

      this.readerInfo.readerType = reader.readerType;
      if (reader.selectedReader) {
        this.readerInfo.message =
          reader.selectedReader.peripheralType +
          "-" +
          reader.selectedReader.peripheralId;
      } else {
        this.readerInfo.message = "Not connected";
      }
      this.readerInfo.readerConnected = reader.readerConnected;
    } else {
      this.readerInfo = this.readerInfo;
    }

    if (localStorage.getItem("storeConfigMode")) {
      this.storeConfigMode = JSON.parse(
        localStorage.getItem("storeConfigMode")
      );
      this.isconfigurationModeChecked =
        this.storeConfigMode["storeConfigModeCheck"];
    } else {
      this.isconfigurationModeChecked = null;
    }

    if (localStorage.getItem("sortCol")) {
      this.sortCheck = JSON.parse(localStorage.getItem("sortCol"));
      this.isSortedChecked = this.sortCheck["boolVal"];
    } else {
      this.isSortedChecked = null;
    }

    if (localStorage.getItem("storeColumn")) {
      this.visibleCheck = JSON.parse(localStorage.getItem("storeColumn"));
      this.isVisibleChecked = this.visibleCheck["storeColumnsCheck"];
    } else {
      this.isVisibleChecked = null;
      localStorage.removeItem("storeColumn");
    }

    if (localStorage.getItem("lastSorting")) {
      let visibleCheck = JSON.parse(localStorage.getItem("lastSorting"));
      this.isSortedChecked = visibleCheck["storeLastColumnCheck"];
    } else {
      this.isSortedChecked = null;
      localStorage.removeItem("storeLastColumnCheck");
    }

    if (localStorage.getItem("storeFilter")) {
      this.visibleCheck = JSON.parse(localStorage.getItem("storeFilter"));
      this.isFilterChecked = this.visibleCheck["storeFilterCheck"];
    } else {
      this.isFilterChecked = null;
      localStorage.removeItem("storeFilter");
    }
  }
  login() {
    if (this.userData == "") {
      //this.router.navigate(['/home']);
      window.location.href = this.SSOUrl;
    }
  }
  home() {
    localStorage.removeItem("dynaDimmerSchema");
    localStorage.removeItem("dynaScenes");
    localStorage.removeItem("configurationData");
    localStorage.removeItem("comparefirmwarenoLatest");
    this.apiService.removeModes("invalid");
    this.apiService.configModeRefresh("pass");
    var url = window.location.href;
    var modes = JSON.parse(localStorage.getItem("modes"));
    var setmode = JSON.parse(localStorage.getItem("setmode"));
    var fileCreated = JSON.parse(localStorage.getItem("featureFileCReation"));
    // if(url.includes('featureLanding')) {
    //   if(setmode === modes) {
    //     localStorage.setItem('modes', JSON.stringify(modes));
    //     this.apiService.sendModes(modes);
    //   } else {
    //     let obj = {};
    //       if(modes=== 'Standard') {
    //       if(fileCreated && modes === 'Standard' || this.isconfigurationModeChecked) {
    //         modes = 'Standard';
    //       } else {
    //         modes = 'Advanced';
    //       }
    //       obj['storeConfigModeData'] = modes;
    //       if(this.isconfigurationModeChecked) {
    //         obj['storeConfigModeCheck'] = true;
    //       }else {
    //         obj['storeConfigModeCheck'] = false;
    //       }
    //       localStorage.setItem('storeConfigMode', JSON.stringify(obj));
    //       this.apiService.postCallForUserPreferences();
    //       localStorage.setItem('modes', JSON.stringify(modes));
    //       this.apiService.sendModes(modes);
    //       if(this.isconfigurationModeChecked) {
    //         obj['storeConfigModeData'] = modes;
    //         obj['storeConfigModeCheck'] = true;
    //         localStorage.setItem('storeConfigMode', JSON.stringify(obj));
    //         this.apiService.postCallForUserPreferences();
    //       }
    //     } else if(modes=== 'Advanced') {
    //       if(fileCreated && modes === 'Advanced' || this.isconfigurationModeChecked) {
    //         modes = 'Advanced';
    //       } else {
    //         modes = 'Standard';
    //       }
    //       obj['storeConfigModeData'] = modes;
    //       if(this.isconfigurationModeChecked) {
    //         obj['storeConfigModeCheck'] = true;
    //       } else {
    //         obj['storeConfigModeCheck'] = false
    //       }
    //       localStorage.setItem('storeConfigMode', JSON.stringify(obj));
    //       this.apiService.postCallForUserPreferences();
    //       localStorage.setItem('modes', JSON.stringify(modes));
    //       this.apiService.sendModes(modes);
    //       if(this.isconfigurationModeChecked) {
    //         obj['storeConfigModeData'] = modes;
    //         obj['storeConfigModeCheck'] = true;
    //         localStorage.setItem('storeConfigMode', JSON.stringify(obj));
    //         this.apiService.postCallForUserPreferences();
    //       }
    //     }
    //   }
    // }
    localStorage.setItem("exportedFeatureFile", JSON.stringify(false));
    localStorage.setItem("newDeviceBoolen", JSON.stringify(true));
    localStorage.removeItem("featureFileCReation");
    localStorage.removeItem("quickConfiguration");
    localStorage.removeItem("configId");
    localStorage.removeItem("configurationData");
    this.router.navigate(["/home"]);
  }
  userPref(e) {
    e.stopPropagation();
  }

  logout() {
    this.authenticationService.stopTokenExpiredCheck();
    localStorage.removeItem("currentUser");
    localStorage.removeItem("modes");
    localStorage.setItem("newDeviceBoolen", JSON.stringify(true));
    localStorage.setItem("loggedIn", "false");
    let value = localStorage.getItem("Name");
    localStorage.setItem("lastloggedUser", value);
    localStorage.removeItem("readerDevices");
    localStorage.removeItem("detectedReaderInfo");
    setTimeout(() => {
      this.authenticationService.logout();
      this.logOutUrl = this.apiService.getLogoutUrl(window.location.hostname);
      let replaceLogoutUrl = window.location.href;
      if (replaceLogoutUrl.includes("featureLanding")) {
        replaceLogoutUrl = replaceLogoutUrl.replace("featureLanding", "home");
      } else if (replaceLogoutUrl.includes("deviceList")) {
        replaceLogoutUrl = replaceLogoutUrl.replace("deviceList", "home");
      }
      window.location.href = this.logOutUrl + replaceLogoutUrl;
      this.authenticationService.setCurrentUser("logout");
    }, 0);

    if (this.isVisibleChecked == null) {
      localStorage.removeItem("storeColumn");
    }
    if (this.isSortedChecked == null) {
      localStorage.removeItem("lastSorting");
    }

    localStorage.removeItem("storeSaveFeatureFile");
    localStorage.removeItem("storeDCFFile");
  }

  getDevieList() {
    this.listReader = this.readerData;
    const modalRef = this.modalService.open(ReadDeviceSelectionPopupComponent, {
      backdrop: "static",
      keyboard: false,
      windowClass: "select-device-modal-comp",
      centered: true,
      size: "lg",
    });
    this.modalRefUnSubscribe = modalRef;
    modalRef.componentInstance.readerName = this.listReader;
    modalRef.componentInstance.clickEvent = true;
    modalRef.componentInstance.componentFrom = "Header";
  }

  getDeviceReader() {
    return this.apiService.getServerStatus(this.readerBaseUrl);
  }

  getReaderList() {
    return this.apiService.apiCall(
      "get",
      this.readerBaseUrl + this.readerListUrl
    );
  }

  sortedCol(event, checkboxtype) {
    this.lastSortCheckbox = event;
    // this.valueChange['boolVal'] = event;
    // const boolVal = this.valueChange['boolVal'];
    // this.valueChange['type'] = checkboxtype;
    this.apiService.getEnvValue();
    this.apiService.getEnvData
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((url) => {
        if (url.length) {
          this.unsubscribe.next();
          this.apiService
            .apiCall("get", url + this.preferencesAPI)
            .subscribe((response) => {
              let obj = {};
              if (response) {
                obj["storeLastSortedColumn"] =
                  response["lastSorting"]["storeLastSortedColumn"];
                if (this.lastSortCheckbox) {
                  obj["storeLastColumnCheck"] = this.lastSortCheckbox;
                  localStorage.setItem("lastSorting", JSON.stringify(obj));
                  this.apiService.postCallForUserPreferences();
                } else {
                  // if(!boolVal){
                  obj["storeLastColumnCheck"] = this.lastSortCheckbox;
                  this.apiService.sendUncheckSort("uncheck");
                  localStorage.setItem("lastSorting", JSON.stringify(obj));
                  this.apiService.postCallForUserPreferences();
                  // localStorage.removeItem('lastSorting');

                  // }
                }
                // window.location.reload();
              }
            });
        }
      });
  }

  visibleColumns(event, visibleColumns) {
    this.visibleCheckedValue = event;
    this.apiService.getEnvValue();
    this.apiService.getEnvData
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((url) => {
        if (url.length) {
          this.unsubscribe.next();
          this.apiService
            .apiCall("get", url + this.preferencesAPI)
            .subscribe((response) => {
              let obj = {};
              let sortObj = {};
              if (response) {
                if (
                  response["storeColumn"] &&
                  response["storeColumn"]["storeColumnsData"]
                ) {
                  obj["storeColumnsData"] =
                    response["storeColumn"]["storeColumnsData"];
                } else {
                  obj["storeColumnsData"] = this.columnList;
                }

                obj["storeColumnsCheck"] = this.visibleCheckedValue;
                localStorage.setItem("storeColumn", JSON.stringify(obj));
                // this.apiService.setColumn(this.columnList);
                this.apiService.postCallForUserPreferences();

                if (response["lastSorting"]["storeLastColumnCheck"]) {
                  sortObj["storeLastSortedColumn"] =
                    response["lastSorting"]["storeLastSortedColumn"];
                  sortObj["storeLastColumnCheck"] =
                    response["lastSorting"]["storeLastColumnCheck"];
                  localStorage.setItem("lastSorting", JSON.stringify(sortObj));
                  this.apiService.postCallForUserPreferences();
                }
              }
            });
        }
      });
  }

  onPreChange(event, checkboxtype) {
    this.valueChange["storeDevicePopupCheck"] = event;
    this.valueChange["storeDevicePopupValue"] = checkboxtype;

    localStorage.setItem("storeOmitOption", JSON.stringify(this.valueChange));
    this.apiService.postCallForUserPreferences();
  }

  onChangeSaveFeatureFile(event, checkboxtype) {
    let saveFeatureFile = {};

    saveFeatureFile["storeSaveFeatureFileCheck"] = event;
    saveFeatureFile["storeSaveFeatureFileCheckValue"] = checkboxtype;

    localStorage.setItem(
      "storeSaveFeatureFile",
      JSON.stringify(saveFeatureFile)
    );
    this.apiService.postCallForUserPreferences();
  }

  onChangeDCFFile(event, checkboxtype) {
    let saveDCFFile = {};

    saveDCFFile["storeDCFFileCheck"] = event;
    saveDCFFile["storeDCFFileCheckValue"] = checkboxtype;

    localStorage.setItem("storeDCFFile", JSON.stringify(saveDCFFile));
    this.apiService.postCallForUserPreferences();
  }

  configurationModeChange(event, configurationMode) {
    this.configModeCheckedValue = event;
    this.apiService.getEnvValue();
    this.apiService.getEnvData
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((url) => {
        if (url.length) {
          this.unsubscribe.next();
          this.apiService
            .apiCall("get", url + this.preferencesAPI)
            .subscribe((response) => {
              let obj = {};
              if (response) {
                if (
                  response["storeConfigMode"] &&
                  response["storeConfigMode"]["storeConfigModeData"]
                ) {
                  obj["storeConfigModeData"] =
                    response["storeConfigMode"]["storeConfigModeData"];
                  obj["storeConfigModeCheck"] =
                    response["storeConfigMode"]["storeConfigModeCheck"];
                } else {
                  obj["storeConfigModeData"] = "standard";
                  obj["storeConfigModeCheck"] = false;
                }

                if (this.configModeCheckedValue) {
                  let standardConfig = JSON.parse(
                    localStorage.getItem("modes")
                  );
                  obj["storeConfigModeData"] = standardConfig;
                  obj["storeConfigModeCheck"] = this.configModeCheckedValue;
                  localStorage.setItem("storeConfigMode", JSON.stringify(obj));
                  this.apiService.postCallForUserPreferences();
                } else {
                  obj["storeConfigModeCheck"] = this.configModeCheckedValue;
                  localStorage.setItem("storeConfigMode", JSON.stringify(obj));
                  this.apiService.postCallForUserPreferences();
                }
              }
            });
        }
      });
  }
  webShopLogin() {
    this.dashboardUrl = "https://" + "www.emea.oemwebshop.signify.com/";
    window.open(this.dashboardUrl, "_blank");
  }
  designInLogin() {
    this.dashboardUrl =
      "https://" + "www.easydesignintool.signify.com/#/advanced";
    window.open(this.dashboardUrl, "_blank");
  }
  oEMLogin() {
    this.dashboardUrl = "https://" + "www.lighting.philips.co.uk/oem-emea";
    window.open(this.dashboardUrl, "_blank");
  }
  menuIconClick() {
    // window.location.href = this.dashboardUrl;
    this.logOutUrl = this.apiService.getLogoutUrl(window.location.hostname);
    let domain = new URL(this.logOutUrl);
    this.dashboardUrl = "https://" + domain.hostname + "/dashboard";
    window.open(this.dashboardUrl, "_blank");
  }
  visiblFilter(event, visibleFilter) {
    this.filterCheckedValue = event;
    this.apiService.getEnvValue();
    this.apiService.getEnvData
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((url) => {
        if (url.length) {
          this.unsubscribe.next();
          this.apiService
            .apiCall("get", url + this.preferencesAPI)
            .subscribe((response) => {
              let obj = {};
              if (response) {
                if (
                  response["storeFilter"] &&
                  response["storeFilter"]["storeFilterData"] &&
                  response["storeFilter"]["storeFilterData"].length > 0
                ) {
                  obj["storeFilterData"] =
                    response["storeFilter"]["storeFilterData"];
                } else {
                  obj["storeFilterData"] = [];
                }
                if (this.filterCheckedValue) {
                  obj["storeFilterCheck"] = this.filterCheckedValue;
                  localStorage.setItem("storeFilter", JSON.stringify(obj));
                  // this.apiService.setColumn(obj['storeFilterData']);
                  this.apiService.postCallForUserPreferences();
                } else {
                  obj["storeFilterCheck"] = this.filterCheckedValue;
                  obj["storeFilterData"] =
                    response["storeFilter"]["storeFilterData"];
                  localStorage.setItem("storeFilter", JSON.stringify(obj));
                  // this.apiService.setFilter('default');
                  this.apiService.postCallForUserPreferences();
                }
              }
            });
        }
      });
  }
  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    //  this.apiService.sendModes('')
    this.modalService.dismissAll();

    if (this.modalRefUnSubscribe) {
      this.modalRefUnSubscribe.close();
    }
  }
  changeMode(modes) {
    localStorage.setItem("modes", JSON.stringify(modes));
    this.apiService.sendModes(modes);
  }
}
