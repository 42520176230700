import { Component, OnInit, Input, Output,EventEmitter,ElementRef,ViewChild,ChangeDetectionStrategy, HostListener, ChangeDetectorRef, ViewRef, OnDestroy} from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { Options } from 'ng5-slider';
import { ApiService } from 'src/app/core-module/services/api.service';
import { restore} from './../../../../assets/json/restoreTooltip.en';
import { element } from 'protractor';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'app-alo-slider',
  templateUrl: './alo-slider.component.html',
  styleUrls: ['./alo-slider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AloSliderComponent extends FieldType implements OnInit,OnDestroy {
@Input() field;
floorValue;
ceilValue;
defaultValue;
valOptions;
option:Options={
  floor:0,
  ceil:100,
  

};
textboxValue;
sliderValue;
mintextboxValue: Number;
minsliderValue;
maxsliderValue;
mindefaultValue;
aloMinSliderValue;
inputError: any = [];
defaultColor = true;
minDefaultColor = true;
isAloEnabled;
isResetDisabled;
sliderChange=false;
val;
isSliderClick=true;
resetALoSlider: boolean;
newval: any;
  showAloMinSlider: boolean;
  aloInputError: boolean;
  aloSliderValue: any;
  quickConfigvalue;
  sliderQuick;
  setQuickConfig;
  isEnableFeature = false;
  restoreText = restore;
  fileResponse: any;
  ng
  column: any;
  properties: any;
  aloPercentageOption: any;
  aloPercentageMaximum: any;
  aloPercentageMinimum: any;
  aloMinLevelMinimum: any;
  aloMinLevelMaximum: any;
  aloMinLevelOption: any;
  ngModelval: any = {};
  aloMinlevelVal: any;
  aloPercentageVal: any;
  aloDefaultColor: boolean;
  aloDisbaled: boolean;
  aloDisbaledButton: boolean;
  wrongValue: any;
  wrongAloMinValue: any;
  quickConfigCustomCSS: string;

  constructor(private apiService: ApiService, private ref: ChangeDetectorRef) { 
    super();
  }

  ngOnInit() {
    this.apiService.getAloDefaultValue.subscribe( (response) => {
      if(response) {
        this.resetSlider()
      }
    });

    this.column = this.field.requiredProperties;
    this.properties = this.field.properties;
    this.aloPercentageMaximum = this.properties.AloPercentage.maximum;
    this.ngModelval['AloPercentageVal'] = this.aloPercentageVal = this.properties.AloPercentage.default;
    if(this.properties.AloMinLevel) {
      this.showAloMinSlider = true;
      this.aloPercentageMinimum = this.properties.AloMinLevel.minimum;
      this.aloMinLevelMaximum = this.properties.AloMinLevel.maximum;
      this.aloMinLevelMinimum = this.properties.AloMinLevel.minimum;
      this.aloMinLevelOption = {
        floor: this.aloMinLevelMinimum,
        ceil: this.aloMinLevelMaximum
      }
      this.ngModelval['AloMinLevelVal'] = this.aloMinlevelVal = this.properties.AloMinLevel.default;
      this.aloPercentageOption = {
        floor: this.aloPercentageMinimum,
        ceil: this.aloPercentageMaximum,
        minLimit: this.ngModelval['AloMinLevelVal']
      }
    } else {
      this.aloPercentageMinimum = this.properties.AloPercentage.minimum;
      this.aloPercentageOption = {
        floor: this.aloPercentageMinimum,
        ceil: this.aloPercentageMaximum
      }
    }

    this.ngModelval['AloEnabledVal'] = this.isEnableFeature = this.properties.AloEnabled.default;
    
    this.fileResponse =JSON.parse(localStorage.getItem('configurationData'))
    let aaa = JSON.parse(localStorage.getItem('saveSetData1'));

  
    let unSelectedFeat = Array(JSON.parse(localStorage.getItem("UnSelectedFeatConfig")));
    
     let  isConfigEdited = false;
     let featIndex:number=0;
     if(unSelectedFeat[0]!=null){
      unSelectedFeat[0].forEach(element => {
        if(element["featureName"]==="ALO"){
          
          isConfigEdited = true;
          this.ngModelval['AloPercentageVal'] = this.aloPercentageVal = element["featureParameters"][0].AloPercentage;
          this.ngModelval['AloEnabledVal'] = this.isEnableFeature = element["featureParameters"][0].AloEnabled;
         
          if(this.field.properties.AloMinLevel) {
            if(this.properties.AloMinLevel) {
              this.ngModelval['AloMinLevelVal'] = this.aloMinlevelVal = element["featureParameters"][0].AloMinLevel ? element["featureParameters"][0].AloMinLevel : this.properties.AloMinLevel.default;
            }
            this.aloPercentageOption = {
              floor: this.aloPercentageMinimum,
              ceil: this.aloPercentageMaximum,
              minLimit: this.ngModelval['AloMinLevelVal']
            }
          }
          unSelectedFeat[0].splice(featIndex,1)
          localStorage.setItem("UnSelectedFeatConfig",JSON.stringify(unSelectedFeat[0]));
        }
        else{
          featIndex++;
        }
        
      });
    }
    if(isConfigEdited){
     
    }
    else if(this.fileResponse && this.fileResponse['resp'] && this.fileResponse['resp']['ALO']) {
      let aloResp = JSON.parse(localStorage.getItem('configurationData'))
      this.ngModelval['AloPercentageVal'] = this.aloPercentageVal = this.fileResponse.resp.ALO.AloPercentage;
      this.ngModelval['AloEnabledVal'] = this.isEnableFeature = this.fileResponse.resp.ALO.AloEnabled;
      if(this.field.properties.AloMinLevel) {
        if(this.properties.AloMinLevel) {
          this.ngModelval['AloMinLevelVal'] = this.aloMinlevelVal = this.fileResponse.resp.ALO.AloMinLevel ? this.fileResponse.resp.ALO.AloMinLevel : this.properties.AloMinLevel.default;
        }
        this.aloPercentageOption = {
          floor: this.aloPercentageMinimum,
          ceil: this.aloPercentageMaximum,
          minLimit: this.ngModelval['AloMinLevelVal']
        }
      }
      delete aloResp['resp']['ALO'];
      localStorage.setItem('configurationData', JSON.stringify(aloResp))
    } else if(aaa && aaa['resp'] && aaa['resp']['ALO']) {
      this.fileResponse = aaa;
      this.ngModelval['AloPercentageVal'] = this.aloPercentageVal = this.fileResponse.resp.ALO.AloPercentageVal ? this.fileResponse.resp.ALO.AloPercentageVal : this.fileResponse.resp.ALO.AloPercentage;
      this.ngModelval['AloEnabledVal'] = this.isEnableFeature = !isNullOrUndefined(this.fileResponse.resp.ALO.AloEnabledVal) ? this.fileResponse.resp.ALO.AloEnabledVal : this.fileResponse.resp.ALO.AloEnabled;
      if(this.field.properties.AloMinLevel) {
        if(this.properties.AloMinLevel) {
          this.ngModelval['AloMinLevelVal'] = this.aloMinlevelVal = this.fileResponse.resp.ALO.AloMinLevelVal ? this.fileResponse.resp.ALO.AloMinLevelVal : this.properties.AloMinLevel.default;
        }
        this.aloPercentageOption = {
          floor: this.aloPercentageMinimum,
          ceil: this.aloPercentageMaximum,
          minLimit: this.ngModelval['AloMinLevelVal']
        }
      }
    }
    this.apiService.aocSliderCick.subscribe(data=>{
      this.aloSliderValue = data['sliderClick'];
    })
    this.rSetChanged(this.isEnableFeature)
   this.apiService.getquickConfig.subscribe(data=>{
    // this.quickConfigvalue = data['quickConfig'];
    if( data === 'quickConfig') {
      this.quickConfigvalue = true;
      this.quickConfigCustomCSS = "quick-custom-inputbox textBoxUnit";
      // this.apiService.aloPercentConfigurationData({ 'AloPercentage': this.textboxValue });
      // this.apiService.setAloQuickData(this.mintextboxValue);
      this.setQuickConfig = 'quickConfig';
    } else if(data === 'standard') {
      this.quickConfigvalue = false;
      this.quickConfigCustomCSS = "custom-inputbox";
      // this.apiService.aloPercentConfigurationData({ 'AloPercentage': this.textboxValue });
      this.sliderValue = this.textboxValue;
      // this.apiService.setAloQuickData(this.mintextboxValue);
    }
    setTimeout(() => {
      if (this.ref && !(this.ref as ViewRef).destroyed) {
        this.ref.detectChanges();
      }
    });
  })

  setTimeout(() => {

    this.checkValidValue();
  },1000) 

  }

  checkValidValue() {
    this.aloDefaultColor = false;
    this.aloDisbaledButton = false;
    if (this.aloPercentageVal < this.aloPercentageMinimum || this.aloPercentageVal > this.aloPercentageMaximum || this.aloPercentageVal < this.ngModelval['AloMinLevelVal']) {
      this.inputError['aloPercentage'] = true;
      this.aloDisbaledButton = true;
    } else {
    }
    if (this.aloMinlevelVal < this.aloMinLevelMinimum || this.aloMinlevelVal > this.aloMinLevelMaximum) {
      this.inputError['aloMinLevel'] = true;
      this.aloDisbaledButton = true;
    } else {
    }
    for (var type in this.inputError) {
      if (this.inputError[type]) {
        this.aloDisbaledButton = true;
      }
    }
    this.apiService.aloSetErrorButton(this.aloDisbaledButton);
  }

  
 
  textValChanged(value, column) {
    if(value === '') {
      value = 0;
    }
    value = parseInt(value);
    this.aloDefaultColor = false;
    this.inputError[column] = false;
    this.aloDisbaledButton=false;
    if(column === 'aloPercentage') {
      if (value < this.aloPercentageMinimum || value > this.aloPercentageMaximum || value < this.ngModelval['AloMinLevelVal']) {
        this.inputError[column] = true;
        this.aloDisbaledButton=true;
        this.aloPercentageVal = value;
        this.wrongValue = value
      } else {
        this.ngModelval['AloPercentageVal'] = value;
        this.aloPercentageVal = value
      }
    } else if (column === 'aloMinLevel') {
      if (value < this.aloMinLevelMinimum || value > this.aloMinLevelMaximum) {
        this.inputError[column] = true;
        this.aloDisbaledButton=true;
        this.aloMinlevelVal = value;
        this.wrongAloMinValue = value
      } else {
        this.ngModelval['AloMinLevelVal'] = value;
        this.aloMinlevelVal = value;
        this.aloPercentageOption = {
          floor: this.aloPercentageMinimum,
          ceil: this.aloPercentageMaximum,
          minLimit: this.ngModelval['AloMinLevelVal']
        }
        if(this.aloMinlevelVal >= this.aloPercentageVal || this.aloMinlevelVal >= this.ngModelval['AloPercentageVal']) {
          this.aloPercentageSliderChange(this.aloMinlevelVal);
        } else if(this.aloPercentageVal > this.aloPercentageMaximum) {
          this.aloPercentageSliderChange(this.aloPercentageMaximum);
        } else {
          this.aloPercentageSliderChange(this.aloPercentageVal);
        }
      }
    }
    this.checkDefault();
    for (var type in this.inputError) {
      if(this.inputError[type]) {
        this.aloDisbaledButton = true;
      }
    }
    this.apiService.setAloDefaultColor(this.aloDefaultColor);
    this.apiService.aloSetErrorButton(this.aloDisbaledButton);
    this.apiService.setAloData(this.ngModelval);

  }

  aloPercentageSliderChange(value) {
    this.inputError['aloPercentage'] = false;
    if (value >= this.aloPercentageMinimum && this.inputError['aloPercentage'] === false) {
      this.ngModelval['AloPercentageVal'] = value;
      this.inputError['aloPercentage'] = false;
      this.aloPercentageVal = value;
      this.aloDisbaledButton = false;
      for (var type in this.inputError) {
        if(this.inputError[type]) {
          this.aloDisbaledButton = true;
        }
      }
      this.apiService.aloSetErrorButton(this.aloDisbaledButton);
      this.checkDefault()
      this.apiService.setAloData(this.ngModelval);
    }
  }

  aloMinValueChange(value) {
    this.inputError['aloMinLevel'] = false;
    if (value >= this.aloMinLevelMinimum && this.inputError['aloMinLevel'] === false) {
      this.ngModelval['AloMinLevelVal'] = value;
      this.inputError['aloMinLevel'] = false;
      this.aloMinlevelVal = value;
      this.aloPercentageOption = {
        floor: this.aloPercentageMinimum,
        ceil: this.aloPercentageMaximum,
        minLimit: this.ngModelval['AloMinLevelVal']
      }
      this.textValChanged(value, 'aloMinLevel')
      this.aloDisbaledButton = false;
      for (var type in this.inputError) {
        if(this.inputError[type]) {
          this.aloDisbaledButton = true;
        }
      }
      this.apiService.aloSetErrorButton(this.aloDisbaledButton);
      this.checkDefault()
      this.apiService.setAloData(this.ngModelval);
    }
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

  
  checkDefault() {
    this.aloDefaultColor = false;
    if(this.properties.AloMinLevel) {
      if((this.properties.AloMinLevel.default === this.aloMinlevelVal
        && this.properties.AloPercentage.default === this.aloPercentageVal)
        && (this.isEnableFeature === this.properties.AloEnabled.default)) {
        this.aloDefaultColor = true;
      }
    } else {
      if((this.properties.AloPercentage.default === this.aloPercentageVal)
      && (this.isEnableFeature === this.properties.AloEnabled.default)) {
      this.aloDefaultColor = true;
      }
    }
    this.apiService.setAloDefaultColor(this.aloDefaultColor);
  }

  rSetChanged(value) {
    this.aloDefaultColor = value;
    this.isEnableFeature = this.ngModelval['AloEnabledVal'] = value
    this.aloDisbaled = !value;
    this.checkDefault();
    this.apiService.setAloDefaultColor(this.aloDefaultColor);
    this.apiService.setAloData(this.ngModelval);
  }

  resetSlider() {
    this.aloDisbaledButton = false;
    if(this.properties.AloMinLevel) {
      this.ngModelval['AloMinLevelVal'] = this.aloMinlevelVal = this.properties.AloMinLevel.default;
      this.inputError['aloMinLevel'] = false;
    }
    this.ngModelval['AloPercentageVal'] = this.aloPercentageVal = this.properties.AloPercentage.default;
    this.inputError['aloPercentage'] = false;
    this.ngModelval['AloEnabledVal'] = this.isEnableFeature = this.properties.AloEnabled.default;
    this.rSetChanged(this.isEnableFeature)
    for (var type in this.inputError) {
      if(this.inputError[type]) {
        this.aloDisbaledButton = true;
      }
    }
    this.aloDefaultColor = true;
    this.apiService.setAloDefaultColor(this.aloDefaultColor);
    this.apiService.aloSetErrorButton(this.aloDisbaledButton);
    this.apiService.setAloData(this.ngModelval);
    setTimeout(() => {
      if (this.ref && !(this.ref as ViewRef).destroyed) {
        this.ref.detectChanges();
      }
    });
  }

  ngOnDestroy() {
    this.textboxValue = 0;
    this.mintextboxValue = 0;
    this.minsliderValue = 0;
    this.sliderValue = 0;
    this.apiService.setAloData('');
    this.apiService.quickAloPercentConfigurationData('');
    this.apiService.aloPercentConfigurationData('');
    this.apiService.sentAloEnabled(false)
    this.apiService.aloSetErrorButton('');
  }
}
