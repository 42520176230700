import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-preset',
  templateUrl: './preset.component.html',
  styleUrls: ['./preset.component.scss']
})
export class PresetComponent implements OnInit {
  fileName: any;
  deviceData: any;
  featurePresent: any;
  openFile: string;
  modifyArray: any=[];

  constructor() { }

  ngOnInit() {
    this.openFile = JSON.parse(localStorage.getItem('newDeviceBoolen'));
    this.fileName = localStorage.getItem('saveFileName');
    this.deviceData = JSON.parse(localStorage.getItem('configurationData'));
    this.deviceData = this.deviceData['deviceName'];
    this.featurePresent = JSON.parse(localStorage.getItem('importFeatureData'));
    this.featurePresent = this.featurePresent['successfulFeatures'];
    this.featurePresent.forEach(element => {
      if(element == 'ALOMin' || element =='ALO') {
        this.modifyArray.push('ALO (Adjustable light output)')
      }
      if(element == 'CLO' || element == 'CLOLite') {
        this.modifyArray.push('CLO (Current light output)');
      }
      if(element == 'AOC') {
        this.modifyArray.push('AOC (Adjustable output current)');
      }
      if(element == 'Ast') {
        this.modifyArray.push('Startup time');
      }
      if(element == 'LumInfoFormat') {
        this.modifyArray.push('Luminaire info');
      }
      if(element == 'Ztv') {
        this.modifyArray.push('0-10v / 1-10v');
      }
      this.modifyArray = this.modifyArray.sort();
    });
  }

}
