import { Component, OnInit, OnDestroy } from "@angular/core";
import { ApiService } from "src/app/core-module/services/api.service";
import * as d3 from "d3";

@Component({
  selector: "app-corridor-graph",
  templateUrl: "./corridor-graph.component.html",
  styleUrls: ["./corridor-graph.component.scss"],
})
export class CorridorGraphComponent implements OnInit {
  inputXRange = [0, 230];
  inputYRange = [0, 100];

  data: any[] = [
    { dimLevel: 0, time: 0 },
    { dimLevel: 10, time: 0 },
    { dimLevel: 10, time: 82 },
    { dimLevel: 100, time: 82 },
    { dimLevel: 115, time: 49 },
    { dimLevel: 215, time: 49 },
    { dimLevel: 215, time: 0 },
  ];
  focus;
  drag;
  public margin = { top: 50, right: 50, bottom: 50, left: 50 };
  public width: number;
  public height: number;
  public x: any;
  public y: any;
  public svg1: any;
  public line: d3.Line<[number, number]>; // this is line defination
  public area: d3.area<[number, number]>; // this is area defination
  public id = "lineChart";
  public color = "#00b36b";
  newData: any;
  changedPoint: any;
  feedMaxValue: any = 112;
  NormalLevelMaximum: any;
  BackgroundLevelMaximum: any;

  constructor(private apiService: ApiService) {}

  ngOnInit() {
    this.apiService.getCorridorData.subscribe((data) => {
      if (data) {
        //this.data
        this.newData = data;
        this.feedMaxValue = data["FadeTimeIndexMaximum"]; //254
        this.NormalLevelMaximum = data["NormalLevelMaximum"]; //254
        this.BackgroundLevelMaximum = data["BackgroundLevelMaximum"]; //254
        this.convertData(data);
        this.buildSvg();
        this.addXandYAxis();
        this.drawLineAndPath();
        this.addDrag();
      }
    });
  }

  public buildSvg() {
    this.svg1 = d3.select("#svg1");
    this.width = 990 - this.margin.left - this.margin.right;
    this.height = 500 - this.margin.top - this.margin.bottom;
  }
  public addXandYAxis() {
    // range of data configuring
    this.x = d3.scaleLinear().range([0, this.width]);
    this.y = d3.scaleLinear().range([this.height, 0]);
    this.x.domain(this.inputXRange);
    this.y.domain(this.inputYRange);
  }

  public drawLineAndPath() {
    this.line = d3
      .line()
      .x((d: any) => this.x(d.dimLevel))
      .y((d: any) => this.y(d.time));
    d3.selectAll("#svg1 > *").remove();
    this.area = d3
      .area()
      .x((d: any) => this.x(d.dimLevel))
      .y0(this.height)
      .y1((d: any) => this.y(d.time));

    this.focus = this.svg1
      .append("g")
      .attr(
        "transform",
        "translate(" + this.margin.left + "," + this.margin.top + ")"
      );
    //ploting Area
    this.focus
      .append("path")
      .datum(this.data)
      .attr("fill", "#1ECBD0")
      .attr("fill-opacity", 0.3)
      .attr("stroke", "#00b36b")
      .attr("stroke-width", "1px")
      .attr("fill", "url('#gradient')")
      .attr("d", this.area);

    //ploting circle
    this.focus
      .selectAll("circle")
      .data(this.data)
      .enter()
      .append("circle")
      .attr("r", 4.0)
      .attr("cx", (d: any) => this.x(d.dimLevel))
      .attr("cy", (d: any) => this.y(d.time))
      .style("cursor", "pointer")
      .style("fill", "#1ECBD0");

    const maxY = d3.max(this.data, function (d) {
      return d.time;
    });
    // Add Gradient
    this.svg1
      .append("linearGradient")
      .attr("id", "gradient")
      .attr("gradientUnits", "userSpaceOnUse")
      .attr("x1", 0)
      .attr("y1", this.y(0))
      .attr("x2", 0)
      .attr("y2", this.y(maxY))
      .selectAll("stop")
      .data([
        {
          offset: "10%",
          color: "transparent",
        },
        {
          offset: "30%",
          color: "#00E487",
        },
        {
          offset: "100%",
          color: this.color,
        },
      ])
      .enter()
      .append("stop")
      .attr("offset", function (d) {
        return d.offset;
      })
      .attr("stop-color", function (d) {
        return d.color;
      });

    this.focus
      .append("g")
      .attr("class", "axis axis--x")
      .style("color", "white")
      .attr("transform", "translate(0," + this.height + ")");
    // .call(d3.axisBottom(this.x));
    // text label for the x axis
    // this.svg.append("text")
    //   .attr("transform",
    //     "translate(" + ((this.width / 2) + 20) + " ," +
    //     (this.height + this.margin.top + 40) + ")")
    //   .style("text-anchor", "middle")
    //   .text("");

    // Configure the Y Axis
    this.focus
      .append("g")
      .attr("class", "axis axis--y")
      .call(d3.axisLeft(this.y));

    // text label for the y axis
    this.svg1
      .append("text")
      .attr("transform", "rotate(-90)")
      .attr("y", 0 - this.margin.left + 62)
      .attr("x", 0 - this.height / 2)
      .style("text-anchor", "middle")
      .style("font", "16px Signify-Relative-Bold")
      .text("Dim level ( % )");

    // add the X gridlines
    this.focus
      .append("g")
      // .attr("class", "grid")
      .attr("transform", "translate(0," + this.height + ")")
      .call(this.make_x_gridlines().tickSize(-this.height).tickFormat(""));

    // add the Y gridlines
    this.focus
      .append("g")
      // .attr("class", "grid")
      .call(this.make_y_gridlines().tickSize(-this.width).tickFormat(""));
  }

  // gridlines in x axis function
  make_x_gridlines() {
    return d3.axisBottom(this.x).ticks(10);
  }
  // gridlines in y axis function
  make_y_gridlines() {
    return d3.axisLeft(this.y).ticks(10);
  }

  public addDrag() {
    const _this = this;
    let selection = this.focus.selectAll("circle").call(
      d3
        .drag()
        .on("start", function (event, d) {
          const control = _this.dragstarted();
        })
        .on("drag", function (event, d) {
          const control = _this.dragstarted();
          const n = selection.nodes();
          const i = n.indexOf(this);
          if (i >= 2 && i <= 5) {
            control.changedPoint = i;
            const m = d3.select(n[i]).node();

            d.dimLevel = control.x.invert(event.x);
            d.time = control.y.invert(event.y);
            if (i === 2) {
              if (d.dimLevel < 10 || d.dimLevel > 10) {
                d.dimLevel = 10;
              }
              if (d.time > 100) {
                d.time = 100;
              }
              if (d.time < 1) {
                d.time = 1;
              }
              const drag3Pt = d3.select(n[3]).node();
              d3.select(drag3Pt)
                .attr("cx", control.x(100))
                .attr("cy", control.y(d.time));
              control.focus.select("path").attr("d", control.area);
              control.focus.select("path").attr("d", control.line);
              control.data[3].dimLevel = 100;
              control.data[3].time = d.time;
            }
            if (i === 3) {
              if (d.dimLevel < 100 || d.dimLevel > 100) {
                d.dimLevel = 100;
              }
              if (d.time > 100) {
                d.time = 100;
              }
              if (d.time < 1) {
                d.time = 1;
              }
              const drag2Pt = d3.select(n[2]).node();
              d3.select(drag2Pt)
                .attr("cx", control.x(10))
                .attr("cy", control.y(d.time));
              control.focus.select("path").attr("d", control.area);
              control.focus.select("path").attr("d", control.line);
              control.data[2].dimLevel = 10;
              control.data[2].time = d.time;
            }
            if (i === 4) {
              if (d.dimLevel < 100) {
                d.dimLevel = 100;
              }
              if (d.dimLevel > 115) {
                d.dimLevel = 115;
              }
              if (d.time > 100) {
                d.time = 100;
              }
              if (d.time < 1) {
                d.time = 1;
              }

              const drag5Pt = d3.select(n[5]).node();
              d3.select(drag5Pt)
                .attr("cx", control.x(215))
                .attr("cy", control.y(d.time));
              control.focus.select("path").attr("d", control.area);
              control.focus.select("path").attr("d", control.line);
              control.data[5].dimLevel = 215;
              control.data[5].time = d.time;
            }
            if (i === 5) {
              if (d.dimLevel < 215) {
                d.dimLevel = 215;
              }
              if (d.dimLevel > 215) {
                d.dimLevel = 215;
              }
              if (d.time > 100) {
                d.time = 100;
              }
              if (d.time < 1) {
                d.time = 1;
              }

              const drag4Pt = d3.select(n[4]).node();
              d3.select(drag4Pt)
                .attr("cx", control.x(control.data[4].dimLevel))
                .attr("cy", control.y(d.time));
              control.focus.select("path").attr("d", control.area);
              control.focus.select("path").attr("d", control.line);
              control.data[4].time = d.time;
            }
            d3.select(m)
              .raise()
              .attr("cx", control.x(d.dimLevel))
              .attr("cy", control.y(d.time));
            control.focus.select("path").attr("d", control.area);
          }
        })
        .on("end", this.dragended.bind(this))
    );
  }

  dragstarted() {
    return this;
  }
  public dragended(d) {
    var newArrayData = [];

    if (this.changedPoint == 2) {
      let logA = this.data[2].time;
      let powValue = Math.log(Math.round(logA)) / Math.log(10);
      let finalNormalValue = (powValue + 1) * (253 / 3) + 1;
      newArrayData["NormalLevelValue"] = Math.round(finalNormalValue);
    } else {
      let logA = this.data[3].time;
      let powValue = Math.log(Math.round(logA)) / Math.log(10);
      let finalNormalValue = (powValue + 1) * (253 / 3) + 1;
      newArrayData["NormalLevelValue"] = Math.round(finalNormalValue);
    }

    if (this.changedPoint == 3) {
      let logA = this.data[3].time;
      let powValue = Math.log(Math.round(logA)) / Math.log(10);
      let finalNormalValue = (powValue + 1) * (253 / 3) + 1;
      newArrayData["NormalLevelValue"] = Math.round(finalNormalValue);
    } else {
      let logA = this.data[2].time;
      let powValue = Math.log(Math.round(logA)) / Math.log(10);
      let finalNormalValue = (powValue + 1) * (253 / 3) + 1;
      newArrayData["NormalLevelValue"] = Math.round(finalNormalValue);
    }

    if (this.changedPoint == 4) {
      let logA = this.data[4].time;
      let powValue = Math.log(Math.round(logA)) / Math.log(10);
      let finalBckLevel = (powValue + 1) * (253 / 3) + 1;
      newArrayData["BackgroundLevelValue"] = Math.round(finalBckLevel);
    } else {
      let logA = this.data[5].time;
      let powValue = Math.log(Math.round(logA)) / Math.log(10);
      let finalBckLevel = (powValue + 1) * (253 / 3) + 1;
      newArrayData["BackgroundLevelValue"] = Math.round(finalBckLevel);
    }

    if (this.changedPoint == 5) {
      let logA = this.data[5].time;
      let powValue = Math.log(Math.round(logA)) / Math.log(10);
      let finalBckLevel = (powValue + 1) * (253 / 3) + 1;
      newArrayData["BackgroundLevelValue"] = Math.round(finalBckLevel);
    } else {
      let logA = this.data[4].time;
      let powValue = Math.log(Math.round(logA)) / Math.log(10);
      let finalBckLevel = (powValue + 1) * (253 / 3) + 1;
      newArrayData["BackgroundLevelValue"] = Math.round(finalBckLevel);
    }

    newArrayData["DelayTimeValue"] = this.newData["DelayTimeValue"];
    newArrayData["FadeTimeIndexValue"] = Math.round(
      this.data[4].dimLevel - 100
    );
    newArrayData["ActivationTimeValue"] =
      this.newData["ActivationTimeValue"] === ""
        ? 0
        : this.newData["ActivationTimeValue"];
    newArrayData["ProlongTimeValue"] = this.newData["ProlongTimeValue"];
    newArrayData["infiniteDisabled"] = this.newData["infiniteDisabled"];
    newArrayData["FadeTimeIndexMaximum"] = this.newData["FadeTimeIndexMaximum"];
    newArrayData["BackgroundLevelMaximum"] =
      this.newData["BackgroundLevelMaximum"];
    newArrayData["NormalLevelMaximum"] = this.newData["NormalLevelMaximum"];
    newArrayData["CorridorEnabled"] = this.newData["CorridorEnabled"];
    this.apiService.sendCorridorGraphdata(newArrayData);
  }
  convertData(data) {
    var y = (data.NormalLevelValue - 1) / (253 / 3) - 1;
    let NmValue = Math.round(Math.pow(10, y));
    let fedTime = Math.round(parseInt(data.FadeTimeIndexValue) + 100);
    var x = (data.BackgroundLevelValue - 1) / (253 / 3) - 1;
    let backgrndValue = Math.round(Math.pow(10, x));
    this.data = [
      { dimLevel: 0, time: 0 },
      { dimLevel: 10, time: 0 },
      { dimLevel: 10, time: NmValue },
      { dimLevel: 100, time: NmValue },
      { dimLevel: fedTime, time: backgrndValue },
      { dimLevel: 215, time: backgrndValue },
      { dimLevel: 215, time: 0 },
    ];
  }
}
