import { Component, OnInit } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ConfigureDeviceModalInputErrorComponent } from "../configure-device-modal-input-error/configure-device-modal-input-error.component";
import { ConfigureDeviceModalInputErrorPositiveComponent } from "../configure-device-modal-input-error-positive/configure-device-modal-input-error-positive.component";
import { ReadDeviceMultiDriverComponent } from "../read-device-multi-driver/read-device-multi-driver.component";
import { ApiService } from '../core-module/services/api.service';
import { Router } from "@angular/router";
@Component({
  selector: "app-configure-device-modal-input",
  templateUrl: "./configure-device-modal-input.component.html",
  styleUrls: ["./configure-device-modal-input.component.scss"],
})
export class ConfigureDeviceModalInputComponent implements OnInit {

  boxSize:any=24;
  ngModelval: any=[];
  constructor(
    public activemodal: NgbActiveModal,
    private modalService: NgbModal,
    private apiService: ApiService,
    public route: Router,
  ) {}

  
  ngOnInit() {

    this.ngModelval["boxSize"] = this.boxSize=24;
    this.apiService.sendMultiBoxSizeUser(this.boxSize);
  }
  onNext() {
    //negative scenario
    // const modalRef = this.modalService.open(
    //   ConfigureDeviceModalInputErrorComponent,
    //   {
    //     windowClass: "select-feature-modal",
    //     backdropClass: "customBackdrop",
    //   }
    // );
    //positive scenario
    // const modalRef = this.modalService.open(
    //   ConfigureDeviceModalInputErrorPositiveComponent,
    //   {
    //     windowClass: "select-feature-modal",
    //     backdropClass: "customBackdrop",
    //   }
    // );
    this.apiService.sendShowNextSuccessBtnMulti(false);
    const modalRef = this.modalService.open(ReadDeviceMultiDriverComponent, {
      windowClass: "select-feature-modal",
      backdropClass: "customBackdrop",
    });
  }
  onCancel(){
    localStorage.setItem("reading", JSON.stringify(false));
    this.route.navigate(["/featureLanding"]);
  }
  numberOfDevices(value){

    this.boxSize = value;

    this.ngModelval["boxSize"] = this.boxSize;
    this.apiService.sendMultiBoxSizeUser(this.boxSize);
  }
  
  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
}
