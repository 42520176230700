import {
  Component,
  OnInit,
  ViewEncapsulation,
  OnDestroy,
  ChangeDetectorRef,
  HostListener,
  ViewChild,
  ElementRef,
  Input,
} from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  Validators,
} from "@angular/forms";
import { ApiService } from "../../../core-module/services/api.service";
import { NgbTabChangeEvent } from "@ng-bootstrap/ng-bootstrap";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { restore } from "../../../../assets/json/restoreTooltip.en";
import { isNullOrUndefined } from "util";

@Component({
  selector: 'app-clo-lite',
  templateUrl: './clo-lite.component.html',
  styleUrls: ['./clo-lite.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CloLiteComponent implements OnInit, OnDestroy {
  addForm: FormGroup;
  rowLength = 0;
  registerForm: FormGroup;
  submitted = false;
  activeIdString: any = "1";
  fewFieldValuePresent = false;

  dataToShow = [];
  unsubscribe = new Subject<any>();
  isTableValuesChanged = false;
  tableValueSubmit = false;
  isNumericCreatedRows = false;
  isGraphUpdated = false;
  disableGraph = false;
  defaultColor = true;
  disableAddBtn = false;
  unsubscribeNumeric = new Subject<any>();
  menuState;
  menuPosition;
  disableAddAboveBtn = false;
  disableAddBelowBtn = false;
  disableNumericClear = true;
  unsubscribeGraphType = new Subject<any>();
  enableChecked = false;
  showCloLite;
  subscription;
  restoreText = restore;

  @ViewChild("contextMenu", { static: false }) contextMenu: ElementRef;

  graphRepresentationType = "";
  afterClearField = false;
  fileResponse: any = {};
  cloLIteDetail: any;
  cloLiteEnable: boolean = false;
  @Input() public cloLiteSchema;
  cloLiteCreatFile: boolean = false;
  constructor(
    private fb: FormBuilder,
    private apiService: ApiService,
  ) {
    this.addForm = this.fb.group({});
  }

  ngOnInit() {
    this.initForm();
    localStorage.setItem("cloPointsStepValue", "0");
    this.apiService.getCloLiteDefaultValue.subscribe((response) => {
      if (response == "valid") {
        this.disableGraph = false;
        this.resetValues();
      }
    });

    let unSelectedFeat = Array(
      JSON.parse(localStorage.getItem("UnSelectedFeatConfig"))
    );
    let isConfigEdited = false;
    let featIndex: number = 0;
    if (unSelectedFeat[0] != null && unSelectedFeat[0].length > 0) {
      unSelectedFeat[0].forEach((element) => {
        if (
          element["featureName"].toString().toUpperCase() === "CLOLITE"
        ) {
          this.fileResponse = {};
          let CLOUnSelected: any = element["featureParameters"][0];
          let clonewEditedData = {
            resp: {
              CLOLite: CLOUnSelected,
            },
          };
          isConfigEdited=true;
          this.fileResponse = clonewEditedData;
          unSelectedFeat[0].splice(featIndex, 1);
          localStorage.setItem(
            "UnSelectedFeatConfig",
            JSON.stringify(unSelectedFeat[0])
          );
        } else {
          featIndex++;
        }
      });
    }

    if (isConfigEdited) {

    } else {
      this.fileResponse = JSON.parse(localStorage.getItem("configurationData"));
      let saveData = JSON.parse(localStorage.getItem("configurationData"));
      if (
        saveData &&
        saveData["resp"] &&
        (saveData["resp"]["CLOLite"])
      ) {
        this.fileResponse = saveData;
      }
    }

    this.loadCloLIteDetails();


    this.dataToShow = [];
    this.defaultColor = true;
    this.apiService.sendGraphIsEditedData([{ hour: 0, power: 100 }]);
    this.apiService.getGraphRepresentation
      .pipe(takeUntil(this.unsubscribeGraphType))
      .subscribe((data) => {
        if (data != "") {
          this.graphRepresentationType = data;
        }
      });
    this.subscription = this.apiService.getCloLiteHead.subscribe((data) => {
      if (data === "CloLite" || data === "Clolite") {
        this.showCloLite = false;
      }
    });
    this.apiService.getChangedGraphData.subscribe((data) => {
      //change rows when drag graph changes -start here
      if (this.registerForm.valid) {
        this.isNumericCreatedRows = true;
      }
      if (!this.showCloLite) {
        if (data.length) {
          this.dataToShow = [];
          data.forEach((content, index) => {
            this.dataToShow.push({
              hour: Math.round(content.hour),
              power: Math.round(content.power),
            });
          });
          this.f.cloHour.setValue(this.dataToShow[1].hour);
          this.f.initialOutput.setValue(this.dataToShow[0].power);
          this.updateCloLiteValues(true);
        }
      }
    }); //change rows when drag graph changes -ends here
    this.checkFinalDefaultTable();
  }

  checkClearFields() {
    if (this.registerForm.controls["initialOutput"].value === "" && this.registerForm.controls["cloHour"].value === "") {
      this.disableNumericClear = true;
    } else {
      this.disableNumericClear = false;
    }
  };

  resetValues() {
    this.cloLIteDetail.requiredProperties.forEach(element => {
      if (element === "CloLiteEnabled" && !isNullOrUndefined(this.cloLIteDetail.properties['CloLiteEnabled']['default'])) {
        this.enableChecked = this.cloLIteDetail.properties['CloLiteEnabled']['default'];
      } else if (element === "CloLiteInitialPowerLevel" && !isNullOrUndefined(this.cloLIteDetail.properties['CloLiteInitialPowerLevel']['default'])) {
        this.f.initialOutput.setValue(this.cloLIteDetail.properties['CloLiteInitialPowerLevel']['default']);
      } else if (element === "CloLiteMaximumWorkingHours" && !isNullOrUndefined(this.cloLIteDetail.properties['CloLiteMaximumWorkingHours']['default'])) {
        this.f.cloHour.setValue(this.cloLIteDetail.properties['CloLiteMaximumWorkingHours']['default']);
      }
    });
    this.afterClearField = false;
    this.updateCloLiteValues(false);
    this.disableGraph = !this.cloLIteDetail.properties['CloLiteEnabled']['default'];
  };

  resetDefaultStatusCheck() {
    if (this.enableChecked == this.cloLIteDetail.properties['CloLiteEnabled']['default'] && this.registerForm.controls["initialOutput"].value == this.cloLIteDetail.properties['CloLiteInitialPowerLevel']['default'] && this.registerForm.controls["cloHour"].value == this.cloLIteDetail.properties['CloLiteMaximumWorkingHours']['default']) {
      this.apiService.setCloLiteDefaultColor(true);
    } else {
      this.apiService.setCloLiteDefaultColor(false);
    }
  }

  loadCloLIteDetails() {
    if (JSON.parse(localStorage.getItem("configurationData"))) {
      let configureData = this.fileResponse;
      this.cloLIteDetail = this.cloLiteSchema;
      this.setRegisterFormValues();
      this.cloLIteDetail.requiredProperties.forEach(element => {
        if (element === "CloLiteEnabled" && !isNullOrUndefined(this.cloLIteDetail.properties['CloLiteEnabled']['default'])) {
          this.enableChecked = !isNullOrUndefined(configureData.resp && configureData.resp.CLOLite) ? configureData.resp.CLOLite['CloLiteEnabled'] : this.cloLIteDetail.properties['CloLiteEnabled']['default'];
          this.disableGraph = !this.enableChecked;
        } else if (element === "CloLiteInitialPowerLevel" && !isNullOrUndefined(this.cloLIteDetail.properties['CloLiteInitialPowerLevel']['default'])) {
          this.registerForm.controls["initialOutput"].setValue(!isNullOrUndefined(configureData.resp && configureData.resp.CLOLite) ? configureData.resp.CLOLite['CloLiteInitialPowerLevel'] : this.cloLIteDetail.properties['CloLiteInitialPowerLevel']['default']);
        } else if (element === "CloLiteMaximumWorkingHours" && !isNullOrUndefined(this.cloLIteDetail.properties['CloLiteMaximumWorkingHours']['default'])) {
          this.registerForm.controls["cloHour"].setValue(!isNullOrUndefined(configureData.resp && configureData.resp.CLOLite) ? configureData.resp.CLOLite['CloLiteMaximumWorkingHours'] : this.cloLIteDetail.properties['CloLiteMaximumWorkingHours']['default']);
        }
      });
      this.updateCloLiteValues(false);
    }
  };

  initForm() {
    this.registerForm = this.fb.group({
      cloHour: [
        "",
        [
          Validators.required,
          Validators.pattern("^[0-9]*$"),
        ],
      ],
      initialOutput: [
        "",
        [
          Validators.required,
          Validators.pattern("^[0-9]*$"),
        ],
      ],
    });
  }

  setRegisterFormValues() {
    this.registerForm.controls["initialOutput"].setValidators([
      Validators.required,
      Validators.pattern("^[0-9]*$"),
      Validators.min(this.cloLIteDetail.properties['CloLiteInitialPowerLevel']['minimum']),
      Validators.max(this.cloLIteDetail.properties['CloLiteInitialPowerLevel']['maximum']),
    ]);
    this.registerForm.controls["cloHour"].setValidators([
      Validators.required,
      Validators.pattern("^[0-9]*$"),
      Validators.min(this.cloLIteDetail.properties['CloLiteMaximumWorkingHours']['minimum']),
      Validators.max(this.cloLIteDetail.properties['CloLiteMaximumWorkingHours']['maximum']),
    ]);
    this.registerForm.controls["initialOutput"].updateValueAndValidity();
    this.registerForm.controls["cloHour"].updateValueAndValidity();
    this.disableNumericClear = false;
    this.submitted = true
  }

  updateCloLiteValues(flagGraph) {
    let cloLite = {
      CloLiteEnabled: this.enableChecked,
      CloLiteInitialPowerLevel: this.registerForm.controls["initialOutput"].errors ? this.cloLIteDetail.properties['CloLiteInitialPowerLevel']['default'] : Number(this.f.initialOutput.value),
      CloLiteMaximumWorkingHours: this.registerForm.controls["cloHour"].errors ? this.cloLIteDetail.properties['CloLiteMaximumWorkingHours']['default'] : Number(this.f.cloHour.value)
    };
    this.setConfigurationData(cloLite, 'CLOLite');
    this.apiService.sendCloLitedata(cloLite);
    this.sendValuesToGraph(flagGraph);
    this.checkCloLiteCreatFileError();
  }

  sendValuesToGraph(flagGraph) {
    let data;
    if (this.registerForm.valid) {
      if(flagGraph){
        data = [{
          hour: 0,
          power: this.dataToShow[0].power
        },
        {
          hour: this.registerForm.controls["cloHour"].value,
          power: this.dataToShow[1].power
        }
        ];
      }
      else{
      data = [{
        hour: 0,
        power: this.registerForm.controls["initialOutput"].value
      },
      {
        hour: this.registerForm.controls["cloHour"].value,
        power: this.cloLIteDetail.properties['CloLiteInitialPowerLevel']['maximum']
      }
      ];
    }
      this.apiService.sendGraphData(
        this.graphRepresentationType,
        data
      );
    } else {
      if(!flagGraph){
        data = [{
          hour: 0,
          power: this.registerForm.controls["initialOutput"].value
        },
        {
          hour: this.registerForm.controls["cloHour"].value,
          power: this.cloLIteDetail.properties['CloLiteInitialPowerLevel']['maximum']
        }
        ];
        this.apiService.sendGraphData(
          this.graphRepresentationType,
          data
        );
      }

    }

    this.apiService.setCloLiteEnableGraph(this.enableChecked);
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  inputValueChanged($event) {
    this.afterClearField = false;
    this.setRegisterFormValues();
    this.updateCloLiteValues(false);
    this.checkClearFields();
    this.resetDefaultStatusCheck();
    this.checkCloLiteCreatFileError();
    this.fewFieldValuePresent = false;
    if ($event.key !== "Tab") {
      this.submitted = true;
    }
    if ($event.key === "Backspace" || $event.key === "Delete") {
      this.submitted = true;
      if (this.fewFieldValuePresent === false) {
        this.apiService.sendGraphValidData("valid");
      }
    }
  }

  setConfigurationData(value, keyVal) {
    let cloLiteObj = {};
    var data = {};
    var addNew = {
      [keyVal]: {},
    };
    data = JSON.parse(localStorage.getItem("configurationData"));
    if (data && !isNullOrUndefined(data["resp"])) {
      cloLiteObj = Object.assign({}, value);
      data["resp"][keyVal] = cloLiteObj;
    } else {
      data["resp"] = addNew;
      cloLiteObj = Object.assign({}, value);
      data["resp"][keyVal] = cloLiteObj;
    }
    localStorage.setItem("configurationData", JSON.stringify(data));
    this.resetDefaultStatusCheck();
  }

  checkCloLiteCreatFileError() {
    if (this.registerForm.controls["initialOutput"].errors || this.registerForm.controls["cloHour"].errors) {
      this.cloLiteCreatFile = true;
    } else {
      this.cloLiteCreatFile = false;
    }
    this.apiService.setCLOliteInputError.next(this.cloLiteCreatFile);
  }

  checkFinalDefaultTable() {
    if (this.enableChecked === true) {
      this.defaultColor = false;
    }
  }


  tabChanged($event: NgbTabChangeEvent) {
    if ($event.nextId === "1") {
      this.isNumericCreatedRows = false;
    }
  }

  get f() {
    return this.registerForm.controls;
  }


  enableGraph(value) {
    this.enableChecked = value;
    this.updateCloLiteValues(false);
    this.resetDefaultStatusCheck();
    this.checkClearFields();
    this.disableGraph = !value;
    this.defaultColor = !value;
    if (this.enableChecked) {
      this.defaultColor = false;
    }
    this.apiService.setCloLiteEnableGraph(value);
  }




  @HostListener("document:click", ["$event"])
  public documentClick(event: Event): void {
    this.menuState = false;
  }
  @HostListener("document:contextmenu", ["$event"])
  public documentRClick(event: Event): void {
    this.menuState = false;
  }


  clearNumericFields() {
    this.disableNumericClear = true;
    for (let key of Object.keys(this.f)) {
      this.f[key].setValue("");
    };
    this.registerForm.controls["initialOutput"].setErrors({ require: true });
    this.registerForm.controls["cloHour"].setErrors({ require: true });
    this.submitted = true;
    this.updateCloLiteValues(false);
    this.checkClearFields();
    this.checkCloLiteCreatFileError();
    this.unsubscribe.next();
    this.isNumericCreatedRows = false;
    this.dataToShow = [];
    this.dataToShow.push({ hour: 0, power: 100 });
    this.apiService.sendGraphIsEditedData([{ hour: 0, power: 100 }]);
    this.apiService.sendGraphData(
      this.graphRepresentationType,
      this.dataToShow
    );
    this.tableValueSubmit = false;
    this.isTableValuesChanged = false;
  }
  ngOnDestroy() {
    this.unsubscribeGraphType.next();
    this.unsubscribeNumeric.next();
    this.unsubscribe.next();
  }

}
