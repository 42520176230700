import {
  Component,
  OnInit,
  Input,
  DoCheck,
  EventEmitter,
  ChangeDetectorRef,
  ViewRef,
  OnDestroy,
} from "@angular/core";
import { isNullOrUndefined } from "util";
import { FieldType } from "@ngx-formly/core";
import { Options } from "ng5-slider";
import { ApiService } from "src/app/core-module/services/api.service";
import { restore } from "./../../../../assets/json/restoreTooltip.en";

@Component({
  selector: "app-slider",
  templateUrl: "./slider.component.html",
  styleUrls: ["./slider.component.scss"],
})
export class SliderComponent
  extends FieldType
  implements OnInit, DoCheck, OnDestroy
{
  @Input() field;
  valOptions: Options = {};
  floorValue;
  ceilValue;
  defaultValue;
  isResetDisabled;
  manualRefresh: EventEmitter<void> = new EventEmitter<void>();
  sliderValue;
  textboxValue;
  defaultColor = true;
  deviceName;
  isHidden;
  isAocEnabled;

  enableRSet;

  aocSliderValue: boolean;
  quickConfigvalue;
  quickConfigCustomCSS;
  setQuickConfig;
  isExternalRset = false;
  restoreText = restore;
  ngModelval: any = {};
  properties: any;
  inputError: any = {};
  aocCreatFile;
  isHiddenEnable;
  isEnable;
  isAocDisable = false;

  constructor(private apiService: ApiService, private ref: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {
    this.apiService.getAocDefaultValue.subscribe((response) => {
      if (response) {
        this.resetSlider();
      }
    });
    this.apiService.aocSliderCick.subscribe((data) => {
      this.aocSliderValue = data["sliderClick"];
    });
    this.properties = this.field.properties;
    this.defaultValue = this.field.properties.AocCurrent.default;
    this.floorValue = this.field.properties.AocCurrent.minimum;
    this.ceilValue = this.field.properties.AocCurrent.maximum;
    this.valOptions = {
      floor: this.floorValue,
      ceil: this.ceilValue,
    };
    this.ngModelval["AocCurrent"] = this.textboxValue =
      this.field.properties.AocCurrent.default;
    if (
      this.field.properties.AocEnabled.aocEnabledLabel === 0 ||
      this.field.properties.AocEnabled.aocEnabledLabel === "0" ||
      this.field.properties.AocEnabled.aocEnabledLabel === "1" ||
      this.field.properties.AocEnabled.aocEnabledLabel === 1
    ) {
      if (
        this.field.properties.AocEnabled.aocEnabledLabel == 1 ||
        this.field.properties.AocEnabled.aocEnabledLabel === "1"
      ) {
        this.isHidden = true;
        //if AocEnabled is true isExternalRset is false and vice-versa
        this.ngModelval["AocEnabled"] =
          this.field.properties.AocEnabled.default;
        this.isAocDisable = this.isExternalRset =
          !this.field.properties.AocEnabled.default;
      } else {
        this.isHiddenEnable = true;
        this.isAocDisable = !this.field.properties.AocEnabled.default;
        this.ngModelval["AocEnabled"] =
          this.field.properties.AocEnabled.default;
      }
    } else {
      this.isAocDisable = !this.field.properties.AocEnabled.const;
    }
    let aaa = JSON.parse(localStorage.getItem("saveSetData"));
    var fileResponse = JSON.parse(localStorage.getItem("configurationData"));

    let unSelectedFeat = Array(
      JSON.parse(localStorage.getItem("UnSelectedFeatConfig"))
    );

    let isConfigEdited = false;
    let featIndex: number = 0;
    if (unSelectedFeat[0] != null) {
      unSelectedFeat[0].forEach((element) => {
        if (element["featureName"] === "AOC") {
          isConfigEdited = true;
          this.ngModelval["AocCurrent"] = this.textboxValue =
            element["featureParameters"][0]["AocCurrent"];
          if (
            this.isHidden === false ||
            this.isHidden === true ||
            this.isHiddenEnable == false ||
            this.isHiddenEnable == true
          ) {
            if (this.field.properties.AocEnabled.aocEnabledLabel == 1) {
              this.isExternalRset = element["featureParameters"][0][
                "AocEnabled"
              ]
                ? !element["featureParameters"][0]["AocEnabled"]
                : !this.field.properties.AocEnabled.default;
              this.isEnable = false;
              this.ngModelval["AocEnabled"] = this.isExternalRset;
              this.rSetChanged(!this.isExternalRset);
            } else {
              this.isExternalRset = false;
              this.isEnable = element["featureParameters"][0]["AocEnabled"]
                ? element["featureParameters"][0]["AocEnabled"]
                : this.field.properties.AocEnabled.default;
              this.ngModelval["AocEnabled"] = this.isEnable;
              this.enableChanged(this.isEnable);
            }
          } else {
            this.isExternalRset = false;
            this.isEnable = false;
            this.isAocDisable = false;
            delete this.ngModelval["AocEnabled"];
          }
          unSelectedFeat[0].splice(featIndex, 1);
          localStorage.setItem(
            "UnSelectedFeatConfig",
            JSON.stringify(unSelectedFeat[0])
          );
        } else {
          featIndex++;
        }
      });
    }
    if (isConfigEdited) {
    } else if (
      fileResponse &&
      fileResponse["resp"] &&
      fileResponse["resp"]["AOC"]
    ) {
      // AOC after load Feature File
      this.ngModelval["AocCurrent"] = this.textboxValue =
        fileResponse["resp"]["AOC"]["AocCurrent"];
      if (
        this.isHidden === false ||
        this.isHidden === true ||
        this.isHiddenEnable == false ||
        this.isHiddenEnable == true
      ) {
        if (this.field.properties.AocEnabled.aocEnabledLabel == 1) {
          if (
            !isNullOrUndefined(fileResponse["resp"]) &&
            !isNullOrUndefined(fileResponse["resp"]["AOC"]) &&
            !isNullOrUndefined(fileResponse["resp"]["AOC"]["AocEnabled"])
          ) {
            if (fileResponse["resp"]["AOC"]["AocEnabled"] === true) {
              this.isExternalRset = false;
            } else {
              this.isExternalRset = true;
            }
          } else {
            this.isExternalRset = !this.field.properties.AocEnabled.default;
          }
          this.isEnable = false;
          this.ngModelval["AocEnabled"] = this.isExternalRset;
          this.rSetChanged(this.isExternalRset);
        } else {
          this.isExternalRset = false;
          this.isEnable = fileResponse["resp"]["AOC"]["AocEnabled"]
            ? fileResponse["resp"]["AOC"]["AocEnabled"]
            : this.field.properties.AocEnabled.default;
          this.ngModelval["AocEnabled"] = this.isEnable;
          this.enableChanged(this.isEnable);
        }
      } else {
        this.isExternalRset = false;
        this.isEnable = false;
        this.isAocDisable = false;
        delete this.ngModelval["AocEnabled"];
      }
      let aocResp = JSON.parse(localStorage.getItem("configurationData"));
      delete aocResp["resp"]["AOC"];
      localStorage.setItem("configurationData", JSON.stringify(aocResp));
    } else if (aaa && aaa["resp"]["AOC"]) {
      // AOC after Refreshing the Page
      if (
        aaa["resp"]["AOC"]["AocCurrent"] < this.valOptions.floor ||
        aaa["resp"]["AOC"]["AocCurrent"] > this.valOptions.ceil
      ) {
        this.defaultValue = this.field.properties.AocCurrent.default;
      } else {
        fileResponse = aaa;
        this.ngModelval["AocCurrent"] = this.textboxValue =
          aaa["resp"]["AOC"]["AocCurrent"];
        if (
          this.isHidden === false ||
          this.isHidden === true ||
          this.isHiddenEnable == false ||
          this.isHiddenEnable == true
        ) {
          if (this.field.properties.AocEnabled.aocEnabledLabel == 1) {
            this.isExternalRset = fileResponse["resp"]["AOC"]["AocEnabled"]
              ? !fileResponse["resp"]["AOC"]["AocEnabled"]
              : !this.field.properties.AocEnabled.default;
            this.isEnable = false;
            this.ngModelval["AocEnabled"] = !this.isExternalRset;
            this.rSetChanged(this.isExternalRset);
          } else {
            this.isExternalRset = false;
            this.isEnable = fileResponse["resp"]["AOC"]["AocEnabled"]
              ? fileResponse["resp"]["AOC"]["AocEnabled"]
              : this.field.properties.AocEnabled.default;
            this.ngModelval["AocEnabled"] = this.isEnable;
            this.enableChanged(this.isEnable);
          }
        } else {
          this.isExternalRset = false;
          this.isEnable = false;
          this.isAocDisable = false;
          delete this.ngModelval["AocEnabled"];
        }
      }
    }
    this.apiService.getquickConfig.subscribe((data) => {
      // this.quickConfigvalue = data['quickConfig'];
      if (data === "quickConfig") {
        this.quickConfigvalue = true;
        this.quickConfigCustomCSS = "quick-custom-inputbox textBoxUnit";
        this.setQuickConfig = "quickConfig";
      } else if (data === "standard") {
        this.quickConfigvalue = false;
        this.quickConfigCustomCSS = "custom-inputbox";
        // this.apiService.aocValueChange(this.textboxValue);
        this.setQuickConfig = "standard";
        // this.apiService.aocValueChange(this.textboxValue);
        // this.formControl.setValue(this.sliderValue);
      }
      setTimeout(() => {
        if (this.ref && !(this.ref as ViewRef).destroyed) {
          this.ref.detectChanges();
        }
      });
    });
    this.checkDefault();
    this.apiService.aocValueChange(this.ngModelval);
    this.checkValidValue();
  }
  ngDoCheck() {
    if (this.aocSliderValue) {
      this.manualRefresh.emit();
    }
  }

  checkDefault() {
    var newtype;
    this.defaultColor = true;
    let schemaDefault;
    for (var type in this.ngModelval) {
      newtype = type.replace("Val", "");
      schemaDefault = this.properties[newtype]["default"];
      if (this.field.properties.AocEnabled.aocEnabledLabel == 2) {
        if (newtype === "AocEnabled") {
          schemaDefault = this.properties[newtype]["const"];
        }
      }
      if (this.ngModelval[type] != schemaDefault) {
        this.defaultColor = false;
      }
    }
    this.apiService.setAocDefaultColor(this.defaultColor);
  }
  sliderEvent(val) {}

  aocValuechange(value) {
    this.inputError["AocCurrent"] = false;
    if (value >= this.floorValue && this.inputError["AocCurrent"] === false) {
      this.ngModelval["AocCurrent"] = value;
      this.textboxValue = value;
      this.checkDefault();
      this.apiService.aocValueChange(this.ngModelval);
      this.apiService.setFormInputValidation(false);
    }
  }

  checkValidValue() {
    let value = parseInt(this.ngModelval["AocCurrent"]);
    if (isNaN(value)) {
      value = 0;
    }
    if (value < this.floorValue || value > this.ceilValue) {
      this.inputError["AocCurrent"] = true;
    } else {
      this.inputError["AocCurrent"] = false;
    }

    for (var type in this.inputError) {
      if (this.inputError[type]) {
        this.aocCreatFile = true;
      }
    }
    this.apiService.setFormInputValidation(this.aocCreatFile);
  }
  enableAocSlider($event) {
    this.isAocEnabled = !this.isAocEnabled;
  }
  valueChanged(value) {
    value = parseInt(value);
    if (isNaN(value)) {
      value = 0;
    }
    this.defaultColor = false;
    this.aocCreatFile = false;

    if (value < this.floorValue || value > this.ceilValue) {
      this.inputError["AocCurrent"] = true;
      this.ngModelval["AocCurrent"] = value;
    } else {
      this.inputError["AocCurrent"] = false;
      this.textboxValue = value;
      this.ngModelval["AocCurrent"] = value;
    }

    this.checkDefault();
    for (var type in this.inputError) {
      if (this.inputError[type]) {
        this.aocCreatFile = true;
      }
    }
    this.apiService.setAocDefaultColor(this.defaultColor);
    this.apiService.setFormInputValidation(this.aocCreatFile);
    this.apiService.aocValueChange(this.ngModelval);
  }

  resetSlider() {
    this.ngModelval["AocCurrent"] = this.textboxValue =
      this.field.properties.AocCurrent.default;
    this.inputError["AocCurrent"] = false;
    if (
      this.field.properties.AocEnabled.aocEnabledLabel === 0 ||
      this.field.properties.AocEnabled.aocEnabledLabel === "0" ||
      this.field.properties.AocEnabled.aocEnabledLabel === "1" ||
      this.field.properties.AocEnabled.aocEnabledLabel === 1
    ) {
      if (
        this.field.properties.AocEnabled.aocEnabledLabel == "1" ||
        this.field.properties.AocEnabled.aocEnabledLabel == 1
      ) {
        this.ngModelval["AocEnabled"] =
          this.field.properties.AocEnabled.default;
        this.isExternalRset = this.ngModelval["AocEnabled"];
        this.isAocDisable = this.field.properties.AocEnabled.default;
      } else {
        this.ngModelval["AocEnabled"] =
          this.field.properties.AocEnabled.default;
        this.isEnable = this.ngModelval["AocEnabled"];
        this.isAocDisable = !this.field.properties.AocEnabled.default;
      }
    } else {
      this.isAocDisable = !this.field.properties.AocEnabled.const;
    }

    this.defaultColor = true;
    this.apiService.aocValueChange(this.ngModelval);
    this.apiService.setAocDefaultColor(this.defaultColor);
  }
  rSetChanged(checked) {
    this.enableRSet = checked;
    this.isExternalRset = checked;
    this.isAocDisable = checked;
    this.ngModelval["AocEnabled"] = !checked;
    if (this.enableRSet) {
      this.ngModelval["AocEnabled"] = !this.enableRSet;
    }
    this.checkDefault();
    this.apiService.aocValueChange(this.ngModelval);
    this.apiService.setAocDefaultColor(this.defaultColor);
  }

  enableChanged(checked) {
    this.enableRSet = checked;
    this.isEnable = checked;
    this.isAocDisable = !checked;
    this.ngModelval["AocEnabled"] = checked;
    if (this.enableRSet) {
      this.ngModelval["AocEnabled"] = checked;
    }
    this.checkDefault();
    this.apiService.aocValueChange(this.ngModelval);
    this.apiService.setAocDefaultColor(this.defaultColor);
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  ngOnDestroy() {
    this.apiService.aocValueChange("");
    //this.apiService.sendQuickConfig('');
    this.textboxValue = null;
    this.sliderValue = null;
    this.defaultValue = null;
  }
}
