import { Component, OnInit } from "@angular/core";
import * as d3Scale from "d3-scale";
import * as d3Shape from "d3-shape";
import * as d3Array from "d3-array";
import * as d3Axis from "d3-axis";
import * as d3 from "d3";
import { ApiService } from "src/app/core-module/services/api.service";
import { isNullOrUndefined } from "util";
@Component({
  selector: "app-samplegraphdynanontime",
  templateUrl: "./samplegraphdynanontime.component.html",
  styleUrls: ["./samplegraphdynanontime.component.scss"],
})
export class SamplegraphdynanontimeComponent implements OnInit {
  inputXRange = [0, 24];

  inputYRange = [0, 100];

  data1: any[] = [
    { dyanXTime: 0, dyanYOutput: 40 },
    { dyanXTime: 15, dyanYOutput: 40 },
    { dyanXTime: 15, dyanYOutput: 100 },
    { dyanXTime: 16, dyanYOutput: 100 },
    { dyanXTime: 16, dyanYOutput: 10 },
    { dyanXTime: 17, dyanYOutput: 10 },
    { dyanXTime: 17, dyanYOutput: 80 },
    { dyanXTime: 18, dyanYOutput: 80 },
    { dyanXTime: 18, dyanYOutput: 30 },
    { dyanXTime: 24, dyanYOutput: 30 },
    { dyanXTime: 24, dyanYOutput: 40 },
    { dyanXTime: 24, dyanYOutput: 40 },
    // {dyanXTime:"00", dyanYOutput:10},
    // {dyanXTime:"03", dyanYOutput:10},
    // {dyanXTime:"03", dyanYOutput:80},
    // {dyanXTime:"20", dyanYOutput:80},
    // {dyanXTime:"20", dyanYOutput:10},
    // {dyanXTime:"24", dyanYOutput:10}
  ];
  data: any[] = [];
  dataFromTableValues: any[] = [];

  savedPrevData: any[] = [];

  focus;
  drag;
  public margin = { top: 50, right: 50, bottom: 50, left: 50 };
  public width: number;
  public height: number;
  public x: any;
  public y: any;
  public svg4: any;
  public line: d3.Line<[number, number]>; // this is line defination
  public area: d3.area<[number, number]>; // this is area defination
  public id = "lineChart";
  public color2 = "#00b36b";
  newData: any;
  changedPoint: any;
  feedMaxValue: any = 112;
  NormalLevelMaximum: any;
  BackgroundLevelMaximum: any;
  Maxdata: any = [];
  Tmaxstart: any;
  TmaxEnd: any;
  TmaxShutDown: any;
  scrolPoint: any;
  deRatingdyanYOutput: any;
  LamOffValue: any;
  DtlDimStopValue: any;
  TmaxdeRatingDim: any;
  Forbidenarea: d3.area<[number, number]>; // this is area defination
  deratingLabelYaxis: number;
  TminEnd: any;
  MaxLimit: any;
  TmindeRatingDim: any;

  constructor(private apiService: ApiService) {}

  ngOnInit() {
    this.apiService.getDynaMidPointShiftValue.subscribe((response) => {
      this.addDeratingLine();
    });
    // this.apiService.getDynaTimeReferencedMode.subscribe(response => {

    //   if(response===false)
    //   {
    //     this.apiService.getFinalDynaDimmerData.subscribe(response=>{
    //       this.addDynaDetails(response);

    //     });
    //   }
    // })
    this.apiService.getFinalDynaDimmerData.subscribe((response) => {
      if (response) {
        this.addDynaDetails(response);
      }
    });

    this.newData = this.data;

    this.buildSvg();
    this.addXandYAxis();
    this.drawLineAndPath();
    this.addDrag();
    this.addDeratingLine();
  }
  addDynaDetails(response: any) {
    if (!isNullOrUndefined(response) && response.length > 0) {
      var newData1 = JSON.parse(response);
      var newData1Len = newData1.length;
      this.data = [];
      this.dataFromTableValues = [];
      if (newData1Len == 1) {
        const element = newData1[0];
        let time = parseFloat(element.timeHHMM.replace(":", "."));
        var output = parseInt(element.outputLevel);
        var fadetime = parseInt(element.fadeTime);
        if (time >= 12 && time <= 24) {
          time = time - 12;
        } else if (time >= 0 && time <=11.59) {
          time = time + 12;
        } else {
          time = 0;
        }
        this.dataFromTableValues.push({
          dyanXTime: 0,
          dyanYOutput: output,
          dynaZfadeTime: -1,
        });
        this.dataFromTableValues.push({
          dyanXTime: time,
          dyanYOutput: output,
          dynaZfadeTime: fadetime,
        });
        this.dataFromTableValues.push({
          dyanXTime: 24,
          dyanYOutput: output,
          dynaZfadeTime: -1,
        });
        this.data = this.dataFromTableValues;
        this.savedPrevData = this.data;
      }
      if (newData1Len == 2) {
        const element1 = newData1[0];
        let time1 = parseFloat(element1.timeHHMM.replace(":", "."));
        var output1 = parseInt(element1.outputLevel);
        var fadetime1 = parseInt(element1.fadeTime);

        if (time1 >= 12 && time1 <= 24) {
          time1 = time1 - 12;
        } else if (time1 >= 0 && time1 <=11.59) {
          time1 = time1 + 12;
        } else {
          time1 = 0;
        }
        const element2 = newData1[1];
        let time2 = parseFloat(element2.timeHHMM.replace(":", "."));
        var output2 = parseInt(element2.outputLevel);
        var fadetime2 = parseInt(element2.fadeTime);

        if (time2 >= 12 && time2 <= 24) {
          time2 = time2 - 12;
        } else if (time2 >= 0 && time2 <=11.59) {
          time2 = time2 + 12;
        } else {
          time2 = 0;
        }

        this.dataFromTableValues.push({
          dyanXTime: 0,
          dyanYOutput: output2,
          dynaZfadeTime: -1,
        });
        this.dataFromTableValues.push({
          dyanXTime: time1,
          dyanYOutput: output2,
          dynaZfadeTime: fadetime1,
        });
        this.dataFromTableValues.push({
          dyanXTime: time1,
          dyanYOutput: output1,
          dynaZfadeTime: fadetime1,
        });
        this.dataFromTableValues.push({
          dyanXTime: time2,
          dyanYOutput: output1,
          dynaZfadeTime: fadetime2,
        });
        this.dataFromTableValues.push({
          dyanXTime: time2,
          dyanYOutput: output2,
          dynaZfadeTime: fadetime2,
        });
        this.dataFromTableValues.push({
          dyanXTime: 24,
          dyanYOutput: output2,
          dynaZfadeTime: -1,
        });
        this.data = this.dataFromTableValues;
        this.savedPrevData = this.data;
      }
      if (newData1Len == 3) {
        const element1 = newData1[0];
        let time1 = parseFloat(element1.timeHHMM.replace(":", "."));
        var output1 = parseInt(element1.outputLevel);
        var fadetime1 = parseInt(element1.fadeTime);

        if (time1 >= 12 && time1 <= 24) {
          time1 = time1 - 12;
        } else if (time1 >= 0 && time1 <=11.59) {
          time1 = time1 + 12;
        } else {
          time1 = 0;
        }
        const element2 = newData1[1];
        let time2 = parseFloat(element2.timeHHMM.replace(":", "."));
        var output2 = parseInt(element2.outputLevel);
        var fadetime2 = parseInt(element2.fadeTime);
        if (time2 >= 12 && time2 <= 24) {
          time2 = time2 - 12;
        } else if (time2 >= 0 && time2 <=11.59) {
          time2 = time2 + 12;
        } else {
          time2 = 0;
        }

        const element3 = newData1[2];
        let time3 = parseFloat(element3.timeHHMM.replace(":", "."));
        var output3 = parseInt(element3.outputLevel);
        var fadetime3 = parseInt(element3.fadeTime);
        if (time3 >= 12 && time3 <= 24) {
          time3 = time3 - 12;
        } else if (time3 >= 0 && time3 <=11.59) {
          time3 = time3 + 12;
        } else {
          time3 = 0;
        }
        this.dataFromTableValues.push({
          dyanXTime: 0,
          dyanYOutput: output3,
          dynaZfadeTime: -1,
        });
        this.dataFromTableValues.push({
          dyanXTime: time1,
          dyanYOutput: output3,
          dynaZfadeTime: fadetime1,
        });
        //this.dataFromTableValues.push({dyanXTime: time1, dyanYOutput: output2})
        this.dataFromTableValues.push({
          dyanXTime: time1,
          dyanYOutput: output1,
          dynaZfadeTime: fadetime1,
        });
        this.dataFromTableValues.push({
          dyanXTime: time2,
          dyanYOutput: output1,
          dynaZfadeTime: fadetime2,
        });
        this.dataFromTableValues.push({
          dyanXTime: time2,
          dyanYOutput: output2,
          dynaZfadeTime: fadetime2,
        });
        this.dataFromTableValues.push({
          dyanXTime: time3,
          dyanYOutput: output2,
          dynaZfadeTime: fadetime3,
        });
        this.dataFromTableValues.push({
          dyanXTime: time3,
          dyanYOutput: output3,
          dynaZfadeTime: fadetime3,
        });
        this.dataFromTableValues.push({
          dyanXTime: 24,
          dyanYOutput: output3,
          dynaZfadeTime: -1,
        });
        this.data = this.dataFromTableValues;
        this.savedPrevData = this.data;
      }
      if (newData1Len == 4) {
        const element1 = newData1[0];
        let time1 = parseFloat(element1.timeHHMM.replace(":", "."));
        var output1 = parseInt(element1.outputLevel);
        var fadetime1 = parseInt(element1.fadeTime);
        if (time1 >= 12 && time1 <= 24) {
          time1 = time1 - 12;
        } else if (time1 >= 0 && time1 <=11.59) {
          time1 = time1 + 12;
        } else {
          time1 = 0;
        }
        const element2 = newData1[1];
        let time2 = parseFloat(element2.timeHHMM.replace(":", "."));
        var output2 = parseInt(element2.outputLevel);
        var fadetime2 = parseInt(element2.fadeTime);
        if (time2 >= 12 && time2 <= 24) {
          time2 = time2 - 12;
        } else if (time2 >= 0 && time2 <=11.59) {
          time2 = time2 + 12;
        } else {
          time2 = 0;
        }

        const element3 = newData1[2];
        let time3 = parseFloat(element3.timeHHMM.replace(":", "."));
        var output3 = parseInt(element3.outputLevel);
        var fadetime3 = parseInt(element3.fadeTime);
        if (time3 >= 12 && time3 <= 24) {
          time3 = time3 - 12;
        } else if (time3 >= 0 && time3 <=11.59) {
          time3 = time3 + 12;
        } else {
          time3 = 0;
        }
        const element4 = newData1[3];
        let time4 = parseFloat(element4.timeHHMM.replace(":", "."));
        var output4 = parseInt(element4.outputLevel);
        var fadetime4 = parseInt(element4.fadeTime);
        if (time4 >= 12 && time4 <= 24) {
          time4 = time4 - 12;
        } else if (time4 >= 0 && time4 <=11.59) {
          time4 = time4 + 12;
        } else {
          time4 = 0;
        }

        this.dataFromTableValues.push({
          dyanXTime: 0,
          dyanYOutput: output4,
          dynaZfadeTime: -1,
        });
        this.dataFromTableValues.push({
          dyanXTime: time1,
          dyanYOutput: output4,
          dynaZfadeTime: fadetime1,
        });
        //this.dataFromTableValues.push({dyanXTime: time1, dyanYOutput: output2})
        this.dataFromTableValues.push({
          dyanXTime: time1,
          dyanYOutput: output1,
          dynaZfadeTime: fadetime1,
        });
        this.dataFromTableValues.push({
          dyanXTime: time2,
          dyanYOutput: output1,
          dynaZfadeTime: fadetime2,
        });
        this.dataFromTableValues.push({
          dyanXTime: time2,
          dyanYOutput: output2,
          dynaZfadeTime: fadetime2,
        });
        this.dataFromTableValues.push({
          dyanXTime: time3,
          dyanYOutput: output2,
          dynaZfadeTime: fadetime3,
        });
        this.dataFromTableValues.push({
          dyanXTime: time3,
          dyanYOutput: output3,
          dynaZfadeTime: fadetime3,
        });
        this.dataFromTableValues.push({
          dyanXTime: time4,
          dyanYOutput: output3,
          dynaZfadeTime: fadetime4,
        });
        this.dataFromTableValues.push({
          dyanXTime: time4,
          dyanYOutput: output4,
          dynaZfadeTime: fadetime4,
        });
        this.dataFromTableValues.push({
          dyanXTime: 24,
          dyanYOutput: output4,
          dynaZfadeTime: -1,
        });
        this.data = this.dataFromTableValues;
        this.savedPrevData = this.data;
      }
      if (newData1Len == 5) {
        const element1 = newData1[0];
        let time1 = parseFloat(element1.timeHHMM.replace(":", "."));
        var output1 = parseInt(element1.outputLevel);
        var fadetime1 = parseInt(element1.fadeTime);
        if (time1 >= 12 && time1 <= 24) {
          time1 = time1 - 12;
        } else if (time1 >= 0 && time1 <=11.59) {
          time1 = time1 + 12;
        } else {
          time1 = 0;
        }
        const element2 = newData1[1];
        let time2 = parseFloat(element2.timeHHMM.replace(":", "."));
        var output2 = parseInt(element2.outputLevel);
        var fadetime2 = parseInt(element2.fadeTime);
        if (time2 >= 12 && time2 <= 24) {
          time2 = time2 - 12;
        } else if (time2 >= 0 && time2 <=11.59) {
          time2 = time2 + 12;
        } else {
          time2 = 0;
        }

        const element3 = newData1[2];
        let time3 = parseFloat(element3.timeHHMM.replace(":", "."));
        var output3 = parseInt(element3.outputLevel);
        var fadetime3 = parseInt(element3.fadeTime);
        if (time3 >= 12 && time3 <= 24) {
          time3 = time3 - 12;
        } else if (time3 >= 0 && time3 <=11.59) {
          time3 = time3 + 12;
        } else {
          time3 = 0;
        }
        const element4 = newData1[3];
        let time4 = parseFloat(element4.timeHHMM.replace(":", "."));
        var output4 = parseInt(element4.outputLevel);
        var fadetime4 = parseInt(element4.fadeTime);
        if (time4 >= 12 && time4 <= 24) {
          time4 = time4 - 12;
        } else if (time4 >= 0 && time4 <=11.59) {
          time4 = time4 + 12;
        } else {
          time4 = 0;
        }
        const element5 = newData1[4];
        let time5 = parseFloat(element5.timeHHMM.replace(":", "."));
        var output5 = parseInt(element5.outputLevel);
        var fadetime5 = parseInt(element5.fadeTime);
        if (time5 >= 12 && time5 <= 24) {
          time5 = time5 - 12;
        } else if (time5 >= 0 && time5 <=11.59) {
          time5 = time5 + 12;
        } else {
          time5 = 0;
        }

        this.dataFromTableValues.push({
          dyanXTime: 0,
          dyanYOutput: output5,
          dynaZfadeTime: -1,
        });
        this.dataFromTableValues.push({
          dyanXTime: time1,
          dyanYOutput: output5,
          dynaZfadeTime: fadetime1,
        });
        //this.dataFromTableValues.push({dyanXTime: time1, dyanYOutput: output2})
        this.dataFromTableValues.push({
          dyanXTime: time1,
          dyanYOutput: output1,
          dynaZfadeTime: fadetime1,
        });
        this.dataFromTableValues.push({
          dyanXTime: time2,
          dyanYOutput: output1,
          dynaZfadeTime: fadetime2,
        });
        this.dataFromTableValues.push({
          dyanXTime: time2,
          dyanYOutput: output2,
          dynaZfadeTime: fadetime2,
        });
        this.dataFromTableValues.push({
          dyanXTime: time3,
          dyanYOutput: output2,
          dynaZfadeTime: fadetime3,
        });
        this.dataFromTableValues.push({
          dyanXTime: time3,
          dyanYOutput: output3,
          dynaZfadeTime: fadetime3,
        });
        this.dataFromTableValues.push({
          dyanXTime: time4,
          dyanYOutput: output3,
          dynaZfadeTime: fadetime4,
        });
        this.dataFromTableValues.push({
          dyanXTime: time4,
          dyanYOutput: output4,
          dynaZfadeTime: fadetime4,
        });
        this.dataFromTableValues.push({
          dyanXTime: time5,
          dyanYOutput: output4,
          dynaZfadeTime: fadetime5,
        });
        this.dataFromTableValues.push({
          dyanXTime: time5,
          dyanYOutput: output5,
          dynaZfadeTime: fadetime5,
        });
        this.dataFromTableValues.push({
          dyanXTime: 24,
          dyanYOutput: output5,
          dynaZfadeTime: -1,
        });
        this.data = this.dataFromTableValues;

        this.savedPrevData = this.data;
      }
      if (newData1Len == 6) {
        const element1 = newData1[0];
        let time1 = parseFloat(element1.timeHHMM.replace(":", "."));
        var output1 = parseInt(element1.outputLevel);
        var fadetime1 = parseInt(element1.fadeTime);
        if (time1 >= 12 && time1 <= 24) {
          time1 = time1 - 12;
        } else if (time1 >= 0 && time1 <=11.59) {
          time1 = time1 + 12;
        } else {
          time1 = 0;
        }
        const element2 = newData1[1];
        let time2 = parseFloat(element2.timeHHMM.replace(":", "."));
        var output2 = parseInt(element2.outputLevel);
        var fadetime2 = parseInt(element2.fadeTime);
        if (time2 >= 12 && time2 <= 24) {
          time2 = time2 - 12;
        } else if (time2 >= 0 && time2 <=11.59) {
          time2 = time2 + 12;
        } else {
          time2 = 0;
        }

        const element3 = newData1[2];
        let time3 = parseFloat(element3.timeHHMM.replace(":", "."));
        var output3 = parseInt(element3.outputLevel);
        var fadetime3 = parseInt(element3.fadeTime);
        if (time3 >= 12 && time3 <= 24) {
          time3 = time3 - 12;
        } else if (time3 >= 0 && time3 <=11.59) {
          time3 = time3 + 12;
        } else {
          time3 = 0;
        }
        const element4 = newData1[3];
        let time4 = parseFloat(element4.timeHHMM.replace(":", "."));
        var output4 = parseInt(element4.outputLevel);
        var fadetime4 = parseInt(element4.fadeTime);
        if (time4 >= 12 && time4 <= 24) {
          time4 = time4 - 12;
        } else if (time4 >= 0 && time4 <=11.59) {
          time4 = time4 + 12;
        } else {
          time4 = 0;
        }
        const element5 = newData1[4];
        let time5 = parseFloat(element5.timeHHMM.replace(":", "."));
        var output5 = parseInt(element5.outputLevel);
        var fadetime5 = parseInt(element5.fadeTime);
        if (time5 >= 12 && time5 <= 24) {
          time5 = time5 - 12;
        } else if (time5 >= 0 && time5 <=11.59) {
          time5 = time5 + 12;
        } else {
          time5 = 0;
        }

        const element6 = newData1[5];
        let time6 = parseFloat(element6.timeHHMM.replace(":", "."));
        var output6 = parseInt(element6.outputLevel);
        var fadetime6 = parseInt(element6.fadeTime);
        if (time6 >= 12 && time6 <= 24) {
          time6 = time6 - 12;
        } else if (time6 >= 0 && time6 <=11.59) {
          time6 = time6 + 12;
        } else {
          time6 = 0;
        }

        this.dataFromTableValues.push({
          dyanXTime: 0,
          dyanYOutput: output6,
          dynaZfadeTime: -1,
        });
        this.dataFromTableValues.push({
          dyanXTime: time1,
          dyanYOutput: output6,
          dynaZfadeTime: fadetime1,
        });
        //this.dataFromTableValues.push({dyanXTime: time1, dyanYOutput: output2})
        this.dataFromTableValues.push({
          dyanXTime: time1,
          dyanYOutput: output1,
          dynaZfadeTime: fadetime1,
        });
        this.dataFromTableValues.push({
          dyanXTime: time2,
          dyanYOutput: output1,
          dynaZfadeTime: fadetime2,
        });
        this.dataFromTableValues.push({
          dyanXTime: time2,
          dyanYOutput: output2,
          dynaZfadeTime: fadetime2,
        });
        this.dataFromTableValues.push({
          dyanXTime: time3,
          dyanYOutput: output2,
          dynaZfadeTime: fadetime3,
        });
        this.dataFromTableValues.push({
          dyanXTime: time3,
          dyanYOutput: output3,
          dynaZfadeTime: fadetime3,
        });
        this.dataFromTableValues.push({
          dyanXTime: time4,
          dyanYOutput: output3,
          dynaZfadeTime: fadetime4,
        });
        this.dataFromTableValues.push({
          dyanXTime: time4,
          dyanYOutput: output4,
          dynaZfadeTime: fadetime4,
        });
        this.dataFromTableValues.push({
          dyanXTime: time5,
          dyanYOutput: output4,
          dynaZfadeTime: fadetime5,
        });
        this.dataFromTableValues.push({
          dyanXTime: time5,
          dyanYOutput: output5,
          dynaZfadeTime: fadetime5,
        });
        this.dataFromTableValues.push({
          dyanXTime: time6,
          dyanYOutput: output5,
          dynaZfadeTime: fadetime6,
        });
        this.dataFromTableValues.push({
          dyanXTime: time6,
          dyanYOutput: output6,
          dynaZfadeTime: fadetime6,
        });
        this.dataFromTableValues.push({
          dyanXTime: 24,
          dyanYOutput: output6,
          dynaZfadeTime: -1,
        });
        this.data = this.dataFromTableValues;

        this.savedPrevData = this.data;
      }

      if (newData1Len == 7) {
        const element1 = newData1[0];
        let time1 = parseFloat(element1.timeHHMM.replace(":", "."));
        var output1 = parseInt(element1.outputLevel);
        var fadetime1 = parseInt(element1.fadeTime);
        if (time1 >= 12 && time1 <= 24) {
          time1 = time1 - 12;
        } else if (time1 >= 0 && time1 <=11.59) {
          time1 = time1 + 12;
        } else {
          time1 = 0;
        }
        const element2 = newData1[1];
        let time2 = parseFloat(element2.timeHHMM.replace(":", "."));
        var output2 = parseInt(element2.outputLevel);
        var fadetime2 = parseInt(element2.fadeTime);
        if (time2 >= 12 && time2 <= 24) {
          time2 = time2 - 12;
        } else if (time2 >= 0 && time2 <=11.59) {
          time2 = time2 + 12;
        } else {
          time2 = 0;
        }

        const element3 = newData1[2];
        let time3 = parseFloat(element3.timeHHMM.replace(":", "."));
        var output3 = parseInt(element3.outputLevel);
        var fadetime3 = parseInt(element3.fadeTime);
        if (time3 >= 12 && time3 <= 24) {
          time3 = time3 - 12;
        } else if (time3 >= 0 && time3 <=11.59) {
          time3 = time3 + 12;
        } else {
          time3 = 0;
        }
        const element4 = newData1[3];
        let time4 = parseFloat(element4.timeHHMM.replace(":", "."));
        var output4 = parseInt(element4.outputLevel);
        var fadetime4 = parseInt(element4.fadeTime);
        if (time4 >= 12 && time4 <= 24) {
          time4 = time4 - 12;
        } else if (time4 >= 0 && time4 <=11.59) {
          time4 = time4 + 12;
        } else {
          time4 = 0;
        }
        const element5 = newData1[4];
        let time5 = parseFloat(element5.timeHHMM.replace(":", "."));
        var output5 = parseInt(element5.outputLevel);
        var fadetime5 = parseInt(element5.fadeTime);
        if (time5 >= 12 && time5 <= 24) {
          time5 = time5 - 12;
        } else if (time5 >= 0 && time5 <=11.59) {
          time5 = time5 + 12;
        } else {
          time5 = 0;
        }

        const element6 = newData1[5];
        let time6 = parseFloat(element6.timeHHMM.replace(":", "."));
        var output6 = parseInt(element6.outputLevel);
        var fadetime6 = parseInt(element6.fadeTime);
        if (time6 >= 12 && time6 <= 24) {
          time6 = time6 - 12;
        } else if (time6 >= 0 && time6 <=11.59) {
          time6 = time6 + 12;
        } else {
          time6 = 0;
        }
		
		const element7 = newData1[6];
        let time7 = parseFloat(element7.timeHHMM.replace(":", "."));
        var output7 = parseInt(element7.outputLevel);
        var fadetime7 = parseInt(element7.fadeTime);
        if (time7 >= 12 && time7 <= 24) {
          time7 = time7 - 12;
        } else if (time7 >= 0 && time7 <=11.59) {
          time7 = time7 + 12;
        } else {
          time7 = 0;
        }

        this.dataFromTableValues.push({
          dyanXTime: 0,
          dyanYOutput: output7,
          dynaZfadeTime: -1,
        });
        this.dataFromTableValues.push({
          dyanXTime: time1,
          dyanYOutput: output7,
          dynaZfadeTime: fadetime1,
        });
        //this.dataFromTableValues.push({dyanXTime: time1, dyanYOutput: output2})
        this.dataFromTableValues.push({
          dyanXTime: time1,
          dyanYOutput: output1,
          dynaZfadeTime: fadetime1,
        });
        this.dataFromTableValues.push({
          dyanXTime: time2,
          dyanYOutput: output1,
          dynaZfadeTime: fadetime2,
        });
        this.dataFromTableValues.push({
          dyanXTime: time2,
          dyanYOutput: output2,
          dynaZfadeTime: fadetime2,
        });
        this.dataFromTableValues.push({
          dyanXTime: time3,
          dyanYOutput: output2,
          dynaZfadeTime: fadetime3,
        });
        this.dataFromTableValues.push({
          dyanXTime: time3,
          dyanYOutput: output3,
          dynaZfadeTime: fadetime3,
        });
        this.dataFromTableValues.push({
          dyanXTime: time4,
          dyanYOutput: output3,
          dynaZfadeTime: fadetime4,
        });
        this.dataFromTableValues.push({
          dyanXTime: time4,
          dyanYOutput: output4,
          dynaZfadeTime: fadetime4,
        });
        this.dataFromTableValues.push({
          dyanXTime: time5,
          dyanYOutput: output4,
          dynaZfadeTime: fadetime5,
        });
        this.dataFromTableValues.push({
          dyanXTime: time5,
          dyanYOutput: output5,
          dynaZfadeTime: fadetime5,
        });
        this.dataFromTableValues.push({
          dyanXTime: time6,
          dyanYOutput: output5,
          dynaZfadeTime: fadetime6,
        });
        this.dataFromTableValues.push({
          dyanXTime: time6,
          dyanYOutput: output6,
          dynaZfadeTime: fadetime6,
        });
		 this.dataFromTableValues.push({
          dyanXTime: time7,
          dyanYOutput: output6,
          dynaZfadeTime: fadetime7,
        });
        this.dataFromTableValues.push({
          dyanXTime: time7,
          dyanYOutput: output7,
          dynaZfadeTime: fadetime7,
        });
        this.dataFromTableValues.push({
          dyanXTime: 24,
          dyanYOutput: output7,
          dynaZfadeTime: -1,
        });
        this.data = this.dataFromTableValues;

        this.savedPrevData = this.data;
      }

      if (newData1Len == 8) {
        const element1 = newData1[0];
        let time1 = parseFloat(element1.timeHHMM.replace(":", "."));
        var output1 = parseInt(element1.outputLevel);
        var fadetime1 = parseInt(element1.fadeTime);
        if (time1 >= 12 && time1 <= 24) {
          time1 = time1 - 12;
        } else if (time1 >= 0 && time1 <=11.59) {
          time1 = time1 + 12;
        } else {
          time1 = 0;
        }
        const element2 = newData1[1];
        let time2 = parseFloat(element2.timeHHMM.replace(":", "."));
        var output2 = parseInt(element2.outputLevel);
        var fadetime2 = parseInt(element2.fadeTime);
        if (time2 >= 12 && time2 <= 24) {
          time2 = time2 - 12;
        } else if (time2 >= 0 && time2 <=11.59) {
          time2 = time2 + 12;
        } else {
          time2 = 0;
        }

        const element3 = newData1[2];
        let time3 = parseFloat(element3.timeHHMM.replace(":", "."));
        var output3 = parseInt(element3.outputLevel);
        var fadetime3 = parseInt(element3.fadeTime);
        if (time3 >= 12 && time3 <= 24) {
          time3 = time3 - 12;
        } else if (time3 >= 0 && time3 <=11.59) {
          time3 = time3 + 12;
        } else {
          time3 = 0;
        }
        const element4 = newData1[3];
        let time4 = parseFloat(element4.timeHHMM.replace(":", "."));
        var output4 = parseInt(element4.outputLevel);
        var fadetime4 = parseInt(element4.fadeTime);
        if (time4 >= 12 && time4 <= 24) {
          time4 = time4 - 12;
        } else if (time4 >= 0 && time4 <=11.59) {
          time4 = time4 + 12;
        } else {
          time4 = 0;
        }
        const element5 = newData1[4];
        let time5 = parseFloat(element5.timeHHMM.replace(":", "."));
        var output5 = parseInt(element5.outputLevel);
        var fadetime5 = parseInt(element5.fadeTime);
        if (time5 >= 12 && time5 <= 24) {
          time5 = time5 - 12;
        } else if (time5 >= 0 && time5 <=11.59) {
          time5 = time5 + 12;
        } else {
          time5 = 0;
        }

        const element6 = newData1[5];
        let time6 = parseFloat(element6.timeHHMM.replace(":", "."));
        var output6 = parseInt(element6.outputLevel);
        var fadetime6 = parseInt(element6.fadeTime);
        if (time6 >= 12 && time6 <= 24) {
          time6 = time6 - 12;
        } else if (time6 >= 0 && time6 <=11.59) {
          time6 = time6 + 12;
        } else {
          time6 = 0;
        }
		
		const element7 = newData1[6];
        let time7 = parseFloat(element7.timeHHMM.replace(":", "."));
        var output7 = parseInt(element7.outputLevel);
        var fadetime7 = parseInt(element7.fadeTime);
        if (time7 >= 12 && time7 <= 24) {
          time7 = time7 - 12;
        } else if (time7 >= 0 && time7 <=11.59) {
          time7 = time7 + 12;
        } else {
          time7 = 0;
        }

const element8 = newData1[7];
        let time8 = parseFloat(element8.timeHHMM.replace(":", "."));
        var output8 = parseInt(element8.outputLevel);
        var fadetime8 = parseInt(element8.fadeTime);
        if (time8 >= 12 && time8 <= 24) {
          time8 = time8 - 12;
        } else if (time8 >= 0 && time8 <=11.59) {
          time8 = time8 + 12;
        } else {
          time8 = 0;
        }
		
        this.dataFromTableValues.push({
          dyanXTime: 0,
          dyanYOutput: output8,
          dynaZfadeTime: -1,
        });
        this.dataFromTableValues.push({
          dyanXTime: time1,
          dyanYOutput: output8,
          dynaZfadeTime: fadetime1,
        });
        //this.dataFromTableValues.push({dyanXTime: time1, dyanYOutput: output2})
        this.dataFromTableValues.push({
          dyanXTime: time1,
          dyanYOutput: output1,
          dynaZfadeTime: fadetime1,
        });
        this.dataFromTableValues.push({
          dyanXTime: time2,
          dyanYOutput: output1,
          dynaZfadeTime: fadetime2,
        });
        this.dataFromTableValues.push({
          dyanXTime: time2,
          dyanYOutput: output2,
          dynaZfadeTime: fadetime2,
        });
        this.dataFromTableValues.push({
          dyanXTime: time3,
          dyanYOutput: output2,
          dynaZfadeTime: fadetime3,
        });
        this.dataFromTableValues.push({
          dyanXTime: time3,
          dyanYOutput: output3,
          dynaZfadeTime: fadetime3,
        });
        this.dataFromTableValues.push({
          dyanXTime: time4,
          dyanYOutput: output3,
          dynaZfadeTime: fadetime4,
        });
        this.dataFromTableValues.push({
          dyanXTime: time4,
          dyanYOutput: output4,
          dynaZfadeTime: fadetime4,
        });
        this.dataFromTableValues.push({
          dyanXTime: time5,
          dyanYOutput: output4,
          dynaZfadeTime: fadetime5,
        });
        this.dataFromTableValues.push({
          dyanXTime: time5,
          dyanYOutput: output5,
          dynaZfadeTime: fadetime5,
        });
        this.dataFromTableValues.push({
          dyanXTime: time6,
          dyanYOutput: output5,
          dynaZfadeTime: fadetime6,
        });
        this.dataFromTableValues.push({
          dyanXTime: time6,
          dyanYOutput: output6,
          dynaZfadeTime: fadetime6,
        });
		 this.dataFromTableValues.push({
          dyanXTime: time7,
          dyanYOutput: output6,
          dynaZfadeTime: fadetime7,
        });
        this.dataFromTableValues.push({
          dyanXTime: time7,
          dyanYOutput: output7,
          dynaZfadeTime: fadetime7,
        });
		 this.dataFromTableValues.push({
          dyanXTime: time8,
          dyanYOutput: output7,
          dynaZfadeTime: fadetime8,
        });
        this.dataFromTableValues.push({
          dyanXTime: time8,
          dyanYOutput: output8,
          dynaZfadeTime: fadetime8,
        });
        this.dataFromTableValues.push({
          dyanXTime: 24,
          dyanYOutput: output8,
          dynaZfadeTime: -1,
        });
        this.data = this.dataFromTableValues;

        this.savedPrevData = this.data;
      }

      if (newData1Len == 9) {
        const element1 = newData1[0];
        let time1 = parseFloat(element1.timeHHMM.replace(":", "."));
        var output1 = parseInt(element1.outputLevel);
        var fadetime1 = parseInt(element1.fadeTime);
        if (time1 >= 12 && time1 <= 24) {
          time1 = time1 - 12;
        } else if (time1 >= 0 && time1 <=11.59) {
          time1 = time1 + 12;
        } else {
          time1 = 0;
        }
        const element2 = newData1[1];
        let time2 = parseFloat(element2.timeHHMM.replace(":", "."));
        var output2 = parseInt(element2.outputLevel);
        var fadetime2 = parseInt(element2.fadeTime);
        if (time2 >= 12 && time2 <= 24) {
          time2 = time2 - 12;
        } else if (time2 >= 0 && time2 <=11.59) {
          time2 = time2 + 12;
        } else {
          time2 = 0;
        }

        const element3 = newData1[2];
        let time3 = parseFloat(element3.timeHHMM.replace(":", "."));
        var output3 = parseInt(element3.outputLevel);
        var fadetime3 = parseInt(element3.fadeTime);
        if (time3 >= 12 && time3 <= 24) {
          time3 = time3 - 12;
        } else if (time3 >= 0 && time3 <=11.59) {
          time3 = time3 + 12;
        } else {
          time3 = 0;
        }
        const element4 = newData1[3];
        let time4 = parseFloat(element4.timeHHMM.replace(":", "."));
        var output4 = parseInt(element4.outputLevel);
        var fadetime4 = parseInt(element4.fadeTime);
        if (time4 >= 12 && time4 <= 24) {
          time4 = time4 - 12;
        } else if (time4 >= 0 && time4 <=11.59) {
          time4 = time4 + 12;
        } else {
          time4 = 0;
        }
        const element5 = newData1[4];
        let time5 = parseFloat(element5.timeHHMM.replace(":", "."));
        var output5 = parseInt(element5.outputLevel);
        var fadetime5 = parseInt(element5.fadeTime);
        if (time5 >= 12 && time5 <= 24) {
          time5 = time5 - 12;
        } else if (time5 >= 0 && time5 <=11.59) {
          time5 = time5 + 12;
        } else {
          time5 = 0;
        }

        const element6 = newData1[5];
        let time6 = parseFloat(element6.timeHHMM.replace(":", "."));
        var output6 = parseInt(element6.outputLevel);
        var fadetime6 = parseInt(element6.fadeTime);
        if (time6 >= 12 && time6 <= 24) {
          time6 = time6 - 12;
        } else if (time6 >= 0 && time6 <=11.59) {
          time6 = time6 + 12;
        } else {
          time6 = 0;
        }
		
		const element7 = newData1[6];
        let time7 = parseFloat(element7.timeHHMM.replace(":", "."));
        var output7 = parseInt(element7.outputLevel);
        var fadetime7 = parseInt(element7.fadeTime);
        if (time7 >= 12 && time7 <= 24) {
          time7 = time7 - 12;
        } else if (time7 >= 0 && time7 <=11.59) {
          time7 = time7 + 12;
        } else {
          time7 = 0;
        }

const element8 = newData1[7];
        let time8 = parseFloat(element8.timeHHMM.replace(":", "."));
        var output8 = parseInt(element8.outputLevel);
        var fadetime8 = parseInt(element8.fadeTime);
        if (time8 >= 12 && time8 <= 24) {
          time8 = time8 - 12;
        } else if (time8 >= 0 && time8 <=11.59) {
          time8 = time8 + 12;
        } else {
          time8 = 0;
        }

const element9 = newData1[8];
        let time9 = parseFloat(element9.timeHHMM.replace(":", "."));
        var output9 = parseInt(element9.outputLevel);
        var fadetime9 = parseInt(element9.fadeTime);
        if (time9 >= 12 && time9 <= 24) {
          time9 = time9 - 12;
        } else if (time9 >= 0 && time9 <=11.59) {
          time9 = time9 + 12;
        } else {
          time9 = 0;
        }
		
        this.dataFromTableValues.push({
          dyanXTime: 0,
          dyanYOutput: output9,
          dynaZfadeTime: -1,
        });
        this.dataFromTableValues.push({
          dyanXTime: time1,
          dyanYOutput: output9,
          dynaZfadeTime: fadetime1,
        });
        //this.dataFromTableValues.push({dyanXTime: time1, dyanYOutput: output2})
        this.dataFromTableValues.push({
          dyanXTime: time1,
          dyanYOutput: output1,
          dynaZfadeTime: fadetime1,
        });
        this.dataFromTableValues.push({
          dyanXTime: time2,
          dyanYOutput: output1,
          dynaZfadeTime: fadetime2,
        });
        this.dataFromTableValues.push({
          dyanXTime: time2,
          dyanYOutput: output2,
          dynaZfadeTime: fadetime2,
        });
        this.dataFromTableValues.push({
          dyanXTime: time3,
          dyanYOutput: output2,
          dynaZfadeTime: fadetime3,
        });
        this.dataFromTableValues.push({
          dyanXTime: time3,
          dyanYOutput: output3,
          dynaZfadeTime: fadetime3,
        });
        this.dataFromTableValues.push({
          dyanXTime: time4,
          dyanYOutput: output3,
          dynaZfadeTime: fadetime4,
        });
        this.dataFromTableValues.push({
          dyanXTime: time4,
          dyanYOutput: output4,
          dynaZfadeTime: fadetime4,
        });
        this.dataFromTableValues.push({
          dyanXTime: time5,
          dyanYOutput: output4,
          dynaZfadeTime: fadetime5,
        });
        this.dataFromTableValues.push({
          dyanXTime: time5,
          dyanYOutput: output5,
          dynaZfadeTime: fadetime5,
        });
        this.dataFromTableValues.push({
          dyanXTime: time6,
          dyanYOutput: output5,
          dynaZfadeTime: fadetime6,
        });
        this.dataFromTableValues.push({
          dyanXTime: time6,
          dyanYOutput: output6,
          dynaZfadeTime: fadetime6,
        });
		 this.dataFromTableValues.push({
          dyanXTime: time7,
          dyanYOutput: output6,
          dynaZfadeTime: fadetime7,
        });
        this.dataFromTableValues.push({
          dyanXTime: time7,
          dyanYOutput: output7,
          dynaZfadeTime: fadetime7,
        });
		 this.dataFromTableValues.push({
          dyanXTime: time8,
          dyanYOutput: output7,
          dynaZfadeTime: fadetime8,
        });
        this.dataFromTableValues.push({
          dyanXTime: time8,
          dyanYOutput: output8,
          dynaZfadeTime: fadetime8,
        });
		
		 this.dataFromTableValues.push({
          dyanXTime: time9,
          dyanYOutput: output8,
          dynaZfadeTime: fadetime9,
        });
        this.dataFromTableValues.push({
          dyanXTime: time9,
          dyanYOutput: output9,
          dynaZfadeTime: fadetime9,
        });
        this.dataFromTableValues.push({
          dyanXTime: 24,
          dyanYOutput: output9,
          dynaZfadeTime: -1,
        });
        this.data = this.dataFromTableValues;

        this.savedPrevData = this.data;
      }

      if (newData1Len == 10) {
        const element1 = newData1[0];
        let time1 = parseFloat(element1.timeHHMM.replace(":", "."));
        var output1 = parseInt(element1.outputLevel);
        var fadetime1 = parseInt(element1.fadeTime);
        if (time1 >= 12 && time1 <= 24) {
          time1 = time1 - 12;
        } else if (time1 >= 0 && time1 <=11.59) {
          time1 = time1 + 12;
        } else {
          time1 = 0;
        }
        const element2 = newData1[1];
        let time2 = parseFloat(element2.timeHHMM.replace(":", "."));
        var output2 = parseInt(element2.outputLevel);
        var fadetime2 = parseInt(element2.fadeTime);
        if (time2 >= 12 && time2 <= 24) {
          time2 = time2 - 12;
        } else if (time2 >= 0 && time2 <=11.59) {
          time2 = time2 + 12;
        } else {
          time2 = 0;
        }

        const element3 = newData1[2];
        let time3 = parseFloat(element3.timeHHMM.replace(":", "."));
        var output3 = parseInt(element3.outputLevel);
        var fadetime3 = parseInt(element3.fadeTime);
        if (time3 >= 12 && time3 <= 24) {
          time3 = time3 - 12;
        } else if (time3 >= 0 && time3 <=11.59) {
          time3 = time3 + 12;
        } else {
          time3 = 0;
        }
        const element4 = newData1[3];
        let time4 = parseFloat(element4.timeHHMM.replace(":", "."));
        var output4 = parseInt(element4.outputLevel);
        var fadetime4 = parseInt(element4.fadeTime);
        if (time4 >= 12 && time4 <= 24) {
          time4 = time4 - 12;
        } else if (time4 >= 0 && time4 <=11.59) {
          time4 = time4 + 12;
        } else {
          time4 = 0;
        }
        const element5 = newData1[4];
        let time5 = parseFloat(element5.timeHHMM.replace(":", "."));
        var output5 = parseInt(element5.outputLevel);
        var fadetime5 = parseInt(element5.fadeTime);
        if (time5 >= 12 && time5 <= 24) {
          time5 = time5 - 12;
        } else if (time5 >= 0 && time5 <=11.59) {
          time5 = time5 + 12;
        } else {
          time5 = 0;
        }

        const element6 = newData1[5];
        let time6 = parseFloat(element6.timeHHMM.replace(":", "."));
        var output6 = parseInt(element6.outputLevel);
        var fadetime6 = parseInt(element6.fadeTime);
        if (time6 >= 12 && time6 <= 24) {
          time6 = time6 - 12;
        } else if (time6 >= 0 && time6 <=11.59) {
          time6 = time6 + 12;
        } else {
          time6 = 0;
        }
		
		const element7 = newData1[6];
        let time7 = parseFloat(element7.timeHHMM.replace(":", "."));
        var output7 = parseInt(element7.outputLevel);
        var fadetime7 = parseInt(element7.fadeTime);
        if (time7 >= 12 && time7 <= 24) {
          time7 = time7 - 12;
        } else if (time7 >= 0 && time7 <=11.59) {
          time7 = time7 + 12;
        } else {
          time7 = 0;
        }

const element8 = newData1[7];
        let time8 = parseFloat(element8.timeHHMM.replace(":", "."));
        var output8 = parseInt(element8.outputLevel);
        var fadetime8 = parseInt(element8.fadeTime);
        if (time8 >= 12 && time8 <= 24) {
          time8 = time8 - 12;
        } else if (time8 >= 0 && time8 <=11.59) {
          time8 = time8 + 12;
        } else {
          time8 = 0;
        }

const element9 = newData1[8];
        let time9 = parseFloat(element9.timeHHMM.replace(":", "."));
        var output9 = parseInt(element9.outputLevel);
        var fadetime9 = parseInt(element9.fadeTime);
        if (time9 >= 12 && time9 <= 24) {
          time9 = time9 - 12;
        } else if (time9 >= 0 && time9 <=11.59) {
          time9 = time9 + 12;
        } else {
          time9 = 0;
        }
		
		const element10 = newData1[9];
        let time10 = parseFloat(element10.timeHHMM.replace(":", "."));
        var output10 = parseInt(element10.outputLevel);
        var fadetime10 = parseInt(element10.fadeTime);
        if (time10 >= 12 && time10 <= 24) {
          time10 = time10 - 12;
        } else if (time10 >= 0 && time10 <=11.59) {
          time10 = time10 + 12;
        } else {
          time10 = 0;
        }
        this.dataFromTableValues.push({
          dyanXTime: 0,
          dyanYOutput: output10,
          dynaZfadeTime: -1,
        });
        this.dataFromTableValues.push({
          dyanXTime: time1,
          dyanYOutput: output10,
          dynaZfadeTime: fadetime1,
        });
        //this.dataFromTableValues.push({dyanXTime: time1, dyanYOutput: output2})
        this.dataFromTableValues.push({
          dyanXTime: time1,
          dyanYOutput: output1,
          dynaZfadeTime: fadetime1,
        });
        this.dataFromTableValues.push({
          dyanXTime: time2,
          dyanYOutput: output1,
          dynaZfadeTime: fadetime2,
        });
        this.dataFromTableValues.push({
          dyanXTime: time2,
          dyanYOutput: output2,
          dynaZfadeTime: fadetime2,
        });
        this.dataFromTableValues.push({
          dyanXTime: time3,
          dyanYOutput: output2,
          dynaZfadeTime: fadetime3,
        });
        this.dataFromTableValues.push({
          dyanXTime: time3,
          dyanYOutput: output3,
          dynaZfadeTime: fadetime3,
        });
        this.dataFromTableValues.push({
          dyanXTime: time4,
          dyanYOutput: output3,
          dynaZfadeTime: fadetime4,
        });
        this.dataFromTableValues.push({
          dyanXTime: time4,
          dyanYOutput: output4,
          dynaZfadeTime: fadetime4,
        });
        this.dataFromTableValues.push({
          dyanXTime: time5,
          dyanYOutput: output4,
          dynaZfadeTime: fadetime5,
        });
        this.dataFromTableValues.push({
          dyanXTime: time5,
          dyanYOutput: output5,
          dynaZfadeTime: fadetime5,
        });
        this.dataFromTableValues.push({
          dyanXTime: time6,
          dyanYOutput: output5,
          dynaZfadeTime: fadetime6,
        });
        this.dataFromTableValues.push({
          dyanXTime: time6,
          dyanYOutput: output6,
          dynaZfadeTime: fadetime6,
        });
		 this.dataFromTableValues.push({
          dyanXTime: time7,
          dyanYOutput: output6,
          dynaZfadeTime: fadetime7,
        });
        this.dataFromTableValues.push({
          dyanXTime: time7,
          dyanYOutput: output7,
          dynaZfadeTime: fadetime7,
        });
		 this.dataFromTableValues.push({
          dyanXTime: time8,
          dyanYOutput: output7,
          dynaZfadeTime: fadetime8,
        });
        this.dataFromTableValues.push({
          dyanXTime: time8,
          dyanYOutput: output8,
          dynaZfadeTime: fadetime8,
        });
		
		 this.dataFromTableValues.push({
          dyanXTime: time9,
          dyanYOutput: output8,
          dynaZfadeTime: fadetime9,
        });
        this.dataFromTableValues.push({
          dyanXTime: time9,
          dyanYOutput: output9,
          dynaZfadeTime: fadetime9,
        });
		
		 this.dataFromTableValues.push({
          dyanXTime: time10,
          dyanYOutput: output9,
          dynaZfadeTime: fadetime10,
        });
        this.dataFromTableValues.push({
          dyanXTime: time10,
          dyanYOutput: output10,
          dynaZfadeTime: fadetime10,
        });
        this.dataFromTableValues.push({
          dyanXTime: 24,
          dyanYOutput: output10,
          dynaZfadeTime: -1,
        });
        this.data = this.dataFromTableValues;

        this.savedPrevData = this.data;
      }
      this.buildSvg();
      this.addXandYAxis();
      this.drawLineAndPath();
      this.addDrag();
      this.addDeratingLine();
    }
  }
  public buildSvg() {
    this.svg4 = d3.select("#svg3");
    this.width = 600 - this.margin.left - this.margin.right;
    this.height = 550 - this.margin.top - this.margin.bottom;
  }
  public addXandYAxis() {
    // range of data configuring
    this.x = d3.scaleLinear().range([0, this.width]);
    this.y = d3.scaleLinear().range([this.height, 0]);
    this.x.domain(this.inputXRange);
    this.y.domain(this.inputYRange);
  }

  public drawLineAndPath() {
    this.line = d3
      .line()
      .x((d: any) => this.x(d.dyanXTime))
      .y((d: any) => this.y(d.dyanYOutput));
    d3.selectAll("#svg3 > *").remove();
    this.area = d3
      .area()
      .x((d: any) => this.x(d.dyanXTime))
      .y0(this.height)
      .y1((d: any) => this.y(d.dyanYOutput));

    this.focus = this.svg4
      .append("g")
      .attr(
        "transform",
        "translate(" + this.margin.left + "," + this.margin.top + ")"
      );
    //ploting Area
    this.focus
      .append("path")
      .datum(this.data)
      .attr("fill", "#1ECBD0")
      .attr("fill-opacity", 0.3)
      .attr("stroke", "#1ECBD0")
      .attr("stroke-width", "1px")
      .attr("fill", "url('#gradient4')")
      .attr("d", this.area);

    //ploting circle
    this.focus
      .selectAll("circle")
      .data(this.data)
      .enter()
      .append("circle")
      .attr("r", 6.0)
      .attr("cx", (d: any) => this.x(d.dyanXTime))
      .attr("cy", (d: any) => this.y(d.dyanYOutput))
      .style("cursor", "pointer")
      .style("fill", "#1ECBD0");

    const maxY = d3.max(this.data, function (d) {
      return d.dyanYOutput;
    });
    // Add Gradient

    this.svg4
      .append("linearGradient")
      .attr("id", "gradient4")
      .attr("gradientUnits", "userSpaceOnUse")
      .attr("x1", 0)
      .attr("y1", this.y(0))
      .attr("x2", 0)
      .attr("y2", this.y(maxY))
      .selectAll("stop")
      .data([
        {
          offset: "10%",
          color: "transparent",
        },
        {
          offset: "30%",
          color: "#00E487",
        },
        {
          offset: "100%",
          color: this.color2,
        },
      ])
      .enter()
      .append("stop")
      .attr("offset", function (d) {
        return d.offset;
      })
      .attr("stop-color", function (d) {
        return d.color;
      });

    this.focus
      .append("g")
      .attr("class", "axis axis--x")
      .style("color", "white")
      .attr("transform", "translate(0," + this.height + ")");
    //   .call(d3.axisBottom(this.x));
    // text label for the x axis
    this.svg4
      .append("text")
      /*.attr("transform",
        "translate(" + ((this.width / 2) + 20) + " ," +
        (this.height + this.margin.top + 40) + ")")*/
      .attr("x", this.x(12))
      .attr("y", this.y(-20))
      .style("text-anchor", "middle")
      .style("font", "16px Signify-Relative-Bold")
      .html("Time (hh:mm)");

    // Configure the Y Axis
    this.focus
      .append("g")
      .attr("class", "axis axis--y")
      .call(d3.axisLeft(this.y));

    // text label for the y axis
    this.svg4
      .append("text")
      .attr("transform", "rotate(-90)")
      .attr("y", 0 - this.margin.left + 62)
      .attr("x", 0 - this.height / 2)
      .style("text-anchor", "middle")
      .style("font", "16px Signify-Relative-Bold")
      .text("Output level (%)");

    // add the X gridlines
    this.focus
      .append("g")
      // .attr("class", "grid")
      .attr("transform", "translate(0," + this.height + ")")
      .call(
        this.make_x_gridlines()
          .tickSize(-this.height)
          .tickFormat(
            (d, i) =>
              [
                12, 13,14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 1, 2, 3, 4, 5, 6, 7, 8,
                9, 10, 11
              ][i]
          )

        //(d,i)=>[0,1, 2, 3, 4, 5, 6,7 ,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24][i])
      );

    // add the Y gridlines
    this.focus
      .append("g")
      // .attr("class", "grid")

      .call(this.make_y_gridlines().tickSize(-this.width).tickFormat(""));
  }

  // gridlines in x axis function
  make_x_gridlines() {
    return d3.axisBottom(this.x).ticks(24);
    //.tickFormat((d,i)=>[0,1, 2, 3, 4, 5, 6,7 ,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24][i]);

    //tickFormat(x=>`(${x.toFixed(1)})`);
  }
  // gridlines in y axis function
  make_y_gridlines() {
    return d3.axisLeft(this.y).ticks(10);
  }

  public addDrag() {
    const _this = this;
    let selection = this.focus.selectAll("circle").call(
      d3
        .drag()
        .on("start", function (event, d) {
          const control = _this.dragstarted();
        })
        .on("drag", function (event, d) {
          const control = _this.dragstarted();
          const n = selection.nodes();
          const i = n.indexOf(this);
          if (i >= 1 && i <n.length-1) {
            control.changedPoint = i;

            const m = d3.select(n[i]).node();
            d.dyanXTime = control.x.invert(event.x);
            d.dyanYOutput = control.y.invert(event.y);

            if (d.dyanXTime > 24) d.dyanXTime = 23.59;
            if (d.dyanXTime < 0) d.dyanXTime = 0.1;
            if (d.dyanYOutput > 100) d.dyanYOutput = 100;
            if (d.dyanYOutput < 0) d.dyanYOutput = 0;

            d3.select(m)
              .attr("cx", control.x(d.dyanXTime))
              .attr("cy", control.y(d.dyanYOutput));
            control.focus.select("path").attr("d", control.area);
          }
        })
        .on("end", this.dragended.bind(this))
    );
  }

  dragstarted() {
    return this;
  }

  //   public dragged(d) {
  //     d["dyanXTime"] = this.x.invert(d3.event.x);
  //     d["dyanYOutput"] = this.y.invert(d3.event.y);
  //     d3.select(event.target)
  //       .attr("cx", this.x(d["dyanXTime"]))
  //       .attr("cy", this.y(d["dyanYOutput"]));
  //     this.focus.select("path").attr("d", this.line);
  //   }

  public dragended(d) {
    let draggedData: any = [];

    if (this.data.length === 3) {
      if (this.changedPoint === 1) {
        let dynaModX = parseFloat(this.data[1].dyanXTime);
        let modTimeXValue = this.getDynaTimeXRefernce(dynaModX);
        draggedData.push({
          dyanXTime: modTimeXValue,
          dyanYOutput: parseInt(this.data[1].dyanYOutput),
          dynaZfadeTime: parseInt(this.data[1].dynaZfadeTime),
        });
      }
    }

    if (this.data.length === 6) {
      this.updateScenes2(this.data, draggedData);
      //  alert(this.changedPoint)
    }
    if (this.data.length === 8) {
      this.updateScenes3(this.data, draggedData);
      //  alert(this.changedPoint)
    }
    if (this.data.length === 10) {
      this.updateScenes4(this.data, draggedData);
      //  alert(this.changedPoint)
    }
    if (this.data.length === 12) {
      this.updateScenes5(this.data, draggedData);
      //  alert(this.changedPoint)
    }
    if (this.data.length === 14) {//scene 6
      this.updateScenes6(this.data, draggedData);
      //  alert(this.changedPoint)
    }
    if (this.data.length === 16) {//scene 7
       this.updateScenes7(this.data, draggedData);
       //  alert(this.changedPoint)
     }
    if (this.data.length === 18) {//scene 8
       this.updateScenes8(this.data, draggedData);
       //  alert(this.changedPoint)
     }
     if (this.data.length === 20) {//scene 9
       this.updateScenes9(this.data, draggedData);
       //  alert(this.changedPoint)
     }
     if (this.data.length === 22) {//scene 10
       this.updateScenes10(this.data, draggedData);
       //  alert(this.changedPoint)
     }
    this.apiService.sendDynaGraphData(draggedData);
  }

///SCENE 10

updateScenes10(data: any[], draggedData: any) {
  if (this.changedPoint === 0) {
    //OUTPUT
    let modifiedOutput = parseInt(this.data[0].dyanYOutput);

    let dynaModX = parseFloat(this.data[2].dyanXTime);
    let modTimeXValue = this.getDynaTimeXRefernce(dynaModX);
    draggedData.push({
      dyanXTime: modTimeXValue,
      dyanYOutput: parseInt(this.data[2].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
    });

    let dynaModX1 = parseFloat(this.data[4].dyanXTime);
    let modTimeXValue1 = this.getDynaTimeXRefernce(dynaModX1);
    draggedData.push({
      dyanXTime: modTimeXValue1,
      dyanYOutput: parseInt(this.data[4].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
    });

    let dynaModX2 = parseFloat(this.data[6].dyanXTime);
    let modTimeXValue2 = this.getDynaTimeXRefernce(dynaModX2);
    draggedData.push({
      dyanXTime: modTimeXValue2,
      dyanYOutput: parseInt(this.data[6].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[6].dynaZfadeTime),
    });

    let dynaModX3 = parseFloat(this.data[8].dyanXTime);
    let modTimeXValue3 = this.getDynaTimeXRefernce(dynaModX3);
    draggedData.push({
      dyanXTime: modTimeXValue3,
      dyanYOutput: parseInt(this.data[8].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[8].dynaZfadeTime),
    });

    let dynaModX4 = parseFloat(this.data[10].dyanXTime);
    let modTimeXValue4 = this.getDynaTimeXRefernce(dynaModX4);
    draggedData.push({
      dyanXTime: modTimeXValue4,
      dyanYOutput: modifiedOutput,
      dynaZfadeTime: parseInt(this.data[10].dynaZfadeTime),
    });
  }
  if (this.changedPoint === 1) {
    //TIME
    let modifiedOutput = parseFloat(this.data[1].dyanXTime);

    let modTimeXValue = this.getDynaTimeXRefernce(modifiedOutput);
    draggedData.push({
      dyanXTime: modTimeXValue,
      dyanYOutput: parseInt(this.data[2].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
    });

    let dynaModX1 = parseFloat(this.data[4].dyanXTime);
    let modTimeXValue1 = this.getDynaTimeXRefernce(dynaModX1);
    draggedData.push({
      dyanXTime: modTimeXValue1,
      dyanYOutput: parseInt(this.data[4].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
    });
    let dynaModX2 = parseFloat(this.data[6].dyanXTime);
    let modTimeXValue2 = this.getDynaTimeXRefernce(dynaModX2);
    draggedData.push({
      dyanXTime: modTimeXValue2,
      dyanYOutput: parseInt(this.data[6].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[6].dynaZfadeTime),
    });

    let dynaModX3 = parseFloat(this.data[8].dyanXTime);
    let modTimeXValue3 = this.getDynaTimeXRefernce(dynaModX3);
    draggedData.push({
      dyanXTime: modTimeXValue3,
      dyanYOutput: parseInt(this.data[8].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[8].dynaZfadeTime),
    });

    let dynaModX4 = parseFloat(this.data[10].dyanXTime);
    let modTimeXValue4 = this.getDynaTimeXRefernce(dynaModX4);
    draggedData.push({
      dyanXTime: modTimeXValue4,
      dyanYOutput: parseInt(this.data[10].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[10].dynaZfadeTime),
    });
  }
  if (this.changedPoint === 2) {
    //OUTPUT
    let modifiedOutput = parseInt(this.data[2].dyanYOutput);

    let dynaModX = parseFloat(this.data[1].dyanXTime);
    let modTimeXValue = this.getDynaTimeXRefernce(dynaModX);
    draggedData.push({
      dyanXTime: modTimeXValue,
      dyanYOutput: modifiedOutput,
      dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
    });

    let dynaModX1 = parseFloat(this.data[4].dyanXTime);
    let modTimeXValue1 = this.getDynaTimeXRefernce(dynaModX1);
    draggedData.push({
      dyanXTime: modTimeXValue1,
      dyanYOutput: parseInt(this.data[4].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
    });

    let dynaModX2 = parseFloat(this.data[6].dyanXTime);
    let modTimeXValue2 = this.getDynaTimeXRefernce(dynaModX2);
    draggedData.push({
      dyanXTime: modTimeXValue2,
      dyanYOutput: parseInt(this.data[6].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[6].dynaZfadeTime),
    });

    let dynaModX3 = parseFloat(this.data[8].dyanXTime);
    let modTimeXValue3 = this.getDynaTimeXRefernce(dynaModX3);
    draggedData.push({
      dyanXTime: modTimeXValue3,
      dyanYOutput: parseInt(this.data[8].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[8].dynaZfadeTime),
    });
    let dynaModX4 = parseFloat(this.data[10].dyanXTime);
    let modTimeXValue4 = this.getDynaTimeXRefernce(dynaModX4);
    draggedData.push({
      dyanXTime: modTimeXValue4,
      dyanYOutput: parseInt(this.data[10].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[10].dynaZfadeTime),
    });
  }
  if (this.changedPoint === 3) {
    //TIME ONLY
    let modifiedOutput = parseFloat(this.data[3].dyanXTime);

    let dynaModX = parseFloat(this.data[2].dyanXTime);
    let modTimeXValue = this.getDynaTimeXRefernce(dynaModX);
    draggedData.push({
      dyanXTime: modTimeXValue,
      dyanYOutput: parseInt(this.data[2].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
    });

    let modTimeXValue1 = this.getDynaTimeXRefernce(modifiedOutput);
    draggedData.push({
      dyanXTime: modTimeXValue1,
      dyanYOutput: parseInt(this.data[4].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
    });

    let dynaModX2 = parseFloat(this.data[6].dyanXTime);
    let modTimeXValue2 = this.getDynaTimeXRefernce(dynaModX2);
    draggedData.push({
      dyanXTime: modTimeXValue2,
      dyanYOutput: parseInt(this.data[6].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[6].dynaZfadeTime),
    });
    let dynaModX3 = parseFloat(this.data[8].dyanXTime);
    let modTimeXValue3 = this.getDynaTimeXRefernce(dynaModX3);
    draggedData.push({
      dyanXTime: modTimeXValue3,
      dyanYOutput: parseInt(this.data[8].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[8].dynaZfadeTime),
    });
    let dynaModX4 = parseFloat(this.data[10].dyanXTime);
    let modTimeXValue4 = this.getDynaTimeXRefernce(dynaModX4);
    draggedData.push({
      dyanXTime: modTimeXValue4,
      dyanYOutput: parseInt(this.data[10].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[10].dynaZfadeTime),
    });
  }

  if (this.changedPoint === 4) {
    //OUTPUT ONLY - Chnage Scene 2
    let modifiedOutput = parseInt(this.data[4].dyanYOutput);

    let dynaModX = parseFloat(this.data[2].dyanXTime);
    let modTimeXValue = this.getDynaTimeXRefernce(dynaModX);
    draggedData.push({
      dyanXTime: modTimeXValue,
      dyanYOutput: parseInt(this.data[2].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
    });

    let dynaModX1 = parseFloat(this.data[3].dyanXTime);
    let modTimeXValue1 = this.getDynaTimeXRefernce(dynaModX1);
    draggedData.push({
      dyanXTime: modTimeXValue1,
      dyanYOutput: modifiedOutput,
      dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
    });

    let dynaModX2 = parseFloat(this.data[6].dyanXTime);
    let modTimeXValue2 = this.getDynaTimeXRefernce(dynaModX2);
    draggedData.push({
      dyanXTime: modTimeXValue2,
      dyanYOutput: parseInt(this.data[6].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[6].dynaZfadeTime),
    });

    let dynaModX3 = parseFloat(this.data[8].dyanXTime);
    let modTimeXValue3 = this.getDynaTimeXRefernce(dynaModX3);
    draggedData.push({
      dyanXTime: modTimeXValue3,
      dyanYOutput: parseInt(this.data[8].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[8].dynaZfadeTime),
    });
    let dynaModX4 = parseFloat(this.data[10].dyanXTime);
    let modTimeXValue4 = this.getDynaTimeXRefernce(dynaModX4);
    draggedData.push({
      dyanXTime: modTimeXValue4,
      dyanYOutput: parseInt(this.data[10].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[10].dynaZfadeTime),
    });
  }
  if (this.changedPoint === 5) {
    //TIME ONLY
    let modifiedOutput = parseFloat(this.data[5].dyanXTime);

    let dynaModX = parseFloat(this.data[2].dyanXTime);
    let modTimeXValue = this.getDynaTimeXRefernce(dynaModX);
    draggedData.push({
      dyanXTime: modTimeXValue,
      dyanYOutput: parseInt(this.data[2].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
    });

    let dynaModX1 = parseFloat(this.data[4].dyanXTime);
    let modTimeXValue1 = this.getDynaTimeXRefernce(dynaModX1);
    draggedData.push({
      dyanXTime: modTimeXValue1,
      dyanYOutput: parseInt(this.data[4].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
    });

    let modTimeXValue2 = this.getDynaTimeXRefernce(modifiedOutput);
    draggedData.push({
      dyanXTime: modTimeXValue2,
      dyanYOutput: parseInt(this.data[6].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[6].dynaZfadeTime),
    });

    let dynaModX3 = parseFloat(this.data[8].dyanXTime);
    let modTimeXValue3 = this.getDynaTimeXRefernce(dynaModX3);
    draggedData.push({
      dyanXTime: modTimeXValue3,
      dyanYOutput: parseInt(this.data[8].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[8].dynaZfadeTime),
    });
    let dynaModX4 = parseFloat(this.data[10].dyanXTime);
    let modTimeXValue4 = this.getDynaTimeXRefernce(dynaModX4);
    draggedData.push({
      dyanXTime: modTimeXValue4,
      dyanYOutput: parseInt(this.data[10].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[10].dynaZfadeTime),
    });
  }
  if (this.changedPoint === 6) {
    //OUTPUT ONLY - Chnage Scene 2
    let modifiedOutput = parseInt(this.data[6].dyanYOutput);

    let dynaModX = parseFloat(this.data[2].dyanXTime);
    let modTimeXValue = this.getDynaTimeXRefernce(dynaModX);
    draggedData.push({
      dyanXTime: modTimeXValue,
      dyanYOutput: parseInt(this.data[2].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
    });

    let dynaModX1 = parseFloat(this.data[4].dyanXTime);
    let modTimeXValue1 = this.getDynaTimeXRefernce(dynaModX1);
    draggedData.push({
      dyanXTime: modTimeXValue1,
      dyanYOutput: parseInt(this.data[4].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
    });

    let dynaModX2 = parseFloat(this.data[5].dyanXTime);
    let modTimeXValue2 = this.getDynaTimeXRefernce(dynaModX2);
    draggedData.push({
      dyanXTime: modTimeXValue2,
      dyanYOutput: modifiedOutput,
      dynaZfadeTime: parseInt(this.data[6].dynaZfadeTime),
    });

    let dynaModX3 = parseFloat(this.data[8].dyanXTime);
    let modTimeXValue3 = this.getDynaTimeXRefernce(dynaModX3);
    draggedData.push({
      dyanXTime: modTimeXValue3,
      dyanYOutput: parseInt(this.data[8].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[8].dynaZfadeTime),
    });
    let dynaModX4 = parseFloat(this.data[10].dyanXTime);
    let modTimeXValue4 = this.getDynaTimeXRefernce(dynaModX4);
    draggedData.push({
      dyanXTime: modTimeXValue4,
      dyanYOutput: parseInt(this.data[10].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[10].dynaZfadeTime),
    });
  }
  if (this.changedPoint === 7) {
    //TIME ONLY
    let modifiedOutput = parseFloat(this.data[7].dyanXTime);

    let dynaModX = parseFloat(this.data[2].dyanXTime);
    let modTimeXValue = this.getDynaTimeXRefernce(dynaModX);
    draggedData.push({
      dyanXTime: modTimeXValue,
      dyanYOutput: parseInt(this.data[2].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
    });

    let dynaModX1 = parseFloat(this.data[4].dyanXTime);
    let modTimeXValue1 = this.getDynaTimeXRefernce(dynaModX1);
    draggedData.push({
      dyanXTime: modTimeXValue1,
      dyanYOutput: parseInt(this.data[4].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
    });

    let dynaModX2 = parseFloat(this.data[6].dyanXTime);
    let modTimeXValue2 = this.getDynaTimeXRefernce(dynaModX2);
    draggedData.push({
      dyanXTime: modTimeXValue2,
      dyanYOutput: parseInt(this.data[6].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[6].dynaZfadeTime),
    });

    let modTimeXValue3 = this.getDynaTimeXRefernce(modifiedOutput);
    draggedData.push({
      dyanXTime: modTimeXValue3,
      dyanYOutput: parseInt(this.data[8].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[8].dynaZfadeTime),
    });

    let dynaModX4 = parseFloat(this.data[10].dyanXTime);
    let modTimeXValue4 = this.getDynaTimeXRefernce(dynaModX4);
    draggedData.push({
      dyanXTime: modTimeXValue4,
      dyanYOutput: parseInt(this.data[10].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[10].dynaZfadeTime),
    });
  }
  if (this.changedPoint === 8) {
    //OUTPUT ONLY - Chnage Scene 2
    let modifiedOutput = parseInt(this.data[8].dyanYOutput);

    let dynaModX = parseFloat(this.data[2].dyanXTime);
    let modTimeXValue = this.getDynaTimeXRefernce(dynaModX);
    draggedData.push({
      dyanXTime: modTimeXValue,
      dyanYOutput: parseInt(this.data[2].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
    });

    let dynaModX1 = parseFloat(this.data[4].dyanXTime);
    let modTimeXValue1 = this.getDynaTimeXRefernce(dynaModX1);
    draggedData.push({
      dyanXTime: modTimeXValue1,
      dyanYOutput: parseInt(this.data[4].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
    });

    let dynaModX2 = parseFloat(this.data[6].dyanXTime);
    let modTimeXValue2 = this.getDynaTimeXRefernce(dynaModX2);
    draggedData.push({
      dyanXTime: modTimeXValue2,
      dyanYOutput: parseInt(this.data[6].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[6].dynaZfadeTime),
    });

    let dynaModX3 = parseFloat(this.data[7].dyanXTime);
    let modTimeXValue3 = this.getDynaTimeXRefernce(dynaModX3);
    draggedData.push({
      dyanXTime: modTimeXValue3,
      dyanYOutput: modifiedOutput,
      dynaZfadeTime: parseInt(this.data[8].dynaZfadeTime),
    });

    let dynaModX4 = parseFloat(this.data[10].dyanXTime);
    let modTimeXValue4 = this.getDynaTimeXRefernce(dynaModX4);
    draggedData.push({
      dyanXTime: modTimeXValue4,
      dyanYOutput: parseInt(this.data[10].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[10].dynaZfadeTime),
    });
  }
  if (this.changedPoint === 9) {
    //TIME ONLY
    let modifiedOutput = parseFloat(this.data[9].dyanXTime);

    let dynaModX = parseFloat(this.data[2].dyanXTime);
    let modTimeXValue = this.getDynaTimeXRefernce(dynaModX);
    draggedData.push({
      dyanXTime: modTimeXValue,
      dyanYOutput: parseInt(this.data[2].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
    });

    let dynaModX1 = parseFloat(this.data[4].dyanXTime);
    let modTimeXValue1 = this.getDynaTimeXRefernce(dynaModX1);
    draggedData.push({
      dyanXTime: modTimeXValue1,
      dyanYOutput: parseInt(this.data[4].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
    });

    let dynaModX2 = parseFloat(this.data[6].dyanXTime);
    let modTimeXValue2 = this.getDynaTimeXRefernce(dynaModX2);
    draggedData.push({
      dyanXTime: modTimeXValue2,
      dyanYOutput: parseInt(this.data[6].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[6].dynaZfadeTime),
    });

    let dynaModX3 = parseFloat(this.data[8].dyanXTime);
    let modTimeXValue3 = this.getDynaTimeXRefernce(dynaModX3);
    draggedData.push({
      dyanXTime: modTimeXValue3,
      dyanYOutput: parseInt(this.data[8].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[8].dynaZfadeTime),
    });

    let modTimeXValue4 = this.getDynaTimeXRefernce(modifiedOutput);
    draggedData.push({
      dyanXTime: modTimeXValue4,
      dyanYOutput: parseInt(this.data[10].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[10].dynaZfadeTime),
    });
  }
  if (this.changedPoint === 10) {
    //OUTPUT ONLY - Chnage Scene 2
    let modifiedOutput = parseInt(this.data[10].dyanYOutput);

    let dynaModX = parseFloat(this.data[2].dyanXTime);
    let modTimeXValue = this.getDynaTimeXRefernce(dynaModX);
    draggedData.push({
      dyanXTime: modTimeXValue,
      dyanYOutput: parseInt(this.data[2].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
    });

    let dynaModX1 = parseFloat(this.data[4].dyanXTime);
    let modTimeXValue1 = this.getDynaTimeXRefernce(dynaModX1);
    draggedData.push({
      dyanXTime: modTimeXValue1,
      dyanYOutput: parseInt(this.data[4].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
    });

    let dynaModX2 = parseFloat(this.data[6].dyanXTime);
    let modTimeXValue2 = this.getDynaTimeXRefernce(dynaModX2);
    draggedData.push({
      dyanXTime: modTimeXValue2,
      dyanYOutput: parseInt(this.data[6].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[6].dynaZfadeTime),
    });

    let dynaModX3 = parseFloat(this.data[8].dyanXTime);
    let modTimeXValue3 = this.getDynaTimeXRefernce(dynaModX3);
    draggedData.push({
      dyanXTime: modTimeXValue3,
      dyanYOutput: parseInt(this.data[8].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[8].dynaZfadeTime),
    });

    let dynaModX4 = parseFloat(this.data[9].dyanXTime);
    let modTimeXValue4 = this.getDynaTimeXRefernce(dynaModX4);
    draggedData.push({
      dyanXTime: modTimeXValue4,
      dyanYOutput: modifiedOutput,
      dynaZfadeTime: parseInt(this.data[10].dynaZfadeTime),
    });
  }
  if (this.changedPoint === 11) {
    let modifiedOutput = parseInt(this.data[11].dyanXTime);

    let dynaModX = parseFloat(this.data[2].dyanXTime);
    let modTimeXValue = this.getDynaTimeXRefernce(dynaModX);
    draggedData.push({
      dyanXTime: modTimeXValue,
      dyanYOutput: parseInt(this.data[2].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
    });

    let dynaModX1 = parseFloat(this.data[4].dyanXTime);
    let modTimeXValue1 = this.getDynaTimeXRefernce(dynaModX1);
    draggedData.push({
      dyanXTime: modTimeXValue1,
      dyanYOutput: parseInt(this.data[4].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
    });

    let dynaModX2 = parseFloat(this.data[6].dyanXTime);
    let modTimeXValue2 = this.getDynaTimeXRefernce(dynaModX2);
    draggedData.push({
      dyanXTime: modTimeXValue2,
      dyanYOutput: parseInt(this.data[6].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[6].dynaZfadeTime),
    });

    let dynaModX3 = parseFloat(this.data[8].dyanXTime);
    let modTimeXValue3 = this.getDynaTimeXRefernce(dynaModX3);
    draggedData.push({
      dyanXTime: modTimeXValue3,
      dyanYOutput: parseInt(this.data[8].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[8].dynaZfadeTime),
    });
    let dynaModX4 = parseFloat(this.data[10].dyanXTime);
    let modTimeXValue4 = this.getDynaTimeXRefernce(dynaModX4);
    draggedData.push({
      dyanXTime: modTimeXValue4,
      dyanYOutput: parseInt(this.data[10].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[10].dynaZfadeTime),
    });
    let dynaModX5= parseFloat(this.data[11].dyanXTime);
    let modTimeXValue5 = this.getDynaTimeXRefernce(dynaModX5);
    draggedData.push({
      dyanXTime: modTimeXValue5,
      dyanYOutput: parseInt(this.data[12].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[11].dynaZfadeTime),
    });
  }

  if (this.changedPoint === 12) {
    //output Only
    let modifiedOutput = parseInt(this.data[12].dyanYOutput);

    let dynaModX = parseFloat(this.data[2].dyanXTime);
    let modTimeXValue = this.getDynaTimeXRefernce(dynaModX);
    draggedData.push({
      dyanXTime: modTimeXValue,
      dyanYOutput: parseInt(this.data[2].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
    });

    let dynaModX1 = parseFloat(this.data[4].dyanXTime);
    let modTimeXValue1 = this.getDynaTimeXRefernce(dynaModX1);
    draggedData.push({
      dyanXTime: modTimeXValue1,
      dyanYOutput: parseInt(this.data[4].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
    });

    let dynaModX2 = parseFloat(this.data[6].dyanXTime);
    let modTimeXValue2 = this.getDynaTimeXRefernce(dynaModX2);
    draggedData.push({
      dyanXTime: modTimeXValue2,
      dyanYOutput: parseInt(this.data[6].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[6].dynaZfadeTime),
    });

    let dynaModX3 = parseFloat(this.data[8].dyanXTime);
    let modTimeXValue3 = this.getDynaTimeXRefernce(dynaModX3);
    draggedData.push({
      dyanXTime: modTimeXValue3,
      dyanYOutput: parseInt(this.data[8].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[8].dynaZfadeTime),
    });
    let dynaModX4 = parseFloat(this.data[10].dyanXTime);
    let modTimeXValue4 = this.getDynaTimeXRefernce(dynaModX4);
    draggedData.push({
      dyanXTime: modTimeXValue4,
      dyanYOutput: parseInt(this.data[10].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[10].dynaZfadeTime),
    });
    let dynaModX5 = parseFloat(this.data[11].dyanXTime);
    let modTimeXValue5 = this.getDynaTimeXRefernce(dynaModX5);
    draggedData.push({
      dyanXTime: modTimeXValue5,
      dyanYOutput: parseInt(this.data[11].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[12].dynaZfadeTime),
    });
  }
  if (this.changedPoint === 13) {
    let modifiedOutput = parseInt(this.data[13].dyanXTime);

    let dynaModX = parseFloat(this.data[2].dyanXTime);
    let modTimeXValue = this.getDynaTimeXRefernce(dynaModX);
    draggedData.push({
      dyanXTime: modTimeXValue,
      dyanYOutput: parseInt(this.data[2].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
    });

    let dynaModX1 = parseFloat(this.data[4].dyanXTime);
    let modTimeXValue1 = this.getDynaTimeXRefernce(dynaModX1);
    draggedData.push({
      dyanXTime: modTimeXValue1,
      dyanYOutput: parseInt(this.data[4].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
    });

    let dynaModX2 = parseFloat(this.data[6].dyanXTime);
    let modTimeXValue2 = this.getDynaTimeXRefernce(dynaModX2);
    draggedData.push({
      dyanXTime: modTimeXValue2,
      dyanYOutput: parseInt(this.data[6].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[6].dynaZfadeTime),
    });

    let dynaModX3 = parseFloat(this.data[8].dyanXTime);
    let modTimeXValue3 = this.getDynaTimeXRefernce(dynaModX3);
    draggedData.push({
      dyanXTime: modTimeXValue3,
      dyanYOutput: parseInt(this.data[8].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[8].dynaZfadeTime),
    });
    let dynaModX4 = parseFloat(this.data[10].dyanXTime);
    let modTimeXValue4 = this.getDynaTimeXRefernce(dynaModX4);
    draggedData.push({
      dyanXTime: modTimeXValue4,
      dyanYOutput: parseInt(this.data[10].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[10].dynaZfadeTime),
    });
    let dynaModX5= parseFloat(this.data[11].dyanXTime);
    let modTimeXValue5 = this.getDynaTimeXRefernce(dynaModX5);
    draggedData.push({
      dyanXTime: modTimeXValue5,
      dyanYOutput: parseInt(this.data[12].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[11].dynaZfadeTime),
    });

    let dynaModX6= parseFloat(this.data[13].dyanXTime);
    let modTimeXValue6 = this.getDynaTimeXRefernce(dynaModX6);
    draggedData.push({
      dyanXTime: modTimeXValue6,
      dyanYOutput: parseInt(this.data[14].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[13].dynaZfadeTime),
    });
  }

  if (this.changedPoint === 14) {
    //output Only
    let modifiedOutput = parseInt(this.data[14].dyanYOutput);

    let dynaModX = parseFloat(this.data[2].dyanXTime);
    let modTimeXValue = this.getDynaTimeXRefernce(dynaModX);
    draggedData.push({
      dyanXTime: modTimeXValue,
      dyanYOutput: parseInt(this.data[2].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
    });

    let dynaModX1 = parseFloat(this.data[4].dyanXTime);
    let modTimeXValue1 = this.getDynaTimeXRefernce(dynaModX1);
    draggedData.push({
      dyanXTime: modTimeXValue1,
      dyanYOutput: parseInt(this.data[4].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
    });

    let dynaModX2 = parseFloat(this.data[6].dyanXTime);
    let modTimeXValue2 = this.getDynaTimeXRefernce(dynaModX2);
    draggedData.push({
      dyanXTime: modTimeXValue2,
      dyanYOutput: parseInt(this.data[6].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[6].dynaZfadeTime),
    });

    let dynaModX3 = parseFloat(this.data[8].dyanXTime);
    let modTimeXValue3 = this.getDynaTimeXRefernce(dynaModX3);
    draggedData.push({
      dyanXTime: modTimeXValue3,
      dyanYOutput: parseInt(this.data[8].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[8].dynaZfadeTime),
    });
    let dynaModX4 = parseFloat(this.data[10].dyanXTime);
    let modTimeXValue4 = this.getDynaTimeXRefernce(dynaModX4);
    draggedData.push({
      dyanXTime: modTimeXValue4,
      dyanYOutput: parseInt(this.data[10].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[10].dynaZfadeTime),
    });
    let dynaModX5 = parseFloat(this.data[12].dyanXTime);
    let modTimeXValue5 = this.getDynaTimeXRefernce(dynaModX5);
    draggedData.push({
      dyanXTime: modTimeXValue5,
      dyanYOutput: parseInt(this.data[12].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[12].dynaZfadeTime),
    });

    let dynaModX6 = parseFloat(this.data[14].dyanXTime);
    let modTimeXValue6 = this.getDynaTimeXRefernce(dynaModX6);
    draggedData.push({
      dyanXTime: modTimeXValue6,
      dyanYOutput: modifiedOutput,
      dynaZfadeTime: parseInt(this.data[14].dynaZfadeTime),
    });
    
  }
  if (this.changedPoint === 15) {
    let modifiedOutput = parseInt(this.data[15].dyanXTime);

    let dynaModX = parseFloat(this.data[2].dyanXTime);
    let modTimeXValue = this.getDynaTimeXRefernce(dynaModX);
    draggedData.push({
      dyanXTime: modTimeXValue,
      dyanYOutput: parseInt(this.data[2].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
    });

    let dynaModX1 = parseFloat(this.data[4].dyanXTime);
    let modTimeXValue1 = this.getDynaTimeXRefernce(dynaModX1);
    draggedData.push({
      dyanXTime: modTimeXValue1,
      dyanYOutput: parseInt(this.data[4].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
    });

    let dynaModX2 = parseFloat(this.data[6].dyanXTime);
    let modTimeXValue2 = this.getDynaTimeXRefernce(dynaModX2);
    draggedData.push({
      dyanXTime: modTimeXValue2,
      dyanYOutput: parseInt(this.data[6].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[6].dynaZfadeTime),
    });

    let dynaModX3 = parseFloat(this.data[8].dyanXTime);
    let modTimeXValue3 = this.getDynaTimeXRefernce(dynaModX3);
    draggedData.push({
      dyanXTime: modTimeXValue3,
      dyanYOutput: parseInt(this.data[8].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[8].dynaZfadeTime),
    });
    let dynaModX4 = parseFloat(this.data[10].dyanXTime);
    let modTimeXValue4 = this.getDynaTimeXRefernce(dynaModX4);
    draggedData.push({
      dyanXTime: modTimeXValue4,
      dyanYOutput: parseInt(this.data[10].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[10].dynaZfadeTime),
    });
    let dynaModX5= parseFloat(this.data[11].dyanXTime);
    let modTimeXValue5 = this.getDynaTimeXRefernce(dynaModX5);
    draggedData.push({
      dyanXTime: modTimeXValue5,
      dyanYOutput: parseInt(this.data[12].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[11].dynaZfadeTime),
    });

    let dynaModX6= parseFloat(this.data[13].dyanXTime);
    let modTimeXValue6 = this.getDynaTimeXRefernce(dynaModX6);
    draggedData.push({
      dyanXTime: modTimeXValue6,
      dyanYOutput: parseInt(this.data[14].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[13].dynaZfadeTime),
    });

    let dynaModX7= parseFloat(this.data[15].dyanXTime);
    let modTimeXValue7 = this.getDynaTimeXRefernce(dynaModX7);
    draggedData.push({
      dyanXTime: modTimeXValue7,
      dyanYOutput: parseInt(this.data[16].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[15].dynaZfadeTime),
    });
  }

  if (this.changedPoint === 16) {
    //output Only
    let modifiedOutput = parseInt(this.data[16].dyanYOutput);

    let dynaModX = parseFloat(this.data[2].dyanXTime);
    let modTimeXValue = this.getDynaTimeXRefernce(dynaModX);
    draggedData.push({
      dyanXTime: modTimeXValue,
      dyanYOutput: parseInt(this.data[2].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
    });

    let dynaModX1 = parseFloat(this.data[4].dyanXTime);
    let modTimeXValue1 = this.getDynaTimeXRefernce(dynaModX1);
    draggedData.push({
      dyanXTime: modTimeXValue1,
      dyanYOutput: parseInt(this.data[4].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
    });

    let dynaModX2 = parseFloat(this.data[6].dyanXTime);
    let modTimeXValue2 = this.getDynaTimeXRefernce(dynaModX2);
    draggedData.push({
      dyanXTime: modTimeXValue2,
      dyanYOutput: parseInt(this.data[6].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[6].dynaZfadeTime),
    });

    let dynaModX3 = parseFloat(this.data[8].dyanXTime);
    let modTimeXValue3 = this.getDynaTimeXRefernce(dynaModX3);
    draggedData.push({
      dyanXTime: modTimeXValue3,
      dyanYOutput: parseInt(this.data[8].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[8].dynaZfadeTime),
    });
    let dynaModX4 = parseFloat(this.data[10].dyanXTime);
    let modTimeXValue4 = this.getDynaTimeXRefernce(dynaModX4);
    draggedData.push({
      dyanXTime: modTimeXValue4,
      dyanYOutput: parseInt(this.data[10].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[10].dynaZfadeTime),
    });
    let dynaModX5 = parseFloat(this.data[12].dyanXTime);
    let modTimeXValue5 = this.getDynaTimeXRefernce(dynaModX5);
    draggedData.push({
      dyanXTime: modTimeXValue5,
      dyanYOutput: parseInt(this.data[12].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[12].dynaZfadeTime),
    });

    let dynaModX6 = parseFloat(this.data[14].dyanXTime);
    let modTimeXValue6 = this.getDynaTimeXRefernce(dynaModX6);
    draggedData.push({
      dyanXTime: modTimeXValue6,
      dyanYOutput: parseInt(this.data[14].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[14].dynaZfadeTime),
    });

    let dynaModX7 = parseFloat(this.data[16].dyanXTime);
    let modTimeXValue7 = this.getDynaTimeXRefernce(dynaModX7);
    draggedData.push({
      dyanXTime: modTimeXValue7,
      dyanYOutput: modifiedOutput,
      dynaZfadeTime: parseInt(this.data[16].dynaZfadeTime),
    });
    
  }

  if (this.changedPoint === 17) {
    let modifiedOutput = parseInt(this.data[17].dyanXTime);

    let dynaModX = parseFloat(this.data[2].dyanXTime);
    let modTimeXValue = this.getDynaTimeXRefernce(dynaModX);
    draggedData.push({
      dyanXTime: modTimeXValue,
      dyanYOutput: parseInt(this.data[2].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
    });

    let dynaModX1 = parseFloat(this.data[4].dyanXTime);
    let modTimeXValue1 = this.getDynaTimeXRefernce(dynaModX1);
    draggedData.push({
      dyanXTime: modTimeXValue1,
      dyanYOutput: parseInt(this.data[4].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
    });

    let dynaModX2 = parseFloat(this.data[6].dyanXTime);
    let modTimeXValue2 = this.getDynaTimeXRefernce(dynaModX2);
    draggedData.push({
      dyanXTime: modTimeXValue2,
      dyanYOutput: parseInt(this.data[6].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[6].dynaZfadeTime),
    });

    let dynaModX3 = parseFloat(this.data[8].dyanXTime);
    let modTimeXValue3 = this.getDynaTimeXRefernce(dynaModX3);
    draggedData.push({
      dyanXTime: modTimeXValue3,
      dyanYOutput: parseInt(this.data[8].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[8].dynaZfadeTime),
    });
    let dynaModX4 = parseFloat(this.data[10].dyanXTime);
    let modTimeXValue4 = this.getDynaTimeXRefernce(dynaModX4);
    draggedData.push({
      dyanXTime: modTimeXValue4,
      dyanYOutput: parseInt(this.data[10].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[10].dynaZfadeTime),
    });
    let dynaModX5= parseFloat(this.data[11].dyanXTime);
    let modTimeXValue5 = this.getDynaTimeXRefernce(dynaModX5);
    draggedData.push({
      dyanXTime: modTimeXValue5,
      dyanYOutput: parseInt(this.data[12].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[11].dynaZfadeTime),
    });

    let dynaModX6= parseFloat(this.data[13].dyanXTime);
    let modTimeXValue6 = this.getDynaTimeXRefernce(dynaModX6);
    draggedData.push({
      dyanXTime: modTimeXValue6,
      dyanYOutput: parseInt(this.data[14].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[13].dynaZfadeTime),
    });

    let dynaModX7= parseFloat(this.data[15].dyanXTime);
    let modTimeXValue7 = this.getDynaTimeXRefernce(dynaModX7);
    draggedData.push({
      dyanXTime: modTimeXValue7,
      dyanYOutput: parseInt(this.data[16].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[15].dynaZfadeTime),
    });

    let dynaModX8= parseFloat(this.data[17].dyanXTime);
    let modTimeXValue8 = this.getDynaTimeXRefernce(dynaModX8);
    draggedData.push({
      dyanXTime: modTimeXValue8,
      dyanYOutput: parseInt(this.data[18].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[17].dynaZfadeTime),
    });
  }

  if (this.changedPoint === 18) {
    //output Only
    let modifiedOutput = parseInt(this.data[16].dyanYOutput);

    let dynaModX = parseFloat(this.data[2].dyanXTime);
    let modTimeXValue = this.getDynaTimeXRefernce(dynaModX);
    draggedData.push({
      dyanXTime: modTimeXValue,
      dyanYOutput: parseInt(this.data[2].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
    });

    let dynaModX1 = parseFloat(this.data[4].dyanXTime);
    let modTimeXValue1 = this.getDynaTimeXRefernce(dynaModX1);
    draggedData.push({
      dyanXTime: modTimeXValue1,
      dyanYOutput: parseInt(this.data[4].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
    });

    let dynaModX2 = parseFloat(this.data[6].dyanXTime);
    let modTimeXValue2 = this.getDynaTimeXRefernce(dynaModX2);
    draggedData.push({
      dyanXTime: modTimeXValue2,
      dyanYOutput: parseInt(this.data[6].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[6].dynaZfadeTime),
    });

    let dynaModX3 = parseFloat(this.data[8].dyanXTime);
    let modTimeXValue3 = this.getDynaTimeXRefernce(dynaModX3);
    draggedData.push({
      dyanXTime: modTimeXValue3,
      dyanYOutput: parseInt(this.data[8].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[8].dynaZfadeTime),
    });
    let dynaModX4 = parseFloat(this.data[10].dyanXTime);
    let modTimeXValue4 = this.getDynaTimeXRefernce(dynaModX4);
    draggedData.push({
      dyanXTime: modTimeXValue4,
      dyanYOutput: parseInt(this.data[10].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[10].dynaZfadeTime),
    });
    let dynaModX5 = parseFloat(this.data[12].dyanXTime);
    let modTimeXValue5 = this.getDynaTimeXRefernce(dynaModX5);
    draggedData.push({
      dyanXTime: modTimeXValue5,
      dyanYOutput: parseInt(this.data[12].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[12].dynaZfadeTime),
    });

    let dynaModX6 = parseFloat(this.data[14].dyanXTime);
    let modTimeXValue6 = this.getDynaTimeXRefernce(dynaModX6);
    draggedData.push({
      dyanXTime: modTimeXValue6,
      dyanYOutput: parseInt(this.data[14].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[14].dynaZfadeTime),
    });

    let dynaModX7 = parseFloat(this.data[16].dyanXTime);
    let modTimeXValue7 = this.getDynaTimeXRefernce(dynaModX7);
    draggedData.push({
      dyanXTime: modTimeXValue7,
      dyanYOutput: parseInt(this.data[16].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[16].dynaZfadeTime),
    });

    
    let dynaModX8 = parseFloat(this.data[18].dyanXTime);
    let modTimeXValue8 = this.getDynaTimeXRefernce(dynaModX8);
    draggedData.push({
      dyanXTime: modTimeXValue8,
      dyanYOutput: modifiedOutput,
      dynaZfadeTime: parseInt(this.data[18].dynaZfadeTime),
    });
    
  }


  if (this.changedPoint === 19) {
    let modifiedOutput = parseInt(this.data[19].dyanXTime);

    let dynaModX = parseFloat(this.data[2].dyanXTime);
    let modTimeXValue = this.getDynaTimeXRefernce(dynaModX);
    draggedData.push({
      dyanXTime: modTimeXValue,
      dyanYOutput: parseInt(this.data[2].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
    });

    let dynaModX1 = parseFloat(this.data[4].dyanXTime);
    let modTimeXValue1 = this.getDynaTimeXRefernce(dynaModX1);
    draggedData.push({
      dyanXTime: modTimeXValue1,
      dyanYOutput: parseInt(this.data[4].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
    });

    let dynaModX2 = parseFloat(this.data[6].dyanXTime);
    let modTimeXValue2 = this.getDynaTimeXRefernce(dynaModX2);
    draggedData.push({
      dyanXTime: modTimeXValue2,
      dyanYOutput: parseInt(this.data[6].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[6].dynaZfadeTime),
    });

    let dynaModX3 = parseFloat(this.data[8].dyanXTime);
    let modTimeXValue3 = this.getDynaTimeXRefernce(dynaModX3);
    draggedData.push({
      dyanXTime: modTimeXValue3,
      dyanYOutput: parseInt(this.data[8].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[8].dynaZfadeTime),
    });
    let dynaModX4 = parseFloat(this.data[10].dyanXTime);
    let modTimeXValue4 = this.getDynaTimeXRefernce(dynaModX4);
    draggedData.push({
      dyanXTime: modTimeXValue4,
      dyanYOutput: parseInt(this.data[10].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[10].dynaZfadeTime),
    });
    let dynaModX5= parseFloat(this.data[11].dyanXTime);
    let modTimeXValue5 = this.getDynaTimeXRefernce(dynaModX5);
    draggedData.push({
      dyanXTime: modTimeXValue5,
      dyanYOutput: parseInt(this.data[12].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[11].dynaZfadeTime),
    });

    let dynaModX6= parseFloat(this.data[13].dyanXTime);
    let modTimeXValue6 = this.getDynaTimeXRefernce(dynaModX6);
    draggedData.push({
      dyanXTime: modTimeXValue6,
      dyanYOutput: parseInt(this.data[14].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[13].dynaZfadeTime),
    });

    let dynaModX7= parseFloat(this.data[15].dyanXTime);
    let modTimeXValue7 = this.getDynaTimeXRefernce(dynaModX7);
    draggedData.push({
      dyanXTime: modTimeXValue7,
      dyanYOutput: parseInt(this.data[16].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[15].dynaZfadeTime),
    });

    let dynaModX8= parseFloat(this.data[17].dyanXTime);
    let modTimeXValue8 = this.getDynaTimeXRefernce(dynaModX8);
    draggedData.push({
      dyanXTime: modTimeXValue8,
      dyanYOutput: parseInt(this.data[18].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[17].dynaZfadeTime),
    });

    let dynaModX9= parseFloat(this.data[19].dyanXTime);
    let modTimeXValue9 = this.getDynaTimeXRefernce(dynaModX9);
    draggedData.push({
      dyanXTime: modTimeXValue9,
      dyanYOutput: parseInt(this.data[20].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[19].dynaZfadeTime),
    });
  }

  if (this.changedPoint === 20) {
    //output Only
    let modifiedOutput = parseInt(this.data[20].dyanYOutput);

    let dynaModX = parseFloat(this.data[2].dyanXTime);
    let modTimeXValue = this.getDynaTimeXRefernce(dynaModX);
    draggedData.push({
      dyanXTime: modTimeXValue,
      dyanYOutput: parseInt(this.data[2].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
    });

    let dynaModX1 = parseFloat(this.data[4].dyanXTime);
    let modTimeXValue1 = this.getDynaTimeXRefernce(dynaModX1);
    draggedData.push({
      dyanXTime: modTimeXValue1,
      dyanYOutput: parseInt(this.data[4].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
    });

    let dynaModX2 = parseFloat(this.data[6].dyanXTime);
    let modTimeXValue2 = this.getDynaTimeXRefernce(dynaModX2);
    draggedData.push({
      dyanXTime: modTimeXValue2,
      dyanYOutput: parseInt(this.data[6].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[6].dynaZfadeTime),
    });

    let dynaModX3 = parseFloat(this.data[8].dyanXTime);
    let modTimeXValue3 = this.getDynaTimeXRefernce(dynaModX3);
    draggedData.push({
      dyanXTime: modTimeXValue3,
      dyanYOutput: parseInt(this.data[8].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[8].dynaZfadeTime),
    });
    let dynaModX4 = parseFloat(this.data[10].dyanXTime);
    let modTimeXValue4 = this.getDynaTimeXRefernce(dynaModX4);
    draggedData.push({
      dyanXTime: modTimeXValue4,
      dyanYOutput: parseInt(this.data[10].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[10].dynaZfadeTime),
    });
    let dynaModX5 = parseFloat(this.data[12].dyanXTime);
    let modTimeXValue5 = this.getDynaTimeXRefernce(dynaModX5);
    draggedData.push({
      dyanXTime: modTimeXValue5,
      dyanYOutput: parseInt(this.data[12].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[12].dynaZfadeTime),
    });

    let dynaModX6 = parseFloat(this.data[14].dyanXTime);
    let modTimeXValue6 = this.getDynaTimeXRefernce(dynaModX6);
    draggedData.push({
      dyanXTime: modTimeXValue6,
      dyanYOutput: parseInt(this.data[14].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[14].dynaZfadeTime),
    });

    let dynaModX7 = parseFloat(this.data[16].dyanXTime);
    let modTimeXValue7 = this.getDynaTimeXRefernce(dynaModX7);
    draggedData.push({
      dyanXTime: modTimeXValue7,
      dyanYOutput: parseInt(this.data[16].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[16].dynaZfadeTime),
    });

    
    let dynaModX8 = parseFloat(this.data[18].dyanXTime);
    let modTimeXValue8 = this.getDynaTimeXRefernce(dynaModX8);
    draggedData.push({
      dyanXTime: modTimeXValue8,
      dyanYOutput: parseInt(this.data[18].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[18].dynaZfadeTime),
    });

    let dynaModX9 = parseFloat(this.data[20].dyanXTime);
    let modTimeXValue9 = this.getDynaTimeXRefernce(dynaModX9);
    draggedData.push({
      dyanXTime: modTimeXValue9,
      dyanYOutput: modifiedOutput,
      dynaZfadeTime: parseInt(this.data[20].dynaZfadeTime),
    });
    
  }

}
////SCENE 10 END

  ///SCENE 9

updateScenes9(data: any[], draggedData: any) {
  if (this.changedPoint === 0) {
    //OUTPUT
    let modifiedOutput = parseInt(this.data[0].dyanYOutput);

    let dynaModX = parseFloat(this.data[2].dyanXTime);
    let modTimeXValue = this.getDynaTimeXRefernce(dynaModX);
    draggedData.push({
      dyanXTime: modTimeXValue,
      dyanYOutput: parseInt(this.data[2].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
    });

    let dynaModX1 = parseFloat(this.data[4].dyanXTime);
    let modTimeXValue1 = this.getDynaTimeXRefernce(dynaModX1);
    draggedData.push({
      dyanXTime: modTimeXValue1,
      dyanYOutput: parseInt(this.data[4].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
    });

    let dynaModX2 = parseFloat(this.data[6].dyanXTime);
    let modTimeXValue2 = this.getDynaTimeXRefernce(dynaModX2);
    draggedData.push({
      dyanXTime: modTimeXValue2,
      dyanYOutput: parseInt(this.data[6].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[6].dynaZfadeTime),
    });

    let dynaModX3 = parseFloat(this.data[8].dyanXTime);
    let modTimeXValue3 = this.getDynaTimeXRefernce(dynaModX3);
    draggedData.push({
      dyanXTime: modTimeXValue3,
      dyanYOutput: parseInt(this.data[8].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[8].dynaZfadeTime),
    });

    let dynaModX4 = parseFloat(this.data[10].dyanXTime);
    let modTimeXValue4 = this.getDynaTimeXRefernce(dynaModX4);
    draggedData.push({
      dyanXTime: modTimeXValue4,
      dyanYOutput: modifiedOutput,
      dynaZfadeTime: parseInt(this.data[10].dynaZfadeTime),
    });
  }
  if (this.changedPoint === 1) {
    //TIME
    let modifiedOutput = parseFloat(this.data[1].dyanXTime);

    let modTimeXValue = this.getDynaTimeXRefernce(modifiedOutput);
    draggedData.push({
      dyanXTime: modTimeXValue,
      dyanYOutput: parseInt(this.data[2].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
    });

    let dynaModX1 = parseFloat(this.data[4].dyanXTime);
    let modTimeXValue1 = this.getDynaTimeXRefernce(dynaModX1);
    draggedData.push({
      dyanXTime: modTimeXValue1,
      dyanYOutput: parseInt(this.data[4].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
    });
    let dynaModX2 = parseFloat(this.data[6].dyanXTime);
    let modTimeXValue2 = this.getDynaTimeXRefernce(dynaModX2);
    draggedData.push({
      dyanXTime: modTimeXValue2,
      dyanYOutput: parseInt(this.data[6].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[6].dynaZfadeTime),
    });

    let dynaModX3 = parseFloat(this.data[8].dyanXTime);
    let modTimeXValue3 = this.getDynaTimeXRefernce(dynaModX3);
    draggedData.push({
      dyanXTime: modTimeXValue3,
      dyanYOutput: parseInt(this.data[8].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[8].dynaZfadeTime),
    });

    let dynaModX4 = parseFloat(this.data[10].dyanXTime);
    let modTimeXValue4 = this.getDynaTimeXRefernce(dynaModX4);
    draggedData.push({
      dyanXTime: modTimeXValue4,
      dyanYOutput: parseInt(this.data[10].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[10].dynaZfadeTime),
    });
  }
  if (this.changedPoint === 2) {
    //OUTPUT
    let modifiedOutput = parseInt(this.data[2].dyanYOutput);

    let dynaModX = parseFloat(this.data[1].dyanXTime);
    let modTimeXValue = this.getDynaTimeXRefernce(dynaModX);
    draggedData.push({
      dyanXTime: modTimeXValue,
      dyanYOutput: modifiedOutput,
      dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
    });

    let dynaModX1 = parseFloat(this.data[4].dyanXTime);
    let modTimeXValue1 = this.getDynaTimeXRefernce(dynaModX1);
    draggedData.push({
      dyanXTime: modTimeXValue1,
      dyanYOutput: parseInt(this.data[4].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
    });

    let dynaModX2 = parseFloat(this.data[6].dyanXTime);
    let modTimeXValue2 = this.getDynaTimeXRefernce(dynaModX2);
    draggedData.push({
      dyanXTime: modTimeXValue2,
      dyanYOutput: parseInt(this.data[6].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[6].dynaZfadeTime),
    });

    let dynaModX3 = parseFloat(this.data[8].dyanXTime);
    let modTimeXValue3 = this.getDynaTimeXRefernce(dynaModX3);
    draggedData.push({
      dyanXTime: modTimeXValue3,
      dyanYOutput: parseInt(this.data[8].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[8].dynaZfadeTime),
    });
    let dynaModX4 = parseFloat(this.data[10].dyanXTime);
    let modTimeXValue4 = this.getDynaTimeXRefernce(dynaModX4);
    draggedData.push({
      dyanXTime: modTimeXValue4,
      dyanYOutput: parseInt(this.data[10].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[10].dynaZfadeTime),
    });
  }
  if (this.changedPoint === 3) {
    //TIME ONLY
    let modifiedOutput = parseFloat(this.data[3].dyanXTime);

    let dynaModX = parseFloat(this.data[2].dyanXTime);
    let modTimeXValue = this.getDynaTimeXRefernce(dynaModX);
    draggedData.push({
      dyanXTime: modTimeXValue,
      dyanYOutput: parseInt(this.data[2].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
    });

    let modTimeXValue1 = this.getDynaTimeXRefernce(modifiedOutput);
    draggedData.push({
      dyanXTime: modTimeXValue1,
      dyanYOutput: parseInt(this.data[4].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
    });

    let dynaModX2 = parseFloat(this.data[6].dyanXTime);
    let modTimeXValue2 = this.getDynaTimeXRefernce(dynaModX2);
    draggedData.push({
      dyanXTime: modTimeXValue2,
      dyanYOutput: parseInt(this.data[6].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[6].dynaZfadeTime),
    });
    let dynaModX3 = parseFloat(this.data[8].dyanXTime);
    let modTimeXValue3 = this.getDynaTimeXRefernce(dynaModX3);
    draggedData.push({
      dyanXTime: modTimeXValue3,
      dyanYOutput: parseInt(this.data[8].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[8].dynaZfadeTime),
    });
    let dynaModX4 = parseFloat(this.data[10].dyanXTime);
    let modTimeXValue4 = this.getDynaTimeXRefernce(dynaModX4);
    draggedData.push({
      dyanXTime: modTimeXValue4,
      dyanYOutput: parseInt(this.data[10].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[10].dynaZfadeTime),
    });
  }

  if (this.changedPoint === 4) {
    //OUTPUT ONLY - Chnage Scene 2
    let modifiedOutput = parseInt(this.data[4].dyanYOutput);

    let dynaModX = parseFloat(this.data[2].dyanXTime);
    let modTimeXValue = this.getDynaTimeXRefernce(dynaModX);
    draggedData.push({
      dyanXTime: modTimeXValue,
      dyanYOutput: parseInt(this.data[2].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
    });

    let dynaModX1 = parseFloat(this.data[3].dyanXTime);
    let modTimeXValue1 = this.getDynaTimeXRefernce(dynaModX1);
    draggedData.push({
      dyanXTime: modTimeXValue1,
      dyanYOutput: modifiedOutput,
      dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
    });

    let dynaModX2 = parseFloat(this.data[6].dyanXTime);
    let modTimeXValue2 = this.getDynaTimeXRefernce(dynaModX2);
    draggedData.push({
      dyanXTime: modTimeXValue2,
      dyanYOutput: parseInt(this.data[6].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[6].dynaZfadeTime),
    });

    let dynaModX3 = parseFloat(this.data[8].dyanXTime);
    let modTimeXValue3 = this.getDynaTimeXRefernce(dynaModX3);
    draggedData.push({
      dyanXTime: modTimeXValue3,
      dyanYOutput: parseInt(this.data[8].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[8].dynaZfadeTime),
    });
    let dynaModX4 = parseFloat(this.data[10].dyanXTime);
    let modTimeXValue4 = this.getDynaTimeXRefernce(dynaModX4);
    draggedData.push({
      dyanXTime: modTimeXValue4,
      dyanYOutput: parseInt(this.data[10].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[10].dynaZfadeTime),
    });
  }
  if (this.changedPoint === 5) {
    //TIME ONLY
    let modifiedOutput = parseFloat(this.data[5].dyanXTime);

    let dynaModX = parseFloat(this.data[2].dyanXTime);
    let modTimeXValue = this.getDynaTimeXRefernce(dynaModX);
    draggedData.push({
      dyanXTime: modTimeXValue,
      dyanYOutput: parseInt(this.data[2].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
    });

    let dynaModX1 = parseFloat(this.data[4].dyanXTime);
    let modTimeXValue1 = this.getDynaTimeXRefernce(dynaModX1);
    draggedData.push({
      dyanXTime: modTimeXValue1,
      dyanYOutput: parseInt(this.data[4].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
    });

    let modTimeXValue2 = this.getDynaTimeXRefernce(modifiedOutput);
    draggedData.push({
      dyanXTime: modTimeXValue2,
      dyanYOutput: parseInt(this.data[6].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[6].dynaZfadeTime),
    });

    let dynaModX3 = parseFloat(this.data[8].dyanXTime);
    let modTimeXValue3 = this.getDynaTimeXRefernce(dynaModX3);
    draggedData.push({
      dyanXTime: modTimeXValue3,
      dyanYOutput: parseInt(this.data[8].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[8].dynaZfadeTime),
    });
    let dynaModX4 = parseFloat(this.data[10].dyanXTime);
    let modTimeXValue4 = this.getDynaTimeXRefernce(dynaModX4);
    draggedData.push({
      dyanXTime: modTimeXValue4,
      dyanYOutput: parseInt(this.data[10].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[10].dynaZfadeTime),
    });
  }
  if (this.changedPoint === 6) {
    //OUTPUT ONLY - Chnage Scene 2
    let modifiedOutput = parseInt(this.data[6].dyanYOutput);

    let dynaModX = parseFloat(this.data[2].dyanXTime);
    let modTimeXValue = this.getDynaTimeXRefernce(dynaModX);
    draggedData.push({
      dyanXTime: modTimeXValue,
      dyanYOutput: parseInt(this.data[2].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
    });

    let dynaModX1 = parseFloat(this.data[4].dyanXTime);
    let modTimeXValue1 = this.getDynaTimeXRefernce(dynaModX1);
    draggedData.push({
      dyanXTime: modTimeXValue1,
      dyanYOutput: parseInt(this.data[4].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
    });

    let dynaModX2 = parseFloat(this.data[5].dyanXTime);
    let modTimeXValue2 = this.getDynaTimeXRefernce(dynaModX2);
    draggedData.push({
      dyanXTime: modTimeXValue2,
      dyanYOutput: modifiedOutput,
      dynaZfadeTime: parseInt(this.data[6].dynaZfadeTime),
    });

    let dynaModX3 = parseFloat(this.data[8].dyanXTime);
    let modTimeXValue3 = this.getDynaTimeXRefernce(dynaModX3);
    draggedData.push({
      dyanXTime: modTimeXValue3,
      dyanYOutput: parseInt(this.data[8].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[8].dynaZfadeTime),
    });
    let dynaModX4 = parseFloat(this.data[10].dyanXTime);
    let modTimeXValue4 = this.getDynaTimeXRefernce(dynaModX4);
    draggedData.push({
      dyanXTime: modTimeXValue4,
      dyanYOutput: parseInt(this.data[10].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[10].dynaZfadeTime),
    });
  }
  if (this.changedPoint === 7) {
    //TIME ONLY
    let modifiedOutput = parseFloat(this.data[7].dyanXTime);

    let dynaModX = parseFloat(this.data[2].dyanXTime);
    let modTimeXValue = this.getDynaTimeXRefernce(dynaModX);
    draggedData.push({
      dyanXTime: modTimeXValue,
      dyanYOutput: parseInt(this.data[2].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
    });

    let dynaModX1 = parseFloat(this.data[4].dyanXTime);
    let modTimeXValue1 = this.getDynaTimeXRefernce(dynaModX1);
    draggedData.push({
      dyanXTime: modTimeXValue1,
      dyanYOutput: parseInt(this.data[4].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
    });

    let dynaModX2 = parseFloat(this.data[6].dyanXTime);
    let modTimeXValue2 = this.getDynaTimeXRefernce(dynaModX2);
    draggedData.push({
      dyanXTime: modTimeXValue2,
      dyanYOutput: parseInt(this.data[6].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[6].dynaZfadeTime),
    });

    let modTimeXValue3 = this.getDynaTimeXRefernce(modifiedOutput);
    draggedData.push({
      dyanXTime: modTimeXValue3,
      dyanYOutput: parseInt(this.data[8].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[8].dynaZfadeTime),
    });

    let dynaModX4 = parseFloat(this.data[10].dyanXTime);
    let modTimeXValue4 = this.getDynaTimeXRefernce(dynaModX4);
    draggedData.push({
      dyanXTime: modTimeXValue4,
      dyanYOutput: parseInt(this.data[10].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[10].dynaZfadeTime),
    });
  }
  if (this.changedPoint === 8) {
    //OUTPUT ONLY - Chnage Scene 2
    let modifiedOutput = parseInt(this.data[8].dyanYOutput);

    let dynaModX = parseFloat(this.data[2].dyanXTime);
    let modTimeXValue = this.getDynaTimeXRefernce(dynaModX);
    draggedData.push({
      dyanXTime: modTimeXValue,
      dyanYOutput: parseInt(this.data[2].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
    });

    let dynaModX1 = parseFloat(this.data[4].dyanXTime);
    let modTimeXValue1 = this.getDynaTimeXRefernce(dynaModX1);
    draggedData.push({
      dyanXTime: modTimeXValue1,
      dyanYOutput: parseInt(this.data[4].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
    });

    let dynaModX2 = parseFloat(this.data[6].dyanXTime);
    let modTimeXValue2 = this.getDynaTimeXRefernce(dynaModX2);
    draggedData.push({
      dyanXTime: modTimeXValue2,
      dyanYOutput: parseInt(this.data[6].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[6].dynaZfadeTime),
    });

    let dynaModX3 = parseFloat(this.data[7].dyanXTime);
    let modTimeXValue3 = this.getDynaTimeXRefernce(dynaModX3);
    draggedData.push({
      dyanXTime: modTimeXValue3,
      dyanYOutput: modifiedOutput,
      dynaZfadeTime: parseInt(this.data[8].dynaZfadeTime),
    });

    let dynaModX4 = parseFloat(this.data[10].dyanXTime);
    let modTimeXValue4 = this.getDynaTimeXRefernce(dynaModX4);
    draggedData.push({
      dyanXTime: modTimeXValue4,
      dyanYOutput: parseInt(this.data[10].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[10].dynaZfadeTime),
    });
  }
  if (this.changedPoint === 9) {
    //TIME ONLY
    let modifiedOutput = parseFloat(this.data[9].dyanXTime);

    let dynaModX = parseFloat(this.data[2].dyanXTime);
    let modTimeXValue = this.getDynaTimeXRefernce(dynaModX);
    draggedData.push({
      dyanXTime: modTimeXValue,
      dyanYOutput: parseInt(this.data[2].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
    });

    let dynaModX1 = parseFloat(this.data[4].dyanXTime);
    let modTimeXValue1 = this.getDynaTimeXRefernce(dynaModX1);
    draggedData.push({
      dyanXTime: modTimeXValue1,
      dyanYOutput: parseInt(this.data[4].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
    });

    let dynaModX2 = parseFloat(this.data[6].dyanXTime);
    let modTimeXValue2 = this.getDynaTimeXRefernce(dynaModX2);
    draggedData.push({
      dyanXTime: modTimeXValue2,
      dyanYOutput: parseInt(this.data[6].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[6].dynaZfadeTime),
    });

    let dynaModX3 = parseFloat(this.data[8].dyanXTime);
    let modTimeXValue3 = this.getDynaTimeXRefernce(dynaModX3);
    draggedData.push({
      dyanXTime: modTimeXValue3,
      dyanYOutput: parseInt(this.data[8].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[8].dynaZfadeTime),
    });

    let modTimeXValue4 = this.getDynaTimeXRefernce(modifiedOutput);
    draggedData.push({
      dyanXTime: modTimeXValue4,
      dyanYOutput: parseInt(this.data[10].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[10].dynaZfadeTime),
    });
  }
  if (this.changedPoint === 10) {
    //OUTPUT ONLY - Chnage Scene 2
    let modifiedOutput = parseInt(this.data[10].dyanYOutput);

    let dynaModX = parseFloat(this.data[2].dyanXTime);
    let modTimeXValue = this.getDynaTimeXRefernce(dynaModX);
    draggedData.push({
      dyanXTime: modTimeXValue,
      dyanYOutput: parseInt(this.data[2].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
    });

    let dynaModX1 = parseFloat(this.data[4].dyanXTime);
    let modTimeXValue1 = this.getDynaTimeXRefernce(dynaModX1);
    draggedData.push({
      dyanXTime: modTimeXValue1,
      dyanYOutput: parseInt(this.data[4].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
    });

    let dynaModX2 = parseFloat(this.data[6].dyanXTime);
    let modTimeXValue2 = this.getDynaTimeXRefernce(dynaModX2);
    draggedData.push({
      dyanXTime: modTimeXValue2,
      dyanYOutput: parseInt(this.data[6].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[6].dynaZfadeTime),
    });

    let dynaModX3 = parseFloat(this.data[8].dyanXTime);
    let modTimeXValue3 = this.getDynaTimeXRefernce(dynaModX3);
    draggedData.push({
      dyanXTime: modTimeXValue3,
      dyanYOutput: parseInt(this.data[8].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[8].dynaZfadeTime),
    });

    let dynaModX4 = parseFloat(this.data[9].dyanXTime);
    let modTimeXValue4 = this.getDynaTimeXRefernce(dynaModX4);
    draggedData.push({
      dyanXTime: modTimeXValue4,
      dyanYOutput: modifiedOutput,
      dynaZfadeTime: parseInt(this.data[10].dynaZfadeTime),
    });
  }
  if (this.changedPoint === 11) {
    let modifiedOutput = parseInt(this.data[11].dyanXTime);

    let dynaModX = parseFloat(this.data[2].dyanXTime);
    let modTimeXValue = this.getDynaTimeXRefernce(dynaModX);
    draggedData.push({
      dyanXTime: modTimeXValue,
      dyanYOutput: parseInt(this.data[2].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
    });

    let dynaModX1 = parseFloat(this.data[4].dyanXTime);
    let modTimeXValue1 = this.getDynaTimeXRefernce(dynaModX1);
    draggedData.push({
      dyanXTime: modTimeXValue1,
      dyanYOutput: parseInt(this.data[4].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
    });

    let dynaModX2 = parseFloat(this.data[6].dyanXTime);
    let modTimeXValue2 = this.getDynaTimeXRefernce(dynaModX2);
    draggedData.push({
      dyanXTime: modTimeXValue2,
      dyanYOutput: parseInt(this.data[6].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[6].dynaZfadeTime),
    });

    let dynaModX3 = parseFloat(this.data[8].dyanXTime);
    let modTimeXValue3 = this.getDynaTimeXRefernce(dynaModX3);
    draggedData.push({
      dyanXTime: modTimeXValue3,
      dyanYOutput: parseInt(this.data[8].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[8].dynaZfadeTime),
    });
    let dynaModX4 = parseFloat(this.data[10].dyanXTime);
    let modTimeXValue4 = this.getDynaTimeXRefernce(dynaModX4);
    draggedData.push({
      dyanXTime: modTimeXValue4,
      dyanYOutput: parseInt(this.data[10].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[10].dynaZfadeTime),
    });
    let dynaModX5= parseFloat(this.data[11].dyanXTime);
    let modTimeXValue5 = this.getDynaTimeXRefernce(dynaModX5);
    draggedData.push({
      dyanXTime: modTimeXValue5,
      dyanYOutput: parseInt(this.data[12].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[11].dynaZfadeTime),
    });
  }

  if (this.changedPoint === 12) {
    //output Only
    let modifiedOutput = parseInt(this.data[12].dyanYOutput);

    let dynaModX = parseFloat(this.data[2].dyanXTime);
    let modTimeXValue = this.getDynaTimeXRefernce(dynaModX);
    draggedData.push({
      dyanXTime: modTimeXValue,
      dyanYOutput: parseInt(this.data[2].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
    });

    let dynaModX1 = parseFloat(this.data[4].dyanXTime);
    let modTimeXValue1 = this.getDynaTimeXRefernce(dynaModX1);
    draggedData.push({
      dyanXTime: modTimeXValue1,
      dyanYOutput: parseInt(this.data[4].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
    });

    let dynaModX2 = parseFloat(this.data[6].dyanXTime);
    let modTimeXValue2 = this.getDynaTimeXRefernce(dynaModX2);
    draggedData.push({
      dyanXTime: modTimeXValue2,
      dyanYOutput: parseInt(this.data[6].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[6].dynaZfadeTime),
    });

    let dynaModX3 = parseFloat(this.data[8].dyanXTime);
    let modTimeXValue3 = this.getDynaTimeXRefernce(dynaModX3);
    draggedData.push({
      dyanXTime: modTimeXValue3,
      dyanYOutput: parseInt(this.data[8].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[8].dynaZfadeTime),
    });
    let dynaModX4 = parseFloat(this.data[10].dyanXTime);
    let modTimeXValue4 = this.getDynaTimeXRefernce(dynaModX4);
    draggedData.push({
      dyanXTime: modTimeXValue4,
      dyanYOutput: parseInt(this.data[10].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[10].dynaZfadeTime),
    });
    let dynaModX5 = parseFloat(this.data[11].dyanXTime);
    let modTimeXValue5 = this.getDynaTimeXRefernce(dynaModX5);
    draggedData.push({
      dyanXTime: modTimeXValue5,
      dyanYOutput: parseInt(this.data[11].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[12].dynaZfadeTime),
    });
  }
  if (this.changedPoint === 13) {
    let modifiedOutput = parseInt(this.data[13].dyanXTime);

    let dynaModX = parseFloat(this.data[2].dyanXTime);
    let modTimeXValue = this.getDynaTimeXRefernce(dynaModX);
    draggedData.push({
      dyanXTime: modTimeXValue,
      dyanYOutput: parseInt(this.data[2].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
    });

    let dynaModX1 = parseFloat(this.data[4].dyanXTime);
    let modTimeXValue1 = this.getDynaTimeXRefernce(dynaModX1);
    draggedData.push({
      dyanXTime: modTimeXValue1,
      dyanYOutput: parseInt(this.data[4].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
    });

    let dynaModX2 = parseFloat(this.data[6].dyanXTime);
    let modTimeXValue2 = this.getDynaTimeXRefernce(dynaModX2);
    draggedData.push({
      dyanXTime: modTimeXValue2,
      dyanYOutput: parseInt(this.data[6].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[6].dynaZfadeTime),
    });

    let dynaModX3 = parseFloat(this.data[8].dyanXTime);
    let modTimeXValue3 = this.getDynaTimeXRefernce(dynaModX3);
    draggedData.push({
      dyanXTime: modTimeXValue3,
      dyanYOutput: parseInt(this.data[8].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[8].dynaZfadeTime),
    });
    let dynaModX4 = parseFloat(this.data[10].dyanXTime);
    let modTimeXValue4 = this.getDynaTimeXRefernce(dynaModX4);
    draggedData.push({
      dyanXTime: modTimeXValue4,
      dyanYOutput: parseInt(this.data[10].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[10].dynaZfadeTime),
    });
    let dynaModX5= parseFloat(this.data[11].dyanXTime);
    let modTimeXValue5 = this.getDynaTimeXRefernce(dynaModX5);
    draggedData.push({
      dyanXTime: modTimeXValue5,
      dyanYOutput: parseInt(this.data[12].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[11].dynaZfadeTime),
    });

    let dynaModX6= parseFloat(this.data[13].dyanXTime);
    let modTimeXValue6 = this.getDynaTimeXRefernce(dynaModX6);
    draggedData.push({
      dyanXTime: modTimeXValue6,
      dyanYOutput: parseInt(this.data[14].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[13].dynaZfadeTime),
    });
  }

  if (this.changedPoint === 14) {
    //output Only
    let modifiedOutput = parseInt(this.data[14].dyanYOutput);

    let dynaModX = parseFloat(this.data[2].dyanXTime);
    let modTimeXValue = this.getDynaTimeXRefernce(dynaModX);
    draggedData.push({
      dyanXTime: modTimeXValue,
      dyanYOutput: parseInt(this.data[2].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
    });

    let dynaModX1 = parseFloat(this.data[4].dyanXTime);
    let modTimeXValue1 = this.getDynaTimeXRefernce(dynaModX1);
    draggedData.push({
      dyanXTime: modTimeXValue1,
      dyanYOutput: parseInt(this.data[4].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
    });

    let dynaModX2 = parseFloat(this.data[6].dyanXTime);
    let modTimeXValue2 = this.getDynaTimeXRefernce(dynaModX2);
    draggedData.push({
      dyanXTime: modTimeXValue2,
      dyanYOutput: parseInt(this.data[6].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[6].dynaZfadeTime),
    });

    let dynaModX3 = parseFloat(this.data[8].dyanXTime);
    let modTimeXValue3 = this.getDynaTimeXRefernce(dynaModX3);
    draggedData.push({
      dyanXTime: modTimeXValue3,
      dyanYOutput: parseInt(this.data[8].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[8].dynaZfadeTime),
    });
    let dynaModX4 = parseFloat(this.data[10].dyanXTime);
    let modTimeXValue4 = this.getDynaTimeXRefernce(dynaModX4);
    draggedData.push({
      dyanXTime: modTimeXValue4,
      dyanYOutput: parseInt(this.data[10].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[10].dynaZfadeTime),
    });
    let dynaModX5 = parseFloat(this.data[12].dyanXTime);
    let modTimeXValue5 = this.getDynaTimeXRefernce(dynaModX5);
    draggedData.push({
      dyanXTime: modTimeXValue5,
      dyanYOutput: parseInt(this.data[12].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[12].dynaZfadeTime),
    });

    let dynaModX6 = parseFloat(this.data[14].dyanXTime);
    let modTimeXValue6 = this.getDynaTimeXRefernce(dynaModX6);
    draggedData.push({
      dyanXTime: modTimeXValue6,
      dyanYOutput: modifiedOutput,
      dynaZfadeTime: parseInt(this.data[14].dynaZfadeTime),
    });
    
  }
  if (this.changedPoint === 15) {
    let modifiedOutput = parseInt(this.data[15].dyanXTime);

    let dynaModX = parseFloat(this.data[2].dyanXTime);
    let modTimeXValue = this.getDynaTimeXRefernce(dynaModX);
    draggedData.push({
      dyanXTime: modTimeXValue,
      dyanYOutput: parseInt(this.data[2].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
    });

    let dynaModX1 = parseFloat(this.data[4].dyanXTime);
    let modTimeXValue1 = this.getDynaTimeXRefernce(dynaModX1);
    draggedData.push({
      dyanXTime: modTimeXValue1,
      dyanYOutput: parseInt(this.data[4].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
    });

    let dynaModX2 = parseFloat(this.data[6].dyanXTime);
    let modTimeXValue2 = this.getDynaTimeXRefernce(dynaModX2);
    draggedData.push({
      dyanXTime: modTimeXValue2,
      dyanYOutput: parseInt(this.data[6].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[6].dynaZfadeTime),
    });

    let dynaModX3 = parseFloat(this.data[8].dyanXTime);
    let modTimeXValue3 = this.getDynaTimeXRefernce(dynaModX3);
    draggedData.push({
      dyanXTime: modTimeXValue3,
      dyanYOutput: parseInt(this.data[8].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[8].dynaZfadeTime),
    });
    let dynaModX4 = parseFloat(this.data[10].dyanXTime);
    let modTimeXValue4 = this.getDynaTimeXRefernce(dynaModX4);
    draggedData.push({
      dyanXTime: modTimeXValue4,
      dyanYOutput: parseInt(this.data[10].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[10].dynaZfadeTime),
    });
    let dynaModX5= parseFloat(this.data[11].dyanXTime);
    let modTimeXValue5 = this.getDynaTimeXRefernce(dynaModX5);
    draggedData.push({
      dyanXTime: modTimeXValue5,
      dyanYOutput: parseInt(this.data[12].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[11].dynaZfadeTime),
    });

    let dynaModX6= parseFloat(this.data[13].dyanXTime);
    let modTimeXValue6 = this.getDynaTimeXRefernce(dynaModX6);
    draggedData.push({
      dyanXTime: modTimeXValue6,
      dyanYOutput: parseInt(this.data[14].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[13].dynaZfadeTime),
    });

    let dynaModX7= parseFloat(this.data[15].dyanXTime);
    let modTimeXValue7 = this.getDynaTimeXRefernce(dynaModX7);
    draggedData.push({
      dyanXTime: modTimeXValue7,
      dyanYOutput: parseInt(this.data[16].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[15].dynaZfadeTime),
    });
  }

  if (this.changedPoint === 16) {
    //output Only
    let modifiedOutput = parseInt(this.data[16].dyanYOutput);

    let dynaModX = parseFloat(this.data[2].dyanXTime);
    let modTimeXValue = this.getDynaTimeXRefernce(dynaModX);
    draggedData.push({
      dyanXTime: modTimeXValue,
      dyanYOutput: parseInt(this.data[2].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
    });

    let dynaModX1 = parseFloat(this.data[4].dyanXTime);
    let modTimeXValue1 = this.getDynaTimeXRefernce(dynaModX1);
    draggedData.push({
      dyanXTime: modTimeXValue1,
      dyanYOutput: parseInt(this.data[4].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
    });

    let dynaModX2 = parseFloat(this.data[6].dyanXTime);
    let modTimeXValue2 = this.getDynaTimeXRefernce(dynaModX2);
    draggedData.push({
      dyanXTime: modTimeXValue2,
      dyanYOutput: parseInt(this.data[6].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[6].dynaZfadeTime),
    });

    let dynaModX3 = parseFloat(this.data[8].dyanXTime);
    let modTimeXValue3 = this.getDynaTimeXRefernce(dynaModX3);
    draggedData.push({
      dyanXTime: modTimeXValue3,
      dyanYOutput: parseInt(this.data[8].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[8].dynaZfadeTime),
    });
    let dynaModX4 = parseFloat(this.data[10].dyanXTime);
    let modTimeXValue4 = this.getDynaTimeXRefernce(dynaModX4);
    draggedData.push({
      dyanXTime: modTimeXValue4,
      dyanYOutput: parseInt(this.data[10].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[10].dynaZfadeTime),
    });
    let dynaModX5 = parseFloat(this.data[12].dyanXTime);
    let modTimeXValue5 = this.getDynaTimeXRefernce(dynaModX5);
    draggedData.push({
      dyanXTime: modTimeXValue5,
      dyanYOutput: parseInt(this.data[12].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[12].dynaZfadeTime),
    });

    let dynaModX6 = parseFloat(this.data[14].dyanXTime);
    let modTimeXValue6 = this.getDynaTimeXRefernce(dynaModX6);
    draggedData.push({
      dyanXTime: modTimeXValue6,
      dyanYOutput: parseInt(this.data[14].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[14].dynaZfadeTime),
    });

    let dynaModX7 = parseFloat(this.data[16].dyanXTime);
    let modTimeXValue7 = this.getDynaTimeXRefernce(dynaModX7);
    draggedData.push({
      dyanXTime: modTimeXValue7,
      dyanYOutput: modifiedOutput,
      dynaZfadeTime: parseInt(this.data[16].dynaZfadeTime),
    });
    
  }

  if (this.changedPoint === 17) {
    let modifiedOutput = parseInt(this.data[17].dyanXTime);

    let dynaModX = parseFloat(this.data[2].dyanXTime);
    let modTimeXValue = this.getDynaTimeXRefernce(dynaModX);
    draggedData.push({
      dyanXTime: modTimeXValue,
      dyanYOutput: parseInt(this.data[2].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
    });

    let dynaModX1 = parseFloat(this.data[4].dyanXTime);
    let modTimeXValue1 = this.getDynaTimeXRefernce(dynaModX1);
    draggedData.push({
      dyanXTime: modTimeXValue1,
      dyanYOutput: parseInt(this.data[4].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
    });

    let dynaModX2 = parseFloat(this.data[6].dyanXTime);
    let modTimeXValue2 = this.getDynaTimeXRefernce(dynaModX2);
    draggedData.push({
      dyanXTime: modTimeXValue2,
      dyanYOutput: parseInt(this.data[6].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[6].dynaZfadeTime),
    });

    let dynaModX3 = parseFloat(this.data[8].dyanXTime);
    let modTimeXValue3 = this.getDynaTimeXRefernce(dynaModX3);
    draggedData.push({
      dyanXTime: modTimeXValue3,
      dyanYOutput: parseInt(this.data[8].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[8].dynaZfadeTime),
    });
    let dynaModX4 = parseFloat(this.data[10].dyanXTime);
    let modTimeXValue4 = this.getDynaTimeXRefernce(dynaModX4);
    draggedData.push({
      dyanXTime: modTimeXValue4,
      dyanYOutput: parseInt(this.data[10].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[10].dynaZfadeTime),
    });
    let dynaModX5= parseFloat(this.data[11].dyanXTime);
    let modTimeXValue5 = this.getDynaTimeXRefernce(dynaModX5);
    draggedData.push({
      dyanXTime: modTimeXValue5,
      dyanYOutput: parseInt(this.data[12].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[11].dynaZfadeTime),
    });

    let dynaModX6= parseFloat(this.data[13].dyanXTime);
    let modTimeXValue6 = this.getDynaTimeXRefernce(dynaModX6);
    draggedData.push({
      dyanXTime: modTimeXValue6,
      dyanYOutput: parseInt(this.data[14].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[13].dynaZfadeTime),
    });

    let dynaModX7= parseFloat(this.data[15].dyanXTime);
    let modTimeXValue7 = this.getDynaTimeXRefernce(dynaModX7);
    draggedData.push({
      dyanXTime: modTimeXValue7,
      dyanYOutput: parseInt(this.data[16].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[15].dynaZfadeTime),
    });

    let dynaModX8= parseFloat(this.data[17].dyanXTime);
    let modTimeXValue8 = this.getDynaTimeXRefernce(dynaModX8);
    draggedData.push({
      dyanXTime: modTimeXValue8,
      dyanYOutput: parseInt(this.data[18].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[17].dynaZfadeTime),
    });
  }

  if (this.changedPoint === 18) {
    //output Only
    let modifiedOutput = parseInt(this.data[18].dyanYOutput);

    let dynaModX = parseFloat(this.data[2].dyanXTime);
    let modTimeXValue = this.getDynaTimeXRefernce(dynaModX);
    draggedData.push({
      dyanXTime: modTimeXValue,
      dyanYOutput: parseInt(this.data[2].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
    });

    let dynaModX1 = parseFloat(this.data[4].dyanXTime);
    let modTimeXValue1 = this.getDynaTimeXRefernce(dynaModX1);
    draggedData.push({
      dyanXTime: modTimeXValue1,
      dyanYOutput: parseInt(this.data[4].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
    });

    let dynaModX2 = parseFloat(this.data[6].dyanXTime);
    let modTimeXValue2 = this.getDynaTimeXRefernce(dynaModX2);
    draggedData.push({
      dyanXTime: modTimeXValue2,
      dyanYOutput: parseInt(this.data[6].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[6].dynaZfadeTime),
    });

    let dynaModX3 = parseFloat(this.data[8].dyanXTime);
    let modTimeXValue3 = this.getDynaTimeXRefernce(dynaModX3);
    draggedData.push({
      dyanXTime: modTimeXValue3,
      dyanYOutput: parseInt(this.data[8].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[8].dynaZfadeTime),
    });
    let dynaModX4 = parseFloat(this.data[10].dyanXTime);
    let modTimeXValue4 = this.getDynaTimeXRefernce(dynaModX4);
    draggedData.push({
      dyanXTime: modTimeXValue4,
      dyanYOutput: parseInt(this.data[10].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[10].dynaZfadeTime),
    });
    let dynaModX5 = parseFloat(this.data[12].dyanXTime);
    let modTimeXValue5 = this.getDynaTimeXRefernce(dynaModX5);
    draggedData.push({
      dyanXTime: modTimeXValue5,
      dyanYOutput: parseInt(this.data[12].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[12].dynaZfadeTime),
    });

    let dynaModX6 = parseFloat(this.data[14].dyanXTime);
    let modTimeXValue6 = this.getDynaTimeXRefernce(dynaModX6);
    draggedData.push({
      dyanXTime: modTimeXValue6,
      dyanYOutput: parseInt(this.data[14].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[14].dynaZfadeTime),
    });

    let dynaModX7 = parseFloat(this.data[16].dyanXTime);
    let modTimeXValue7 = this.getDynaTimeXRefernce(dynaModX7);
    draggedData.push({
      dyanXTime: modTimeXValue7,
      dyanYOutput: parseInt(this.data[16].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[16].dynaZfadeTime),
    });

    
    let dynaModX8 = parseFloat(this.data[18].dyanXTime);
    let modTimeXValue8 = this.getDynaTimeXRefernce(dynaModX8);
    draggedData.push({
      dyanXTime: modTimeXValue8,
      dyanYOutput: modifiedOutput,
      dynaZfadeTime: parseInt(this.data[18].dynaZfadeTime),
    });
    
  }

}

  /// SCENE 9 END
///SCENE 8 

updateScenes8(data: any[], draggedData: any) {
  if (this.changedPoint === 0) {
    //OUTPUT
    let modifiedOutput = parseInt(this.data[0].dyanYOutput);

    let dynaModX = parseFloat(this.data[2].dyanXTime);
    let modTimeXValue = this.getDynaTimeXRefernce(dynaModX);
    draggedData.push({
      dyanXTime: modTimeXValue,
      dyanYOutput: parseInt(this.data[2].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
    });

    let dynaModX1 = parseFloat(this.data[4].dyanXTime);
    let modTimeXValue1 = this.getDynaTimeXRefernce(dynaModX1);
    draggedData.push({
      dyanXTime: modTimeXValue1,
      dyanYOutput: parseInt(this.data[4].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
    });

    let dynaModX2 = parseFloat(this.data[6].dyanXTime);
    let modTimeXValue2 = this.getDynaTimeXRefernce(dynaModX2);
    draggedData.push({
      dyanXTime: modTimeXValue2,
      dyanYOutput: parseInt(this.data[6].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[6].dynaZfadeTime),
    });

    let dynaModX3 = parseFloat(this.data[8].dyanXTime);
    let modTimeXValue3 = this.getDynaTimeXRefernce(dynaModX3);
    draggedData.push({
      dyanXTime: modTimeXValue3,
      dyanYOutput: parseInt(this.data[8].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[8].dynaZfadeTime),
    });

    let dynaModX4 = parseFloat(this.data[10].dyanXTime);
    let modTimeXValue4 = this.getDynaTimeXRefernce(dynaModX4);
    draggedData.push({
      dyanXTime: modTimeXValue4,
      dyanYOutput: modifiedOutput,
      dynaZfadeTime: parseInt(this.data[10].dynaZfadeTime),
    });
  }
  if (this.changedPoint === 1) {
    //TIME
    let modifiedOutput = parseFloat(this.data[1].dyanXTime);

    let modTimeXValue = this.getDynaTimeXRefernce(modifiedOutput);
    draggedData.push({
      dyanXTime: modTimeXValue,
      dyanYOutput: parseInt(this.data[2].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
    });

    let dynaModX1 = parseFloat(this.data[4].dyanXTime);
    let modTimeXValue1 = this.getDynaTimeXRefernce(dynaModX1);
    draggedData.push({
      dyanXTime: modTimeXValue1,
      dyanYOutput: parseInt(this.data[4].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
    });
    let dynaModX2 = parseFloat(this.data[6].dyanXTime);
    let modTimeXValue2 = this.getDynaTimeXRefernce(dynaModX2);
    draggedData.push({
      dyanXTime: modTimeXValue2,
      dyanYOutput: parseInt(this.data[6].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[6].dynaZfadeTime),
    });

    let dynaModX3 = parseFloat(this.data[8].dyanXTime);
    let modTimeXValue3 = this.getDynaTimeXRefernce(dynaModX3);
    draggedData.push({
      dyanXTime: modTimeXValue3,
      dyanYOutput: parseInt(this.data[8].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[8].dynaZfadeTime),
    });

    let dynaModX4 = parseFloat(this.data[10].dyanXTime);
    let modTimeXValue4 = this.getDynaTimeXRefernce(dynaModX4);
    draggedData.push({
      dyanXTime: modTimeXValue4,
      dyanYOutput: parseInt(this.data[10].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[10].dynaZfadeTime),
    });
  }
  if (this.changedPoint === 2) {
    //OUTPUT
    let modifiedOutput = parseInt(this.data[2].dyanYOutput);

    let dynaModX = parseFloat(this.data[1].dyanXTime);
    let modTimeXValue = this.getDynaTimeXRefernce(dynaModX);
    draggedData.push({
      dyanXTime: modTimeXValue,
      dyanYOutput: modifiedOutput,
      dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
    });

    let dynaModX1 = parseFloat(this.data[4].dyanXTime);
    let modTimeXValue1 = this.getDynaTimeXRefernce(dynaModX1);
    draggedData.push({
      dyanXTime: modTimeXValue1,
      dyanYOutput: parseInt(this.data[4].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
    });

    let dynaModX2 = parseFloat(this.data[6].dyanXTime);
    let modTimeXValue2 = this.getDynaTimeXRefernce(dynaModX2);
    draggedData.push({
      dyanXTime: modTimeXValue2,
      dyanYOutput: parseInt(this.data[6].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[6].dynaZfadeTime),
    });

    let dynaModX3 = parseFloat(this.data[8].dyanXTime);
    let modTimeXValue3 = this.getDynaTimeXRefernce(dynaModX3);
    draggedData.push({
      dyanXTime: modTimeXValue3,
      dyanYOutput: parseInt(this.data[8].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[8].dynaZfadeTime),
    });
    let dynaModX4 = parseFloat(this.data[10].dyanXTime);
    let modTimeXValue4 = this.getDynaTimeXRefernce(dynaModX4);
    draggedData.push({
      dyanXTime: modTimeXValue4,
      dyanYOutput: parseInt(this.data[10].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[10].dynaZfadeTime),
    });
  }
  if (this.changedPoint === 3) {
    //TIME ONLY
    let modifiedOutput = parseFloat(this.data[3].dyanXTime);

    let dynaModX = parseFloat(this.data[2].dyanXTime);
    let modTimeXValue = this.getDynaTimeXRefernce(dynaModX);
    draggedData.push({
      dyanXTime: modTimeXValue,
      dyanYOutput: parseInt(this.data[2].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
    });

    let modTimeXValue1 = this.getDynaTimeXRefernce(modifiedOutput);
    draggedData.push({
      dyanXTime: modTimeXValue1,
      dyanYOutput: parseInt(this.data[4].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
    });

    let dynaModX2 = parseFloat(this.data[6].dyanXTime);
    let modTimeXValue2 = this.getDynaTimeXRefernce(dynaModX2);
    draggedData.push({
      dyanXTime: modTimeXValue2,
      dyanYOutput: parseInt(this.data[6].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[6].dynaZfadeTime),
    });
    let dynaModX3 = parseFloat(this.data[8].dyanXTime);
    let modTimeXValue3 = this.getDynaTimeXRefernce(dynaModX3);
    draggedData.push({
      dyanXTime: modTimeXValue3,
      dyanYOutput: parseInt(this.data[8].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[8].dynaZfadeTime),
    });
    let dynaModX4 = parseFloat(this.data[10].dyanXTime);
    let modTimeXValue4 = this.getDynaTimeXRefernce(dynaModX4);
    draggedData.push({
      dyanXTime: modTimeXValue4,
      dyanYOutput: parseInt(this.data[10].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[10].dynaZfadeTime),
    });
  }

  if (this.changedPoint === 4) {
    //OUTPUT ONLY - Chnage Scene 2
    let modifiedOutput = parseInt(this.data[4].dyanYOutput);

    let dynaModX = parseFloat(this.data[2].dyanXTime);
    let modTimeXValue = this.getDynaTimeXRefernce(dynaModX);
    draggedData.push({
      dyanXTime: modTimeXValue,
      dyanYOutput: parseInt(this.data[2].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
    });

    let dynaModX1 = parseFloat(this.data[3].dyanXTime);
    let modTimeXValue1 = this.getDynaTimeXRefernce(dynaModX1);
    draggedData.push({
      dyanXTime: modTimeXValue1,
      dyanYOutput: modifiedOutput,
      dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
    });

    let dynaModX2 = parseFloat(this.data[6].dyanXTime);
    let modTimeXValue2 = this.getDynaTimeXRefernce(dynaModX2);
    draggedData.push({
      dyanXTime: modTimeXValue2,
      dyanYOutput: parseInt(this.data[6].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[6].dynaZfadeTime),
    });

    let dynaModX3 = parseFloat(this.data[8].dyanXTime);
    let modTimeXValue3 = this.getDynaTimeXRefernce(dynaModX3);
    draggedData.push({
      dyanXTime: modTimeXValue3,
      dyanYOutput: parseInt(this.data[8].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[8].dynaZfadeTime),
    });
    let dynaModX4 = parseFloat(this.data[10].dyanXTime);
    let modTimeXValue4 = this.getDynaTimeXRefernce(dynaModX4);
    draggedData.push({
      dyanXTime: modTimeXValue4,
      dyanYOutput: parseInt(this.data[10].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[10].dynaZfadeTime),
    });
  }
  if (this.changedPoint === 5) {
    //TIME ONLY
    let modifiedOutput = parseFloat(this.data[5].dyanXTime);

    let dynaModX = parseFloat(this.data[2].dyanXTime);
    let modTimeXValue = this.getDynaTimeXRefernce(dynaModX);
    draggedData.push({
      dyanXTime: modTimeXValue,
      dyanYOutput: parseInt(this.data[2].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
    });

    let dynaModX1 = parseFloat(this.data[4].dyanXTime);
    let modTimeXValue1 = this.getDynaTimeXRefernce(dynaModX1);
    draggedData.push({
      dyanXTime: modTimeXValue1,
      dyanYOutput: parseInt(this.data[4].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
    });

    let modTimeXValue2 = this.getDynaTimeXRefernce(modifiedOutput);
    draggedData.push({
      dyanXTime: modTimeXValue2,
      dyanYOutput: parseInt(this.data[6].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[6].dynaZfadeTime),
    });

    let dynaModX3 = parseFloat(this.data[8].dyanXTime);
    let modTimeXValue3 = this.getDynaTimeXRefernce(dynaModX3);
    draggedData.push({
      dyanXTime: modTimeXValue3,
      dyanYOutput: parseInt(this.data[8].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[8].dynaZfadeTime),
    });
    let dynaModX4 = parseFloat(this.data[10].dyanXTime);
    let modTimeXValue4 = this.getDynaTimeXRefernce(dynaModX4);
    draggedData.push({
      dyanXTime: modTimeXValue4,
      dyanYOutput: parseInt(this.data[10].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[10].dynaZfadeTime),
    });
  }
  if (this.changedPoint === 6) {
    //OUTPUT ONLY - Chnage Scene 2
    let modifiedOutput = parseInt(this.data[6].dyanYOutput);

    let dynaModX = parseFloat(this.data[2].dyanXTime);
    let modTimeXValue = this.getDynaTimeXRefernce(dynaModX);
    draggedData.push({
      dyanXTime: modTimeXValue,
      dyanYOutput: parseInt(this.data[2].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
    });

    let dynaModX1 = parseFloat(this.data[4].dyanXTime);
    let modTimeXValue1 = this.getDynaTimeXRefernce(dynaModX1);
    draggedData.push({
      dyanXTime: modTimeXValue1,
      dyanYOutput: parseInt(this.data[4].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
    });

    let dynaModX2 = parseFloat(this.data[5].dyanXTime);
    let modTimeXValue2 = this.getDynaTimeXRefernce(dynaModX2);
    draggedData.push({
      dyanXTime: modTimeXValue2,
      dyanYOutput: modifiedOutput,
      dynaZfadeTime: parseInt(this.data[6].dynaZfadeTime),
    });

    let dynaModX3 = parseFloat(this.data[8].dyanXTime);
    let modTimeXValue3 = this.getDynaTimeXRefernce(dynaModX3);
    draggedData.push({
      dyanXTime: modTimeXValue3,
      dyanYOutput: parseInt(this.data[8].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[8].dynaZfadeTime),
    });
    let dynaModX4 = parseFloat(this.data[10].dyanXTime);
    let modTimeXValue4 = this.getDynaTimeXRefernce(dynaModX4);
    draggedData.push({
      dyanXTime: modTimeXValue4,
      dyanYOutput: parseInt(this.data[10].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[10].dynaZfadeTime),
    });
  }
  if (this.changedPoint === 7) {
    //TIME ONLY
    let modifiedOutput = parseFloat(this.data[7].dyanXTime);

    let dynaModX = parseFloat(this.data[2].dyanXTime);
    let modTimeXValue = this.getDynaTimeXRefernce(dynaModX);
    draggedData.push({
      dyanXTime: modTimeXValue,
      dyanYOutput: parseInt(this.data[2].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
    });

    let dynaModX1 = parseFloat(this.data[4].dyanXTime);
    let modTimeXValue1 = this.getDynaTimeXRefernce(dynaModX1);
    draggedData.push({
      dyanXTime: modTimeXValue1,
      dyanYOutput: parseInt(this.data[4].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
    });

    let dynaModX2 = parseFloat(this.data[6].dyanXTime);
    let modTimeXValue2 = this.getDynaTimeXRefernce(dynaModX2);
    draggedData.push({
      dyanXTime: modTimeXValue2,
      dyanYOutput: parseInt(this.data[6].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[6].dynaZfadeTime),
    });

    let modTimeXValue3 = this.getDynaTimeXRefernce(modifiedOutput);
    draggedData.push({
      dyanXTime: modTimeXValue3,
      dyanYOutput: parseInt(this.data[8].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[8].dynaZfadeTime),
    });

    let dynaModX4 = parseFloat(this.data[10].dyanXTime);
    let modTimeXValue4 = this.getDynaTimeXRefernce(dynaModX4);
    draggedData.push({
      dyanXTime: modTimeXValue4,
      dyanYOutput: parseInt(this.data[10].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[10].dynaZfadeTime),
    });
  }
  if (this.changedPoint === 8) {
    //OUTPUT ONLY - Chnage Scene 2
    let modifiedOutput = parseInt(this.data[8].dyanYOutput);

    let dynaModX = parseFloat(this.data[2].dyanXTime);
    let modTimeXValue = this.getDynaTimeXRefernce(dynaModX);
    draggedData.push({
      dyanXTime: modTimeXValue,
      dyanYOutput: parseInt(this.data[2].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
    });

    let dynaModX1 = parseFloat(this.data[4].dyanXTime);
    let modTimeXValue1 = this.getDynaTimeXRefernce(dynaModX1);
    draggedData.push({
      dyanXTime: modTimeXValue1,
      dyanYOutput: parseInt(this.data[4].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
    });

    let dynaModX2 = parseFloat(this.data[6].dyanXTime);
    let modTimeXValue2 = this.getDynaTimeXRefernce(dynaModX2);
    draggedData.push({
      dyanXTime: modTimeXValue2,
      dyanYOutput: parseInt(this.data[6].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[6].dynaZfadeTime),
    });

    let dynaModX3 = parseFloat(this.data[7].dyanXTime);
    let modTimeXValue3 = this.getDynaTimeXRefernce(dynaModX3);
    draggedData.push({
      dyanXTime: modTimeXValue3,
      dyanYOutput: modifiedOutput,
      dynaZfadeTime: parseInt(this.data[8].dynaZfadeTime),
    });

    let dynaModX4 = parseFloat(this.data[10].dyanXTime);
    let modTimeXValue4 = this.getDynaTimeXRefernce(dynaModX4);
    draggedData.push({
      dyanXTime: modTimeXValue4,
      dyanYOutput: parseInt(this.data[10].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[10].dynaZfadeTime),
    });
  }
  if (this.changedPoint === 9) {
    //TIME ONLY
    let modifiedOutput = parseFloat(this.data[9].dyanXTime);

    let dynaModX = parseFloat(this.data[2].dyanXTime);
    let modTimeXValue = this.getDynaTimeXRefernce(dynaModX);
    draggedData.push({
      dyanXTime: modTimeXValue,
      dyanYOutput: parseInt(this.data[2].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
    });

    let dynaModX1 = parseFloat(this.data[4].dyanXTime);
    let modTimeXValue1 = this.getDynaTimeXRefernce(dynaModX1);
    draggedData.push({
      dyanXTime: modTimeXValue1,
      dyanYOutput: parseInt(this.data[4].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
    });

    let dynaModX2 = parseFloat(this.data[6].dyanXTime);
    let modTimeXValue2 = this.getDynaTimeXRefernce(dynaModX2);
    draggedData.push({
      dyanXTime: modTimeXValue2,
      dyanYOutput: parseInt(this.data[6].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[6].dynaZfadeTime),
    });

    let dynaModX3 = parseFloat(this.data[8].dyanXTime);
    let modTimeXValue3 = this.getDynaTimeXRefernce(dynaModX3);
    draggedData.push({
      dyanXTime: modTimeXValue3,
      dyanYOutput: parseInt(this.data[8].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[8].dynaZfadeTime),
    });

    let modTimeXValue4 = this.getDynaTimeXRefernce(modifiedOutput);
    draggedData.push({
      dyanXTime: modTimeXValue4,
      dyanYOutput: parseInt(this.data[10].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[10].dynaZfadeTime),
    });
  }
  if (this.changedPoint === 10) {
    //OUTPUT ONLY - Chnage Scene 2
    let modifiedOutput = parseInt(this.data[10].dyanYOutput);

    let dynaModX = parseFloat(this.data[2].dyanXTime);
    let modTimeXValue = this.getDynaTimeXRefernce(dynaModX);
    draggedData.push({
      dyanXTime: modTimeXValue,
      dyanYOutput: parseInt(this.data[2].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
    });

    let dynaModX1 = parseFloat(this.data[4].dyanXTime);
    let modTimeXValue1 = this.getDynaTimeXRefernce(dynaModX1);
    draggedData.push({
      dyanXTime: modTimeXValue1,
      dyanYOutput: parseInt(this.data[4].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
    });

    let dynaModX2 = parseFloat(this.data[6].dyanXTime);
    let modTimeXValue2 = this.getDynaTimeXRefernce(dynaModX2);
    draggedData.push({
      dyanXTime: modTimeXValue2,
      dyanYOutput: parseInt(this.data[6].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[6].dynaZfadeTime),
    });

    let dynaModX3 = parseFloat(this.data[8].dyanXTime);
    let modTimeXValue3 = this.getDynaTimeXRefernce(dynaModX3);
    draggedData.push({
      dyanXTime: modTimeXValue3,
      dyanYOutput: parseInt(this.data[8].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[8].dynaZfadeTime),
    });

    let dynaModX4 = parseFloat(this.data[9].dyanXTime);
    let modTimeXValue4 = this.getDynaTimeXRefernce(dynaModX4);
    draggedData.push({
      dyanXTime: modTimeXValue4,
      dyanYOutput: modifiedOutput,
      dynaZfadeTime: parseInt(this.data[10].dynaZfadeTime),
    });
  }
  if (this.changedPoint === 11) {
    let modifiedOutput = parseInt(this.data[11].dyanXTime);

    let dynaModX = parseFloat(this.data[2].dyanXTime);
    let modTimeXValue = this.getDynaTimeXRefernce(dynaModX);
    draggedData.push({
      dyanXTime: modTimeXValue,
      dyanYOutput: parseInt(this.data[2].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
    });

    let dynaModX1 = parseFloat(this.data[4].dyanXTime);
    let modTimeXValue1 = this.getDynaTimeXRefernce(dynaModX1);
    draggedData.push({
      dyanXTime: modTimeXValue1,
      dyanYOutput: parseInt(this.data[4].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
    });

    let dynaModX2 = parseFloat(this.data[6].dyanXTime);
    let modTimeXValue2 = this.getDynaTimeXRefernce(dynaModX2);
    draggedData.push({
      dyanXTime: modTimeXValue2,
      dyanYOutput: parseInt(this.data[6].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[6].dynaZfadeTime),
    });

    let dynaModX3 = parseFloat(this.data[8].dyanXTime);
    let modTimeXValue3 = this.getDynaTimeXRefernce(dynaModX3);
    draggedData.push({
      dyanXTime: modTimeXValue3,
      dyanYOutput: parseInt(this.data[8].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[8].dynaZfadeTime),
    });
    let dynaModX4 = parseFloat(this.data[10].dyanXTime);
    let modTimeXValue4 = this.getDynaTimeXRefernce(dynaModX4);
    draggedData.push({
      dyanXTime: modTimeXValue4,
      dyanYOutput: parseInt(this.data[10].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[10].dynaZfadeTime),
    });
    let dynaModX5= parseFloat(this.data[11].dyanXTime);
    let modTimeXValue5 = this.getDynaTimeXRefernce(dynaModX5);
    draggedData.push({
      dyanXTime: modTimeXValue5,
      dyanYOutput: parseInt(this.data[12].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[11].dynaZfadeTime),
    });
  }

  if (this.changedPoint === 12) {
    //output Only
    let modifiedOutput = parseInt(this.data[12].dyanYOutput);

    let dynaModX = parseFloat(this.data[2].dyanXTime);
    let modTimeXValue = this.getDynaTimeXRefernce(dynaModX);
    draggedData.push({
      dyanXTime: modTimeXValue,
      dyanYOutput: parseInt(this.data[2].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
    });

    let dynaModX1 = parseFloat(this.data[4].dyanXTime);
    let modTimeXValue1 = this.getDynaTimeXRefernce(dynaModX1);
    draggedData.push({
      dyanXTime: modTimeXValue1,
      dyanYOutput: parseInt(this.data[4].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
    });

    let dynaModX2 = parseFloat(this.data[6].dyanXTime);
    let modTimeXValue2 = this.getDynaTimeXRefernce(dynaModX2);
    draggedData.push({
      dyanXTime: modTimeXValue2,
      dyanYOutput: parseInt(this.data[6].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[6].dynaZfadeTime),
    });

    let dynaModX3 = parseFloat(this.data[8].dyanXTime);
    let modTimeXValue3 = this.getDynaTimeXRefernce(dynaModX3);
    draggedData.push({
      dyanXTime: modTimeXValue3,
      dyanYOutput: parseInt(this.data[8].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[8].dynaZfadeTime),
    });
    let dynaModX4 = parseFloat(this.data[10].dyanXTime);
    let modTimeXValue4 = this.getDynaTimeXRefernce(dynaModX4);
    draggedData.push({
      dyanXTime: modTimeXValue4,
      dyanYOutput: parseInt(this.data[10].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[10].dynaZfadeTime),
    });
    let dynaModX5 = parseFloat(this.data[11].dyanXTime);
    let modTimeXValue5 = this.getDynaTimeXRefernce(dynaModX5);
    draggedData.push({
      dyanXTime: modTimeXValue5,
      dyanYOutput: parseInt(this.data[11].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[12].dynaZfadeTime),
    });
  }
  if (this.changedPoint === 13) {
    let modifiedOutput = parseInt(this.data[13].dyanXTime);

    let dynaModX = parseFloat(this.data[2].dyanXTime);
    let modTimeXValue = this.getDynaTimeXRefernce(dynaModX);
    draggedData.push({
      dyanXTime: modTimeXValue,
      dyanYOutput: parseInt(this.data[2].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
    });

    let dynaModX1 = parseFloat(this.data[4].dyanXTime);
    let modTimeXValue1 = this.getDynaTimeXRefernce(dynaModX1);
    draggedData.push({
      dyanXTime: modTimeXValue1,
      dyanYOutput: parseInt(this.data[4].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
    });

    let dynaModX2 = parseFloat(this.data[6].dyanXTime);
    let modTimeXValue2 = this.getDynaTimeXRefernce(dynaModX2);
    draggedData.push({
      dyanXTime: modTimeXValue2,
      dyanYOutput: parseInt(this.data[6].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[6].dynaZfadeTime),
    });

    let dynaModX3 = parseFloat(this.data[8].dyanXTime);
    let modTimeXValue3 = this.getDynaTimeXRefernce(dynaModX3);
    draggedData.push({
      dyanXTime: modTimeXValue3,
      dyanYOutput: parseInt(this.data[8].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[8].dynaZfadeTime),
    });
    let dynaModX4 = parseFloat(this.data[10].dyanXTime);
    let modTimeXValue4 = this.getDynaTimeXRefernce(dynaModX4);
    draggedData.push({
      dyanXTime: modTimeXValue4,
      dyanYOutput: parseInt(this.data[10].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[10].dynaZfadeTime),
    });
    let dynaModX5= parseFloat(this.data[11].dyanXTime);
    let modTimeXValue5 = this.getDynaTimeXRefernce(dynaModX5);
    draggedData.push({
      dyanXTime: modTimeXValue5,
      dyanYOutput: parseInt(this.data[12].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[11].dynaZfadeTime),
    });

    let dynaModX6= parseFloat(this.data[13].dyanXTime);
    let modTimeXValue6 = this.getDynaTimeXRefernce(dynaModX6);
    draggedData.push({
      dyanXTime: modTimeXValue6,
      dyanYOutput: parseInt(this.data[14].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[13].dynaZfadeTime),
    });
  }

  if (this.changedPoint === 14) {
    //output Only
    let modifiedOutput = parseInt(this.data[14].dyanYOutput);

    let dynaModX = parseFloat(this.data[2].dyanXTime);
    let modTimeXValue = this.getDynaTimeXRefernce(dynaModX);
    draggedData.push({
      dyanXTime: modTimeXValue,
      dyanYOutput: parseInt(this.data[2].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
    });

    let dynaModX1 = parseFloat(this.data[4].dyanXTime);
    let modTimeXValue1 = this.getDynaTimeXRefernce(dynaModX1);
    draggedData.push({
      dyanXTime: modTimeXValue1,
      dyanYOutput: parseInt(this.data[4].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
    });

    let dynaModX2 = parseFloat(this.data[6].dyanXTime);
    let modTimeXValue2 = this.getDynaTimeXRefernce(dynaModX2);
    draggedData.push({
      dyanXTime: modTimeXValue2,
      dyanYOutput: parseInt(this.data[6].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[6].dynaZfadeTime),
    });

    let dynaModX3 = parseFloat(this.data[8].dyanXTime);
    let modTimeXValue3 = this.getDynaTimeXRefernce(dynaModX3);
    draggedData.push({
      dyanXTime: modTimeXValue3,
      dyanYOutput: parseInt(this.data[8].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[8].dynaZfadeTime),
    });
    let dynaModX4 = parseFloat(this.data[10].dyanXTime);
    let modTimeXValue4 = this.getDynaTimeXRefernce(dynaModX4);
    draggedData.push({
      dyanXTime: modTimeXValue4,
      dyanYOutput: parseInt(this.data[10].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[10].dynaZfadeTime),
    });
    let dynaModX5 = parseFloat(this.data[12].dyanXTime);
    let modTimeXValue5 = this.getDynaTimeXRefernce(dynaModX5);
    draggedData.push({
      dyanXTime: modTimeXValue5,
      dyanYOutput: parseInt(this.data[12].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[12].dynaZfadeTime),
    });

    let dynaModX6 = parseFloat(this.data[14].dyanXTime);
    let modTimeXValue6 = this.getDynaTimeXRefernce(dynaModX6);
    draggedData.push({
      dyanXTime: modTimeXValue6,
      dyanYOutput: modifiedOutput,
      dynaZfadeTime: parseInt(this.data[14].dynaZfadeTime),
    });
    
  }
  if (this.changedPoint === 15) {
    let modifiedOutput = parseInt(this.data[15].dyanXTime);

    let dynaModX = parseFloat(this.data[2].dyanXTime);
    let modTimeXValue = this.getDynaTimeXRefernce(dynaModX);
    draggedData.push({
      dyanXTime: modTimeXValue,
      dyanYOutput: parseInt(this.data[2].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
    });

    let dynaModX1 = parseFloat(this.data[4].dyanXTime);
    let modTimeXValue1 = this.getDynaTimeXRefernce(dynaModX1);
    draggedData.push({
      dyanXTime: modTimeXValue1,
      dyanYOutput: parseInt(this.data[4].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
    });

    let dynaModX2 = parseFloat(this.data[6].dyanXTime);
    let modTimeXValue2 = this.getDynaTimeXRefernce(dynaModX2);
    draggedData.push({
      dyanXTime: modTimeXValue2,
      dyanYOutput: parseInt(this.data[6].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[6].dynaZfadeTime),
    });

    let dynaModX3 = parseFloat(this.data[8].dyanXTime);
    let modTimeXValue3 = this.getDynaTimeXRefernce(dynaModX3);
    draggedData.push({
      dyanXTime: modTimeXValue3,
      dyanYOutput: parseInt(this.data[8].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[8].dynaZfadeTime),
    });
    let dynaModX4 = parseFloat(this.data[10].dyanXTime);
    let modTimeXValue4 = this.getDynaTimeXRefernce(dynaModX4);
    draggedData.push({
      dyanXTime: modTimeXValue4,
      dyanYOutput: parseInt(this.data[10].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[10].dynaZfadeTime),
    });
    let dynaModX5= parseFloat(this.data[11].dyanXTime);
    let modTimeXValue5 = this.getDynaTimeXRefernce(dynaModX5);
    draggedData.push({
      dyanXTime: modTimeXValue5,
      dyanYOutput: parseInt(this.data[12].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[11].dynaZfadeTime),
    });

    let dynaModX6= parseFloat(this.data[13].dyanXTime);
    let modTimeXValue6 = this.getDynaTimeXRefernce(dynaModX6);
    draggedData.push({
      dyanXTime: modTimeXValue6,
      dyanYOutput: parseInt(this.data[14].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[13].dynaZfadeTime),
    });

    let dynaModX7= parseFloat(this.data[15].dyanXTime);
    let modTimeXValue7 = this.getDynaTimeXRefernce(dynaModX7);
    draggedData.push({
      dyanXTime: modTimeXValue7,
      dyanYOutput: parseInt(this.data[16].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[15].dynaZfadeTime),
    });
  }

  if (this.changedPoint === 16) {
    //output Only
    let modifiedOutput = parseInt(this.data[16].dyanYOutput);

    let dynaModX = parseFloat(this.data[2].dyanXTime);
    let modTimeXValue = this.getDynaTimeXRefernce(dynaModX);
    draggedData.push({
      dyanXTime: modTimeXValue,
      dyanYOutput: parseInt(this.data[2].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
    });

    let dynaModX1 = parseFloat(this.data[4].dyanXTime);
    let modTimeXValue1 = this.getDynaTimeXRefernce(dynaModX1);
    draggedData.push({
      dyanXTime: modTimeXValue1,
      dyanYOutput: parseInt(this.data[4].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
    });

    let dynaModX2 = parseFloat(this.data[6].dyanXTime);
    let modTimeXValue2 = this.getDynaTimeXRefernce(dynaModX2);
    draggedData.push({
      dyanXTime: modTimeXValue2,
      dyanYOutput: parseInt(this.data[6].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[6].dynaZfadeTime),
    });

    let dynaModX3 = parseFloat(this.data[8].dyanXTime);
    let modTimeXValue3 = this.getDynaTimeXRefernce(dynaModX3);
    draggedData.push({
      dyanXTime: modTimeXValue3,
      dyanYOutput: parseInt(this.data[8].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[8].dynaZfadeTime),
    });
    let dynaModX4 = parseFloat(this.data[10].dyanXTime);
    let modTimeXValue4 = this.getDynaTimeXRefernce(dynaModX4);
    draggedData.push({
      dyanXTime: modTimeXValue4,
      dyanYOutput: parseInt(this.data[10].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[10].dynaZfadeTime),
    });
    let dynaModX5 = parseFloat(this.data[12].dyanXTime);
    let modTimeXValue5 = this.getDynaTimeXRefernce(dynaModX5);
    draggedData.push({
      dyanXTime: modTimeXValue5,
      dyanYOutput: parseInt(this.data[12].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[12].dynaZfadeTime),
    });

    let dynaModX6 = parseFloat(this.data[14].dyanXTime);
    let modTimeXValue6 = this.getDynaTimeXRefernce(dynaModX6);
    draggedData.push({
      dyanXTime: modTimeXValue6,
      dyanYOutput: parseInt(this.data[14].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[14].dynaZfadeTime),
    });

    let dynaModX7 = parseFloat(this.data[16].dyanXTime);
    let modTimeXValue7 = this.getDynaTimeXRefernce(dynaModX7);
    draggedData.push({
      dyanXTime: modTimeXValue7,
      dyanYOutput: modifiedOutput,
      dynaZfadeTime: parseInt(this.data[16].dynaZfadeTime),
    });
    
  }


}


/////SCENE 8 end

////SCENE 7
updateScenes7(data: any[], draggedData: any) {
  if (this.changedPoint === 0) {
    //OUTPUT
    let modifiedOutput = parseInt(this.data[0].dyanYOutput);

    let dynaModX = parseFloat(this.data[2].dyanXTime);
    let modTimeXValue = this.getDynaTimeXRefernce(dynaModX);
    draggedData.push({
      dyanXTime: modTimeXValue,
      dyanYOutput: parseInt(this.data[2].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
    });

    let dynaModX1 = parseFloat(this.data[4].dyanXTime);
    let modTimeXValue1 = this.getDynaTimeXRefernce(dynaModX1);
    draggedData.push({
      dyanXTime: modTimeXValue1,
      dyanYOutput: parseInt(this.data[4].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
    });

    let dynaModX2 = parseFloat(this.data[6].dyanXTime);
    let modTimeXValue2 = this.getDynaTimeXRefernce(dynaModX2);
    draggedData.push({
      dyanXTime: modTimeXValue2,
      dyanYOutput: parseInt(this.data[6].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[6].dynaZfadeTime),
    });

    let dynaModX3 = parseFloat(this.data[8].dyanXTime);
    let modTimeXValue3 = this.getDynaTimeXRefernce(dynaModX3);
    draggedData.push({
      dyanXTime: modTimeXValue3,
      dyanYOutput: parseInt(this.data[8].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[8].dynaZfadeTime),
    });

    let dynaModX4 = parseFloat(this.data[10].dyanXTime);
    let modTimeXValue4 = this.getDynaTimeXRefernce(dynaModX4);
    draggedData.push({
      dyanXTime: modTimeXValue4,
      dyanYOutput: modifiedOutput,
      dynaZfadeTime: parseInt(this.data[10].dynaZfadeTime),
    });
  }
  if (this.changedPoint === 1) {
    //TIME
    let modifiedOutput = parseFloat(this.data[1].dyanXTime);

    let modTimeXValue = this.getDynaTimeXRefernce(modifiedOutput);
    draggedData.push({
      dyanXTime: modTimeXValue,
      dyanYOutput: parseInt(this.data[2].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
    });

    let dynaModX1 = parseFloat(this.data[4].dyanXTime);
    let modTimeXValue1 = this.getDynaTimeXRefernce(dynaModX1);
    draggedData.push({
      dyanXTime: modTimeXValue1,
      dyanYOutput: parseInt(this.data[4].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
    });
    let dynaModX2 = parseFloat(this.data[6].dyanXTime);
    let modTimeXValue2 = this.getDynaTimeXRefernce(dynaModX2);
    draggedData.push({
      dyanXTime: modTimeXValue2,
      dyanYOutput: parseInt(this.data[6].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[6].dynaZfadeTime),
    });

    let dynaModX3 = parseFloat(this.data[8].dyanXTime);
    let modTimeXValue3 = this.getDynaTimeXRefernce(dynaModX3);
    draggedData.push({
      dyanXTime: modTimeXValue3,
      dyanYOutput: parseInt(this.data[8].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[8].dynaZfadeTime),
    });

    let dynaModX4 = parseFloat(this.data[10].dyanXTime);
    let modTimeXValue4 = this.getDynaTimeXRefernce(dynaModX4);
    draggedData.push({
      dyanXTime: modTimeXValue4,
      dyanYOutput: parseInt(this.data[10].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[10].dynaZfadeTime),
    });
  }
  if (this.changedPoint === 2) {
    //OUTPUT
    let modifiedOutput = parseInt(this.data[2].dyanYOutput);

    let dynaModX = parseFloat(this.data[1].dyanXTime);
    let modTimeXValue = this.getDynaTimeXRefernce(dynaModX);
    draggedData.push({
      dyanXTime: modTimeXValue,
      dyanYOutput: modifiedOutput,
      dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
    });

    let dynaModX1 = parseFloat(this.data[4].dyanXTime);
    let modTimeXValue1 = this.getDynaTimeXRefernce(dynaModX1);
    draggedData.push({
      dyanXTime: modTimeXValue1,
      dyanYOutput: parseInt(this.data[4].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
    });

    let dynaModX2 = parseFloat(this.data[6].dyanXTime);
    let modTimeXValue2 = this.getDynaTimeXRefernce(dynaModX2);
    draggedData.push({
      dyanXTime: modTimeXValue2,
      dyanYOutput: parseInt(this.data[6].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[6].dynaZfadeTime),
    });

    let dynaModX3 = parseFloat(this.data[8].dyanXTime);
    let modTimeXValue3 = this.getDynaTimeXRefernce(dynaModX3);
    draggedData.push({
      dyanXTime: modTimeXValue3,
      dyanYOutput: parseInt(this.data[8].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[8].dynaZfadeTime),
    });
    let dynaModX4 = parseFloat(this.data[10].dyanXTime);
    let modTimeXValue4 = this.getDynaTimeXRefernce(dynaModX4);
    draggedData.push({
      dyanXTime: modTimeXValue4,
      dyanYOutput: parseInt(this.data[10].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[10].dynaZfadeTime),
    });
  }
  if (this.changedPoint === 3) {
    //TIME ONLY
    let modifiedOutput = parseFloat(this.data[3].dyanXTime);

    let dynaModX = parseFloat(this.data[2].dyanXTime);
    let modTimeXValue = this.getDynaTimeXRefernce(dynaModX);
    draggedData.push({
      dyanXTime: modTimeXValue,
      dyanYOutput: parseInt(this.data[2].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
    });

    let modTimeXValue1 = this.getDynaTimeXRefernce(modifiedOutput);
    draggedData.push({
      dyanXTime: modTimeXValue1,
      dyanYOutput: parseInt(this.data[4].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
    });

    let dynaModX2 = parseFloat(this.data[6].dyanXTime);
    let modTimeXValue2 = this.getDynaTimeXRefernce(dynaModX2);
    draggedData.push({
      dyanXTime: modTimeXValue2,
      dyanYOutput: parseInt(this.data[6].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[6].dynaZfadeTime),
    });
    let dynaModX3 = parseFloat(this.data[8].dyanXTime);
    let modTimeXValue3 = this.getDynaTimeXRefernce(dynaModX3);
    draggedData.push({
      dyanXTime: modTimeXValue3,
      dyanYOutput: parseInt(this.data[8].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[8].dynaZfadeTime),
    });
    let dynaModX4 = parseFloat(this.data[10].dyanXTime);
    let modTimeXValue4 = this.getDynaTimeXRefernce(dynaModX4);
    draggedData.push({
      dyanXTime: modTimeXValue4,
      dyanYOutput: parseInt(this.data[10].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[10].dynaZfadeTime),
    });
  }

  if (this.changedPoint === 4) {
    //OUTPUT ONLY - Chnage Scene 2
    let modifiedOutput = parseInt(this.data[4].dyanYOutput);

    let dynaModX = parseFloat(this.data[2].dyanXTime);
    let modTimeXValue = this.getDynaTimeXRefernce(dynaModX);
    draggedData.push({
      dyanXTime: modTimeXValue,
      dyanYOutput: parseInt(this.data[2].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
    });

    let dynaModX1 = parseFloat(this.data[3].dyanXTime);
    let modTimeXValue1 = this.getDynaTimeXRefernce(dynaModX1);
    draggedData.push({
      dyanXTime: modTimeXValue1,
      dyanYOutput: modifiedOutput,
      dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
    });

    let dynaModX2 = parseFloat(this.data[6].dyanXTime);
    let modTimeXValue2 = this.getDynaTimeXRefernce(dynaModX2);
    draggedData.push({
      dyanXTime: modTimeXValue2,
      dyanYOutput: parseInt(this.data[6].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[6].dynaZfadeTime),
    });

    let dynaModX3 = parseFloat(this.data[8].dyanXTime);
    let modTimeXValue3 = this.getDynaTimeXRefernce(dynaModX3);
    draggedData.push({
      dyanXTime: modTimeXValue3,
      dyanYOutput: parseInt(this.data[8].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[8].dynaZfadeTime),
    });
    let dynaModX4 = parseFloat(this.data[10].dyanXTime);
    let modTimeXValue4 = this.getDynaTimeXRefernce(dynaModX4);
    draggedData.push({
      dyanXTime: modTimeXValue4,
      dyanYOutput: parseInt(this.data[10].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[10].dynaZfadeTime),
    });
  }
  if (this.changedPoint === 5) {
    //TIME ONLY
    let modifiedOutput = parseFloat(this.data[5].dyanXTime);

    let dynaModX = parseFloat(this.data[2].dyanXTime);
    let modTimeXValue = this.getDynaTimeXRefernce(dynaModX);
    draggedData.push({
      dyanXTime: modTimeXValue,
      dyanYOutput: parseInt(this.data[2].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
    });

    let dynaModX1 = parseFloat(this.data[4].dyanXTime);
    let modTimeXValue1 = this.getDynaTimeXRefernce(dynaModX1);
    draggedData.push({
      dyanXTime: modTimeXValue1,
      dyanYOutput: parseInt(this.data[4].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
    });

    let modTimeXValue2 = this.getDynaTimeXRefernce(modifiedOutput);
    draggedData.push({
      dyanXTime: modTimeXValue2,
      dyanYOutput: parseInt(this.data[6].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[6].dynaZfadeTime),
    });

    let dynaModX3 = parseFloat(this.data[8].dyanXTime);
    let modTimeXValue3 = this.getDynaTimeXRefernce(dynaModX3);
    draggedData.push({
      dyanXTime: modTimeXValue3,
      dyanYOutput: parseInt(this.data[8].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[8].dynaZfadeTime),
    });
    let dynaModX4 = parseFloat(this.data[10].dyanXTime);
    let modTimeXValue4 = this.getDynaTimeXRefernce(dynaModX4);
    draggedData.push({
      dyanXTime: modTimeXValue4,
      dyanYOutput: parseInt(this.data[10].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[10].dynaZfadeTime),
    });
  }
  if (this.changedPoint === 6) {
    //OUTPUT ONLY - Chnage Scene 2
    let modifiedOutput = parseInt(this.data[6].dyanYOutput);

    let dynaModX = parseFloat(this.data[2].dyanXTime);
    let modTimeXValue = this.getDynaTimeXRefernce(dynaModX);
    draggedData.push({
      dyanXTime: modTimeXValue,
      dyanYOutput: parseInt(this.data[2].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
    });

    let dynaModX1 = parseFloat(this.data[4].dyanXTime);
    let modTimeXValue1 = this.getDynaTimeXRefernce(dynaModX1);
    draggedData.push({
      dyanXTime: modTimeXValue1,
      dyanYOutput: parseInt(this.data[4].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
    });

    let dynaModX2 = parseFloat(this.data[5].dyanXTime);
    let modTimeXValue2 = this.getDynaTimeXRefernce(dynaModX2);
    draggedData.push({
      dyanXTime: modTimeXValue2,
      dyanYOutput: modifiedOutput,
      dynaZfadeTime: parseInt(this.data[6].dynaZfadeTime),
    });

    let dynaModX3 = parseFloat(this.data[8].dyanXTime);
    let modTimeXValue3 = this.getDynaTimeXRefernce(dynaModX3);
    draggedData.push({
      dyanXTime: modTimeXValue3,
      dyanYOutput: parseInt(this.data[8].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[8].dynaZfadeTime),
    });
    let dynaModX4 = parseFloat(this.data[10].dyanXTime);
    let modTimeXValue4 = this.getDynaTimeXRefernce(dynaModX4);
    draggedData.push({
      dyanXTime: modTimeXValue4,
      dyanYOutput: parseInt(this.data[10].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[10].dynaZfadeTime),
    });
  }
  if (this.changedPoint === 7) {
    //TIME ONLY
    let modifiedOutput = parseFloat(this.data[7].dyanXTime);

    let dynaModX = parseFloat(this.data[2].dyanXTime);
    let modTimeXValue = this.getDynaTimeXRefernce(dynaModX);
    draggedData.push({
      dyanXTime: modTimeXValue,
      dyanYOutput: parseInt(this.data[2].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
    });

    let dynaModX1 = parseFloat(this.data[4].dyanXTime);
    let modTimeXValue1 = this.getDynaTimeXRefernce(dynaModX1);
    draggedData.push({
      dyanXTime: modTimeXValue1,
      dyanYOutput: parseInt(this.data[4].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
    });

    let dynaModX2 = parseFloat(this.data[6].dyanXTime);
    let modTimeXValue2 = this.getDynaTimeXRefernce(dynaModX2);
    draggedData.push({
      dyanXTime: modTimeXValue2,
      dyanYOutput: parseInt(this.data[6].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[6].dynaZfadeTime),
    });

    let modTimeXValue3 = this.getDynaTimeXRefernce(modifiedOutput);
    draggedData.push({
      dyanXTime: modTimeXValue3,
      dyanYOutput: parseInt(this.data[8].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[8].dynaZfadeTime),
    });

    let dynaModX4 = parseFloat(this.data[10].dyanXTime);
    let modTimeXValue4 = this.getDynaTimeXRefernce(dynaModX4);
    draggedData.push({
      dyanXTime: modTimeXValue4,
      dyanYOutput: parseInt(this.data[10].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[10].dynaZfadeTime),
    });
  }
  if (this.changedPoint === 8) {
    //OUTPUT ONLY - Chnage Scene 2
    let modifiedOutput = parseInt(this.data[8].dyanYOutput);

    let dynaModX = parseFloat(this.data[2].dyanXTime);
    let modTimeXValue = this.getDynaTimeXRefernce(dynaModX);
    draggedData.push({
      dyanXTime: modTimeXValue,
      dyanYOutput: parseInt(this.data[2].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
    });

    let dynaModX1 = parseFloat(this.data[4].dyanXTime);
    let modTimeXValue1 = this.getDynaTimeXRefernce(dynaModX1);
    draggedData.push({
      dyanXTime: modTimeXValue1,
      dyanYOutput: parseInt(this.data[4].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
    });

    let dynaModX2 = parseFloat(this.data[6].dyanXTime);
    let modTimeXValue2 = this.getDynaTimeXRefernce(dynaModX2);
    draggedData.push({
      dyanXTime: modTimeXValue2,
      dyanYOutput: parseInt(this.data[6].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[6].dynaZfadeTime),
    });

    let dynaModX3 = parseFloat(this.data[7].dyanXTime);
    let modTimeXValue3 = this.getDynaTimeXRefernce(dynaModX3);
    draggedData.push({
      dyanXTime: modTimeXValue3,
      dyanYOutput: modifiedOutput,
      dynaZfadeTime: parseInt(this.data[8].dynaZfadeTime),
    });

    let dynaModX4 = parseFloat(this.data[10].dyanXTime);
    let modTimeXValue4 = this.getDynaTimeXRefernce(dynaModX4);
    draggedData.push({
      dyanXTime: modTimeXValue4,
      dyanYOutput: parseInt(this.data[10].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[10].dynaZfadeTime),
    });
  }
  if (this.changedPoint === 9) {
    //TIME ONLY
    let modifiedOutput = parseFloat(this.data[9].dyanXTime);

    let dynaModX = parseFloat(this.data[2].dyanXTime);
    let modTimeXValue = this.getDynaTimeXRefernce(dynaModX);
    draggedData.push({
      dyanXTime: modTimeXValue,
      dyanYOutput: parseInt(this.data[2].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
    });

    let dynaModX1 = parseFloat(this.data[4].dyanXTime);
    let modTimeXValue1 = this.getDynaTimeXRefernce(dynaModX1);
    draggedData.push({
      dyanXTime: modTimeXValue1,
      dyanYOutput: parseInt(this.data[4].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
    });

    let dynaModX2 = parseFloat(this.data[6].dyanXTime);
    let modTimeXValue2 = this.getDynaTimeXRefernce(dynaModX2);
    draggedData.push({
      dyanXTime: modTimeXValue2,
      dyanYOutput: parseInt(this.data[6].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[6].dynaZfadeTime),
    });

    let dynaModX3 = parseFloat(this.data[8].dyanXTime);
    let modTimeXValue3 = this.getDynaTimeXRefernce(dynaModX3);
    draggedData.push({
      dyanXTime: modTimeXValue3,
      dyanYOutput: parseInt(this.data[8].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[8].dynaZfadeTime),
    });

    let modTimeXValue4 = this.getDynaTimeXRefernce(modifiedOutput);
    draggedData.push({
      dyanXTime: modTimeXValue4,
      dyanYOutput: parseInt(this.data[10].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[10].dynaZfadeTime),
    });
  }
  if (this.changedPoint === 10) {
    //OUTPUT ONLY - Chnage Scene 2
    let modifiedOutput = parseInt(this.data[10].dyanYOutput);

    let dynaModX = parseFloat(this.data[2].dyanXTime);
    let modTimeXValue = this.getDynaTimeXRefernce(dynaModX);
    draggedData.push({
      dyanXTime: modTimeXValue,
      dyanYOutput: parseInt(this.data[2].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
    });

    let dynaModX1 = parseFloat(this.data[4].dyanXTime);
    let modTimeXValue1 = this.getDynaTimeXRefernce(dynaModX1);
    draggedData.push({
      dyanXTime: modTimeXValue1,
      dyanYOutput: parseInt(this.data[4].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
    });

    let dynaModX2 = parseFloat(this.data[6].dyanXTime);
    let modTimeXValue2 = this.getDynaTimeXRefernce(dynaModX2);
    draggedData.push({
      dyanXTime: modTimeXValue2,
      dyanYOutput: parseInt(this.data[6].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[6].dynaZfadeTime),
    });

    let dynaModX3 = parseFloat(this.data[8].dyanXTime);
    let modTimeXValue3 = this.getDynaTimeXRefernce(dynaModX3);
    draggedData.push({
      dyanXTime: modTimeXValue3,
      dyanYOutput: parseInt(this.data[8].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[8].dynaZfadeTime),
    });

    let dynaModX4 = parseFloat(this.data[9].dyanXTime);
    let modTimeXValue4 = this.getDynaTimeXRefernce(dynaModX4);
    draggedData.push({
      dyanXTime: modTimeXValue4,
      dyanYOutput: modifiedOutput,
      dynaZfadeTime: parseInt(this.data[10].dynaZfadeTime),
    });
  }
  if (this.changedPoint === 11) {
    let modifiedOutput = parseInt(this.data[11].dyanXTime);

    let dynaModX = parseFloat(this.data[2].dyanXTime);
    let modTimeXValue = this.getDynaTimeXRefernce(dynaModX);
    draggedData.push({
      dyanXTime: modTimeXValue,
      dyanYOutput: parseInt(this.data[2].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
    });

    let dynaModX1 = parseFloat(this.data[4].dyanXTime);
    let modTimeXValue1 = this.getDynaTimeXRefernce(dynaModX1);
    draggedData.push({
      dyanXTime: modTimeXValue1,
      dyanYOutput: parseInt(this.data[4].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
    });

    let dynaModX2 = parseFloat(this.data[6].dyanXTime);
    let modTimeXValue2 = this.getDynaTimeXRefernce(dynaModX2);
    draggedData.push({
      dyanXTime: modTimeXValue2,
      dyanYOutput: parseInt(this.data[6].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[6].dynaZfadeTime),
    });

    let dynaModX3 = parseFloat(this.data[8].dyanXTime);
    let modTimeXValue3 = this.getDynaTimeXRefernce(dynaModX3);
    draggedData.push({
      dyanXTime: modTimeXValue3,
      dyanYOutput: parseInt(this.data[8].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[8].dynaZfadeTime),
    });
    let dynaModX4 = parseFloat(this.data[10].dyanXTime);
    let modTimeXValue4 = this.getDynaTimeXRefernce(dynaModX4);
    draggedData.push({
      dyanXTime: modTimeXValue4,
      dyanYOutput: parseInt(this.data[10].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[10].dynaZfadeTime),
    });
    let dynaModX5= parseFloat(this.data[11].dyanXTime);
    let modTimeXValue5 = this.getDynaTimeXRefernce(dynaModX5);
    draggedData.push({
      dyanXTime: modTimeXValue5,
      dyanYOutput: parseInt(this.data[12].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[11].dynaZfadeTime),
    });
  }

  if (this.changedPoint === 12) {
    //output Only
    let modifiedOutput = parseInt(this.data[12].dyanYOutput);

    let dynaModX = parseFloat(this.data[2].dyanXTime);
    let modTimeXValue = this.getDynaTimeXRefernce(dynaModX);
    draggedData.push({
      dyanXTime: modTimeXValue,
      dyanYOutput: parseInt(this.data[2].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
    });

    let dynaModX1 = parseFloat(this.data[4].dyanXTime);
    let modTimeXValue1 = this.getDynaTimeXRefernce(dynaModX1);
    draggedData.push({
      dyanXTime: modTimeXValue1,
      dyanYOutput: parseInt(this.data[4].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
    });

    let dynaModX2 = parseFloat(this.data[6].dyanXTime);
    let modTimeXValue2 = this.getDynaTimeXRefernce(dynaModX2);
    draggedData.push({
      dyanXTime: modTimeXValue2,
      dyanYOutput: parseInt(this.data[6].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[6].dynaZfadeTime),
    });

    let dynaModX3 = parseFloat(this.data[8].dyanXTime);
    let modTimeXValue3 = this.getDynaTimeXRefernce(dynaModX3);
    draggedData.push({
      dyanXTime: modTimeXValue3,
      dyanYOutput: parseInt(this.data[8].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[8].dynaZfadeTime),
    });
    let dynaModX4 = parseFloat(this.data[10].dyanXTime);
    let modTimeXValue4 = this.getDynaTimeXRefernce(dynaModX4);
    draggedData.push({
      dyanXTime: modTimeXValue4,
      dyanYOutput: parseInt(this.data[10].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[10].dynaZfadeTime),
    });
    let dynaModX5 = parseFloat(this.data[11].dyanXTime);
    let modTimeXValue5 = this.getDynaTimeXRefernce(dynaModX5);
    draggedData.push({
      dyanXTime: modTimeXValue5,
      dyanYOutput: parseInt(this.data[11].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[12].dynaZfadeTime),
    });
  }
  if (this.changedPoint === 13) {
    let modifiedOutput = parseInt(this.data[13].dyanXTime);

    let dynaModX = parseFloat(this.data[2].dyanXTime);
    let modTimeXValue = this.getDynaTimeXRefernce(dynaModX);
    draggedData.push({
      dyanXTime: modTimeXValue,
      dyanYOutput: parseInt(this.data[2].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
    });

    let dynaModX1 = parseFloat(this.data[4].dyanXTime);
    let modTimeXValue1 = this.getDynaTimeXRefernce(dynaModX1);
    draggedData.push({
      dyanXTime: modTimeXValue1,
      dyanYOutput: parseInt(this.data[4].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
    });

    let dynaModX2 = parseFloat(this.data[6].dyanXTime);
    let modTimeXValue2 = this.getDynaTimeXRefernce(dynaModX2);
    draggedData.push({
      dyanXTime: modTimeXValue2,
      dyanYOutput: parseInt(this.data[6].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[6].dynaZfadeTime),
    });

    let dynaModX3 = parseFloat(this.data[8].dyanXTime);
    let modTimeXValue3 = this.getDynaTimeXRefernce(dynaModX3);
    draggedData.push({
      dyanXTime: modTimeXValue3,
      dyanYOutput: parseInt(this.data[8].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[8].dynaZfadeTime),
    });
    let dynaModX4 = parseFloat(this.data[10].dyanXTime);
    let modTimeXValue4 = this.getDynaTimeXRefernce(dynaModX4);
    draggedData.push({
      dyanXTime: modTimeXValue4,
      dyanYOutput: parseInt(this.data[10].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[10].dynaZfadeTime),
    });
    let dynaModX5= parseFloat(this.data[11].dyanXTime);
    let modTimeXValue5 = this.getDynaTimeXRefernce(dynaModX5);
    draggedData.push({
      dyanXTime: modTimeXValue5,
      dyanYOutput: parseInt(this.data[12].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[11].dynaZfadeTime),
    });

    let dynaModX6= parseFloat(this.data[13].dyanXTime);
    let modTimeXValue6 = this.getDynaTimeXRefernce(dynaModX6);
    draggedData.push({
      dyanXTime: modTimeXValue6,
      dyanYOutput: parseInt(this.data[14].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[13].dynaZfadeTime),
    });
  }

  if (this.changedPoint === 14) {
    //output Only
    let modifiedOutput = parseInt(this.data[14].dyanYOutput);

    let dynaModX = parseFloat(this.data[2].dyanXTime);
    let modTimeXValue = this.getDynaTimeXRefernce(dynaModX);
    draggedData.push({
      dyanXTime: modTimeXValue,
      dyanYOutput: parseInt(this.data[2].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
    });

    let dynaModX1 = parseFloat(this.data[4].dyanXTime);
    let modTimeXValue1 = this.getDynaTimeXRefernce(dynaModX1);
    draggedData.push({
      dyanXTime: modTimeXValue1,
      dyanYOutput: parseInt(this.data[4].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
    });

    let dynaModX2 = parseFloat(this.data[6].dyanXTime);
    let modTimeXValue2 = this.getDynaTimeXRefernce(dynaModX2);
    draggedData.push({
      dyanXTime: modTimeXValue2,
      dyanYOutput: parseInt(this.data[6].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[6].dynaZfadeTime),
    });

    let dynaModX3 = parseFloat(this.data[8].dyanXTime);
    let modTimeXValue3 = this.getDynaTimeXRefernce(dynaModX3);
    draggedData.push({
      dyanXTime: modTimeXValue3,
      dyanYOutput: parseInt(this.data[8].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[8].dynaZfadeTime),
    });
    let dynaModX4 = parseFloat(this.data[10].dyanXTime);
    let modTimeXValue4 = this.getDynaTimeXRefernce(dynaModX4);
    draggedData.push({
      dyanXTime: modTimeXValue4,
      dyanYOutput: parseInt(this.data[10].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[10].dynaZfadeTime),
    });
    let dynaModX5 = parseFloat(this.data[12].dyanXTime);
    let modTimeXValue5 = this.getDynaTimeXRefernce(dynaModX5);
    draggedData.push({
      dyanXTime: modTimeXValue5,
      dyanYOutput: parseInt(this.data[12].dyanYOutput),
      dynaZfadeTime: parseInt(this.data[12].dynaZfadeTime),
    });

    let dynaModX6 = parseFloat(this.data[14].dyanXTime);
    let modTimeXValue6 = this.getDynaTimeXRefernce(dynaModX6);
    draggedData.push({
      dyanXTime: modTimeXValue6,
      dyanYOutput: modifiedOutput,
      dynaZfadeTime: parseInt(this.data[14].dynaZfadeTime),
    });
    
  }
}


/////SCENE 7 end
  //////////////////////SCENE 6
  updateScenes6(data: any[], draggedData: any) {
    if (this.changedPoint === 0) {
      //OUTPUT
      let modifiedOutput = parseInt(this.data[0].dyanYOutput);

      let dynaModX = parseFloat(this.data[2].dyanXTime);
      let modTimeXValue = this.getDynaTimeXRefernce(dynaModX);
      draggedData.push({
        dyanXTime: modTimeXValue,
        dyanYOutput: parseInt(this.data[2].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
      });

      let dynaModX1 = parseFloat(this.data[4].dyanXTime);
      let modTimeXValue1 = this.getDynaTimeXRefernce(dynaModX1);
      draggedData.push({
        dyanXTime: modTimeXValue1,
        dyanYOutput: parseInt(this.data[4].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
      });

      let dynaModX2 = parseFloat(this.data[6].dyanXTime);
      let modTimeXValue2 = this.getDynaTimeXRefernce(dynaModX2);
      draggedData.push({
        dyanXTime: modTimeXValue2,
        dyanYOutput: parseInt(this.data[6].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[6].dynaZfadeTime),
      });

      let dynaModX3 = parseFloat(this.data[8].dyanXTime);
      let modTimeXValue3 = this.getDynaTimeXRefernce(dynaModX3);
      draggedData.push({
        dyanXTime: modTimeXValue3,
        dyanYOutput: parseInt(this.data[8].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[8].dynaZfadeTime),
      });

      let dynaModX4 = parseFloat(this.data[10].dyanXTime);
      let modTimeXValue4 = this.getDynaTimeXRefernce(dynaModX4);
      draggedData.push({
        dyanXTime: modTimeXValue4,
        dyanYOutput: modifiedOutput,
        dynaZfadeTime: parseInt(this.data[10].dynaZfadeTime),
      });
    }
    if (this.changedPoint === 1) {
      //TIME
      let modifiedOutput = parseFloat(this.data[1].dyanXTime);

      let modTimeXValue = this.getDynaTimeXRefernce(modifiedOutput);
      draggedData.push({
        dyanXTime: modTimeXValue,
        dyanYOutput: parseInt(this.data[2].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
      });

      let dynaModX1 = parseFloat(this.data[4].dyanXTime);
      let modTimeXValue1 = this.getDynaTimeXRefernce(dynaModX1);
      draggedData.push({
        dyanXTime: modTimeXValue1,
        dyanYOutput: parseInt(this.data[4].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
      });
      let dynaModX2 = parseFloat(this.data[6].dyanXTime);
      let modTimeXValue2 = this.getDynaTimeXRefernce(dynaModX2);
      draggedData.push({
        dyanXTime: modTimeXValue2,
        dyanYOutput: parseInt(this.data[6].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[6].dynaZfadeTime),
      });

      let dynaModX3 = parseFloat(this.data[8].dyanXTime);
      let modTimeXValue3 = this.getDynaTimeXRefernce(dynaModX3);
      draggedData.push({
        dyanXTime: modTimeXValue3,
        dyanYOutput: parseInt(this.data[8].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[8].dynaZfadeTime),
      });

      let dynaModX4 = parseFloat(this.data[10].dyanXTime);
      let modTimeXValue4 = this.getDynaTimeXRefernce(dynaModX4);
      draggedData.push({
        dyanXTime: modTimeXValue4,
        dyanYOutput: parseInt(this.data[10].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[10].dynaZfadeTime),
      });
    }
    if (this.changedPoint === 2) {
      //OUTPUT
      let modifiedOutput = parseInt(this.data[2].dyanYOutput);

      let dynaModX = parseFloat(this.data[1].dyanXTime);
      let modTimeXValue = this.getDynaTimeXRefernce(dynaModX);
      draggedData.push({
        dyanXTime: modTimeXValue,
        dyanYOutput: modifiedOutput,
        dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
      });

      let dynaModX1 = parseFloat(this.data[4].dyanXTime);
      let modTimeXValue1 = this.getDynaTimeXRefernce(dynaModX1);
      draggedData.push({
        dyanXTime: modTimeXValue1,
        dyanYOutput: parseInt(this.data[4].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
      });

      let dynaModX2 = parseFloat(this.data[6].dyanXTime);
      let modTimeXValue2 = this.getDynaTimeXRefernce(dynaModX2);
      draggedData.push({
        dyanXTime: modTimeXValue2,
        dyanYOutput: parseInt(this.data[6].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[6].dynaZfadeTime),
      });

      let dynaModX3 = parseFloat(this.data[8].dyanXTime);
      let modTimeXValue3 = this.getDynaTimeXRefernce(dynaModX3);
      draggedData.push({
        dyanXTime: modTimeXValue3,
        dyanYOutput: parseInt(this.data[8].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[8].dynaZfadeTime),
      });
      let dynaModX4 = parseFloat(this.data[10].dyanXTime);
      let modTimeXValue4 = this.getDynaTimeXRefernce(dynaModX4);
      draggedData.push({
        dyanXTime: modTimeXValue4,
        dyanYOutput: parseInt(this.data[10].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[10].dynaZfadeTime),
      });
    }
    if (this.changedPoint === 3) {
      //TIME ONLY
      let modifiedOutput = parseFloat(this.data[3].dyanXTime);

      let dynaModX = parseFloat(this.data[2].dyanXTime);
      let modTimeXValue = this.getDynaTimeXRefernce(dynaModX);
      draggedData.push({
        dyanXTime: modTimeXValue,
        dyanYOutput: parseInt(this.data[2].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
      });

      let modTimeXValue1 = this.getDynaTimeXRefernce(modifiedOutput);
      draggedData.push({
        dyanXTime: modTimeXValue1,
        dyanYOutput: parseInt(this.data[4].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
      });

      let dynaModX2 = parseFloat(this.data[6].dyanXTime);
      let modTimeXValue2 = this.getDynaTimeXRefernce(dynaModX2);
      draggedData.push({
        dyanXTime: modTimeXValue2,
        dyanYOutput: parseInt(this.data[6].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[6].dynaZfadeTime),
      });
      let dynaModX3 = parseFloat(this.data[8].dyanXTime);
      let modTimeXValue3 = this.getDynaTimeXRefernce(dynaModX3);
      draggedData.push({
        dyanXTime: modTimeXValue3,
        dyanYOutput: parseInt(this.data[8].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[8].dynaZfadeTime),
      });
      let dynaModX4 = parseFloat(this.data[10].dyanXTime);
      let modTimeXValue4 = this.getDynaTimeXRefernce(dynaModX4);
      draggedData.push({
        dyanXTime: modTimeXValue4,
        dyanYOutput: parseInt(this.data[10].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[10].dynaZfadeTime),
      });
    }

    if (this.changedPoint === 4) {
      //OUTPUT ONLY - Chnage Scene 2
      let modifiedOutput = parseInt(this.data[4].dyanYOutput);

      let dynaModX = parseFloat(this.data[2].dyanXTime);
      let modTimeXValue = this.getDynaTimeXRefernce(dynaModX);
      draggedData.push({
        dyanXTime: modTimeXValue,
        dyanYOutput: parseInt(this.data[2].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
      });

      let dynaModX1 = parseFloat(this.data[3].dyanXTime);
      let modTimeXValue1 = this.getDynaTimeXRefernce(dynaModX1);
      draggedData.push({
        dyanXTime: modTimeXValue1,
        dyanYOutput: modifiedOutput,
        dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
      });

      let dynaModX2 = parseFloat(this.data[6].dyanXTime);
      let modTimeXValue2 = this.getDynaTimeXRefernce(dynaModX2);
      draggedData.push({
        dyanXTime: modTimeXValue2,
        dyanYOutput: parseInt(this.data[6].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[6].dynaZfadeTime),
      });

      let dynaModX3 = parseFloat(this.data[8].dyanXTime);
      let modTimeXValue3 = this.getDynaTimeXRefernce(dynaModX3);
      draggedData.push({
        dyanXTime: modTimeXValue3,
        dyanYOutput: parseInt(this.data[8].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[8].dynaZfadeTime),
      });
      let dynaModX4 = parseFloat(this.data[10].dyanXTime);
      let modTimeXValue4 = this.getDynaTimeXRefernce(dynaModX4);
      draggedData.push({
        dyanXTime: modTimeXValue4,
        dyanYOutput: parseInt(this.data[10].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[10].dynaZfadeTime),
      });
    }
    if (this.changedPoint === 5) {
      //TIME ONLY
      let modifiedOutput = parseFloat(this.data[5].dyanXTime);

      let dynaModX = parseFloat(this.data[2].dyanXTime);
      let modTimeXValue = this.getDynaTimeXRefernce(dynaModX);
      draggedData.push({
        dyanXTime: modTimeXValue,
        dyanYOutput: parseInt(this.data[2].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
      });

      let dynaModX1 = parseFloat(this.data[4].dyanXTime);
      let modTimeXValue1 = this.getDynaTimeXRefernce(dynaModX1);
      draggedData.push({
        dyanXTime: modTimeXValue1,
        dyanYOutput: parseInt(this.data[4].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
      });

      let modTimeXValue2 = this.getDynaTimeXRefernce(modifiedOutput);
      draggedData.push({
        dyanXTime: modTimeXValue2,
        dyanYOutput: parseInt(this.data[6].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[6].dynaZfadeTime),
      });

      let dynaModX3 = parseFloat(this.data[8].dyanXTime);
      let modTimeXValue3 = this.getDynaTimeXRefernce(dynaModX3);
      draggedData.push({
        dyanXTime: modTimeXValue3,
        dyanYOutput: parseInt(this.data[8].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[8].dynaZfadeTime),
      });
      let dynaModX4 = parseFloat(this.data[10].dyanXTime);
      let modTimeXValue4 = this.getDynaTimeXRefernce(dynaModX4);
      draggedData.push({
        dyanXTime: modTimeXValue4,
        dyanYOutput: parseInt(this.data[10].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[10].dynaZfadeTime),
      });
    }
    if (this.changedPoint === 6) {
      //OUTPUT ONLY - Chnage Scene 2
      let modifiedOutput = parseInt(this.data[6].dyanYOutput);

      let dynaModX = parseFloat(this.data[2].dyanXTime);
      let modTimeXValue = this.getDynaTimeXRefernce(dynaModX);
      draggedData.push({
        dyanXTime: modTimeXValue,
        dyanYOutput: parseInt(this.data[2].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
      });

      let dynaModX1 = parseFloat(this.data[4].dyanXTime);
      let modTimeXValue1 = this.getDynaTimeXRefernce(dynaModX1);
      draggedData.push({
        dyanXTime: modTimeXValue1,
        dyanYOutput: parseInt(this.data[4].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
      });

      let dynaModX2 = parseFloat(this.data[5].dyanXTime);
      let modTimeXValue2 = this.getDynaTimeXRefernce(dynaModX2);
      draggedData.push({
        dyanXTime: modTimeXValue2,
        dyanYOutput: modifiedOutput,
        dynaZfadeTime: parseInt(this.data[6].dynaZfadeTime),
      });

      let dynaModX3 = parseFloat(this.data[8].dyanXTime);
      let modTimeXValue3 = this.getDynaTimeXRefernce(dynaModX3);
      draggedData.push({
        dyanXTime: modTimeXValue3,
        dyanYOutput: parseInt(this.data[8].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[8].dynaZfadeTime),
      });
      let dynaModX4 = parseFloat(this.data[10].dyanXTime);
      let modTimeXValue4 = this.getDynaTimeXRefernce(dynaModX4);
      draggedData.push({
        dyanXTime: modTimeXValue4,
        dyanYOutput: parseInt(this.data[10].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[10].dynaZfadeTime),
      });
    }
    if (this.changedPoint === 7) {
      //TIME ONLY
      let modifiedOutput = parseFloat(this.data[7].dyanXTime);

      let dynaModX = parseFloat(this.data[2].dyanXTime);
      let modTimeXValue = this.getDynaTimeXRefernce(dynaModX);
      draggedData.push({
        dyanXTime: modTimeXValue,
        dyanYOutput: parseInt(this.data[2].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
      });

      let dynaModX1 = parseFloat(this.data[4].dyanXTime);
      let modTimeXValue1 = this.getDynaTimeXRefernce(dynaModX1);
      draggedData.push({
        dyanXTime: modTimeXValue1,
        dyanYOutput: parseInt(this.data[4].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
      });

      let dynaModX2 = parseFloat(this.data[6].dyanXTime);
      let modTimeXValue2 = this.getDynaTimeXRefernce(dynaModX2);
      draggedData.push({
        dyanXTime: modTimeXValue2,
        dyanYOutput: parseInt(this.data[6].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[6].dynaZfadeTime),
      });

      let modTimeXValue3 = this.getDynaTimeXRefernce(modifiedOutput);
      draggedData.push({
        dyanXTime: modTimeXValue3,
        dyanYOutput: parseInt(this.data[8].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[8].dynaZfadeTime),
      });

      let dynaModX4 = parseFloat(this.data[10].dyanXTime);
      let modTimeXValue4 = this.getDynaTimeXRefernce(dynaModX4);
      draggedData.push({
        dyanXTime: modTimeXValue4,
        dyanYOutput: parseInt(this.data[10].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[10].dynaZfadeTime),
      });
    }
    if (this.changedPoint === 8) {
      //OUTPUT ONLY - Chnage Scene 2
      let modifiedOutput = parseInt(this.data[8].dyanYOutput);

      let dynaModX = parseFloat(this.data[2].dyanXTime);
      let modTimeXValue = this.getDynaTimeXRefernce(dynaModX);
      draggedData.push({
        dyanXTime: modTimeXValue,
        dyanYOutput: parseInt(this.data[2].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
      });

      let dynaModX1 = parseFloat(this.data[4].dyanXTime);
      let modTimeXValue1 = this.getDynaTimeXRefernce(dynaModX1);
      draggedData.push({
        dyanXTime: modTimeXValue1,
        dyanYOutput: parseInt(this.data[4].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
      });

      let dynaModX2 = parseFloat(this.data[6].dyanXTime);
      let modTimeXValue2 = this.getDynaTimeXRefernce(dynaModX2);
      draggedData.push({
        dyanXTime: modTimeXValue2,
        dyanYOutput: parseInt(this.data[6].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[6].dynaZfadeTime),
      });

      let dynaModX3 = parseFloat(this.data[7].dyanXTime);
      let modTimeXValue3 = this.getDynaTimeXRefernce(dynaModX3);
      draggedData.push({
        dyanXTime: modTimeXValue3,
        dyanYOutput: modifiedOutput,
        dynaZfadeTime: parseInt(this.data[8].dynaZfadeTime),
      });

      let dynaModX4 = parseFloat(this.data[10].dyanXTime);
      let modTimeXValue4 = this.getDynaTimeXRefernce(dynaModX4);
      draggedData.push({
        dyanXTime: modTimeXValue4,
        dyanYOutput: parseInt(this.data[10].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[10].dynaZfadeTime),
      });
    }
    if (this.changedPoint === 9) {
      //TIME ONLY
      let modifiedOutput = parseFloat(this.data[9].dyanXTime);

      let dynaModX = parseFloat(this.data[2].dyanXTime);
      let modTimeXValue = this.getDynaTimeXRefernce(dynaModX);
      draggedData.push({
        dyanXTime: modTimeXValue,
        dyanYOutput: parseInt(this.data[2].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
      });

      let dynaModX1 = parseFloat(this.data[4].dyanXTime);
      let modTimeXValue1 = this.getDynaTimeXRefernce(dynaModX1);
      draggedData.push({
        dyanXTime: modTimeXValue1,
        dyanYOutput: parseInt(this.data[4].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
      });

      let dynaModX2 = parseFloat(this.data[6].dyanXTime);
      let modTimeXValue2 = this.getDynaTimeXRefernce(dynaModX2);
      draggedData.push({
        dyanXTime: modTimeXValue2,
        dyanYOutput: parseInt(this.data[6].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[6].dynaZfadeTime),
      });

      let dynaModX3 = parseFloat(this.data[8].dyanXTime);
      let modTimeXValue3 = this.getDynaTimeXRefernce(dynaModX3);
      draggedData.push({
        dyanXTime: modTimeXValue3,
        dyanYOutput: parseInt(this.data[8].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[8].dynaZfadeTime),
      });

      let modTimeXValue4 = this.getDynaTimeXRefernce(modifiedOutput);
      draggedData.push({
        dyanXTime: modTimeXValue4,
        dyanYOutput: parseInt(this.data[10].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[10].dynaZfadeTime),
      });
    }
    if (this.changedPoint === 10) {
      //OUTPUT ONLY - Chnage Scene 2
      let modifiedOutput = parseInt(this.data[10].dyanYOutput);

      let dynaModX = parseFloat(this.data[2].dyanXTime);
      let modTimeXValue = this.getDynaTimeXRefernce(dynaModX);
      draggedData.push({
        dyanXTime: modTimeXValue,
        dyanYOutput: parseInt(this.data[2].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
      });

      let dynaModX1 = parseFloat(this.data[4].dyanXTime);
      let modTimeXValue1 = this.getDynaTimeXRefernce(dynaModX1);
      draggedData.push({
        dyanXTime: modTimeXValue1,
        dyanYOutput: parseInt(this.data[4].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
      });

      let dynaModX2 = parseFloat(this.data[6].dyanXTime);
      let modTimeXValue2 = this.getDynaTimeXRefernce(dynaModX2);
      draggedData.push({
        dyanXTime: modTimeXValue2,
        dyanYOutput: parseInt(this.data[6].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[6].dynaZfadeTime),
      });

      let dynaModX3 = parseFloat(this.data[8].dyanXTime);
      let modTimeXValue3 = this.getDynaTimeXRefernce(dynaModX3);
      draggedData.push({
        dyanXTime: modTimeXValue3,
        dyanYOutput: parseInt(this.data[8].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[8].dynaZfadeTime),
      });

      let dynaModX4 = parseFloat(this.data[9].dyanXTime);
      let modTimeXValue4 = this.getDynaTimeXRefernce(dynaModX4);
      draggedData.push({
        dyanXTime: modTimeXValue4,
        dyanYOutput: modifiedOutput,
        dynaZfadeTime: parseInt(this.data[10].dynaZfadeTime),
      });
    }
    if (this.changedPoint === 11) {
      let modifiedOutput = parseInt(this.data[11].dyanXTime);

      let dynaModX = parseFloat(this.data[2].dyanXTime);
      let modTimeXValue = this.getDynaTimeXRefernce(dynaModX);
      draggedData.push({
        dyanXTime: modTimeXValue,
        dyanYOutput: parseInt(this.data[2].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
      });

      let dynaModX1 = parseFloat(this.data[4].dyanXTime);
      let modTimeXValue1 = this.getDynaTimeXRefernce(dynaModX1);
      draggedData.push({
        dyanXTime: modTimeXValue1,
        dyanYOutput: parseInt(this.data[4].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
      });

      let dynaModX2 = parseFloat(this.data[6].dyanXTime);
      let modTimeXValue2 = this.getDynaTimeXRefernce(dynaModX2);
      draggedData.push({
        dyanXTime: modTimeXValue2,
        dyanYOutput: parseInt(this.data[6].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[6].dynaZfadeTime),
      });

      let dynaModX3 = parseFloat(this.data[8].dyanXTime);
      let modTimeXValue3 = this.getDynaTimeXRefernce(dynaModX3);
      draggedData.push({
        dyanXTime: modTimeXValue3,
        dyanYOutput: parseInt(this.data[8].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[8].dynaZfadeTime),
      });
      let dynaModX4 = parseFloat(this.data[10].dyanXTime);
      let modTimeXValue4 = this.getDynaTimeXRefernce(dynaModX4);
      draggedData.push({
        dyanXTime: modTimeXValue4,
        dyanYOutput: parseInt(this.data[10].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[10].dynaZfadeTime),
      });
      let dynaModX5= parseFloat(this.data[11].dyanXTime);
      let modTimeXValue5 = this.getDynaTimeXRefernce(dynaModX5);
      draggedData.push({
        dyanXTime: modTimeXValue5,
        dyanYOutput: parseInt(this.data[12].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[11].dynaZfadeTime),
      });
    }

    if (this.changedPoint === 12) {
      //output Only
      let modifiedOutput = parseInt(this.data[12].dyanYOutput);

      let dynaModX = parseFloat(this.data[2].dyanXTime);
      let modTimeXValue = this.getDynaTimeXRefernce(dynaModX);
      draggedData.push({
        dyanXTime: modTimeXValue,
        dyanYOutput: parseInt(this.data[2].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
      });

      let dynaModX1 = parseFloat(this.data[4].dyanXTime);
      let modTimeXValue1 = this.getDynaTimeXRefernce(dynaModX1);
      draggedData.push({
        dyanXTime: modTimeXValue1,
        dyanYOutput: parseInt(this.data[4].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
      });

      let dynaModX2 = parseFloat(this.data[6].dyanXTime);
      let modTimeXValue2 = this.getDynaTimeXRefernce(dynaModX2);
      draggedData.push({
        dyanXTime: modTimeXValue2,
        dyanYOutput: parseInt(this.data[6].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[6].dynaZfadeTime),
      });

      let dynaModX3 = parseFloat(this.data[8].dyanXTime);
      let modTimeXValue3 = this.getDynaTimeXRefernce(dynaModX3);
      draggedData.push({
        dyanXTime: modTimeXValue3,
        dyanYOutput: parseInt(this.data[8].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[8].dynaZfadeTime),
      });
      let dynaModX4 = parseFloat(this.data[10].dyanXTime);
      let modTimeXValue4 = this.getDynaTimeXRefernce(dynaModX4);
      draggedData.push({
        dyanXTime: modTimeXValue4,
        dyanYOutput: parseInt(this.data[10].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[10].dynaZfadeTime),
      });
      let dynaModX5 = parseFloat(this.data[11].dyanXTime);
      let modTimeXValue5 = this.getDynaTimeXRefernce(dynaModX5);
      draggedData.push({
        dyanXTime: modTimeXValue5,
        dyanYOutput: modifiedOutput,
        dynaZfadeTime: parseInt(this.data[12].dynaZfadeTime),
      });
    }
    if (this.changedPoint === 13) {
      //output Only
      let modifiedOutput = parseInt(this.data[13].dyanYOutput);

      let dynaModX = parseFloat(this.data[2].dyanXTime);
      let modTimeXValue = this.getDynaTimeXRefernce(dynaModX);
      draggedData.push({
        dyanXTime: modTimeXValue,
        dyanYOutput: parseInt(this.data[2].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
      });

      let dynaModX1 = parseFloat(this.data[4].dyanXTime);
      let modTimeXValue1 = this.getDynaTimeXRefernce(dynaModX1);
      draggedData.push({
        dyanXTime: modTimeXValue1,
        dyanYOutput: parseInt(this.data[4].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
      });

      let dynaModX2 = parseFloat(this.data[6].dyanXTime);
      let modTimeXValue2 = this.getDynaTimeXRefernce(dynaModX2);
      draggedData.push({
        dyanXTime: modTimeXValue2,
        dyanYOutput: parseInt(this.data[6].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[6].dynaZfadeTime),
      });

      let dynaModX3 = parseFloat(this.data[8].dyanXTime);
      let modTimeXValue3 = this.getDynaTimeXRefernce(dynaModX3);
      draggedData.push({
        dyanXTime: modTimeXValue3,
        dyanYOutput: parseInt(this.data[8].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[8].dynaZfadeTime),
      });
      let dynaModX4 = parseFloat(this.data[10].dyanXTime);
      let modTimeXValue4 = this.getDynaTimeXRefernce(dynaModX4);
      draggedData.push({
        dyanXTime: modTimeXValue4,
        dyanYOutput: modifiedOutput,
        dynaZfadeTime: parseInt(this.data[10].dynaZfadeTime),
      });
      let dynaModX5 = parseFloat(this.data[12].dyanXTime);
      let modTimeXValue5 = this.getDynaTimeXRefernce(dynaModX5);
      draggedData.push({
        dyanXTime: modTimeXValue5,
        dyanYOutput: modifiedOutput,
        dynaZfadeTime: parseInt(this.data[12].dynaZfadeTime),
      });
    }
  }
/////////////SCENE 6 end
  
  updateScenes5(data: any[], draggedData: any) {
    if (this.changedPoint === 0) {
      //OUTPUT
      let modifiedOutput = parseInt(this.data[0].dyanYOutput);

      let dynaModX = parseFloat(this.data[2].dyanXTime);
      let modTimeXValue = this.getDynaTimeXRefernce(dynaModX);
      draggedData.push({
        dyanXTime: modTimeXValue,
        dyanYOutput: parseInt(this.data[2].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
      });

      let dynaModX1 = parseFloat(this.data[4].dyanXTime);
      let modTimeXValue1 = this.getDynaTimeXRefernce(dynaModX1);
      draggedData.push({
        dyanXTime: modTimeXValue1,
        dyanYOutput: parseInt(this.data[4].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
      });

      let dynaModX2 = parseFloat(this.data[6].dyanXTime);
      let modTimeXValue2 = this.getDynaTimeXRefernce(dynaModX2);
      draggedData.push({
        dyanXTime: modTimeXValue2,
        dyanYOutput: parseInt(this.data[6].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[6].dynaZfadeTime),
      });

      let dynaModX3 = parseFloat(this.data[8].dyanXTime);
      let modTimeXValue3 = this.getDynaTimeXRefernce(dynaModX3);
      draggedData.push({
        dyanXTime: modTimeXValue3,
        dyanYOutput: parseInt(this.data[8].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[8].dynaZfadeTime),
      });

      let dynaModX4 = parseFloat(this.data[10].dyanXTime);
      let modTimeXValue4 = this.getDynaTimeXRefernce(dynaModX4);
      draggedData.push({
        dyanXTime: modTimeXValue4,
        dyanYOutput: modifiedOutput,
        dynaZfadeTime: parseInt(this.data[10].dynaZfadeTime),
      });
    }
    if (this.changedPoint === 1) {
      //TIME
      let modifiedOutput = parseFloat(this.data[1].dyanXTime);

      let modTimeXValue = this.getDynaTimeXRefernce(modifiedOutput);
      draggedData.push({
        dyanXTime: modTimeXValue,
        dyanYOutput: parseInt(this.data[2].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
      });

      let dynaModX1 = parseFloat(this.data[4].dyanXTime);
      let modTimeXValue1 = this.getDynaTimeXRefernce(dynaModX1);
      draggedData.push({
        dyanXTime: modTimeXValue1,
        dyanYOutput: parseInt(this.data[4].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
      });
      let dynaModX2 = parseFloat(this.data[6].dyanXTime);
      let modTimeXValue2 = this.getDynaTimeXRefernce(dynaModX2);
      draggedData.push({
        dyanXTime: modTimeXValue2,
        dyanYOutput: parseInt(this.data[6].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[6].dynaZfadeTime),
      });

      let dynaModX3 = parseFloat(this.data[8].dyanXTime);
      let modTimeXValue3 = this.getDynaTimeXRefernce(dynaModX3);
      draggedData.push({
        dyanXTime: modTimeXValue3,
        dyanYOutput: parseInt(this.data[8].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[8].dynaZfadeTime),
      });

      let dynaModX4 = parseFloat(this.data[10].dyanXTime);
      let modTimeXValue4 = this.getDynaTimeXRefernce(dynaModX4);
      draggedData.push({
        dyanXTime: modTimeXValue4,
        dyanYOutput: parseInt(this.data[10].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[10].dynaZfadeTime),
      });
    }
    if (this.changedPoint === 2) {
      //OUTPUT
      let modifiedOutput = parseInt(this.data[2].dyanYOutput);

      let dynaModX = parseFloat(this.data[1].dyanXTime);
      let modTimeXValue = this.getDynaTimeXRefernce(dynaModX);
      draggedData.push({
        dyanXTime: modTimeXValue,
        dyanYOutput: modifiedOutput,
        dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
      });

      let dynaModX1 = parseFloat(this.data[4].dyanXTime);
      let modTimeXValue1 = this.getDynaTimeXRefernce(dynaModX1);
      draggedData.push({
        dyanXTime: modTimeXValue1,
        dyanYOutput: parseInt(this.data[4].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
      });

      let dynaModX2 = parseFloat(this.data[6].dyanXTime);
      let modTimeXValue2 = this.getDynaTimeXRefernce(dynaModX2);
      draggedData.push({
        dyanXTime: modTimeXValue2,
        dyanYOutput: parseInt(this.data[6].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[6].dynaZfadeTime),
      });

      let dynaModX3 = parseFloat(this.data[8].dyanXTime);
      let modTimeXValue3 = this.getDynaTimeXRefernce(dynaModX3);
      draggedData.push({
        dyanXTime: modTimeXValue3,
        dyanYOutput: parseInt(this.data[8].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[8].dynaZfadeTime),
      });
      let dynaModX4 = parseFloat(this.data[10].dyanXTime);
      let modTimeXValue4 = this.getDynaTimeXRefernce(dynaModX4);
      draggedData.push({
        dyanXTime: modTimeXValue4,
        dyanYOutput: parseInt(this.data[10].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[10].dynaZfadeTime),
      });
    }
    if (this.changedPoint === 3) {
      //TIME ONLY
      let modifiedOutput = parseFloat(this.data[3].dyanXTime);

      let dynaModX = parseFloat(this.data[2].dyanXTime);
      let modTimeXValue = this.getDynaTimeXRefernce(dynaModX);
      draggedData.push({
        dyanXTime: modTimeXValue,
        dyanYOutput: parseInt(this.data[2].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
      });

      let modTimeXValue1 = this.getDynaTimeXRefernce(modifiedOutput);
      draggedData.push({
        dyanXTime: modTimeXValue1,
        dyanYOutput: parseInt(this.data[4].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
      });

      let dynaModX2 = parseFloat(this.data[6].dyanXTime);
      let modTimeXValue2 = this.getDynaTimeXRefernce(dynaModX2);
      draggedData.push({
        dyanXTime: modTimeXValue2,
        dyanYOutput: parseInt(this.data[6].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[6].dynaZfadeTime),
      });
      let dynaModX3 = parseFloat(this.data[8].dyanXTime);
      let modTimeXValue3 = this.getDynaTimeXRefernce(dynaModX3);
      draggedData.push({
        dyanXTime: modTimeXValue3,
        dyanYOutput: parseInt(this.data[8].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[8].dynaZfadeTime),
      });
      let dynaModX4 = parseFloat(this.data[10].dyanXTime);
      let modTimeXValue4 = this.getDynaTimeXRefernce(dynaModX4);
      draggedData.push({
        dyanXTime: modTimeXValue4,
        dyanYOutput: parseInt(this.data[10].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[10].dynaZfadeTime),
      });
    }

    if (this.changedPoint === 4) {
      //OUTPUT ONLY - Chnage Scene 2
      let modifiedOutput = parseInt(this.data[4].dyanYOutput);

      let dynaModX = parseFloat(this.data[2].dyanXTime);
      let modTimeXValue = this.getDynaTimeXRefernce(dynaModX);
      draggedData.push({
        dyanXTime: modTimeXValue,
        dyanYOutput: parseInt(this.data[2].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
      });

      let dynaModX1 = parseFloat(this.data[3].dyanXTime);
      let modTimeXValue1 = this.getDynaTimeXRefernce(dynaModX1);
      draggedData.push({
        dyanXTime: modTimeXValue1,
        dyanYOutput: modifiedOutput,
        dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
      });

      let dynaModX2 = parseFloat(this.data[6].dyanXTime);
      let modTimeXValue2 = this.getDynaTimeXRefernce(dynaModX2);
      draggedData.push({
        dyanXTime: modTimeXValue2,
        dyanYOutput: parseInt(this.data[6].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[6].dynaZfadeTime),
      });

      let dynaModX3 = parseFloat(this.data[8].dyanXTime);
      let modTimeXValue3 = this.getDynaTimeXRefernce(dynaModX3);
      draggedData.push({
        dyanXTime: modTimeXValue3,
        dyanYOutput: parseInt(this.data[8].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[8].dynaZfadeTime),
      });
      let dynaModX4 = parseFloat(this.data[10].dyanXTime);
      let modTimeXValue4 = this.getDynaTimeXRefernce(dynaModX4);
      draggedData.push({
        dyanXTime: modTimeXValue4,
        dyanYOutput: parseInt(this.data[10].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[10].dynaZfadeTime),
      });
    }
    if (this.changedPoint === 5) {
      //TIME ONLY
      let modifiedOutput = parseFloat(this.data[5].dyanXTime);

      let dynaModX = parseFloat(this.data[2].dyanXTime);
      let modTimeXValue = this.getDynaTimeXRefernce(dynaModX);
      draggedData.push({
        dyanXTime: modTimeXValue,
        dyanYOutput: parseInt(this.data[2].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
      });

      let dynaModX1 = parseFloat(this.data[4].dyanXTime);
      let modTimeXValue1 = this.getDynaTimeXRefernce(dynaModX1);
      draggedData.push({
        dyanXTime: modTimeXValue1,
        dyanYOutput: parseInt(this.data[4].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
      });

      let modTimeXValue2 = this.getDynaTimeXRefernce(modifiedOutput);
      draggedData.push({
        dyanXTime: modTimeXValue2,
        dyanYOutput: parseInt(this.data[6].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[6].dynaZfadeTime),
      });

      let dynaModX3 = parseFloat(this.data[8].dyanXTime);
      let modTimeXValue3 = this.getDynaTimeXRefernce(dynaModX3);
      draggedData.push({
        dyanXTime: modTimeXValue3,
        dyanYOutput: parseInt(this.data[8].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[8].dynaZfadeTime),
      });
      let dynaModX4 = parseFloat(this.data[10].dyanXTime);
      let modTimeXValue4 = this.getDynaTimeXRefernce(dynaModX4);
      draggedData.push({
        dyanXTime: modTimeXValue4,
        dyanYOutput: parseInt(this.data[10].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[10].dynaZfadeTime),
      });
    }
    if (this.changedPoint === 6) {
      //OUTPUT ONLY - Chnage Scene 2
      let modifiedOutput = parseInt(this.data[6].dyanYOutput);

      let dynaModX = parseFloat(this.data[2].dyanXTime);
      let modTimeXValue = this.getDynaTimeXRefernce(dynaModX);
      draggedData.push({
        dyanXTime: modTimeXValue,
        dyanYOutput: parseInt(this.data[2].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
      });

      let dynaModX1 = parseFloat(this.data[4].dyanXTime);
      let modTimeXValue1 = this.getDynaTimeXRefernce(dynaModX1);
      draggedData.push({
        dyanXTime: modTimeXValue1,
        dyanYOutput: parseInt(this.data[4].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
      });

      let dynaModX2 = parseFloat(this.data[5].dyanXTime);
      let modTimeXValue2 = this.getDynaTimeXRefernce(dynaModX2);
      draggedData.push({
        dyanXTime: modTimeXValue2,
        dyanYOutput: modifiedOutput,
        dynaZfadeTime: parseInt(this.data[6].dynaZfadeTime),
      });

      let dynaModX3 = parseFloat(this.data[8].dyanXTime);
      let modTimeXValue3 = this.getDynaTimeXRefernce(dynaModX3);
      draggedData.push({
        dyanXTime: modTimeXValue3,
        dyanYOutput: parseInt(this.data[8].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[8].dynaZfadeTime),
      });
      let dynaModX4 = parseFloat(this.data[10].dyanXTime);
      let modTimeXValue4 = this.getDynaTimeXRefernce(dynaModX4);
      draggedData.push({
        dyanXTime: modTimeXValue4,
        dyanYOutput: parseInt(this.data[10].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[10].dynaZfadeTime),
      });
    }
    if (this.changedPoint === 7) {
      //TIME ONLY
      let modifiedOutput = parseFloat(this.data[7].dyanXTime);

      let dynaModX = parseFloat(this.data[2].dyanXTime);
      let modTimeXValue = this.getDynaTimeXRefernce(dynaModX);
      draggedData.push({
        dyanXTime: modTimeXValue,
        dyanYOutput: parseInt(this.data[2].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
      });

      let dynaModX1 = parseFloat(this.data[4].dyanXTime);
      let modTimeXValue1 = this.getDynaTimeXRefernce(dynaModX1);
      draggedData.push({
        dyanXTime: modTimeXValue1,
        dyanYOutput: parseInt(this.data[4].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
      });

      let dynaModX2 = parseFloat(this.data[6].dyanXTime);
      let modTimeXValue2 = this.getDynaTimeXRefernce(dynaModX2);
      draggedData.push({
        dyanXTime: modTimeXValue2,
        dyanYOutput: parseInt(this.data[6].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[6].dynaZfadeTime),
      });

      let modTimeXValue3 = this.getDynaTimeXRefernce(modifiedOutput);
      draggedData.push({
        dyanXTime: modTimeXValue3,
        dyanYOutput: parseInt(this.data[8].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[8].dynaZfadeTime),
      });

      let dynaModX4 = parseFloat(this.data[10].dyanXTime);
      let modTimeXValue4 = this.getDynaTimeXRefernce(dynaModX4);
      draggedData.push({
        dyanXTime: modTimeXValue4,
        dyanYOutput: parseInt(this.data[10].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[10].dynaZfadeTime),
      });
    }
    if (this.changedPoint === 8) {
      //OUTPUT ONLY - Chnage Scene 2
      let modifiedOutput = parseInt(this.data[8].dyanYOutput);

      let dynaModX = parseFloat(this.data[2].dyanXTime);
      let modTimeXValue = this.getDynaTimeXRefernce(dynaModX);
      draggedData.push({
        dyanXTime: modTimeXValue,
        dyanYOutput: parseInt(this.data[2].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
      });

      let dynaModX1 = parseFloat(this.data[4].dyanXTime);
      let modTimeXValue1 = this.getDynaTimeXRefernce(dynaModX1);
      draggedData.push({
        dyanXTime: modTimeXValue1,
        dyanYOutput: parseInt(this.data[4].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
      });

      let dynaModX2 = parseFloat(this.data[6].dyanXTime);
      let modTimeXValue2 = this.getDynaTimeXRefernce(dynaModX2);
      draggedData.push({
        dyanXTime: modTimeXValue2,
        dyanYOutput: parseInt(this.data[6].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[6].dynaZfadeTime),
      });

      let dynaModX3 = parseFloat(this.data[7].dyanXTime);
      let modTimeXValue3 = this.getDynaTimeXRefernce(dynaModX3);
      draggedData.push({
        dyanXTime: modTimeXValue3,
        dyanYOutput: modifiedOutput,
        dynaZfadeTime: parseInt(this.data[8].dynaZfadeTime),
      });

      let dynaModX4 = parseFloat(this.data[10].dyanXTime);
      let modTimeXValue4 = this.getDynaTimeXRefernce(dynaModX4);
      draggedData.push({
        dyanXTime: modTimeXValue4,
        dyanYOutput: parseInt(this.data[10].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[10].dynaZfadeTime),
      });
    }
    if (this.changedPoint === 9) {
      //TIME ONLY
      let modifiedOutput = parseFloat(this.data[9].dyanXTime);

      let dynaModX = parseFloat(this.data[2].dyanXTime);
      let modTimeXValue = this.getDynaTimeXRefernce(dynaModX);
      draggedData.push({
        dyanXTime: modTimeXValue,
        dyanYOutput: parseInt(this.data[2].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
      });

      let dynaModX1 = parseFloat(this.data[4].dyanXTime);
      let modTimeXValue1 = this.getDynaTimeXRefernce(dynaModX1);
      draggedData.push({
        dyanXTime: modTimeXValue1,
        dyanYOutput: parseInt(this.data[4].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
      });

      let dynaModX2 = parseFloat(this.data[6].dyanXTime);
      let modTimeXValue2 = this.getDynaTimeXRefernce(dynaModX2);
      draggedData.push({
        dyanXTime: modTimeXValue2,
        dyanYOutput: parseInt(this.data[6].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[6].dynaZfadeTime),
      });

      let dynaModX3 = parseFloat(this.data[8].dyanXTime);
      let modTimeXValue3 = this.getDynaTimeXRefernce(dynaModX3);
      draggedData.push({
        dyanXTime: modTimeXValue3,
        dyanYOutput: parseInt(this.data[8].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[8].dynaZfadeTime),
      });

      let modTimeXValue4 = this.getDynaTimeXRefernce(modifiedOutput);
      draggedData.push({
        dyanXTime: modTimeXValue4,
        dyanYOutput: parseInt(this.data[10].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[10].dynaZfadeTime),
      });
    }
    if (this.changedPoint === 10) {
      //OUTPUT ONLY - Chnage Scene 2
      let modifiedOutput = parseInt(this.data[10].dyanYOutput);

      let dynaModX = parseFloat(this.data[2].dyanXTime);
      let modTimeXValue = this.getDynaTimeXRefernce(dynaModX);
      draggedData.push({
        dyanXTime: modTimeXValue,
        dyanYOutput: parseInt(this.data[2].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
      });

      let dynaModX1 = parseFloat(this.data[4].dyanXTime);
      let modTimeXValue1 = this.getDynaTimeXRefernce(dynaModX1);
      draggedData.push({
        dyanXTime: modTimeXValue1,
        dyanYOutput: parseInt(this.data[4].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
      });

      let dynaModX2 = parseFloat(this.data[6].dyanXTime);
      let modTimeXValue2 = this.getDynaTimeXRefernce(dynaModX2);
      draggedData.push({
        dyanXTime: modTimeXValue2,
        dyanYOutput: parseInt(this.data[6].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[6].dynaZfadeTime),
      });

      let dynaModX3 = parseFloat(this.data[8].dyanXTime);
      let modTimeXValue3 = this.getDynaTimeXRefernce(dynaModX3);
      draggedData.push({
        dyanXTime: modTimeXValue3,
        dyanYOutput: parseInt(this.data[8].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[8].dynaZfadeTime),
      });

      let dynaModX4 = parseFloat(this.data[9].dyanXTime);
      let modTimeXValue4 = this.getDynaTimeXRefernce(dynaModX4);
      draggedData.push({
        dyanXTime: modTimeXValue4,
        dyanYOutput: modifiedOutput,
        dynaZfadeTime: parseInt(this.data[10].dynaZfadeTime),
      });
    }
    if (this.changedPoint === 11) {
  
      let modifiedOutput = parseInt(this.data[11].dyanYOutput);

      let dynaModX = parseFloat(this.data[2].dyanXTime);
      let modTimeXValue = this.getDynaTimeXRefernce(dynaModX);
      draggedData.push({
        dyanXTime: modTimeXValue,
        dyanYOutput: parseInt(this.data[2].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
      });

      let dynaModX1 = parseFloat(this.data[4].dyanXTime);
      let modTimeXValue1 = this.getDynaTimeXRefernce(dynaModX1);
      draggedData.push({
        dyanXTime: modTimeXValue1,
        dyanYOutput: parseInt(this.data[4].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
      });

      let dynaModX2 = parseFloat(this.data[6].dyanXTime);
      let modTimeXValue2 = this.getDynaTimeXRefernce(dynaModX2);
      draggedData.push({
        dyanXTime: modTimeXValue2,
        dyanYOutput: parseInt(this.data[6].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[6].dynaZfadeTime),
      });

      let dynaModX3 = parseFloat(this.data[8].dyanXTime);
      let modTimeXValue3 = this.getDynaTimeXRefernce(dynaModX3);
      draggedData.push({
        dyanXTime: modTimeXValue3,
        dyanYOutput: parseInt(this.data[8].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[8].dynaZfadeTime),
      });
      let dynaModX4 = parseFloat(this.data[10].dyanXTime);
      let modTimeXValue4 = this.getDynaTimeXRefernce(dynaModX4);
      draggedData.push({
        dyanXTime: modTimeXValue4,
        dyanYOutput: modifiedOutput,
        dynaZfadeTime: parseInt(this.data[10].dynaZfadeTime),
      });
    }
  }
  updateScenes4(data: any[], draggedData: any) {
    if (this.changedPoint === 0) {
      //OUTPUT
      let modifiedOutput = parseInt(this.data[0].dyanYOutput);

      let dynaModX = parseFloat(this.data[2].dyanXTime);
      let modTimeXValue = this.getDynaTimeXRefernce(dynaModX);
      draggedData.push({
        dyanXTime: modTimeXValue,
        dyanYOutput: parseInt(this.data[2].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
      });

      let dynaModX1 = parseFloat(this.data[4].dyanXTime);
      let modTimeXValue1 = this.getDynaTimeXRefernce(dynaModX1);
      draggedData.push({
        dyanXTime: modTimeXValue1,
        dyanYOutput: parseInt(this.data[4].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
      });

      let dynaModX2 = parseFloat(this.data[6].dyanXTime);
      let modTimeXValue2 = this.getDynaTimeXRefernce(dynaModX2);
      draggedData.push({
        dyanXTime: modTimeXValue2,
        dyanYOutput: parseInt(this.data[6].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[6].dynaZfadeTime),
      });

      let dynaModX3 = parseFloat(this.data[8].dyanXTime);
      let modTimeXValue3 = this.getDynaTimeXRefernce(dynaModX3);
      draggedData.push({
        dyanXTime: modTimeXValue3,
        dyanYOutput: modifiedOutput,
        dynaZfadeTime: parseInt(this.data[8].dynaZfadeTime),
      });
    }
    if (this.changedPoint === 1) {
      //TIME
      let modifiedOutput = parseFloat(this.data[1].dyanXTime);

      let modTimeXValue = this.getDynaTimeXRefernce(modifiedOutput);
      draggedData.push({
        dyanXTime: modTimeXValue,
        dyanYOutput: parseInt(this.data[2].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
      });

      let dynaModX1 = parseFloat(this.data[4].dyanXTime);
      let modTimeXValue1 = this.getDynaTimeXRefernce(dynaModX1);
      draggedData.push({
        dyanXTime: modTimeXValue1,
        dyanYOutput: parseInt(this.data[4].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
      });
      let dynaModX2 = parseFloat(this.data[6].dyanXTime);
      let modTimeXValue2 = this.getDynaTimeXRefernce(dynaModX2);
      draggedData.push({
        dyanXTime: modTimeXValue2,
        dyanYOutput: parseInt(this.data[6].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[6].dynaZfadeTime),
      });

      let dynaModX3 = parseFloat(this.data[8].dyanXTime);
      let modTimeXValue3 = this.getDynaTimeXRefernce(dynaModX3);
      draggedData.push({
        dyanXTime: modTimeXValue3,
        dyanYOutput: parseInt(this.data[8].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[8].dynaZfadeTime),
      });
    }
    if (this.changedPoint === 2) {
      //OUTPUT
      let modifiedOutput = parseInt(this.data[2].dyanYOutput);

      let dynaModX = parseFloat(this.data[1].dyanXTime);
      let modTimeXValue = this.getDynaTimeXRefernce(dynaModX);
      draggedData.push({
        dyanXTime: modTimeXValue,
        dyanYOutput: modifiedOutput,
        dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
      });

      let dynaModX1 = parseFloat(this.data[4].dyanXTime);
      let modTimeXValue1 = this.getDynaTimeXRefernce(dynaModX1);
      draggedData.push({
        dyanXTime: modTimeXValue1,
        dyanYOutput: parseInt(this.data[4].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
      });

      let dynaModX2 = parseFloat(this.data[6].dyanXTime);
      let modTimeXValue2 = this.getDynaTimeXRefernce(dynaModX2);
      draggedData.push({
        dyanXTime: modTimeXValue2,
        dyanYOutput: parseInt(this.data[6].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[6].dynaZfadeTime),
      });

      let dynaModX3 = parseFloat(this.data[8].dyanXTime);
      let modTimeXValue3 = this.getDynaTimeXRefernce(dynaModX3);
      draggedData.push({
        dyanXTime: modTimeXValue3,
        dyanYOutput: parseInt(this.data[8].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[8].dynaZfadeTime),
      });
    }
    if (this.changedPoint === 3) {
      //TIME ONLY
      let modifiedOutput = parseFloat(this.data[3].dyanXTime);

      let dynaModX = parseFloat(this.data[2].dyanXTime);
      let modTimeXValue = this.getDynaTimeXRefernce(dynaModX);
      draggedData.push({
        dyanXTime: modTimeXValue,
        dyanYOutput: parseInt(this.data[2].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
      });

      let modTimeXValue1 = this.getDynaTimeXRefernce(modifiedOutput);
      draggedData.push({
        dyanXTime: modTimeXValue1,
        dyanYOutput: parseInt(this.data[4].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
      });

      let dynaModX2 = parseFloat(this.data[6].dyanXTime);
      let modTimeXValue2 = this.getDynaTimeXRefernce(dynaModX2);
      draggedData.push({
        dyanXTime: modTimeXValue2,
        dyanYOutput: parseInt(this.data[6].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[6].dynaZfadeTime),
      });
      let dynaModX3 = parseFloat(this.data[8].dyanXTime);
      let modTimeXValue3 = this.getDynaTimeXRefernce(dynaModX3);
      draggedData.push({
        dyanXTime: modTimeXValue3,
        dyanYOutput: parseInt(this.data[8].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[8].dynaZfadeTime),
      });
    }

    if (this.changedPoint === 4) {
      //OUTPUT ONLY - Chnage Scene 2
      let modifiedOutput = parseInt(this.data[4].dyanYOutput);

      let dynaModX = parseFloat(this.data[2].dyanXTime);
      let modTimeXValue = this.getDynaTimeXRefernce(dynaModX);
      draggedData.push({
        dyanXTime: modTimeXValue,
        dyanYOutput: parseInt(this.data[2].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
      });

      let dynaModX1 = parseFloat(this.data[3].dyanXTime);
      let modTimeXValue1 = this.getDynaTimeXRefernce(dynaModX1);
      draggedData.push({
        dyanXTime: modTimeXValue1,
        dyanYOutput: modifiedOutput,
        dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
      });

      let dynaModX2 = parseFloat(this.data[6].dyanXTime);
      let modTimeXValue2 = this.getDynaTimeXRefernce(dynaModX2);
      draggedData.push({
        dyanXTime: modTimeXValue2,
        dyanYOutput: parseInt(this.data[6].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[6].dynaZfadeTime),
      });

      let dynaModX3 = parseFloat(this.data[8].dyanXTime);
      let modTimeXValue3 = this.getDynaTimeXRefernce(dynaModX3);
      draggedData.push({
        dyanXTime: modTimeXValue3,
        dyanYOutput: parseInt(this.data[8].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[8].dynaZfadeTime),
      });
    }
    if (this.changedPoint === 5) {
      //TIME ONLY
      let modifiedOutput = parseFloat(this.data[5].dyanXTime);

      let dynaModX = parseFloat(this.data[2].dyanXTime);
      let modTimeXValue = this.getDynaTimeXRefernce(dynaModX);
      draggedData.push({
        dyanXTime: modTimeXValue,
        dyanYOutput: parseInt(this.data[2].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
      });

      let dynaModX1 = parseFloat(this.data[4].dyanXTime);
      let modTimeXValue1 = this.getDynaTimeXRefernce(dynaModX1);
      draggedData.push({
        dyanXTime: modTimeXValue1,
        dyanYOutput: parseInt(this.data[4].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
      });

      let modTimeXValue2 = this.getDynaTimeXRefernce(modifiedOutput);
      draggedData.push({
        dyanXTime: modTimeXValue2,
        dyanYOutput: parseInt(this.data[6].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[6].dynaZfadeTime),
      });

      let dynaModX3 = parseFloat(this.data[8].dyanXTime);
      let modTimeXValue3 = this.getDynaTimeXRefernce(dynaModX3);
      draggedData.push({
        dyanXTime: modTimeXValue3,
        dyanYOutput: parseInt(this.data[8].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[8].dynaZfadeTime),
      });
    }
    if (this.changedPoint === 6) {
      //OUTPUT ONLY - Chnage Scene 2
      let modifiedOutput = parseInt(this.data[6].dyanYOutput);

      let dynaModX = parseFloat(this.data[2].dyanXTime);
      let modTimeXValue = this.getDynaTimeXRefernce(dynaModX);
      draggedData.push({
        dyanXTime: modTimeXValue,
        dyanYOutput: parseInt(this.data[2].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
      });

      let dynaModX1 = parseFloat(this.data[4].dyanXTime);
      let modTimeXValue1 = this.getDynaTimeXRefernce(dynaModX1);
      draggedData.push({
        dyanXTime: modTimeXValue1,
        dyanYOutput: parseInt(this.data[4].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
      });

      let dynaModX2 = parseFloat(this.data[5].dyanXTime);
      let modTimeXValue2 = this.getDynaTimeXRefernce(dynaModX2);
      draggedData.push({
        dyanXTime: modTimeXValue2,
        dyanYOutput: modifiedOutput,
        dynaZfadeTime: parseInt(this.data[6].dynaZfadeTime),
      });

      let dynaModX3 = parseFloat(this.data[8].dyanXTime);
      let modTimeXValue3 = this.getDynaTimeXRefernce(dynaModX3);
      draggedData.push({
        dyanXTime: modTimeXValue3,
        dyanYOutput: parseInt(this.data[8].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[8].dynaZfadeTime),
      });
    }
    if (this.changedPoint === 7) {
      //TIME ONLY
      let modifiedOutput = parseFloat(this.data[7].dyanXTime);

      let dynaModX = parseFloat(this.data[2].dyanXTime);
      let modTimeXValue = this.getDynaTimeXRefernce(dynaModX);
      draggedData.push({
        dyanXTime: modTimeXValue,
        dyanYOutput: parseInt(this.data[2].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
      });

      let dynaModX1 = parseFloat(this.data[4].dyanXTime);
      let modTimeXValue1 = this.getDynaTimeXRefernce(dynaModX1);
      draggedData.push({
        dyanXTime: modTimeXValue1,
        dyanYOutput: parseInt(this.data[4].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
      });

      let dynaModX2 = parseFloat(this.data[6].dyanXTime);
      let modTimeXValue2 = this.getDynaTimeXRefernce(dynaModX2);
      draggedData.push({
        dyanXTime: modTimeXValue2,
        dyanYOutput: parseInt(this.data[6].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[6].dynaZfadeTime),
      });

      let modTimeXValue3 = this.getDynaTimeXRefernce(modifiedOutput);
      draggedData.push({
        dyanXTime: modTimeXValue3,
        dyanYOutput: parseInt(this.data[8].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[8].dynaZfadeTime),
      });
    }
    if (this.changedPoint === 8) {
      //OUTPUT ONLY - Chnage Scene 2
      let modifiedOutput = parseInt(this.data[8].dyanYOutput);

      let dynaModX = parseFloat(this.data[2].dyanXTime);
      let modTimeXValue = this.getDynaTimeXRefernce(dynaModX);
      draggedData.push({
        dyanXTime: modTimeXValue,
        dyanYOutput: parseInt(this.data[2].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
      });

      let dynaModX1 = parseFloat(this.data[4].dyanXTime);
      let modTimeXValue1 = this.getDynaTimeXRefernce(dynaModX1);
      draggedData.push({
        dyanXTime: modTimeXValue1,
        dyanYOutput: parseInt(this.data[4].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
      });

      let dynaModX2 = parseFloat(this.data[6].dyanXTime);
      let modTimeXValue2 = this.getDynaTimeXRefernce(dynaModX2);
      draggedData.push({
        dyanXTime: modTimeXValue2,
        dyanYOutput: parseInt(this.data[6].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[6].dynaZfadeTime),
      });

      let dynaModX3 = parseFloat(this.data[7].dyanXTime);
      let modTimeXValue3 = this.getDynaTimeXRefernce(dynaModX3);
      draggedData.push({
        dyanXTime: modTimeXValue3,
        dyanYOutput: modifiedOutput,
        dynaZfadeTime: parseInt(this.data[8].dynaZfadeTime),
      });
    }
    if (this.changedPoint === 9) {
      //OUTPUT ONLY - Chnage Scene 2
      let modifiedOutput = parseInt(this.data[9].dyanYOutput);

      let dynaModX = parseFloat(this.data[2].dyanXTime);
      let modTimeXValue = this.getDynaTimeXRefernce(dynaModX);
      draggedData.push({
        dyanXTime: modTimeXValue,
        dyanYOutput: parseInt(this.data[2].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
      });

      let dynaModX1 = parseFloat(this.data[4].dyanXTime);
      let modTimeXValue1 = this.getDynaTimeXRefernce(dynaModX1);
      draggedData.push({
        dyanXTime: modTimeXValue1,
        dyanYOutput: parseInt(this.data[4].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
      });

      let dynaModX2 = parseFloat(this.data[5].dyanXTime);
      let modTimeXValue2 = this.getDynaTimeXRefernce(dynaModX2);
      draggedData.push({
        dyanXTime: modTimeXValue2,
        dyanYOutput: parseInt(this.data[6].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[6].dynaZfadeTime),
      });

      let dynaModX3 = parseFloat(this.data[7].dyanXTime);
      let modTimeXValue3 = this.getDynaTimeXRefernce(dynaModX3);
      draggedData.push({
        dyanXTime: modTimeXValue3,
        dyanYOutput: modifiedOutput,
        dynaZfadeTime: parseInt(this.data[8].dynaZfadeTime),
      });
    }
  }
  updateScenes3(data: any[], draggedData: any) {
    if (this.changedPoint === 0) {
      //OUTPUT
      let modifiedOutput = parseInt(this.data[0].dyanYOutput);

      let dynaModX = parseFloat(this.data[2].dyanXTime);
      let modTimeXValue = this.getDynaTimeXRefernce(dynaModX);
      draggedData.push({
        dyanXTime: modTimeXValue,
        dyanYOutput: parseInt(this.data[2].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
      });

      let dynaModX1 = parseFloat(this.data[4].dyanXTime);
      let modTimeXValue1 = this.getDynaTimeXRefernce(dynaModX1);
      draggedData.push({
        dyanXTime: modTimeXValue1,
        dyanYOutput: parseInt(this.data[4].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
      });

      let dynaModX2 = parseFloat(this.data[6].dyanXTime);
      let modTimeXValue2 = this.getDynaTimeXRefernce(dynaModX2);
      draggedData.push({
        dyanXTime: modTimeXValue2,
        dyanYOutput: modifiedOutput,
        dynaZfadeTime: parseInt(this.data[6].dynaZfadeTime),
      });
    }
    if (this.changedPoint === 1) {
      //TIME
      let modifiedOutput = parseFloat(this.data[1].dyanXTime);

      let modTimeXValue = this.getDynaTimeXRefernce(modifiedOutput);
      draggedData.push({
        dyanXTime: modTimeXValue,
        dyanYOutput: parseInt(this.data[2].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
      });

      let dynaModX1 = parseFloat(this.data[4].dyanXTime);
      let modTimeXValue1 = this.getDynaTimeXRefernce(dynaModX1);
      draggedData.push({
        dyanXTime: modTimeXValue1,
        dyanYOutput: parseInt(this.data[4].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
      });
      let dynaModX2 = parseFloat(this.data[6].dyanXTime);
      let modTimeXValue2 = this.getDynaTimeXRefernce(dynaModX2);
      draggedData.push({
        dyanXTime: modTimeXValue2,
        dyanYOutput: parseInt(this.data[6].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[6].dynaZfadeTime),
      });
    }
    if (this.changedPoint === 2) {
      //OUTPUT
      let modifiedOutput = parseInt(this.data[2].dyanYOutput);

      let dynaModX = parseFloat(this.data[1].dyanXTime);
      let modTimeXValue = this.getDynaTimeXRefernce(dynaModX);
      draggedData.push({
        dyanXTime: modTimeXValue,
        dyanYOutput: modifiedOutput,
        dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
      });

      let dynaModX1 = parseFloat(this.data[4].dyanXTime);
      let modTimeXValue1 = this.getDynaTimeXRefernce(dynaModX1);
      draggedData.push({
        dyanXTime: modTimeXValue1,
        dyanYOutput: parseInt(this.data[4].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
      });

      let dynaModX2 = parseFloat(this.data[6].dyanXTime);
      let modTimeXValue2 = this.getDynaTimeXRefernce(dynaModX2);
      draggedData.push({
        dyanXTime: modTimeXValue2,
        dyanYOutput: parseInt(this.data[6].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[6].dynaZfadeTime),
      });
    }
    if (this.changedPoint === 3) {
      //TIME ONLY
      let modifiedOutput = parseFloat(this.data[3].dyanXTime);

      let dynaModX = parseFloat(this.data[2].dyanXTime);
      let modTimeXValue = this.getDynaTimeXRefernce(dynaModX);
      draggedData.push({
        dyanXTime: modTimeXValue,
        dyanYOutput: parseInt(this.data[2].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
      });

      let modTimeXValue1 = this.getDynaTimeXRefernce(modifiedOutput);
      draggedData.push({
        dyanXTime: modTimeXValue1,
        dyanYOutput: parseInt(this.data[4].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
      });

      let dynaModX2 = parseFloat(this.data[6].dyanXTime);
      let modTimeXValue2 = this.getDynaTimeXRefernce(dynaModX2);
      draggedData.push({
        dyanXTime: modTimeXValue2,
        dyanYOutput: parseInt(this.data[6].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[6].dynaZfadeTime),
      });
    }

    if (this.changedPoint === 4) {
      //OUTPUT ONLY - Chnage Scene 2
      let modifiedOutput = parseInt(this.data[4].dyanYOutput);

      let dynaModX = parseFloat(this.data[2].dyanXTime);
      let modTimeXValue = this.getDynaTimeXRefernce(dynaModX);
      draggedData.push({
        dyanXTime: modTimeXValue,
        dyanYOutput: parseInt(this.data[2].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
      });

      let dynaModX1 = parseFloat(this.data[3].dyanXTime);
      let modTimeXValue1 = this.getDynaTimeXRefernce(dynaModX1);
      draggedData.push({
        dyanXTime: modTimeXValue1,
        dyanYOutput: modifiedOutput,
        dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
      });

      let dynaModX2 = parseFloat(this.data[6].dyanXTime);
      let modTimeXValue2 = this.getDynaTimeXRefernce(dynaModX2);
      draggedData.push({
        dyanXTime: modTimeXValue2,
        dyanYOutput: parseInt(this.data[6].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[6].dynaZfadeTime),
      });
    }
    if (this.changedPoint === 5) {
      //TIME ONLY
      let modifiedOutput = parseFloat(this.data[5].dyanXTime);

      let dynaModX = parseFloat(this.data[2].dyanXTime);
      let modTimeXValue = this.getDynaTimeXRefernce(dynaModX);
      draggedData.push({
        dyanXTime: modTimeXValue,
        dyanYOutput: parseInt(this.data[2].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
      });

      let dynaModX1 = parseFloat(this.data[4].dyanXTime);
      let modTimeXValue1 = this.getDynaTimeXRefernce(dynaModX1);
      draggedData.push({
        dyanXTime: modTimeXValue1,
        dyanYOutput: parseInt(this.data[4].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
      });

      let modTimeXValue2 = this.getDynaTimeXRefernce(modifiedOutput);
      draggedData.push({
        dyanXTime: modTimeXValue2,
        dyanYOutput: parseInt(this.data[6].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[6].dynaZfadeTime),
      });
    }
    if (this.changedPoint === 6) {
      //OUTPUT ONLY - Chnage Scene 2
      let modifiedOutput = parseInt(this.data[6].dyanYOutput);

      let dynaModX = parseFloat(this.data[2].dyanXTime);
      let modTimeXValue = this.getDynaTimeXRefernce(dynaModX);
      draggedData.push({
        dyanXTime: modTimeXValue,
        dyanYOutput: parseInt(this.data[2].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
      });

      let dynaModX1 = parseFloat(this.data[4].dyanXTime);
      let modTimeXValue1 = this.getDynaTimeXRefernce(dynaModX1);
      draggedData.push({
        dyanXTime: modTimeXValue1,
        dyanYOutput: parseInt(this.data[4].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
      });

      let dynaModX2 = parseFloat(this.data[5].dyanXTime);
      let modTimeXValue2 = this.getDynaTimeXRefernce(dynaModX2);
      draggedData.push({
        dyanXTime: modTimeXValue2,
        dyanYOutput: modifiedOutput,
        dynaZfadeTime: parseInt(this.data[6].dynaZfadeTime),
      });
    }
    if (this.changedPoint === 7) {
      //OUTPUT ONLY - Chnage Scene 2
      let modifiedOutput = parseInt(this.data[7].dyanYOutput);

      let dynaModX = parseFloat(this.data[2].dyanXTime);
      let modTimeXValue = this.getDynaTimeXRefernce(dynaModX);
      draggedData.push({
        dyanXTime: modTimeXValue,
        dyanYOutput: parseInt(this.data[2].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
      });

      let dynaModX1 = parseFloat(this.data[4].dyanXTime);
      let modTimeXValue1 = this.getDynaTimeXRefernce(dynaModX1);
      draggedData.push({
        dyanXTime: modTimeXValue1,
        dyanYOutput: parseInt(this.data[4].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
      });

      let dynaModX2 = parseFloat(this.data[5].dyanXTime);
      let modTimeXValue2 = this.getDynaTimeXRefernce(dynaModX2);
      draggedData.push({
        dyanXTime: modTimeXValue2,
        dyanYOutput: modifiedOutput,
        dynaZfadeTime: parseInt(this.data[6].dynaZfadeTime),
      });
    }
  }
  updateScenes2(data: any[], draggedData: any) {
    if (this.changedPoint === 0) {
      let modifiedOutput = parseInt(this.data[0].dyanYOutput);

      let dynaModX = parseFloat(this.data[2].dyanXTime);
      let modTimeXValue = this.getDynaTimeXRefernce(dynaModX);
      draggedData.push({
        dyanXTime: modTimeXValue,
        dyanYOutput: parseInt(this.data[2].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
      });

      let dynaModX1 = parseFloat(this.data[4].dyanXTime);
      let modTimeXValue1 = this.getDynaTimeXRefernce(dynaModX1);
      draggedData.push({
        dyanXTime: modTimeXValue1,
        dyanYOutput: modifiedOutput,
        dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
      });
    }
    if (this.changedPoint === 1) {
      let modifiedOutput = parseInt(this.data[1].dyanYOutput);

      let dynaModX = parseFloat(this.data[2].dyanXTime);
      let modTimeXValue = this.getDynaTimeXRefernce(dynaModX);
      draggedData.push({
        dyanXTime: modTimeXValue,
        dyanYOutput: parseInt(this.data[2].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
      });

      let dynaModX1 = parseFloat(this.data[4].dyanXTime);
      let modTimeXValue1 = this.getDynaTimeXRefernce(dynaModX1);
      draggedData.push({
        dyanXTime: modTimeXValue1,
        dyanYOutput: modifiedOutput,
        dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
      });
    }
    if (this.changedPoint === 2) {
      let modifiedtime = parseFloat(this.data[2].dyanXTime);

      let modTimeXValue = this.getDynaTimeXRefernce(modifiedtime);
      draggedData.push({
        dyanXTime: modTimeXValue,
        dyanYOutput: parseInt(this.data[3].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
      });

      let dynaModX1 = parseFloat(this.data[4].dyanXTime);
      let modTimeXValue1 = this.getDynaTimeXRefernce(dynaModX1);
      draggedData.push({
        dyanXTime: modTimeXValue1,
        dyanYOutput: parseInt(this.data[4].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
      });
    }
    if (this.changedPoint === 3) {
      //OUTPUT ONLY
      let modifiedtime = parseFloat(this.data[2].dyanXTime);

      let modTimeXValue = this.getDynaTimeXRefernce(modifiedtime);
      draggedData.push({
        dyanXTime: modTimeXValue,
        dyanYOutput: parseInt(this.data[3].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
      });

      let dynaModX1 = parseFloat(this.data[4].dyanXTime);
      let modTimeXValue1 = this.getDynaTimeXRefernce(dynaModX1);
      draggedData.push({
        dyanXTime: modTimeXValue1,
        dyanYOutput: parseInt(this.data[4].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
      });
    }

    if (this.changedPoint === 4) {
      //TIME ONLY - Chnage Scene 2
      let modifiedtime = parseFloat(this.data[2].dyanXTime);

      let modTimeXValue = this.getDynaTimeXRefernce(modifiedtime);
      draggedData.push({
        dyanXTime: modTimeXValue,
        dyanYOutput: parseInt(this.data[2].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
      });

      let dynaModX1 = parseFloat(this.data[4].dyanXTime);
      let modTimeXValue1 = this.getDynaTimeXRefernce(dynaModX1);
      draggedData.push({
        dyanXTime: modTimeXValue1,
        dyanYOutput: parseInt(this.data[5].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[4].dynaZfadeTime),
      });
    }
    if (this.changedPoint === 5) {
      //OUTPUT ONLY - Chnage Scene 2

      let modTimeXValue = this.getDynaTimeXRefernce(
        parseFloat(this.data[2].dyanXTime)
      );
      draggedData.push({
        dyanXTime: modTimeXValue,
        dyanYOutput: parseInt(this.data[2].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[2].dynaZfadeTime),
      });

      let dynaModX1 = parseFloat(this.data[3].dyanXTime);
      let modTimeXValue1 = this.getDynaTimeXRefernce(dynaModX1);
      draggedData.push({
        dyanXTime: modTimeXValue1,
        dyanYOutput: parseInt(this.data[5].dyanYOutput),
        dynaZfadeTime: parseInt(this.data[3].dynaZfadeTime),
      });
    }
  }
  getDynaTimeXRefernce(dynaModX: number) {
    if (dynaModX >= 0 && dynaModX <= 12) {
      dynaModX = dynaModX + 12;
    } else if (dynaModX >= 12 && dynaModX <= 24) {
      dynaModX = dynaModX - 12;
    }
    let hh = dynaModX.toString().split(".")[0];
    let mm = "00";

    try {
      mm = dynaModX.toString().split(".")[1].substring(0, 2);
      if (mm.length === 1) {
        mm = mm.concat("0");
      }
    } catch (error) {}
    if (Number(mm) > 59) mm = "59";
    return hh + ":" + mm;
  }

  addDeratingLine() {
    var midNightShiftPoint = 0;
    var x1Value = 12;

    this.apiService.getDynaMidPointShiftValue.subscribe((response) => {
      midNightShiftPoint = response;
    });
    if (midNightShiftPoint > 0) {
      midNightShiftPoint = midNightShiftPoint / 100;
      x1Value = x1Value + midNightShiftPoint * 1.6;
    } else if (midNightShiftPoint < 0) {
      midNightShiftPoint = midNightShiftPoint / 100;
      x1Value = x1Value + midNightShiftPoint * 1.6;
    } else {
      midNightShiftPoint = midNightShiftPoint / 100;
      x1Value = x1Value + midNightShiftPoint;
    }
    if (!isNullOrUndefined(this.focus)) {
      this.focus
        .append("line")
        .style("stroke", "blue")
        .style("stroke-dasharray", "5, 5")
        .text("Mid Night Shift")
        .attr("x1", this.x(x1Value))
        .attr("y1", this.y(100))
        .attr("x2", this.x(x1Value))
        .attr("y2", this.y(0));
    }

    // .attr("x1", this.x(9)).attr("y1", this.y(100))
    // .attr("x2", this.x(9)).attr("y2", this.y(0));
    if (!isNullOrUndefined(this.focus)) {
      this.focus
        .append("text")
        .attr("x", this.x(9))
        .attr("y", this.y(100))
        // .attr("transform", "rotate(-90," + this.x(-this.deratingLabelYaxis) + "," + this.y(this.deRatingdyanYOutput) + ")")
        .attr("text-anchor", "start")
        .style("font", "10px Signify-Relative-Bold")
        .style("color", "lightgrey")
        .style("text-anchor", "middle")
        .text("");
    }
  }

  addTMAXShutDown() {
    this.focus
      .append("line")
      .style("stroke", "grey")
      .style("stroke-dasharray", "5, 5")
      .text("T_MAX_START")
      .attr("x1", this.x(this.TmaxShutDown))
      .attr("y1", this.y(105))
      .attr("x2", this.x(this.TmaxShutDown))
      .attr("y2", this.y(0));

    this.focus
      .append("text")
      .attr("x", this.x(this.TmaxShutDown))
      .attr("y", this.y(-7))
      .attr("text-anchor", "end")
      .style("font", "10px Signify-Relative-Bold")
      .style("color", "lightgrey")
      .style("text-anchor", "middle")
      .text("T_Max_Shutdown");
  }

  addTMAXStart() {
    this.focus
      .append("line")
      .style("stroke", "grey")
      .style("stroke-dasharray", "5, 5")
      .attr("x1", this.x(this.Tmaxstart))
      .attr("y1", this.y(105))
      .attr("x2", this.x(this.Tmaxstart))
      .attr("y2", this.y(0));
    this.focus
      .append("text")
      .attr("x", this.x(this.Tmaxstart))
      .attr("y", this.y(-7))
      .attr("text-anchor", "end")
      .style("font", "10px Signify-Relative-Bold")
      .style("color", "lightgrey")
      .style("text-anchor", "middle")
      .text("T_Max_Start");
  }

  addTMAXEnd() {
    this.focus
      .append("line")
      .style("stroke", "grey")
      .style("stroke-dasharray", "5, 5")
      .text("T_MAX_END")
      .attr("x1", this.x(this.TmaxEnd))
      .attr("y1", this.y(105))
      .attr("x2", this.x(this.TmaxEnd))
      .attr("y2", this.y(0));
    this.focus
      .append("text")
      .attr("x", this.x(this.TmaxEnd))
      .attr("y", this.y(-12))
      .attr("text-anchor", "end")
      .style("font", "10px Signify-Relative-Bold")
      .style("color", "lightgrey")
      .style("text-anchor", "middle")
      .text("T_Max_End");
  }
}
