import { Injectable, Type } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, from, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorComponent } from 'src/app/error/error.component';

@Injectable({ providedIn: 'root' })
export class ModalService {

  constructor(private ngbModal: NgbModal) { }

  confirm(message = 'Really?', title = 'Confirm', showCancelButton = false): Observable<boolean> {
    const modal = this.ngbModal.open(ErrorComponent, {
      keyboard: false,
      centered: true,
      windowClass: "error-style, error-style1",
      backdrop: 'static'
    });

    modal.componentInstance.errorDescription = message;
    modal.componentInstance.errorName = title;
    modal.componentInstance.showCancelButton = showCancelButton;


    return from(modal.result);
  }


}
