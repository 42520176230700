import { Component, OnInit } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ReadDeviceMultiDriverNextComponent } from "../read-device-multi-driver-next/read-device-multi-driver-next.component";
import { ApiService } from "../core-module/services/api.service";
@Component({
  selector: "app-configure-device-modal-input-error-positive",
  templateUrl: "./configure-device-modal-input-error-positive.component.html",
  styleUrls: ["./configure-device-modal-input-error-positive.component.scss"],
})
export class ConfigureDeviceModalInputErrorPositiveComponent implements OnInit {
  pathVal: string;
  constructor(
    private apiService: ApiService,
    public activemodal: NgbActiveModal,
    private modalService: NgbModal
  ) {}

  ngOnInit() {
    this.pathVal = this.apiService.getImageUrl(window.location.hostname);
    setTimeout(() => {
      const modalRef = this.modalService.open(
        ReadDeviceMultiDriverNextComponent,
        {
          windowClass: "select-feature-modal",
          backdropClass: "customBackdrop",
        }
      );
    }, 5000);
  }
}
