import { Component, OnInit, OnDestroy, Input } from "@angular/core";
import { ApiService } from "src/app/core-module/services/api.service";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { takeUntil } from "rxjs/operators";
import { Subject, forkJoin, Observable, Subscription, BehaviorSubject } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { HttpHeaders } from "@angular/common/http";
import { Router } from "@angular/router";
import { ErrorComponent } from "src/app/error/error.component";
import { ReaderSelectionPopupComponent } from '../shared-module/components/reader-selection-popup/reader-selection-popup.component';
import { ConfigureDeviceModalInputComponent } from '../configure-device-modal-input/configure-device-modal-input.component';
@Component({
  selector: "app-read-device-multi-driver",
  templateUrl: "./read-device-multi-driver.component.html",
  styleUrls: ["./read-device-multi-driver.component.scss"],
})
export class ReadDeviceMultiDriverComponent implements OnInit, OnDestroy {
  @Input() public newPayLoadValues;
  @Input() public isRetry:boolean=false;
  readDevice: any;
  deviceNfcId: any;
  readingStatus;
  loading: boolean;
  readerData;
  hideStatus: boolean;
  customerLoader: boolean = false;
  isProductionInfoAvailable: boolean = false;
  customerLoaderAfter: boolean = false;
  noReader: boolean;
  specialErrorNoDatabase: boolean = false;
  baseLinkUrl = "http://localhost:2020";
  readerStartUrl = "/server/v1/status";
  readerListUrl = "/peripheral/v1/types";
  readreDataUrl = "/device/v1/NFC/LCN9600/dummy/memorybank/114";
  readerName: any;
  listReader: any = [];
  errorMessage: any;
  readerLength: any = [];
  deviceInfoUrl = "/peripheral/v1/nfc/{peripheralId}/devices?refresh=true";
  memoryBankUrl =
    "/device/v1/nfc/{peripheralId}/{deviceNfcId}/memorybanks/metadata";
  envScehmaLIstApi: any;
  corruptBitUrl =
    "/device/v1/nfc/{peripheralId}/{deviceNfcId}/memorybanks/corrupt";
  writingInDeviceURL =
    "/device/v1/nfc/{peripheralId}/{deviceNfcId}/memorybanks";
  listOfBank: any;
  unsubscribe = new Subject<any>();
  allFeatures: any;
  feature12NcName: any = [];
  readerBaseUrl: any[];
  readerDeviceData: any;
  featureWithId: any;
  productionDate: any;
  productionBatchId: any;
  sku: any;
  nonCorrupt = [];
  corrupt = [];
  payLoadUrl = "/device/v1/nfc/{peripheralId}/{deviceNfcId}/memorybanks";
  importPayLoadUrl =
    "feature/api/v1/configuration/{configurationId}/feature-payload";
  importPayLoadUrlNE =
    "feature/api/v1/configuration/{configurationId}/feature-payload/not-encrypted";
  startConfiguration;
  specificFeaturePayLoad;
  url: any;
  deviceInformationObj: {};
  bankIdByIndex: any;
  newDeviceObj;
  envHostName;
  apiListResponse: any[] = [];
  mem: any = [];
  softwareVersionSchema: any;
  luminSoftwareVersionSchema: any;
  everySchemaofDevice: any;
  alominBankId: boolean;
  isALoMinObj: boolean;
  isWritingRetryCheck: boolean = false;
  payLoadResp: any;
  subscription: Subscription;
  removeSuccessText: boolean;
  writingDevicesContainer: boolean;
  writingURL: any;
  removeWritingHeader: boolean = true;
  writingURLViaPlugin =
    "feature/api/v1/configuration/{configurationId}/feature-payload";
  writingURLViaPluginNE =
    "feature/api/v1/configuration/{configurationId}/feature-payload/not-encrypted";
  isAocMinObj: boolean;
  isLumMinSixObj: boolean;
  specificMemoryBanksList = [
    {
      memoryBankId: "114-0", //AOC
    },
    {
      memoryBankId: "73-0", //ALO
    },
    {
      memoryBankId: "37-0", //ALO MIN
    },
    {
      memoryBankId: "14-0", //CLO//CLOLITE
    },
    {
      memoryBankId: "149-0", //AOC PWM
    },
    {
      memoryBankId: "29-0", //AST
    },
    {
      memoryBankId: "1-0", //LUMIN
    },
    {
      memoryBankId: "35-0", //SRPSU
    },
    {
      memoryBankId: "19-0", //ZTV
    },
    {
      memoryBankId: "52-0",
    },
    {
      memoryBankId: "13-0", //LUMIN
    },
    {
      memoryBankId: "15-0", //LINESWITCH
    },
    {
      memoryBankId: "18-0", //AMPDIM
    },
    {
      memoryBankId: "75-0", //DC EMERGENCY
    },
    {
      memoryBankId: "74-0", //DTL
    },
    {
      memoryBankId: "113-0", //DTL
    },
    {
      memoryBankId: "36-0", //Coded Mains
    },
    {
      memoryBankId: "207-0", //Dali 253M
    },
  ];
  regions: string[];
  searchName: any[];
  apiList: any = [];
  deviceInfo: any;
  fullDeviceList = "search/api/v1/search/v1/devices";
  envDeviceListURL: string;
  writingDevice: any;
  corruptBits: any;
  isNormalFlow: any;
  dtlSoftwareVersionSchema: any;
  startConfigurationURL: any[];
  allMemoryBanks: any = { memoryBankId: "All" };
  envAloschemaDetails: any;
  configurationData: any;
  latestDeviceVersion: any = [];
  latestDvValue: any;
  retryButton: boolean = false;
  retryClicked: boolean = false;
  mtpSoftwareVersionSchema: any;
  dimminginterfaceSoftwareVersionSchema: any;
  cloSoftwareVersionSchema: any;
  aloSoftwareVersionSchema: any;
  aocSoftwareVersionSchema: any;
  touchdimSoftwareVersionSchema: any;
  corridorSoftwareVersionSchema: any;
  ztvSoftwareVersionSchema: any;
  ampdimSoftwareVersionSchema: any;
  astSoftwareVersionSchema: any;
  cloliteSoftwareVersionSchema: any;
  dynadimSoftwareVersionSchema: any;
  dcemergencySoftwareVersionSchema: any;
  lineswitchSoftwareVersionSchema: any;
  codedmainsSoftwareVersionSchema: any;
  continueRead: boolean = false;
  errorCodedDC = [
    "206-Error occurred due to unsupported request! Please try again. If the issue persists contact Signify",
    "304-Error occurred due to unsupported request! Please try again. If the issue persists contact Signify",
    "400-Error occurred due to unsupported request! Please try again. If the issue persists contact Signify",
    "400.1-Error occurred due to unsupported request! Please try again. If the issue persists contact Signify",
    "400.2-Error occurred due to unsupported request! Please try again. If the issue persists contact Signify",
    "400.3-Error occurred due to unsupported request! Please try again. If the issue persists contact Signify",
    "400.4-Error occurred due to unsupported request! Please try again. If the issue persists contact Signify",
    "400.5-Error occurred due to unsupported request! Please try again. If the issue persists contact Signify",
    "400.6-Error occurred due to unsupported request! Please try again. If the issue persists contact Signify",
    "400.7-Error occurred due to unsupported request! Please try again. If the issue persists contact Signify",
    "400.8-Error occurred due to unsupported request! Please try again. If the issue persists contact Signify",
    "400.9-Device not found! Please check the device and try again",
    "404.1-Reader not connected! Please check the reader and try again",
    "404.2-Device not found! Please check the device and try again",
    "405-Error occurred due to unsupported request! Please try again. If the issue persists contact Signify",
    "412.1-Reader not connected! Please check the reader and try again",
    "412.2-Device not found! Please check the device and try again",
    "500-Error occurred due to unsupported request! Please try again. If the issue persists contact Signify",
    "1203_1-Error occurred due to unsupported request! Please try again. If the issue persists contact Signify",
  ];
  showExtraPopUp: boolean = true;
  showDeviceIDReadDC: boolean = false;
  deviceCNtMatched: boolean = false;
  configConnectedDevices: boolean=false;
  showNextSuccessWriteBtn: boolean = false;
  userBoxSizeValue: any=24;
  incorrectBoxSize: boolean = false;
  actualDevicesCnt: number = 0;
  owpProtectedMultiDevices: boolean=false;
  deviceNotMatching: boolean=false;
  deviceNfcIdsList:string[]=[];
  pathVal:string;
  // readerUrl: any;
  // isTestCase: boolean=true;
  public initialCount: any = 0;
  public outOfCount = 24;
  getShowNextMultiSuccessBtnSub: Subscription;
  owpProtectedDevices: number = 0;
  totalMatchedDevices: number = 0;
  isError: boolean = false;
  isDeviceConnected: boolean = true;
  corruptedDevices: boolean = false;
  corruptedDevicesCount: number = 0;
  isShowCancelBtn: boolean = true;

  constructor(
    private service: ApiService,
    public activemodal: NgbActiveModal,
    private modalService: NgbModal,
    private http: HttpClient,
    public route: Router
  ) {}

  ngOnInit() {
    this.isShowCancelBtn = false;
    this.pathVal = this.service.getImageUrl(window.location.hostname);
    this.showNextSuccessWriteBtn= false;
    /* this.service.getGeneratePlugInPutData.subscribe(pluginData=>{
      if(pluginData!="")
      this.mem = pluginData;
    }) */
    let multiDeviceConfig = localStorage.getItem("multidevice");
    
    let pluginout = JSON.parse(localStorage.getItem("pluginOut"));
    if (pluginout != "") {
      this.mem = pluginout;
    }
    this.service.getconfigData.subscribe((data) => {
      this.readDevice = data;
    });

    this.getShowNextMultiSuccessBtnSub = this.service.getShowNextMultiSuccessBtn.subscribe((data)=>{
      if(data){
        this.showNextSuccessWriteBtn = data;
        this.isShowCancelBtn = true;
        if(this.showNextSuccessWriteBtn===true){
          this.customerLoaderAfter=false;
        }
      }
    })

    this.service.getUserBoxSizeValue.subscribe((data)=>{
      if(data){
        this.userBoxSizeValue = Number(data);
      }
    })
    /* this.service.closeModal.subscribe((data) => {
      if (data) {
        this.activemodal.close();
      }
    }); */
  
    this.service.getReaderInfo.subscribe((data) => {
      if (data) {
        this.readerData = data;
        this.hideStatus = true;
        this.readerName = data;

        // this.getDeviceVersion();
        setTimeout(() => {
          this.dataInreader();
        }, 3000);
        /*  // setTimeout(() => {
         //   this.getListOfFeature();
         // }, 3000); */
      }
    });
    if (this.readDevice === "readDevice") {
      this.writingDevice = JSON.parse(localStorage.getItem("isWriting"));
      this.customerLoaderAfter = true;
      if (this.writingDevice) {
        this.readerName = JSON.parse(localStorage.getItem("readerInfo"));
        this.hideStatus = true;
        this.removeSuccessText = false;
        this.removeWritingHeader = true;
        this.writingDevicesContainer = true;
        this.isWritingRetryCheck = true;
        this.newReaderInfo();
      }
    }

    setTimeout(() => {
      this.service.getEnvValue();
      this.service.getEnvData
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((url) => {
          if (url.length > 0) {
            this.url = url;
          }
        });
    }, 1000);
  }
  showDCError(errorMessage: any, errorDescription: any, show: boolean) {
    if (show) {
      const modalRef = this.modalService.open(ErrorComponent, {
        keyboard: false,
        centered: true,
        windowClass: "error-style, error-style1",
      });
      modalRef.componentInstance.errorName = errorMessage;
      modalRef.componentInstance.errorDescription = errorDescription;
    }
  }

  newReaderInfo() {
    this.customerLoaderAfter = true;
    this.service.getBaseUrl();
    this.service.getReadData
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((url) => {
        if (url.length > 0) {
          this.readerBaseUrl = url;
          this.unsubscribe.next();
          localStorage.setItem("reading", JSON.stringify(true));
          this.getReaderList().subscribe(
            (data) => {
              this.readerData = data;
              localStorage.setItem(
                "readerInfo",
                JSON.stringify(this.readerData)
              );
              if (this.readerData) {
                this.hideStatus = true;
                if (this.readerData.length === 1) {
                  this.readerName = this.readerData[0];
                  this.dataInreader();
                } else {
                  this.listReader = this.readerData;
                  const modalRef = this.modalService.open(
                    ReaderSelectionPopupComponent,
                    {
                      backdrop: "static",
                      keyboard: false,
                      windowClass: "select-device-modal-comp",
                      centered: true,
                      size: "lg",
                    }
                  );
                  modalRef.componentInstance.readerName = this.listReader;
                }
              }
            },
            (error) => {
              this.isShowCancelBtn = true;
              this.removeWritingHeader = false;
              this.writingDevicesContainer = false;
              this.customerLoaderAfter = true;
              this.noReader = true;
              this.retryButton = true;
              if (error.status) {
                this.errorCodedDC.forEach((errorCode) => {
                  if (errorCode.includes(error.status)) {
                    if (error.status === errorCode.split("-")[0]) {
                      this.errorMessage = errorCode.split("-")[1];
                      this.showDCError(
                        error.message,
                        this.errorMessage,
                        this.showExtraPopUp
                      );
                    }
                  }
                });
              } else {
                if (error["Message"]) {
                  this.errorMessage = error["Message"];
                } else {
                  this.errorMessage = "Please check the connection";
                  this.retryButton = true;
                }
              }
              this.hideStatus = true;
              this.customerLoaderAfter = false;
              this.customerLoader = false;
            }
          );
        }
      });
  }

  dataInreader() {
    this.customerLoaderAfter = true;
    this.service.getBaseUrl();
    this.service.getReadData
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((url) => {
        this.envHostName = url;
        if (url.length > 0) {
          this.readerBaseUrl = url;
          this.unsubscribe.next();
          this.getDeviceInfo().subscribe(
            (data) => {
              this.owpProtectedMultiDevices = false;
              this.readerDeviceData = data[0];
              this.incorrectBoxSize = false;
              let arrayData:any[]=[];
                  arrayData =Array(data);
              this.actualDevicesCnt = arrayData[0].length;    
              this.deviceNfcIdsList=arrayData[0].map((item)=> item['deviceNfcId']);
              this.outOfCount=this.deviceNfcIdsList.length;
              this.owpProtectedDevices = arrayData[0].filter(item => item.deviceOwpStatus === 'Enabled').length;
              this.totalMatchedDevices = arrayData[0].filter(item => item['deviceMetadata']['device12nc'] === arrayData[0][0]['deviceMetadata']['device12nc']).length;
             if(arrayData[0].length!==Number(this.userBoxSizeValue)){
             this.incorrectBoxSize= true; 
             this.customerLoaderAfter = false;
             this.deviceCNtMatched = false;
             }
             else if (arrayData[0].length !== this.totalMatchedDevices) {
               this.customerLoaderAfter = false;
               this.loading = false;
               this.customerLoader = false;
               this.deviceNotMatching = true;
             }
             else if (this.owpProtectedDevices > 0) {
                /* this.readerData = false;
                this.removeWritingHeader = false;
                this.writingDevicesContainer = false;
                this.noReader = true;
                this.customerLoader = false;
                this.loading = false;
                this.customerLoaderAfter = false;
                this.errorMessage =
                  "This device is OWP protected and therefore not accessible. Please select another device!"; */
                  this.customerLoaderAfter = false;
                this.owpProtectedMultiDevices = true;
                this.loading = false;
                this.customerLoader = false;
              } else if (data) {
                localStorage.setItem(
                  "setReaderDEviceInfo",
                  JSON.stringify(this.readerDeviceData)
                );
                let corruptedNfcSub: BehaviorSubject<string> = new BehaviorSubject(this.deviceNfcIdsList[0]);
                let corruptedNfcSubObs$ = corruptedNfcSub.asObservable();
                let count = 0;
                localStorage.setItem("reading", JSON.stringify(false));
                this.service.getBaseUrl();
                this.service.getReadData
                  .pipe(takeUntil(this.unsubscribe))
                  .subscribe((url) => {
                    if (url.length > 0) {
                      this.unsubscribe.next();
                      corruptedNfcSubObs$.subscribe((nfcId) => {
                        let compatibleFeat = this.checkCompatibleFeat();
                        let corruptBit = this.checkCorruptBitByNfcId(nfcId);
        
                        forkJoin([compatibleFeat, corruptBit]).subscribe(
                          (results) => {
                            count++;
                            this.corruptBits = results[1];
                            //this.featureWithId = results[0];
                            this.featureWithId = JSON.parse(
                              atob(results[0]["memoryBank"])
                            );
        
                            localStorage.setItem(
                              "compatibleFeatureID",
                              JSON.stringify(this.featureWithId)
                            );
                            let isCorrupted = false;
                            this.corruptBits["memoryBanks"].forEach((element) => {
                              for (let i = 0; i < this.featureWithId.length; i++) {
                                if (
                                  element["memoryBankId"] ===
                                  this.featureWithId[i]["memoryBankId"]
                                ) {
                                  isCorrupted = true;
                                }
                              }
                            });
                            if (isCorrupted) {
                              this.corruptedDevicesCount++;
                            }
                            if (count == this.deviceNfcIdsList.length) {
                              corruptedNfcSub.unsubscribe();
                              this.isShowCancelBtn = true;
                              if (this.corruptedDevicesCount > 0) {
                                this.corruptedDevices = true;
                                
                              } else {
                                this.corruptedDevices = false;
                                this.continueConfig();
                              }
                              this.customerLoaderAfter = false;
                              this.loading = false;
                              this.customerLoader = false;
                            } else {
                              corruptedNfcSub.next(this.deviceNfcIdsList[count])

                            } 

                          },
                          (err) => {
                            this.isShowCancelBtn = true;
                            count++;
                            if (count == this.deviceNfcIdsList.length) {
                              corruptedNfcSub.unsubscribe();
                              if (this.corruptedDevicesCount > 0) {
                                this.corruptedDevices = true;
                              } else {
                                this.corruptedDevices = false;
                              }
                            } else {
                              corruptedNfcSub.next(this.deviceNfcIdsList[count])

                            } 
                            this.feature12NcName = [];
                            this.service.sendMultiFeatures(this.feature12NcName);
                            this.readerData = false;
                            this.noReader = true;
                            this.customerLoader = false;
                            this.loading = false;
                            this.customerLoaderAfter = false;
                            let myObj = err;
                            this.errorMessage = myObj.Message;
                            if (err.status) {
                              this.errorCodedDC.forEach((errorCode) => {
                                if (errorCode.includes(err.status)) {
                                  if (err.status === errorCode.split("-")[0]) {
                                    this.errorMessage = errorCode.split("-")[1];
                                    this.showDCError(
                                      err.message,
                                      this.errorMessage,
                                      this.showExtraPopUp
                                    );
                                    this.retryButton = true;
                                  }
                                }
                              });
                            } else {
                              if (
                                this.errorMessage ===
                                "An error occurred while retrieving the corrupt Memory Banks! \r\n Please check the Device or Peripheral ID and try again!"
                              ) {
                                this.errorMessage =
                                  "An error occured while retrieving the corrupt data. Please check the Device or Peripheral ID and try again!";
                                this.retryButton = true;
                              }
                            }
                          }
                        );
                       }); 
                    } 
                    })      

            
              }
            },
            (err) => {

              if(err.description==="Expected devices not found"){

                let tempUpdate = JSON.parse(
                  localStorage.getItem("configurationData")
                );

                this.readerDeviceData = {deviceMetadata : tempUpdate}

                let arrayData:any[]=[];
                arrayData =Array(err);
                
                let actualDevices = arrayData[0].deviceNfcIds.length
                if(actualDevices!==Number(this.userBoxSizeValue)){
                  this.incorrectBoxSize= true; 
                  this.customerLoaderAfter = false;
                  this.deviceCNtMatched = false;
                  }

                  this.outOfCount = actualDevices
                  this.actualDevicesCnt = actualDevices
              }
              
         
         else{
              this.retryButton = true;
              this.readerData = false;
              this.removeWritingHeader = false;
              this.writingDevicesContainer = false;
              this.noReader = true;
              this.customerLoader = false;
              this.loading = false;
              this.customerLoaderAfter = false;
              let myObj = err;
              this.errorMessage = myObj.Message;
              if (err.status) {
                this.errorCodedDC.forEach((errorCode) => {
                  if (errorCode.includes(err.status)) {
                    if (err.status === errorCode.split("-")[0]) {
                      this.errorMessage = errorCode.split("-")[1];
                      this.showDCError(
                        err.message,
                        this.errorMessage,
                        this.showExtraPopUp
                      );
                    }
                  }
                });
              }
            }
            this.isShowCancelBtn = true;
            }
          );
        }
      });
  }

  continueConfig(){
    this.removeSuccessText = true;
    this.hideStatus = true;
    localStorage.setItem("reading", JSON.stringify(false));
    let configDeviceInfo = JSON.parse(
      localStorage.getItem("configurationData")
    );
    this.isNormalFlow = JSON.parse(
      localStorage.getItem("normalFlow")
    );
    let checkDeviceSoftware;
    if (localStorage.getItem("comparefirmwarenoLatest")) {
      checkDeviceSoftware = JSON.parse(
        localStorage.getItem("comparefirmwarenoLatest")
      );
      if (checkDeviceSoftware.length > 3) {
        checkDeviceSoftware = checkDeviceSoftware.slice(0, -2);
      }
    }
    if (
      configDeviceInfo &&
      configDeviceInfo["deviceName"] ===
        this.readerDeviceData.deviceMetadata["deviceName"] &&
      configDeviceInfo["device12nc"] ===
        this.readerDeviceData.deviceMetadata["device12nc"] &&
      checkDeviceSoftware ===
        this.readerDeviceData.deviceMetadata["deviceSwVersion"]
    ) {
      this.customerLoader = false;
      this.hideStatus = true;
      this.removeSuccessText = false;
      this.removeWritingHeader = true;
      this.writingDevicesContainer = true;
      this.deviceCNtMatched = true;
      this.customerLoaderAfter = false;
      this.service.getEnvValue();
      this.service.getEnvData
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((url) => {
          if (url.length > 0) {
            this.url = url;

            
            /*  if(this.mem!=""){
        this.writingDeviceWithPC(this.mem);
      }
      else{ */
           // this.generatePluginOutput();
            /*  } */
          }
        });
    } else if (this.isNormalFlow) {
     /*  this.readerData = false;
      this.noReader = true;
      this.removeWritingHeader = false;
      this.writingDevicesContainer = false;
      this.customerLoader = false;
      this.loading = false;
      this.customerLoaderAfter = false;
      this.errorMessage =
        "This device is not matching with the connected device. Please choose another device."; */
        this.loading = false;
        this.customerLoader = false;
        this.deviceNotMatching = true;
        this.customerLoader = false;
        this.customerLoaderAfter = false;
    }
  }



  getMemoreyBankList() {
    this.service.getBaseUrl();
    this.service.getReadData
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((url) => {
        if (url.length > 0) {
          this.unsubscribe.next();
          this.getMemoryBank().subscribe(
            (data) => {
              if (data) {
                localStorage.setItem("reading", JSON.stringify(true));
                let compatibleFeat = this.checkCompatibleFeat();
                let corruptBit = this.checkCorruptBit();

                forkJoin([compatibleFeat, corruptBit]).subscribe(
                  (results) => {
                    this.corruptBits = results[1];
                    //this.featureWithId = results[0];
                    this.featureWithId = JSON.parse(
                      atob(results[0]["memoryBank"])
                    );

                    localStorage.setItem(
                      "compatibleFeatureID",
                      JSON.stringify(this.featureWithId)
                    );
                    this.corruptBits["memoryBanks"].forEach((element) => {
                      for (let i = 0; i < this.featureWithId.length; i++) {
                        if (
                          element["memoryBankId"] ===
                          this.featureWithId[i]["memoryBankId"]
                        ) {
                          this.corrupt.push(
                            this.featureWithId[i]["featureName"]
                          );
                        }
                      }
                    });
                    let isWriting = JSON.parse(
                      localStorage.getItem("isWriting")
                    );
                    if (!isWriting) {
                      setTimeout(() => {
                        this.getConfiguration();
                      }, 100);
                    } else {
                      this.getImportPayLoad();
                    }
                  },
                  (err) => {
                    this.feature12NcName = [];
                    this.service.sendMultiFeatures(this.feature12NcName);
                    this.readerData = false;
                    this.noReader = true;
                    this.customerLoader = false;
                    this.loading = false;
                    this.customerLoaderAfter = false;
                    let myObj = err;
                    this.errorMessage = myObj.Message;
                    if (err.status) {
                      this.errorCodedDC.forEach((errorCode) => {
                        if (errorCode.includes(err.status)) {
                          if (err.status === errorCode.split("-")[0]) {
                            this.errorMessage = errorCode.split("-")[1];
                            this.showDCError(
                              err.message,
                              this.errorMessage,
                              this.showExtraPopUp
                            );
                            this.retryButton = true;
                          }
                        }
                      });
                    } else {
                      if (
                        this.errorMessage ===
                        "An error occurred while retrieving the corrupt Memory Banks! \r\n Please check the Device or Peripheral ID and try again!"
                      ) {
                        this.errorMessage =
                          "An error occured while retrieving the corrupt data. Please check the Device or Peripheral ID and try again!";
                        this.retryButton = true;
                      }
                    }
                  }
                );
              }
            },
            (err) => {
              this.feature12NcName = [];
              this.service.sendMultiFeatures(this.feature12NcName);
              this.readerData = false;
              this.noReader = true;
              this.customerLoader = false;
              this.loading = false;
              this.customerLoaderAfter = false;
              let myObj = err;
              this.errorMessage = myObj.Message;

              if (err.status) {
                this.errorCodedDC.forEach((errorCode) => {
                  if (errorCode.includes(err.status)) {
                    if (err.status === errorCode.split("-")[0]) {
                      this.errorMessage = errorCode.split("-")[1];
                      this.showDCError(
                        err.message,
                        this.errorMessage,
                        this.showExtraPopUp
                      );
                      this.retryButton = true;
                    }
                  }
                });
              } else {
                if (
                  this.errorMessage ===
                  "An error occurred while retrieving the list of supported Memory Banks! \r\n Please check the Device or Peripheral ID and try again!"
                ) {
                  this.errorMessage =
                    "An error occured while retrieving the data. Please check the Device or Peripheral ID and try again!";
                  this.retryButton = true;
                }
              }
              this.isShowCancelBtn = true;
            }
          );
        }
      });
  }

  

  cancel() {
    localStorage.setItem("reading", JSON.stringify(false));
    localStorage.setItem("normalFlow", JSON.stringify(false));
    this.route.navigate(["/featureLanding"]);
    //localStorage.removeItem("configurationData");
    //localStorage.removeItem("supporteFeatures");
    this.service.removeModes("invalid");
    this.service.modalClose("");
    this.service.readerInfo("");
    this.unsubscribe.unsubscribe();
    this.service.sendMultiFeatures("");
    this.service.sendCorruptFeat("");
    this.service.sendNonCorrupt("");
    this.service.redFailedFeatures("");
  }

  getConfiguration() {
    localStorage.setItem("reading", JSON.stringify(false));
    this.service.getEnvValue();
    this.service.getEnvData
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((url) => {
        if (url.length > 0) {
          this.unsubscribe.next();
          this.startConfigurationURL = url;
          this.configurationAPI(this.readerDeviceData.deviceMetadata).subscribe(
            (data) => {
              if (data) {
                this.hideStatus = true;
                this.startConfiguration = data;
                localStorage.setItem(
                  "readerConfigurationData",
                  JSON.stringify(this.startConfiguration)
                );
                this.getFeaturePayload();
              }
            },
            (err) => {
              this.feature12NcName = [];
              this.service.sendMultiFeatures(this.feature12NcName);
              this.readerData = false;
              this.noReader = true;
              this.customerLoader = false;
              this.loading = false;
              this.customerLoaderAfter = false;
              let myObj = err;
              this.errorMessage = myObj.message;
              //this.specialErrorNoDatabase = true;
              if (this.errorMessage === "Device type Not Exist.") {
                let deviceInfoUnAvailable =
                  this.readerDeviceData.deviceMetadata;
                // this.errorMessage = 'Device is not available in our database. So we cannot read or write, please choose another device';
                this.deviceNotMatching = true;
                /* this.errorMessage =
                  "This device is not found in our database \n \n" +
                  deviceInfoUnAvailable.deviceName +
                  " " +
                  deviceInfoUnAvailable.deviceSwVersion +
                  "\n 12nc: " +
                  deviceInfoUnAvailable.device12nc +
                  "\n \n Please choose another device!"; */
                // this.showDCError("Not found", this.errorMessage,this.showExtraPopUp);
              }
              this.isShowCancelBtn = true;
            }
          );
        }
      });
  }

  getFeaturePayload() {
    this.service.getBaseUrl();
    this.service.getReadData
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((url) => {
        if (this.url.length > 0) {
          let supportedMB: any[] = [];
          this.feature12NcName.forEach((featureMBName) => {
            switch (featureMBName) {
              case "luminfoformat3-":
                supportedMB.push({
                  memoryBankId: "52-0",
                });
                supportedMB.push({
                  memoryBankId: "1-0",
                });
                break;
              case "luminfoformat0-":
                supportedMB.push({
                  memoryBankId: "52-0",
                });
                supportedMB.push({
                  memoryBankId: "1-0",
                });
                break;
              case "clo-":
                supportedMB.push({
                  memoryBankId: "14-0",
                });
                break;
              case "ast-":
                supportedMB.push({
                  memoryBankId: "29-0",
                });
                break;
              case "srpsu-":
                supportedMB.push({
                  memoryBankId: "35-0",
                });
                break;
              case "alo-":
                supportedMB.push({
                  memoryBankId: "37-0",
                });
                supportedMB.push({
                  memoryBankId: "73-0",
                });
                break;
              case "aoc-":
                supportedMB.push({
                  memoryBankId: "114-0",
                });
                supportedMB.push({
                  memoryBankId: "149-0",
                });

                break;
              case "clolite-":
                supportedMB.push({
                  memoryBankId: "14-0",
                });
                break;
              case "ztv-":
                supportedMB.push({
                  memoryBankId: "19-0",
                });
                break;
              case "dynadimmer-":
                supportedMB.push({
                  memoryBankId: "13-0",
                });
                break;
              case "lineswitch-":
                supportedMB.push({
                  memoryBankId: "15-0",
                });
                break;
              case "ampdim-":
                supportedMB.push({
                  memoryBankId: "18-0",
                });
                break;
              case "dcemergency-":
                supportedMB.push({
                  memoryBankId: "75-0",
                });
                break;
              case "dtl-":
                supportedMB.push({
                  memoryBankId: "74-0",
                });
                break;
              case "mtp-":
                supportedMB.push({
                  memoryBankId: "113-0",
                });
                break;
              case "mindimlevel-":
                supportedMB.push({
                  memoryBankId: "30-0",
                });
                break;
              case "codedmains-":
                supportedMB.push({
                  memoryBankId: "36-0",
                });
                break;
              case "touchdim-":
                supportedMB.push({
                  memoryBankId: "27-0",
                });
                break;
              case "corridor-":
                supportedMB.push({
                  memoryBankId: "16-0",
                });
                break;
              case "diia253maintenance-":
                supportedMB.push({
                  memoryBankId: "207-0",
                });
                break;
              default:
                break;
            }
          });
          let allPayResp = {
            memorybankIds:
              //this.specificMemoryBanksList
              supportedMB,
          };
          this.unsubscribe.next();
          //this.service.apiCall('post',this.readerBaseUrl+this.payLoadUrl.replace('{peripheralId}',this.readerName['peripheralType']).replace('{deviceNfcId}', this.readerDeviceData['deviceNfcId']),allPayResp)
          this.service
            .apiCall(
              "post",
              this.readerBaseUrl +
                this.payLoadUrl
                  .replace("{peripheralId}", this.readerName["peripheralId"])
                  .replace(
                    "{deviceNfcId}",
                    this.readerDeviceData["deviceNfcId"]
                  ),
              allPayResp
            )
            .subscribe(
              (resp) => {
                this.mem = resp;
                this.getImportPayLoad();
              },
              (err) => {
                this.feature12NcName = [];
                this.service.sendMultiFeatures(this.feature12NcName);
                this.writingDevicesContainer = false;
                this.readerData = false;
                this.noReader = true;
                this.customerLoader = false;
                this.loading = false;
                this.customerLoaderAfter = false;
                let myObj = err;
                this.errorMessage = myObj.Message;
                if (err.status) {
                  this.errorCodedDC.forEach((errorCode) => {
                    if (errorCode.includes(err.status)) {
                      if (err.status === errorCode.split("-")[0]) {
                        this.errorMessage = errorCode.split("-")[1];
                        this.showDCError(
                          err.message,
                          this.errorMessage,
                          this.showExtraPopUp
                        );
                        this.retryButton = true;
                      }
                    }
                  });
                } else {
                  if (
                    this.errorMessage ===
                    "An error occurred while returning the memory bank properties! \r\n Please check the Device or Peripheral ID and try again!"
                  ) {
                    this.errorMessage =
                      "An error occurred while returning the properties!  Please check the Device or Peripheral ID and try again!";
                    this.retryButton = true;
                  }
                }
                this.isShowCancelBtn = true;
              }
            );
        }
      });
  }

  getImportPayLoad() {
    // this.customerLoaderAfter = true;
    localStorage.setItem("reading", JSON.stringify(false));
    var configurationDataValue = JSON.parse(
      localStorage.getItem("configurationData")
    );
    let uniqueConfigIDs = ["NA"];
    if (configurationDataValue != null) {
      uniqueConfigIDs = configurationDataValue["configurationIDUniqueDC"];
    }
    this.service.getEnvValue();
    this.service.getEnvData
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((url) => {
        if (this.url.length > 0) {
          let enyDryFlag = false;
          if (this.mem.hasOwnProperty("iv")) {
            this.envScehmaLIstApi = this.url + this.importPayLoadUrl;
            enyDryFlag = true;
          } else {
            this.envScehmaLIstApi = this.url + this.importPayLoadUrlNE;
            enyDryFlag = false;
          }

          this.unsubscribe.next();
          let i = 0;
          this.apiListResponse = [];
          let newObj = [];
          let aloMinObj = [];
          let aocMinObj = [];
          let lumInfoSixObj = [];
          this.mem.properties.forEach((bankId) => {
            if (bankId.memoryBankId === "37-0") {
              this.isALoMinObj = true;

              if (enyDryFlag) {
                aloMinObj.push({
                  memoryBankId: bankId.memoryBankId,
                  ciphertext: bankId.ciphertext,
                });
              } else {
                aloMinObj.push({
                  memoryBankId: bankId.memoryBankId,
                  memoryBankVersion: bankId.memoryBankVersion,
                  payload: bankId.payload,
                });
              }
            }
            if (bankId.memoryBankId === "149-0") {
              this.isAocMinObj = true;

              if (enyDryFlag) {
                aocMinObj.push({
                  memoryBankId: bankId.memoryBankId,
                  ciphertext: bankId.ciphertext,
                });
              } else {
                aocMinObj.push({
                  memoryBankId: bankId.memoryBankId,
                  memoryBankVersion: bankId.memoryBankVersion,
                  payload: bankId.payload,
                });
              }
            }
            if (bankId.memoryBankId === "52-0") {
              this.isLumMinSixObj = true;

              if (enyDryFlag) {
                lumInfoSixObj.push({
                  memoryBankId: bankId.memoryBankId,
                  ciphertext: bankId.ciphertext,
                });
              } else {
                lumInfoSixObj.push({
                  memoryBankId: bankId.memoryBankId,
                  memoryBankVersion: bankId.memoryBankVersion,
                  payload: bankId.payload,
                });
              }
            }
          });
          this.mem.properties.forEach((bankId) => {
            this.bankIdByIndex = bankId.memoryBankId;
            if (
              bankId.memoryBankId === "19-0" ||
              bankId.memoryBankId === "35-0" ||
              bankId.memoryBankId === "1-0" ||
              bankId.memoryBankId === "114-0" ||
              bankId.memoryBankId === "73-0" ||
              bankId.memoryBankId === "14-0" ||
              bankId.memoryBankId === "149-0" ||
              bankId.memoryBankId === "29-0" ||
              bankId.memoryBankId ===
                "27-0"  ||
              bankId.memoryBankId === '13-0' || bankId.memoryBankId === '15-0' || bankId.memoryBankId === '18-0' ||
              bankId.memoryBankId === "75-0" ||
              bankId.memoryBankId === "74-0" ||
              bankId.memoryBankId === "113-0" ||
              bankId.memoryBankId === "30-0" ||
              bankId.memoryBankId ===
                "16-0"||
              bankId.memoryBankId === '36-0' ||
              bankId.memoryBankId === '207-0'
            ) {
              if (bankId.memoryBankId === "73-0" && this.isALoMinObj) {
                newObj = [];
                if (enyDryFlag) {
                  newObj.push({
                    memoryBankId: bankId.memoryBankId,
                    ciphertext: bankId.ciphertext,
                  });
                } else {
                  newObj.push({
                    memoryBankId: bankId.memoryBankId,
                    memoryBankVersion: bankId.memoryBankVersion,
                    payload: bankId.payload,
                  });
                }
                newObj = newObj.concat(aloMinObj);
              } else if (bankId.memoryBankId === "114-0" && this.isAocMinObj) {
                newObj = [];
                if (enyDryFlag) {
                  newObj = [
                    {
                      memoryBankId: bankId.memoryBankId,
                      ciphertext: bankId.ciphertext,
                    },
                  ];
                } else {
                  newObj = [
                    {
                      memoryBankId: bankId.memoryBankId,
                      memoryBankVersion: bankId.memoryBankVersion,
                      payload: bankId.payload,
                    },
                  ];
                }

                newObj = newObj.concat(aocMinObj);
              } else if (bankId.memoryBankId === "1-0" && this.isLumMinSixObj) {
                newObj = [];
                if (enyDryFlag) {
                  newObj = [
                    {
                      memoryBankId: bankId.memoryBankId,
                      ciphertext: bankId.ciphertext,
                    },
                  ];
                } else {
                  newObj = [
                    {
                      memoryBankId: bankId.memoryBankId,
                      memoryBankVersion: bankId.memoryBankVersion,
                      payload: bankId.payload,
                    },
                  ];
                }

                newObj = newObj.concat(lumInfoSixObj);
              } else if (bankId.memoryBankId === "13-0") {
                //let payloadMod = this.matchPayload13(bankId.payload)
                newObj = [];
                if (enyDryFlag) {
                  newObj = [
                    {
                      memoryBankId: bankId.memoryBankId,
                      ciphertext: bankId.ciphertext,
                    },
                  ];
                } else {
                  newObj = [
                    {
                      memoryBankId: bankId.memoryBankId,
                      memoryBankVersion: bankId.memoryBankVersion,
                      payload: bankId.payload,
                    },
                  ];
                }
              } else if (bankId.memoryBankId === "15-0") {
                //let payloadMod = this.matchPayload15(bankId.payload)
                newObj = [];
                if (enyDryFlag) {
                  newObj = [
                    {
                      memoryBankId: bankId.memoryBankId,
                      ciphertext: bankId.ciphertext,
                    },
                  ];
                } else {
                  newObj = [
                    {
                      memoryBankId: bankId.memoryBankId,
                      memoryBankVersion: bankId.memoryBankVersion,
                      payload: bankId.payload,
                    },
                  ];
                }
              } else if (bankId.memoryBankId === "27-0") {
                //let payloadMod = this.matchPayload15(bankId.payload)
                newObj = [];
                if (enyDryFlag) {
                  newObj = [
                    {
                      memoryBankId: bankId.memoryBankId,
                      ciphertext: bankId.ciphertext,
                    },
                  ];
                } else {
                  newObj = [
                    {
                      memoryBankId: bankId.memoryBankId,
                      memoryBankVersion: bankId.memoryBankVersion,
                      payload: bankId.payload,
                    },
                  ];
                }
              } else if (bankId.memoryBankId === "18-0") {
                //let payloadMod = bankId.payload;
                //if (!this.writingDevice)
                //payloadMod = this.workAroundPayAmpDim(bankId.payload);

                if (enyDryFlag) {
                  newObj = [
                    {
                      memoryBankId: bankId.memoryBankId,
                      ciphertext: bankId.ciphertext,
                    },
                  ];
                } else {
                  newObj = [
                    {
                      memoryBankId: bankId.memoryBankId,
                      memoryBankVersion: bankId.memoryBankVersion,
                      payload: bankId.payload,
                    },
                  ];
                }
              } else if (
                bankId.memoryBankId === "19-0" ||
                bankId.memoryBankId === "35-0" ||
                bankId.memoryBankId === "1-0" ||
                bankId.memoryBankId === "114-0" ||
                bankId.memoryBankId === "14-0" ||
                bankId.memoryBankId === "73-0" ||
                bankId.memoryBankId === "29-0" ||
                bankId.memoryBankId === "15-0" ||
                bankId.memoryBankId === "75-0" ||
                bankId.memoryBankId === "74-0" ||
                bankId.memoryBankId === "113-0" ||
                bankId.memoryBankId === "30-0" ||
                bankId.memoryBankId === "36-0" ||
                bankId.memoryBankId === "16-0" ||
                bankId.memoryBankId === '207-0'
              ) {
                newObj = [];
                if (enyDryFlag) {
                  newObj = [
                    {
                      memoryBankId: bankId.memoryBankId,
                      ciphertext: bankId.ciphertext,
                    },
                  ];
                } else {
                  newObj = [
                    {
                      memoryBankId: bankId.memoryBankId,
                      memoryBankVersion: bankId.memoryBankVersion,
                      payload: bankId.payload,
                    },
                  ];
                }
              }

              let newConfigurationIDUnq = uniqueConfigIDs[0];

              if (!(newConfigurationIDUnq === "NA")) {
                uniqueConfigIDs.shift();
              }
              this.unsubscribe.next();
              //let uniqueConfigID =  this.UniqueCofigIDImportPayload();

              ///////////
              /*     this.service.getEnvValue();
           this.service.getEnvData.pipe(takeUntil(this.unsubscribe)).subscribe(url => {
             if (url.length > 0) {
               this.unsubscribe.next();
               this.startConfigurationURL = url;
               this.configurationAPI(this.readerDeviceData.deviceMetadata).subscribe((data) => {
                 if (data) {
                  this.apiListResponse[i] = this.service.apiCall('post', this.envScehmaLIstApi.replace('{configurationId}', data['configurationID']), { "deviceName": this.mem.deviceName, "device12nc": this.mem.device12nc, "deviceSwVersion": this.mem.deviceSwVersion, "interface": this.mem.interface, "properties": newObj });
           
                  //this.apiListResponse[i] = this.service.apiCall('post', this.envScehmaLIstApi.replace('{configurationId}', this.startConfiguration['configurationID']), { "deviceName": this.mem.deviceName, "device12nc": this.mem.device12nc, "deviceSwVersion": this.mem.deviceSwVersion, "interface": this.mem.interface, "properties": newObj });
                   i++;
                 }
               }, err => {

               })
             }
       
       
           }); */

              /////Below chnage for passing new config id
     
              if (!(newConfigurationIDUnq === "NA")) {
                if (newConfigurationIDUnq === undefined) {
                  let newConfigId = "";
                  this.service.getEnvValue();
                  this.service.getEnvData
                    .pipe(takeUntil(this.unsubscribe))
                    .subscribe((url) => {
                      if (url.length > 0) {
                        this.unsubscribe.next();
                        this.startConfigurationURL = url;
                        this.configurationAPI(
                          this.readerDeviceData.deviceMetadata
                        ).subscribe(
                          (data) => {
                            if (data) {
              
                              newConfigId = data["configurationID"];
                              if (this.mem.hasOwnProperty("iv")) {
                                this.apiListResponse[i] = this.service.apiCall(
                                  "post",
                                  this.envScehmaLIstApi.replace(
                                    "{configurationId}",
                                    newConfigId
                                  ),
                                  {
                                    deviceName: this.mem.deviceName,
                                    device12nc: this.mem.device12nc,
                                    deviceSwVersion: this.mem.deviceSwVersion,
                                    interface: this.mem.interface,
                                    iv: this.mem.iv,
                                    properties: newObj,
                                  }
                                );
                              } else {
                                this.apiListResponse[i] = this.service.apiCall(
                                  "post",
                                  this.envScehmaLIstApi.replace(
                                    "{configurationId}",
                                    newConfigId
                                  ),
                                  {
                                    deviceName: this.mem.deviceName,
                                    device12nc: this.mem.device12nc,
                                    deviceSwVersion: this.mem.deviceSwVersion,
                                    interface: this.mem.interface,
                                    properties: newObj,
                                  }
                                );
                              }
                            }
                          },
                          (err) => {}
                        );
                      }
                    });
                } else {
                  if (this.mem.hasOwnProperty("iv")) {
                    this.apiListResponse[i] = this.service.apiCall(
                      "post",
                      this.envScehmaLIstApi.replace(
                        "{configurationId}",
                        newConfigurationIDUnq
                      ),
                      {
                        deviceName: this.mem.deviceName,
                        device12nc: this.mem.device12nc,
                        deviceSwVersion: this.mem.deviceSwVersion,
                        interface: this.mem.interface,
                        iv: this.mem.iv,
                        properties: newObj,
                      }
                    );
                  } else {
                    this.apiListResponse[i] = this.service.apiCall(
                      "post",
                      this.envScehmaLIstApi.replace(
                        "{configurationId}",
                        newConfigurationIDUnq
                      ),
                      {
                        deviceName: this.mem.deviceName,
                        device12nc: this.mem.device12nc,
                        deviceSwVersion: this.mem.deviceSwVersion,
                        interface: this.mem.interface,
                        properties: newObj,
                      }
                    );
                  }
                }
                i++;
              }
              //////// Changes are eblow code
              else {
                if (this.mem.hasOwnProperty("iv")) {
                  this.apiListResponse[i] = this.service.apiCall(
                    "post",
                    this.envScehmaLIstApi.replace(
                      "{configurationId}",
                      this.startConfiguration["configurationID"]
                    ),
                    {
                      deviceName: this.mem.deviceName,
                      device12nc: this.mem.device12nc,
                      deviceSwVersion: this.mem.deviceSwVersion,
                      interface: this.mem.interface,
                      iv: this.mem.iv,
                      properties: newObj,
                    }
                  );
                } else {
                  this.apiListResponse[i] = this.service.apiCall(
                    "post",
                    this.envScehmaLIstApi.replace(
                      "{configurationId}",
                      this.startConfiguration["configurationID"]
                    ),
                    {
                      deviceName: this.mem.deviceName,
                      device12nc: this.mem.device12nc,
                      deviceSwVersion: this.mem.deviceSwVersion,
                      interface: this.mem.interface,
                      properties: newObj,
                    }
                  );
                }
                i++;
              }

              /////////////
              /*      setTimeout(() => {
            this.apiListResponse[i] = this.service.apiCall('post', this.envScehmaLIstApi.replace('{configurationId}', uniqueConfigID), { "deviceName": this.mem.deviceName, "device12nc": this.mem.device12nc, "deviceSwVersion": this.mem.deviceSwVersion, "interface": this.mem.interface, "properties": newObj });
           
            //this.apiListResponse[i] = this.service.apiCall('post', this.envScehmaLIstApi.replace('{configurationId}', this.startConfiguration['configurationID']), { "deviceName": this.mem.deviceName, "device12nc": this.mem.device12nc, "deviceSwVersion": this.mem.deviceSwVersion, "interface": this.mem.interface, "properties": newObj });
             i++;
           }, 1000); */

              /*  this.configurationAPI(this.mem).subscribe((data)=>{
      
            
           })
             */
            }
          });

          forkJoin(this.apiListResponse).subscribe(
            (data) => {
              if (data) {
                this.hideStatus = true;
                let greenFeat = [];
                let redFeat = [];
                data.forEach((element) => {
                  let UnauthorizedErrorFeature: boolean = false;
                  if (element["failedFeatures"].length == 1) {
                    if (
                      element["failedFeatures"][0].message === "Unauthorized."
                    ) {
                      UnauthorizedErrorFeature = true;
                    }
                  }

                  if (!UnauthorizedErrorFeature) {
                    if (element["successfulFeatures"].length > 0) {
                      greenFeat.push(element["successfulFeatures"]);
                    } else if (element["failedFeatures"].length > 0) {
                      redFeat.push(element["failedFeatures"]);
                    }
                  }
                  // if(element['successfulFeatures'].length>0) {
                  //   greenFeat.push(element['successfulFeatures']);
                  // } else if(element['failedFeatures'].length>0) {
                  //   redFeat.push(element['failedFeatures']);
                  // }
                  UnauthorizedErrorFeature = false;
                });
                // this.service.redFailedFeatures(0);
                localStorage.setItem(
                  "resultFeatures",
                  JSON.stringify({
                    green: greenFeat,
                    red: redFeat,
                    orange: this.corrupt,
                    deviceInfo: this.deviceInfo,
                  })
                );
                // this.service.sendNonCorrupt({green: greenFeat, red: redFeat});
                // this.service.sendCorruptFeat(this.corrupt);
              }
              this.customerLoaderAfter = false;
            },
            (err) => {
              this.isShowCancelBtn = true;
              this.feature12NcName = [];
              this.isShowCancelBtn = true;
              this.service.sendMultiFeatures(this.feature12NcName);
              this.readerData = false;
              this.noReader = true;
              this.customerLoader = false;
              this.loading = false;
              this.customerLoaderAfter = false;
              let myObj = err;
              this.errorMessage = myObj.message;
              if (
                this.errorMessage ===
                "An error occurred while returning the memory bank properties! \r\n Please check the Device or Peripheral ID and try again!"
              ) {
                this.errorMessage =
                  "An error occurred while returning the properties!  Please check the Device or Peripheral ID and try again!";
                this.retryButton = true;
              }
            }
          );
        }
      });
  }
 


 

  generatePluginOutput() {
    this.service.getEnvValue();
    this.service.getEnvData
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((url) => {
        if (url.length > 0) {
          this.unsubscribe.next();
          let configuration = JSON.parse(
            localStorage.getItem("configurationData")
          );
          let writngUrl;
          writngUrl = url + this.writingURLViaPlugin;

          this.service
            .apiCall(
              "get",
              writngUrl.replace(
                "{configurationId}",
                configuration["configurationID"]
              )
            )
            .subscribe(
              (data) => {
                if (data) {
                  this.mem = data;
                  this.writingDeviceWithPC(data);
                }
              },
              (err) => {
                this.isShowCancelBtn = true;
                this.readerData = false;
                this.writingDevicesContainer = false;
                this.noReader = true;
                this.customerLoader = false;
                this.loading = false;
                this.customerLoaderAfter = false;
                let myObj = err;
                this.errorMessage = myObj.message;
                if (err.status) {
                  this.errorCodedDC.forEach((errorCode) => {
                    if (errorCode.includes(err.status)) {
                      if (err.status === errorCode.split("-")[0]) {
                        this.errorMessage = errorCode.split("-")[1];
                        this.showDCError(
                          err.message,
                          this.errorMessage,
                          this.showExtraPopUp
                        );
                        this.retryButton = true;
                      }
                    }
                  });
                } else {
                  const modalRef = this.modalService.open(ErrorComponent, {
                    keyboard: false,
                    centered: true,
                    windowClass: "error-style, error-style1",
                  });
                  modalRef.componentInstance.errorName = err["message"];
                  modalRef.componentInstance.errorDescription =
                    err["description"];
                }
              }
            );
        }
      });
  }


   writingDeviceWithPC(data) {
    let readerName = JSON.parse(localStorage.getItem("readerInfo"));
    readerName = readerName[0];
    let readerDeviceData = JSON.parse(
      localStorage.getItem("setReaderDEviceInfo")
    );
    let startConfiguration = JSON.parse(
      localStorage.getItem("configurationData")
    );
    this.startConfiguration = JSON.parse(
      localStorage.getItem("configurationData")
    );
    this.feature12NcName["allFeature"] =
      startConfiguration["selectedFeaturesInfo"];
    this.service.sendMultiFeatures(this.feature12NcName["allFeature"]);
    let i = 0;
    this.apiListResponse = [];
    this.service.getBaseUrl();
     this.service.getReadData
       .pipe(takeUntil(this.unsubscribe))
       .subscribe(async (url) => {
         if (url.length > 0) {
           this.writingURL = url + this.writingInDeviceURL;
           this.readerBaseUrl = url;
           this.unsubscribe.next();
           let writingObj = {};
           /*  data.properties = this.checkInterdependencyPayLoadOffset(
             data.properties );*/

           //data.properties = this.touchDimWorkAroundForWrite(data.properties);
           if (data.hasOwnProperty("iv")) {
             writingObj = {
               deviceName: data.deviceName,
               device12nc: data.device12nc,
               deviceSwVersion: data.deviceSwVersion,
               interface: data.interface,
               iv: data.iv,
               properties: data.properties,
               metadata: data.metadata,
             };
           } else {
             writingObj = {
               deviceName: data.deviceName,
               device12nc: data.device12nc,
               deviceSwVersion: data.deviceSwVersion,
               interface: data.interface,
               properties: data.properties,
             };
           }

           let memoryNfcSub: BehaviorSubject<string> = new BehaviorSubject(this.deviceNfcIdsList[0]);
           let memoryNfcSubObs$ = memoryNfcSub.asObservable();
           let count = 0;
           memoryNfcSubObs$.subscribe((nfcId) =>
             this.service
               .putApi(
                 "put",
                 this.writingURL
                   .replace("{peripheralId}", readerName["peripheralId"])
                   .replace("{deviceNfcId}", nfcId),
                 writingObj
                 /* {
                   deviceName: data.deviceName,
                   device12nc: data.device12nc,
                   deviceSwVersion: data.deviceSwVersion,
                   interface: data.interface,
                   properties: data.properties,
                 } */
               )
               .subscribe((data) => {
                // if (count == 0) {
               // }
                 count++;
                 if (count < 10) {
                   this.initialCount = "0" + count;
                 } else {
                   this.initialCount = count;
                 }
                 if (count == this.deviceNfcIdsList.length) {
                   memoryNfcSub.unsubscribe();
                   localStorage.removeItem("saveSetData");
                   this.removeWritingHeader = false;
                   this.removeSuccessText = false;
                   this.readerData = JSON.parse(
                     localStorage.getItem("readerInfo")
                   );
                   this.readerName = this.readerData[0];
                   this.readerDeviceData = JSON.parse(
                     localStorage.getItem("setReaderDEviceInfo")
                   );
                   if (localStorage.getItem("compatibleFeatureID")) {
                     this.featureWithId = JSON.parse(
                       localStorage.getItem("compatibleFeatureID")
                     );
                     this.getImportPayLoad();
                   } else {
                    // this.getMemoreyBankList();
                    let isWriting = JSON.parse(
                      localStorage.getItem("isWriting")
                    );
                    if (!isWriting) {
                      setTimeout(() => {
                        this.getConfiguration();
                      }, 100);
                    } else {
                      this.getImportPayLoad();
                    }
                   }
                 } else {
                   memoryNfcSub.next(this.deviceNfcIdsList[count])

                 }

               },
                 (err) => {
                   count++;
                   this.retryButton = true;
                   this.isShowCancelBtn = true;
                   this.readerData = true;
                   this.writingDevicesContainer = true;
                   this.customerLoader = false;
                   this.loading = false;
                   this.customerLoaderAfter = false;
                   let myObj = err;
                   this.errorMessage = myObj.Message;
                   this.isError = true;
                   memoryNfcSub.unsubscribe();
                   if (err.status === "412.1" || err.status === "422.2") {
                     this.isDeviceConnected = false;
                     this.noReader = true;
                   } else {
                     this.noReader = false;
                   }
                  //  if (err.status) {
                  //    this.errorCodedDC.forEach((errorCode) => {
                  //      if (errorCode.includes(err.status)) {
                  //        if (err.status === errorCode.split("-")[0]) {
                  //          this.errorMessage = errorCode.split("-")[1];
                  //          this.showDCError(
                  //            err.message,
                  //            this.errorMessage,
                  //            this.showExtraPopUp
                  //          );
                  //          this.retryButton = true;
                  //        }
                  //      }
                  //    });
                  //  }
                  //  if (count == this.deviceNfcIdsList.length) {
                  //   memoryNfcSub.unsubscribe();
                  //  }
                 }
               )
           );
         }
       });
  }


  getReaderList() {
    return this.service.apiCall("get", this.readerBaseUrl + this.readerListUrl);
    // return this.service.apiCall('get', '/assets/reader/list.json');
  }

  getDeviceInfo() {
    //return this.service.apiCall('get', this.readerBaseUrl+this.deviceInfoUrl.replace('{peripheralId}',this.readerName['peripheralType']))
    if (this.readerName["peripheralType"] === "LCN9640") {
      this.deviceInfoUrl = `/peripheral/v1/nfc/{peripheralId}/devices/${this.userBoxSizeValue}?refresh=true`;
    } else {
      this.deviceInfoUrl = "/peripheral/v1/nfc/{peripheralId}/devices?refresh=true";
    }
   
    return this.service.apiCall(
      "get",
      this.readerBaseUrl +
        this.deviceInfoUrl.replace(
          "{peripheralId}",
          this.readerName["peripheralId"]
        )
    );
    // return this.service.apiCall('get', '/assets/reader/readerInfo.json')
  }

  getMemoryBank() {
    //return this.service.apiCall('get', this.readerBaseUrl+this.memoryBankUrl.replace('{peripheralId}',this.readerName['peripheralType']).replace('{deviceNfcId}', this.readerDeviceData['deviceNfcId']));
    return this.service.apiCall(
      "get",
      this.readerBaseUrl +
        this.memoryBankUrl
          .replace("{peripheralId}", this.readerName["peripheralId"])
          .replace("{deviceNfcId}",this.readerDeviceData["deviceNfcId"])
    );

    // return this.service.apiCall('get', '/assets/reader/memoryBank.json');
  }

  getMemoryBankByNfcID(deviceNfcId:any) {
    //return this.service.apiCall('get', this.readerBaseUrl+this.memoryBankUrl.replace('{peripheralId}',this.readerName['peripheralType']).replace('{deviceNfcId}', this.readerDeviceData['deviceNfcId']));
    return this.service.apiCall(
      "get",
      this.readerBaseUrl +
        this.memoryBankUrl
          .replace("{peripheralId}", this.readerName["peripheralId"])
          .replace("{deviceNfcId}", deviceNfcId)
    );

    // return this.service.apiCall('get', '/assets/reader/memoryBank.json');
  }


  checkCompatibleFeat() {
    if (window.location.hostname == "localhost") {
      return this.service.apiCall("get", "/assets/reader/memoryId.json");
    } else {
      return this.service.apiCall("get", "memoryId.json");
    }
  }

  checkCorruptBit() {
    //return this.service.apiCall('get', this.readerBaseUrl+this.corruptBitUrl.replace('{peripheralId}',this.readerName['peripheralType']).replace('{deviceNfcId}', this.readerDeviceData['deviceNfcId']));
    return this.service.apiCall(
      "get",
      this.readerBaseUrl +
        this.corruptBitUrl
          .replace("{peripheralId}", this.readerName["peripheralId"])
          .replace("{deviceNfcId}", this.readerDeviceData["deviceNfcId"])
    );
    // return this.service.apiCall('get','/assets/reader/corruptBit.json');
  }

  checkCorruptBitByNfcId(deviceNfcId:any) {
    //return this.service.apiCall('get', this.readerBaseUrl+this.corruptBitUrl.replace('{peripheralId}',this.readerName['peripheralType']).replace('{deviceNfcId}', this.readerDeviceData['deviceNfcId']));
    return this.service.apiCall(
      "get",
      this.readerBaseUrl +
        this.corruptBitUrl
          .replace("{peripheralId}", this.readerName["peripheralId"])
          .replace("{deviceNfcId}", deviceNfcId)
    );
    // return this.service.apiCall('get','/assets/reader/corruptBit.json');
  }

  configurationAPI(deviceInfo) {
    let mockDevice = {
      device12nc: deviceInfo.device12nc,
      deviceName: deviceInfo.deviceName,
      softwareVersion: deviceInfo.deviceSwVersion,
    };
    return this.service.getStartConfiguration(
      mockDevice,
      this.startConfigurationURL
    );
  }

 
 
  nextSuccessMultiWrite(){
    this.incorrectBoxSize = false;
    this.deviceCNtMatched = false;
    this.owpProtectedMultiDevices = false;
    this.deviceNotMatching = false;
    this.corruptedDevices = false;
    this.service.sendShowNextSuccessBtnMulti(false);
    this.showNextSuccessWriteBtn = false;
    const modalRef = this.modalService.open(ConfigureDeviceModalInputComponent, {
      windowClass: "select-feature-modal",
      backdropClass: "customBackdrop",
    });
  }

  retry() {
    if (this.isWritingRetryCheck) {
      //  this.cancel();
      this.service.modalClose("");
      this.service.readerInfo("");
      this.unsubscribe.unsubscribe();
      this.service.sendMultiFeatures("");
      this.service.sendCorruptFeat("");
      this.service.sendNonCorrupt("");
      this.service.redFailedFeatures("");

      this.retryClicked = true;

      this.service.modalConfigurationData("readDevice");

      const modalRef = this.modalService.open(ReadDeviceMultiDriverComponent, {
        windowClass: "select-feature-modal",
        backdropClass: "customBackdrop",
      });
      modalRef.componentInstance.isRetry = true;
    } else {
      this.cancel();
      this.service.modalClose("");
      this.service.readerInfo("");
      this.unsubscribe.unsubscribe();
      this.service.sendMultiFeatures("");
      this.service.sendCorruptFeat("");
      this.service.sendNonCorrupt("");
      this.service.redFailedFeatures("");

      this.retryClicked = true;
      localStorage.setItem("normalFlow", JSON.stringify(false));
      localStorage.removeItem("CCTBoolen");
      localStorage.removeItem("saveReadValue");
      localStorage.removeItem("resultFeatures");
      localStorage.removeItem("supporteFeatures");
      localStorage.removeItem("configurationData");
      localStorage.removeItem("readerDeviceFullInfo");
      this.service.removeModes("invalid");
      localStorage.setItem("reading", JSON.stringify(true));
      localStorage.removeItem("saveSetData");
      let isLoggedin = JSON.parse(localStorage.getItem("loggedIn"));

      localStorage.setItem("isWriting", JSON.stringify(false));
      this.service.modalConfigurationData("readDevice");

      const modalRef = this.modalService.open(ReadDeviceMultiDriverComponent, {
        windowClass: "select-feature-modal",
        backdropClass: "customBackdrop",
      });
      modalRef.componentInstance.isRetry = true;
    }

    //}
  }

  retryConfigure() {
    this.newReaderInfo();
  }

  nextOnValidDevices(){
    this.deviceCNtMatched = false;
    this.configConnectedDevices = true;
    this.incorrectBoxSize = false;
    this.owpProtectedMultiDevices = false;
    this.corruptedDevices = false;
    this.deviceNotMatching = false;
    this.customerLoaderAfter = true;
    this.showNextSuccessWriteBtn = false;
    this.isShowCancelBtn = false;
    this.generatePluginOutput();
    

  }
  ngOnDestroy() {
    this.service.modalClose("");
    this.service.readerInfo("");
    this.unsubscribe.unsubscribe();
    this.service.sendMultiFeatures("");
    this.service.sendCorruptFeat("");
    this.service.sendNonCorrupt("");
    this.service.redFailedFeatures("");
    this.showNextSuccessWriteBtn = false;
    if(this.getShowNextMultiSuccessBtnSub){
     this.getShowNextMultiSuccessBtnSub.unsubscribe();
    };
    this.deviceCNtMatched = false;
  }
}
