import { Component, OnInit } from "@angular/core";
import { ApiService } from "src/app/core-module/services/api.service";
import * as d3 from "d3";
@Component({
  selector: "app-mtp-graph",
  templateUrl: "./mtp-graph.component.html",
  styleUrls: ["./mtp-graph.component.scss"],
})
export class MtpGraphComponent implements OnInit {
  inputXRange = [0, 165];

  inputYRange = [0, 100];

  data: any[] = [
    { dyanXTime: 0, dyanYOutput: 100 },
    { dyanXTime: 80, dyanYOutput: 100 },
    { dyanXTime: 90, dyanYOutput: 10 },
    { dyanXTime: 165, dyanYOutput: 10 },
  ];
  data1: any[] = [];
  dataFromTableValues: any[] = [];

  focus;
  drag;
  public margin = { top: 50, right: 50, bottom: 50, left: 50 };
  public width: number;
  public height: number;
  public x: any;
  public y: any;
  public svg6: any;
  public line: d3.Line<[number, number]>; // this is line defination
  public area: d3.area<[number, number]>; // this is area defination
  public id = "lineChart";
  public color2 = "#00b36b";
  newData: any;
  changedPoint: any;
  feedMaxValue: any = 112;
  NormalLevelMaximum: any;
  BackgroundLevelMaximum: any;
  Maxdata: any = [];
  Tmaxstart: any;
  TmaxEnd: any;
  TmaxShutDown: any;
  scrolPoint: any;
  deRatingdyanYOutput: any;
  LamOffValue: any;
  DtlDimStopValue: any;
  TmaxdeRatingDim: any;
  Forbidenarea: d3.area<[number, number]>; // this is area defination
  deratingLabelYaxis: number;
  TminEnd: any;
  MaxLimit: any;
  TmindeRatingDim: any;
  graphEnabled: boolean;
  philipsLEDChecked: boolean;
  tcDeltaValue: number;
  mtpStartMax: number;
  mtpStopMax: number;
  mtpDeratingMax: number;
  mtpDimStartMaxSch: number;
  mtpDimStopMaxSch: number;
  mtpDimLevelMaxSch: number;

  constructor(private apiService: ApiService) {}

  ngOnInit() {
    // this.apiService.getDynaTimeReferencedMode.subscribe(response => {

    //   if(response===true)
    //   {
    //     this.apiService.getFinalDynaDimmerData.subscribe(response=>{
    //       this.addDynaDetails(response);

    //     });
    //   }
    // })

    this.apiService.getTCDeltaValueMTP.subscribe((delta) => {
      this.tcDeltaValue = delta;
    });
    this.apiService.getMtpDimStartMax.subscribe((value) => {
      this.mtpDimStartMaxSch = value;
    });
    this.apiService.getMtpDimStopMax.subscribe((value) => {
      this.mtpDimStopMaxSch = value;
    });
    this.apiService.getMtpDimLevelMax.subscribe((value) => {
      this.mtpDimLevelMaxSch = value;
    });
    this.apiService.getMtpData.subscribe((response) => {
      this.data = this.addMTPData(response);

      this.buildSvg();
      this.addXandYAxis();
      this.drawLineAndPath();
      this.addDrag();
    });

    this.newData = this.data1;

    this.buildSvg();
    this.addXandYAxis();
    this.drawLineAndPath();
    this.addDrag();
  }
  addMTPData(response: any): any[] {
    //let dataFromTable = JSON.parse(response);
    // console.log(response);

    this.mtpStartMax = response.DimStartValueMax
      ? response.DimStartValueMax
      : this.mtpDimStartMaxSch;
    this.mtpStopMax = response.DimStopValueMax
      ? response.DimStopValueMax
      : this.mtpDimStopMaxSch;
    this.mtpDeratingMax = response.DimLevelValueMax
      ? response.DimLevelValueMax
      : this.mtpDimLevelMaxSch;
    if (response.SelectedNtc === "PhilipsLedLightEngines") {
      this.philipsLEDChecked = true;
    } else {
      this.philipsLEDChecked = false; //MOWEB 1.1
    }
    if (response.Enabled === true) {
      this.graphEnabled = true;
    }
    let dimStartValue =
      response.DimStartValue > response.DimStartValueMax
        ? response.DimStartValueMax
        : response.DimStartValue;
    let dimStopValue =
      response.DimStopValue > response.DimStopValueMax
        ? response.DimStopValueMax
        : response.DimStopValue;
    let dimLevel =
      response.DimLevelValue > response.DimLevelValueMax
        ? response.DimLevelValueMax
        : response.DimLevelValue;
    const data2 = [
      { dyanXTime: 0, dyanYOutput: this.mtpDimLevelMaxSch },
      { dyanXTime: dimStartValue, dyanYOutput: this.mtpDimLevelMaxSch },
      { dyanXTime: dimStopValue, dyanYOutput: dimLevel },
      { dyanXTime: this.mtpDimStopMaxSch, dyanYOutput: dimLevel },
    ];
    return data2;
  }

  public buildSvg() {
    this.svg6 = d3.select("#svg6");
    this.width = 960 - this.margin.left - this.margin.right;
    this.height = 500 - this.margin.top - this.margin.bottom;
  }
  public addXandYAxis() {
    // range of data configuring
    this.x = d3.scaleLinear().range([0, this.width]);
    this.y = d3.scaleLinear().range([this.height, 0]);
    this.x.domain(this.inputXRange);
    this.y.domain(this.inputYRange);
  }

  public drawLineAndPath() {
    this.line = d3
      .line()
      .x((d: any) => this.x(d.dyanXTime))
      .y((d: any) => this.y(d.dyanYOutput));
    d3.selectAll("#svg6 > *").remove();
    this.area = d3
      .area()
      .x((d: any) => this.x(d.dyanXTime))
      .y0(this.height)
      .y1((d: any) => this.y(d.dyanYOutput));

    this.focus = this.svg6
      .append("g")
      .attr(
        "transform",
        "translate(" + this.margin.left + "," + this.margin.top + ")"
      );
    //ploting Area
    this.focus
      .append("path")
      .datum(this.data)
      .attr("fill", "#1ECBD0")
      .attr("fill-opacity", 0.3)
      .attr("stroke", "#1ECBD0")
      .attr("stroke-width", "1px")
      .attr("fill", "url('#gradient6')")
      .attr("d", this.area);

    //ploting circle
    this.focus
      .selectAll("circle")
      .data(this.data)
      .enter()
      .append("circle")
      .attr("r", 4.0)
      .attr("cx", (d: any) => this.x(d.dyanXTime))
      .attr("cy", (d: any) => this.y(d.dyanYOutput))
      .style("cursor", "pointer")
      .style("fill", "#1ECBD0");

    const maxY = d3.max(this.data, function (d) {
      return d.dyanYOutput;
    });
    // Add Gradient

    this.svg6
      .append("linearGradient")
      .attr("id", "gradient6")
      .attr("gradientUnits", "userSpaceOnUse")
      .attr("x1", 0)
      .attr("y1", this.y(0))
      .attr("x2", 0)
      .attr("y2", this.y(maxY))
      .selectAll("stop")
      .data([
        {
          offset: "10%",
          color: "transparent",
        },
        {
          offset: "30%",
          color: "#00E487",
        },
        {
          offset: "100%",
          color: this.color2,
        },
      ])
      .enter()
      .append("stop")
      .attr("offset", function (d) {
        return d.offset;
      })
      .attr("stop-color", function (d) {
        return d.color;
      });

    this.focus
      .append("g")
      .attr("class", "axis axis--x")
      .style("color", "white")
      .attr("transform", "translate(0," + this.height + ")");
    //.call(d3.axisBottom(this.x));
    // text label for the x axis
    this.svg6
      .append("text")
      /*.attr("transform",
        "translate(" + ((this.width / 2) + 20) + " ," +
        (this.height + this.margin.top + 40) + ")")*/
      .attr("x", this.x(90))
      .attr("y", this.y(-20))
      .style("text-anchor", "middle")
      .style("font", "16px Signify-Relative-Bold")
      //.html("Temperature (Celcius)");
      .html("Temperature (&degC)");

    // Configure the Y Axis
    this.focus
      .append("g")
      .attr("class", "axis axis--y")
      .call(d3.axisLeft(this.y));

    // text label for the y axis
    this.svg6
      .append("text")
      .attr("transform", "rotate(-90)")
      .attr("y", 0 - this.margin.left + 62)
      .attr("x", 0 - this.height / 1.4 + 35)
      .style("text-anchor", "middle")
      .style("font", "16px Signify-Relative-Bold")
      .text("Dim level (%)");

    // add the X gridlines
    this.focus
      .append("g")
      // .attr("class", "grid")
      .attr("transform", "translate(0," + this.height + ")")
      .call(
        this.make_x_gridlines().tickSize(-this.height)

        //(d,i)=>[0,1, 2, 3, 4, 5, 6,7 ,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24][i])
      );

    // add the Y gridlines
    this.focus
      .append("g")
      // .attr("class", "grid")

      .call(this.make_y_gridlines().tickSize(-this.width).tickFormat(""));
  }

  // gridlines in x axis function
  make_x_gridlines() {
    return d3
      .axisBottom(this.x)
      .ticks(17)
      .tickFormat(
        (d, i) =>
          [
            0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120, 130, 140, 150,
            160, 165,
          ][i]
      );

    //tickFormat(x=>`(${x.toFixed(1)})`);
  }
  // gridlines in y axis function
  make_y_gridlines() {
    return d3.axisLeft(this.y).ticks(10);
  }

  public addDrag() {
    const _this = this;
    let selection = this.focus.selectAll("circle").call(
      d3
        .drag()
        .on("start", function (event, d) {
          const control = _this.dragged();
          /*  d["dyanXTime"] = control.x.invert(event.x);
          d["dyanYOutput"] = control.y.invert(event.y);
          const n = selection.nodes();
          const i = n.indexOf(this);
          const m = d3.select(n[i]).node();
          d3.select(m)
            .raise()
            .attr("cx", control.x(d.dyanXTime))
            .attr("cy", control.y(d.dyanYOutput));
          control.focus.select("path").attr("d", control.line); */
        })
        .on("drag", function (event, d) {
          const control = _this.dragged();
          const n = selection.nodes();
          const i = n.indexOf(this);
          if (i >= 0 && i <= 3) {
            control.changedPoint = i;
            const m = d3.select(n[i]).node();

            if (control.changedPoint == 0 && control.graphEnabled) {
              d.dyanXTime = 0;
              d.dyanYOutput = _this.mtpDeratingMax;
            }

            if (
              control.changedPoint == 1 &&
              control.graphEnabled &&
              !_this.philipsLEDChecked
            ) {
              d.dyanXTime = control.x.invert(event.x);
              if (d.dyanXTime < 0) {
                return;
              }
              if (d.dyanXTime > _this.mtpStopMax - _this.tcDeltaValue)
                d.dyanXTime = _this.mtpStopMax - _this.tcDeltaValue;
              d.dyanYOutput = _this.mtpDeratingMax;

              let stopLevelPrev = control.data[2].dyanXTime;
              if (d.dyanXTime > stopLevelPrev - _this.tcDeltaValue) {
                d.dyanXTime = stopLevelPrev - _this.tcDeltaValue;
              }
            } else if (control.changedPoint == 1) {
              let maxXCheck = control.x.invert(event.x);
              let maxYCheck = control.y.invert(event.y);
              if (!_this.philipsLEDChecked) {
                if (maxXCheck > _this.mtpStartMax) {
                  d.dyanXTime = _this.mtpStartMax;
                }
                if (maxYCheck > _this.mtpDeratingMax) {
                  d.dyanYTime = _this.mtpDeratingMax;
                }
              }
            }
            if (
              control.changedPoint == 2 &&
              control.graphEnabled &&
              !_this.philipsLEDChecked
            ) {
              d.dyanXTime = control.x.invert(event.x);
              d.dyanYOutput = control.y.invert(event.y);
              if (d.dyanXTime > _this.mtpStopMax)
                d.dyanXTime = _this.mtpStopMax;
              if (d.dyanYOutput > _this.mtpDeratingMax)
                d.dyanYOutput = _this.mtpDeratingMax;
              if (d.dyanYOutput <= 1) d.dyanYOutput = 1;
              let startLevelPrev = control.data[1].dyanXTime;
              if (d.dyanXTime < startLevelPrev)
                d.dyanXTime = startLevelPrev + _this.tcDeltaValue;

              const drag3Pt = d3.select(n[3]).node();
              d3.select(drag3Pt)
                .attr("cx", control.x(_this.mtpStopMax))
                .attr("cy", control.y(d.dyanYOutput));
              control.focus.select("path").attr("d", control.area);
              control.focus.select("path").attr("d", control.line);

              control.data[3].dyanYOutput = d.dyanYOutput;
            } else if (control.changedPoint == 2 && !_this.philipsLEDChecked) {
              d.dyanXTime = 90;
              //d.dyanYOutput = 100;
            }
            if (
              control.changedPoint == 3 &&
              (control.graphEnabled || control.philipsLEDChecked)
            ) {
              d.dyanXTime = _this.mtpStopMax;
              d.dyanYOutput = control.y.invert(event.y);
              if (d.dyanYOutput > _this.mtpDeratingMax)
                d.dyanYOutput = _this.mtpDeratingMax;
              if (d.dyanYOutput <= 1) d.dyanYOutput = 1;

              const drag2Pt = d3.select(n[2]).node();
              d3.select(drag2Pt)
                .attr("cx", control.x(control.data[2].dyanXTime))
                .attr("cy", control.y(d.dyanYOutput));
              control.focus.select("path").attr("d", control.area);
              control.focus.select("path").attr("d", control.line);
              control.data[2].dyanYOutput = d.dyanYOutput;
            }
            d3.select(m)
              .raise()
              .attr("cx", control.x(d.dyanXTime))
              .attr("cy", control.y(d.dyanYOutput));
            control.focus.select("path").attr("d", control.area);
          }
        })
        .on("end", this.dragended.bind(this))
    );
  }

  dragstarted(d) {}

  public dragged() {
    return this;
  }
  public dragended(d) {
    //console.log("AFter Drag");
    this.apiService.sendMtpGraphdata(this.data);
  }
}
