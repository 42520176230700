import { Component, OnInit } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ReadDeviceMultiDriverSuccessComponent } from "../read-device-multi-driver-success/read-device-multi-driver-success.component";
@Component({
  selector: "app-read-device-multi-driver-next",
  templateUrl: "./read-device-multi-driver-next.component.html",
  styleUrls: ["./read-device-multi-driver-next.component.scss"],
})
export class ReadDeviceMultiDriverNextComponent implements OnInit {
  features: any = ["ALO", "AOC", "CLO", "Luminaire info", "Startup time"];
  constructor(
    public activemodal: NgbActiveModal,
    private modalService: NgbModal
  ) {}

  ngOnInit() {
    setTimeout(() => {
      const modalRef = this.modalService.open(
        ReadDeviceMultiDriverSuccessComponent,
        {
          windowClass: "select-feature-modal",
          backdropClass: "customBackdrop",
        }
      );
    }, 9000);
  }
}
