import {
  Component,
  OnInit,
  Input,
  ChangeDetectorRef,
  ViewRef,
  OnDestroy,
} from "@angular/core";
import { FieldType } from "@ngx-formly/core";
import { ApiService } from "src/app/core-module/services/api.service";
import { restore } from "./../../../../assets/json/restoreTooltip.en";
import { isNullOrUndefined } from "util";
import { Subscription } from "rxjs";

@Component({
  selector: "app-dtl",
  templateUrl: "./dtl.component.html",
  styleUrls: ["./dtl.component.scss"],
})
export class DtlComponent extends FieldType implements OnInit, OnDestroy {
  @Input() field;
  column: any;
  properties: any;
  DtlDimStart: any;
  DtlDimStartMin: any;
  DtlDimStartMax: any;
  DtlDimStartOption;
  DtlDimStop: any;
  DtlDimStopMax: any;
  DtlDimStopMin: any;
  DtlOutputPercentage: any;
  DtlOutputPercentageMax: any;
  DtlOutputPercentageMin: any;
  ngModelval: any = [];
  DtlDimStopVal: number;
  DtlDimStartVal: number;
  DtlOutputPercentageVal: number;
  DtlLampOff: any;
  DtlLampOffMin: any;
  DtlLampOffMax: any;
  DtlLampOffVal: any;
  DtlEnabledval: any;
  dtlDefaultColor: boolean;
  inputError: any = [];
  DtlDimStopOption;
  DtlOutputPercentageOption;
  DtlLampOffOption;
  dtldisbaled: any;
  quickConfigvalue: boolean;
  quickConfigCustomCSS: string;
  setQuickConfig: string;
  DtlLampOffDisabledVal: boolean;
  originalDtlLampOffDisabledVal: boolean;
  DtlLampOffDisbaled: any;
  dtlCreatFile: boolean;
  isEdited: any;
  dtldDataSub: Subscription;
  constructor(private service: ApiService, private ref: ChangeDetectorRef) {
    super();
  }
  setConfigurationData(value, keyVal) {
    let touchDimObj = {};
    var key = keyVal;
    var data = {};
    var addNew = {
      [keyVal]: {},
    };
    data = JSON.parse(localStorage.getItem("configurationData"));
    if (data && !isNullOrUndefined(data["resp"])) {
      touchDimObj = Object.assign({}, value);
      data["resp"][keyVal] = touchDimObj;
    } else {
      data["resp"] = addNew;
      touchDimObj = Object.assign({}, value);
      data["resp"][keyVal] = touchDimObj;
    }
    localStorage.setItem("configurationData", JSON.stringify(data));
  }
  ngOnInit() {
    this.isEdited = false;
    let minMax = [];
    this.dtldDataSub = this.service.getdtlDefaultColor.subscribe((response) => {
      if (response) {
        this.dtlDefaultColor = true;
        this.resetDefaultDtl();
      }
    });
    this.dtldDataSub = this.service.getDtldData.subscribe((data) => {
      if (data) {
        this.ngModelval = data;
        this.DtlDimStartVal = data["DtlDimStartVal"];
        this.DtlDimStopVal = data["DtlDimStopVal"];
        this.DtlOutputPercentageVal = data["DtlOutputPercentageVal"];
        if (data["DtlLampOffVal"]) {
          this.DtlLampOffVal = data["DtlLampOffVal"];
        }
        this.checkdfault();
        this.checkInvalidValues();
      }
    });
    this.column = this.field.requiredProperties;
    this.properties = this.field.properties;
    this.service.dtlInputError(false);
    this.DtlDimStart = this.properties.DtlDimStart;
    this.DtlOutputPercentage = this.properties.DtlOutputPercentage;
    this.DtlDimStop = this.properties.DtlDimStop;
    this.DtlDimStartMin = this.DtlDimStart.minimum;
    this.DtlDimStopMin = this.DtlDimStart.minimum;
    this.DtlLampOffMin = this.DtlDimStart.minimum;
    if (
      this.properties.DtlLampOff &&
      this.properties.DtlLampOff.const === undefined
    ) {
      this.DtlDimStartMax = this.DtlDimStart.maximum;
      this.DtlDimStopMax = this.DtlDimStop.maximum;
      this.field.anyOf.forEach((element) => {
        if (element.properties.DtlLampOff.maximum) {
          this.DtlLampOffMax = element.properties.DtlLampOff.maximum;
        }
      });
    } else {
      this.DtlDimStopMax = this.DtlDimStop.maximum;
      this.DtlDimStartMax = this.DtlDimStart.maximum;
    }
    this.ngModelval["DtlDimStartVal"] = this.DtlDimStartVal =
      this.DtlDimStart.default;
    this.DtlDimStartOption = {
      floor: this.DtlDimStartMin,
      ceil: this.DtlDimStartMax,
    };
    minMax.push({ dtlStart: this.DtlDimStartOption });
    this.DtlOutputPercentageMin = this.DtlOutputPercentage.minimum;
    this.DtlOutputPercentageMax = this.DtlOutputPercentage.maximum;
    this.ngModelval["DtlOutputPercentageVal"] = this.DtlOutputPercentageVal =
      this.DtlOutputPercentage.default;
    this.DtlOutputPercentageOption = {
      floor: this.DtlOutputPercentageMin,
      ceil: this.DtlOutputPercentageMax,
    };
    minMax.push({ dtlPercentage: this.DtlOutputPercentageOption });
    if (
      this.properties.DtlDimStop &&
      this.properties.DtlDimStop.const === undefined
    ) {
      this.ngModelval["DtlDimStopVal"] = this.DtlDimStopVal =
        this.DtlDimStop.default;
      this.DtlDimStopOption = {
        floor: this.DtlDimStopMin,
        ceil: this.DtlDimStopMax,
      };
      minMax.push({ dtlStop: this.DtlDimStopOption });
    }
    if (
      this.properties.DtlLampOff &&
      this.properties.DtlLampOff.const === undefined
    ) {
      this.DtlLampOff = this.properties.DtlLampOff;
      if (
        this.properties.DtlLampOff["$interSchemaPropertyDependency"] !=
        undefined
      ) {
        let minLampOff = this.field.anyOf[0].properties.DtlLampOff.minimum;
        let maxLampOff = this.field.anyOf[0].properties.DtlLampOff.maximum;
        let constLampOff = this.field.anyOf[1].properties.DtlLampOff.const;
        let defaultLampOff = this.DtlLampOff.default;
        if (defaultLampOff < constLampOff) {
          this.ngModelval["DtlLampOffVal"] = this.DtlLampOffVal =
            this.DtlLampOff.default;
          this.originalDtlLampOffDisabledVal =
            this.DtlLampOffDisabledVal =
            this.ngModelval["DtlLampOffDisabled"] =
              false;
          this.DtlLampOffDisbaled = false;
        }
        if (defaultLampOff === constLampOff && maxLampOff < defaultLampOff) {
          this.ngModelval["DtlLampOffVal"] = this.DtlLampOffVal = maxLampOff;
          this.originalDtlLampOffDisabledVal =
            this.DtlLampOffDisabledVal =
            this.ngModelval["DtlLampOffDisabled"] =
              true;
          this.DtlLampOffDisbaled = true;
        }
      } else {
        this.ngModelval["DtlLampOffVal"] = this.DtlLampOffVal =
          this.DtlLampOff.default;
        this.originalDtlLampOffDisabledVal =
          this.DtlLampOffDisabledVal =
          this.ngModelval["DtlLampOffDisabled"] =
            false;
        this.DtlLampOffDisbaled = false;
      }
      this.DtlLampOffOption = {
        floor: this.DtlLampOffMin,
        ceil: this.DtlLampOffMax,
        step: 1,
        minLimit: this.ngModelval["DtlDimStopVal"] + 2,
        maxLimit: this.DtlLampOffMax,
      };
      minMax.push({ dtlLampOff: this.DtlLampOffOption });
      this.DtlDimStopOption = {
        floor: this.DtlDimStartMin,
        ceil: this.DtlDimStopMax,
        step: 1,
        minLimit: this.ngModelval["DtlDimStartVal"] + 5,
        maxLimit: this.DtlLampOffMax - 2,
      };
      this.DtlDimStartOption = {
        floor: this.DtlDimStartMin,
        ceil: this.DtlDimStartMax,
      };
      this.dimStartSlideValuechange(this.ngModelval["DtlDimStartVal"]);
    }
    if (this.properties["DtlEnabled"] && this.column.includes("DtlEnabled")) {
      this.DtlEnabledval = this.properties["DtlEnabled"].default;
      this.ngModelval["DtlEnabled"] = this.properties["DtlEnabled"].default;
      if (this.properties["DtlEnabled"].default === false) {
        this.dtldisbaled = true;
      } else {
        this.dtldisbaled = false;
      }
    }
    this.service.getquickConfig.subscribe((data) => {
      if (data === "quickConfig") {
        this.quickConfigvalue = true;
        this.quickConfigCustomCSS = "quick-custom-inputbox textBoxUnit";
        this.setQuickConfig = "quickConfig";
      } else {
        this.quickConfigvalue = false;
        this.quickConfigCustomCSS = "custom-inputbox";
        this.setQuickConfig = "standard";
      }
      setTimeout(() => {
        if (this.ref && !(this.ref as ViewRef).destroyed) {
          this.ref.detectChanges();
        }
      });
    });
    this.service.sendMinMaxValues(minMax);
    let unSelectedFeat = Array(
      JSON.parse(localStorage.getItem("UnSelectedFeatConfig"))
    );

    let isConfigEdited = false;
    let featIndex: number = 0;
    if (unSelectedFeat[0] != null) {
      unSelectedFeat[0].forEach((element) => {
        if (
          !isNullOrUndefined(element) &&
          !isNullOrUndefined(element["featureName"]) &&
          element["featureName"].toString().toUpperCase() === "DTL"
        ) {
          isConfigEdited = true;
          let DTL: any = element["featureParameters"][0];
          this.addDTLLoadedFeatureReadValues(DTL);
          unSelectedFeat[0].splice(featIndex, 1);
          localStorage.setItem(
            "UnSelectedFeatConfig",
            JSON.stringify(unSelectedFeat[0])
          );
        } else {
          featIndex++;
        }
      });
    }

    if (isConfigEdited) {
    } else {
      setTimeout(() => {
        const dtlLoadedFeature = JSON.parse(
          localStorage.getItem("configurationData")
        );
        if (dtlLoadedFeature) {
          if (
            dtlLoadedFeature.resp &&
            (dtlLoadedFeature.resp.Dtl ||
              dtlLoadedFeature.resp.DTL ||
              dtlLoadedFeature.resp.dtl)
          ) {
            if (dtlLoadedFeature.resp.Dtl) {
              this.addDTLLoadedFeatureReadValues(dtlLoadedFeature.resp.Dtl);
            } else if (dtlLoadedFeature.resp.dtl) {
              this.addDTLLoadedFeatureReadValues(dtlLoadedFeature.resp.dtl);
            } else if (dtlLoadedFeature.resp.DTL) {
              this.addDTLLoadedFeatureReadValues(dtlLoadedFeature.resp.DTL);
            }
          }
        }
      }, 1000);
    }

    setTimeout(() => {
      this.sendDtldata(this.ngModelval);
      this.isEdited = true;
    }, 2000);
  }
  sendDtldata(val) {
    this.service.sendDtldata(val);
    if (this.isEdited) {
      this.setConfigurationData(val, "DTL");
    }
  }

  addDTLLoadedFeatureReadValues(Dtl: any) {
    this.dtldisbaled = false;
    let DtlObj = Dtl;
    var keys = Object.keys(DtlObj);
    var values = Object.values(DtlObj);
    var result = [];
    keys.forEach((key, i) => (result[key] = values[i]));
    this.ngModelval = result;
    if (this.column.includes("DtlEnabled")) {
      this.ngModelval["DtlEnabled"] = this.DtlEnabledval = !isNullOrUndefined(
        result["DtlEnabled"]
      )
        ? result["DtlEnabled"]
        : this.field.anyOf[0].properties.DtlEnabled.const;
      this.dtldisbaled = !this.DtlEnabledval;
    }
    if (
      result["DtlDimStopVal"] != undefined &&
      this.column.includes("DtlDimStop")
    ) {
      this.ngModelval["DtlDimStopVal"] =
        this.ngModelval["DtlDimStop"] =
        this.DtlDimStopVal =
          result["DtlDimStopVal"];
    } else if (
      result["DtlDimStop"] != undefined &&
      this.column.includes("DtlDimStop")
    ) {
      this.ngModelval["DtlDimStopVal"] =
        this.ngModelval["DtlDimStop"] =
        this.DtlDimStopVal =
          result["DtlDimStop"];
    }
    if (
      result["DtlDimStartVal"] != undefined &&
      this.column.includes("DtlDimStart")
    ) {
      this.ngModelval["DtlDimStartVal"] =
        this.ngModelval["DtlDimStart"] =
        this.DtlDimStartVal =
          result["DtlDimStartVal"];
    } else if (
      result["DtlDimStart"] != undefined &&
      this.column.includes("DtlDimStart")
    ) {
      this.ngModelval["DtlDimStartVal"] =
        this.ngModelval["DtlDimStart"] =
        this.DtlDimStartVal =
          result["DtlDimStart"];
    }
    if (
      result["DtlOutputPercentageVal"] != undefined &&
      this.column.includes("DtlOutputPercentage")
    ) {
      this.ngModelval["DtlOutputPercentageVal"] =
        this.ngModelval["DtlOutputPercentage"] =
        this.DtlOutputPercentageVal =
          result["DtlOutputPercentageVal"];
    } else if (
      result["DtlOutputPercentage"] != undefined &&
      this.column.includes("DtlOutputPercentage")
    ) {
      this.ngModelval["DtlOutputPercentageVal"] =
        this.ngModelval["DtlOutputPercentage"] =
        this.DtlOutputPercentageVal =
          result["DtlOutputPercentage"];
    }
    if (
      result["DtlLampOffVal"] != undefined &&
      this.column.includes("DtlLampOff")
    ) {
      this.ngModelval["DtlLampOffVal"] = this.ngModelval["DtlLampOff"] =
        result["DtlLampOffVal"];
      this.DtlLampOffVal = result["DtlLampOffVal"];
    } else if (
      result["DtlLampOff"] != undefined &&
      this.column.includes("DtlLampOff")
    ) {
      if (result["DtlLampOff"] === 200) {
        // this.ngModelval["DtlLampOffVal"] = this.ngModelval["DtlLampOff"] =
        //   this.properties.DtlLampOff.default;
        if (
          this.properties.DtlLampOff.default ===
          this.field.anyOf[1].properties.DtlLampOff.const
        ) {
          this.DtlLampOffVal =
            this.ngModelval["DtlLampOffVal"] =
            this.ngModelval["DtlLampOff"] =
              this.field.anyOf[0].properties.DtlLampOff.maximum;
        } else {
          this.DtlLampOffVal =
            this.ngModelval["DtlLampOffVal"] =
            this.ngModelval["DtlLampOff"] =
              this.properties.DtlLampOff.default;
        }
        this.DtlLampOffDisabledVal =
          this.ngModelval["DtlLampOffDisabled"] =
          result["DtlLampOffDisabled"] =
            true;
      } else {
        this.ngModelval["DtlLampOffVal"] = this.ngModelval["DtlLampOff"] =
          result["DtlLampOff"];
        this.DtlLampOffVal = result["DtlLampOff"];
      }
    }
    this.DtlLampOffDisabledVal = this.ngModelval["DtlLampOffDisabled"] =
      result["DtlLampOffDisabled"];
    this.DtlLampOffDisbaled = result["DtlLampOffDisabled"];
    if (
      result["DtlLampOffDisabled"] === undefined &&
      result["DtlLampOff"] === 200
    ) {
      this.DtlLampOffDisabledVal = this.ngModelval["DtlLampOffDisabled"] = true;
      this.DtlLampOffDisbaled = true;
      this.lampOffDisabled(this.DtlLampOffDisbaled);
    } else {
      this.DtlLampOffDisabledVal = this.ngModelval["DtlLampOffDisabled"] =
        result["DtlLampOffDisabled"];
      this.DtlLampOffDisbaled = result["DtlLampOffDisabled"];
      if (result["DtlLampOffDisabled"] === undefined) {
        this.DtlLampOffDisabledVal = this.ngModelval["DtlLampOffDisabled"] =
          false;
        this.DtlLampOffDisbaled = false;
        this.lampOffDisabled(false);
      }
    }
    this.checkdfault();
    var ngModelkeys = Object.keys(this.ngModelval);
    var ngModelvalues = Object.values(this.ngModelval);
    ngModelkeys.forEach((key, i) => {
      this.textValChanged(ngModelvalues[i], key);
    });
    this.sendDtldata(this.ngModelval);
  }
  rSetChanged(val) {
    for (var i = 0; i < this.column.length; i++) {
      let type = this.column[i];
      if (
        type === "DtlEnabled" &&
        this.ngModelval[type] != this.properties[type]["default"]
      ) {
        this.dtlDefaultColor = false;
      }
    }
    this.dtldisbaled = !val;
    this.checkdfault();
  }

  dimStartSlideValuechange(value) {
    if (value >= this.DtlDimStartMin && value <= this.DtlDimStartMax) {
      this.ngModelval["DtlDimStartVal"] = value;
      this.inputError["DtlDimStart"] = false;
      if (this.DtlLampOff) {
        this.DtlDimStopOption = {
          floor: this.DtlDimStartMin,
          ceil: this.DtlDimStopMax,
          step: 1,
          minLimit: this.ngModelval["DtlDimStartVal"] + 5,
          maxLimit: this.DtlLampOffMax - 2,
        };
      } else {
        this.DtlDimStopOption = {
          floor: this.DtlDimStartMin,
          ceil: this.DtlDimStopMax,
          step: 1,
          minLimit: this.ngModelval["DtlDimStartVal"] + 5,
          maxLimit: this.DtlDimStopMax,
        };
      }
      if (
        value >= this.ngModelval["DtlDimStopVal"] ||
        value + 5 >= this.ngModelval["DtlDimStopVal"]
      ) {
        this.ngModelval["DtlDimStopVal"] = value + 5;
        this.dimStopSlideValuechange(this.ngModelval["DtlDimStopVal"]);
      }
      this.checkInvalidValues();
      this.sendDtldata(this.ngModelval);
    } else {
      this.inputError["DtlDimStart"] = true;
      this.dtlCreatFile = true;
    }
  }

  dimStopSlideValuechange(value) {
    if (
      parseInt(value) >= this.DtlDimStopMin &&
      parseInt(value) <= this.DtlDimStopMax &&
      parseInt(value) >= parseInt(this.ngModelval["DtlDimStartVal"]) + 5
    ) {
      this.ngModelval["DtlDimStopVal"] = parseInt(value);
      this.inputError["DtlDimStop"] = false;
      if (this.DtlLampOff) {
        this.DtlLampOffOption = {
          floor: parseInt(this.DtlDimStartMin),
          ceil: parseInt(this.DtlLampOffMax),
          step: 1,
          minLimit: parseInt(this.ngModelval["DtlDimStopVal"]) + 2,
          maxLimit: parseInt(this.DtlLampOffMax),
        };
        if (
          parseInt(value) >= this.ngModelval["DtlLampOffVal"] ||
          parseInt(value) + 2 >= this.ngModelval["DtlLampOffVal"]
        ) {
          this.ngModelval["DtlLampOffVal"] = parseInt(value) + 2;
          this.DtlLampOffSlideValuechange(this.ngModelval["DtlLampOffVal"]);
        }
      }
      this.checkInvalidValues();
      this.sendDtldata(this.ngModelval);
    }
  }

  dtlStopsliderChangeMinLim(value) {}

  dtlShutDownsliderEvent() {}

  dtlStartsliderChangeMinLim(value) {}
  DtlLampOffSlideValuechange(value) {
    if (
      value >= this.DtlLampOffMin &&
      value <= this.DtlLampOffMax &&
      value >= parseInt(this.ngModelval["DtlDimStopVal"]) + 2
    ) {
      this.ngModelval["DtlLampOffVal"] = value;
      this.inputError["DtlLampOff"] = false;
    } else {
      this.ngModelval["DtlLampOffVal"] = value;
      this.inputError["DtlLampOff"] = true;
    }
    this.checkInvalidValues();
    this.sendDtldata(this.ngModelval);
  }

  DtlOutputPercentageSlideValuechange(value) {
    this.inputError["DtlOutputPercentage"] = false;
    if (
      value >= this.DtlOutputPercentageMin &&
      this.inputError["DtlOutputPercentage"] === false
    ) {
      this.ngModelval["DtlOutputPercentageVal"] = value;
      // this.dtlStopminValcalculate();
      this.DtlOutputPercentageVal = value;
      this.sendDtldata(this.ngModelval);
      this.checkdfault();
      this.checkInvalidValues();
    } else if (
      value < this.DtlOutputPercentageMin ||
      value > this.DtlOutputPercentageMax
    ) {
      this.inputError["DtlOutputPercentage"] = true;
      this.ngModelval["DtlOutputPercentageVal"] = this.DtlOutputPercentageVal =
        value;
      this.sendDtldata(this.ngModelval);
      this.checkdfault();
      this.checkInvalidValues();
    }
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  textValChanged(value, colName) {
    var emptyVal = value;
    // value = parseInt(value);
    // if (isNaN(value) || value.length === 0) {
    //   value = 0;
    // }
    this.dtlDefaultColor = false;
    this.inputError[colName] = false;
    this.dtlCreatFile = false;
    if (colName == "DtlDimStart" && this.column.includes("DtlDimStart")) {
      if (emptyVal === "") {
        this.inputError[colName] = true;
        this.dtlCreatFile = true;
        this.ngModelval["DtlDimStartVal"] = emptyVal;
      }
      if (value < this.DtlDimStartMin || value > this.DtlDimStartMax) {
        this.ngModelval["DtlDimStartVal"] = value;
        this.inputError[colName] = true;
        this.dtlCreatFile = true;
      } else {
        this.DtlDimStartVal = this.ngModelval["DtlDimStartVal"] = value;
        if (this.DtlLampOff) {
          this.DtlDimStopOption = {
            floor: this.DtlDimStartMin,
            ceil: this.DtlDimStopMax,
            step: 1,
            minLimit: parseInt(this.ngModelval["DtlDimStartVal"]) + 5,
            maxLimit: parseInt(this.DtlLampOffMax) - 2,
          };
        } else {
          this.DtlDimStopOption = {
            floor: this.DtlDimStartMin,
            ceil: this.DtlDimStopMax,
            step: 1,
            minLimit: parseInt(this.ngModelval["DtlDimStartVal"]) + 5,
            maxLimit: this.DtlDimStopMax,
          };
        }
        if (
          value >= this.ngModelval["DtlDimStopVal"] ||
          parseInt(value) + 5 >= this.ngModelval["DtlDimStopVal"]
        ) {
          this.ngModelval["DtlDimStopVal"] = parseInt(value) + 5;
          this.inputError["DtlLampOff"] = false;
          this.inputError["DtlDimStop"] = false;
          this.dimStopSlideValuechange(this.ngModelval["DtlDimStopVal"]);
          this.DtlLampOffSlideValuechange(this.ngModelval["DtlLampOffVal"]);
        }
      }
    } else if (colName == "DtlDimStop" && this.column.includes("DtlDimStop")) {
      // if (emptyVal === "") {
      //   this.inputError[colName] = true;
      //   this.dtlCreatFile = true;
      //   this.ngModelval["DtlDimStopVal"] = emptyVal;
      // }
      if (
        value < this.DtlDimStopMin ||
        value > this.DtlDimStopMax ||
        value < parseInt(this.ngModelval["DtlDimStartVal"]) + 5 ||
        emptyVal === ""
      ) {
        this.inputError[colName] = true;
        this.dtlCreatFile = true;
        this.ngModelval["DtlDimStopVal"] = value;
      } else {
        this.ngModelval["DtlDimStopVal"] = value;
        this.DtlDimStopVal = value;
        this.inputError[colName] = false;
        this.dtlCreatFile = false;
        if (this.DtlLampOff) {
          this.DtlLampOffOption = {
            floor: this.DtlDimStartMin,
            ceil: this.DtlLampOffMax,
            step: 1,
            minLimit: parseInt(this.ngModelval["DtlDimStopVal"]) + 2,
            maxLimit: this.DtlLampOffMax,
          };
        }
        if (
          this.ngModelval["DtlLampOffDisabled"] === false &&
          (value >= this.ngModelval["DtlLampOffVal"] ||
            parseInt(value) + 2 >= this.ngModelval["DtlLampOffVal"])
        ) {
          this.ngModelval["DtlLampOffVal"] = parseInt(value) + 2;
          this.inputError["DtlLampOff"] = false;
          this.DtlLampOffSlideValuechange(this.ngModelval["DtlLampOffVal"]);
        }
      }
    } else if (colName == "DtlOutputPercentage") {
      if (Number.isNaN(value)) {
        this.inputError[colName] = true;
        this.dtlCreatFile = true;
      }
      if (
        value < this.DtlOutputPercentageMin ||
        value > this.DtlOutputPercentageMax
      ) {
        this.inputError[colName] = true;
        this.DtlOutputPercentageOption = {
          floor: this.DtlOutputPercentage.minimum,
          ceil: this.DtlOutputPercentageMax,
        };
        this.ngModelval["DtlOutputPercentageVal"] =
          this.DtlOutputPercentageVal = value;
      } else {
        this.DtlOutputPercentageVal = value;
        this.ngModelval["DtlOutputPercentageVal"] = value;
        this.DtlOutputPercentageOption = {
          floor: this.DtlOutputPercentageMin,
          ceil: this.DtlOutputPercentageMax,
        };
      }
    } else if (colName == "DtlLampOff" && this.column.includes("DtlLampOff")) {
      if (emptyVal === "") {
        this.inputError[colName] = true;
        this.dtlCreatFile = true;
        this.ngModelval["DtlLampOffVal"] = emptyVal;
      }
      if (
        value > this.DtlLampOffMax ||
        value < parseInt(this.ngModelval["DtlDimStopVal"]) + 2
      ) {
        this.inputError[colName] = true;
        this.dtlCreatFile = true;
        this.ngModelval["DtlLampOffVal"] = value;
      } else {
        this.ngModelval["DtlLampOffVal"] = value;
        this.DtlLampOffVal = value;
      }
    } else {
    }
    this.checkdfault();

    for (var type in this.inputError) {
      if (this.inputError[type]) {
        this.dtlCreatFile = true;
      }
    }
    this.checkInvalidValues();
    this.service.dtlEditedVal(this.dtlDefaultColor);
    this.service.dtlInputError(this.dtlCreatFile);
    this.sendDtldata(this.ngModelval);
  }

  sliderEvent() {
    this.checkdfault();
  }

  checkInvalidValues() {
    this.dtlCreatFile = false;
    for (var type in this.inputError) {
      if (this.inputError[type] && this.column.includes(type)) {
        this.dtlCreatFile = true;
      }
    }
    this.service.dtlInputError(this.dtlCreatFile);
  }

  resetDefaultDtl() {
    this.dtlDefaultColor = true;
    if (this.column.includes("DtlEnabled") && this.properties["DtlEnabled"]) {
      if (this.properties["DtlEnabled"].default === false) {
        this.ngModelval["DtlEnabled"] = false;
        this.dtldisbaled = true;
      } else {
        this.ngModelval["DtlEnabled"] = true;
        this.dtldisbaled = false;
      }
    }
    if (this.column.includes("DtlLampOff") && this.properties.DtlLampOff) {
      if (
        this.properties.DtlLampOff["$interSchemaPropertyDependency"] !=
        undefined
      ) {
        let minLampOff = this.field.anyOf[0].properties.DtlLampOff.minimum;
        let maxLampOff = this.field.anyOf[0].properties.DtlLampOff.maximum;
        let constLampOff = this.field.anyOf[1].properties.DtlLampOff.const;
        let defaultLampOff = this.DtlLampOff.default;
        if (defaultLampOff < constLampOff) {
          this.ngModelval["DtlLampOffVal"] =
            this.ngModelval["DtlLampOff"] =
            this.DtlLampOffVal =
              this.DtlLampOff.default;
          this.DtlLampOffDisabledVal = this.ngModelval["DtlLampOffDisabled"] =
            false;
          this.DtlLampOffDisbaled = false;
        }
        if (defaultLampOff === constLampOff && maxLampOff < defaultLampOff) {
          this.ngModelval["DtlLampOffVal"] =
            this.ngModelval["DtlLampOff"] =
            this.DtlLampOffVal =
              maxLampOff;
          this.DtlLampOffDisabledVal = this.ngModelval["DtlLampOffDisabled"] =
            true;
          this.DtlLampOffDisbaled = true;
        }
      } else {
        this.ngModelval["DtlLampOffVal"] =
          this.ngModelval["DtlLampOff"] =
          this.DtlLampOffVal =
            this.properties.DtlLampOff.default
              ? this.properties.DtlLampOff.default
              : this.properties.DtlLampOff.const;
        this.DtlLampOffDisabledVal = this.ngModelval["DtlLampOffDisabled"] =
          false;
        this.DtlLampOffDisbaled = false;
      }

      this.DtlLampOffOption = {
        floor: this.DtlLampOffMin,
        ceil: this.DtlLampOffMax,
        step: 1,
        minLimit: this.ngModelval["DtlDimStopVal"] + 2,
        maxLimit: this.DtlLampOffMax,
      };
    }
    if (this.column.includes("DtlDimStop") && this.properties.DtlDimStop) {
      this.ngModelval["DtlDimStopVal"] =
        this.ngModelval["DtlDimStop"] =
        this.DtlDimStopVal =
          this.DtlDimStop.default;
    }
    if (
      this.column.includes("DtlOutputPercentage") &&
      this.properties.DtlOutputPercentage
    ) {
      this.ngModelval["DtlOutputPercentageVal"] =
        this.ngModelval["DtlOutputPercentage"] =
        this.DtlOutputPercentageVal =
          this.DtlOutputPercentage.default;
    }
    if (this.column.includes("DtlDimStart") && this.properties.DtlDimStart) {
      this.ngModelval["DtlDimStartVal"] =
        this.ngModelval["DtlDimStart"] =
        this.DtlDimStartVal =
          this.DtlDimStart.default;
    }
    for (var type in this.inputError) {
      this.inputError[type] = false;
    }
    this.service.dtlInputError(false);
    this.dtlCreatFile = false;
    //this.service.dtlEditedVal(this.dtlDefaultColor);
    this.service.dtlInputError(this.dtlCreatFile);
    this.sendDtldata(this.ngModelval);
  }

  checkdfault() {
    var newtype;
    this.dtlDefaultColor = true;
    var keys = Object.keys(this.ngModelval);
    var values = Object.values(this.ngModelval);
    var result = [];
    result = keys.map((key, i) => {
      return {
        key: key,
        value: values[i],
      };
    });
    if (!isNullOrUndefined(this.properties)) {
      for (let i = 0; i < result.length; i++) {
        if (result[i]["key"]) {
          newtype = result[i]["key"].replace("Val", "");
          let index = result.indexOf(newtype);
          if (index > -1) {
            result.splice(index, 0);
          }
          if (
            result[i]["key"] === "DtlLampOffVal" ||
            result[i]["key"] === "DtlLampOff"
          ) {
            let dtlLampOffDefaultTemp: number = 0;
            if (
              !isNullOrUndefined(this.properties) &&
              this.properties.DtlLampOff &&
              this.properties.DtlLampOff["$interSchemaPropertyDependency"] !=
                undefined
            ) {
              let minLampOff =
                this.field.anyOf[0].properties.DtlLampOff.minimum;
              let maxLampOff =
                this.field.anyOf[0].properties.DtlLampOff.maximum;
              let constLampOff =
                this.field.anyOf[1].properties.DtlLampOff.const;
              let defaultLampOff = this.DtlLampOff.default;
              if (defaultLampOff < constLampOff) {
                dtlLampOffDefaultTemp = this.DtlLampOff.default;
              }
              if (
                defaultLampOff === constLampOff &&
                maxLampOff < defaultLampOff
              ) {
                dtlLampOffDefaultTemp = maxLampOff;
              }
              if (this.ngModelval["DtlLampOffDisabled"] === false) {
                dtlLampOffDefaultTemp = this.DtlLampOff.default;
              } else {
                dtlLampOffDefaultTemp = this.DtlLampOff.default - 1;
              }
              if (
                this.properties.DtlLampOff.default ===
                this.field.anyOf[1].properties.DtlLampOff.const
              ) {
                dtlLampOffDefaultTemp =
                  this.field.anyOf[0].properties.DtlLampOff.maximum;
              } else {
                dtlLampOffDefaultTemp = this.properties.DtlLampOff.default;
              }
              if (
                !isNullOrUndefined(
                  result[i]["value"] && dtlLampOffDefaultTemp
                ) &&
                result[i]["value"] != dtlLampOffDefaultTemp
              ) {
                this.dtlDefaultColor = false;
              }
            } else {
              // let dtlLampOffNoDep =
              //   !isNullOrUndefined(this.properties) &&
              //   this.properties != "" &&
              //   this.properties.DtlLampOff &&
              //   this.properties.DtlLampOff.default
              //     ? this.properties.DtlLampOff.default
              //     : !isNullOrUndefined(this.properties) &&
              //       this.properties.DtlLampOff &&
              //       this.properties.DtlLampOff.const
              //     ? this.properties.DtlLampOff.const
              //     : null;
              if (
                !isNullOrUndefined(this.properties) &&
                this.properties != "" &&
                this.properties.DtlLampOff &&
                this.properties.DtlLampOff.default &&
                this.properties.DtlLampOff.default ===
                  this.field.anyOf[1].properties.DtlLampOff.const
              ) {
                var dtlLampOffNoDep =
                  this.field.anyOf[0].properties.DtlLampOff.maximum;
              } else if (
                !isNullOrUndefined(this.properties) &&
                this.properties.DtlLampOff &&
                this.properties.DtlLampOff.const
              ) {
                var dtlLampOffNoDep = this.properties.DtlLampOff.const;
              } else if (
                !isNullOrUndefined(this.properties) &&
                this.properties.DtlLampOff &&
                this.properties.DtlLampOff.default
              ) {
                var dtlLampOffNoDep = this.properties.DtlLampOff.default;
              }
              if (
                !isNullOrUndefined(dtlLampOffNoDep && result[i]["value"]) &&
                result[i]["value"] != dtlLampOffNoDep
              ) {
                this.dtlDefaultColor = false;
              }
            }
          } else {
            if (
              !isNullOrUndefined(result[i]["value"]) &&
              result[i]["key"] === "DtlEnabled" &&
              (result[i]["value"] === true || result[i]["value"] === false)
            ) {
              let dtlEnableDefaultVal;
              if (
                !isNullOrUndefined(
                  this.properties[result[i]["key"]] &&
                    this.properties[result[i]["key"]]["default"]
                ) &&
                this.properties[result[i]["key"]]["default"] === false
              ) {
                dtlEnableDefaultVal = false;
              } else if (
                !isNullOrUndefined(
                  this.properties[result[i]["key"]] &&
                    this.properties[result[i]["key"]]["default"]
                ) &&
                this.properties[result[i]["key"]]["default"] === true
              ) {
                dtlEnableDefaultVal = true;
              } else if (
                !isNullOrUndefined(
                  this.properties[result[i]["key"]] &&
                    this.properties[result[i]["key"]]["default"]
                ) &&
                this.properties[result[i]["key"]]["const"] === false
              ) {
                dtlEnableDefaultVal = false;
              } else {
                dtlEnableDefaultVal = true;
              }
              if (
                !isNullOrUndefined(dtlEnableDefaultVal && result[i]["value"]) &&
                result[i]["value"] != dtlEnableDefaultVal
              ) {
                this.dtlDefaultColor = false;
              }
            } else if (
              result[i]["key"] === "DtlDimStopVal" &&
              this.properties[newtype] &&
              this.properties[newtype]["default"]
            ) {
              let val = this.properties[newtype]["default"]
                ? this.properties[newtype]["default"]
                : this.properties[newtype]["const"];
              if (
                !isNullOrUndefined(result[i]["value"] && val) &&
                result[i]["value"] != val
              ) {
                this.dtlDefaultColor = false;
              }
            } else if (
              result[i]["key"] === "DtlLampOffDisabled" &&
              result[i]["value"] != this.originalDtlLampOffDisabledVal
            ) {
              this.dtlDefaultColor = false;
            } else if (
              !isNullOrUndefined(this.properties && result[i]["value"]) &&
              this.properties != "" &&
              result[i]["key"] != "DtlDimStopVal" &&
              result[i]["key"] != "DtlLampOffDisabled" &&
              result[i]["value"] !=
                (!isNullOrUndefined(this.properties[newtype]) &&
                this.properties[newtype]["default"]
                  ? this.properties[newtype]["default"]
                  : this.properties[newtype] &&
                    this.properties[newtype]["const"]
                  ? this.properties[newtype]["const"]
                  : null)
            ) {
              this.dtlDefaultColor = false;
            }
          }
        }
      }
    }
    this.service.dtlEditedVal(this.dtlDefaultColor);
  }

  dtlStopminValcalculate() {
    this.DtlDimStopMin = this.DtlDimStartVal + 5;
    if (this.DtlDimStopVal < this.DtlDimStopMin) {
      this.ngModelval["DtlDimStopVal"] = this.DtlDimStopVal =
        this.DtlDimStopMin;
    }
    this.DtlDimStopOption = {
      floor: this.DtlDimStopMin,
      ceil: this.DtlDimStopMax,
    };
    this.dtlLampOffpminValcalculate();
  }

  lampOffDisabled(value) {
    this.DtlLampOffDisbaled = value;
    if (value == true) {
      var dtlDefaultColor = false;
      if (
        !isNullOrUndefined(this.field.anyOf && this.field.anyOf[1]) &&
        this.properties.DtlLampOff.default ===
          this.field.anyOf[1].properties.DtlLampOff.const
      ) {
        // var dtlDefaultColor = true;
      }
      if (this.column.includes("DtlLampOff") && this.properties.DtlLampOff) {
        if (
          this.properties.DtlLampOff["$interSchemaPropertyDependency"] !=
          undefined
        ) {
          let minLampOff = this.field.anyOf[0].properties.DtlLampOff.minimum;
          let maxLampOff = this.field.anyOf[0].properties.DtlLampOff.maximum;
          let constLampOff = this.field.anyOf[1].properties.DtlLampOff.const;
          let defaultLampOff = this.DtlLampOff.default;
          if (defaultLampOff < constLampOff) {
            this.ngModelval["DtlLampOffVal"] = this.DtlLampOffVal =
              this.DtlLampOff.default;
          }
          if (defaultLampOff === constLampOff && maxLampOff < defaultLampOff) {
            this.ngModelval["DtlLampOffVal"] = this.DtlLampOffVal = maxLampOff;
          }
        } else {
          if (
            this.properties.DtlLampOff.default ===
            this.field.anyOf[1].properties.DtlLampOff.const
          ) {
            this.DtlLampOffVal =
              this.ngModelval["DtlLampOffVal"] =
              this.ngModelval["DtlLampOff"] =
                this.field.anyOf[0].properties.DtlLampOff.maximum;
          } else {
            this.DtlLampOffVal =
              this.ngModelval["DtlLampOffVal"] =
              this.ngModelval["DtlLampOff"] =
                this.properties.DtlLampOff.default;
          }
          // this.ngModelval["DtlLampOffVal"] = this.DtlLampOffVal = this
          //   .properties.DtlLampOff.default
          //   ? this.properties.DtlLampOff.default
          //   : this.properties.DtlLampOff.const;
        }
      }
    } else {
      //var dtlDefaultColor = false;
      if (
        !isNullOrUndefined(this.field.anyOf && this.field.anyOf[0]) &&
        this.properties.DtlLampOff.default ===
          this.field.anyOf[0].properties.DtlLampOff.maximum
      ) {
        //var dtlDefaultColor = true;
      }
      if (
        this.ngModelval["DtlLampOffVal"] <=
        this.ngModelval["DtlDimStopVal"] + 2
      ) {
        this.DtlLampOffSlideValuechange(this.ngModelval["DtlDimStopVal"] + 2);
      }
    }
    this.checkdfault();
    this.sendDtldata(this.ngModelval);

    //this.service.setDtlDefaultColor(dtlDefaultColor);
    //this.checkdfault();
  }

  dtlLampOffpminValcalculate() {
    this.DtlLampOffMin = this.DtlDimStopVal + 2;
    if (this.DtlLampOffVal < this.DtlLampOffMin) {
      this.ngModelval["DtlLampOffVal"] = this.DtlLampOffVal =
        this.DtlLampOffMin;
    }
    this.DtlLampOffOption = {
      floor: this.DtlLampOffMin,
      ceil: this.DtlLampOffMax,
    };
  }

  ngOnDestroy() {
    this.service.setDtlDefaultColor("");
    this.ngModelval = [];
    this.service.sendDtldata(null);
    //this.sendDtldata("");
    this.properties = "";
    if (this.dtldDataSub) {
      this.dtldDataSub.unsubscribe();
    }
  }
}
