import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, forkJoin, throwError } from 'rxjs';
import { catchError, flatMap, switchMap, takeUntil } from 'rxjs/operators';
import { ApiService } from 'src/app/core-module/services/api.service';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'app-read-device-selection-popup',
  templateUrl: './read-device-selection-popup.component.html',
  styleUrls: ['./read-device-selection-popup.component.scss']
})
export class ReadDeviceSelectionPopupComponent implements OnInit, OnDestroy {

  @Input() public readerName;
  @Input() public clickEvent;
  @Input() public componentFrom;
  nameR: any = [];
  selectedReader: any = 1;
  firsOption: any;
  readreDataUrl = '/device/v1/NFC/LCN9600/dummy/memorybank/114';
  sendReaderInfo;
  readerDevicesForm: FormGroup;
  readersInfo: Array<any>;
  reader = "dali";
  readerConnected: boolean = false
  readerBaseUrl: any[];
  isDcsConnected: boolean = true;
  readerListUrl = "/peripheral/v1/types";
  readingStatus;
  readerData;
  isDaliSupported: boolean = false;
  pathVal: string;
  unsubscribe = new Subject<any>();
  statusCallDataSwVersion:any;
  statusCallData;
  latestDcsVersion: any;
  isReadDevice: boolean = false;
  loggedIn:boolean;
  constructor(public activemodal: NgbActiveModal, private service: ApiService, private fb: FormBuilder,
    private apiService: ApiService) { }

  ngOnInit() {
    // setTimeout(() => {
    //   // this.nameR = this.readerName;
    //   // this.slectedReader = this.nameR[0];
    // }, 1000);
    this.pathVal = this.apiService.getImageUrl(window.location.hostname);
    this.readerDevicesForm = this.fb.group({
      readerType: new FormControl(""),
      simpleSetDevices: new FormControl(""),
      daliDevices: new FormControl(""),
    });
    this.getReaders();
    this.refreshReadersList();
  }

  getReaders() {
    if (localStorage.getItem("readerDevices") &&
      localStorage.getItem("readerDevices") != 'undefined') {
      this.readersInfo = JSON.parse(
        localStorage.getItem("readerDevices")
      );
      if (localStorage.getItem("detectedReaderInfo") &&
        localStorage.getItem("detectedReaderInfo") != 'undefined') {

        let reader = JSON.parse(
          localStorage.getItem("detectedReaderInfo")
        );

        this.readerDevicesForm.controls.readerType.setValue('simpleSet');
        this.readerDevicesForm.controls.simpleSetDevices.setValue(reader.selectedReader.peripheralType+'-'+reader.selectedReader.peripheralId);
        this.readerConnected = reader.readerConnected;
      } else {
      }
    } else {
      this.readersInfo = [];
      this.readerConnected = false;
      this.readerDevicesForm.controls.readerType.setValue('simpleSet');
      this.readerDevicesForm.controls.simpleSetDevices.setValue('select');

    }
    this.readerDevicesForm.controls.daliDevices.setValue('select');

  }

  refreshReadersList() {
    this.apiService.getBaseUrl();
    this.apiService.getReadData
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((url) => {
        if (url.length > 0) {
          this.readerBaseUrl = url;
          this.unsubscribe.next();
          let status = this.getDeviceReader();
          let readerSelection = this.getReaderList();

          status.pipe(
            flatMap(data => {
              this.statusCallData = data;
              this.statusCallDataSwVersion =
              this.statusCallData.serverSwVersion
                .toString()
                .split(".")
                .join("");
            this.latestDcsVersion = JSON.parse(
              localStorage.getItem("latestDcsVersion")
            );

            if (
              !isNullOrUndefined(this.latestDcsVersion) &&
              !isNullOrUndefined(this.statusCallDataSwVersion)
            ) {
              if (
                parseInt(this.latestDcsVersion) >
                parseInt(this.statusCallDataSwVersion)
              ) {
                this.isReadDevice = false;
                this.loggedIn = true;
              } else {
                this.isReadDevice = true;
              }
                 console.log(this.isReadDevice)
              this.apiService.sendReadDevice(this.isReadDevice);
            }
              return readerSelection.pipe(
                catchError(err => {
                  // do stuff with err from API1 request
                  return throwError(err);
                })
              )
            }),
            catchError(err => {
              this.apiService.setReadDeviceStatusViaSimpleSet(false);
              return throwError(err);

            })
          
          
          ).subscribe(results => {
              let readerData = results;
              localStorage.setItem(
                "readerDevices",
                JSON.stringify(readerData)
              );

              let detectedReader = {
                readerType: 'SimpleSet',
                selectedReader: readerData[0],
                readerConnected: true,
                isDcsConnected: true
              }

              localStorage.setItem(
                "detectedReaderInfo",
                JSON.stringify(detectedReader)
              );
              this.apiService.setReadDeviceStatusViaSimpleSet(true);
              this.getReaders();

            },
            error => {
              localStorage.removeItem("readerDevices");
              if (
                error &&
                !isNullOrUndefined(error.target && error.target.url) &&
                error.target.url === "http://localhost:2020/server/v1/status"
              ) {
                this.isDcsConnected = false;
              } else {
                this.isDcsConnected = true;
              }
              let detectedReader = {
                readerType: 'SimpleSet',
                selectedReader: null,
                readerConnected: false,
                isDcsConnected: this.isDcsConnected
              }

              localStorage.setItem(
                "detectedReaderInfo",
                JSON.stringify(detectedReader)
              );

              this.getReaders();

            }
          )
        }
      });
  }

  getDeviceReader() {
    return this.apiService.getServerStatus(this.readerBaseUrl);
  }

  getReaderList() {
    return this.apiService.apiCall("get", this.readerBaseUrl + this.readerListUrl);
  }

  onChange(value) {
    this.selectedReader = value;
  }

  selectreader() {
   

    
    if (this.readersInfo.length > 0) {
      let reader = JSON.parse(
        localStorage.getItem("detectedReaderInfo")
      );

      let selevtedReadArr:any[] = [];
       selevtedReadArr=this.readersInfo.filter((item)=>{
       return item['peripheralType']+'-'+item['peripheralId']===this.readerDevicesForm.controls['simpleSetDevices'].value
      })

      reader['selectedReader'] = selevtedReadArr[0];
      localStorage.setItem("detectedReaderInfo", JSON.stringify(reader));
      localStorage.setItem('readerInfo', JSON.stringify(selevtedReadArr));
      if (!this.clickEvent) {
        this.service.readerInfo(selevtedReadArr[0]);
      }

    }
  
    this.activemodal.close();

  }

  cancel() {
    this.activemodal.close();
 
  if (this.componentFrom==="ReadDevice") {
    this.service.modalClose('close');
  }
   
   // 
  }

  ngOnDestroy() {
    this.service.modalClose('');
  }



}
