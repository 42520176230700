import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/core-module/services/api.service';

@Component({
  selector: 'app-reader-selection-popup',
  templateUrl: './reader-selection-popup.component.html',
  styleUrls: ['./reader-selection-popup.component.scss']
})
export class ReaderSelectionPopupComponent implements OnInit, OnDestroy {

  @Input() public readerName;
  nameR: any=[];
  slectedReader: any;
  firsOption: any;
  readreDataUrl = '/device/v1/NFC/LCN9600/dummy/memorybank/114';
  sendReaderInfo;
  constructor(public activemodal: NgbActiveModal, private service: ApiService) { }

  ngOnInit() {
    setTimeout(() => {
      this.nameR = this.readerName;
      this.slectedReader = this.nameR[0];
    }, 1000);
   
  }

  onChange(value) {
    this.slectedReader = this.nameR[value];
  }

  selectreader() {

    let selevtedReadArr:any[] = [];

    selevtedReadArr.push(this.slectedReader)
    localStorage.setItem('readerInfo', JSON.stringify(selevtedReadArr));
    this.service.readerInfo(this.slectedReader);
    this.activemodal.close();
  }

  cancel() {
    this.activemodal.close();
    this.service.modalClose('close');
  }

  ngOnDestroy() {
    this.service.modalClose('');
  }

}
