import { Component, OnInit, Input } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ApiService } from "../core-module/services/api.service";

@Component({
  selector: "app-error",
  templateUrl: "./error.component.html",
  styleUrls: ["./error.component.scss"],
})
export class ErrorComponent implements OnInit {
  constructor(
    private activeModal: NgbActiveModal,
    private service: ApiService,
    private modalService: NgbModal
  ) { }
  @Input() public errorDescription: any;
  @Input() public errorName: any;
  @Input() public showCancelButton: boolean = false;
  ngOnInit() { }
}
