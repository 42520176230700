import { Component, Inject, NgZone, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { DOCUMENT } from "@angular/common";
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  getCloseHeaderPopUpModalVal: boolean;
  count: number = 0;
  constructor(
    public translate: TranslateService,
    private route: ActivatedRoute,
    private _router: Router,
    private zone: NgZone,
    @Inject(DOCUMENT) private document: Document
  ) {
    //translate.setDefaultLang("en");
  }
  title = "MultiOne";
  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      if (params["lang"]) {
        var lang = params["lang"];
      }
      if (lang == "nl") {
        this.translate.use("nl");
      }
    });
  }
}
