import {
  Component,
  OnInit,
  Input,
  ChangeDetectorRef,
  ViewRef,
} from "@angular/core";
import { isNullOrUndefined } from "util";
import { FieldType } from "@ngx-formly/core";
import { ApiService } from "src/app/core-module/services/api.service";
@Component({
  selector: "app-dali-m",
  templateUrl: "./dali-m.component.html",
  styleUrls: ["./dali-m.component.scss"],
})
export class DaliMComponent extends FieldType implements OnInit {
  @Input() field;
  column: any;
  properties: any;
  ngModelval: any = [];
  JSONOb: any = [];
  inputError: any = [];
  quickConfigCustomCSS;
  diia253maintenanceDefaultColor;
  setQuickConfig;
  isEdited: any;
  RatedLifeValueMin: any;
  RatedLifeValueMax: any;
  RatedLife: any;
  ReferenceTemperatureValueMin: any;
  ReferenceTemperatureValueMax: any;
  reqiredProperties: any;
  maskMaximum: any = {
    RatedStarts: 65535,
    RatedLife: 255,
    ReferenceTemperature: 195,
  };
  ReferenceTemperature: any;
  RatedStarts: any;
  RatedStartsValueMin: any;
  RatedStartsValueMax: any;
  fileResponse: any;
  diia253maintenanceCreateFile: boolean;
  constructor(private apiService: ApiService, private ref: ChangeDetectorRef) {
    super();
  }
  valueChanged(fieldName?) {
    for (let j = 0; j < this.JSONOb.length; j++) {
      if (
        parseInt(this.ngModelval[fieldName]) ===
          parseInt(this.maskMaximum[fieldName]) &&
        this.JSONOb[j].key == fieldName
      ) {
        delete this.JSONOb[j];
        this.ngModelval[fieldName] = "";
        this.JSONOb.push({ key: fieldName, value: this.ngModelval[fieldName] });
        this.senddiia253maintenancedata(this.ngModelval);
        this.diia253maintenanceDefaultColor = true;
        this.apiService.setdiia253maintenanceDefaultColor(
          this.diia253maintenanceDefaultColor
        );
      }
    }
  }
  RatedLifevalueChanged(value, fieldName?) {
    this.inputError["RatedLife"] = false;
    if (value >= this.RatedLifeValueMin && value <= this.RatedLifeValueMax) {
      this.ngModelval["RatedLife"] = value;
      this.senddiia253maintenancedata(this.ngModelval);
      this.checkdfault();
      this.checkInvalidValues();
    } else if (value == this.field["properties"]["RatedLife"]["default"]) {
      this.ngModelval["RatedLife"] = value;
      this.senddiia253maintenancedata(this.ngModelval);
      this.checkInvalidValues();
      this.valueChanged(fieldName);
      this.checkdfault();
    } else {
      this.inputError["RatedLife"] = true;
      this.apiService.senddiia253maintenanceInputError(true);
    }
  }
  ReferenceTemperaturevalueChanged(value, fieldName?) {
    this.inputError["ReferenceTemperature"] = false;
    if (
      value >= this.ReferenceTemperatureValueMin &&
      value <= this.ReferenceTemperatureValueMax
    ) {
      this.ngModelval["ReferenceTemperature"] = value;
      this.senddiia253maintenancedata(this.ngModelval);
      
      this.checkInvalidValues();
      this.checkdfault();
    } else if (
      value == this.field["properties"]["ReferenceTemperature"]["default"]
    ) {
      this.ngModelval["ReferenceTemperature"] = value;
      this.senddiia253maintenancedata(this.ngModelval);
      
      this.checkInvalidValues();
      this.valueChanged(fieldName);
      this.checkdfault();
    } else {
      this.inputError["ReferenceTemperature"] = true;
      this.apiService.senddiia253maintenanceInputError(true);
    }
  }
  RatedStartsvalueChanged(value, fieldName?) {
    this.inputError["RatedStarts"] = false;
    if (
      value >= this.RatedStartsValueMin &&
      value <= this.RatedStartsValueMax
    ) {
      this.ngModelval["RatedStarts"] = value;
      this.senddiia253maintenancedata(this.ngModelval);
      this.checkdfault();
      this.checkInvalidValues();
    } else if (value == this.field["properties"]["RatedStarts"]["default"]) {
      this.ngModelval["RatedStarts"] = value;
      this.senddiia253maintenancedata(this.ngModelval);
      
      this.checkInvalidValues();
      this.valueChanged(fieldName);
      this.checkdfault();
    } else {
      this.inputError["RatedStarts"] = true;
      this.apiService.senddiia253maintenanceInputError(true);
    }
  }
  checkInvalidValues() {
    this.diia253maintenanceCreateFile = false;
    for (var type in this.inputError) {
      if (this.inputError[type]) {
        this.diia253maintenanceCreateFile = true;
      }
    }
    this.apiService.diia253maintenanceInputError(
      this.diia253maintenanceCreateFile
    );
    this.apiService.senddiia253maintenanceInputError(
      this.diia253maintenanceCreateFile
    );
  }
  checkdfault() {
    this.diia253maintenanceDefaultColor = true;
    this.JSONOb = [];
    for (let j = 0; j < this.reqiredProperties.length; j++) {
      let type = this.reqiredProperties[j];
      this.JSONOb.push({ key: type, value: this.ngModelval[type] });
    }
    for (var type in this.ngModelval) {
      if (!isNullOrUndefined(this.properties)) {
        if (
          type === "RatedLife" ||
          type === "ReferenceTemperature" ||
          type === "RatedStarts"
        ) {
          if (
            this.properties.RatedLife.default ===
              this.field.allOf[0].if.properties.RatedLife.const &&
            this.ngModelval["RatedLife"] != ""
          ) {
            this.diia253maintenanceDefaultColor = false;
          } else if (
            this.properties.ReferenceTemperature.default ===
              this.field.allOf[1].if.properties.ReferenceTemperature.const &&
            this.ngModelval["ReferenceTemperature"] != ""
          ) {
            this.diia253maintenanceDefaultColor = false;
          } else if (
            this.properties.RatedStarts.default ===
              this.field.allOf[2].if.properties.RatedStarts.const &&
            this.ngModelval["RatedStarts"] != ""
          ) {
            this.diia253maintenanceDefaultColor = false;
          }
        }
        this.apiService.setdiia253maintenanceDefaultColor(
          this.diia253maintenanceDefaultColor
        );
        this.apiService.diia253maintenanceEditedVal(
          this.diia253maintenanceDefaultColor
        );
      }
    }
  }
  resetDefaultdiia253maintenance() {
    if (
      !isNullOrUndefined(this.properties) &&
      !isNullOrUndefined(this.properties.RatedLife) &&
      this.properties.RatedLife.default ===
        this.field.allOf[0].if.properties.RatedLife.const
    ) {
      this.ngModelval["RatedLife"] = "";
      this.inputError["RatedLife"] = false;
    }
    if (
      !isNullOrUndefined(this.properties) &&
      !isNullOrUndefined(this.properties.ReferenceTemperature) &&
      this.properties.ReferenceTemperature.default ===
        this.field.allOf[1].if.properties.ReferenceTemperature.const
    ) {
      this.ngModelval["ReferenceTemperature"] = "";
      this.inputError["ReferenceTemperature"] = false;
    }
    if (
      !isNullOrUndefined(this.properties) &&
      !isNullOrUndefined(this.properties.RatedStarts) &&
      this.properties.RatedStarts.default ===
        this.field.allOf[2].if.properties.RatedStarts.const
    ) {
      this.ngModelval["RatedStarts"] = "";
      this.inputError["RatedStarts"] = false;
    }
    this.apiService.senddiia253maintenanceInputError(false);
    this.apiService.setdiia253maintenanceEditedData(this.ngModelval);
    this.senddiia253maintenancedata(this.ngModelval);
  }
  ngOnInit() {
    this.isEdited = false;
    this.apiService.getdiia253maintenanceDefaultValue.subscribe((response) => {
      if (response == "valid") {
        this.diia253maintenanceDefaultColor = true;
        this.resetDefaultdiia253maintenance();
      }
    });
    this.column = this.field.requiredProperties;
    this.properties = this.field.properties;
    this.reqiredProperties = this.field.requiredProperties;
    this.RatedLifeValueMin =
      this.field.allOf[0].else.properties.RatedLife.minimum;
    this.RatedLifeValueMax =
      this.field.allOf[0].else.properties.RatedLife.maximum;
    if (
      this.properties.RatedLife.default ===
      this.field.allOf[0].if.properties.RatedLife.const
    ) {
      this.ngModelval["RatedLife"] = "";
    }
    this.ReferenceTemperatureValueMin =
      this.field.allOf[1].else.properties.ReferenceTemperature.minimum;
    this.ReferenceTemperatureValueMax =
      this.field.allOf[1].else.properties.ReferenceTemperature.maximum;
    if (
      this.properties.ReferenceTemperature.default ===
      this.field.allOf[1].if.properties.ReferenceTemperature.const
    ) {
      this.ngModelval["ReferenceTemperature"] = "";
    }
    this.RatedStartsValueMin =
      this.field.allOf[2].else.properties.RatedStarts.minimum;
    this.RatedStartsValueMax =
      this.field.allOf[2].else.properties.RatedStarts.maximum;
    if (
      this.properties.RatedStarts.default ===
      this.field.allOf[2].if.properties.RatedStarts.const
    ) {
      this.ngModelval["RatedStarts"] = "";
    }

    let unSelectedFeat = Array(
      JSON.parse(localStorage.getItem("UnSelectedFeatConfig"))
    );

    let isConfigEdited = false;
    let featIndex: number = 0;
    if (unSelectedFeat[0] != null) {
      unSelectedFeat[0].forEach((element) => {
        if (
          !isNullOrUndefined(element) &&
          !isNullOrUndefined(element["featureName"]) &&
          element["featureName"].toString().toUpperCase() ===
            "DIIA253MAINTENANCE"
        ) {
          isConfigEdited = true;
          let DIIA253MAINTENANCE: any = element["featureParameters"][0];
          this.isEdited = true;
          this.adddiia253maintenanceLoadedFeatureReadValues(DIIA253MAINTENANCE);
          unSelectedFeat[0].splice(featIndex, 1);
          localStorage.setItem(
            "UnSelectedFeatConfig",
            JSON.stringify(unSelectedFeat[0])
          );
        } else {
          featIndex++;
        }
      });
    }

    this.apiService.getquickConfig.subscribe((data) => {
      if (data === "quickConfig") {
        this.quickConfigCustomCSS = "custom-inputbox";
        this.setQuickConfig = "quickConfig";
      } else if (data === "standard") {
        this.quickConfigCustomCSS = "custom-inputbox";
        this.setQuickConfig = "standard";
      }
      setTimeout(() => {
        if (this.ref && !(this.ref as ViewRef).destroyed) {
          this.ref.detectChanges();
        }
      });
    });

    if (isConfigEdited) {
    } else {
      setTimeout(() => {
        this.fileResponse = JSON.parse(
          localStorage.getItem("configurationData")
        );
        if (this.fileResponse) {
          if (
            this.fileResponse.resp &&
            (this.fileResponse.resp.diia253maintenance ||
              this.fileResponse.resp.Diia253Maintenance ||
              this.fileResponse.resp.DiiA253Maintenance)
          ) {
            if (this.fileResponse.resp.diia253maintenance) {
              this.adddiia253maintenanceLoadedFeatureReadValues(
                this.fileResponse.resp.diia253maintenance
              );
            } else if (this.fileResponse.resp.Diia253Maintenance) {
              this.adddiia253maintenanceLoadedFeatureReadValues(
                this.fileResponse.resp.Diia253Maintenance
              );
            } else if (this.fileResponse.resp.DiiA253Maintenance) {
              this.adddiia253maintenanceLoadedFeatureReadValues(
                this.fileResponse.resp.DiiA253Maintenance
              );
            }
          } else {
          }
        }
      }, 1000);
    }
    setTimeout(() => {
      this.senddiia253maintenancedata(this.ngModelval);
      this.isEdited = true;
    }, 1000);
  }
  adddiia253maintenanceLoadedFeatureReadValues(diia253maintenance: any) {
    let diia253maintenanceObj = diia253maintenance;
    var keys = Object.keys(diia253maintenanceObj);
    var values = Object.values(diia253maintenanceObj);
    keys.forEach((key, i) => {
      if (key === "ReferenceTemperature") {
        this.ReferenceTemperaturevalueChanged(values[i]);
      } else if (key === "RatedStarts") {
        this.RatedStartsvalueChanged(values[i]);
      } else if (key === "RatedLife") {
        this.RatedLifevalueChanged(values[i]);
      }
    });
    var result = [];
    keys.forEach((key, i) => (result[key] = values[i]));
    this.ngModelval = result;
    this.ngModelval["RatedLife"] =
      result["RatedLife"] === "" || result["RatedLife"] === 255
        ? ""
        : result["RatedLife"];
    this.ngModelval["ReferenceTemperature"] =
      result["ReferenceTemperature"] === "" ||
      result["ReferenceTemperature"] === 195
        ? ""
        : result["ReferenceTemperature"];
    this.ngModelval["RatedStarts"] =
      result["RatedStarts"] === "" || result["RatedStarts"] === 65535
        ? ""
        : result["RatedStarts"];
    this.checkdfault();
    this.checkInvalidValues();
    this.senddiia253maintenancedata(this.ngModelval);
  }
  setConfigurationData(value, keyVal) {
    let diia253maintenanceObj = {};
    var key = keyVal;
    var data = {};
    var addNew = {
      [keyVal]: {},
    };
    data = JSON.parse(localStorage.getItem("configurationData"));
    if (data && !isNullOrUndefined(data["resp"])) {
      diia253maintenanceObj = Object.assign({}, value);
      data["resp"][keyVal] = diia253maintenanceObj;
    } else {
      data["resp"] = addNew;
      diia253maintenanceObj = Object.assign({}, value);
      data["resp"][keyVal] = diia253maintenanceObj;
    }
    localStorage.setItem("configurationData", JSON.stringify(data));
  }
  senddiia253maintenancedata(val) {
    this.apiService.senddiia253maintenancedata(val);
    if (this.isEdited) {
      this.setConfigurationData(this.ngModelval, "diia253maintenance");
    }
  }
  ngOnDestroy() {
    this.apiService.senddiia253maintenancedata("");
  }
  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  numbersAll(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 44 || charCode > 57)) {
      return false;
    }
    return true;
  }
}
