import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  ChangeDetectorRef,
  ViewRef,
} from "@angular/core";
import { FieldType } from "@ngx-formly/core";
import { Subscription } from "rxjs";
import { ApiService } from "src/app/core-module/services/api.service";
import { isNullOrUndefined } from "util";

@Component({
  selector: "app-line-switch",
  templateUrl: "./line-switch.component.html",
  styleUrls: ["./line-switch.component.scss"],
})
export class LineSwitchComponent
  extends FieldType
  implements OnInit, OnDestroy
{
  @Input() field;
  column: any;
  featureVersion: any;
  properties: any;
  ngModelval: any = [];
  inputError: any = [];
  quickConfigvalue: boolean;
  quickConfigCustomCSS: string;
  setQuickConfig: string;
  HighVLevelNormal: any;
  lineswitchHighVLevelMin;
  lineswitchHighVLevelMax;
  HighVLevelValue;
  lineswitchHighVOption;
  lineswitchLowVLevel: any;
  lineswitchLowVLevelMin: any;
  lineswitchLowVLevelMax: any;
  lineswitchLowVLevelValue: any;
  lineswitchLowVLevelOption: { floor: any; ceil: any };
  lineswitchFadeUpTime: any;
  lineswitchFadeUpTimeMin: any;
  lineswitchFadeUpTimeMax: any;
  lineswitchFadeUpTimeValue: any;
  lineswitchFadeUpTimeOption: { floor: any; ceil: any };
  lineswitchFadeDownTimeOption: { floor: any; ceil: any };
  lineswitchFadeDownTimeMax: any;
  lineswitchFadeDownTimeMin: any;
  lineswitchFadeDownTime: any;
  lineswitchFadeDownValue: any;
  lineswitchOffDelayTimeMax: any;
  lineswitchOffDelayTimeMin: any;
  lineswitchOffDelayTime: any;
  lineswitchOffDelayValue: any;
  lineswitchOffDelayTimeOption: { floor: any; ceil: any };
  lineswitch2ndInactiveDimLevelMax: any;
  lineswitch2ndInactiveDimLevelMin: any;
  lineswitch2ndInactiveDimLevelOption: { floor: any; ceil: any };
  lineswitch2ndInactiveDimLevelValue: any;
  lineswitch2ndInactiveDimLevel: any;
  lineswitch2ndInactiveLevelDelayTimeMin: any;
  LineSwitchEnabled: any;
  lineswitch2ndInactiveLevelDelayTimeMax: any;
  lineswitch2ndInactiveLevelDelayTimeValue: any;
  lineswitch2ndInactiveLevelDelayTimeOption: { floor: any; ceil: any };
  lineswitch2ndInactiveLevelDelayTime: any;
  isLineSwitchEnabled: any;
  inactiveLevelEnabled;
  lineSwitchDefaultColor;
  lineswitchCreateFile: boolean;
  show2ndInactiveLevelEnabled: boolean = true;
  show2ndInactiveDimLevel: boolean = true;
  show2ndInactiveLevelDelayTime: boolean = true;
  isEdited: any;
  isResetLineSwitch:boolean =false;
  lineSwitchSub:Subscription;
  getLineSwitchDefaultValueSub:Subscription;
  constructor(private service: ApiService, private ref: ChangeDetectorRef) {
    super();
  }
  ngOnInit() {
    this.isEdited = false;
    this.getLineSwitchDefaultValueSub = this.service.getLineSwitchDefaultValue.subscribe((response) => {
      if (response == "valid") {
        this.lineSwitchDefaultColor = true;
        this.isResetLineSwitch = true;
        this.resetDefaultLineSwitch();
      }
    });
    //Duplicate Restore Funcionality
    // this.service.getLineSwitchDefault.subscribe((response) => {
    //   if (response) {
    //     this.lineSwitchDefaultColor = true;
    //     this.resetDefaultLineSwitch();
    //   }
    // });
    this.lineSwitchSub = this.service.getLineSwitchData.subscribe((data) => {
      if (data) {
        this.ngModelval = data;
        this.ngModelval["HighVLevelValue"] = data["HighVLevelValue"];
        this.ngModelval["lineswitchLowVLevelValue"] =
          data["lineswitchLowVLevelValue"];
        this.ngModelval["lineswitchFadeUpTimeValue"] =
          data["lineswitchFadeUpTimeValue"];
        this.ngModelval["lineswitchFadeDownValue"] =
          data["lineswitchFadeDownValue"];
        this.ngModelval["lineswitchOffDelayValue"] =
          data["lineswitchOffDelayValue"];
        this.ngModelval["2ndInactiveLevelEnabled"] =
          data["2ndInactiveLevelEnabled"];
        this.ngModelval["lineswitch2ndInactiveDimLevelValue"] =
          data["lineswitch2ndInactiveDimLevelValue"];
        this.ngModelval["lineswitch2ndInactiveLevelDelayTimeValue"] =
          data["lineswitch2ndInactiveLevelDelayTimeValue"];
        if (this.isResetLineSwitch) {
          this.isResetLineSwitch = false;
        } else {
          this.checkdfault();
        }        
          this.checkInvalidValues();
      }
    });
    this.featureVersion = this.field.featureVersion;
    this.column = this.field.requiredProperties;
    this.properties = this.changeKeys(this.field.properties);
    this.service.lineswitchInputError(false);
    this.HighVLevelNormal = this.properties.HighVLevel;
    this.lineswitchHighVLevelMin = this.HighVLevelNormal.minimum;
    this.lineswitchHighVLevelMax = this.HighVLevelNormal.maximum;
    this.ngModelval["HighVLevelValue"] = this.HighVLevelValue =
      this.HighVLevelNormal.default;
    this.lineswitchHighVOption = {
      floor: this.lineswitchHighVLevelMin,
      ceil: this.lineswitchHighVLevelMax,
    };
    this.LineSwitchEnabled = this.properties.LineSwitchEnabled;
    this.ngModelval["isLineSwitchEnabled"] = this.isLineSwitchEnabled =
      this.LineSwitchEnabled.default;
    this.lineswitchLowVLevel = this.properties.LowVLevel;
    this.lineswitchLowVLevelMin = this.lineswitchLowVLevel.minimum;
    this.lineswitchLowVLevelMax = this.lineswitchLowVLevel.maximum;
    this.ngModelval["lineswitchLowVLevelValue"] =
      this.lineswitchLowVLevelValue = this.lineswitchLowVLevel.default;
    this.lineswitchLowVLevelOption = {
      floor: this.lineswitchLowVLevelMin,
      ceil: this.lineswitchLowVLevelMax,
    };
    this.lineswitchFadeUpTime = this.properties.FadeUpTime;
    this.lineswitchFadeUpTimeMin = this.lineswitchFadeUpTime.minimum;
    this.lineswitchFadeUpTimeMax = this.lineswitchFadeUpTime.maximum;
    this.ngModelval["lineswitchFadeUpTimeValue"] =
      this.lineswitchFadeUpTimeValue = this.lineswitchFadeUpTime.default;
    this.lineswitchFadeUpTimeOption = {
      floor: this.lineswitchFadeUpTimeMin,
      ceil: this.lineswitchFadeUpTimeMax,
    };
    this.lineswitchFadeDownTime = this.properties.FadeDownTime;
    this.lineswitchFadeDownTimeMin = this.lineswitchFadeDownTime.minimum;
    this.lineswitchFadeDownTimeMax = this.lineswitchFadeDownTime.maximum;
    this.ngModelval["lineswitchFadeDownValue"] = this.lineswitchFadeDownValue =
      this.lineswitchFadeDownTime.default;
    this.lineswitchFadeDownTimeOption = {
      floor: this.lineswitchFadeDownTimeMin,
      ceil: this.lineswitchFadeDownTimeMax,
    };
    this.lineswitchOffDelayTime = this.properties.SwitchOffDelayTime;
    this.lineswitchOffDelayTimeMin = this.lineswitchOffDelayTime.minimum;
    this.lineswitchOffDelayTimeMax = this.lineswitchOffDelayTime.maximum;
    this.ngModelval["lineswitchOffDelayValue"] = this.lineswitchOffDelayValue =
      this.lineswitchOffDelayTime.default;
    this.lineswitchOffDelayTimeOption = {
      floor: this.lineswitchOffDelayTimeMin,
      ceil: this.lineswitchOffDelayTimeMax,
    };
    if (this.featureVersion === 4) {
      this.inactiveLevelEnabled = this.ngModelval["2ndInactiveLevelEnabled"] =
        this.properties.secondInactiveLevelEnabled.default;
      this.lineswitch2ndInactiveDimLevel =
        this.properties.secondInactiveDimLevel;
      this.lineswitch2ndInactiveDimLevelMin =
        this.lineswitch2ndInactiveDimLevel.minimum;
      this.lineswitch2ndInactiveDimLevelMax =
        this.lineswitch2ndInactiveDimLevel.maximum;
      this.ngModelval["lineswitch2ndInactiveDimLevelValue"] =
        this.lineswitch2ndInactiveDimLevelValue =
          this.lineswitch2ndInactiveDimLevel.default;
      this.lineswitch2ndInactiveDimLevelOption = {
        floor: this.lineswitch2ndInactiveDimLevelMin,
        ceil: this.lineswitch2ndInactiveDimLevelMax,
      };
      this.lineswitch2ndInactiveLevelDelayTime =
        this.properties.secondInactiveLevelDelayTime;
      this.lineswitch2ndInactiveLevelDelayTimeMin =
        this.lineswitch2ndInactiveLevelDelayTime.minimum;
      this.lineswitch2ndInactiveLevelDelayTimeMax =
        this.lineswitch2ndInactiveLevelDelayTime.maximum;
      this.ngModelval["lineswitch2ndInactiveLevelDelayTimeValue"] =
        this.lineswitch2ndInactiveLevelDelayTimeValue =
          this.lineswitch2ndInactiveLevelDelayTime.default;
      this.lineswitch2ndInactiveLevelDelayTimeOption = {
        floor: this.lineswitch2ndInactiveLevelDelayTimeMin,
        ceil: this.lineswitch2ndInactiveLevelDelayTimeMax,
      };
    }
    let unSelectedFeat = Array(
      JSON.parse(localStorage.getItem("UnSelectedFeatConfig"))
    );
    let isConfigEdited = false;
    let featIndex: number = 0;
    if (unSelectedFeat[0] != null) {
      unSelectedFeat[0].forEach((element) => {
        if (
          !isNullOrUndefined(element) &&
          !isNullOrUndefined(element["featureName"]) &&
          element["featureName"].toString().toUpperCase() === "LINESWITCH"
        ) {
          isConfigEdited = true;
          let LINESWITCH: any = element["featureParameters"][0];
          this.addlineSwitchLoadedFeatureReadValues(LINESWITCH);
          unSelectedFeat[0].splice(featIndex, 1);
          localStorage.setItem(
            "UnSelectedFeatConfig",
            JSON.stringify(unSelectedFeat[0])
          );
        } else {
          featIndex++;
        }
      });
    }

    /////


    if (isConfigEdited) {

    } else {
      setTimeout(() => {
        const lineSwitchLoaddedFetaure = JSON.parse(
          localStorage.getItem("configurationData")
        );
        if (lineSwitchLoaddedFetaure) {
          if (
            lineSwitchLoaddedFetaure.resp &&
            (lineSwitchLoaddedFetaure.resp.LineSwitch ||
              lineSwitchLoaddedFetaure.resp.Lineswitch)
          ) {
            if (lineSwitchLoaddedFetaure.resp.LineSwitch) {
              this.addlineSwitchLoadedFeatureReadValues(
                lineSwitchLoaddedFetaure.resp.LineSwitch
              );
            } else if (lineSwitchLoaddedFetaure.resp.Lineswitch) {
              this.addlineSwitchLoadedFeatureReadValues(
                lineSwitchLoaddedFetaure.resp.Lineswitch
              );
            }
          }
        }
      }, 1000);
    }
    this.service.getquickConfig.subscribe((data) => {
      if (data === "quickConfig") {
        this.quickConfigvalue = true;
        this.quickConfigCustomCSS = "quick-custom-inputbox textBoxUnit";
        this.setQuickConfig = "quickConfig";
      } else if (data === "standard") {
        this.quickConfigvalue = false;
        this.quickConfigCustomCSS = "custom-inputbox";
        this.setQuickConfig = "standard";
      }
      setTimeout(() => {
        if (this.ref && !(this.ref as ViewRef).destroyed) {
          this.ref.detectChanges();
        }
      });
    });
    setTimeout(() => {
      this.sendLineSwitchdata();
    }, 1000);

  }

  sendLineSwitchdata() {
    this.service.sendLineSwitchdata(this.ngModelval);
    this.setConfigurationData(this.ngModelval, 'LineSwitch');
  };

  setConfigurationData(value, keyVal) {
    if(!isNullOrUndefined(value)){
      let lineSwitchObj = {};
      var key = keyVal;
      var data = {};
      var addNew = {
        [keyVal]: {},
      };
      data = JSON.parse(localStorage.getItem("configurationData"));
      if (data && !isNullOrUndefined(data["resp"])) {
        lineSwitchObj = Object.assign({}, value);
        data["resp"][keyVal] = lineSwitchObj;
      } else {
        data["resp"] = addNew;
        lineSwitchObj = Object.assign({}, value);
        data["resp"][keyVal] = lineSwitchObj;
      }
      localStorage.setItem("configurationData", JSON.stringify(data));
    }
  }


  toggleIsLineSwitchEnabled(isChecked: boolean) {
    this.ngModelval["isLineSwitchEnabled"] = this.isLineSwitchEnabled =
      isChecked;
    this.sendLineSwitchdata();
    this.checkdfault();
    this.checkInvalidValues();
  }
  addlineSwitchLoadedFeatureReadValues(lineSwitch: any) {
    let lineSwitchObj = lineSwitch;
    var keys = Object.keys(lineSwitchObj);
    var values = Object.values(lineSwitchObj);
    keys.forEach((key, i) => {
      this.textValChanged(values[i], key);
    });
    var result = [];
    keys.forEach((key, i) => (result[key] = values[i]));
    this.ngModelval = result;
    this.ngModelval["HighVLevelValue"] = !isNullOrUndefined(result["HighVLevelValue"]) ? result["HighVLevelValue"] : result["HighVLevel"];
    this.isLineSwitchEnabled = this.ngModelval["isLineSwitchEnabled"] = result["LineSwitchEnabled"];
    this.HighVLevelValue = !isNullOrUndefined(result["HighVLevelValue"]) ? result["HighVLevelValue"] : result["HighVLevel"];
    this.ngModelval["lineswitchLowVLevelValue"] = !isNullOrUndefined(result["lineswitchLowVLevelValue"]) ? result["lineswitchLowVLevelValue"] : result["LowVLevel"];
    this.lineswitchLowVLevelValue = !isNullOrUndefined(result["lineswitchLowVLevelValue"]) ? result["lineswitchLowVLevelValue"] : result["LowVLevel"];
    this.ngModelval["lineswitchFadeUpTimeValue"] = !isNullOrUndefined(result["lineswitchFadeUpTimeValue"]) ? result["lineswitchFadeUpTimeValue"] : result["FadeUpTime"];
    this.lineswitchFadeUpTimeValue = !isNullOrUndefined(result["lineswitchFadeUpTimeValue"]) ? result["lineswitchFadeUpTimeValue"] : result["FadeUpTime"];
    this.ngModelval["lineswitchFadeDownValue"] = !isNullOrUndefined(result["lineswitchFadeDownValue"]) ? result["lineswitchFadeDownValue"] : result["FadeDownTime"];
    this.lineswitchFadeDownValue = !isNullOrUndefined(result["lineswitchFadeDownValue"]) ? result["lineswitchFadeDownValue"] : result["FadeDownTime"];
    this.ngModelval["lineswitchOffDelayValue"] = !isNullOrUndefined(result["lineswitchOffDelayValue"]) ? result["lineswitchOffDelayValue"] : result["SwitchOffDelayTime"];
    this.lineswitchOffDelayValue = !isNullOrUndefined(result["lineswitchOffDelayValue"]) ? result["lineswitchOffDelayValue"] : result["SwitchOffDelayTime"];
    if (result.hasOwnProperty("2ndInactiveLevelEnabled")) {
      this.inactiveLevelEnabled = this.ngModelval["2ndInactiveLevelEnabled"];
      this.ngModelval["2ndInactiveLevelEnabled"] =
        result["2ndInactiveLevelEnabled"];
    } else {
      this.show2ndInactiveLevelEnabled = false;
    }
    if (result.hasOwnProperty("2ndInactiveDimLevel") || result.hasOwnProperty("lineswitch2ndInactiveDimLevelValue") ) {
      this.ngModelval["lineswitch2ndInactiveDimLevelValue"] =
      !isNullOrUndefined(result["lineswitch2ndInactiveDimLevelValue"]) ? result["lineswitch2ndInactiveDimLevelValue"] : result["2ndInactiveDimLevel"];
      this.lineswitch2ndInactiveDimLevelValue = !isNullOrUndefined(result["lineswitch2ndInactiveDimLevelValue"]) ? result["lineswitch2ndInactiveDimLevelValue"] : result["2ndInactiveDimLevel"];
    } else {
      this.show2ndInactiveDimLevel = false;
    }
    if (result.hasOwnProperty("2ndInactiveLevelDelayTime") || result.hasOwnProperty("lineswitch2ndInactiveLevelDelayTimeValue")) {
      this.ngModelval["lineswitch2ndInactiveLevelDelayTimeValue"] =
      !isNullOrUndefined(result["lineswitch2ndInactiveLevelDelayTimeValue"]) ? result["lineswitch2ndInactiveLevelDelayTimeValue"] : result["2ndInactiveLevelDelayTime"]; 
      this.lineswitch2ndInactiveLevelDelayTimeValue =
      !isNullOrUndefined(result["lineswitch2ndInactiveLevelDelayTimeValue"]) ? result["lineswitch2ndInactiveLevelDelayTimeValue"] : result["2ndInactiveLevelDelayTime"];
    } else {
      this.show2ndInactiveLevelDelayTime = false;
    }
    this.checkdfault();
    this.checkInvalidValues();
    this.sendLineSwitchdata();
  }
  changeKeys(originalObj) {
    const replacements = {
      "2ndInactiveDimLevel": "secondInactiveDimLevel",
      "2ndInactiveLevelDelayTime": "secondInactiveLevelDelayTime",
      "2ndInactiveLevelEnabled": "secondInactiveLevelEnabled",
    };
    let replacedItems = Object.keys(originalObj).map((key) => {
      const newKey = replacements[key] || key;
      return { [newKey]: originalObj[key] };
    });
    return replacedItems.reduce((a, b) => Object.assign({}, a, b));
  }
  enableLevel2(value) {
    this.inactiveLevelEnabled = value;
    this.ngModelval["2ndInactiveLevelEnabled"] = value;
    this.sendLineSwitchdata();
  }
  lineswitchHighVValueChange(value) {
    this.inputError["HighVLevel"] = false;
    if (
      value >= this.lineswitchHighVLevelMin &&
      this.inputError["HighVLevel"] === false
    ) {
      this.ngModelval["HighVLevelValue"] = value;
      this.HighVLevelValue = value;
      this.sendLineSwitchdata();
      this.checkdfault();
      this.checkInvalidValues();
    }
  }
  lineswitchLowVLevelChange(value) {
    this.inputError["LowVLevel"] = false;
    if (
      value >= this.lineswitchLowVLevelMin &&
      this.inputError["LowVLevel"] === false
    ) {
      this.ngModelval["lineswitchLowVLevelValue"] = value;
      this.sendLineSwitchdata();
      this.checkdfault();
      this.checkInvalidValues();
    }
  }
  lineswitchFadeUpTimeChange(value) {
    this.inputError["FadeUpTime"] = false;
    if (
      value >= this.lineswitchFadeUpTimeMin &&
      this.inputError["FadeUpTime"] === false
    ) {
      this.ngModelval["lineswitchFadeUpTimeValue"] = value;
      this.sendLineSwitchdata();
      this.checkdfault();
      this.checkInvalidValues();
    }
  }
  lineswitchFadeDownTimeChange(value) {
    this.inputError["FadeDownTime"] = false;
    if (
      value >= this.lineswitchFadeDownTimeMin &&
      this.inputError["FadeDownTime"] === false
    ) {
      this.ngModelval["lineswitchFadeDownValue"] = value;
      this.sendLineSwitchdata();
      this.checkdfault();
      this.checkInvalidValues();
    }
  }
  lineSwitchoffDelayTimeChange(value) {
    this.inputError["SwitchOffDelayTime"] = false;
    if (
      value >= this.lineswitchOffDelayTimeMin &&
      this.inputError["SwitchOffDelayTime"] === false
    ) {
      this.ngModelval["lineswitchOffDelayValue"] = value;
      this.sendLineSwitchdata();
      this.checkdfault();
      this.checkInvalidValues();
    }
  }
  lineswitch2ndInactiveDimLevelChange(value) {
    this.inputError["2ndInactiveDimLevel"] = false;
    if (
      value >= this.lineswitch2ndInactiveDimLevelMin &&
      this.inputError["2ndInactiveDimLevel"] === false
    ) {
      this.ngModelval["lineswitch2ndInactiveDimLevelValue"] = value;
      this.sendLineSwitchdata();
      this.checkdfault();
      this.checkInvalidValues();
    }
  }
  lineswitch2ndInactiveLevelDelayTimeChange(value) {
    this.inputError["2ndInactiveLevelDelayTime"] = false;
    if (
      value >= this.lineswitch2ndInactiveLevelDelayTimeMin &&
      this.inputError["2ndInactiveLevelDelayTime"] === false
    ) {
      this.ngModelval["lineswitch2ndInactiveLevelDelayTimeValue"] = value;
      this.sendLineSwitchdata();
      this.checkdfault();
      this.checkInvalidValues();
    }
  }
  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  textValChanged(value, colName) {
    value = parseInt(value);
    if (isNaN(value) || value.length === 0) {
      value = 0;
    }
    this.lineSwitchDefaultColor = false;
    this.inputError[colName] = false;
    this.lineswitchCreateFile = false;
    if (colName == "HighVLevel") {
      if (
        value < this.lineswitchHighVLevelMin ||
        value > this.lineswitchHighVLevelMax
      ) {
        this.inputError[colName] = true;
        this.ngModelval["HighVLevelValue"] = value;
      } else {
        this.HighVLevelValue = value;
        this.ngModelval["HighVLevelValue"] = value;
      }
    } else if (colName == "LowVLevel") {
      if (
        value < this.lineswitchLowVLevelMin ||
        value > this.lineswitchLowVLevelMax
      ) {
        this.inputError[colName] = true;
        this.ngModelval["lineswitchLowVLevelValue"] = value;
      } else {
        this.lineswitchLowVLevelValue = value;
        this.ngModelval["lineswitchLowVLevelValue"] = value;
      }
    } else if (colName == "FadeUpTime") {
      if (
        value < this.lineswitchFadeUpTimeMin ||
        value > this.lineswitchFadeUpTimeMax
      ) {
        this.inputError[colName] = true;
        this.ngModelval["lineswitchFadeUpTimeValue"] = value;
      } else {
        this.lineswitchFadeUpTimeValue = value;
        this.ngModelval["lineswitchFadeUpTimeValue"] = value;
      }
    } else if (colName == "FadeDownTime") {
      if (
        value < this.lineswitchFadeDownTimeMin ||
        value > this.lineswitchFadeDownTimeMax
      ) {
        this.inputError[colName] = true;
        this.ngModelval["lineswitchFadeDownValue"] = value;
      } else {
        this.lineswitchFadeDownValue = value;
        this.ngModelval["lineswitchFadeDownValue"] = value;
      }
    } else if (colName == "SwitchOffDelayTime") {
      if (
        value < this.lineswitchOffDelayTimeMin ||
        value > this.lineswitchOffDelayTimeMax
      ) {
        this.inputError[colName] = true;
        this.ngModelval["lineswitchOffDelayValue"] = value;
      } else {
        this.lineswitchOffDelayValue = value;
        this.ngModelval["lineswitchOffDelayValue"] = value;
      }
    } else if (colName == "2ndInactiveDimLevel") {
      if (
        value < this.lineswitch2ndInactiveDimLevelMin ||
        value > this.lineswitch2ndInactiveDimLevelMax
      ) {
        this.inputError[colName] = true;
        this.ngModelval["lineswitch2ndInactiveDimLevelValue"] = value;
      } else {
        this.lineswitch2ndInactiveDimLevelValue = value;
        this.ngModelval["lineswitch2ndInactiveDimLevelValue"] = value;
      }
    } else if (colName == "2ndInactiveLevelDelayTime") {
      if (
        value < this.lineswitch2ndInactiveLevelDelayTimeMin ||
        value > this.lineswitch2ndInactiveLevelDelayTimeMax
      ) {
        this.inputError[colName] = true;
        this.ngModelval["lineswitch2ndInactiveLevelDelayTimeValue"] = value;
      } else {
        this.lineswitch2ndInactiveLevelDelayTimeValue = value;
        this.ngModelval["lineswitch2ndInactiveLevelDelayTimeValue"] = value;
      }
    }
    this.checkdfault();
    for (var type in this.inputError) {
      if (this.inputError[type]) {
        this.lineswitchCreateFile = true;
      }
    }
    this.service.lineSwitchEditedVal(this.lineSwitchDefaultColor);
    this.service.lineswitchInputError(this.lineswitchCreateFile);
    this.sendLineSwitchdata();
  }
  checkdfault() {
    var newtype;
    var newtype2;
    var newtype3;
    this.lineSwitchDefaultColor = true;
    this.properties = this.changeKeys(this.field.properties);
    for (var type in this.ngModelval) {
      if (type == "HighVLevelValue") {
        newtype = type.replace("Value", "");
        if (this.ngModelval[type] != this.properties[newtype]["default"]) {
          this.lineSwitchDefaultColor = false;
        }
      } else if (
        type == "lineswitchLowVLevelValue" ||
        type == "lineswitchFadeUpTimeValue"
      ) {
        newtype = type.replace("lineswitch", "");
        newtype2 = newtype.replace("Value", "");
        if (this.ngModelval[type] != this.properties[newtype2]["default"]) {
          this.lineSwitchDefaultColor = false;
        }
      } else if (type == "lineswitchFadeDownValue") {
        newtype = type.replace("lineswitch", "");
        newtype2 = newtype.replace("Value", "Time");
        if (this.ngModelval[type] != this.properties[newtype2]["default"]) {
          this.lineSwitchDefaultColor = false;
        }
      } else if (type == "lineswitchOffDelayValue") {
        newtype = type.replace("lineswitchOffDelayValue", "SwitchOffDelayTime");
        if (this.ngModelval[type] != this.properties[newtype]["default"]) {
          this.lineSwitchDefaultColor = false;
        }
      } else if (type == "2ndInactiveLevelEnabled") {
        newtype = type.replace("2", "seco");
        if (this.properties.hasOwnProperty(newtype)) {
          if (this.ngModelval[type] != this.properties[newtype]["default"]) {
            this.lineSwitchDefaultColor = false;
          }
        }
      } else if (
        type == "lineswitch2ndInactiveDimLevelValue" ||
        type == "lineswitch2ndInactiveLevelDelayTimeValue"
      ) {
        newtype = type.replace("lineswitch", "");
        newtype2 = newtype.replace("Value", "");
        newtype3 = newtype2.replace("2", "seco");
        if (this.properties.hasOwnProperty(newtype3)) {
          if (this.ngModelval[type] != this.properties[newtype3]["default"]) {
            this.lineSwitchDefaultColor = false;
          }
        }
      } else if (type == "isLineSwitchEnabled") {
        newtype = type.replace("isLineSwitchEnabled", "LineSwitchEnabled");
        if (
          !isNullOrUndefined(this.properties[newtype]["default"]) &&
          this.ngModelval[type] != this.properties[newtype]["default"]
        ) {
          this.lineSwitchDefaultColor = false;
        }
      }
      this.service.lineSwitchEditedVal(this.lineSwitchDefaultColor);
    }
  }
  checkInvalidValues() {
    this.lineswitchCreateFile = false;
    for (var type in this.inputError) {
      if (this.inputError[type]) {
        this.lineswitchCreateFile = true;
      }
    }
    this.service.lineswitchInputError(this.lineswitchCreateFile);
  }
  sliderEvent() {}
  resetDefaultLineSwitch() {
    this.enableLevel2(false);
    this.ngModelval["HighVLevelValue"] = this.HighVLevelValue =
      this.HighVLevelNormal.default;
    this.ngModelval["lineswitchLowVLevelValue"] =
      this.lineswitchLowVLevelValue = this.lineswitchLowVLevel.default;
    if (!isNullOrUndefined(this.LineSwitchEnabled.default)) {
      this.ngModelval["isLineSwitchEnabled"] = this.isLineSwitchEnabled =
        this.LineSwitchEnabled.default;
    }
    if (!isNullOrUndefined(this.lineswitchFadeUpTime.default)) {
      this.ngModelval["lineswitchFadeUpTimeValue"] =
        this.lineswitchFadeUpTimeValue = this.lineswitchFadeUpTime.default;
    }
    if (!isNullOrUndefined(this.lineswitchFadeDownTime.default)) {
      this.ngModelval["lineswitchFadeDownValue"] =
        this.lineswitchFadeDownValue = this.lineswitchFadeDownTime.default;
    }
    if (!isNullOrUndefined(this.lineswitchOffDelayTime.default)) {
      this.ngModelval["lineswitchOffDelayValue"] =
        this.lineswitchOffDelayValue = this.lineswitchOffDelayTime.default;
    }
    if (
      !isNullOrUndefined(this.properties.secondInactiveLevelEnabled) &&
      !isNullOrUndefined(this.properties.secondInactiveLevelEnabled.default)
    ) {
      this.ngModelval["2ndInactiveLevelEnabled"] =
        this.properties.secondInactiveLevelEnabled.default;
    }
    if (
      !isNullOrUndefined(this.lineswitch2ndInactiveLevelDelayTime) &&
      !isNullOrUndefined(this.lineswitch2ndInactiveLevelDelayTime.default)
    ) {
      this.ngModelval["lineswitch2ndInactiveLevelDelayTimeValue"] =
        this.lineswitch2ndInactiveLevelDelayTimeValue =
          this.lineswitch2ndInactiveLevelDelayTime.default;
    }
    if (
      !isNullOrUndefined(this.lineswitch2ndInactiveDimLevel) &&
      !isNullOrUndefined(this.lineswitch2ndInactiveDimLevel.default)
    ) {
      this.ngModelval["lineswitch2ndInactiveDimLevelValue"] =
        this.lineswitch2ndInactiveDimLevelValue =
          this.lineswitch2ndInactiveDimLevel.default;
    }
    for (var type in this.inputError) {
      this.inputError[type] = false;
    }
    this.lineswitchCreateFile = false;
    this.service.lineswitchInputError(this.lineswitchCreateFile);
    this.sendLineSwitchdata();
  }
  ngOnDestroy() {
    this.service.setLineSwitchDefaultColor("");
    if(this.lineSwitchSub){
     this.lineSwitchSub.unsubscribe();
    };
    if(this.getLineSwitchDefaultValueSub){
     this.getLineSwitchDefaultValueSub.unsubscribe();
    }
    //this.sendLineSwitchdata("");
  }
}
