import { Directive, HostBinding, Output, HostListener, EventEmitter, ElementRef, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Directive({
  selector: '[apInputError]'
})
export class appInputError {

  @Input() formControl: FormControl;
  constructor(public element: ElementRef) {
  }

  ngOnInit(){
    if(this.formControl){
      this.formControl.statusChanges.subscribe(status => {
        this.handleStatus(status);
      });
      const status = this.formControl.status;
      this.handleStatus(status);
    }
  }

  handleStatus(status){
    if(status == 'INVALID'){
      this.element.nativeElement.classList.add('hasError');
    }else{
      this.element.nativeElement.classList.remove('hasError');
    }
  }

}
