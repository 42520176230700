import { Injectable } from "@angular/core";
import { WindowReferenceService } from "./window-reference.service";

@Injectable({
  providedIn: "root",
})
export class DataLayerService {
  private window;

  constructor(private _windowRef: WindowReferenceService) {
    this.window = _windowRef.nativeWindow; // intialise the window to what we get from our window service
  }

  private addToDataLayer(obj) {

    if (obj) this.window.dataLayer.push(obj);
  }
  setSelectedDeviceInfo(dataObj) {
    this.addToDataLayer(dataObj);
  }
  resetRegion(filter) {
    let index = this.window.dataLayer.findIndex(
      (item) => item.region === filter
    );
    this.window.dataLayer.splice(index, 1);
  }
  resetcollapsedFeatures(filter) {
    let index = this.window.dataLayer.findIndex((item) => {
      if(item.event != "collapseFeature"){
        return;
      }
      if (
        item.event === "collapseFeature" &&
        item.features[0].name === filter
      ) {
        return item;
      }
    });
    this.window.dataLayer.splice(index, 1);
  }
  resetSearch() {
    let index = this.window.dataLayer.findIndex((x) => x.event === "search");
    this.window.dataLayer.splice(index, 1);
  }
  resetSetSelectedDeviceInfo() {
    let data = JSON.parse(localStorage.getItem("readerDeviceFullInfo"));
    let index = this.window.dataLayer.findIndex(
      (x) => x.productName === data[0].deviceName
    );
    this.window.dataLayer.splice(index, 1);
  }
  logUserDetails(userDataObj) {
    this.addToDataLayer(userDataObj);
  }
  checkAndAdd(data) {
    var matchedData = this.window.dataLayer.find(
      (item) => item.region === data.region
    );
    if (!matchedData) {
      this.addToDataLayer(data);
    }
  }
  logSearchEvent(eventObj) {
    this.addToDataLayer(eventObj);
  }
}
