import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  ChangeDetectorRef,
  ViewRef,
  DoCheck,
} from "@angular/core";
import { Options } from "ng5-slider";
import { FieldType } from "@ngx-formly/core";
import { restore } from "./../../../../assets/json/restoreTooltip.en";
import { ApiService } from "src/app/core-module/services/api.service";
import { isNullOrUndefined } from "util";

@Component({
  selector: "app-dcemergency",
  templateUrl: "./dcemergency.component.html",
  styleUrls: ["./dcemergency.component.scss"],
})
export class DcemergencyComponent extends FieldType implements OnInit {
  @Input() field;
  valOptions: Options = {};
  floorValue;
  ceilValue;
  defaultValue;
  enableFeatureCheckbox: any = false;
  isResetDisabled;
  isSliderClick;
  manualRefresh: EventEmitter<void> = new EventEmitter<void>();
  sliderValue;
  textboxValue;
  defaultColor = true;
  deviceName;
  isHidden;
  isAocEnabled;
  isEnableFeature;
  inputError = false;
  quickConfigvalue: boolean;
  quickConfigCustomCSS;
  setQuickConfig;
  restoreText = restore;
  booleanValue = true;
  isDimmingEnabled;
  isDimmingEnabledValue;
  isDimmingCheckbox: any = false;
  dimmingChecked: any;
  fileResponse: any;
  ngModelval: any = [];
  calculatedPercentageVal = null;
  isEdited: any;
  constructor(private apiService: ApiService, private ref: ChangeDetectorRef) {
    super();
  }
  setConfigurationData(value, keyVal) {
    let touchDimObj = {};
    var key = keyVal;
    var data = {};
    var addNew = {
      [keyVal]: {},
    };
    data = JSON.parse(localStorage.getItem("configurationData"));
    if (data && !isNullOrUndefined(data["resp"])) {
      touchDimObj = Object.assign({}, value);
      data["resp"][keyVal] = touchDimObj;
    } else {
      data["resp"] = addNew;
      touchDimObj = Object.assign({}, value);
      data["resp"][keyVal] = touchDimObj;
    }
    localStorage.setItem("configurationData", JSON.stringify(data));
  }
  ngOnInit() {
    this.isEdited = false;
    this.apiService.getDcemergencyDefaultData.subscribe((response) => {
      if (response) {
        this.resetDefaultDcemergency();
      }
    });
    this.apiService.getDcemergencyDefaultColor.subscribe((response) => {
      if (response) {
      }
    });
    this.isEnableFeature = this.field.properties.DcEmergencyEnabled.default;
    if (this.isEnableFeature) {
      this.enableFeatureCheckbox = true;
    }
    this.isDimmingEnabledValue =
      this.field.properties.DcEmergencyDimmingEnabled.default;
    if (
      this.field.requiredProperties.includes("DcEmergencyDimmingEnabled") &&
      (this.isDimmingEnabledValue === true ||
        this.isDimmingEnabledValue === false)
    ) {
      this.isDimmingCheckbox = true;
      this.isDimmingEnabled = this.isDimmingEnabledValue;
    } else {
      this.isDimmingEnabled =
        this.field.properties.DcEmergencyDimmingEnabled.const;
      this.isDimmingCheckbox = false;
    }
    this.floorValue = this.field.properties.DcEmergencyLevel.minimum;
    this.ceilValue = this.field.properties.DcEmergencyLevel.maximum;
    this.defaultValue = this.field.properties.DcEmergencyLevel.default;
    if (this.field.properties.length === 1) {
      this.isResetDisabled = true;
    } else {
      this.isResetDisabled = false;
    }
    this.valOptions = {
      floor: this.floorValue,
      ceil: this.ceilValue,
      disabled: false,
    };
    this.textboxValue = this.defaultValue;
    this.sliderValue = this.defaultValue;
    if (this.textboxValue >= this.floorValue && this.inputError != true) {
      var y = (this.textboxValue - 1) / (253 / 3) - 1;
      this.calculatedPercentageVal = Math.round(Math.pow(10, y));
    } else {
      this.calculatedPercentageVal = null;
    }
    this.apiService.getquickConfig.subscribe((data) => {
      if (data === "quickConfig") {
        this.quickConfigvalue = true;
        this.quickConfigCustomCSS = "quick-custom-inputbox";
        this.setQuickConfig = "quickConfig";
      } else {
        this.quickConfigvalue = false;
        this.quickConfigCustomCSS = "custom-inputbox";
        this.setQuickConfig = "standard";
      }
      setTimeout(() => {
        if (this.ref && !(this.ref as ViewRef).destroyed) {
          this.ref.detectChanges();
        }
      });
    });

    this.ngModelval["DcEmergencyDimmingEnabled"] = this.isDimmingEnabled;
    this.ngModelval["DcEmergencyEnabled"] =
      this.field.properties.DcEmergencyEnabled.default;
    this.ngModelval["DcEmergencyLevel"] = this.textboxValue;
    let unSelectedFeat = Array(
      JSON.parse(localStorage.getItem("UnSelectedFeatConfig"))
    );
    let isConfigEdited = false;
    let featIndex: number = 0;
    if (unSelectedFeat[0] != null) {
      unSelectedFeat[0].forEach((element) => {
        if (!isNullOrUndefined(element) && !isNullOrUndefined(element["featureName"]) && element["featureName"].toString().toUpperCase() === "DCEMERGENCY") {
          isConfigEdited = true;
          this.ngModelval["DcEmergencyDimmingEnabled"] = this.isDimmingEnabled =
            element["featureParameters"][0]["DcEmergencyDimmingEnabled"];
          this.ngModelval["DcEmergencyEnabled"] = this.isEnableFeature =
            element["featureParameters"][0]["DcEmergencyEnabled"];
          this.ngModelval["DcEmergencyLevel"] =
            this.textboxValue =
            this.sliderValue =
              element["featureParameters"][0]["DcEmergencyLevel"];

          unSelectedFeat[0].splice(featIndex, 1);
          localStorage.setItem(
            "UnSelectedFeatConfig",
            JSON.stringify(unSelectedFeat[0])
          );
          this.sendDCEmergencyData();
          this.isEdited = true;
        } else {
          featIndex++;
        }
      });
    }

    /////

    this.apiService.sendDcemergencyInputError(this.inputError);
    this.sendDCEmergencyData();
    this.isEdited = true;
    setTimeout(() => {
      const DCEmergencyloaddedFetaure = JSON.parse(
        localStorage.getItem("configurationData")
      );
      if (DCEmergencyloaddedFetaure) {
        if (
          DCEmergencyloaddedFetaure.resp &&
          (DCEmergencyloaddedFetaure.resp.DCEmergency ||
            DCEmergencyloaddedFetaure.resp.DcEmergency)
        ) {
          if (DCEmergencyloaddedFetaure.resp.DCEmergency)
            this.addDCEmergencyLoadedFeatureReadValues(
              DCEmergencyloaddedFetaure.resp.DCEmergency
            );
          else if (DCEmergencyloaddedFetaure.resp.DcEmergency)
            this.addDCEmergencyLoadedFeatureReadValues(
              DCEmergencyloaddedFetaure.resp.DcEmergency
            );
        }
      }
    }, 1000);
  }
  addDCEmergencyLoadedFeatureReadValues(DCEMERGENCY: any) {
    //DcEmergencyLevel: 185, DcEmergencyDimmingEnabled: false, DcEmergencyEnabled: true
    this.ngModelval["DcEmergencyDimmingEnabled"] = this.isDimmingEnabled =
      DCEMERGENCY.DcEmergencyDimmingEnabled;
    this.ngModelval["DcEmergencyEnabled"] = this.isEnableFeature =
      DCEMERGENCY.DcEmergencyEnabled;
    this.ngModelval["DcEmergencyLevel"] =
      this.textboxValue =
      this.sliderValue =
        DCEMERGENCY.DcEmergencyLevel;
    if (this.textboxValue >= this.floorValue && this.inputError != true) {
      var y = (this.textboxValue - 1) / (253 / 3) - 1;
      this.calculatedPercentageVal = Math.round(Math.pow(10, y));
    } else {
      this.calculatedPercentageVal = null;
    }
    //this.checkDefaults();
    this.apiService.sendDcemergencyInputError(this.inputError);
    this.sendDCEmergencyData();
    this.checkValidValue();
  }
  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  checkDefaults() {
    this.defaultColor = true;
    if (
      this.defaultValue === Number(this.sliderValue) &&
      this.defaultValue === Number(this.textboxValue)
    ) {
      this.defaultColor = true;
    } else {
      this.defaultColor = false;
    }
    this.isDimmingEnabledValue =
      this.field.properties.DcEmergencyDimmingEnabled.default;
    if (
      this.field.requiredProperties.includes("DcEmergencyDimmingEnabled") &&
      this.isDimmingEnabledValue != this.isDimmingEnabled
    ) {
      this.defaultColor = false;
    }
    // this.isEnableFeature = this.field.properties.DcEmergencyEnabled.default;
    if (
      this.isEnableFeature === this.field.properties.DcEmergencyEnabled.default
    ) {
      //this.enableFeatureCheckbox = true;
    } else {
      this.defaultColor = false;
    }
    this.apiService.setDcemergencyDefaultColor(this.defaultColor);
  }

  sliderEvent(val) {
    this.textboxValue = val;
    if (
      this.defaultValue === Number(this.sliderValue) &&
      this.defaultValue === this.textboxValue
    ) {
      this.defaultColor = true;
    } else {
      this.defaultColor = false;
    }
    if (val < this.floorValue || val > this.ceilValue) {
      this.inputError = true;
      this.apiService.sendDcemergencyInputError(this.inputError);
    } else {
      this.inputError = false;
      this.apiService.sendDcemergencyInputError(this.inputError);
    }
    if (this.textboxValue >= this.floorValue && this.inputError != true) {
      var y = (this.textboxValue - 1) / (253 / 3) - 1;
      this.calculatedPercentageVal = Math.round(Math.pow(10, y));
    } else {
      this.calculatedPercentageVal = null;
    }
    this.ngModelval["DcEmergencyLevel"] = this.textboxValue;
    this.apiService.setDcemergencyDefaultColor(this.defaultColor);
    this.sendDCEmergencyData();
  }

  checkValidValue(){
    if (this.textboxValue < this.floorValue || this.textboxValue > this.ceilValue) {
      this.inputError = true;
      this.apiService.sendDcemergencyInputError(this.inputError);
    } else {
      this.inputError = false;
      this.apiService.sendDcemergencyInputError(this.inputError);
    }
  }

  valueChanged(value) {
    if (value < this.floorValue || value > this.ceilValue) {
      this.inputError = true;
      this.apiService.sendDcemergencyInputError(this.inputError);
    } else {
      this.inputError = false;
      this.apiService.sendDcemergencyInputError(this.inputError);
      this.sliderValue = Number(this.textboxValue);
    }
    this.textboxValue = value;
    if (this.textboxValue >= this.floorValue && this.inputError != true) {
      var y = (this.textboxValue - 1) / (253 / 3) - 1;
      this.calculatedPercentageVal = Math.round(Math.pow(10, y));
    } else {
      this.calculatedPercentageVal = null;
    }
    if (this.field.properties.length === 1) {
      this.defaultColor = true;
    } else {
      this.defaultColor = false;
    }
    if (this.textboxValue === this.defaultValue.toString()) {
      this.defaultColor = true;
    } else {
      this.defaultColor = false;
    }
    this.apiService.setDcemergencyDefaultColor(this.defaultColor);
    this.ngModelval["DcEmergencyLevel"] = this.textboxValue;
    this.sendDCEmergencyData();
  }

  sliderValueChange(value) {
    this.sliderEvent(value);
  }

  resetDefaultDcemergency() {
    this.isEnableFeature = this.field.properties.DcEmergencyEnabled.default;
    if (this.isEnableFeature) {
      this.enableFeatureCheckbox = true;
    }

    this.isDimmingEnabledValue =
      this.field.properties.DcEmergencyDimmingEnabled.default;
    if (
      this.field.requiredProperties.includes("DcEmergencyDimmingEnabled") &&
      (this.isDimmingEnabledValue === true ||
        this.isDimmingEnabledValue === false)
    ) {
      this.isDimmingCheckbox = true;
      this.isDimmingEnabled = this.isDimmingEnabledValue;

      // this.dimmingChecked = true;
    } else {
      this.isDimmingEnabled =
        this.field.properties.DcEmergencyDimmingEnabled.const;
      this.isDimmingCheckbox = false;
    }
    //  if(this.dimmingChecked && !this.defaultColor ){
    //   this.isDimmingEnabled = this.dimmingChecked;
    //   this.isDimmingCheckbox = true;
    // }

    this.defaultValue = this.field.properties.DcEmergencyLevel.default;
    this.textboxValue = this.field.properties.DcEmergencyLevel.default;
    this.sliderValue = this.field.properties.DcEmergencyLevel.default;
    this.inputError = false;
    this.ngModelval["DcEmergencyEnabled"] = this.isEnableFeature;
    this.ngModelval["DcEmergencyDimmingEnabled"] = this.isDimmingEnabledValue;
    this.ngModelval["DcEmergencyLevel"] = this.textboxValue;
    this.apiService.sendDcemergencyInputError(this.inputError);
    this.isResetDisabled = false;
    this.isSliderClick = false;
    this.sendDCEmergencyData();
  }

  enableFeature(event) {
    this.isEnableFeature = event;
    this.ngModelval["DcEmergencyEnabled"] = event;

    if (this.isEnableFeature === false) {
      this.isResetDisabled = true;
    } else this.isResetDisabled = false;

    this.sendDCEmergencyData();
  }

  dimmingEnabled(event) {
    this.isDimmingEnabled = event;
    this.ngModelval["DcEmergencyDimmingEnabled"] = event;
    this.sendDCEmergencyData();
  }

  sendDCEmergencyData() {
    if (this.ngModelval["DcEmergencyEnabled"] === false) {
      this.isResetDisabled = true;
    }
    if (this.isEdited) {
      this.setConfigurationData(this.ngModelval, "DCEmergency");
    }
    this.checkDefaults();
    this.apiService.setDcemergencyEditedData(this.ngModelval);
  }

  ngOnDestroy() {
    // this.apiService.sendQuickConfig('');
    // this.apiService.setDcemergencyDefaultColor(true);
    // this.apiService.sendDcemergencyDefaultData('');
    this.apiService.setDcemergencyDefaultColor(true);
    // this.apiService.sendDcemergencyDefaultData('');
  }
}
