import { Component, OnInit, Input, OnDestroy } from "@angular/core";
import { FieldType } from "@ngx-formly/core";
import { ApiService } from "src/app/core-module/services/api.service";
import { element } from "protractor";
import { isNullOrUndefined } from "util";

@Component({
  selector: "app-dimming-interface",
  templateUrl: "./dimming-interface.component.html",
  styleUrls: ["./dimming-interface.component.scss"],
})
export class DimmingInterfaceComponent
  extends FieldType
  implements OnInit, OnDestroy
{
  @Input() field;
  column: any;
  properties: any;
  ngModelval: any = {};
  selectedInterface = [];
  overrideInterface = [];
  dropdown = [
    "LineSwitch",
    "Amp Dim",
    "Dynadimmer",
    "DALI",
    "CodedMains",
    "ZTV",
  ];
  openDynaDimmer: boolean = false;
  overriddenInterface: any;
  openLineSwitch: boolean;
  overridenInterfaceArray = [];
  defaultColor: boolean;
  isLineSwitchOverride: boolean;
  isCodedMainsOverride: boolean;
  defaultRestoring: boolean;
  showOverride: boolean = false;
  selectedList = [];
  OverridenList = [];
  showCodedMains: boolean = false;
  constructor(private service: ApiService) {
    super();
  }
  toggleValue(value, selectedInterface) {
    if (Boolean(value) === true) {
      if (selectedInterface === "LineSwitch") {
        this.isLineSwitchOverride = true;
        this.ngModelval["OverrideInterface"].push("LineSwitch");
      } else if (
        selectedInterface === "CodedMains" &&
        this.ngModelval["OverrideInterface"].length > 0
      ) {
        this.isLineSwitchOverride = true;
        this.isCodedMainsOverride = true;
        this.ngModelval["OverrideInterface"].push("CodedMains");
      } else if (
        selectedInterface === "CodedMains" &&
        this.ngModelval["OverrideInterface"].length === 0
      ) {
        this.isLineSwitchOverride = false;
        this.isCodedMainsOverride = true;
        this.ngModelval["OverrideInterface"].push("CodedMains");
      }
      let sortedData = this.ngModelval["OverrideInterface"].sort();
      this.ngModelval["OverrideInterface"] = sortedData;
      this.service.sendDimmingInterfacedata(this.ngModelval);
    } else if (Boolean(value) === false) {
      if (selectedInterface === "LineSwitch") {
        this.isLineSwitchOverride = false;
        if (this.ngModelval["OverrideInterface"].length === 0) {
          this.ngModelval["OverrideInterface"] = [];
        } else {
          const index =
            this.ngModelval["OverrideInterface"].indexOf("LineSwitch");
          if (index > -1) {
            this.ngModelval["OverrideInterface"].splice(index, 1);
          }
        }
      } else if (selectedInterface === "CodedMains") {
        this.isCodedMainsOverride = false;
        if (this.ngModelval["OverrideInterface"].length === 0) {
          this.ngModelval["OverrideInterface"] = [];
        } else {
          const index =
            this.ngModelval["OverrideInterface"].indexOf("CodedMains");
          if (index > -1) {
            this.ngModelval["OverrideInterface"].splice(index, 1);
          }
        }
      }
      let sortedData = this.ngModelval["OverrideInterface"].sort();
      this.ngModelval["OverrideInterface"] = sortedData;
      this.service.sendDimmingInterfacedata(this.ngModelval);
    }
  }
  onChangeList(value) {
    this.isCodedMainsOverride = this.isLineSwitchOverride = false;
    let checkFeatures = JSON.parse(localStorage.getItem("configurationData"));
    if (value === "Dynadimmer" || value === "CodedMains") {
      if (value === "CodedMains") {
        this.showOverride = checkFeatures["feature12Nc"].includes("lineswitch-")
          ? true
          : false;
        this.showCodedMains = false;
      } else if (value === "Dynadimmer") {
        this.showOverride = checkFeatures["feature12Nc"].includes("lineswitch-")
          ? true
          : false;
        this.showCodedMains = checkFeatures["feature12Nc"].includes(
          "codedmains-"
        )
          ? true
          : false;
      }
    } else {
      this.showOverride = this.showCodedMains = false;
    }
    // if (
    //   this.OverridenList.length > 0 &&
    //   this.OverridenList.includes("LineSwitch")
    // ) {
    //   this.isLineSwitchOverrideInterface = true;
    // }else{
    //   this.isLineSwitchOverrideInterface = false;
    // }
    // if (this.OverridenList.length > 0) {
    //   this.openDynaDimmer = true;
    // } else {
    //   this.openDynaDimmer = false;
    // }
    if (
      checkFeatures["feature12Nc"].includes("lineswitch-") ||
      checkFeatures["feature12Nc"].includes("codedmains-")
    ) {
      this.ngModelval["OverrideInterface"] = [];
    }
    this.ngModelval["SelectedInterface"] = value;
    this.service.sendDimmingInterfacedata(this.ngModelval);
    this.checkDefault();
    if (
      checkFeatures["feature12Nc"].includes("ztv-") &&
      checkFeatures["feature12Nc"].includes("dimminginterface-")
    ) {
      this.checkValidation();
    }
  }
  ngOnInit() {
    this.service.getrestoreDefault.subscribe((data) => {
      if (data) {
        this.restoreDefault();
      }
    });

    this.service.getDimmingValidity.subscribe((data) => {
      if (data) {
        this.checkValidation();
      }
    });
    this.column = this.field.requiredProperties;
    this.properties = this.field.properties;
    if (!isNullOrUndefined(localStorage.getItem("selectedInterface"))) {
      this.selectedInterface = JSON.parse(
        localStorage.getItem("selectedInterface")
      );
    } else {
      this.selectedInterface = this.properties.SelectedInterface["enum"];
    }
    this.overrideInterface =
      this.properties.OverrideInterface &&
      this.properties.OverrideInterface.items &&
      this.properties.OverrideInterface.items["enum"]
        ? this.properties.OverrideInterface.items["enum"]
        : [];
    let newSupportedIntf: string[] = [];
    let selectedInftCnt = this.selectedInterface.length;
    for (let index = 0; index < selectedInftCnt; index++) {
      const element = this.selectedInterface[index];
      if (
        element.toString().toUpperCase() === "LINESWITCH" ||
        element.toString().toUpperCase() === "DYNADIMMER" ||
        element.toString().toUpperCase() === "DALI" ||
        element.toString().toUpperCase() === "AMPDIM" ||
        element.toString().toUpperCase() === "CODEDMAINS" ||
        element.toString().toUpperCase() === "ZTV"
      ) {
        if (element.toString().toUpperCase() === `0-10V / 1-10V`) {
          newSupportedIntf.push("Ztv");
        } else if (element.toString() === `Coded Mains Scene Settings`) {
          newSupportedIntf.push("CodedMains");
        } else {
          newSupportedIntf.push(element);
        }
      }
    }
    this.selectedInterface = newSupportedIntf;
    localStorage.setItem(
      "selectedInterface",
      JSON.stringify(this.selectedInterface)
    );
    this.checkRequiredProperties();
    // this.overriddenInterface = this.properties.OverrideInterface.items['enum'];
    this.checkDefault();
    let checkFeatures = JSON.parse(localStorage.getItem("configurationData"));
    if (
      checkFeatures["feature12Nc"].includes("ztv-") &&
      checkFeatures["feature12Nc"].includes("dimminginterface-")
    ) {
      this.checkValidation();
    }
    this.service.sendDimmingInterfacedata(this.ngModelval);
    if (
      checkFeatures["feature12Nc"].includes("dynadimmer-") &&
      checkFeatures["feature12Nc"].includes("lineswitch-")
    ) {
      if (this.properties.SelectedInterface.default === "CodedMains") {
        this.showOverride = checkFeatures["feature12Nc"].includes("lineswitch-")
          ? true
          : false;
        this.showCodedMains = checkFeatures["feature12Nc"].includes(
          "codedmains-"
        )
          ? true
          : false;
      } else if (this.properties.SelectedInterface.default === "Dynadimmer") {
        this.showOverride = checkFeatures["feature12Nc"].includes("lineswitch-")
          ? true
          : false;
        this.showCodedMains = checkFeatures["feature12Nc"].includes(
          "codedmains-"
        )
          ? true
          : false;
      }
    }
    setTimeout(() => {
      let dimminginterfaceLoaddedFetaure = JSON.parse(
        localStorage.getItem("configurationData")
      );
      let unSelectedFeat = Array(
        JSON.parse(localStorage.getItem("UnSelectedFeatConfig"))
      );

      let isConfigEdited = false;
      let featIndex: number = 0;
      if (unSelectedFeat[0] != null) {
        unSelectedFeat[0].forEach((element) => {
          if (
            !isNullOrUndefined(element) &&
            !isNullOrUndefined(element["featureName"]) &&
            element["featureName"].toString().toUpperCase() ===
              "DIMMINGINTERFACE"
          ) {
            isConfigEdited = true;
            let DimmingInterfaceEdt: any = element["featureParameters"][0];
            let dimminginterfaceEdtVl = {
              resp: {
                DimmingInterface: DimmingInterfaceEdt,
              },
            };
            dimminginterfaceLoaddedFetaure = dimminginterfaceEdtVl;
            unSelectedFeat[0].splice(featIndex, 1);
            localStorage.setItem(
              "UnSelectedFeatConfig",
              JSON.stringify(unSelectedFeat[0])
            );
          } else {
            featIndex++;
          }
        });
      }

      /////
      if (dimminginterfaceLoaddedFetaure) {
        if (
          dimminginterfaceLoaddedFetaure.resp &&
          dimminginterfaceLoaddedFetaure.resp.DimmingInterface &&
          dimminginterfaceLoaddedFetaure.resp.DimmingInterface
            .SelectedInterface === "Dynadimmer"
        ) {
          this.ngModelval["SelectedInterface"] = "Dynadimmer";
          //this.showCodedMains = true;
          if (
            checkFeatures["feature12Nc"].includes("lineswitch-") ||
            checkFeatures["feature12Nc"].includes("codedmains-")
          ) {
            if (checkFeatures["feature12Nc"].includes("lineswitch-"))
              this.showOverride = true;
            if (checkFeatures["feature12Nc"].includes("codedmains-"))
              this.showCodedMains = true;
          }
          if (
            dimminginterfaceLoaddedFetaure.resp.DimmingInterface
              .OverrideInterface
          ) {
            if (
              dimminginterfaceLoaddedFetaure.resp.DimmingInterface
                .OverrideInterface.length > 1 &&
              dimminginterfaceLoaddedFetaure.resp.DimmingInterface
                .OverrideInterface != ""
            ) {
              this.isLineSwitchOverride = this.isCodedMainsOverride = true;
              this.ngModelval["OverrideInterface"] =
                dimminginterfaceLoaddedFetaure.resp.DimmingInterface.OverrideInterface;
              this.checkDefault();
              this.service.sendDimmingInterfacedata(this.ngModelval);
            } else if (
              dimminginterfaceLoaddedFetaure.resp.DimmingInterface
                .OverrideInterface.length > 0 &&
              dimminginterfaceLoaddedFetaure.resp.DimmingInterface
                .OverrideInterface != ""
            ) {
              if (
                dimminginterfaceLoaddedFetaure.resp.DimmingInterface.OverrideInterface.includes(
                  "LineSwitch"
                )
              ) {
                this.isLineSwitchOverride = true;
                this.ngModelval["OverrideInterface"] = ["LineSwitch"];
                this.checkDefault();
                this.service.sendDimmingInterfacedata(this.ngModelval);
              } else {
                this.isCodedMainsOverride = true;
                this.ngModelval["OverrideInterface"] = ["CodedMains"];
                this.checkDefault();
                this.service.sendDimmingInterfacedata(this.ngModelval);
              }
            } else {
              this.isLineSwitchOverride = false;
              if (checkFeatures["feature12Nc"].includes("lineswitch-")) {
                this.ngModelval["OverrideInterface"] = [];
              }
              this.checkDefault();
              this.service.sendDimmingInterfacedata(this.ngModelval);
            }
          }
        }
      }
      if (dimminginterfaceLoaddedFetaure) {
        if (
          dimminginterfaceLoaddedFetaure.resp &&
          dimminginterfaceLoaddedFetaure.resp.DimmingInterface &&
          dimminginterfaceLoaddedFetaure.resp.DimmingInterface
            .SelectedInterface === "CodedMains"
        ) {
          this.ngModelval["SelectedInterface"] = "CodedMains";
          if (checkFeatures["feature12Nc"].includes("lineswitch-"))
            this.showOverride = true;

          this.showCodedMains = false;
          if (
            dimminginterfaceLoaddedFetaure.resp.DimmingInterface
              .OverrideInterface.length > 0
          ) {
            if (
              dimminginterfaceLoaddedFetaure.resp.DimmingInterface.OverrideInterface.includes(
                "LineSwitch"
              )
            ) {
              this.ngModelval["OverrideInterface"] = ["LineSwitch"];
              this.isLineSwitchOverride = true;
            } else {
              this.ngModelval["OverrideInterface"] = [];
              this.isLineSwitchOverride = false;
            }
          } else {
            this.ngModelval["OverrideInterface"] = [];
            this.isLineSwitchOverride = false;
          }
        }
      }
      if (dimminginterfaceLoaddedFetaure) {
        if (
          dimminginterfaceLoaddedFetaure.resp &&
          dimminginterfaceLoaddedFetaure.resp.DimmingInterface &&
          dimminginterfaceLoaddedFetaure.resp.DimmingInterface
            .SelectedInterface === "LineSwitch"
        ) {
          this.ngModelval["SelectedInterface"] = "LineSwitch";
        }
      }
      if (dimminginterfaceLoaddedFetaure) {
        if (
          (dimminginterfaceLoaddedFetaure.resp &&
            dimminginterfaceLoaddedFetaure.resp.DimmingInterface &&
            dimminginterfaceLoaddedFetaure.resp.DimmingInterface
              .SelectedInterface === "AmpDim") ||
          (dimminginterfaceLoaddedFetaure.resp &&
            dimminginterfaceLoaddedFetaure.resp.ampdim &&
            dimminginterfaceLoaddedFetaure.resp.DimmingInterface
              .SelectedInterface === "AmpDim")
        ) {
          this.ngModelval["SelectedInterface"] = "AmpDim";
        }
      }
      if (dimminginterfaceLoaddedFetaure) {
        if (
          (dimminginterfaceLoaddedFetaure.resp &&
            dimminginterfaceLoaddedFetaure.resp.DimmingInterface &&
            dimminginterfaceLoaddedFetaure.resp.DimmingInterface
              .SelectedInterface === "Ztv") ||
          (dimminginterfaceLoaddedFetaure.resp &&
            dimminginterfaceLoaddedFetaure.resp.ZTV &&
            dimminginterfaceLoaddedFetaure.resp.DimmingInterface
              .SelectedInterface === "Ztv")
        ) {
          this.ngModelval["SelectedInterface"] = "Ztv";
        }
      }
      if (dimminginterfaceLoaddedFetaure) {
        if (
          dimminginterfaceLoaddedFetaure.resp &&
          dimminginterfaceLoaddedFetaure.resp.DimmingInterface &&
          dimminginterfaceLoaddedFetaure.resp.DimmingInterface
            .SelectedInterface === "Dali"
        ) {
          this.ngModelval["SelectedInterface"] = "Dali";
        }
      }
    }, 1000);

    setTimeout(() => {
      this.checkDefault();
    }, 3000);
  }

  checkValidation() {
    let checkFeatures = JSON.parse(localStorage.getItem("configurationData"));
    let obj = Object.keys(this.ngModelval).length;
    if (obj > 0) {
      /*  if (this.ngModelval["SelectedInterface"] === "Ztv") {
        if (
          this.ngModelval["SelectedInterface"] === "Ztv" &&
          checkFeatures["selectedFeaturesInfo"].includes("ztv-") &&
          checkFeatures["selectedFeaturesInfo"].includes("dimminginterface-")
        ) {
          this.service.validtionOfDimming("valid");
        } else if (
          !checkFeatures["selectedFeaturesInfo"].includes("ztv-") &&
          !checkFeatures["selectedFeaturesInfo"].includes("dimminginterface-")
        ) {
          this.service.validtionOfDimming("valid");
        } else {
          this.service.validtionOfDimming("invalid");
          alert(
            "0-10V / 1-10V is interdependent with Dimming Interface, so please include it in Feature File"
          );
        }
      }
      // else if (
      //   this.ngModelval["SelectedInterface"] === "Dynadimmer" &&
      //   (checkFeatures["selectedFeaturesInfo"].includes("ztv-") ||
      //     checkFeatures["selectedFeaturesInfo"].includes("dimminginterface-"))
      // ) {
      //   this.service.validtionOfDimming("invalid");
      // }
      else {
        this.service.validtionOfDimming("valid");
      }
 */
    }
  }

  checkRequiredProperties() {
    this.field.requiredProperties.forEach((element) => {
      if (element === "SelectedInterface") {
        this.ngModelval["SelectedInterface"] =
          this.properties.SelectedInterface.default;
      } else if (element === "OverrideInterface") {
        this.ngModelval["OverrideInterface"] =
          this.properties.OverrideInterface.default;
        //this.createCheckList(this.properties.SelectedInterface.default);
      }
    });
  }

  createCheckList(value) {
    this.OverridenList = [];
    for (let i = 0; i < this.field["allOf"].length; i++) {
      if (
        this.field["allOf"][i]["then"].properties.OverrideInterface !==
        undefined
      ) {
        for (
          let j = 0;
          j <
          this.field["allOf"][i]["then"].properties.OverrideInterface.enum
            .length;
          j++
        ) {
          if (
            this.field["allOf"][i]["then"].properties.OverrideInterface.enum[
              j
            ] === value
          ) {
            if (
              this.field["allOf"][i]["if"].properties.SelectedInterface.const[0]
            ) {
              this.OverridenList.push(
                this.field["allOf"][i]["if"].properties.SelectedInterface
                  .const[0]
              );
            }
          }
        }
      }
    }
    this.ngModelval["OverrideInterface"] = this.OverridenList;
  }

  paramterChecked(ischecked, event) {
    if (ischecked) {
      this.overridenInterfaceArray.push(event);
    } else {
      this.overridenInterfaceArray = this.overridenInterfaceArray.filter(
        (item) => item !== event
      );
    }
    this.ngModelval["OverrideInterface"] = this.overridenInterfaceArray;
    this.service.sendDimmingInterfacedata(this.ngModelval);
  }

  checkDefault() {
    this.defaultRestoring = true;
    for (var type in this.ngModelval) {
      if (type != "OverrideInterface") {
        if (this.ngModelval[type] != this.properties[type]["default"]) {
          this.defaultRestoring = false;
        }
      }
    }
    this.service.sendDimmingRestoreDefault(this.defaultRestoring);
  }

  restoreDefault() {
    if (
      !isNullOrUndefined(
        this.properties &&
          this.properties.SelectedInterface &&
          this.properties.SelectedInterface.default
      )
    ) {
      this.onChangeList(this.properties.SelectedInterface.default);
    }
    this.checkValidation();
    this.service.sendDimmingRestoreDefault(this.defaultRestoring);
  }

  ngOnDestroy() {
    this.service.sendDimmingRestoreDefault("");
    this.service.sendDimmingDefault("");
    this.ngModelval = {};
    this.service.sendDimmingInterfacedata("");
    this.service.sendDimmingCheckValidation("");
    localStorage.removeItem("selectedInterface");
  }
}
