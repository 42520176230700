export const url =[
    {
      "localhost": "https://signify-portal-oem-tools-acc.trimm.net/multione-jwt-sso-redirect.html?tenantName=oem&defName=MultiOne&redirectUrl="
    }, {
        "dev": "https://signify-portal-oem-tools-acc.trimm.net/multione-jwt-sso-redirect.html?tenantName=oem&defName=MultiOne&redirectUrl="
    }, {
        "tst": "https://signify-portal-oem-tools-acc.trimm.net/multione-jwt-sso-redirect.html?tenantName=oem&defName=MultiOne&redirectUrl="
    }, {
        "acc": "https://signify-portal-oem-tools-acc.trimm.net/multione-jwt-sso-redirect.html?tenantName=oem&defName=MultiOne&redirectUrl="
    }, {
        "staging": "https://www.tools.mytechnology.portal.signify.com/multione-jwt-sso-redirect.html?tenantName=oem&defName=MultiOne&redirectUrl="
    }, {
        "prod": "https://www.tools.mytechnology.portal.signify.com/multione-jwt-sso-redirect.html?tenantName=oem&defName=MultiOne&redirectUrl="
    }, {
        "www": "https://www.tools.mytechnology.portal.signify.com/multione-jwt-sso-redirect.html?tenantName=oem&defName=MultiOne&redirectUrl="
    }, {
        "app": "https://www.tools.mytechnology.portal.signify.com/multione-jwt-sso-redirect.html?tenantName=oem&defName=MultiOne&redirectUrl="
    }, {
        "beta": "https://www.tools.mytechnology.portal.signify.com/multione-jwt-sso-redirect.html?tenantName=oem&defName=MultiOne&redirectUrl="
    }
]