import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  ChangeDetectorRef,
  ViewRef,
} from "@angular/core";
import { FieldType } from "@ngx-formly/core";
import { ApiService } from "src/app/core-module/services/api.service";
import { isNullOrUndefined } from "util";
@Component({
  selector: "app-corridor",
  templateUrl: "./corridor.component.html",
  styleUrls: ["./corridor.component.scss"],
})
export class CorridorComponent extends FieldType implements OnInit, OnDestroy {
  @Input() field;
  column: any;
  properties: any;
  ngModelval: any = [];
  inputError: any = [];
  quickConfigvalue: boolean;
  quickConfigCustomCSS: string;
  setQuickConfig: string;
  corridorNormal;
  corridorNormalMin;
  corridorNormalMax;
  NormalLevelValue;
  corridorNormalOption;
  corridorBackgroundLevel: any;
  corridorBackgroundLevelMin: any;
  corridorBackgroundLevelMax: any;
  BackgroundLevelValue: any;
  corridorBackgroundLevelOption: { floor: any; ceil: any };
  corridorDelayTime: any;
  corridorDelayTimeMin: any;
  corridorDelayTimeMax: any;
  DelayTimeValue: any;
  corridorDelayTimeOption: { floor: any; ceil: any };
  corridorFadeTimeIndexMin: any;
  corridorFadeTimeIndex: any;
  corridorFadeTimeIndexMax: any;
  FadeTimeIndexValue: any;
  corridorFadeTimeIndexOption: { floor: any; ceil: any };
  corridorProlongTime: any;
  corridorProlongTimeMin: any;
  corridorProlongTimeMax: any;
  ProlongTimeValue: any;
  corridorProlongTimeOption: { floor: any; ceil: any };
  corridorActivationTime: any;
  corridorActivationTimeMin: any;
  corridorActivationTimeMax: any;
  ActivationTimeValue: any;
  corridorActivationTimeOption: { floor: any; ceil: any };
  InfiniteDisabledVal;
  corridorDefaultColor;
  corridordisbaled: boolean;
  corridorCreateFile: boolean;
  corridorEnabledval: any;
  isEdited: any;
  calculatedPercentageVal = null;
  calculatedPercentageVal1 = null;
  fadeTimeDynamicVal: any;
  fadeTimeDataset = {
    "0": "Extended fade",
    "1": "0.7",
    "2": "1.0",
    "3": "1.4",
    "4": "2.0",
    "5": "2.8",
    "6": "4.0",
    "7": "5.7",
    "8": "8.0",
    "9": "11.3",
    "10": "16.0",
    "11": "22.6",
    "12": "32.0",
    "13": "45.3",
    "14": "64.0",
    "15": "90.5",
  };
  constructor(private service: ApiService, private ref: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {
    this.isEdited = false;
    this.service.getCorridorGraphData.subscribe((responseGraph) => {
      this.ngModelval["ActivationTimeValue"] =
        responseGraph["ActivationTimeValue"];
      this.ngModelval["BackgroundLevelValue"] = this.BackgroundLevelValue =
        responseGraph["BackgroundLevelValue"];
      if (
        this.BackgroundLevelValue >= this.corridorBackgroundLevelMin &&
        this.inputError["BackgroundLevel"] != true
      ) {
        var y = (this.BackgroundLevelValue - 1) / (253 / 3) - 1;
        this.calculatedPercentageVal1 = Math.round(Math.pow(10, y));
      } else {
        this.calculatedPercentageVal1 = null;
      }
      this.ngModelval["CorridorEnabled"] = this.corridorEnabledval =
        responseGraph["CorridorEnabled"];
      this.ngModelval["DelayTimeValue"] = this.DelayTimeValue =
        responseGraph["DelayTimeValue"];
      this.ngModelval["FadeTimeIndexValue"] = this.FadeTimeIndexValue =
        responseGraph["FadeTimeIndexValue"];
      this.fadeTimeDynamicVal =
        this.fadeTimeDataset[responseGraph["FadeTimeIndexValue"]];
      this.ngModelval["NormalLevelValue"] = this.NormalLevelValue =
        responseGraph["NormalLevelValue"];
      if (
        this.NormalLevelValue >= this.corridorNormalMin &&
        this.inputError["NormalLevel"] != true
      ) {
        var y = (this.NormalLevelValue - 1) / (253 / 3) - 1;
        this.calculatedPercentageVal = Math.round(Math.pow(10, y));
      } else {
        this.calculatedPercentageVal = null;
      }
      this.ngModelval["ProlongTimeValue"] = this.ProlongTimeValue =
        responseGraph["ProlongTimeValue"];
      this.InfiniteDisabledVal = this.ngModelval["infiniteDisabled"] =
        responseGraph["infiniteDisabled"];
      //fixed graph drag issue
      this.corridorActivationTimeChange(this.ngModelval["ActivationTimeValue"]);
    });
    this.service.getCorridorDefault.subscribe((response) => {
      if (response) {
        this.corridorDefaultColor = true;
        this.resetDefaultCorridor();
      }
    });
    ///From API
    this.column = this.field.requiredProperties;
    this.properties = this.field.properties;
    this.service.corridorInputError(false);
    ///Begining of corridorNormal
    this.corridorNormal = this.properties.NormalLevel;
    this.corridorNormalMin = this.corridorNormal.minimum;
    this.corridorNormalMax = this.corridorNormal.maximum;
    this.ngModelval["NormalLevelValue"] = this.NormalLevelValue =
      this.corridorNormal.default;
    this.ngModelval["NormalLevelMaximum"] = this.corridorNormalMax;
    this.corridorNormalOption = {
      floor: this.corridorNormalMin,
      ceil: this.corridorNormalMax,
    };
    if (
      this.NormalLevelValue >= this.corridorNormalMin &&
      this.inputError["NormalLevel"] != true
    ) {
      var y = (this.NormalLevelValue - 1) / (253 / 3) - 1;
      this.calculatedPercentageVal = Math.round(Math.pow(10, y));
    } else {
      this.calculatedPercentageVal = null;
    }
    //End of corridorNormal
    ///Begining of BackgroundLevel
    this.corridorBackgroundLevel = this.properties.BackgroundLevel;
    this.corridorBackgroundLevelMin = this.corridorBackgroundLevel.minimum;
    this.corridorBackgroundLevelMax = this.corridorBackgroundLevel.maximum;
    this.ngModelval["BackgroundLevelValue"] = this.BackgroundLevelValue =
      this.corridorBackgroundLevel.default;
    this.ngModelval["BackgroundLevelMaximum"] = this.corridorBackgroundLevelMax;
    this.corridorBackgroundLevelOption = {
      floor: this.corridorBackgroundLevelMin,
      ceil: this.corridorBackgroundLevelMax,
    };
    if (
      this.BackgroundLevelValue >= this.corridorBackgroundLevelMin &&
      this.inputError["BackgroundLevel"] != true
    ) {
      var y = (this.BackgroundLevelValue - 1) / (253 / 3) - 1;
      this.calculatedPercentageVal1 = Math.round(Math.pow(10, y));
    } else {
      this.calculatedPercentageVal1 = null;
    }
    //End of BackgroundLevel
    ///Begining of DelayTime
    this.corridorDelayTime = this.properties.DelayTime;
    this.corridorDelayTimeMin = this.corridorDelayTime.minimum;
    this.corridorDelayTimeMax = this.corridorDelayTime.maximum;
    this.ngModelval["DelayTimeValue"] = this.DelayTimeValue =
      this.corridorDelayTime.default;
    this.corridorDelayTimeOption = {
      floor: this.corridorDelayTimeMin,
      ceil: this.corridorDelayTimeMax,
    };
    //End of DelayTime
    ///Begining of FadeTimeIndex
    this.corridorFadeTimeIndex = this.properties.FadeTimeIndex;
    this.corridorFadeTimeIndexMin = this.corridorFadeTimeIndex.minimum;
    this.corridorFadeTimeIndexMax = this.corridorFadeTimeIndex.maximum;
    this.ngModelval["FadeTimeIndexValue"] = this.FadeTimeIndexValue =
      this.corridorFadeTimeIndex.default;
    this.fadeTimeDynamicVal =
      this.fadeTimeDataset[this.corridorFadeTimeIndex.default];
    this.ngModelval["FadeTimeIndexMaximum"] = this.corridorFadeTimeIndexMax;
    this.corridorFadeTimeIndexOption = {
      floor: this.corridorFadeTimeIndexMin,
      ceil: this.corridorFadeTimeIndexMax,
    };
    //End of FadeTimeIndex
    ///Begining of ProlongTime
    this.corridorProlongTime = this.properties.ProlongTime;
    this.corridorProlongTimeMin =
      this.field.oneOf[0].properties.ProlongTime.minimum;
    this.corridorProlongTimeMax =
      this.field.oneOf[0].properties.ProlongTime.maximum;
    this.ngModelval["ProlongTimeValue"] = this.ProlongTimeValue =
      this.corridorProlongTime.default;
    this.corridorProlongTimeOption = {
      floor: this.corridorProlongTimeMin,
      ceil: this.corridorProlongTimeMax,
    };
    //End of ProlongTime
    ///Begining of ActivationTime
    this.corridorActivationTime = this.properties.ActivationTime;
    this.corridorActivationTimeMin = this.corridorActivationTime.minimum;
    this.corridorActivationTimeMax = this.corridorActivationTime.maximum;
    this.ngModelval["ActivationTimeValue"] = this.ActivationTimeValue =
      this.corridorActivationTime.default;
    this.corridorActivationTimeOption = {
      floor: this.corridorActivationTimeMin,
      ceil: this.corridorActivationTimeMax,
    };
    //End of ActivationTime
    //DTL enabled start
    if (
      this.properties["CorridorEnabled"] &&
      this.properties["CorridorEnabled"].default
    ) {
      this.corridorEnabledval = this.properties["CorridorEnabled"].default;
      this.ngModelval["CorridorEnabled"] =
        this.properties["CorridorEnabled"].default;
      //this.rSetChanged(this.DtlEnabledval);
    }
    //end DTL Enabled
    this.InfiniteDisabledVal = this.ngModelval["infiniteDisabled"] = false;
    //For quick Configuration
    this.service.getquickConfig.subscribe((data) => {
      if (data === "quickConfig") {
        this.quickConfigvalue = true;
        this.quickConfigCustomCSS = "quick-custom-inputbox textBoxUnit";
        this.setQuickConfig = "quickConfig";
      } else if (data === "standard") {
        this.quickConfigvalue = false;
        this.quickConfigCustomCSS = "custom-inputbox";
        this.setQuickConfig = "standard";
      }
      setTimeout(() => {
        if (this.ref && !(this.ref as ViewRef).destroyed) {
          this.ref.detectChanges();
        }
      });
    });
    ///End quick config
    let unSelectedFeat = Array(
      JSON.parse(localStorage.getItem("UnSelectedFeatConfig"))
    );
    let isConfigEdited = false;
    let featIndex: number = 0;
    if (unSelectedFeat[0] != null) {
      unSelectedFeat[0].forEach((element) => {
        if (element["featureName"].toString().toUpperCase() === "CORRIDOR") {
          isConfigEdited = true;
          let CORRIDOR: any = element["featureParameters"][0];
          this.addCorridorLoadedFeatureReadValues(CORRIDOR);
          unSelectedFeat[0].splice(featIndex, 1);
          localStorage.setItem(
            "UnSelectedFeatConfig",
            JSON.stringify(unSelectedFeat[0])
          );
        } else {
          featIndex++;
        }
      });
    }
    this.checkdfault();
    this.sendCorridordata(this.ngModelval);
    this.isEdited = true;
    setTimeout(() => {
      const corridorLoadedFeature = JSON.parse(
        localStorage.getItem("configurationData")
      );
      if (corridorLoadedFeature && !isConfigEdited) {
        if (corridorLoadedFeature.resp && corridorLoadedFeature.resp.Corridor) {
          if (corridorLoadedFeature.resp.Corridor) {
            this.addCorridorLoadedFeatureReadValues(
              corridorLoadedFeature.resp.Corridor
            );
          }
        }
      }
    }, 100);
  }
  addCorridorLoadedFeatureReadValues(Corridor: any) {
    let CorridorObj = Corridor;
    var keys = Object.keys(CorridorObj);
    var values = Object.values(CorridorObj);
    keys.forEach((key, i) => {
      this.textValChanged(values[i], key);
    });
    const index = keys.indexOf("ActivationTime");
    keys[index] = "ActivationTimeValue";
    const index1 = keys.indexOf("BackgroundLevel");
    keys[index1] = "BackgroundLevelValue";
    const index2 = keys.indexOf("DelayTime");
    keys[index2] = "DelayTimeValue";
    const index3 = keys.indexOf("FadeTimeIndex");
    keys[index3] = "FadeTimeIndexValue";
    const index4 = keys.indexOf("NormalLevel");
    keys[index4] = "NormalLevelValue";
    const index5 = keys.indexOf("ProlongTime");
    keys[index5] = "ProlongTimeValue";
    var result = [];
    keys.forEach((key, i) => (result[key] = values[i]));
    this.ngModelval = result;
    this.ngModelval["CorridorEnabled"] = result["CorridorEnabled"];
    this.corridordisbaled = !this.ngModelval["CorridorEnabled"];
    this.corridorNormal = this.properties.NormalLevel;
    this.corridorNormalMin = this.corridorNormal.minimum;
    this.corridorNormalMax = this.corridorNormal.maximum;
    this.ngModelval["NormalLevelValue"] = this.NormalLevelValue =
      result["NormalLevelValue"];
    this.ngModelval["NormalLevelMaximum"] = this.corridorNormalMax;
    this.corridorNormalOption = {
      floor: this.corridorNormalMin,
      ceil: this.corridorNormalMax,
    };
    this.corridorBackgroundLevel = this.properties.BackgroundLevel;
    this.corridorBackgroundLevelMin = this.corridorBackgroundLevel.minimum;
    this.corridorBackgroundLevelMax = this.corridorBackgroundLevel.maximum;
    this.ngModelval["BackgroundLevelValue"] = this.BackgroundLevelValue =
      result["BackgroundLevelValue"];
    this.ngModelval["BackgroundLevelMaximum"] = this.corridorBackgroundLevelMax;
    this.corridorBackgroundLevelOption = {
      floor: this.corridorBackgroundLevelMin,
      ceil: this.corridorBackgroundLevelMax,
    };
    this.corridorDelayTime = this.properties.DelayTime;
    this.corridorDelayTimeMin = this.corridorDelayTime.minimum;
    this.corridorDelayTimeMax = this.corridorDelayTime.maximum;
    this.ngModelval["DelayTimeValue"] = this.DelayTimeValue =
      result["DelayTimeValue"];
    this.corridorDelayTimeOption = {
      floor: this.corridorDelayTimeMin,
      ceil: this.corridorDelayTimeMax,
    };
    this.corridorFadeTimeIndex = this.properties.FadeTimeIndex;
    this.corridorFadeTimeIndexMin = this.corridorFadeTimeIndex.minimum;
    this.corridorFadeTimeIndexMax = this.corridorFadeTimeIndex.maximum;
    this.ngModelval["FadeTimeIndexValue"] = this.FadeTimeIndexValue =
      result["FadeTimeIndexValue"];
    this.ngModelval["FadeTimeIndexMaximum"] = this.corridorFadeTimeIndexMax;
    this.corridorFadeTimeIndexOption = {
      floor: this.corridorFadeTimeIndexMin,
      ceil: this.corridorFadeTimeIndexMax,
    };
    this.corridorProlongTime = this.properties.ProlongTime;
    this.corridorProlongTimeMin =
      this.field.oneOf[0].properties.ProlongTime.minimum;
    this.corridorProlongTimeMax =
      this.field.oneOf[0].properties.ProlongTime.maximum;
    this.ngModelval["ProlongTimeValue"] = this.ProlongTimeValue =
      result["ProlongTimeValue"];
    this.corridorProlongTimeOption = {
      floor: this.corridorProlongTimeMin,
      ceil: this.corridorProlongTimeMax,
    };
    this.corridorActivationTime = this.properties.ActivationTime;
    this.corridorActivationTimeMin = this.corridorActivationTime.minimum;
    this.corridorActivationTimeMax = this.corridorActivationTime.maximum;
    this.ngModelval["ActivationTimeValue"] = this.ActivationTimeValue =
      result["ActivationTimeValue"];
    this.corridorActivationTimeOption = {
      floor: this.corridorActivationTimeMin,
      ceil: this.corridorActivationTimeMax,
    };
    if (
      result["ProlongTimeValue"] === 2550 ||
      result["infiniteDisabled"] === true
    ) {
      this.ngModelval["infiniteDisabled"] = this.InfiniteDisabledVal = true;
      this.ngModelval["ProlongTimeValue"] = 2540;
      this.inputError["ProlongTime"] = false;
    } else if (
      result["ProlongTimeValue"] != 2550 ||
      result["infiniteDisabled"] === false
    ) {
      this.ngModelval["infiniteDisabled"] = this.InfiniteDisabledVal = false;
    }
    this.infinteDisabled(this.ngModelval["infiniteDisabled"]);
    this.checkdfault();
    this.sendCorridordata(this.ngModelval);
  }
  sendCorridordata(val) {
    this.service.sendCorridordata(val);
    if (this.isEdited) {
      this.setConfigurationData(val, "Corridor");
    }
  }
  rSetChanged(value) {
    this.corridorDefaultColor = value;
    for (var i = 0; i < this.column.length; i++) {
      let type = this.column[i];
      if (this.ngModelval[type] != this.properties[type]["default"]) {
        this.corridorDefaultColor = false;
      }
    }
    this.ngModelval["CorridorEnabled"] = value;
    this.sendCorridordata(this.ngModelval);
    this.checkdfault();
    this.corridordisbaled = !value;
    this.service.corridorEditedVal(this.corridorDefaultColor);
  }

  infinteDisabled(value) {
    this.InfiniteDisabledVal = value;
    if (value == true) {
      this.corridorDefaultColor = false;
      this.service.corridorEditedVal(this.corridorDefaultColor);
    } else {
      this.corridorDefaultColor = true;
      this.service.corridorEditedVal(this.corridorDefaultColor);
    }
    this.ngModelval["infiniteDisabled"] = value;
    this.sendCorridordata(this.ngModelval);
    this.checkdfault();
  }

  corridorNormalValueChange(value) {
    this.sendMaxValues();
    this.inputError["NormalLevel"] = false;
    if (
      value >= this.corridorNormalMin &&
      this.inputError["NormalLevel"] === false
    ) {
      this.ngModelval["NormalLevelValue"] = value;
      this.NormalLevelValue = value;
      if (
        value >= this.corridorNormalMin &&
        this.inputError["NormalLevel"] != true
      ) {
        var y = (value - 1) / (253 / 3) - 1;
        this.calculatedPercentageVal = Math.round(Math.pow(10, y));
      } else {
        this.calculatedPercentageVal = null;
      }
      this.sendCorridordata(this.ngModelval);
      this.checkdfault();
      this.checkInvalidValues();
    }
  }
  corridorBackgroundLevelChange(value) {
    this.sendMaxValues();
    this.inputError["BackgroundLevel"] = false;
    if (
      value >= this.corridorBackgroundLevelMin &&
      this.inputError["BackgroundLevel"] === false
    ) {
      this.ngModelval["BackgroundLevelValue"] = value;
      if (
        value >= this.corridorBackgroundLevelMin &&
        this.inputError["BackgroundLevel"] != true
      ) {
        var y = (value - 1) / (253 / 3) - 1;
        this.calculatedPercentageVal1 = Math.round(Math.pow(10, y));
      } else {
        this.calculatedPercentageVal1 = null;
      }
      this.sendCorridordata(this.ngModelval);
      this.checkdfault();
      this.checkInvalidValues();
    }
  }
  corridorFadeTimeIndexChange(value) {
    this.sendMaxValues();
    this.inputError["FadeTimeIndex"] = false;
    if (
      value >= this.corridorFadeTimeIndexMin &&
      this.inputError["FadeTimeIndex"] === false
    ) {
      this.ngModelval["FadeTimeIndexValue"] = value;
      this.fadeTimeDynamicVal = this.fadeTimeDataset[value];
      this.sendCorridordata(this.ngModelval);
      this.checkdfault();
      this.checkInvalidValues();
    }
  }
  corridorDelayTimeChange(value) {
    this.sendMaxValues();
    //this.inputError["DelayTime"] = false;
    if (value % 10 != 0) {
      //  this.inputError["DelayTime"] = true;

      let delayTimeValue = Math.round(value / 10) * 10;
      this.inputError["DelayTime"] = false;
      this.ngModelval["DelayTimeValue"] = delayTimeValue;
      this.sendCorridordata(this.ngModelval);
      this.checkdfault();
      this.checkInvalidValues();
      
    } else {
      this.inputError["DelayTime"] = false;
      if (
        value >= this.corridorDelayTimeMin &&
        this.inputError["DelayTime"] === false
      ) {
        this.ngModelval["DelayTimeValue"] = value;
        this.sendCorridordata(this.ngModelval);
        this.checkdfault();
        this.checkInvalidValues();
      }
    }
  }
  corridorProlongTimeChange(value) {
    this.sendMaxValues();
    //this.inputError["ProlongTime"] = false;
    if (value % 10 != 0) {
      
      let prolongTimeValue = Math.round(value / 10) * 10;
      this.inputError["ProlongTime"] = false;
      this.ngModelval["ProlongTimeValue"] = prolongTimeValue;
      this.sendCorridordata(this.ngModelval);
      this.checkdfault();
      this.checkInvalidValues();
      
    } else {
      this.inputError["ProlongTime"] = false;
      if (
        value >= this.corridorProlongTimeMin &&
        this.inputError["ProlongTime"] === false
      ) {
        this.ngModelval["ProlongTimeValue"] = value;
        this.sendCorridordata(this.ngModelval);
        this.checkdfault();
        this.checkInvalidValues();
      }
    }
  }
  corridorActivationTimeChange(value) {
    this.sendMaxValues();
    this.inputError["ActivationTime"] = false;
    if (
      value >= this.corridorActivationTimeMin &&
      this.inputError["ActivationTime"] === false
    ) {
      this.ngModelval["ActivationTimeValue"] = value;
      this.sendCorridordata(this.ngModelval);
      this.checkdfault();
      this.checkInvalidValues();
    }
  }

  sendMaxValues() {
    this.ngModelval["FadeTimeIndexMaximum"] = this.corridorFadeTimeIndexMax;
    this.ngModelval["BackgroundLevelMaximum"] = this.corridorBackgroundLevelMax;
    this.ngModelval["NormalLevelMaximum"] = this.corridorNormalMax;
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  changeMultiples(event, name) {
    this.corridorDefaultColor = false;
    this.inputError[name] = false;
    this.corridorCreateFile = false;
    if (name == "ProlongTime" || name == "ProlongTimeValue") {
      if (event % 10 != 0 || event > this.corridorProlongTimeMax) {
        this.ProlongTimeValue = Math.round(event / 10) * 10;
        this.ngModelval["ProlongTimeValue"] = this.ProlongTimeValue;
        if (
          this.ProlongTimeValue < this.corridorProlongTimeMin ||
          this.ProlongTimeValue > this.corridorProlongTimeMax ||
          this.ProlongTimeValue.length === 0
        ) {
          this.inputError[name] = true;
        } else {
          this.inputError[name] = false;
        }
      } else if (event.length === 0) {
        this.inputError[name] = true;
        this.ProlongTimeValue = Number(0);
      }
    }
    if (name == "DelayTime" || name == "DelayTimeValue") {
      if (event % 10 != 0 || event > this.corridorDelayTimeMax) {
        this.DelayTimeValue = Math.round(event / 10) * 10;
        this.ngModelval["DelayTimeValue"] = this.DelayTimeValue;
        if (
          this.DelayTimeValue < this.corridorDelayTimeMin ||
          this.DelayTimeValue > this.corridorDelayTimeMax ||
          this.DelayTimeValue.length === 0
        ) {
          this.inputError[name] = true;
        } else {
          this.inputError[name] = false;
        }
      } else if (event.length === 0) {
        this.inputError[name] = true;
        this.DelayTimeValue = Number(0);
      }
    }
    this.checkdfault();
    for (var type in this.inputError) {
      if (this.inputError[type]) {
        this.corridorCreateFile = true;
      }
    }
    this.service.corridorEditedVal(this.corridorDefaultColor);
    this.service.corridorInputError(this.corridorCreateFile);
    this.sendCorridordata(this.ngModelval);
  }

  textValChanged(value, colName) {
    this.sendMaxValues();
    let originalVal = value;
    value = parseInt(value);
    if (isNaN(value)) {
      value = 0;
    }
    this.corridorDefaultColor = false;
    this.inputError[colName] = false;
    this.corridorCreateFile = false;
    if (colName == "NormalLevel") {
      if (value < this.corridorNormalMin || value > this.corridorNormalMax) {
        this.inputError[colName] = true;
        this.ngModelval["NormalLevelValue"] = value;
      } else {
        this.NormalLevelValue = value;
        this.ngModelval["NormalLevelValue"] = value;
      }
      if (
        this.NormalLevelValue >= this.corridorNormalMin &&
        this.inputError["NormalLevel"] != true
      ) {
        var y = (this.NormalLevelValue - 1) / (253 / 3) - 1;
        this.calculatedPercentageVal = Math.round(Math.pow(10, y));
      } else {
        this.calculatedPercentageVal = null;
      }
    } else if (colName == "BackgroundLevel") {
      if (
        value < this.corridorBackgroundLevelMin ||
        value > this.corridorBackgroundLevelMax
      ) {
        this.inputError[colName] = true;
        this.ngModelval["BackgroundLevelValue"] = value;
      } else {
        this.BackgroundLevelValue = value;
        this.ngModelval["BackgroundLevelValue"] = value;
      }
      if (
        this.BackgroundLevelValue >= this.corridorBackgroundLevelMin &&
        this.inputError["BackgroundLevel"] != true
      ) {
        var y = (this.BackgroundLevelValue - 1) / (253 / 3) - 1;
        this.calculatedPercentageVal1 = Math.round(Math.pow(10, y));
      } else {
        this.calculatedPercentageVal1 = null;
      }
    } else if (colName == "DelayTime") {
      if (
        originalVal < this.corridorDelayTimeMin ||
        originalVal > this.corridorDelayTimeMax ||
        originalVal.length === 0
      ) {
        this.inputError[colName] = true;
        // this.ngModelval["DelayTimeValue"] = originalVal;
        if (originalVal.length <= 0) {
          //this.DelayTimeValue = Number(0);
        }
      } else {
        this.DelayTimeValue = Number(originalVal);
        this.ngModelval["DelayTimeValue"] = Number(originalVal);
      }
    } else if (colName == "FadeTimeIndex") {
      if (
        originalVal < this.corridorFadeTimeIndexMin ||
        originalVal > this.corridorFadeTimeIndexMax ||
        originalVal.length === 0
      ) {
        this.inputError[colName] = true;
        this.ngModelval["FadeTimeIndexValue"] = Number(originalVal);
        if (originalVal.length <= 0) {
          this.FadeTimeIndexValue = Number(0);
        }
        this.fadeTimeDynamicVal = null;
      } else {
        this.FadeTimeIndexValue = Number(originalVal);
        this.ngModelval["FadeTimeIndexValue"] = Number(originalVal);
        this.fadeTimeDynamicVal = this.fadeTimeDataset[originalVal];
      }
      // if (value < this.corridorFadeTimeIndexMin && value !== this.corridorFadeTimeIndex) {
      //   this.FadeTimeIndexValue = this.corridorFadeTimeIndexMin;
      // } else if(value > this.corridorFadeTimeIndexMax && value !== this.corridorFadeTimeIndex){
      //   this.FadeTimeIndexValue = this.corridorFadeTimeIndexMax;
      // }else{
      //   this.FadeTimeIndexValue = value;
      // }
    } else if (colName == "ProlongTime") {
      if (originalVal === 2550) {
        originalVal = 2540;
      }
      if (
        originalVal < this.corridorProlongTimeMin ||
        originalVal > this.corridorProlongTimeMax ||
        originalVal.length === 0
      ) {
        this.inputError[colName] = true;
        //this.ngModelval["ProlongTimeValue"] = originalVal;
        if (originalVal.length <= 0) {
          //this.ProlongTimeValue = Number(0);
        }
      } else {
         this.ProlongTimeValue = Number(originalVal);
         this.ngModelval["ProlongTimeValue"] = Number(originalVal);
      }
    } else if (colName == "ActivationTime") {
      if (
        originalVal < this.corridorActivationTimeMin ||
        originalVal > this.corridorActivationTimeMax ||
        originalVal.length === 0
      ) {
        this.inputError[colName] = true;
        this.ngModelval["ActivationTimeValue"] = originalVal;
        if (originalVal.length <= 0) {
          this.ActivationTimeValue = Number(0);
        }
      } else {
        this.ActivationTimeValue = Number(originalVal);
        this.ngModelval["ActivationTimeValue"] = Number(originalVal);
      }
    }
    this.checkdfault();
    for (var type in this.inputError) {
      if (this.inputError[type]) {
        this.corridorCreateFile = true;
      }
    }
    this.service.corridorEditedVal(this.corridorDefaultColor);
    this.service.corridorInputError(this.corridorCreateFile);
    this.sendCorridordata(this.ngModelval);
  }

  checkInvalidValues() {
    this.corridorCreateFile = false;
    for (var type in this.inputError) {
      if (this.inputError[type]) {
        this.corridorCreateFile = true;
      }
    }
    this.service.corridorInputError(this.corridorCreateFile);
  }

  sliderEvent() {}

  resetDefaultCorridor() {
    this.ngModelval["NormalLevelValue"] = this.NormalLevelValue =
      this.corridorNormal.default;
    var y = (this.NormalLevelValue - 1) / (253 / 3) - 1;
    this.calculatedPercentageVal = Math.round(Math.pow(10, y));
    this.ngModelval["DelayTimeValue"] = this.DelayTimeValue =
      this.corridorDelayTime.default;
    this.ngModelval["FadeTimeIndexValue"] = this.FadeTimeIndexValue =
      this.corridorFadeTimeIndex.default;
    this.fadeTimeDynamicVal =
      this.fadeTimeDataset[this.corridorFadeTimeIndex.default];
    this.ngModelval["ActivationTimeValue"] = this.ActivationTimeValue =
      this.corridorActivationTime.default;
    this.ngModelval["BackgroundLevelValue"] = this.BackgroundLevelValue =
      this.corridorBackgroundLevel.default;
    var y1 = (this.BackgroundLevelValue - 1) / (253 / 3) - 1;
    this.calculatedPercentageVal1 = Math.round(Math.pow(10, y1));
    this.ngModelval["ProlongTimeValue"] = this.ProlongTimeValue =
      this.corridorProlongTime.default;
    this.ngModelval["CorridorEnabled"] =
      this.properties["CorridorEnabled"].default;
    this.corridordisbaled = !this.ngModelval["CorridorEnabled"];
    this.ngModelval["infiniteDisabled"] = false;
    this.infinteDisabled(this.ngModelval["infiniteDisabled"]);
    for (var type in this.inputError) {
      this.inputError[type] = false;
    }
    this.corridorCreateFile = false;
    this.service.corridorInputError(this.corridorCreateFile);
    this.sendCorridordata(this.ngModelval);
  }

  checkdfault() {
    var newtype;
    this.corridorDefaultColor = true;
    if (!isNullOrUndefined(this.properties)) {
      for (var type in this.ngModelval) {
        if (
          type != "infiniteDisabled" &&
          type != "FadeTimeIndexMaximum" &&
          type != "NormalLevelMaximum" &&
          type != "BackgroundLevelMaximum" &&
          !isNullOrUndefined(this.properties) &&
          type != "CorridorEnabled"
        ) {
          newtype = type.replace("Value", "");
          if (this.ngModelval[type] != this.properties[newtype]["default"]) {
            this.corridorDefaultColor = false;
          }
        }
        if (
          !isNullOrUndefined(this.properties) &&
          type === "infiniteDisabled" &&
          this.ngModelval["infiniteDisabled"] != false
        ) {
          this.corridorDefaultColor = false;
        }
        if (
          !isNullOrUndefined(this.properties && this.ngModelval[type]) &&
          type === "CorridorEnabled"
        ) {
          if (this.ngModelval[type] != this.properties[type]["default"]) {
            this.corridorDefaultColor = false;
          }
        }
        this.service.corridorEditedVal(this.corridorDefaultColor);
      }
    }
  }
  setConfigurationData(value, keyVal) {
    let corridorObj = {};
    var key = keyVal;
    var data = {};
    var addNew = {
      [keyVal]: {},
    };
    data = JSON.parse(localStorage.getItem("configurationData"));
    if (data && !isNullOrUndefined(data["resp"])) {
      corridorObj = Object.assign({}, value);
      data["resp"][keyVal] = corridorObj;
    } else {
      data["resp"] = addNew;
      corridorObj = Object.assign({}, value);
      data["resp"][keyVal] = corridorObj;
    }
    localStorage.setItem("configurationData", JSON.stringify(data));
  }
  ngOnDestroy() {
    this.service.sendCorridordata("");
    this.service.setCorridorDefaultColor("");
    // this.service.setCorridorDefaultColor("");
    // this.sendCorridordata("");
  }
}
